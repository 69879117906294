import React from 'react';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import AppState from '../AppState';
import ConfigDateTime from './ConfigDateTime';
import Translate from '../Translate';
import Utils from '../Utils';
import Tracking from '../Tracking';
import ClearIcon from 'material-ui-icons/Clear';
import Button from 'material-ui/Button';

const styles = theme => ({
});


@observer
export class ChatBot extends React.Component
{
	mounted = false;
	contextData = {};
	steps = [];

	date = new Date();
	searchCategoryId;

	constructor(props)
	{
		super(props);

		this.steps = AppState.instance.chatBotManager.GetSteps(this.props.goalId);
		this.contextData.step = 0;

		var loaded = false;
		if (this.props.id)
			loaded = AppState.instance.chatBotManager.LoadState(this, this.props.id);

		this.SetupContextData();

		if (loaded)
		{
			const goalId = this.contextData["goal"];
			if (goalId)
				for (var i = 0; i < this.steps.length; ++i)
					this.steps[i].OnGoalChanged(goalId, this.contextData);
		}
	}

	componentDidMount()
	{
		this.mounted = true;

		if (this.props.onInit)
			this.props.onInit(this);

		if (this.contextData.step > 0)
			if (this.props.onActivated)
				this.props.onActivated(this);

		if (this.props.goal)
		{
			var step = this.steps[0];
			step.OnStepDone(this.contextData, {
				goal: this.props.goal
			});
		}

		this.searchCategoryId = AppState.instance.filterManager.CreateFilterId(AppState.instance.filterStack);
		AppState.instance.contentStore.AddOnDataChanged(this.searchCategoryId, this.OnSearchDataChanged);
	}

	componentWillUnmount()
	{
		AppState.instance.contentStore.RemoveOnDataChanged(this.searchCategoryId, this.OnSearchDataChanged);
		this.mounted = false;
	}

	OnSearchDataChanged = (action, newData) =>
	{
		if (action === 'request')
		 	++this.contextData.isSearching;
		else if (action === 'data' || action === 'error' || action === 'abort' || action === 'eod' || action === 'flushed')
			this.contextData.isSearching = 0;
		this.forceUpdate();
	}

	OnStepDone = (contextData) => (newData) =>
	{
		this.DoOnStepDone(contextData, newData);
	}

	OnGoalAchieved()
	{
		const currentGoal = this.contextData["goal"];
		Tracking.SendGoogleEvent("Chatbot", "ChatBot_Goal_Finished", currentGoal);

		this.Reset(false);
	}

	DoOnStepDone(contextData, newData)
	{
		var newGoal;
		var finish;

		const currentGoal = contextData["goal"];

		for (var propId in newData)
		{
			contextData[propId] = newData[propId];

			if (propId === "goal")
				newGoal = newData[propId];
			else if (propId === "finish")
				finish = newData[propId];
		}

		const currentStepIdx = contextData.step;
		const currentStep = this.steps[currentStepIdx];
		
		Tracking.SendGoogleEvent("Chatbot", "ChatBot_Step_Finished", currentGoal ? (currentGoal + "_" + currentStep.GetId()) : currentStep.GetId());

		var nextStep = true;
		if (newGoal)
		{
			//HACK: switch from this intro bot to the real bot
			if (this.props.intro)
			{
				this.Reset(true);
				if (this.props.onActivated)
					this.props.onActivated(this, newGoal);
				AppState.instance.appContainer.forceUpdate();
				return;
			}

			this.OnGoalChanged(newGoal);
		}
		else if (finish === "yes")
		{
			if (this.props.onFinished)
				this.props.onFinished(this);
		// 	this.Reset(false);
		// 	nextStep = false;
		}
		else if (finish === "delete")
		{
			this.Reset(true);
		}

		if (nextStep)
		{
			contextData.step++;
			AppState.instance.chatBotManager.SaveState(this, this.props.id);

			if (contextData.step < this.steps.length)
			{
				const nextId = this.steps[contextData.step].GetId();
				contextData[nextId + "_new"] = true;
				setTimeout(() => {
					contextData[nextId + "_new"] = false;
					contextData.onChanged();
				}, 1000);
			}

			if (contextData.step === 1)
				if (this.props.onActivated)
					this.props.onActivated(this);
		}

		contextData.onChanged();
	}

	OnStepEdit = (contextData) => (newData) =>
	{
		for (var propId in newData)
		{
			contextData[propId] = newData[propId];
		}

		AppState.instance.chatBotManager.SaveState(this, this.props.id);
		contextData.onChanged();
	}

	OnSearch = (contextData) => (searchTerm) =>
	{
		AppState.instance.lastEnteredSearchString = searchTerm;

		AppState.instance.userProfile.OnSearch(searchTerm);
		AppState.instance.contentStore.Search(searchTerm);

		contextData.onChanged();
	}

	OnSearchFocus = (contextData) => () =>
	{
		/*const targetStep = this.GetPickerTargetStep();
		if (targetStep && targetStep.IsCurrentlyEdited(contextData))
		{
			// up to the .chatBot and down to .activitySearchContainer
			const chatBotDiv = $("#" + this.contextData.searchPageContainerId).parents(".chatBot");
			const cbOffset = chatBotDiv.offset().top;
			const searchField = chatBotDiv.find(".activitySearchContainer");
			const offset = searchField.offset();
			const pos = offset.top - cbOffset + 44;
			$("#" + this.contextData.searchPageContainerId).css('top', pos);
		}
		else
		{
			$("#" + this.contextData.searchPageContainerId).css('top', '');
		}*/

		contextData.searchHasFocus = true;
		contextData.onChanged();
	}

	OnSearchBlur = (contextData) => () =>
	{
		setTimeout(() => {
			contextData.searchHasFocus = false;
		}, 300);
	}

	OnGoalChanged(goalId)
	{
		var combined = [];

		const currentStep = this.contextData.step;
		for (var i = 0; i <= currentStep && i < this.steps.length; ++i)
		{
			const step = this.steps[i];
			combined.push(step);
		}

		var newSteps = AppState.instance.chatBotManager.GetSteps(goalId);

		this.steps = combined.concat(newSteps);

		for (i = 0; i < this.steps.length; ++i)
			this.steps[i].OnGoalChanged(goalId, this.contextData);
	}

	IsClosingMsgActive()
	{
		const currentStep = this.contextData.step;
		for (var i = 0; i <= currentStep && i < this.steps.length; ++i)
		{
			const step = this.steps[i];
			if (step.GetId() === "closingmsg")
				return true;
		}
		return false;
	}

	GetPickerTargetStep()
	{
		const isEdit = this.contextData["edit"] === true;
		if (isEdit)
		{
			for (var i = 0; i < this.steps.length; ++i)
			{
				const step = this.steps[i];
				const isStepEdit = this.contextData[step.GetId() + "_edit"] === true;
				if (isStepEdit)
					return step;
			}
		}
		else
		{
			const currentStepIdx = this.contextData.step;
			return this.steps[currentStepIdx];
		}

		return undefined;
	}

	ShowCalendar()
	{
		this.contextData.showCalendar = true;
		this.forceUpdate();
	}

	OnCloseDateSelection = () =>
	{
		this.contextData.showCalendar = false;
		this.forceUpdate();
	}

	OnDateSelected = (date) =>
	{
		this.contextData.showCalendar = false;

		const targetStep = this.GetPickerTargetStep();
		if (targetStep && targetStep.OnDateSelected)
			targetStep.OnDateSelected(this.contextData, date);
	}

	ConfirmDeletion(onYes, onNo)
	{
		AppState.instance.screenMessage.ShowAlert(Translate.T("en.Confirmation", "de.Bestätigung", "confirmation.title"),
			Translate.T("en.Do you really want to cancel this conversation and delete all entered data?",
			"de.Willst Du diese Unterhaltung wirklich abbrechen und Deine eingegebenen Daten löschen?", "confirmation.cancelchatbot"),
			[{label: Translate.FindTranslation("answer.no"), value: 0}, {label: Translate.FindTranslation("answer.yes"), value: 1}], true, (resultButton) =>
		{
			if (resultButton && resultButton.value)
			{
				if (onYes)
					onYes();
			}
			else
			{
				if (onNo)
					onNo();
			}
		});
	}

	OnCancel()
	{
		const currentStepIdx = this.contextData.step;
		if (currentStepIdx > 0)
		{
			this.ConfirmDeletion(() =>
			{
				this.Reset(true);
			});
		}
		else
		{
			this.Reset(true);
		}
	}

	Reset(undo)
	{
		if (undo)
		{
			const currentStep = this.contextData.step;
			for (var i = 0; i <= currentStep && i < this.steps.length; ++i)
			{
				const step = this.steps[i];
				step.Undo(this.contextData);
			}
		}

		this.steps = AppState.instance.chatBotManager.GetSteps(this.props.goalId);
		this.contextData = {};
		this.contextData.step = 0;

		if (this.props.id)
			AppState.instance.chatBotManager.DeleteState(this.props.id);

		this.SetupContextData();

		if (AppState.instance.lastEnteredSearchString)
		{
			AppState.instance.lastEnteredSearchString = undefined;
			AppState.instance.contentStore.Search("");
			if (AppState.instance.appInstance)
				AppState.instance.appInstance.OnLeaveSearch();
		}

		this.forceUpdate();

		if (this.props.onDeactivated)
			this.props.onDeactivated(this);
	}

	OnDataChanged = (contextData) => () =>
	{
		if (contextData.bot.mounted)
			contextData.bot.forceUpdate();
	}

	SetupContextData()
	{
		this.contextData.bot = this;
		this.contextData.onStepDone = this.OnStepDone(this.contextData);
		this.contextData.onStepEdit = this.OnStepEdit(this.contextData);
		this.contextData.isSearching = 0;
		this.contextData.onSearch = this.OnSearch(this.contextData);
		this.contextData.onSearchFocus = this.OnSearchFocus(this.contextData);
		this.contextData.onSearchBlur = this.OnSearchBlur(this.contextData);
		this.contextData.onChanged = this.OnDataChanged(this.contextData);
		this.contextData.progress = undefined;
		this.contextData.success = undefined;
		this.contextData.error = undefined;
		this.contextData.searchPageContainerId = Utils.GenerateRandomStr(32);
	}

	OnCancelClicked = () =>
	{
		this.OnCancel();
	}

	OnStopEventPropagation = (e) =>
	{
		e.stopPropagation();
	}

	render()
	{
		if (!this.steps)
			return null;

		const currentStep = this.contextData.step;

		var stepElements = [];
		for (var i = 0; i <= currentStep && i < this.steps.length; ++i)
		{
			const step = this.steps[i];
			stepElements.push(step.render(this.contextData));
		}

		return (
			<div
				className={"chatBot step" + currentStep}
				id={"chatBot_" + this.props.id}
				onClick={AppState.instance.deviceInfo.desktop ? this.OnStopEventPropagation : undefined}
			>

				{AppState.instance.deviceInfo.desktop && currentStep > 0 &&
					<div className="chatBotCancelButton">
						<Button onClick={this.OnCancelClicked}>
							<ClearIcon />
						</Button>
					</div>
				}

				{stepElements}

				<ConfigDateTime
					dialogOnly={true}
					openDatePicker={this.contextData.showCalendar}
					date={this.date}
					maxDate={new Date()}
					onCloseDatePicker={this.OnCloseDateSelection}
					onDateSelected={this.OnDateSelected} />
			</div>
		);
	}
}

ChatBot.propTypes = {
};
  
export default withStyles(styles)(ChatBot);