import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import AppState from '../../AppState';
import Translate from '../../Translate';
import { Button } from 'material-ui';
import AdvCard from './AdvCard';
import TooltipWrapper from '../TooltipWrapper';

const styles = theme => ({
});

@observer
class AdvAdventure extends React.Component
{
	advManager;

	constructor(props)
	{
		super(props);

		this.advManager = AppState.instance.advManager;
	}

	componentDidMount()
	{
		this.advManager.onConfigChanged.Add(this.OnConfigChanged);
		this.advManager.onInstancesChanged.Add(this.OnInstancesChanged);
	}

	componentWillUnmount()
	{
		this.advManager.onConfigChanged.Remove(this.OnConfigChanged);
		this.advManager.onInstancesChanged.Remove(this.OnInstancesChanged);
	}

	OnConfigChanged = () =>
	{
		this.forceUpdate();
	}

	OnInstancesChanged = () =>
	{
		this.forceUpdate();
	}

	OnAdventureCta = (e) =>
	{
		e.stopPropagation();
		e.preventDefault();

		setTimeout(() => {
			if (this.props.onCta)
				this.props.onCta(this.props.adventureId);	
		}, 10);
	}

	// OnBackgroundClick = (e) =>
	// {
	// 	if (this.props.onBackgroundClick)
	// 	{
	// 		e.stopPropagation();
	// 		e.preventDefault();

	// 		setTimeout(() => {
	// 			this.props.onBackgroundClick(e);
	// 		}, 10);
	// 	}
	// }

	render()
	{
		const adv = this.advManager.GetAdventureById(this.props.adventureId);
		if (!adv)
			return null;

		const teaser = adv.teaser;

		const cards = this.advManager.GetCards(adv);

		var hasCardInstances = false;
		if (cards && cards.length > 0)
		{
			for (var i = 0; i < cards.length; ++i)
			{
				var instances = this.advManager.GetCardInstances(cards[i].id);
				if (instances && instances.length > 0)
				{
					hasCardInstances = true;
					break;
				}
			}
		}

		var instanceOverview = this.advManager.GetAdvInstancesOverview(adv.id);

		var isUnlocked = instanceOverview.isUnlocked;
		var isStarted = instanceOverview.startedInstance !== undefined;
		var isFinished = instanceOverview.finishedInstance !== undefined;

		//const isUnlocked = instance ? instance.unlocked !== undefined : false;
		const isLocked = !isUnlocked;
		//const isStarted = instance ? instance.started !== undefined : false;
		//const isFinished = instance ? (instance.finished !== undefined) : false;
		const renderCards = isUnlocked && hasCardInstances && cards;

		return (
			<div
				className={"adventure" + (renderCards ? " withCards":"") +
				(this.props.className ? " " + this.props.className : "")} key={adv.id}
				//onClick={this.OnBackgroundClick}
			>
				{teaser && teaser.pictureUrl &&
					<div
						className={"keyImage" + (isLocked ? " grayscale":"") + (hasCardInstances ? " withCards":"")}
						style={{backgroundImage: 'url("' + teaser.pictureUrl + '")'}}
					/>}

				<div className="title">{adv.title}</div>

				{adv.subtitle &&
					<div className="teaser">
						{adv.subtitle}
					</div>}

				{isUnlocked &&
					<div className={"cta" + (isStarted && !isFinished ? " resume":"")}>
						<Button onClick={this.OnAdventureCta}>
							{!isStarted && !isFinished && Translate.T("de.Mehr Information", "en.More Information", "adventure.cta.more")}
							{isStarted && !isFinished && Translate.T("de.Abenteuer Fortsetzen", "en.Continue Adventure", "adventure.cta.resume")}
							{isStarted && isFinished && Translate.T("de.Zeige Ergebnis", "en.Show Results", "adventure.cta.finished")}
						</Button>
					</div>}

				{renderCards &&
					<div className="cards">
						{cards.map((card, index) =>
						{
							return (
								<AdvCard key={card.id} cardId={card.id} isFirst={index === 0} isLast={index === cards.length - 1}
									showTitle={false} showPlus={false} showNumInstances={false}
									//onClick={this.OnCardClicked}
									imageSize="_s"
								/>
							);
						})}
					</div>}

				{isLocked &&
					<div className="lock">
						<TooltipWrapper title={Translate.T("de.Lege Dir einen persönlichen Account an, um Dein Abenteuer zu starten.",
							"en.Create an account to start your adventure", "advadventure.locked.tooltip")} >
							<img src="/assets/cards/lock.png" alt="locked" />
						</TooltipWrapper>
					</div>}
			</div>
		);
	}
}

AdvAdventure.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AdvAdventure);