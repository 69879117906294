import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import Tracking from '../Tracking';
import AppState from '../AppState';
import Map from '../components/Map';
import Menu, { MenuItem } from 'material-ui/Menu';
import Translate from '../Translate';
import AccountRequestPage from './AccountRequestPage';
import Utils from '../Utils';
import KeyboardArrowDown from 'material-ui-icons/KeyboardArrowDown';
import Button from 'material-ui/Button';
import $ from 'jquery';
import Tutorial from '../components/Tutorial';
import LandingPageFooter from '../components/LandingPageFooter';
//import RestApi from '../RestApi';
import SwipeableViews from 'react-swipeable-views';
import DeviceHub from 'material-ui-icons/DeviceHub';
import TripIcon from 'material-ui-icons/Place';
import PersonIcon from 'material-ui-icons/Person';
import SearchIcon from 'material-ui-icons/Search';
import Dots from '../components/Dots';


const styles = {
};


@observer
class MobileLandingPage extends React.Component
{
	menuAnchor = null;
	@observable isLogoMenuOpen = false;
	@observable featureIndex = 0;

	iconChat;
	iconSaved;
	iconExplore;
	iconCommunity;
	tripIcon;
	iconProfile;
	pageFooter;

	constructor(props)
	{
		super(props);

		const iconSize = 56;
		const iconColor = "#e31c46";

		this.iconChat = <div className="menuIconChat" style={{width: iconSize, height: iconSize}}>{Utils.RenderCardIcon(false, iconSize, iconSize, iconColor)}</div>;
		this.iconSaved = <div className="menuIconChat" style={{width: iconSize, height: iconSize}}>{Utils.RenderStarIcon(iconSize, iconSize, iconColor, iconColor, 2.5)}</div>;

		//var iconJournals = <AddCircleOutline style={{color: iconColor, width: iconSize, height: iconSize }} />;
		this.iconExplore = <SearchIcon style={{color: iconColor, width: iconSize, height: iconSize }} />;
		this.iconCommunity = <DeviceHub style={{color: iconColor, width: iconSize, height: iconSize }} />;
		this.tripIcon = <TripIcon style={{color: iconColor, width: iconSize, height: iconSize }} />;
		this.iconProfile = <PersonIcon style={{color: iconColor, width: iconSize, height: iconSize }} />;
	}
	
	componentDidMount()
	{
		//var thisPtr = this;
		this.UpdateCover();
		//$(window).on('resize orientationchange', function() {
		//	thisPtr.UpdateCover();
		//});

		const showWebcontentAtStart = AppState.instance.showWebcontentAtStart;
		const scrollDelay = 500;

		if (showWebcontentAtStart === "tos")
			this.OnToSClicked();
		else if (showWebcontentAtStart === "pp")
			this.OnPPClicked();
		else if (showWebcontentAtStart === "imprint")
			this.OnImprintClicked();
		else if (showWebcontentAtStart === "features")
			this.ScrollTo('.mlpSectionFeatures', undefined, true, scrollDelay);
		else if (showWebcontentAtStart === "activities")
			this.ScrollTo('.mlpSectionMap', undefined, true, scrollDelay);
		else if (showWebcontentAtStart === "start")
			this.ScrollTo('.mlpSectionFeatures', undefined, true, scrollDelay);
		else if (showWebcontentAtStart === "about")
			this.ScrollTo('.mlpSectionFooter', undefined, true, scrollDelay);

		AppState.instance.showWebcontentAtStart = undefined;
	}

	ScrollTo(selector, offset, jump, delay)
	{
		if (delay)
		{
			setTimeout(() => {
				this.ScrollTo(selector, offset, jump);
			}, delay);
		}
		else
		{
			var scrollTarget = $(selector).offset().top;
			if (offset)
				scrollTarget += offset;

			var body = $("html, body");

			if (jump)
			{
				body.scrollTop(scrollTarget);
			}
			else
			{
				body.stop().animate({scrollTop: scrollTarget}, 500, 'swing');
			}
		}
	}

	UpdateCover()
	{
		var h = $(window).height();
		var w = $(window).width();
		$('.mlpSection0').css({'height': h});

		// prevent flickering in browser when addr-bar moves up/down
		$('.mlpMapContainer').css({'height': h});
		//$('.mlpFeatureCard').css({'height': h * 0.6});

		if (h > w)
			$('.mlpSection0').css({'background-image': 'url(/assets/familyp.jpg)'});
		else
			$('.mlpSection0').css({'background-image': 'url(/assets/familyl.jpg)'});
	}

	OnLogoClicked = (event) =>
	{
		this.menuAnchor = event.currentTarget;
		this.isLogoMenuOpen = true;
		event.stopPropagation();
	}

	handleLogoMenuRequestClose = () =>
	{
		this.isLogoMenuOpen = false;
	}

	OnLoginClicked = () =>
	{
		this.isLogoMenuOpen = false;
		//Tracking.SendEvent("screenTransition", {from: "/IntroPage", to: "/AccountRequestPage"});
		AccountRequestPage.Show();
	}

	OnLogoutClicked = () =>
	{
		this.isLogoMenuOpen = false;
		AppState.instance.OnLoggedOut();
	}

	OnFooterInit = (footer) =>
	{
		this.pageFooter = footer;
	}

	OnToSClicked = () =>
	{
		this.isLogoMenuOpen = false;

		if (this.pageFooter)
			this.pageFooter.OnTos();
	}

	OnPPClicked = () =>
	{
		this.isLogoMenuOpen = false;

		if (this.pageFooter)
			this.pageFooter.OnPP();
	}

	OnImprintClicked = () =>
	{
		this.isLogoMenuOpen = false;

		if (this.pageFooter)
			this.pageFooter.OnImprint();
	}

	OnLanguageClicked = () =>
	{
		this.isLogoMenuOpen = false;
		Translate.ShowLanguageSelection(this.OnLanguageSelected);
	}

	renderIntroHeader()
	{
		const isLoggedIn = AppState.instance.IsLoggedIn();
		//if (isLoggedIn)
		//	return null;

		return (
			<div className="intro_container">
				<div className="intro_logocontainer" id="intro_logocontainer" onClick={this.OnLogoClicked}>
					{Utils.RenderLogo(40, 40, "white", "#e31c46")}
					<KeyboardArrowDown color="primary"
						className="arrowDown"
						style={{
							width: 16,
							height: 16,
							marginBottom: '10px'
						}}/>
				</div>
				<Menu
					id="logoMenu"
					anchorEl={this.menuAnchor}
					open={this.isLogoMenuOpen}
					onClose={this.handleLogoMenuRequestClose}
				>
					{!isLoggedIn && <MenuItem onClick={this.OnLoginClicked}>{Translate.T("en.Log In", "de.Einloggen", "menu.login")}</MenuItem>}
					{isLoggedIn && <MenuItem onClick={this.OnLogoutClicked}>{Translate.T("en.Log Out", "de.Ausloggen", "menu.logout")}</MenuItem>}
					<MenuItem onClick={this.OnLanguageClicked}>{Translate.T("en.Language", "de.Sprache", "language")}</MenuItem>
					<MenuItem onClick={this.OnToSClicked}>{Translate.T("en.Terms of Service","de.Benutzungsbedingungen","tos")}</MenuItem>
					<MenuItem onClick={this.OnPPClicked}>{Translate.T("en.Privacy Policy","de.Datenschutzbestimmungen","privacypolicy")}</MenuItem>
					<MenuItem onClick={this.OnImprintClicked}>{Translate.T("en.Imprint","de.Impressum","imprint")}</MenuItem>
				</Menu>

				<div className="mktClaim" id="mktClaim">
					<div className="mktClaimLena">Lena</div>
					<div className="mktClaimText">{Translate.T("en.Simple. Quick.\nGreat trips with your family.", "de.Einfach. Schnell.\nPassende Ausflüge für Deine Familie.", "intro.marketingclaim")}</div>
				</div>
			</div>
		);
	}

	HidePage()
	{
		AppState.instance.isMobileLandingPage = false;
		Utils.ScrollToTop();

		if (!Tutorial.IsActive())
			AppState.instance.contentStore.Resume();
		Tutorial.OnCoverLifted();

		AppState.instance.appInstance.OnMobileLandingPageDone();
	}

	OnCTA = (event) =>
	{
		Tracking.OnPageView("mlp/cta");
		this.HidePage();
	}

	OnSecondaryCTA = (event) =>
	{
		Tracking.OnPageView("mlp/cta2");
		this.HidePage();
	}

	RenderFeature(id, icon, title, text)
	{
		return (
			<div id={id} key={id} className="mlpFeature">
				<div className="mlpFeatureCard">
					<div className="header">{icon}</div>
					<div className="title">{title}</div>
					<div className="text">{text}</div>
				</div>
			</div>
		);
	}

	OnFeatureSwiped = (newIndex, oldIndex) =>
	{
		this.featureIndex = newIndex;
	}

	render()
	{
		var centerLat;
		var centerLng;
		if (this.filterSetup && this.filterSetup.location)
		{
			centerLat = this.filterSetup.location.latitude;
			centerLng = this.filterSetup.location.longitude;
		}
		else
		{
			let loc = AppState.instance.GetBestLocation();
			if (loc)
			{
				centerLat = loc.latitude;
				centerLng = loc.longitude;
			}
		}

		return (
			<div
				id="mlp"
				className={"mlp"}  
			>
				<div className="mlpSection mlpSection0">
					<div className="coverGradient" />
					<div className="coverGradientFooter" />

					{this.renderIntroHeader()}

					<div className="coverButtons">
						<div className="coverCTA">
							<Button color="default" onClick={this.OnCTA}>
								{Translate.T("en.Receive Recommendation", "de.Vorschläge erhalten", "cover.buttons.cta")}
							</Button>
						</div>
					</div>

					<div className="scrollhint">
						<KeyboardArrowDown />
					</div>
				</div>

				

				{/* <div className="mlpSection mlpSectionAdventure">
					<div className="image"/>

					<div className="text0">{Translate.T("de.Bist Du bereit für aufregende Abenteuer während Deiner Familienausflüge?",
						"en.Are your ready for exciting adventures during your family trips?", "advannouncement.claim0")}</div>

					<div className="text1">{Translate.T("de.Erlebe gemeinsam mit Deinen Kindern fantastische, interaktive Geschichten und schnappe Dir dabei LENAs Abenteuerkarten.",
						"en.Experience fantastic, interactive stories together with your kids and fetch LENA's adventure cards on the go.", "advannouncement.text0")}</div>
				</div>

				<div className="mlpSection mlpSectionCta3">
					<Button color="default" onClick={this.OnSecondaryCTA}>
						{Translate.T("en.Get Started Now", "de.Los geht's", "cover.buttons.cta2")}
					</Button>
				</div> */}

				<div className="mlpSection mlpSectionMap">

					<div className="mlpMapIntro">
						{Translate.T("de.Tausende Ausflugsziele, Veranstaltungen, Workshops und Restaurants für Familien", "en.Thousands of Family Trip Destinations, Events, Workshops and Restaurants", "lp.mapintro")}
					</div>

					<div className="mlpMapContainer">
						<Map
							id="mlpMap"
							gestureHandling="cooperative"
							maxNumCategories={2}
							disableBottomClick={true}
							filterPhoto={true}
							disableFilterButton={true}
							contentMode={"tiny"}
							loadOnVisibility={false}
							centerLat={centerLat}
							centerLng={centerLng}
						/>
					</div>
				</div>

				<div className="mlpSection mlpSectionCta2">
					<div className="mlpMapIntro2">
						{Translate.T("de.Zuviele um selbst zu suchen?\nLENA findet die passenden Vorschläge für Dich!", "en.Too much to search for yourself?\nLENA finds the best options for you!", "lp.mapintro2")}
					</div>

					<Button color="default" onClick={this.OnSecondaryCTA}>
						{Translate.T("en.Receive Recommendation", "de.Vorschläge erhalten", "cover.buttons.cta2")}
					</Button>
				</div>

				{/* <div className="mlpSection mlpSectionClaim2">
					<div className="logocontainer" id="logocontainer">
						<img src="/assets/logo64.png" alt="logo" />
					</div>

					<div className="mktClaimText2">
						{Translate.T("en.Out of thousands of activities, events, workshops and restaurants, LENA helps you find the ones matching your family's interests and needs.",
							"de.LENA hilft Dir und Deiner Familie aus tausenden Ausflugszielen, Veranstaltungen, Workshops und Restaurants jene zu finden, die perfekt zu Euren Interessen und Bedürfnissen passen.", "landingpage.marketingclaim2")}
					</div>
				</div> */}

				<div className="mlpSection mlpSectionFeatures">
					<div className="mlpMapIntro mlpFeatureTitle">
						{Translate.T("de.LENA bietet noch mehr", "en.LENA has a lot to offer", "lp.features.title")}
					</div>

					<SwipeableViews
						index={this.featureIndex}
						className={"featureContainer"}
						onChangeIndex={this.OnFeatureSwiped}
						resistance={false}
						disabled={false}
					>
						{this.RenderFeature("swipe", this.iconChat,
							Translate.T("en.Swipe through suggestions", "de.Swipe durch die Vorschläge", "landingpage.feature.swipe.title"),
							Translate.T("en.LENA offers you great family activity options in an easy to use swipe-interface. " +
								"LENA matches the suggestions with your interests & needs and the weather.",
								"de.LENA präsentiert Dir mit Hilfe eines moderenen Swipe-Interfaces tolle Vorschläge. " +
								"LENA passt ihre Vorschläge an Deine Interessen & Bedürfnisse und das Wetter an.", "landingpage.feature.swipe.desc"))}

						{this.RenderFeature("explore", this.iconExplore,
							Translate.T("en.Explore all options", "de.Durchforste alle Möglichkeiten", "landingpage.feature.explore.title"),
							Translate.T("en.Search directly for activities, sort and filter by distance and category or explore the interactive map.",
								"de.Suche direkt nach Einträgen, sortiere und filtere nach Entfernung und Kategorie oder stöbere in einer interaktiven Landkarte.",
								"landingpage.feature.explore.desc"))}

						{this.RenderFeature("bookmarks", this.iconSaved,
							Translate.T("en.Bookmarks", "de.Lesezeichen", "landingpage.feature.bookmark.title"),
							Translate.T("en.Save your favourites in a convenient bookmark list and access them at any time.",
								"de.Speichere Dir Deine Lieblingseinträge in einer übersichtlichen Liste und rufe sie jederzeit wieder auf.",
								"landingpage.feature.bookmark.desc"))}

						{this.RenderFeature("profile", this.iconProfile,
							Translate.T("en.Personal Profile", "de.Persönliches Profil", "landingpage.feature.profile.title"),
							Translate.T("en.Create your personal profile and invite your family and friends.\n" +
									"Share your bookmarks and plan joint trips together.",
								"de.Lege Dir ein persönliches Profil an und lade Deine Familie und Freunde in Deinen Kreis ein.\n" +
									"Teilt Eure Lesezeichen und plant gemeinsame Ausflüge.",
								"landingpage.feature.profile.desc"))}

						{this.RenderFeature("community", this.iconCommunity,
							Translate.T("en.Be part of a community", "de.Werde Teil einer Community", "landingpage.feature.add.title"),
							Translate.T("en.Help other families by suggesting your insider tips near you. With the help of LENA's Artificial Intelligence it takes only seconds to create your own entries.",
								"de.Hilf anderen Familien mit Deinen Geheimtipps in Deiner Nähe. Mit Hilfe der künstlichen Intelligenz von LENA ist der Eintrag in ein paar Sekunden erstellt.",
								"landingpage.feature.add.desc"))}

						{/*this.RenderFeature("plan", tripIcon,
							Translate.T("en.Plan your trips", "de.Plane Deine Ausflüge", "landingpage.feature.trips.title"),
							Translate.T("en.It's a breeze to plan your day trips with LENA. Including automatic incorporation of weather forecasts and travel durations.",
								"de.Mit LENA kannst Du Eure Ausflüge kinderleicht planen. Wettervorhersagen und Reisezeiten werden automatisch berücksichtigt.",
						"landingpage.feature.trips.desc"))*/}
							
					</SwipeableViews>

					<Dots index={this.featureIndex} count={5} />
				</div>

				<div className="mlpSection mlpSectionFooter">
					<LandingPageFooter onInit={this.OnFooterInit} />
				</div>
			</div>
		);
	}
}

MobileLandingPage.propTypes = {
	classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(MobileLandingPage);