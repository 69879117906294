import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import Translate from '../Translate';
import AppState from '../AppState';
import ProfilePicture from './ProfilePicture';
import { Button } from 'material-ui';
import MoreHorizIcon from 'material-ui-icons/MoreHoriz';
import Menu, { MenuItem } from 'material-ui/Menu';
import AgeSelection from './AgeSelection';

const styles = {
};

@observer
class ContentSource extends React.Component
{
	menuAnchor;
	@observable isMenuVisible = false;

	componentWillUnmount()
	{
		AppState.instance.socialManager.Unsubscribe(this.props.username, this.OnProfileLoaded);
	}

	renderActionLabel(contentType)
	{
		if (this.props.username === "lena" && contentType === "comment")
			return null;
			
		if (contentType === "review")
			return (Translate.T("de.hat eine Bewertung geteilt", "en.shared a review", "content.action.review"));
		if (contentType === "experience")
			return (Translate.T("de.hat einen Ausflug geteilt", "en.shared a trip experience", "content.action.experience"));
		if (contentType === "image")
			return (Translate.T("de.hat ein Foto geteilt", "en.shared a photo", "content.action.photo"));
		if (contentType === "video")
			return (Translate.T("de.hat ein Video geteilt", "en.shared a video", "content.action.video"));
		if (contentType === "activity")
			return (Translate.T("de.hat dieses Ausflugsziel eingetragen", "en.shared this activity", "content.action.activity"));
		if (contentType === "checkin")
			return (Translate.T("de.befindet sich bei diesem Ausflugsziel", "en.checked in at this activity", "content.action.checkin"));
		if (contentType === "question")
			return (Translate.T("de.hat eine Frage gestellt", "en.asked a question", "content.action.question"));
		if (contentType === "comment")
			return (Translate.T("de.hat einen Kommentar geschrieben", "en.made a comment", "content.action.comment"));

		return null;
	}

	OnSourceClicked = () =>
	{
		const profile = AppState.instance.socialManager.GetProfile(this.props.username);
		if (!profile)
			return;

		AppState.instance.appContainer.OnProfileClicked(profile.username);
	}

	OnOpenMenu = (e) =>
	{
		this.menuAnchor = e.currentTarget;
		this.isMenuVisible = true;
		e.stopPropagation();
		e.preventDefault();
	}

	OnCloseMenu = () =>
	{
		this.isMenuVisible = false;
	}

	OnDeleteContent = (e) =>
	{
		this.OnCloseMenu();
		if (this.props.onDeleteContent)
			this.props.onDeleteContent(e);
	}

	OnProfileLoaded = () =>
	{
		this.forceUpdate();
	}

	renderAgeGroups(profile)
	{
		if (!profile.childrenAgeGroups)
			return null;
			
		var newFlags = [];
		const ageGroups = profile.childrenAgeGroups;
		newFlags.push(ageGroups && ageGroups.indexOf(0) >= 0);
		newFlags.push(ageGroups && ageGroups.indexOf(1) >= 0);
		newFlags.push(ageGroups && ageGroups.indexOf(2) >= 0);
		newFlags.push(ageGroups && ageGroups.indexOf(3) >= 0);
		newFlags.push(ageGroups && ageGroups.indexOf(4) >= 0);

		return (
			<AgeSelection
				className="csAgeGroups"
				editable={false}
				showIndicatorIcon={false}
				states={newFlags}
				renderMode="tiny"
			/>
		);
	}

	render()
	{
		//console.log("-------------- render: " + this.props.username);

		const profile = AppState.instance.socialManager.GetProfile(this.props.username, undefined, this.OnProfileLoaded);

		var isOwnContent = false;
		if (AppState.instance.IsLoggedIn())
			isOwnContent = this.props.username === AppState.instance.userProfile.username;

		return (
			<div className="contentSource">
				<ProfilePicture className="csPhoto" username={this.props.username} onClick={this.OnSourceClicked}/>

				{/* {profile && this.renderAgeGroups(profile)} */}

				<div className="csRight">
					<div className="csName" onClick={this.OnSourceClicked}>
						{profile && profile.firstName && <span className="csFirstName">{profile.firstName}</span>}
						{profile && profile.lastName && <span className="csLastName">{profile.lastName}</span>}
						{profile && !profile.firstName && !profile.lastName && profile.username && <span className="csUsername">{profile.username}</span>}
					</div>

					<div className="csAction">
						{this.renderActionLabel(this.props.contentType)}
					</div>

					<br/>

					{this.props.time && <div className="csTime">
						{this.props.time && <span className="cstime">{this.props.time}</span>}
					</div>}
				</div>

				{isOwnContent && <Button onClick={this.OnOpenMenu} className="csMenuMoreButton">
					<MoreHorizIcon />
				</Button>}

				<Menu
					anchorEl={this.menuAnchor}
					open={this.isMenuVisible}
					onClose={this.OnCloseMenu}
				>
					<MenuItem onClick={this.OnDeleteContent}>{Translate.T("en.Delete content", "de.Lösche Beitrag", "contentsource.delete.content")}</MenuItem>
				</Menu>
			</div>
		);
	}
}

ContentSource.propTypes = {
	classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(ContentSource);