import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import AppState from '../../AppState';
import PlaceIcon from 'material-ui-icons/Place';
import Utils from '../../Utils';
import CheckIcon from 'material-ui-icons/Check';
import PhotoCamera from 'material-ui-icons/PhotoCamera';
import Translate from '../../Translate';
import TooltipWrapper from '../TooltipWrapper';

const styles = theme => ({
});

@observer
class AdvCard extends React.Component
{
	advManager;

	constructor(props)
	{
		super(props);

		this.advManager = AppState.instance.advManager;
	}

	componentDidMount()
	{
		this.advManager.onConfigChanged.Add(this.OnConfigChanged);
		this.advManager.onInstancesChanged.Add(this.OnInstancesChanged);
	}

	componentWillUnmount()
	{
		this.advManager.onConfigChanged.Remove(this.OnConfigChanged);
		this.advManager.onInstancesChanged.Remove(this.OnInstancesChanged);
	}

	OnConfigChanged = () =>
	{
		this.forceUpdate();
	}

	OnInstancesChanged = () =>
	{
		this.forceUpdate();
	}

	OnCardClicked = (e) =>
	{
		e.stopPropagation();

		setTimeout(() => {
			if (this.props.onClick)
			{
				this.props.onClick(this.props.cardId, e);
			}
			else
			{
				// Default is to show card details
				AppState.instance.cardDetailsPopup.Show(this.props.cardId, undefined, this.props.context);
			}	
		}, 10);
	}

	render()
	{
		const cardId = this.props.cardId;
		if (cardId === undefined)
			return null;

		const card = this.advManager.GetCardById(cardId);
		if (!card)
			return null;
		var instances = this.advManager.GetCardInstances(cardId);

		const isNew = (this.props.showWhenNew && instances.length > 0) ? this.advManager.IsCardNew(cardId) : false;
		const showCheck = this.props.showCheckIfAvailable && instances.length > 0;
		const showSpawnTrigger = this.props.showSpawnTrigger !== false;
		const showValueHint = this.props.showValueHint === true && instances.length > 0;

		//if (this.props.showImage || instances.length > 0)
		//{
			const cardImages = this.advManager.GetCardImages(card.id);
			const cardImage = cardImages.length > 0 ? cardImages[0] : {};

			var imgUrl = cardImage.content;

			var size = this.props.imageSize || "_m";
				
			var gray = "";
			var disabled = false;
			if (this.props.disableUnavailable !== false && instances.length === 0)
			{
				gray = "_g";
				disabled = true;
			}

			//const ext = Utils.GetExtension(imgUrl);
			imgUrl = Utils.ChangeExtension(imgUrl, "");
			imgUrl += size + gray + ".jpg";//ext;

			return (
				<div
					className={"advcard instance" +
						(disabled ? " disabled":"") +
						(this.props.stretched ? " stretched":"") +
						(this.props.isFirst ? " first":"") +
						(this.props.isLast ? " last":"")}
					key={card.id}
					onClick={this.OnCardClicked}
				>
					<div
						className="cardImage"
						style={{backgroundImage: 'url("' + imgUrl + '")'}}
					/>
					{/* <div className="title">{card.title}</div>
					<div className="category">{card.category}</div> */}
					{this.props.showNumInstances && instances.length > 1 && <div className="numInstances"><div>{instances.length}</div></div>}

					{showCheck && <div className="check"><CheckIcon/></div>}

					{isNew && !showCheck && <div className="new">{Translate.T("de.NEU", "en.NEW", "advcard.new")}</div>}

					{showSpawnTrigger && card.spawnTriggers > 1 && instances.length === 0 &&
						<div className="spawnTrigger">
							{(card.spawnTriggers & 2) === 2 && <TooltipWrapper title={Translate.T("de.Gehe mit Deinem Smartphone und LENA zum markierten Punkt beim Ausflugsziel um die Karte zu erhalten",
								"en.Go with your smartphone and LENA to the marked position at the venue to receive this card", "advcard.tooltip.spawntrigger.gps")} ><PlaceIcon/></TooltipWrapper>}
							{(card.spawnTriggers & 4) === 4 && <TooltipWrapper title={Translate.T("de.Suche das reale Objekt beim Ausflugsziel und mache ein Foto um die Karte zu erhalten",
								"en.Take a photo of the real thing at the venue to receive this card", "advcard.tooltip.spawntrigger.gps")} ><PhotoCamera/></TooltipWrapper>}
							{(card.spawnTriggers & 16) === 16 &&
								<TooltipWrapper title={Translate.T("de.Vervollständige das Kartenset um die Karte zu erhalten",
									"en.Complete the card set to receive this card", "advcard.tooltip.spawntrigger.fullset")} >
									{Utils.RenderCardIcon(false, 24, 24, "#555")}
								</TooltipWrapper>}
						</div>}

					{showValueHint &&
						<div className="valuehint">
							<div className="v_adv">
								<TooltipWrapper title={Translate.T("de.Abenteuerlust", "en.Adventuresomeness", "advcard.tooltip.values.adventure")}><div className="ttcontent"/></TooltipWrapper>
							</div>

							<div className="v_love">
								<TooltipWrapper title={Translate.T("de.Liebe", "en.Love", "advcard.tooltip.values.love")}><div className="ttcontent"/></TooltipWrapper>
							</div>

							<div className="v_selfesteem">
								<TooltipWrapper title={Translate.T("de.Selbstwertgefühl", "en.Self-Esteem", "advcard.tooltip.values.selfesteem")}><div className="ttcontent"/></TooltipWrapper>								
							</div>

							<div className="v_humor">
								<TooltipWrapper title={Translate.T("de.Humor", "en.Humor", "advcard.tooltip.values.humor")}><div className="ttcontent"/></TooltipWrapper>								
							</div>
						</div>}

					{this.props.additionalElement}
				</div>
			);
		//}
		// else
		// {
		// 	return (
		// 		<div
		// 			className={"advcard" + (this.props.stretched ? " stretched":"") + (this.props.isFirst ? " first":"") + (this.props.isLast ? " last":"")} key={card.id}
		// 			onClick={this.OnCardClicked}
		// 		>
		// 			{this.props.showTitle && <div className="title">{card.title}</div>}
		// 			{this.props.showTitle && <div className="category">{card.category}</div>}
		// 			{this.props.showPlus && <AddIcon className="add"/>}
		// 			{isNew && !showCheck && <div className="new">{Translate.T("de.NEU", "en.NEW", "advcard.new")}</div>}
		// 			{this.props.additionalElement}
		// 		</div>
		// 	);
		// }
	}
}

AdvCard.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AdvCard);