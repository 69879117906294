import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import {observer} from 'mobx-react';
import SettingsPage from './SettingsPage';
import EditProfilePage from './EditProfilePage';
import {ToSPage, PPPage, ImprintPage} from './WebContentPage';
import ListMenu from '../components/ListMenu';
import Translate from '../Translate';
import AccountRequestPage from './AccountRequestPage';
import Button from 'material-ui/Button';
import AppState from '../AppState';
import ProfileComponent from '../components/ProfileComponent';
import {observable} from 'mobx';
import SocialList from '../components/SocialList';
import Timeline from '../components/Timeline';
import Utils from '../Utils';
import $ from 'jquery';
import PeopleSearch from '../components/PeopleSearch';
import ArrowBack from 'material-ui-icons/ArrowBack';

const styles = theme => ({
	button: {
		marginLeft: 'auto',
		marginRight: '0px',
		display: 'inherit',
		top: '-28px'
	},
	input: {
	  display: 'none',
	},
});

@observer
class ProfilePage extends React.Component
{
	@observable profileComponentUpdateCount = 0;

	//@observable showSocialList = false;
	socialList;
	//socialListName;
	@observable showIndex = 0;
	_isMounted = false;
	timeline;

	constructor(props)
	{
		super(props);
		AppState.instance.profilePage = this;

		AppState.instance.AddOnAuthDone(() =>
		{
			this.profileComponentUpdateCount++;
			if (this._isMounted)
				this.forceUpdate();
		});
	}

	componentDidMount()
	{
		this._isMounted = true;
	}
	
	componentWillUnmount()
	{
		this._isMounted = false;
	}

	OnActivate()
	{
		this.profileComponentUpdateCount++;

		if (!AppState.instance.IsLoggedIn())
			this.showIndex = 5;

		if (this.timeline)
			this.timeline.Refresh();
	}

	OnLoginButton = () =>
	{
		AccountRequestPage.Show();
	}

	OnShowSocialList = (list, listName) =>
	{
		//if (!AppState.instance.featureSocial)
		//	return;
		/*if (this.showSocialList && this.socialListName === listName)
		{
			this.showSocialList = false;
			return;
		}

		this.socialList = list;
		this.socialListName = listName;
		if (list)
			this.showSocialList = true;*/

		this.socialList = list;
		if (listName === "family")
			this.showIndex = 1;
		if (listName === "friends")
			this.showIndex = 2;
		if (listName === "followers")
			this.showIndex = 3;
		if (listName === "following")
			this.showIndex = 4;
	}

	OnShowSettings = () =>
	{
		this.socialList = undefined;
		//this.showSocialList = false;
		this.showIndex = 5;
	}

	OnShowTimeline = () =>
	{
		//if (!AppState.instance.featureSocial)
		//	return;

		this.socialList = undefined;
		//this.showSocialList = false;
		this.showIndex = 0;
	}

	OnSearchForPeople = () =>
	{
		if (AppState.instance.appInstance)
			AppState.instance.appInstance.OnTabbarChanged(undefined, AppState.TabIndexExplore);
		setTimeout(() => {
			$("#searchBar #searchInput").focus();	
		}, 1000);
	}

	OnTimelineInit = (instance) =>
	{
		this.timeline = instance;
		//instance.Refresh();
	}

	OnBack = () =>
	{
		if (AppState.instance.appInstance)
		{
			if (AppState.instance.previousMainTab)
				AppState.instance.appInstance.OnTabbarChanged(undefined, AppState.instance.previousMainTab);
			else
				AppState.instance.appInstance.OnTabbarChanged(undefined, AppState.TabIndexSwipe);
		}
	}

	render()
	{
		//Tracking.OnRender("ProfilePage");

		var s = {};
		if (AppState.instance.currentMainTab !== AppState.TabIndexProfile)
			s = {'display': 'none'};

		const isLoggedIn = AppState.instance.IsLoggedIn();

		var menuEntries = [];
		if (isLoggedIn)
			menuEntries.push({id: 0, key: "EditProfilePage", label: Translate.T('en.Edit profile', 'de.Profil bearbeiten', 'editprofile'), component: EditProfilePage});

		menuEntries.push({id: 1, key: "SettingsPage", label: Translate.T('en.Settings', 'de.Einstellungen', 'settings'), component: SettingsPage});
		//menuEntries.push({id: 1, label: 'Help', component: null});
		//menuEntries.push({id: 2, label: 'Feedback', component: null});
		menuEntries.push({id: 2, key: "TosPage", label: Translate.T('en.Terms of Service', 'de.Nutzungsbedingungen', 'tos'), component: ToSPage});
		menuEntries.push({id: 3, key: "PPPage", label: Translate.T('en.Privacy Policy', 'de.Datenschutzbestimmungen', 'privacypolicy'), component: PPPage});
		menuEntries.push({id: 4, key: "ImprintPage", label: Translate.T('en.Imprint', 'de.Impressum', 'imprint'), component: ImprintPage});

		return (
			<div
				id="profilePage"
				key="profilePage"
				className="profilePage"
				style={s}
			>
				{!AppState.instance.deviceInfo.desktop && <div className="header">
					<div className="backButton">
						<Button onClick={this.OnBack}>
							<ArrowBack />	
						</Button>
					</div>

					<div className="title">
						{Translate.T("de.Mein Profil", "en.My Profile", "profilepage.header.title")}
					</div>
				</div>}

				{isLoggedIn && <ProfileComponent
					username={(isLoggedIn && AppState.instance.loginDataChecked) ? AppState.instance.userProfile.username : undefined}
					token={this.profileComponentUpdateCount}
					onShowTimeline={isLoggedIn ? this.OnShowTimeline : undefined}
					onShowSocialList={this.OnShowSocialList}
					onShowSettings={this.OnShowSettings}
					activeIndex={this.showIndex}
				/>}

				{!isLoggedIn && <div className="bookmarkNoAccountWarning">
					<div className="bookmarkNoAccountWarningText">
						{Translate.T("en.Create your personal account to use all services of LENA.", "de.Erstelle Deinen persönlichen Account um alle Services von LENA zu verwenden.", "bookmarks.loginrequest")}
					</div>
					<Button color="default" raised onClick={this.OnLoginButton}>
						{Translate.T("en.Create Account", "de.Jetzt registrieren", "createaccount")}
					</Button>
				</div>}

				{this.showIndex === 0 && AppState.instance.GeneralDataLoadingEnabled() &&
					<Timeline isOwnUser={true} onInit={this.OnTimelineInit} />}

				{this.showIndex >= 1 && this.showIndex <= 4 && this.socialList && this.socialList.length > 0 && <SocialList usernames={this.socialList} />}
				{this.showIndex >= 1 && this.showIndex <= 4 && (!this.socialList || this.socialList.length === 0) &&
					<div className="emptySocialList">
						<div className="emptySocialListLogo">
							{Utils.RenderLogo(40, 40, "white", "#e31c46")}
						</div>
						<div className="emptySocialListTitle">
							{Translate.T("de.Deine Liste ist noch leer.", "en.Your list is still empty.", "social.list.empty")}
							<br/>
							{Translate.T("de.Suche nach Deiner Familie und Freunden", "en.Search for your family and friends", "social.list.empty.button")}
						</div>
						<PeopleSearch
							asFamily={this.showIndex === 1}
						/>
					</div>
				}

				{this.showIndex >= 1 && this.showIndex <= 2 && this.socialList && this.socialList.length > 0 &&
					<div className="findMoreSocialList">
						<div className="emptySocialListTitle">
							{Translate.T("de.Suche nach Deiner Familie und Freunden", "en.Search for your family and friends", "social.list.empty.button")}
						</div>
						<PeopleSearch
							asFamily={this.showIndex === 1}
						/>
					</div>
				}

				{this.showIndex === 5 && <ListMenu
					menuEntries={menuEntries}
				/>}
			</div>
		);
	}
}

ProfilePage.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProfilePage);