import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import Utils from '../Utils';
import {Link} from 'react-router-dom';
import Rating from './Rating';
import Translate from '../Translate';
import MediaPlayer from './MediaPlayer';
import RemoveRedEye from 'material-ui-icons/RemoveRedEye';
import PersonIcon from 'material-ui-icons/Person';
import SocialButton from './SocialButton';
import AppState from '../AppState';

const styles = theme => ({
	root: {
	  backgroundColor: theme.palette.background.paper,
	},
  });

@observer
class SearchResult extends React.Component
{
	OnCardClicked = (e) =>
	{
		if (this.props.onClick)
		{
			if (this.props.onClick(this.props.contentData, e) !== true)
			{
				e.stopPropagation();
				e.preventDefault();
			}
		}
	}

	render()
	{
		//console.log("render searchresult: " + this.props.contentData.title);
		var sr = this.props.contentData;
		const columnMode = this.props.columnMode;

		var isPlaceholder = false;
		if (sr.id === "")
		{
			isPlaceholder = true;
		}

		if (sr.type === 'profile' && !sr.username && sr.desc && sr.desc.length > 0)
		{
			const profile = JSON.parse(sr.desc);
			if (profile)
			{
				sr.username = profile.username;
				AppState.instance.socialManager.UpdateProfile(profile);
			}
		}

		// var desc = sr.desc;
		// if (desc === undefined || desc === null || desc === "")
		// 	desc = sr.title;

		//var maxDescLines = columnMode ? 2 : 3;
		//var maxDescLines = 3;

		// var additionalStyle = null;
		// if (columnMode)
		// {
		// 	additionalStyle = {'width': 'calc(100% - 48px)', 'max-width': '400px'};
		// }

		// var descText = Utils.TrimTextToNumLines(desc, "searchResultCardDesc" + (columnMode ? " searchResultCardDescColumn" : ""), maxDescLines, "...", additionalStyle);
		//var titleText = columnMode ? sr.title : Utils.TrimTextToWidth(sr.title, "searchResultCardTitle", 220, "...");
		var titleText = sr.title;// : Utils.TrimTextToMaxChars(sr.title, "searchResultCardTitle", 220, "...");
		
		var infoLine = "";
		if (sr.address)
		{
			infoLine = Utils.ExtractCity(sr.address);
			if (infoLine === sr.address)
				infoLine = "";
		}
		if (this.props.fullAddress)
			infoLine = Utils.RemoveCountry(sr.address);

		const catNames = sr.categories;
		if (catNames !== undefined && catNames !== null)
		{
			for (var i = 0; i < catNames.length && i < 2; ++i)
			{
				if (infoLine.length === 0)
					infoLine = catNames[i];
				else
					infoLine += " • " + catNames[i];
			}
		}

		if (sr.type === 'website')
		{
			var _url = sr.url;
			_url = Utils.ReplaceAll(_url, "https://", "");
			_url = Utils.ReplaceAll(_url, "http://", "");

			if (infoLine.length === 0)
				infoLine = _url;
			else
				infoLine += " • " + _url;
		}

		var profile;
		if (sr.type === "profile" && sr.username)
		{
			profile = AppState.instance.socialManager.GetProfile(sr.username);

			if (profile && profile.profilePicture)
				sr.img = profile.profilePicture;

			if (profile && !sr.title)
			{
				if (profile.firstName && profile.lastName)
					sr.title = profile.firstName + " " + profile.lastName;
				else if (profile.firstName)
					sr.title = profile.firstName;
				else if (profile.lastName)
					sr.title = profile.lastName;
					
				titleText = sr.title;
			}
		}

		if (sr.type === "profile" && infoLine.length === 0)
		{
			if (profile)
			{
				if (profile.district)
					infoLine = profile.district;
				else if (profile.state)
					infoLine = profile.state;
				else if (profile.country)
					infoLine = profile.country;
			}
		}

		var isBookmarked = false;
		if (sr.type === 'content')
		{
			//TODO:
			isBookmarked = false;
		}

		Utils.TestImageUrl(sr.img);

		var className = "searchResultCard" + (columnMode ? (" searchResultCardColumn" +
			(this.props.isLast ? " searchResultCardColumnLast" : "") +
			(this.props.isFirst ? " searchResultCardColumnFirst" : "")) : "");
		if (sr.type === 'content' || isPlaceholder)
			className += " searchResultCardContent";
		if (sr.type === 'profile')
			className += " searchResultCardProfile";

		if (columnMode)
		{
			className += " searchResultCardColumn";
			if (this.props.isFirst)
				className += " searchResultCardColumnFirst";
			if (this.props.isLast)
				className += " searchResultCardColumnLast";
		}
		else
		{
			if (this.props.isFirst)
				className += " searchResultCardFirst";
			if (this.props.isLast)
				className += " searchResultCardLast";
		}

		var imgElement;
		if (sr.img || sr.type === "profile")
		{
			if (sr.type === "profile" && sr.img)
			{
				imgElement = (
					<div
						key={"searchResultImage_" + sr.id}
						className={"searchResultImage searchResultImageSmall" + (columnMode ? " searchResultImageColumn" : "")}
						style={{backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: 'url("' + Utils.FixHttpImages(sr.img) + '")'}}
					/>
				);
			}
			else if (sr.type === "profile" && !sr.img)
			{
				imgElement = (
					<div
						key={"searchResultImage_" + sr.id}
						className={"searchResultImage searchResultImageSmall emptyProfilePhoto" + (columnMode ? " searchResultImageColumn" : "")}
					>
						<PersonIcon />
					</div>
				);
			}
			else if (sr.type === 'content')
			{
				var mediaData = {};
				mediaData.activity = {};
				mediaData.activity.id = sr.id;
				mediaData.activity.pictureUrls = [sr.img];

				imgElement = (
					<MediaPlayer
						data={mediaData}
						className={"searchResultImage" + (columnMode ? " searchResultImageColumn" : "") +
							((sr.type !== 'content' && !isPlaceholder) ? " searchResultImageSmall" : "") +
							(isPlaceholder ? " searchResultImagePH" : "")}
						interactive={false}
						defaultContentHeight={60}
					/>
				);
			}
			else
			{
				imgElement = (
					<div
						key={"searchResultImage_" + sr.id}
						className={"searchResultImage" + (columnMode ? " searchResultImageColumn" : "") +
							((sr.type !== 'content' && !isPlaceholder) ? " searchResultImageSmall" : "") +
							(isPlaceholder ? " searchResultImagePH" : "")
						}
						alt="TitleImage"
						style={{backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: 'url("' + Utils.FixHttpImages(sr.img) + '")'}}
					/>
				);
			}
		}

		var Tag = Link;
		var tagProps = {to: sr.url};
		if (this.props.onClick)
		{
			Tag = 'div';
			//tagProps = {onClick: this.props.onClick};
			tagProps = {onClick: this.OnCardClicked};
		}

		return (
			
			<div
				className={className}
				key={"searchResultCard_" + sr.id}
				id={"searchResultCard_" + sr.id}
				data-activityid={sr.id}
				onClick={this.OnCardClicked}
			>
				<Tag {...tagProps} className="contentLink">

					{imgElement}

					<div className={(sr.type === 'content' || sr.type === 'googlecontent' || sr.type === 'osmcontent') ? "searchResultTextContainerContent" : "searchResultTextContainer"}>
						<div className={"searchResultCardTitle" + (columnMode ? " searchResultCardTitleColumn" : "") +
							(isPlaceholder ? " searchResultCardTitlePH" : "")}>
							{titleText}
						</div>
						{infoLine && infoLine.length > 0 && <div className={"searchResultCardInfoLine" + (columnMode ? " searchResultCardInfoLineColumn" : "")}>
							{infoLine}
						</div>}
						{sr.type === 'googlecontent' && <div className="googleSearchResult">
							<img alt="powered by google" src="/assets/powered_by_google_on_white.png" />
						</div>}

						{sr.liveData && this.props.showLiveData && <div className="searchResultLiveData">
							<div className="ratingSubContainer">
								<Rating value={sr.rating} />
							</div>
							<div className="detailLiveDataItem detailLiveDataViewers">
								<RemoveRedEye />
								<span className="label">{sr.liveData.viewers._1m}<span className="viewersDuration">{Translate.T("en.Month", "de.Monat", "viewers.thismonth")}</span></span>
							</div>
							<div className="detailLiveDataItem detailLiveDataViewers">
								<RemoveRedEye />
								<span className="label">{sr.liveData.viewers._total}<span className="viewersDuration">{Translate.T("en.Total", "de.Total", "viewers.total")}</span></span>
							</div>
						</div>}
					</div>

					{sr.username && <SocialButton username={sr.username} />}

					{isBookmarked === true && <div className="bookmarkHeartIcon">
						{Utils.RenderStarIcon(24, 24, "white", "#e31c46", 2.5)}
					</div>}

					{this.props.children}
				</Tag>
			</div>
		);
	}
}

SearchResult.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	contentData: PropTypes.object.isRequired,
	columnMode: PropTypes.bool.isRequired,
	isLast: PropTypes.bool.isRequired,
};

export default withStyles(styles, { withTheme: true })(SearchResult);