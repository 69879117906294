import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import {observer} from 'mobx-react';
import Button from 'material-ui/Button';
import Translate from '../Translate';
import Tracking from '../Tracking';
import AppState from '../AppState';
import Utils from '../Utils';
import RestApi from '../RestApi';
import { observable } from 'mobx';
import AccountRequestPage from '../pages/AccountRequestPage';
import PersonIcon from 'material-ui-icons/Person';
import OpenInNew from 'material-ui-icons/OpenInNew';
import SocialButton from './SocialButton';
import FriendRequests from './FriendRequests';
import Timeline from './Timeline';
import SettingsIcon from 'material-ui-icons/Settings';
import AgeSelection from './AgeSelection';
import FullscreenMedia from './FullscreenMedia';
import SocialLists from './desktop/SocialLists';

const styles = theme => ({
});

@observer
class ProfileComponent extends React.Component
{
	ageButtonStates = [];
	username;
	@observable isOwnProfile;
	@observable iamFollowing;
	@observable isFollowingMe;
	@observable isFriend;
	@observable contentProfileElements;
	@observable showLoginError = false;
	pictureContainerId;

	constructor(props)
	{
		super(props);

		this.pictureContainerId = Utils.GenerateRandomId(32);

		if (this.props.username)
		{
			this.username = this.props.username;
		}
		else
		{
			var locInfo = Utils.ExtractLocationInfo(window.location.pathname);
			this.username = locInfo.username;
		}
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps.username !== this.props.username ||
			prevProps.token !== this.props.token)
		{
			if (this.props.username)
			{
				this.username = this.props.username;
				this.UpdateData();
			}
		}
	}

	componentDidMount()
	{
		AppState.instance.socialManager.onProfilesChanged.Add(this.OnProfilesChanged);
		//this.UpdateData();
		AppState.instance.AddOnAuthDone(this.OnAuthDone);
	}

	componentWillUnmount()
	{
		AppState.instance.RemoveOnAuthDone(this.OnAuthDone);
		AppState.instance.socialManager.onProfilesChanged.Remove(this.OnProfilesChanged);
	}

	OnLayoutChanged = () =>
	{
		if (this.props.onLayoutChanged)
			this.props.onLayoutChanged();
	}

	OnAuthDone = () =>
	{
		this.UpdateData();
	}

	OnProfilesChanged = () =>
	{
		const profile = AppState.instance.socialManager.GetProfile(this.username);
		this.OnProfileReceived(profile);
		this.forceUpdate();
	}

	OnProfileReceived(profile)
	{
		if (profile)
		{
			this.isOwnProfile = profile.isOwnProfile;
			this.iamFollowing = profile.iamFollowing;
			this.isFollowingMe = profile.isFollowingMe;
			this.isFriend = profile.isFriend;
			this.contentProfileElements = this.RenderContentProfile();
			this.showLoginError = false;
		}
		else
		{
			this.isOwnProfile = undefined;
			this.iamFollowing = undefined;
			this.isFollowingMe = undefined;
			this.isFriend = undefined;
			this.contentProfileElements = undefined;
			this.showLoginError = true;
		}
	}

	UpdateData = () =>
	{
		this.forceUpdate();
	}

	static OnThrowCard(direction)
	{
		console.log("THROW: " + direction);
		if (window.location.pathname.indexOf("/profile") >= 0)
			AppState.instance.appContainer.context.router.history.push("/");
	}

	OnContentProfileClicked = (dimId, positive) => (e) =>
	{
		RestApi.SendRequest("/increasecontentprofile", {
			dimensionId: dimId,
			axis: positive,
			incr: 30
		}).then((profile) =>
		{
			this.OnProfileReceived(profile);
		});
	}

	RenderContentProfile()
	{
		var result = [];

		const profile = AppState.instance.socialManager.GetProfile(this.username);

		if (profile.contentProfile && profile.contentProfile.contentProfile.dimensions)
		{
			for (var i = 0; i < profile.contentProfile.contentProfile.dimensions.length; ++i)
			{
				var dim = profile.contentProfile.contentProfile.dimensions[i];
				//if (dim.points[0] !== 0 || dim.points[1] !== 0)
				//{
					var dimName = profile.contentProfile.dimensionNames[dim.id];

					result.push(<div className="ppDimension" key={i}>
						<div className="ppDimensionTitle">{dimName + " (" + (dim.counts[0] + dim.counts[1]) + ")"}</div>
						<div className="ppDimensionNumbers">
							<div className={"ppDimensionNeg" + (dim.points[0] === 0 ? " null":"")} onClick={this.OnContentProfileClicked(dim.id, 0)}>
								{Math.round(dim.points[0])}</div>
							<div className={"ppDimensionPos" + (dim.points[1] === 0 ? " null":"")} onClick={this.OnContentProfileClicked(dim.id, 1)}>
								{Math.round(dim.points[1])}</div>
						</div>
					</div>);
				//}
			}
		}

		return result;
	}

	ResetContentProfile = () =>
	{
		AppState.instance.screenMessage.ShowAlert("Profile Reset", "Do you really want to reset your profile?",
			[{label: "No", value: 0}, {label: "Yes", value: 1}], false, (resultButton) =>
		{
			if (resultButton && resultButton.value)
			{
				RestApi.SendRequest("/resetcontentprofile", {}).then((profile) =>
				{
					this.OnProfileReceived(profile);
				});
			}
		});
	}

	DebugProfile = () =>
	{
		const url = RestApi.endpoint + "/debugcontentprofile";
		window.open(url, "_blank");
	}

	OnLoginButton = () =>
	{
		AccountRequestPage.Show();
	}

	OpenWeblink = (url) => (e) =>
	{
		e.stopPropagation();

		Utils.OpenExternalLink(url);
	}

	OnShowSocialList = (listName) => (e) =>
	{
		const profile = AppState.instance.socialManager.GetProfile(this.username);
		const list = profile[listName];

		//if (!list || list.length === 0)
		//	return;

		if (this.props.onShowSocialList)
			this.props.onShowSocialList(list, listName);

		this.OnLayoutChanged();
	}

	OnAgeButtonClicked = (e, ageGroupIdx) =>
	{
		this.ageButtonStates[ageGroupIdx] = !this.ageButtonStates[ageGroupIdx];

		AppState.instance.userProfile.SetAgeGroups(this.ageButtonStates, () =>
		{
			AppState.instance.OnFilterChanged();
			this.ageButtonStates = [];
			AppState.instance.socialManager.LoadProfile(this.username);
		});
	}

	renderAgeGroups(profile)
	{
		if (profile.username === "lena")
			return null;
			
		const editable = this.isOwnProfile;

		if (this.ageButtonStates.length === 0)
		{
			var newFlags = [];
			const ageGroups = profile.childrenAgeGroups;
			newFlags.push(ageGroups && ageGroups.indexOf(0) >= 0);
			newFlags.push(ageGroups && ageGroups.indexOf(1) >= 0);
			newFlags.push(ageGroups && ageGroups.indexOf(2) >= 0);
			newFlags.push(ageGroups && ageGroups.indexOf(3) >= 0);
			newFlags.push(ageGroups && ageGroups.indexOf(4) >= 0);

			this.ageButtonStates = newFlags;
		}

		return (
			<AgeSelection
				className="profileAgeGroups"
				editable={editable}
				showIndicatorIcon={true}
				onClick={editable ? this.OnAgeButtonClicked : undefined}
				states={this.ageButtonStates}
			/>
		);
	}

	OnPhotoClicked = (url) => () =>
	{
		if (!url || url.length === 0)
			return;

		var items = [];
		var imageData = AppState.instance.imageManager.Get(url);

		if (!imageData && url.indexOf(".fbsbx.com") >= 0)
		{
			imageData = {
				width: 200,
				height: 200,
				url: url
			};
		}

		if (imageData)
		{
			if (Utils.IsVideoUrl(url))
			{
				const posterUrl = Utils.GetVideoPoster(url);
				const id = Utils.GenerateRandomId(32);

				items.push({
					videosrc: url,
					videoId: id,
					posterUrl: posterUrl,
					w: imageData ? imageData.width : window.innerWidth,
					h: imageData ? imageData.height : Math.round(window.innerWidth * 9 / 16),
					videoW: imageData ? imageData.width : window.innerWidth,
					videoH: imageData ? imageData.height : Math.round(window.innerWidth * 9 / 16),
				});
			}
			else
			{
				var fixedUrl = Utils.FixHttpImages(url);
				fixedUrl = Utils.ScaleDownCloudinaryImages(fixedUrl);

				items.push({
					msrc: fixedUrl,
					src: Utils.FixHttpImages(imageData.url),
					w: imageData.width,
					h: imageData.height,
				});
			}
		}

		if (items.length === 0)
			return; // No valid image data found in ImageManager

		FullscreenMedia.Show(items, 0, "#" + this.pictureContainerId);
	}

	render()
	{
		Tracking.OnRender("PublicComponent");

		// var backgroundColorStyle = {};
		// var colorStyle = {};
		// if (profile && profile.profileColor)
		// {
		// 	backgroundColorStyle = {backgroundColor: profile.profileColor};
		// 	colorStyle = {color: profile.profileColor};
		// }

		var websiteText;
		const profile = AppState.instance.socialManager.GetProfile(this.username);
		if (profile && profile.website)
		{
			websiteText = profile.website;
			websiteText = websiteText.replace("http://", "");
			websiteText = websiteText.replace("https://", "");
		}

		return (
			<div
				id="profileComponent"
				key="profileComponent"
				className="profileComponent"
			>
				<div className="ppHeader" />

				<div className="ppContent">
					{profile && this.props.showName !== false && <div className="ppName">
						{profile.firstName && <div className="ppFirstName">{profile.firstName}</div>}
						{profile.lastName && <div className="ppLastName">{profile.lastName}</div>}
					</div>}

					{profile && profile.website && <div className="ppWebsite" onClick={this.OpenWeblink(profile.website)}>
						{websiteText} <OpenInNew style={{width: '14px', height: '14px'}} />
					</div>}

					{!this.isOwnProfile && profile &&
						<SocialButton username={profile.username} />
					}

					{profile && this.renderAgeGroups(profile)}

					{profile && !this.props.showSocialLists && !this.props.disableNumbers && <div className="ppNumbers">
						{/* <div className="ppNumbersItem">
							<div className="ppNumbersItemValue">
								{profile.heartContainers}
							</div>
							<div className="ppNumbersItemLabel">
								{Translate.T("en.Hearts", "de.Herzen", "profile.heartcontainers")}
							</div>
						</div>*/}

						{this.props.onShowTimeline && <div
							className={"ppNumbersItem ppTimeline" + (this.props.activeIndex === 0 ? " active": " inactive")}
							onClick={this.props.onShowTimeline}>
							<div className="ppNumbersItemValue">
								{Utils.RenderListIcon(20, 20, "#e31c46", "none", 2.0, 2.0)}
							</div>
							<div className="ppNumbersItemLabel">
								{/*Translate.T("en.Settings", "de.Einstellungen", "profile.settings")*/}
							</div>
						</div>}

						<div
							className={"ppNumbersItem" + (this.props.activeIndex === 1 ? " active": " inactive")}
							onClick={this.OnShowSocialList("family")}>
							<div className="ppNumbersItemValue">
								{profile.numFamily}
							</div>
							<div className="ppNumbersItemLabel">
								{Translate.T("en.Family", "de.Familie", "profile.family")}
							</div>
						</div>

						<div
							className={"ppNumbersItem" + (this.props.activeIndex === 2 ? " active": " inactive")}
							onClick={this.OnShowSocialList("friends")}>
							<div className="ppNumbersItemValue">
								{profile.numFriends}
							</div>
							<div className="ppNumbersItemLabel">
								{Translate.T("en.Friends", "de.Freunde", "profile.friends")}
							</div>
						</div>

						<div
							className={"ppNumbersItem" + (this.props.activeIndex === 3 ? " active": " inactive")}
							onClick={this.OnShowSocialList("followers")}>
							<div className="ppNumbersItemValue">
								{profile.numFollowers}
							</div>
							<div className="ppNumbersItemLabel">
								{Translate.T("en.Followers", "de.Followers", "profile.followers")}
							</div>
						</div>

						<div
							className={"ppNumbersItem" + (this.props.activeIndex === 4 ? " active": " inactive")}
							onClick={this.OnShowSocialList("following")}>
							<div className="ppNumbersItemValue">
								{profile.numFollowing}
							</div>
							<div className="ppNumbersItemLabel">
								{Translate.T("en.Following", "de.Following", "profile.following")}
							</div>
						</div>

						{this.props.onShowSettings && <div
							className={"ppNumbersItem ppSettings" + (this.props.activeIndex === 5 ? " active": " inactive")}
							onClick={this.props.onShowSettings}>
							<div className="ppNumbersItemValue">
								<SettingsIcon />
							</div>
							<div className="ppNumbersItemLabel">
								{/*Translate.T("en.Settings", "de.Einstellungen", "profile.settings")*/}
							</div>
						</div>}
						
					</div>}

					{/*this.isFollowingMe && <div className="ppIsFollowingMe">
						<Check />
						{Translate.T('en.{0} is following you', 'de.{0} folgt dir', 'account.nameisfollowingme', [profile.firstName])}
					</div>*/}


					{/*!AppState.instance.isProduction && this.contentProfileElements && this.isOwnProfile && <div className="ppContentProfile">
						{this.contentProfileElements}

						<div className="ppContentProfileReset">
							<Button onClick={this.ResetContentProfile} aria-label="Reset Profile">
								<Icon>clear_all</Icon>
								{Translate.T('en.Reset profile', 'de.Profil zurücksetzen', 'account.button.resetcontentprofile')}
							</Button>
						</div>

						<div className="ppContentProfileReset">
							<Button onClick={this.DebugProfile} aria-label="Debug Profile">
								<Icon>fingerprint</Icon>
								{Translate.T('en.Debug profile', 'de.Debug Profil', 'account.button.debugprofile')}
							</Button>
						</div>
					</div>*/}

					{profile && profile.profilePicture && this.props.showPhoto !== false &&
					<div
						id={this.pictureContainerId}
						onClick={this.OnPhotoClicked(profile.profilePicture)}
						className={"ppPicture " + profile.username} style={{backgroundImage: 'url("' + profile.profilePicture + '")'}}
					/>}
					
					{(!profile || !profile.profilePicture) && this.props.showPhoto !== false &&
					<div className="ppPicture"><PersonIcon /></div>}
				</div>

				{this.isOwnProfile && <FriendRequests />}

				{profile && !this.isOwnProfile && !this.props.disableTimeline &&
					<Timeline
						username={profile.username}
						onLayoutChanged={this.OnLayoutChanged}
						pullToRefreshDisabled={true}
						onMediaSwitching={this.props.onMediaSwitching}
						onMediaTouchEnd={this.props.onMediaTouchEnd}
						onMediaTouchStart={this.props.onMediaTouchStart}
					/>}

				{this.showLoginError && <div className="ppLoginError">
					<div className="bookmarkNoAccountWarning">
						<div className="bookmarkNoAccountWarningText">
							{Translate.T("en.You are not logged in. To see the profile of others please login with your account.",
								"de.Du bist nicht eingeloggt. Um das Profile von anderen zu sehen, logge Dich bitte ein.", "profile.loginrequest")}
						</div>
						<Button color="default" raised onClick={this.OnLoginButton}>
							{Translate.T("en.Login", "de.Einloggen", "login")}
						</Button>
					</div>
				</div>}

				{profile && this.props.showSocialLists && <SocialLists
					username={profile.username}
					onListSelected={this.props.onSocialListSelected}
				/>}
			</div>
		);
	}
}

ProfileComponent.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProfileComponent);