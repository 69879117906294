import React from 'react';
import RestApi from './RestApi';
import Utils from './Utils';
import AppState from './AppState';
import $ from 'jquery';
var ReactGA = require('react-ga');

export default class Tracking extends React.Component
{
	static appState;
	static lastEventSent;
	static sessionId;
	static currentPage = "";
	static currentPageView = "";
	static queue = [];
	static hjDisabled = false;

	static visibleItemsQueue = {};
	static flushVisibleItemsTimer;

	static Init(appState)
	{
		Tracking.appState = appState;

		if (document.cookie.indexOf('hj-disable=true') > -1)
		{
			Tracking.hjDisabled = true;
		}
		
		if (appState.userProfile.id === undefined)
		{
			// Create a dummy user for tracking
			appState.userProfile.id = "anonym" + Utils.GenerateRandomStr(32);
			appState.userProfile.userStartDate = new Date();
			appState.userProfile.Save();

			/*Tracking.SendEvent("newUser").then((r) =>
			{
				Tracking.SendEvent("sessionStart").then((sr) =>
				{
					Tracking.ProcessQueue();
				});
			});*/
		}
		/*else
		{
			Tracking.SendEvent("sessionStart").then((sr) =>
			{
				Tracking.ProcessQueue();
			});
		}*/

		Tracking.ProcessQueue();

		AppState.instance.AddOnScreenScrolled(Tracking.TrackVisibleItems);
	}

	static ProcessQueue()
	{
		for (var i = 0; i < Tracking.queue.length; ++i)
		{
			const e = Tracking.queue[i];
			Tracking.SendEvent(e.name, e.params);
		}
		Tracking.queue = [];
	}

	/*static CheckKeepAlive()
	{
		var now = new Date();
		var diffSeconds = (now - Tracking.lastEventSent) / 1000;
		//console.log("DIFF: " + diffSeconds);
		if (diffSeconds >= 30)
		{
			Tracking.SendEvent("keepAlive")
			.then( () =>
			{
				setTimeout(Tracking.CheckKeepAlive, 1000);
			})
			.catch( () =>
			{
				Tracking.lastEventSent = new Date();
				setTimeout(Tracking.CheckKeepAlive, 1000);
			});
		}
		else
		{
			setTimeout(Tracking.CheckKeepAlive, 1000);
		}
	}*/

	static OnError(error)
	{
		//console.log('Error: ' + error.msg);

		if (error.message !== undefined || error.stack !== undefined)
		{
			Tracking.SendEvent("error", {
				message: error.message,
				stack: error.stack
			});

			ReactGA.exception({
				description: error.message,
				fatal: false
			});
		}
		else
		{
			if (typeof error === 'string')
			{
				Tracking.SendEvent("error", {message: error});
				ReactGA.exception({
					description: error,
					fatal: false
				});
			}
			else
				Tracking.SendEvent("error", error);
		}
	}

	static OnRender(pageName)
	{
		/*if (pageName !== Tracking.currentPage)
		{
			Tracking.currentPage = pageName;

			Tracking.SendEvent("pageRender", {
				url: "/" + pageName
			});
		}*/
	}

	static OnPageView(pageName)
	{
		if (pageName !== Tracking.currentPageView)
		{
			//console.log("PAGE VIEW: " + pageName);

			Tracking.currentPageView = pageName;

			Tracking.SendEvent("pageView", {
				url: "/" + pageName
			});
		}
	}

	static SendGoogleEvent(category, action, label, value)
	{
		if (AppState.instance.isProduction)
			ReactGA.event({
				category: category,
				action: action,
				label: label,
				value: value
			});

		Tracking.SendEvent(action, {
			category: category,
			label: label,
			value: value
		});
	}

	static SendEvent(eventName, eventParameters)
	{
		if (Tracking.appState === undefined)
		{
			Tracking.queue.push({name: eventName, params: eventParameters});
			return new Promise((resolve, reject) => { return resolve(undefined);});
		}

		//console.log("EVENT: " + eventName);

		if (eventName === "pageView" && AppState.instance.isProduction)
		{
			ReactGA.set({ page: eventParameters.url });
			ReactGA.pageview(eventParameters.url);

			if (!Tracking.hjDisabled && window.hj)
				window.hj('vpv', eventParameters.url);
		}

		var activityId;
		var contentId;
		var latitude;
		var longitude;
		if (eventParameters)
		{
			if (eventParameters.activityId)
			{
				activityId = eventParameters.activityId;
				delete eventParameters.activityId;
			}
			if (eventParameters.contentId)
			{
				contentId = eventParameters.contentId;
				delete eventParameters.contentId;
			}
			if (eventParameters.latitude)
			{
				latitude = eventParameters.latitude;
				delete eventParameters.latitude;
			}
			if (eventParameters.longitude)
			{
				longitude = eventParameters.longitude;
				delete eventParameters.longitude;
			}
		}

		if (!eventParameters)
			eventParameters = {};

		const di = AppState.instance.deviceInfo;

		if (di.desktop)
			eventParameters.deviceType = "desktop";
		else if (di.tablet)
			eventParameters.deviceType = "tablet";
		else
			eventParameters.deviceType = "mobile";

		if (di.samsungBrowser)
			eventParameters.browser = "Samsung Internet";
		else if (di.safari)
			eventParameters.browser = "Safari";
		else if (di.edge)
			eventParameters.browser = "Edge";
		else if (di.opera)
			eventParameters.browser = "Opera";
		else if (di.chrome)
			eventParameters.browser = "Chrome";
		else if (di.ie)
			eventParameters.browser = "IE";
		else if (di.firefox)
			eventParameters.browser = "Firefox";
		else
			eventParameters.browser = "Unknown";

		eventParameters.screen = window.screen.width + 'x' + window.screen.height;

		//eventParameters.device
		//eventParameters.browserVersion
		eventParameters.browserMode = Utils.IsStandaloneMode() ? "standalone" : undefined;
		eventParameters.os = di.os;
		//eventParameters.osVersion

		if (AppState.instance.GetGpsPosition())
		{
			const loc = AppState.instance.GetGpsPosition();
			eventParameters.geoLat = loc.latitude;
			eventParameters.geoLon = loc.longitude;
		}

		var event = {
			Name: eventName,
			Parameters: eventParameters,
			SessionId: Tracking.sessionId,
			UserId: Tracking.appState.userProfile.id,
			Platform: navigator.userAgent,
			City: Tracking.appState.ipLocation.address,
			CountryCode: Tracking.appState.ipLocation.country,
			UserStartDate: Tracking.appState.userProfile.userStartDate,
			activityId: activityId,
			contentId: contentId,
			latitude: latitude,
			longitude: longitude
		};

		Tracking.lastEventSent = new Date();
		return RestApi.SendEvent(event)
			.then((newSessionId) =>
			{
				if (newSessionId && newSessionId.length > 5)
				{
					Tracking.sessionId = newSessionId;
				}
				return new Promise((resolve, reject) => { return resolve(undefined);});
			});
	}

	static FlushVisibleItemsTracking()
	{
		var eventParams = {};
		var found = false;
		for (var key in Tracking.visibleItemsQueue)
		{
			eventParams[key] = Tracking.visibleItemsQueue[key];
			found = true;
		}
		Tracking.visibleItemsQueue = {};

		if (found)
			Tracking.SendEvent("contentVisibility", eventParams);
	}

	static TrackVisibleItems = () =>
	{
		// Get all elements which should be tracked
		var elements = [];

		var topSelector;
		if (AppState.instance.isShowingDetailPage > 0)
		{
			topSelector = ".detailPage"
		}
		else if (AppState.instance.currentMainTab === AppState.TabIndexSwipe)
			topSelector = "#swipePage";
		
		if (!topSelector)
			return;

		$(topSelector + ' [data-tkg]').each(function()
		{
			// check if group is inside some known "invisibility" areas
			const isInvisible = $(this).parents(".backgroundCards").length > 0;
			if (isInvisible)
				return;

			// tracking group
			var groupKey = $(this).attr('data-tkg');
			
			$(this).find("[data-tk]").each(function()
			{
				var tk = $(this).attr('data-tk');

				const key = groupKey + "." + tk;

				elements.push({
					dom: this,
					key: key
				});
			})
		});

		// Check if they are visible (in viewport and on top)
		for (var i = 0; i < elements.length; ++i)
		{
			var element = elements[i];

			var rect = element.dom.getBoundingClientRect();
			const containmentRect = {
				top: 0,
				left: 0,
				bottom: window.innerHeight || document.documentElement.clientHeight,
				right: window.innerWidth || document.documentElement.clientWidth
			};

			const distance = Utils.GetDistanceFromContainer(rect, containmentRect);
			var isVisible = distance <= 0;
			if (isVisible)
			{
				element.visible = Utils.CalcPercentInViewportYRect(rect);

				if (element.visible > 0.05)
				{
					const oldValue = Tracking.visibleItemsQueue[element.key];
					if (oldValue === undefined)
						Tracking.visibleItemsQueue[element.key] = element.visible;
					else if (element.visible > oldValue)
						Tracking.visibleItemsQueue[element.key] = element.visible;
				}
			}
		}

		// Collect and report max values every N seconds
		Tracking.flushVisibleItemsTimer = Utils.SetTimer(Tracking.flushVisibleItemsTimer, 1000,
			Tracking.FlushVisibleItemsTracking, 200);
	}
}
