import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import ContentGrid from './ContentGrid';
import ContentCard from './ContentCard';
import Translate from '../Translate';
import {observable} from 'mobx';
import $ from 'jquery';
import Utils from '../Utils';


const styles = theme => ({	
});


@observer
class MapContent extends React.Component
{
	@observable selectedContentId;

	componentDidUpdate(prevProps)
	{
		if (this.props.selectedContentId !== prevProps.selectedContentId)
		{
			this.OnContentSelected(this.props.selectedContentId, this.props.selectedContentId !== this.selectedContentId);
		}
	}

	OnMouseOver = (content) => () =>
	{
		this.OnContentSelected(content ? content.id : undefined, false);
	}

	OnContentSelected(contentId, scrollTo)
	{
		if (this.props.map)
			this.props.map.SelectMarker(contentId);
		this.selectedContentId = contentId;

		if (this.selectedContentId && scrollTo)
		{
			this.ScrollTo(this.selectedContentId);
		}
	}

	ScrollTo(contentId)
	{
		const prefix = this.props.containerSelector ? (this.props.containerSelector + " ") : "";
		const selector = prefix + "#mci_" + contentId;

		const target = $(selector);
		const container = this.props.containerSelector ? $(this.props.containerSelector) : $('html, body');

		const isHorizontal = this.props.maxNumRows === 1;

		const percent = isHorizontal ? Utils.CalcPercentInViewportX(target[0]) : Utils.CalcPercentInViewportY(target[0]);
		if (percent > 0.95)
			return;

		const offset = target.offset();
		if (offset)
		{
			if (isHorizontal)
			{
				var x = offset.left;

				if (this.props.containerSelector)
				{
					x += container.scrollLeft();
					const containerOffsetX = container.offset().left;
					x -= containerOffsetX;
				}

				x -= (this.props.scrollOffset === undefined ? 0 : this.props.scrollOffset);

				container.animate({scrollLeft: x}, 500);
			}
			else
			{
				var y = offset.top;

				if (this.props.containerSelector)
				{
					y += container.scrollTop();
					const containerOffsetY = container.offset().top;
					y -= containerOffsetY;
				}

				y -= (this.props.scrollOffset === undefined ? 168 : this.props.scrollOffset);

				container.animate({scrollTop: y}, 500);
			}
		}
	}

	OnRenderContent = (content, index, contentList,
		contentWidth, numContentPerRow, isFirstContentInRow, isLastContentInRow) =>
	{
		const isSelected = this.selectedContentId === content.id;

		return (
			<div
				className={"mapContentCell" + (isSelected ? " selected":"")}
				id={"mci_" + content.id}
			>
				<ContentCard
					contentData={content}
					columnMode={false}
					isFirst={false}
					isLast={false}
					itemType={"content"}
					showLocation={true}
					defaultContentHeight={Math.floor(contentWidth / 1.5)}
					onMouseOver={this.OnMouseOver(content)}
					//onMouseOut={this.OnMouseOut(content)}
				/>
			</div>
		);
	}

	render()
	{
		return (
			<div
				className={"mapContent" + (this.props.className || "")}
				key={this.props.key}
				id={this.props.id}
			>
				{this.props.showTitle !== false &&
				<div className="mapContentTitle">
					{this.props.content && this.props.content.length === 1 && Translate.T("de.Ein Eintrag auf Karte", "en.One entry on map", "mapcontent.title.oneentry")}
					{(!this.props.content || this.props.content.length !== 1) && Translate.T("de.{0} Einträge auf Karte", "en.{0} entries on map",
						"mapcontent.title.entries", [this.props.content ? this.props.content.length : 0])}
				</div>}

				<ContentGrid
					content={this.props.content}
					minContentWidth={this.props.minContentWidth || 300}
					maxContentWidth={this.props.maxContentWidth || 400}
					idealNumContentPerRow={this.props.idealNumContentPerRow || 3}
					contentSpacing={this.props.contentSpacing || 16}
					onRenderContent={this.OnRenderContent}
					maxNumRows={this.props.maxNumRows}
					onScrollingNeeded={this.props.onScrollingNeeded}
				/>

			</div>
		);
	}
}

MapContent.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(MapContent);