import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import AppState from '../AppState';
import Utils from '../Utils';
//import AdvManager from '../AdvManager';
import AdvCard from '../components/adventure/AdvCard';
//import RestApi from '../RestApi';
import ContentCard from '../components/ContentCard';
//import Timeline from '../components/Timeline';
//import SocialInteraction from '../components/SocialInteraction';
import Translate from '../Translate';
import Button from 'material-ui/Button';
import MediaPlayer from '../components/MediaPlayer';
import AdvNotReady from '../components/adventure/AdvNotReady';
//import Slide from 'material-ui/transitions/Slide';
import { observable } from 'mobx';
import Tracking from '../Tracking';
import HorizontalScroller from '../components/HorizontalScroller';

const styles = theme => ({
});

@observer
class AdventureDetailsPage extends React.Component
{
	advManager;
	timeline;
	@observable showPage = false;

	constructor(props)
	{
		super(props);

		this.advManager = AppState.instance.advManager;
	}

	componentDidMount()
	{
		this.advManager.onConfigChanged.Add(this.OnConfigChanged);
		this.advManager.onInstancesChanged.Add(this.OnInstancesChanged);
		this.showPage = true;

		Tracking.SendEvent("AdventureDetailsPage", {adventureId: this.props.adventureId});
	}

	componentWillUnmount()
	{
		this.advManager.onConfigChanged.Remove(this.OnConfigChanged);
		this.advManager.onInstancesChanged.Remove(this.OnInstancesChanged);
	}

	OnConfigChanged = () =>
	{
		this.forceUpdate();
	}

	OnInstancesChanged = () =>
	{
		this.forceUpdate();
	}

	// OnCardClicked = (cardId, e) =>
	// {
	// 	var card = this.advManager.GetCardById(cardId);
	// 	if (card && card.title === "Placeholder")
	// 		return;

	// 	//TEMP:
	// 	RestApi.SendRequest("/advdummycard", {cardId: cardId})
	// 	.then((r) =>
	// 	{
	// 		this.advManager.LoadInstances();
	// 	});
	// }

	OnMessageSent = (message, withHeart) =>
	{
		if (this.timeline)
			this.timeline.Refresh();
	}

	OnLocationSetCta = (locationSetId) => (e) =>
	{
		e.stopPropagation();
		e.preventDefault();

		//TODO:
		// Start adventure
		/*this.advManager.StartAdventure(this.props.adventureId, locationSetId)
		.then((newInstance) =>
		{
			AppState.instance.adventuresPageInstance.ShowInstancePage(newInstance);
		})
		.catch((error) =>
		{
			//TODO:
		});*/

		AppState.instance.screenMessage.ShowDialog(undefined, AdvNotReady, undefined, undefined, undefined, undefined, false, "AdvNotReadyContainer");
	}

	render()
	{
		const advId = this.props.adventureId;
		if (advId === undefined)
			return null;

		const adv = this.advManager.GetAdventureById(advId);
		if (!adv)
			return null;

		const parentDesc = adv.desc;
		const teaser = adv.teaser;
		const cards = this.advManager.GetCards(adv);

		var hasCardInstances = false;
		if (cards && cards.length > 0)
		{
			for (var i = 0; i < cards.length; ++i)
			{
				var instances = this.advManager.GetCardInstances(cards[i].id);
				if (instances && instances.length > 0)
				{
					hasCardInstances = true;
					break;
				}
			}
		}

		//const isUnlocked = true;//instance ? instance.unlocked !== undefined : false;
		const isLocked = false;// !isUnlocked;
		//const isStarted = instance ? instance.started !== undefined : false;
		//const isFinished = instance ? (instance.finished !== undefined) : false;

		var locationSets = [];
		for (i = 0; i < adv.locationSetTitles.length; ++i)
		{
			const locSet = Utils.GetByProp(this.advManager.config.locationSets, "title", adv.locationSetTitles[i]);
			if (locSet)
				locationSets.push(locSet);
		}

		var mediaData;
		if (teaser && teaser.pictureUrl)
		{
			mediaData = {};
			mediaData.activity = {};
			mediaData.activity.id = adv.id;
			mediaData.activity.pictureUrls = [teaser.pictureUrl];
		}

		return (
			// <Slide direction="left" in={this.showPage}>
				<div
					className="adventureDetailsPage" key={adv.id}
				>
					{mediaData &&
						<MediaPlayer data={mediaData} className={"keyImage" + (isLocked ? " grayscale":"") + (hasCardInstances ? " withCards":"")}
							disableAuthorLink={true}
							defaultContentHeight={AppState.instance.deviceInfo.tablet ? 600 : 200}
							unloadable={false}
						/>}

					<div className="detailInfoContainer">

						<h1 className="title">{adv.title}</h1>

						<h2 className="subtitle">{adv.subtitle}</h2>

						{teaser &&
							<div className="teaser">
								{teaser.text}
							</div>}

						{cards &&
							<div className="cards">
								{cards.map((card, index) =>
								{
									return (
										<AdvCard key={card.id} cardId={card.id} isFirst={index === 0} isLast={index === cards.length - 1}
											showTitle={true} showPlus={true} showNumInstances={false}
											//onClick={this.OnCardClicked}
										/>
									);
								})}
							</div>}

						<div className="advParentDesc">
							<div className="title">{Translate.T("de.Beschreibung für Eltern", "en.Description for parents", "adventure.details.desc.title")}</div>

							<div className="detailShortInfoLine">
								<div className="detailShortInfoItem">
									{Utils.RenderTimelapse(24, 24, "#eee", 1.5)}
									{/* <img alt="visitduration" src="/assets/timelapse.svg" style={{width: 24, height: 24}} className="detailShortInfoIcon" /> */}
									<div className="detailShortInfoText">
										{Translate.T("de.{0} - {1} h", "en.{0} - {1} h", "adventure.details.desc.duration", [adv.durationMin, adv.durationMax])}
									</div>
								</div>

								{adv.ageFitMin && (adv.ageFitMax === undefined) && <div className="detailShortInfoItemSmall">
									{Utils.RenderChildrenIcon(24, 24, "#eee", 1.5)}
									<div className="detailShortInfoText">
										{Translate.T("en.{0} y/o & Up", "de.Ab {0} Jahre", "icon.agefit.minonly", [adv.ageFitMin])}
									</div>
								</div>}
								{adv.ageFitMax && (adv.ageFitMin === undefined) && <div className="detailShortInfoItemSmall">
									{Utils.RenderChildrenIcon(24, 24, "#eee", 1.5)}
									<div className="detailShortInfoText">
										{Translate.T("en.Up to {0} y/o", "de.Bis {0} Jahre", "icon.agefit.maxonly", [adv.ageFitMax])}
									</div>
								</div>}
								{adv.ageFitMax && adv.ageFitMin && <div className="detailShortInfoItemSmall">
									{Utils.RenderChildrenIcon(24, 24, "#eee", 1.5)}
									<div className="detailShortInfoText">
										{Translate.T("en.{0} - {1} y/o", "de.{0} - {1} Jahre", "icon.agefit.minmax", [adv.ageFitMin, adv.ageFitMax])}
									</div>
								</div>}

							</div>

							{parentDesc &&
								<div className="desc">{parentDesc.text}</div>}
						</div>

						<div className="locationSets">
							{locationSets.length !== 1 && <div className="title">{Translate.T("de.Erlebe das Abenteuer bei einem dieser Ausflugsziele",
								"en.Experience the adventure at one of these locations", "adventure.details.locations.title")}</div>}
							{locationSets.length === 1 && <div className="title">{Translate.T("de.Erlebe das Abenteuer bei diesem Ausflugsziel",
								"en.Experience the adventure at this location", "adventure.details.locations.title.singular")}</div>}

							<HorizontalScroller allowIScroll={false} className="set">
								{locationSets.map((locationSet) =>
								{
									var locations = [];
									var activities = {};
									var activityContent;
									for (i = 0; i < locationSet.locationIds.length; ++i)
									{
										var locationData = Utils.GetById(this.advManager.config.locations, locationSet.locationIds[i]);
										locations.push(locationData);

										activities[locationData.activityId] = 1;

										//TODO: support multiple activities per locationset
										if (!activityContent)
										{
											activityContent = Utils.GetById(this.advManager.config.activities, locationData.activityId);
										}
									}

									// var numActivities = 0;
									// for (var key in activities)
									// 	++numActivities;

									var locationCta = (
										<div className="locationCta">
											<Button onClick={this.OnLocationSetCta(locationSet.id)}>
												{Translate.T("de.Starte Abenteuer", "en.Start Adventure", "adventure.locationset.cta")}
											</Button>
										</div>
									);

									return (
										<div className="locationSet" key={locationSet.id}>
											{activityContent &&
												<ContentCard
													contentData={activityContent}
													columnMode={false}
													isFirst={true}
													isLast={true}
													itemType="content"
													renderMode="tiny"
													defaultContentHeight={150}
													maxNumCategories={1}
													additionalCardContent={locationCta}
												/>}
										</div>
									);
								})}
							</HorizontalScroller>
						</div>

						{/* <div className="sectionSocial">
							<div className="title">{Translate.T("de.Wie gefällt Dir dieses Abenteuer?", "en.How do you like this adventure?", "adventure.details.social.title")}</div>

							<SocialInteraction
								receiverType={"adventure"}
								receiverId={adv.id}
								enableSendHeart={true}
								onMessageSent={this.OnMessageSent}
								//onCommentChanged={this.props.onCommentChanged}
							/>

							<Timeline
								activityId={adv.id}
								pullToRefreshDisabled={true}
								isOwnUser={false}
								disableEodMsg={true}
								context={"adventure"}
								loadMoreButton={true}
								onInit={(tli) => this.timeline = tli}
							/>
						</div> */}

					</div>
				</div>
			//</Slide>
		);
	}
}

AdventureDetailsPage.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AdventureDetailsPage);