import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import Button from 'material-ui/Button';
import Translate from '../Translate';

const styles = theme => ({
});

@observer
class AgeSelection extends React.Component
{
	OnAgeButtonClicked = ageGroupIdx => (e) =>
	{
		if (this.props.onClick)
			this.props.onClick(e, ageGroupIdx);
	}

	RenderAgeSelectorButton(ageGroupIdx, label)
	{
		var checked = false;
		if (this.props.states.length > ageGroupIdx)
			checked = this.props.states[ageGroupIdx];

		if (this.props.renderMode === "tiny")
		{
			return (
				<div
					className={"ageCheckbox tiny " + (checked ? "checked" : "unchecked") +
						(ageGroupIdx === 0 ? " first":"") +
						(ageGroupIdx === 4 ? " last":"")
					}
				/>
			);	
		}

		return (
			<Button
				onClick={this.props.editable === false ? undefined : this.OnAgeButtonClicked(ageGroupIdx)}
				className={"ageCheckbox " + (checked ? "checked" : "unchecked") +
					(ageGroupIdx === 0 ? " first":"") +
					(ageGroupIdx === 4 ? " last":"")
				}
			>
				{label}
			</Button>
		);
	}

	render()
	{
		return (
			<div className={"uiControlAgeSelectorCBs " + this.props.className + " " + this.props.renderMode}>

				{this.props.showIndicatorIcon && <div className="indicatorIcon">
					<img alt="age" src="/assets/age.svg" />
					<div className="ppNumbersItemLabel">
						{Translate.T("en.Kids-Age", "de.Kinderalter", "profile.children.age")}
					</div>
				</div>}

				{this.RenderAgeSelectorButton(0, "0 - 1")}
				{this.RenderAgeSelectorButton(1, "2 - 3")}
				{this.RenderAgeSelectorButton(2, "4 - 5")}
				{this.RenderAgeSelectorButton(3, "6 - 9")}
				{this.RenderAgeSelectorButton(4, "10+")}
			</div>
		);
	}
}

AgeSelection.propTypes =
{
	classes: PropTypes.object,
	theme: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(AgeSelection);