import Translate from '../../Translate';
import StepBase from './StepBase';

export default class StepClosingMessage extends StepBase
{
	constructor(props)
	{
		super(props);

		this.AddAnswerOption({
			label: Translate.T("en.Close", "de.Schließen", "lena.closing.button.close"),
			onClick: this.OnOptionClicked,
			id: "close"
		});
	}

	OnOptionClicked = (e, contextData) =>
	{
		contextData.bot.OnGoalAchieved();
	}

	GetId()
	{
		return "closingmsg";
	}

	GeneratedDataIds()
	{
		return ["closingmsg"];
	}

	Undo(contextData)
	{
	}

	renderStepLena(contextData)
	{
		if (contextData["goal"] === "feedback")
			return this.renderLenaMsg(Translate.T("de.Vielen Dank für Dein Feedback!", "en.Thanks for your feedback!", "lena.closingmsg.feedback"));

		if (contextData["goal"] === "checkin")
			return this.renderLenaMsg(Translate.T("de.Sehr gut! Ich habe dieses Ausflugsziel in Deiner Liste gespeichert und Dein Profil angepasst.",
				"en.Great! I saved this place in your list and adjusted your profile.", "lena.closingmsg.checkin"));

		if (contextData["goal"] === "experience")
			return this.renderLenaMsg(Translate.T("de.Erfolgreich erledigt!\nIch hoffe, dass Du viele Herzen dafür bekommst!",
				"en.Done! I hope you will receive many hearts for it!", "lena.closingmsg.experience"));

		if (contextData.goal === "question")
			return this.renderLenaMsg(Translate.T("de.Ich habe ihnen Deine Frage geschickt! Du kannst sie in Deiner Profil-Chronik beobachten.",
				"en.I've sent them your question! You can check it out in your profile timeline.", "lena.closingmsg.question"));

		return this.renderLenaMsg(Translate.T("de.Ich freue mich auf unsere nächste Unterhaltung!",
			"en.Looking forward to our next conversation!", "lena.closingmsg.default"));
	}

	renderUserSelection(contextData)
	{
		return null;
	}

	renderUserOptions(contextData)
	{
		return this.renderAnswerOptions(contextData, this.answerOptions);
	}
}
