import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import Button from 'material-ui/Button';
import ReactIScroll from '../iscroll/react-iscroll';
import iScroll from 'iscroll/build/iscroll-probe.js';
import $ from 'jquery';


const styles = theme => ({	
});

const iScrollOptions = {
	mouseWheel: true,
	scrollbars: false,
	scrollX: false,
	scrollY: true,
	freeScroll: false,
	fadeScrollbars: true,
	bounce: false,
	momentum: false,
	eventPassthrough: false,
	tap: true,
	click: true,
	interactiveScrollbars: false,
	probeType: 3,
};

@observer
class TimePicker extends React.Component
{
	hours = [];
	minutes = [];

	iScrollHours;
	iScrollMinutes;
	selectedHour;
	selectedMinute;

	itemHeight;

	constructor(props)
	{
		super(props);

		this.itemHeight = this.props.itemHeight || 60;

		for (var j = 0; j < 5; ++j)
		{
			for (var i = 0; i < 24; ++i)
			{
				this.hours.push(i);
			}

			for (i = 0; i < 12; ++i)
			{
				if (i * 10 >= 60)
					break;

				this.minutes.push(i * 10);
			}
		}

		var selectedDate = this.props.value || new Date();

		const hours = selectedDate.getHours();
		const minutes = selectedDate.getMinutes();

		this.selectedHour = (hours % 24) + 2 * 24;
		this.selectedMinute = Math.round((minutes % 60) / 10) + 2 * 6;
	}

	OnScrollHoursRefresh = (iScrollInstance) =>
	{
		this.iScrollHours = iScrollInstance;
		setTimeout(() => {
			this.iScrollHours.scrollTo(0, -(this.selectedHour - 1) * this.itemHeight);	
		}, 100);
	}

	OnScrollMinutesRefresh = (iScrollInstance) =>
	{
		this.iScrollMinutes = iScrollInstance;
		setTimeout(() => {
			this.iScrollMinutes.scrollTo(0, -(this.selectedMinute - 1) * this.itemHeight);	
		}, 100);
	}

	OnScrollHours = (iScrollInstance) =>
	{
		const wasAM = (this.selectedHour % 24) < 12;
		this.selectedHour = this.OnScrollInternal(iScrollInstance, "Hour", this.selectedHour);

		const isAM = (this.selectedHour % 24) < 12;

		if (isAM !== wasAM)
		{
			this.UpdateAMPM();
		}
	}

	UpdateAMPM()
	{
		const isAM = (this.selectedHour % 24) < 12;

		$("#timePickerAM").css('display', isAM ? 'block':'none');
		$("#timePickerPM").css('display', !isAM ? 'block':'none');
	}

	OnScrollMinutes = (iScrollInstance) =>
	{
		this.selectedMinute = this.OnScrollInternal(iScrollInstance, "Minute", this.selectedMinute);
	}

	OnScrollInternal(iScrollInstance, itemStr, prevSelected)
	{
		//console.log("SCROLL: " + iScrollInstance.y);

		const scrollY = iScrollInstance.y;
		const newSelected = Math.round(- scrollY / this.itemHeight) + 1;

		if (prevSelected !== newSelected)
		{
			$(".timePicker" + itemStr).removeClass("selected");
			if (newSelected)
			{
				$("#timePicker" + itemStr + newSelected).addClass("selected");
			}
		}

		return newSelected;
	}

	OnScrollHoursEnd = (iScrollInstance) =>
	{
		//console.log("END: " + iScrollInstance.y);
		this.OnScrollEndInternal(iScrollInstance, "Hour", 24);
	}

	OnScrollMinutesEnd = (iScrollInstance) =>
	{
		this.OnScrollEndInternal(iScrollInstance, "Minute", 6);
	}

	OnScrollEndInternal(iScrollInstance, itemStr, interval)
	{
		// snap
		const newY = Math.round(iScrollInstance.y / this.itemHeight) * this.itemHeight;
		if (newY !== iScrollInstance.y)
		{
			iScrollInstance.scrollTo(0, newY, 150, {
				style: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
				fn: function (k) {
					return k * ( 2 - k );
				}
			});
		}

		setTimeout(() => {

			const oldHour = this.selectedHour;
			const oldMinute = this.selectedMinute;

			this.selectedHour = (this.selectedHour % 24) + 2 * 24;
			this.selectedMinute = (this.selectedMinute % 6) + 2 * 6;

			if (oldHour !== this.selectedHour || oldMinute !== this.selectedMinute)
			{
				this.forceUpdate();
			}
			this.OnChanged();
		}, 200);
	}

	OnHourClicked = (index) => () =>
	{
		if (index !== this.selectedHour)
			this.OnItemClicked(this.iScrollHours, index);
	}

	OnMinuteClicked = (index) => () =>
	{
		if (index !== this.selectedMinute)
			this.OnItemClicked(this.iScrollMinutes, index);
	}

	OnItemClicked(iScrollInstance, index)
	{
		iScrollInstance.scrollTo(0, -(index - 1) * this.itemHeight, 300, 
			{
				style: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
				fn: function (k) {
					return k * ( 2 - k );
				}
			});
	}

	OnChanged()
	{
		if (this.props.onChange)
		{
			var newDate = new Date();
			const hours = this.selectedHour % 24;
			const minutes = (this.selectedMinute % 6) * 10;
			newDate.setHours(hours, minutes, 0, 0);

			this.props.onChange(newDate);
		}
	}

	OnAMPMClicked = () =>
	{
		this.selectedHour += 12;
		this.selectedHour = this.selectedHour % 24 + 2 * 24;
		this.forceUpdate();
		this.OnChanged();
	}

	render()
	{
		setTimeout(function () {
			if (this.iScrollHours)
				this.iScrollHours.refresh();
			if (this.iScrollMinutes)
				this.iScrollMinutes.refresh();
		}, 200);

		const isAM = (this.selectedHour % 24) < 12;
		const useAMPM = this.props.mode === '12h';

		return (
			<div className="timePicker" style={{height: 3 * this.itemHeight}}>

				<div className="timePickerColumn" style={{height: 3 * this.itemHeight}}>
				
					<ReactIScroll
						iScroll={iScroll}
						options={iScrollOptions}
						onScroll={this.OnScrollHours}
						onScrollEnd={this.OnScrollHoursEnd}
						onRefresh={this.OnScrollHoursRefresh}
						className="timePickerHours"
					>
						<ul>
							{this.hours.map((hour, index) =>
							{
								var hourStr = hour;
								if (useAMPM && hour >= 12)
									hourStr = (hour - 12);

								return (
									<li id={"timePickerHour" + index}
										key={index}
										className={"timePickerHour" + (index === this.selectedHour ? " selected":"")}
										style={{height: this.itemHeight, lineHeight: this.itemHeight + 'px'}}
										onClick={this.OnHourClicked(index)}
									>
										{hourStr}
									</li>
								);
							})}
						</ul>
					</ReactIScroll>
				</div>

				<div className="timePickerColumn" style={{height: 3 * this.itemHeight}}>
					<ReactIScroll
						iScroll={iScroll}
						options={iScrollOptions}
						onScroll={this.OnScrollMinutes}
						onScrollEnd={this.OnScrollMinutesEnd}
						onRefresh={this.OnScrollMinutesRefresh}
						className="timePickerMinutes"
					>
						<ul>
							{this.minutes.map((minute, index) =>
							{
								var minuteStr = minute;
								if (minute < 10)
									minuteStr = "0" + minuteStr;
								return (
									<li id={"timePickerMinute" + index}
										key={index}
										className={"timePickerMinute" + (index === this.selectedMinute ? " selected":"")}
										style={{height: this.itemHeight, lineHeight: this.itemHeight + 'px'}}
										onClick={this.OnMinuteClicked(index)}
									>
										{minuteStr}
									</li>
								);
							})}
						</ul>
					</ReactIScroll>
				</div>

				<div className="timePickerColon">:</div>

				<div className="timePickerLine timePickerLine0" />
				<div className="timePickerLine timePickerLine1" />

				{useAMPM && <div className="timePickerAMPMPlaceholder" /> }

				{useAMPM && <div className="timePickerAMPM">
					<Button id="timePickerAM" onClick={this.OnAMPMClicked} style={{display: (isAM ? 'block':'none')}}>
						AM
					</Button>
					<Button id="timePickerPM" onClick={this.OnAMPMClicked} style={{display: (!isAM ? 'block':'none')}}>
						PM
					</Button>
				</div>}
				
			</div>
		);
	}
}

TimePicker.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(TimePicker);