import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import {LinearProgress} from 'material-ui/Progress';
import Collapse from 'material-ui/transitions/Collapse';
import Autorenew from 'material-ui-icons/Autorenew';
import Close from 'material-ui-icons/Close';
import Button from 'material-ui/Button';

const styles = theme => ({	
});

@observer
class ProgressDisplay extends React.Component
{
	render()
	{
		const data = this.props.data;
		const progress = data.progress;

		return (
			<Collapse in={data.show} timeout="auto" unmountOnExit onExit={this.props.onDone}>
				<div key={this.props.key} className="progressDisplay">

					{/*data.dataUrl !== undefined && <img src={data.dataUrl} className="progressImgThumbnail" alt="upload preview" />*/}

					{data.dataUrl !== undefined && <div className="progressImgThumbnail"
						style={{backgroundImage: 'url("' + data.dataUrl + '")'}} />}

					<div className="progressRightColumn">
						<div className={"progressLabel" + (data.error ? " progressLabelError":"")}>
							{data.label}
						</div>

						{data.error && <Button onClick={data.onRetry} className="progressBtnRetry">
							<Autorenew/>
						</Button>}

						{data.error && <Button onClick={data.onCancel} className="progressBtnCancel">
							<Close/>
						</Button>}

						<LinearProgress mode="determinate" value={progress} className={"progressBar" + (data.error ? " progressBarError":"")} />
					</div>
				</div>
			</Collapse>
		);
	}
}

ProgressDisplay.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(ProgressDisplay);