import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {CircularProgress} from 'material-ui/Progress';
import Button from 'material-ui/Button';
import KeyboardArrowRight from 'material-ui-icons/KeyboardArrowRight';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import AppState from '../AppState';
import ContentList from './ContentList';
import Translate from '../Translate';
import VisibilitySensor from 'react-visibility-sensor';

const styles = theme => ({
	root: {
	  backgroundColor: theme.palette.background.paper,
	},
	refresh: {
		display: 'inline-block',
		position: 'relative',
	},
	showAllButton: {
		textTransform: 'none',
		color: '#555',
		padding: '0',
		paddingLeft: '4px',
		minWidth: '0',
		lineHeight: '14px'
	}
});

@observer
class CategoryTab extends React.Component
{
	@observable categoryContent = [];
	isLoading = 0;
	isEod = false;
	/*@observable*/ numPlaceholderEntries = 0;
	_isMounted = false;
	static PlaceholderCatId = 99999999;

	componentDidMount()
	{
		/*if (this.props.preload === true)
		{
			this.LoadData();
		}*/
		this._isMounted = true;
		if (this.props.active === true)
			this.OnTabActivated();
		//if (this.props.isRoot === false)
		//{
		//	this.LoadData();
		//}
	}

	componentWillUnmount()
	{
		this._isMounted = false;
	}

	componentWillReceiveProps(newProps)
	{
		if (this.props.active !== newProps.active)
		{
			if (this.props.active)
				this.OnTabDeactivated();
			else
				this.OnTabActivated();
		}
	}

	OnTabActivated = () =>
	{
		this.LoadData();
	}

	OnTabDeactivated = () =>
	{
		//console.log("DEACTIVATE: " + this.props.categoryId);
	}

	LoadData()
	{
		if (this.props.categoryIds === CategoryTab.PlaceholderCatId)
			return;

		AppState.instance.contentStore.GetCategoryContent(this.props.categoryIds, this.OnDataChanged, this.props.childIndex);
	}

	OnDataChanged = (action, newData, maxTotal) =>
	{
		//console.log("CategoryTab.OnDataChanged(" + this.props.categoryIds + ")(" + action + ", [" + (newData ? newData.length : "undefined") + "], " + (maxTotal || "undefined"));
		if (action === 'request')
		 	++this.isLoading;
		else if (action === 'data' || action === 'error' || action === 'abort' || action === 'eod')
		 	this.isLoading = 0;

		//console.log("isLoading@" + this.props.categoryIds + " = " + this.isLoading);

		if (action === 'data')
		{
			var filteredData = [];

			for (var i = 0; i < newData.length; ++i)
			{
				if (newData[i].type === "filter")
				{
					const filter = AppState.instance.filterManager.GetFilter(newData[i].id);
					if (filter)
					{
						if (filter.showInPage)
							filteredData.push(newData[i]);
					}
					else
					{
						filteredData.push(newData[i]);
					}
				}
				else
				{
					filteredData.push(newData[i]);
				}
			}


			if (maxTotal !== undefined)
			{
				this.numPlaceholderEntries = maxTotal - filteredData.length;
			}
			else
			{
				this.numPlaceholderEntries = 0;
			}
			this.categoryContent = filteredData;
		}
		else if (action === 'eod')
		{
			this.isEod = true;
			if (this.numPlaceholderEntries !== 0)
			{
				this.numPlaceholderEntries = 0;
				if (this._isMounted)
					this.forceUpdate();
			}
		}
		else if (action === "flushed")
		{
			// Reload data
			//console.log("FLUSHED data for: " + this.props.categoryIds);
			setTimeout(() => {
				this.LoadData();	
			}, 10);
		}
	}

	GetContentType()
	{
		// Check if the content is 'content', 'filter' or 'mixed'
		if (!this.categoryContent || this.categoryContent.length === 0)
		{
			if (this.props.itemType === "searchresult")
				return this.props.itemType;
			else
				return 'placeholder';
		}

		var numContent = 0;
		var numFilter = 0;
		var numCategories = 0;
		for (var i = 0; i < this.categoryContent.length; ++i)
		{
			var c = this.categoryContent[i];
			if (c.type === "filter")
				numFilter++;
			else if (c.type === "content")
				numContent++;
			else if (c.type === "categoryname")
				numCategories++;
		}

		if (numFilter === this.categoryContent.length)
			return "filter";
		if (numContent === this.categoryContent.length)
			return "content";
		if (numCategories === this.categoryContent.length)
			return "categoryname";
		return "mixed";
	}

	OnShowAllClicked = catId => event =>
	{
		if (this.props.onShowAllClicked)
			this.props.onShowAllClicked(catId);
	}

	OnVisibilitySensor = (isVisible) =>
	{
		//console.log("CategoryTab.OnVisibilitySensor: " + isVisible + ", " + this.isLoading);
		if (this.props.autoloadMore === false)
			return;

		if (isVisible && this.isLoading === 0)
		{
			this.doLoadMore();
		}
	}

	OnManualLoadMore = () =>
	{
		this.doLoadMore();
	}

	doLoadMore()
	{
		++this.isLoading;
		AppState.instance.contentStore.RequestMoreContent(this.props.categoryIds, this.props.childIndex);
	}

	renderInternal()
	{
		const itemType = this.GetContentType();

		if (itemType === 'category' || itemType === 'filter')
		{
			var placeholders = [];
			var numAddItems = this.numPlaceholderEntries;
			if (numAddItems > 10)
				numAddItems = 10;
			if (this.props.horizontalContentList)
				numAddItems = 0;
			for (var i = 0; i < numAddItems; ++i)
			{
				placeholders.push(
					<div className={"resultCategory resultCategoryPlaceholder" + (i === numAddItems - 1 ? " resultCategoryLast" : "")}
						key={"resultCategory_placeholder_" + i}>
						<div className="resultCategoryName resultCategoryNamePlaceholder" />
						<div className="resultCategoryMore resultCategoryMorePlaceholder" />
						<CategoryTab isRoot={false}
							categoryIds={CategoryTab.PlaceholderCatId} itemType="placeholder" preload={false} active={false}
							allowIScroll={this.props.allowIScroll}
							horizontalContentList={true}
							autoloadMore={this.props.autoloadMore}
						/>
					</div>
				);
			}

			var keyPostFix = "";
			if (this.categoryContent.length > 0)
			{
				const entry = this.categoryContent[this.categoryContent.length - 1];
				if (entry)
					keyPostFix = entry.id;
			}

			const isDesktop = AppState.instance.deviceInfo.desktop;

			// Render lists of ContentList
			return (
				<div>
					{this.categoryContent.map((categoryData, index, catArray) =>
					{
						if (!categoryData)
							return null;

						var childIndex; // The index of this categoryContent in the overall list (only for root pages with multiple listings)
						if (this.props.isRoot && this.categoryContent.length > 1)
							childIndex = index;
							
						return (
							<div className={"resultCategory" + ((index === this.categoryContent.length - 1) && (this.numPlaceholderEntries === 0) ? " resultCategoryLast" : "")}
								key={"resultCategory_" + categoryData.id + "_" + index}>

								<div className="resultCategoryName" onClick={!isDesktop ? this.OnShowAllClicked(categoryData.id) : undefined}>{categoryData.title}</div>

								{!isDesktop && <div className="resultCategoryMore">
									<Button className={this.props.classes.showAllButton} onClick={this.OnShowAllClicked(categoryData.id)}>
										{Translate.T("en.See all", "de.Mehr", "results.showall")}
										<KeyboardArrowRight
											style={{
												width: 20,
												height: 20,
												position: 'relative',
												top: '-1px'
											}}/>
									</Button>
								</div>}

								<CategoryTab isRoot={false}
									childIndex={childIndex}
									allowIScroll={this.props.allowIScroll}
									categoryIds={categoryData.id} itemType="content" preload={true} active={true} horizontalContentList={true}
									autoloadMore={this.props.autoloadMore}
								/>

								{isDesktop && <div className="resultCategoryMoreDesktop">
									<Button className={this.props.classes.showAllButton} onClick={this.OnShowAllClicked(categoryData.id)}>
										{Translate.T("en.Show all", "de.Alle anzeigen", "results.showall.desktop")}
										<KeyboardArrowRight
											style={{
												width: 20,
												height: 20,
												position: 'relative',
												top: '-1px'
											}}/>
									</Button>
								</div>}
							</div>
						);
					})}

					{this.props.autoloadMore === false && !this.isEod &&
						<div className="manualLoadMore">
							<Button onClick={this.OnManualLoadMore}>
								{Translate.T("de.Mehr anzeigen", "en.Show more", "results.manualloadmore")}
							</Button>
						</div>}
					
					{placeholders.length > 0 && this.props.autoloadMore !== false &&
						<VisibilitySensor
							onChange={this.OnVisibilitySensor}
							key={"placeholderSensor" + keyPostFix}
							active={true}
							partialVisibility={true}
						>
							<div>
								{placeholders}
							</div>
						</VisibilitySensor>}
				</div>
			);
		}
		else // 'mixed' and 'content' as vertical list
		{
			var data = this.categoryContent;
			if (itemType === 'placeholder')
			{
				data = [];
				data.push(null);
				data.push(null);
			}

			// Render a single ContentList
			return (
				<ContentList key={itemType + this.props.categoryIds} itemType={itemType}
					childIndex={this.props.childIndex}
					categoryIds={this.props.categoryIds}
					renderMode={this.props.renderMode}
					allowIScroll={this.props.allowIScroll}
					contentOnly={this.props.contentOnly}
					onContentClick={this.props.onContentClick}
					contentData={data}
					maxTotal={this.numPlaceholderEntries}
					horizontal={this.props.horizontalContentList}
					autoloadMore={this.props.autoloadMore}
				/>
			);
		}
	}

	render()
	{
		var loadingMarginTop = 0;
		if (this.categoryContent.length > 0)
			loadingMarginTop = -56;

		var style = {
			marginTop: loadingMarginTop + 'px',
			display: this.isLoading > 0 ? 'block' : 'none'
		};

		return (
			<div>
				{this.renderInternal()}
				<div className="categoryLoading" style={style} id={this.isLoading}>
					<CircularProgress/>
				</div>
			</div>
		);
	}
}

CategoryTab.propTypes =
{
	classes: PropTypes.object,
	theme: PropTypes.object,
	categoryIds: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number]).isRequired,
	itemType: PropTypes.string.isRequired,
	isRoot: PropTypes.bool.isRequired,
	preload: PropTypes.bool,
	active: PropTypes.bool,
	horizontalContentList: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(CategoryTab);