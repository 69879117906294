//import React from 'react';
import EXIF from 'exif-js';

/**
 * Set up operations and degrees to rotate for each EXIF orientation (index).
*/
// const ExifOrientations = [
// 	{ op: 'none', degrees: 0 },
// 	{ op: 'flip-x', degrees: 0 },
// 	{ op: 'none', degrees: 180 },
// 	{ op: 'flip-y', degrees: 0 },
// 	{ op: 'flip-x', degrees: 90 },
// 	{ op: 'none', degrees: 90 },
// 	{ op: 'flip-x', degrees: -90 },
// 	{ op: 'none', degrees: -90 }
// ];

export default class CanvasImageUploader
{
	image;          // Image object (<img>)
	imageData;      // Image from canvas as byte array
	options;
	
	constructor(options)
	{
		this.options = options || {};
    	if (typeof options.maxSize === 'undefined') this.options.maxSize = 1280;
    	if (typeof options.jpegQuality === 'undefined') this.options.jpegQuality = 0.7;
	}

	/**
     * Converts a base64 string to byte array.
     */
    base64toBlob(base64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(base64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);
            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, {type: contentType});
    }

    /**
     * Determines whether we need to change width/height dimensions or not.
     */
    isRotated(orientation) {
        return !! ~[5,6,7,8].indexOf(orientation);
    }

    flipContext(ctx, canvas, x, y) {
        ctx.translate(x ? canvas.width : 0, y ? canvas.height : 0);
        ctx.scale(x ? -1 : 1, y ? -1 : 1);
    }

    rotateContext(ctx, attr) {
        var x = attr.x || 0;
        var y = attr.y || 0;

        if (attr.degrees) {
            attr.radians = attr.degrees * (Math.PI / 180);
        }

        ctx.translate(x, y);
        ctx.rotate(attr.radians);
        ctx.translate(-x, -y);
    }

    calculateSize(image, maxSize) {
        var size = { width: image.width, height: image.height };
        if (image.width > maxSize || image.height > maxSize) {
            var ratio = image.width / image.height;
            if (image.width >= image.height) {
                size.width = maxSize;
                size.height = maxSize / ratio;
            } else {
                size.height = maxSize;
                size.width = maxSize * ratio;
            }
        }
        return size;
    }

    setDimensions($canvas, size, orientation) {
        if (this.isRotated(orientation)) {
            $canvas.attr('height', size.width);
            $canvas.attr('width', size.height);
        } else {
            $canvas.attr('width', size.width);
            $canvas.attr('height', size.height);
        }
    }

    drawOnCanvas(image, $canvas, orientation, maxSize) {
        var canvas = $canvas[0];
        var ctx = canvas.getContext('2d');

        //var exifOrientation = ExifOrientations[orientation - 1];
		var size = this.calculateSize(image, maxSize);
        this.setDimensions($canvas, size, orientation);

        // Clear canvas
		ctx.clearRect(0, 0, $canvas.width(), $canvas.height());
		
		//ctx.fillStyle="red";
		//ctx.fillRect(0, 0, $canvas.width(), $canvas.height());

        // Flip vertically or horizontally
        //if ('flip-x' === exifOrientation.op) this.flipContext(ctx, canvas, true, false);
        //if ('flip-y' === exifOrientation.op) this.flipContext(ctx, canvas, false, true);

        // Rotate image
		// if (exifOrientation.degrees)
		// {
		// 	this.rotateContext(ctx,
		// 	{
        //         degrees: exifOrientation.degrees,
        //         x: $canvas.width() / 2,
        //         y: $canvas.height() / 2
        //     });

		// 	if (this.isRotated(orientation))
		// 	{
        //         var diff = $canvas.width() - $canvas.height();
        //         ctx.translate(diff / 2, -diff / 2);
        //     }
		// }
		
		var rawWidth = size.width;
		var rawHeight = size.height;
		switch (orientation) {
			case 2: ctx.transform(-1, 0, 0, 1, rawWidth, 0); break;
			case 3: ctx.transform(-1, 0, 0, -1, rawWidth, rawHeight); break;
			case 4: ctx.transform(1, 0, 0, -1, 0, rawHeight); break;
			case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
			case 6: ctx.transform(0, 1, -1, 0, rawHeight, 0); break;
			case 7: ctx.transform(0, -1, -1, 0, rawHeight, rawWidth); break;
			case 8: ctx.transform(0, -1, 1, 0, 0, rawWidth); break;
			default: break;
		}

        ctx.drawImage(image, 0, 0, image.width, image.height,   // Source rectangle
			0, 0, size.width, size.height);                     // Destination rectangle
			
		//console.log("DRAW: " + image.width + ", " + image.height + " => " + size.width + ", " + size.height);
    }

	readImageToCanvasOnLoad(image, $canvas, callback)
	{
		var thisPtr = this;
		this.getExifOrientation(image, function (orientation)
		{
			console.log("ORIENT: " + orientation);
            thisPtr.drawOnCanvas(image, $canvas, orientation, thisPtr.options.maxSize);
            if (callback)
                callback();
            else
                console.warn('No callback for readImageToCanvas');
        });
    }

    getExifOrientation(image, callback) {
        if (!image) {
            console.warn('No image');
            return;
        }

        EXIF.getData(image, function () {
            var orientation = EXIF.getTag(image, 'Orientation') || 1;
            if (callback)
                callback(orientation);
            else
                console.warn('No callback for getExifOrientation()');
        });
	}
	
	/**
	 * Run to initialize CanvasImageUploader.
	 */
	newImage() {
		this.imageData = null;
		this.image = new Image();
	}

	/**
	 * Returns the image data if any file has been read.
	 * @returns {Blob|null}
	 */
	getImageData() {
		return this.imageData;
	}

	/**
	 * Draw an image (<img>) or contents of a canvas to another canvas. The destination
	 * canvas is resized properly.
	 * @param source The image or source canvas to draw on a new canvas.
	 * @param $destination The destination canvas to draw onto.
	 * @param maxSize Maximum width or height of the destination canvas.
	 */
	copyToCanvas(source, destination, maxSize) {
		var size = this.calculateSize(source, maxSize);
		this.setDimensions(destination, size, 1);
		var destCtx = destination[0].getContext('2d');
		destCtx.drawImage(source, 0, 0, source.width, source.height,
			0, 0, size.width, size.height);
	}

	/**
	 * Draw an image from a file on a canvas.
	 * @param file The uploaded file.
	 * @param $canvas The canvas (jQuery) object to draw on.
	 * @param callback Function that is called when the operation has finished.
	 */
	readImageToCanvas(file, canvas, callback)
	{
		this.newImage();
		if (!file)
			return;

		var thisPtr = this;
		var reader = new FileReader();
		reader.onload = function (fileReaderEvent) {
			thisPtr.image.onload = function () { thisPtr.readImageToCanvasOnLoad(this, canvas, callback); };
			thisPtr.image.src = fileReaderEvent.target.result;      // The URL from FileReader
		}
		reader.readAsDataURL(file);
	}

	/**
	 * Read the canvas data and save it as a binary byte array to image data variable.
	 * Get this data using the method getImageData().
	 * @param canvas
	 */
	saveCanvasToImageData(canvas) {
		var base64 = canvas.toDataURL('image/jpeg', this.options.jpegQuality)
			.replace(/^data:image\/(png|jpeg|jpg|gif);base64,/, '');
		this.imageData = this.base64toBlob(base64, 'image/jpeg');       // Byte array
	}

	getCanvasDataUrl(canvas)
	{
		return canvas.toDataURL('image/jpeg', this.options.jpegQuality);
	}
}
