import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import Moment from 'moment';
import Translate from '../Translate';
import Button from 'material-ui/Button';

const styles = theme => ({
	
  });

@observer
class WeatherDisplay extends React.Component
{
	@observable showWeatherDetails = false;
	decodedData;

	OnShowWeatherDetails = (event) =>
	{
		this.showWeatherDetails = true;
		event.preventDefault();
		event.stopPropagation();

		if (this.props.onExpanded)
			this.props.onExpanded();
	}

	static RenderWeatherIcon(weather, className, onClick, key)
	{
		if (!weather)
			return null;
			
		return (
			<div className={className} onClick={onClick} key={key}>
				<img alt="weather" src={"/assets/w/" + weather.icon + ".svg"} style={{width: '24px', height: '24px'}} className="detailShortInfoIcon" />
				<div className="detailShortInfoText">
					{Math.round(weather.temp) + "°C"}
				</div>
			</div>
		);
	}

	static RenderWeatherLine(weatherData, index)
	{
		var dayStr;
		if (index === 0)
			dayStr = Translate.T("de.Heute", "en.Today", "today");
		else if (index === 4)
			dayStr = Translate.T("de.Morgen", "en.Tomorrow", "tomorrow");
		else
		{
			const dayOffset = index / 4;
			dayStr = Moment().add(dayOffset, 'days').format("dddd");
			//dayStr = Moment(weatherData[index].date).format("dddd");
		}

		return (
			<div className="detailWeatherLine">
				<div className="detailWeatherDay">{dayStr}</div>
				<div className="detailWeatherIcons">
					{WeatherDisplay.RenderWeatherIcon(weatherData[index + 0], "detailWeatherIcon")}
					{WeatherDisplay.RenderWeatherIcon(weatherData[index + 1], "detailWeatherIcon")}
					{WeatherDisplay.RenderWeatherIcon(weatherData[index + 2], "detailWeatherIcon")}
					{WeatherDisplay.RenderWeatherIcon(weatherData[index + 3], "detailWeatherIcon")}
				</div>
			</div>
		);
	}

	static DecodeWeather(str)
	{
		if (str.length < 5)
			return str;

		var dow = Number(str.substring(0, 1));
		var icon = str.substring(1, 4);
		var temp = Number(str.substring(4));

		return {
			dow,
			icon,
			temp
		};
	}

	static DecodeWeatherForecast(str)
	{
		var parts = str.split('|');
		var result = [];

		for (var i = 0; i < parts.length; ++i)
		{
			var item = parts[i];
			result.push(WeatherDisplay.DecodeWeather(item));
		}

		return result;
	}

	CheckDecoding()
	{
		if (this.isDecoded)
			return;

		if (this.props.data)
		{
			this.decodedData = WeatherDisplay.DecodeWeatherForecast(this.props.data);
			this.isDecoded = true;
		}
	}

	render()
	{
		this.CheckDecoding();

		return (
			<div className="detailSection detailSectionWeather" data-tk="weather">
				<div className="detailSectionTitle detailSectionTitleWeather">
					{Translate.T("de.Wetter", "en.Weather", "detail.weather.title")}
					</div>
				<div className="detailWeather">
					<div className="detailWeatherHeader">
						<div className="detailWeatherHeaderItem">9:00</div>
						<div className="detailWeatherHeaderItem">12:00</div>
						<div className="detailWeatherHeaderItem">15:00</div>
						<div className="detailWeatherHeaderItem">18:00</div>
					</div>
					{WeatherDisplay.RenderWeatherLine(this.decodedData, 0)}
					{WeatherDisplay.RenderWeatherLine(this.decodedData, 4)}
					{this.showWeatherDetails && WeatherDisplay.RenderWeatherLine(this.decodedData, 8)}
					{this.showWeatherDetails && WeatherDisplay.RenderWeatherLine(this.decodedData, 12)}
					{this.showWeatherDetails && WeatherDisplay.RenderWeatherLine(this.decodedData, 16)}
				</div>
				{!this.showWeatherDetails && <div className="detailShowWeatherDetails">
					<Button onClick={this.OnShowWeatherDetails}>
						{Translate.T("en.Show more", "de.Mehr anzeigen", "button.showweatherdetails")}
					</Button>
				</div>}
			</div>
		);
	}
}

WeatherDisplay.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(WeatherDisplay);