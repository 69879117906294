import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import AppState from '../AppState';
import ActivityRenderer from '../components/ActivityRenderer';

// Not stable enough in our context, as the children of Reparentable have complicated state/logic
//import Reparentable from './Reparentable';

const styles = theme => ({
});

@observer
class SuggestionCard extends React.Component
{
	componentDidMount()
	{
		if (this.props.contentData && this.props.contentData.activity && this.props.contentData.activity.name)
		{
			AppState.instance.contentStore.SetActivityDetails(this.props.contentData.activity.name, this.props.contentData);
		}
	}

	OnCategoryClicked = (catName) =>
	{
		//TODO: repair
		// const catId = AppState.instance.contentStore.GetCategoryId(catName);
		// if (catId !== undefined)
		// {
		// 	AppState.instance.appInstance.OnTabbarChanged(undefined, AppState.TabIndexExplore);
		// 	AppState.instance.mainPageInstance.OnCategoryClicked(catId);
		// }

		//TEMP: to create swipe-screenshots
		//AppState.instance.swipePageInstance.Pose();
	}

	render()
	{
		var sr = this.props.contentData;

		return (
			// <Reparentable uid={"suggestioncard_" + sr.activity.id}>
				<div
					key={"suggestionCard_" + sr.activity.id}
					id={"suggestionCard_" + sr.activity.id}
					className="suggestionCardContainer"
				>
					<ActivityRenderer
						data={sr}
						onChanged={this.props.onChanged}
						onMediaSwitching={this.props.onMediaSwitching}
						onMediaTouchEnd={this.props.onMediaTouchEnd}
						onMediaTouchStart={this.props.onMediaTouchStart}
						onBeforeMediaZoom={this.props.onBeforeMediaZoom}
						onAfterMediaZoom={this.props.onAfterMediaZoom}
						onMediaPlayerInstance={this.props.onMediaPlayerInstance}
						onDropDownMenuOpen={this.props.onDropDownMenuOpen}
						containerClassname={"swipePage"}
						showBookmarkStatus={this.props.showBookmarkStatus}
						mapSimpleMode={true}
						onlyOneCategory={true}
						onCategoryClicked={this.OnCategoryClicked}
						onBeforeLinkClicked={this.props.onBeforeLinkClicked}
						onTopRatingClicked={this.props.onTopRatingClicked}
						onTopWeatherClicked={this.props.onTopWeatherClicked}
						ignoreActiveFilter={true}
					/>
				</div>
			//</Reparentable>
		  );
	}
}

SuggestionCard.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	contentData: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(SuggestionCard);