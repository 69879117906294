/* eslint-disable no-useless-concat */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import Utils from './Utils';
import AppState from './AppState';
import App from './App';
import Translate from './Translate';
import Button from 'material-ui/Button';
import LockIcon from 'material-ui-icons/Lock';
import ArrowRight from 'material-ui-icons/ArrowForward';
import KeyboardArrowDown from 'material-ui-icons/KeyboardArrowDown';
import DeviceHub from 'material-ui-icons/DeviceHub';
import TripIcon from 'material-ui-icons/Place';
import PersonIcon from 'material-ui-icons/Person';
import SearchIcon from 'material-ui-icons/Search';
import Map from './components/Map';
import $ from 'jquery';
import { observable } from 'mobx';
import DropdownMenu from 'react-dd-menu';
import LandingPageFooter from './components/LandingPageFooter';
import AccountRequestPage from './pages/AccountRequestPage';
import Tracking from './Tracking';

const styles = theme => ({
});

@observer
class LandingPage extends React.Component
{
	@observable isLanguageMenuOpen = false;
	hasScrolledToApp = false;
	appScrollTimerTriggered = false;
	hasScrolled = false;
	@observable isLoading = true;
	@observable screenshot = 0;
	pageFooter;

	constructor(props)
	{
		super(props);

		this.isIE = Utils.DetectIEVersion() ? true : false;
	}

	componentDidMount()
	{
		AppState.instance.landingPage = this;

		$(window).on('scroll', this.OnScroll);

		this.forceUpdate();

		if (!this.isIE)
		{
			setTimeout(() =>
			{
				// Show phone frame a little bit to motivate scrolling down
				this.appScrollTimerTriggered = true;
				if (!this.hasScrolledToApp && !this.hasScrolled)
				{
					setTimeout(() => {
						$(".lpEmulation").css("transition", "transform 10ms cubic-bezier(0.3, 0.1, 0.7, 0.9) 0ms");
					}, 500);
				}
			}, 3000);
		}

		setTimeout(() => {
			$(".lpContainer").css('height', 'auto');
			this.isLoading = false;
		}, 250);

		const showWebcontentAtStart = AppState.instance.showWebcontentAtStart;
		const scrollDelay = 500;

		if (showWebcontentAtStart === "tos")
			this.OnTos();
		else if (showWebcontentAtStart === "pp")
			this.OnPP();
		else if (showWebcontentAtStart === "imprint")
			this.OnImprint();
		else if (showWebcontentAtStart === "features")
			this.ScrollTo('.lpSectionFeatures', undefined, true, scrollDelay);
		else if (showWebcontentAtStart === "activities")
			this.ScrollTo('.lpSectionMap', -70, true, scrollDelay);
		else if (showWebcontentAtStart === "start")
			this.ScrollTo('.lpSectionInstall', -32, true, scrollDelay);
		else if (showWebcontentAtStart === "about")
			this.ScrollTo('.lpSectionMission', undefined, true, scrollDelay);

		AppState.instance.showWebcontentAtStart = undefined;
	}

	componentWillUnmount()
	{
		AppState.instance.landingPage = undefined;
		$(window).off('scroll', this.OnScroll);
	}

	shouldComponentUpdate(nextProps, nextState)
	{
		// Prevents some refresh bugs
		return true;
	}

	OnFooterInit = (footer) =>
	{
		this.pageFooter = footer;
	}

	OnTos = () =>
	{
		if (this.pageFooter)
			this.pageFooter.OnTos();
	}

	OnPP = () =>
	{
		if (this.pageFooter)
			this.pageFooter.OnPP();
	}

	OnImprint = () =>
	{
		if (this.pageFooter)
			this.pageFooter.OnImprint();
	}

	OnScroll = () =>
	{
		//this.OnCoverScroll();

		if (AppState.instance.IsLoggedIn())
			return;

		var st = $(window).scrollTop();
		if (this.isIE)
			st = 1000;
		if (st > 0)
			this.hasScrolled = true;

		const threshold = window.innerHeight / 8;
		const percentTo2 = st / threshold; 

		if (percentTo2 >= 1)
		{
			$(".lpHeader").removeClass('lpHeader05');
			$(".lpHeader").addClass('lpHeader2');
			$(".lpHeader").css('backgroundColor', 'white');
		}
		else
		{
			if (percentTo2 >= 0.5)
			{
				$(".lpHeader").addClass('lpHeader05');
			}
			else
			{
				$(".lpHeader").removeClass('lpHeader05');
			}

			$(".lpHeader").removeClass('lpHeader2');
			$(".lpHeader").css('backgroundColor', 'rgba(255,255,255,' + percentTo2 + ')');
		}

		var lpCTA = $(".lpCTA");
		if (lpCTA && lpCTA.length > 0)
		{
			var lastCTA = $(".lpCTA").offset().top;
			lastCTA -= window.innerHeight;

			if (st > lastCTA || st < window.innerHeight * 0.5)
			{
				$(".lpHeaderCTA").addClass("lpHeaderCTA2");
			}
			else
			{
				$(".lpHeaderCTA").removeClass("lpHeaderCTA2");
			}
		}

		const appTH = window.innerHeight * 0.5;
		if (st > appTH)
		{
			this.hasScrolledToApp = true;
		}
	}

	OnLanguageMenuClicked = () =>
	{
		this.isLanguageMenuOpen = true;
	}

	OnLanguageMenuClose = () =>
	{
		this.isLanguageMenuOpen = false;
	}

	OnLanguageSelected = (lang) => () =>
	{
		Translate.SwitchLanguage(lang);
	}

	OnPersonClicked = (linkedinId) => () =>
	{
		Utils.OpenExternalLink("https://www.linkedin.com/in/" + linkedinId + "/");
	}

	ChangeScreenshot = (index) => () =>
	{
		this.screenshot = index;

		Tracking.SendEvent("lp_changescreenshot", {
			index: index
		});
	}

	RenderFeature(col, row, tabIndex, icon, title, text, screenshotIndex)
	{
		const selected = screenshotIndex === this.screenshot;
		const top = row * 200;

		return (
			<div
				className={"lpTapFeature" + (col === 0 ? " left":" right") + (selected ? " selected":"")}
				style={{top: top}}
				onClick={this.ChangeScreenshot(screenshotIndex)}
			>
				<div className={"lpFeatureIcon" + (col === 0 ? " left":" right") + (selected ? " selected":"")}>
					{icon}
				</div>
				<div className={"lpFeatureTitle" + (col === 0 ? " left":" right") + (selected ? " selected":"")}>
					{title}
				</div>
				<div className={"lpFeatureText" + (col === 0 ? " left":" right") + (selected ? " selected":"")}>
					{text}
				</div>
			</div>
		);
	}

	ScrollTo(selector, offset, jump, delay)
	{
		if (delay)
		{
			setTimeout(() => {
				this.ScrollTo(selector, offset, jump);
			}, delay);
		}
		else
		{
			var scrollTarget = $(selector).offset().top;
			if (offset)
				scrollTarget += offset;

			var body = $("html, body");

			if (jump)
			{
				body.scrollTop(scrollTarget);
			}
			else
			{
				body.stop().animate({scrollTop: scrollTarget}, 500, 'swing');
			}
		}
	}

	OnHeaderMenu = (selector, offset) => () =>
	{
		this.ScrollTo(selector, offset);
	}

	TryItHere = () =>
	{
		AppState.instance.chatPageInstance.NudgeCard();
	}

	OnOpenProvider = () =>
	{
		const url = AppState.instance.rootUrl + "/provider";
		window.open(url, '_blank');
	}

	OnRegisterAccount = (e) =>
	{
		e.stopPropagation();
		AccountRequestPage.Show();
	}

	renderDesktopLoginCta()
	{
		return (
			<div className="desktopLoginContainer">
				<div className="intro">
					{Translate.T("en.Or get started right here", "de.Oder starte gleich hier", "landingpage.getstarted.here")}
				</div>
				<Button onClick={this.OnRegisterAccount}>
					{Translate.T("en.Register Account", "de.Account anlegen", "landingpage.getstarted.register")}
				</Button>
			</div>
		);
	}

	OnAdventureCta = () =>
	{
		Tracking.SendEvent("adv_landingpage_cta");

		window.open("/abenteuer", "_self");
	}

	render()
	{
		const iconSize = 48;
		const iconColor = "#aaa";

		var iconChat = <div className="menuIconChat" style={{width: iconSize, height: iconSize}}>{Utils.RenderCardIcon(false, iconSize, iconSize, iconColor)}</div>;
		var iconSaved = <div className="menuIconChat" style={{width: iconSize, height: iconSize}}>{Utils.RenderStarIcon(iconSize, iconSize, iconColor, iconColor, 2.5)}</div>;

		//var iconJournals = <AddCircleOutline style={{color: iconColor, width: iconSize, height: iconSize }} />;
		var iconExplore = <SearchIcon style={{color: iconColor, width: iconSize, height: iconSize }} />;
		var iconCommunity = <DeviceHub style={{color: iconColor, width: iconSize, height: iconSize }} />;
		var tripIcon = <TripIcon style={{color: iconColor, width: iconSize, height: iconSize }} />;
		var iconProfile = <PersonIcon style={{color: iconColor, width: iconSize, height: iconSize }} />;

		const menuOptions = {
			isOpen: this.isLanguageMenuOpen,
			close: this.OnLanguageMenuClose,
			toggle: (<Button className="lpHeaderItem" onClick={this.OnLanguageMenuClicked}>
				{AppState.instance.userProfile.userLang === 'de' && Translate.T("de.Deutsch", "en.German", "lp.lang.german")}
				{AppState.instance.userProfile.userLang !== 'de' && Translate.T("de.Englisch", "en.English", "lp.lang.english")}
				<KeyboardArrowDown/>
			</Button>),
			align: 'right'
		};

		var centerLat;
		var centerLng;
		const loc = AppState.instance.GetBestLocation();
		if (loc)
		{
			centerLat = loc.latitude;
			centerLng = loc.longitude;
		}

		return (
			<div className="lpContainer" >
				{this.isLoading !== false && <div className="lpContainerLoader" 
					style={{
						width: '100vw',
						height: '100vh',
						backgroundColor: 'white',
						position: 'fixed',
						zIndex: '99999'
					}}
				/>}
				
				<div className="lpHeader">
					<div className="lpCol0" />

					<div className="lpCol1">
						<div className="lpBrand">
							<div className="lpLogo">
								<img src="/assets/logons64.png" alt="logo" />
							</div>
							<div className="lpLogoText">
								LENA
							</div>
							<div className="lpLogoTextSub">
								Inspiring • Families
							</div>
						</div>
					</div>

					<div className="lpCol2 lpHeaderMain">
						<div className="lpHeaderItems">
							<Button className="lpHeaderItem" onClick={this.OnHeaderMenu('.lpSectionFeatures')}>
								{Translate.T("en.Features", "de.Features", "lp.header.features")}
							</Button>
							<Button className="lpHeaderItem" onClick={this.OnHeaderMenu('.lpSectionMap', -70)}>
								{Translate.T("en.Things to do", "de.Familienaktivitäten", "lp.header.thingstodo")}
							</Button>
							<Button className="lpHeaderItem lpHeaderCTA" onClick={this.OnHeaderMenu('.lpSectionInstall', -32)}>
								{Translate.T("en.Get started", "de.Lege los", "lp.header.getstarted")}
							</Button>
							<Button className="lpHeaderItem" onClick={this.OnOpenProvider}>
								{Translate.T("en.Get your activity listed", "de.Angebot eintragen", "lp.header.provider")}
							</Button>
							<Button className="lpHeaderItem" onClick={this.OnHeaderMenu('.lpSectionMission')}>
								{Translate.T("en.About LENA", "de.Über LENA", "lp.header.mission")}
							</Button>
							
							{/* <Button className="lpHeaderItem" onClick={this.OnHeaderMenu('.lpFooter')}>
								{Translate.T("en.Contact", "de.Kontakt", "lp.header.contact")}
							</Button> */}
						</div>

						<div className="lpHeaderLanguages">
							<Button onClick={this.OnRegisterAccount} className="loginButton">
								<PersonIcon />
							</Button>
							<DropdownMenu className="lpLanguageMenu" {...menuOptions}>
								<li>
									<Button className={"lpLanguageMenuButton"} onClick={this.OnLanguageSelected('de')}>
										{Translate.T("de.Deutsch", "en.German", "lp.lang.german")}
									</Button>
								</li>
								<li>
									<Button className={"lpLanguageMenuButton"} onClick={this.OnLanguageSelected('en')}>
										{Translate.T("de.Englisch", "en.English", "lp.lang.english")}
									</Button>
								</li>
							</DropdownMenu>
						</div>
					</div>					
				</div>

				<div className="lpSection lpSection0">

					<div className="lpBackgroundImage" />

					<div className="lpCol0" />

					<div className="lpCol1" />

					<div className="lpCol2">
						<div className="lpMktClaim">
							{Translate.T("en.Simple. Quick.\nGreat things to do for your family.",
								"de.Einfach. Schnell.\nPassende Ausflüge für Deine Familie.", "landingpage.marketingclaim")}
						</div>

						<div className="lpMktClaim2">
							{Translate.T("en.Get inspired for diverse family quality time. Out of thousands of activities, events, workshops and restaurants, LENA helps you find the ones matching your family's interests and needs.",
								"de.Lass Dich inspirieren zu mehr und abwechslungsreicher Zeit mit Deiner Familie. LENA hilft Dir und Deiner Familie aus tausenden Ausflugszielen, Veranstaltungen, Workshops und Restaurants jene zu finden, die perfekt zu Euren Interessen und Bedürfnissen passen.", "landingpage.marketingclaim2")}
						</div>

						<Button onClick={this.OnHeaderMenu('.lpSectionFeatures')} className="lpButtonLearnMore">
							{Translate.T("en.Learn more", "de.Mehr erfahren", "lp.learnmore")}
						</Button>

						<Button onClick={this.OnRegisterAccount} className="lpButtonRegisterTop">
							{Translate.T("en.Register Account", "de.Account anlegen", "landingpage.getstarted.register")}
						</Button>
					</div>

				</div>

				<div className="lpSection lpSectionFeatures">

					<div className="lpCol0" />

					<div className="lpCol12" >

						<div className="lpEmulation">

							<div className="lpScreenshot">
								{this.screenshot === 0 && <img src="/assets/screenshots/swipe.png" alt="swipe" />}
								{this.screenshot === 1 && <img src="/assets/screenshots/timeline.png" alt="timeline" />}
								{this.screenshot === 2 && <img src="/assets/screenshots/bookmarks.png" alt="bookmarks" />}
								{this.screenshot === 3 && <img src="/assets/screenshots/map.png" alt="map" />}
								{this.screenshot === 4 && <img src="/assets/screenshots/details.png" alt="details" />}
								{this.screenshot === 5 && <img src="/assets/screenshots/profile.png" alt="profile" />}
							</div>

							<div className="lpPhoneImg">
								<img src="/assets/phone_small.png" alt="phone" />
							</div>

							<div className="lpBottomMenu">
								<div className="lpBottomMenuItem" onClick={this.ChangeScreenshot(1)} />
								<div className="lpBottomMenuItem" onClick={this.ChangeScreenshot(0)} />
								<div className="lpBottomMenuItem" onClick={this.ChangeScreenshot(3)} />
								<div className="lpBottomMenuItem" onClick={this.ChangeScreenshot(2)} />
								<div className="lpBottomMenuItem" onClick={this.ChangeScreenshot(5)} />
							</div>

							<div className="lpAppContainer">
								<App appState={this.appState}/>
							</div>
						</div>
						
						<div className="lpFeaturesTitle">
							{Translate.T("en.How it works", "de.So funktioniert es", "lp.features.title")}
						</div>

						{/* <div className="lpTryIt" onClick={this.TryItHere}>
							{AppState.instance.userProfile.userLang === 'de' && <img className="lpTryText" src="/assets/probiere.png" alt="try it" />}
							{AppState.instance.userProfile.userLang !== 'de' && <img className="lpTryText" src="/assets/try.png" alt="try it" />}
							<img className="lpTryArrow" src="/assets/arrow_white.png" alt="arrow" />
						</div> */}

						<div className="lpFeatureContainer">

							{this.RenderFeature(0, 0, AppState.TabIndexSwipe, iconChat,
								Translate.T("en.Swipe through suggestions", "de.Swipe durch die Vorschläge", "landingpage.feature.swipe.title"),
								Translate.T("en.LENA offers you great family activity options in an easy to use swipe-interface. " +
									"LENA matches the suggestions with your interests & needs and the weather.",
									"de.LENA präsentiert Dir mit Hilfe eines moderenen Swipe-Interfaces tolle Vorschläge. " +
									"LENA passt ihre Vorschläge an Deine Interessen & Bedürfnisse und das Wetter an.", "landingpage.feature.swipe.desc"), 0)}

							{this.RenderFeature(1, 0, AppState.TabIndexExplore, iconExplore,
								Translate.T("en.Explore all options", "de.Durchforste alle Möglichkeiten", "landingpage.feature.explore.title"),
								Translate.T("en.Search directly for activities, sort and filter by distance and category or explore the interactive map.",
									"de.Suche direkt nach Einträgen, sortiere und filtere nach Entfernung und Kategorie oder stöbere in einer interaktiven Landkarte.",
									"landingpage.feature.explore.desc"), 3)}

							{this.RenderFeature(0, 1, AppState.TabIndexTimeline, iconCommunity,
								Translate.T("en.Be part of a community", "de.Werde Teil einer Community", "landingpage.feature.add.title"),
								Translate.T("en.Help other families by suggesting your insider tips near you. With the help of LENA's Artificial Intelligence it takes only seconds to create your own entries.",
									"de.Hilf anderen Familien mit Deinen Geheimtipps in Deiner Nähe. Mit Hilfe der künstlichen Intelligenz von LENA ist der Eintrag in ein paar Sekunden erstellt.",
									"landingpage.feature.add.desc"), 1)}

							{this.RenderFeature(1, 1, AppState.TabIndexJournal, tripIcon,
								Translate.T("en.Plan your trips", "de.Plane Deine Ausflüge", "landingpage.feature.trips.title"),
								Translate.T("en.It's a breeze to plan your day trips with LENA. Including automatic incorporation of weather forecasts and travel durations.",
									"de.Mit LENA kannst Du Eure Ausflüge kinderleicht planen. Wettervorhersagen und Reisezeiten werden automatisch berücksichtigt.",
									"landingpage.feature.trips.desc"), 4)}

							{this.RenderFeature(0, 2, AppState.TabIndexBookmark, iconSaved,
								Translate.T("en.Bookmarks", "de.Lesezeichen", "landingpage.feature.bookmark.title"),
								Translate.T("en.Save your favourites in a convenient bookmark list and access them at any time.",
									"de.Speichere Dir Deine Lieblingseinträge in einer übersichtlichen Liste und rufe sie jederzeit wieder auf.",
									"landingpage.feature.bookmark.desc"), 2)}

							{this.RenderFeature(1, 2, AppState.TabIndexProfile, iconProfile,
								Translate.T("en.Personal Profile", "de.Persönliches Profil", "landingpage.feature.profile.title"),
								Translate.T("en.Create your personal profile and invite your family and friends.\n" +
										"Share your bookmarks and plan joint trips together.",
									"de.Lege Dir ein persönliches Profil an und lade Deine Familie und Freunde in Deinen Kreis ein.\n" +
										"Teilt Eure Lesezeichen und plant gemeinsame Ausflüge.",
									"landingpage.feature.profile.desc"), 5)}

						</div>
					</div>
				</div>

				<div className="lpSection lpSectionInstall">

					<div className="lpCol0" />

					<div className="lpCol1">
						<div className="lpCTA">
							{Translate.T("en.Grab your smartphone and open",
								"de.Schnapp Dir Dein Handy und öffne", "landingpage.tryit")}
						</div>
					</div>

					<div className="lpCol2">

						<div className="lpInstallIntro">
							{Translate.T("en.Get LENA onto your smartphone or tablet",
								"de.Bring LENA auch auf Dein Smartphone oder Tablet", "landingpage.tryit.pre")}
							{/*Translate.T('en.LENA helps wherever you are', 'de.LENA ist überall für Dich bereit', 'landingpage.install.intro')*/}
						</div>

						<div className="lpCTAPre">
							{Translate.T("en.No installation required.",
								"de.Keine Installation notwendig.", "landingpage.tryit.pre")}
						</div>

						<div className="lpBrowser">
							<div className="lpBrowserBar">
								<div className="lpBrowserBarInner">
									<LockIcon />
									<span>www.lena.app</span>
								</div>
								<div className="lpBrowserArrow">
									<ArrowRight />
								</div>
							</div>
						</div>

						{this.renderDesktopLoginCta()}

						<div className="lpInstallIntro2">
							{Translate.T('en.LENA is supported by all major devices and browsers.', 'de.LENA wird von allen gängigen Geräten und Browsern unterstützt.', 'landingpage.install.intro')}
						</div>

						<div className="lpInstallImages">
							<img className="lpInstallOs" src="/assets/osicons.png" alt="operating systems" />
							<img className="lpInstallBrowsers" src="/assets/browsers.png" alt="browsers" />
						</div>
					</div>
				</div>

				{/* <div className="lpSection lpSectionAdventure">
					<div className="lpCol0" />

					<div className="lpCol1">
						<div className="image"/>

						<div className="advCta">
							<Button onClick={this.OnAdventureCta}>
								{Translate.T("de.Mehr erfahren", "en.More information", "landingpage.adv.cta")}
							</Button>
						</div>
					</div>

					<div className="lpCol2">
						<div className="lpMapIntro">{Translate.T("de.Bist Du bereit für aufregende Abenteuer während Deiner Familienausflüge?",
							"en.Are your ready for exciting adventures during your family trips?", "advannouncement.claim0")}</div>

						<div className="lpMapIntro2">{Translate.T("de.Erlebe gemeinsam mit Deinen Kindern fantastische, interaktive Geschichten und schnappe Dir dabei LENAs Abenteuerkarten.",
							"en.Experience fantastic, interactive stories together with your kids and fetch LENA's adventure cards on the go.", "advannouncement.text0")}</div>					
					</div>
				</div> */}

				<div className="lpSection lpSectionMap">

					<div className="lpCol0" />

					<div className="lpCol1">
						<div className="lpMapContainer">
							<Map
								id="lpMap"
								zoomFactor={10}
								gestureHandling="cooperative"
								maxNumCategories={2}
								disableBottomClick={false}
								filterPhoto={true}
								disableFilterButton={true}
								contentMode={"tiny"}
								loadOnVisibility={false}
								centerLat={centerLat}
								centerLng={centerLng}
								gridSize={12}
							/>
						</div>
					</div>

					<div className="lpCol2">

						<div className="lpMapIntro">
							{Translate.T("de.Tausende Ausflugsziele, Veranstaltungen, Workshops und Restaurants für Familien", "en.Thousands of Family Trip Destinations, Events, Workshops and Restaurants", "lp.mapintro")}
						</div>

						<div className="lpMapIntro2">
							{Translate.T("de.Zuviele um selbst zu suchen?\nLENA findet die passenden Vorschläge für Dich!", "en.Too much to search for yourself?\nLENA finds the best options for you!", "lp.mapintro2")}
						</div>
					</div>

				</div>

				

				<div className="lpSection lpSectionInstall2">

					<div className="lpCol0" />

					<div className="lpCol1">
						<div className="lpCTA">
							{Translate.T("en.Grab your smartphone and open",
								"de.Schnapp Dir Dein Handy und öffne", "landingpage.tryit")}
						</div>
					</div>

					<div className="lpCol2">

						<div className="lpBrowser">
							<div className="lpBrowserBar">
								<div className="lpBrowserBarInner">
									<LockIcon />
									<span>www.lena.app</span>
								</div>
								<div className="lpBrowserArrow">
									<ArrowRight />
								</div>
							</div>
						</div>

						{this.renderDesktopLoginCta()}
					</div>
					
				</div>

				<div className="lpSection lpSectionMission">

					<div className="lpCol0" />

					<div className="lpCol1">
						<img className="lpMissionImage" src="/assets/md.jpg" alt="mother daughter nature" />
					</div>

					<div className="lpCol23">

						<div className="lpMissionHeader">
							{Translate.T("de.Unsere Mission", "en.Our mission", "lp.mission.intro")}
						</div>

						<div className="lpMissionText">
							{Translate.T(
								"de.\"Wir wollen Familien zu abwechslungsreichen Aktivitäten inspirieren. " + 
									"Familie kann Euer Nachbar sein, zur Familie können Eure Freunde gehören - Familie sind Menschen, die Ihr liebt und denen Ihr vertraut.\n" +
									"Wir wollen für Euch Möglichkeiten schaffen, Euch mit jenen Menschen zu verbinden, die Ihr liebt. " +
									"Wir wollen Euch dazu inspieren, das Leben gemeinsam zu erfahren - das ist die Essenz einer Familie. Das liegt LENA am Herzen.\"",
								"en.\"Our Mission is to inspire families to diversified activities. "+
									"Family is your neighbour, family is your friend, family is someone you love and trust.\n" +
									"We want to give you the opportunity to connect with the people you love. " + 
									"We want to inspire you to experience life together - it is essence of being a family.\nThis is the core of LENA.\"",
								"lp.mission.text")}
						</div>
						<div className="lpMissionName" onClick={this.OnPersonClicked('matthias-bauchinger-02164819')}>
							{Translate.T("de.Matthias Bauchinger, Gründer von LENA und Vater von drei Kindern", "en.Matthias Bauchinger, Founder of LENA and father-of-three", "lp.mission.name")}
						</div>
						
					</div>

				</div>

				<LandingPageFooter onInit={this.OnFooterInit} />

			</div>
		);
	}
}

LandingPage.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(LandingPage);