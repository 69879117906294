import React from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import Tracking from '../Tracking';
import RestApi from '../RestApi';
import AppState from '../AppState';
import Translate from '../Translate';

@observer
export class WebContentPage extends React.Component
{
	contentTitle;
	@observable text;
	static clickHook;
	
	componentDidMount()
	{
		window.OnWebContentClick = function(msg)
		{
			WebContentPage.OnWebContentClick(msg);
		}

		Tracking.OnPageView("webcontent/" + this.contentTitle);

		var thisPtr = this;
		RestApi.SendRequest("/content", {title: this.contentTitle, language: AppState.instance.userProfile.userLang}).then((content) =>
		{
			if (content)
			{
				thisPtr.text = this.PreprocessContent(content.content);
			}
			else
			{
				AppState.instance.screenMessage.Show("Error while loading page content");
			}
		});
	}

	PreprocessContent(content)
	{
		//&lt;a onclick=webcontentclick(\'open.pp\')&gt;
		var startIdx = -1;
		for (var i = 0; i < 1000; ++i)
		{
			startIdx = content.indexOf("&lt;a onclick=");
			if (startIdx < 0)
				break;
			const endIdx = content.indexOf("&gt;", startIdx);

			const before = content.substring(0, startIdx);
			const msg = content.substring(startIdx + 14, endIdx);

			content = before + "<a onclick=\"window.OnWebContentClick('" + msg + "')\">" + content.substring(endIdx + 4);

			content = content.replace("&lt;/a&gt;", "</a>");
		}
		
		return content;
	}

	static OnWebContentClick(msg)
	{
		if (WebContentPage.clickHook)
		{
			const shouldContinue = WebContentPage.clickHook(msg);
			if (!shouldContinue)
				return;
		}

		if (msg === "open.pp")
		{
			AppState.instance.screenMessage.ShowDialog(Translate.T('en.Privacy Policy', 'de.Datenschutzbestimmungen', 'privacypolicy'), PPPage);
		}
		else if (msg === "open.tos")
		{
			AppState.instance.screenMessage.ShowDialog(Translate.T('en.Terms of Service', 'de.Nutzungsbedingungen', 'tos'), ToSPage);
		}
		else if (msg === "ga.optout")
		{
			console.log("ga.optout");
			var gaProperty = 'UA-105568793-1';
			var disableStr = 'ga-disable-' + gaProperty;
			document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
			window[disableStr] = true;
		}
		else if (msg === "hj.optout")
		{
			console.log("hj.optout");
			disableStr = 'hj-disable';
			document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
			window[disableStr] = true;
		}
	}

	render()
	{
		Tracking.OnRender(this.contentTitle + "Page");

		return (
			<div
				className="webContentPage"
				key={this.contentTitle + "Page"}
			>
				<div className="webContent" dangerouslySetInnerHTML={{ __html: this.text }} />
			</div>
		);
	}
}

export class ToSPage extends WebContentPage
{
	constructor(props)
	{
		super(props);
		this.contentTitle = "tos";
	}
}

export class PPPage extends WebContentPage
{
	constructor(props)
	{
		super(props);
		this.contentTitle = "pp";
	}
}

export class ImprintPage extends WebContentPage
{
	constructor(props)
	{
		super(props);
		this.contentTitle = "imprint";
	}
}
