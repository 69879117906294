import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import AppState from '../AppState';
import AccountRequestPage from './AccountRequestPage';
import Translate from '../Translate';
import Button from 'material-ui/Button';
import UserListCard from '../components/UserListCard';
import UserListSubPage from './UserListSubPage';
import AddIcon from 'material-ui-icons/Add';
import UserListSelection from '../components/UserListSelection';
import Tracking from '../Tracking';
import Tabs, { Tab } from 'material-ui/Tabs';

const styles = theme => ({
	root: {
	  backgroundColor: theme.palette.background.paper,
	},
	tabsRoot: {
		position: 'absolute',
		top: '0px',
		zIndex: '1',
		backgroundColor: theme.palette.background.paper,
		boxShadow: '0 1px 3px 0px rgba(0, 0, 0, 0.08)',
		width: '100%',
	},
	swipeButtonRoot: {
		height: '100%',
		fontWeight: '700',
		fontSize: '12px',
		paddingLeft: '8px',
		paddingRight: '8px',
		width: '100%',
		borderRadius: 19
	},
	badge: {
		//position: 'absolute',
		//top: '19px',
		//right: '20px'
		marginRight: 10
	},
});

@observer
class BookmarkPage extends React.Component
{
	swipeContext = ""; //TODO: === listName, listId
	subPageList;
	@observable showNewDialog = false;

	componentDidMount()
	{
		AppState.instance.bookmarkPageInstance = this;
		AppState.instance.listManager.onListsChanged.Add(this.OnListsChanged);
	}

	componentWillUnmount()
	{
		AppState.instance.listManager.onListsChanged.Remove(this.OnListsChanged);
		AppState.instance.bookmarkPageInstance = undefined;
	}

	OnActivate()
	{
		AppState.instance.listManager.RefreshData();
		AppState.instance.showNewIndicator[AppState.TabIndexBookmark] = undefined;
		this.forceUpdate();
	}

	OnListsChanged = () =>
	{
		this.forceUpdate();
		AppState.instance.appContainer.forceUpdate();
		this.forceUpdate();
	}

	OnLoginButton = () =>
	{
		var msg = Translate.FindTranslation("accountrequest.bookmark");
		AccountRequestPage.Show(msg);
	}

	OnSuggestionCardThrowout(content, direction)
	{
	}

	OnCardClicked = (list) =>
	{
		if (AppState.instance.deviceInfo.desktop)
		{
			AppState.instance.appContainer.GoTo("/bookmarks/" + encodeURIComponent(list.title), true);
		}
		else
		{
			this.subPageList = list;
			this.OnSubPageChanged();
		}
	}

	OnSubPageChanged()
	{
		if (this.subPageList)
		{
			Tracking.OnPageView("bookmark/shortlist");
		}
		else
		{
			Tracking.OnPageView("bookmark");
		}
		this.forceUpdate();
		AppState.instance.appContainer.forceUpdate();
	}

	MatchUiWithUrl()
	{
		var idx = window.location.pathname.indexOf("/bookmarks/");
		if (idx >= 0)
		{
			var listTitle = window.location.pathname.substring(idx + 11);
			if (listTitle && listTitle.length > 0)
			{
				listTitle = decodeURIComponent(listTitle);
				this.subPageList = AppState.instance.listManager.GetListByTitle(listTitle);
				this.OnSubPageChanged();
			}
		}
		else
		{
			this.subPageList = undefined;
			this.OnSubPageChanged();
		}
	}

	OnSubPageClose = () =>
	{
		if (AppState.instance.deviceInfo.desktop)
		{
			AppState.instance.appContainer.GoTo("/", true);
		}
		else
		{
			this.subPageList = undefined;
			this.OnSubPageChanged();
		}
	}

	OnDeleteList = () =>
	{
		if (AppState.instance.swipeListId === this.subPageList.id)
		{
			var lists = AppState.instance.listManager.GetLists();
			if (lists.length > 0)
				AppState.instance.swipeListId = lists[0].id;
		}

		if (this.subPageList)
		{
			AppState.instance.listManager.DeleteList(this.subPageList.id);
		}
		this.subPageList = undefined;
		this.OnSubPageChanged();
	}

	OnSwitchList = (listId) =>
	{
		this.subPageList = AppState.instance.listManager.GetListById(listId);
		this.OnSubPageChanged();
	}

	OnAddButtonClicked = () =>
	{
		if (AppState.instance.IsLoggedIn())
		{
			this.showNewDialog = false;
			this.forceUpdate();
			this.showNewDialog = true;
			this.forceUpdate();
			AppState.instance.appContainer.forceUpdate();
		}
		else
		{
			var msg = Translate.T("en.Create as many lists as you like", "de.Erstelle soviele Lesezeichen-Listen wie Du möchtest", "accountrequest.newuserlist");
			AccountRequestPage.Show(msg);
		}
	}

	RenderFab()
	{
		return (
			<div className="bookmarkAddButton">
				<Button fab color="primary" aria-label="add"
					onClick={this.OnAddButtonClicked}
				>
					<AddIcon />
				</Button>
			</div>
		);
	}

	OnHeaderTabChanged = (event, tabIndex) =>
	{
		// AppState.instance.appInstance.OnTabbarChanged(event, tabIndex === 0 ? AppState.TabIndexBookmark : AppState.TabIndexAdventure);
		
		// setTimeout(() => {
		// 	AppState.instance.appContainer.forceUpdate();	
		// }, 100);
	}

	RenderSubPage()
	{
		if (this.subPageList)
		{
			return <UserListSubPage
				list={this.subPageList}
				onClose={this.OnSubPageClose}
				onDelete={this.OnDeleteList}
				onSwitchList={this.OnSwitchList}
				className="userListDialog"
			/>;
		}
		else
		{
			return <div className="bookmarkPageHeader">
				<Tabs
					value={0}
					onChange={this.OnHeaderTabChanged}
					indicatorColor="primary"
					textColor="primary"
					//classes={{
					//	root: styles.tabsRoot,
					//}}
				>
					<Tab value={0} key={0} label={Translate.T("en.Bookmarks", "de.Lesezeichen", "bookmarkpage.header.bookmarks")} />
					{/* <Tab value={1} key={1} label={Translate.T("en.Adventures", "de.Abenteuer", "bookmarkpage.header.adventures")} /> */}
					{/*AppState.instance.advManager.enabled &&
						<Tab value={1} key={1} label={Translate.T("en.Adventure Cards", "de.Abenteuerkarten", "bookmarkpage.header.adventurecards")} />*/}
				</Tabs>
			</div>
		}
	}

	OnNewDialogClose = () =>
	{
		this.showNewDialog = false;
		this.forceUpdate();
		AppState.instance.appContainer.forceUpdate();
	}

	renderAddListButton()
	{
		return (
			<div className="bookmarkAddButtonDesktop">
				<Button color="primary" onClick={this.OnAddButtonClicked}>
					{Translate.T("en.Add Bookmark List", "de.Lesezeichen Liste hinzufügen", "bookmarkpage.addlist.desktop")}
				</Button>
			</div>
		);
	}

	render()
	{
		if (!AppState.instance.GeneralDataLoadingEnabled())
			return null;
			
		if (AppState.instance.deviceInfo.desktop && this.subPageList)
			return this.RenderSubPage();

		var warning;
		// const isLoggedIn = AppState.instance.IsLoggedIn();
		// if (!isLoggedIn)
		// {
		// 	if (!isEmpty)
		// 	{
		// 		warning = <div className="bookmarkNoAccountWarning">
		// 			<div className="bookmarkNoAccountWarningText">
		// 				{Translate.T("en.Create your personal account to use all services of LENA.", "de.Erstelle Deinen persönlichen Account um alle Services von LENA zu verwenden.", "bookmarks.loginrequest")}
		// 			</div>
		// 			<Button color="default" raised onClick={this.OnLoginButton}>
		// 				{Translate.T("en.Create Account", "de.Account erstellen", "createaccount")}
		// 			</Button>
		// 		</div>;
		// 	}
		// }

		var lists = AppState.instance.listManager.GetLists();

		// if (lists && !AppState.instance.isBetaTester)
		// {
		// 	for (var i = lists.length - 1; i >= 0; --i)
		// 	{
		// 		if (AppState.instance.listManager.IsCheckInList(lists[i].id))
		// 		{
		// 			lists.splice(i, 1);
		// 			break;
		// 		}
		// 	}
		// }

		var s = {};
		if (AppState.instance.currentMainTab !== AppState.TabIndexBookmark)
			s = {'display': 'none'};

		return (
			<div id="bookmarkPage" className="bookmarkPage" style={s}>
				{warning}

				{AppState.instance.deviceInfo.desktop && this.renderAddListButton()}

				{lists.map((list, index) =>
				{
					if (list.flag >= 1 && list.flag <= 4) // friends, family,...
						return null;

					return (
						<UserListCard
							list={list}
							key={index}
							onClick={this.OnCardClicked}
						/>
					);
				})}

				<UserListSelection
					dialogMode={true}
					visible={this.showNewDialog}
					onClose={this.OnNewDialogClose}
				/>

			</div>
		);
	}
}

BookmarkPage.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(BookmarkPage);