import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import SearchField from '../SearchField';
import SearchIcon from 'material-ui-icons/Search';
import SearchPage from '../../pages/SearchPage';
import AppState from '../../AppState';
import Translate from '../../Translate';
import ProfilePicture from '../ProfilePicture';
import Button from 'material-ui/Button';
import { observable } from '../../../node_modules/mobx';
import SettingsPage from '../../pages/SettingsPage';
import EditProfilePage from '../../pages/EditProfilePage';
import {ToSPage, PPPage, ImprintPage} from '../../pages/WebContentPage';
import ListMenu from '../ListMenu';
import Menu from 'material-ui/Menu';
import AccountRequestPage from '../../pages/AccountRequestPage';
import $ from 'jquery';
import ClearIcon from 'material-ui-icons/Clear';
import { CircularProgress } from 'material-ui';

const styles = theme => ({	
});

@observer
class DesktopMenu extends React.Component
{
	@observable showProfileMenu = false;
	@observable searchHasFocus = false;
	@observable isSearching = 0;
	@observable isLocationSearching = false;
	menuAnchor;
	searchCategoryId;

	componentDidMount()
	{
		AppState.instance.onStartLocationSearch.Add(this.OnStartLocationSearch);
		AppState.instance.onLocationSearchDone.Add(this.OnEndLocationSearch);

		const showWebcontentAtStart = AppState.instance.showWebcontentAtStart;

		if (showWebcontentAtStart === "tos")
			this.OnTos();
		else if (showWebcontentAtStart === "pp")
			this.OnPP();
		else if (showWebcontentAtStart === "imprint")
			this.OnImprint();

		AppState.instance.showWebcontentAtStart = undefined;
	}

	OnTos = () =>
	{
		const label = Translate.T('en.Terms of Service', 'de.Nutzungsbedingungen', 'tos');
		AppState.instance.screenMessage.ShowDialog(label, ToSPage);
	}

	OnPP = () =>
	{
		const label = Translate.T('en.Privacy Policy', 'de.Datenschutzbestimmungen', 'privacypolicy');
		AppState.instance.screenMessage.ShowDialog(label, PPPage);
	}

	OnImprint = () =>
	{
		const label = Translate.T('en.Imprint', 'de.Impressum', 'imprint');
		AppState.instance.screenMessage.ShowDialog(label, ImprintPage);
	}

	OnStartLocationSearch = () =>
	{
		this.isLocationSearching = true;
	}

	OnEndLocationSearch = () =>
	{
		this.isLocationSearching = false;
	}

	OnMenuClicked = (tabIndex) => () =>
	{
		if (this.props.onPageSelected)
			this.props.onPageSelected(tabIndex);
	}

	OnProfileMenuClicked = (e) =>
	{
		this.menuAnchor = e.currentTarget;
		this.showProfileMenu = !this.showProfileMenu;
	}

	OnChatBotClicked = () =>
	{
		if (this.props.onChatBotClicked)
			this.props.onChatBotClicked();
	}

	OnSearchDataChanged = (action, newData) =>
	{
		if (action === 'request')
		 	++this.isSearching;
		else if (action === 'data' || action === 'error' || action === 'abort' || action === 'eod' || action === 'flushed')
			this.isSearching = 0;
	}

	OnEnter = (searchTerm) =>
	{
		this.OnSearch(searchTerm);
		return true;
	}

	OnSearch = (searchTerm) =>
	{
		//console.log(searchTerm);
		AppState.instance.lastEnteredSearchString = searchTerm;

		if (!AppState.instance.isInLocationSearch)
		{
			AppState.instance.userProfile.OnSearch(searchTerm);
			AppState.instance.contentStore.Search(searchTerm);
		}

		if (AppState.instance.mainPageInstance)
			AppState.instance.mainPageInstance.OnSearch(searchTerm);

		this.forceUpdate();
	}

	OnSearchFocus = () =>
	{
		this.searchHasFocus = true;
		//console.log("OnFocus");

		this.searchCategoryId = AppState.instance.filterManager.CreateFilterId(AppState.instance.filterStack);
		AppState.instance.contentStore.AddOnDataChanged(this.searchCategoryId, this.OnSearchDataChanged);
	}

	OnSearchBlur = () =>
	{
		setTimeout(() =>
		{
			this.searchHasFocus = false;
			$(".detailSearch #searchInput").val("");
			
			AppState.instance.contentStore.RemoveOnDataChanged(this.searchCategoryId, this.OnSearchDataChanged);

			this.OnSearch(undefined);
			this.isSearching = 0;

			if (AppState.instance.isInLocationSearch)
			{
				AppState.instance.isInLocationSearch = false;
			}
			//console.log("OnSearchBlur");
		}, 300);
	}

	OnProfileMenuRequestClose = () =>
	{
		this.showProfileMenu = false;
		this.menuAnchor = undefined;
	}

	OnProfileMenuItemClicked = () =>
	{
		this.OnProfileMenuRequestClose();
	}

	OnLogoutClicked = () =>
	{
		AppState.instance.OnLoggedOut();
	}

	OnLoginClicked = () =>
	{
		AccountRequestPage.Show();
	}

	OnClearInput = () =>
	{
		$(".detailSearch #searchInput").val("");
		this.OnSearch(undefined);
		
		setTimeout(() => {
			$(".detailSearch #searchInput").focus();
		}, 500);
	}

	OnSearchResultClicked = (contentData, e) =>
	{
		this.OnSearchBlur();
		
		if (contentData && contentData.url)
		{
			AppState.instance.appContainer.GoTo(contentData.url, true);
		}
	}

	OnLogoClicked = () =>
	{
		window.open("https://www.lena.app", "_self");
	}

	render()
	{
		const searchResultsShown = this.searchHasFocus && !AppState.instance.isInLocationSearch &&
			AppState.instance.lastEnteredSearchString && AppState.instance.lastEnteredSearchString.length > 0;
		const showSearchPage = this.searchHasFocus && !AppState.instance.isInLocationSearch;

		const isLoggedIn = AppState.instance.IsLoggedIn();

		var menuEntries = [];
		if (isLoggedIn)
		{
			menuEntries.push({id: 0, key: "EditProfilePage", label: Translate.T('en.Edit profile', 'de.Profil bearbeiten', 'editprofile'), component: EditProfilePage});
		}

		menuEntries.push({id: 1, key: "SettingsPage", label: Translate.T('en.Account-Settings', 'de.Account-Einstellungen', 'settings'), component: SettingsPage});
		//menuEntries.push({id: 1, label: 'Help', component: null});
		//menuEntries.push({id: 2, label: 'Feedback', component: null});
		menuEntries.push({id: 2, key: "TosPage", label: Translate.T('en.Terms of Service', 'de.Nutzungsbedingungen', 'tos'), component: ToSPage, className: "tos webcontent"});
		menuEntries.push({id: 3, key: "PPPage", label: Translate.T('en.Privacy Policy', 'de.Datenschutzbestimmungen', 'privacypolicy'), component: PPPage, className: "pp webcontent"});
		menuEntries.push({id: 4, key: "ImprintPage", label: Translate.T('en.Imprint', 'de.Impressum', 'imprint'), component: ImprintPage, className: "imprint webcontent"});

		if (isLoggedIn)
			menuEntries.push({id: 5, key: "logout", label: Translate.T('en.Logout', 'de.Abmelden', 'logout'), onClick: this.OnLogoutClicked});
		else
			menuEntries.push({id: 6, key: "login", label: Translate.T('en.Log In', 'de.Anmelden', 'login'), onClick: this.OnLoginClicked});

		const isSearching = (this.isSearching !== 0) || this.isLocationSearching;
		const showClearIcon = AppState.instance.lastEnteredSearchString && AppState.instance.lastEnteredSearchString.length > 0 && !isSearching;

		return (
			<div className="desktopMenu">
				
				<div className="dtMenuLeftBrand" onClick={this.OnLogoClicked}>
					<div className="dtMenuLogo">
						<img src="/assets/logo64.png" alt="logo" />
					</div>
					<div className="dtMenuBrand">
						<div className="dtMenuLogoText">
							LENA
						</div>
						<div className="dtMenuLogoTextSub">
							Inspiring • Families
						</div>
					</div>
				</div>

				{!showClearIcon && !isSearching && <SearchIcon className="detailSearchIcon" />}
				{showClearIcon && <ClearIcon className="detailSearchIcon detailSearchClear" onClick={this.OnClearInput} />}
				{isSearching && <CircularProgress size={24} className="detailSearchIcon" />}

				<div className="detailSearch">
					<SearchField
						onSearch={this.OnSearch}
						onFocus={this.OnSearchFocus}
						onBlur={this.OnSearchBlur}
						onEnter={this.OnEnter}
					/>
				</div>

				<div className={"detailSearchPageContainer" + (showSearchPage ? " visible": " invisible")}>
					<div className={"detailSearchPageContainerBackground" + (searchResultsShown ? " visible": " invisible")} />

					<SearchPage visible={showSearchPage}
						searchTerm={AppState.instance.lastEnteredSearchString}
						hideDefaults={true}
						contentOnly={false}
						onContentClick={this.OnSearchResultClicked}
					/>
				</div>

				<div className="dtMenuItems">
					{/* <Button className={"dtMenuItem dtMenuItemLena" + (this.props.chatBotVisible ? " selected":"")}
						onClick={this.OnChatBotClicked}>
						<div className="dtMenuBrand">
							<div className="dtMenuLogo">
								<img src="/assets/logo64.png" alt="logo" />
							</div>
						</div>
					</Button> */}
					<Button className={"dtMenuItem" + (AppState.instance.currentMainTab === AppState.TabIndexTimeline ? " selected":"")}
						onClick={this.OnMenuClicked(AppState.TabIndexTimeline)}>
						{Translate.T("en.Community", "de.Community", "desktop.menu.timeline")}

						{AppState.instance.showNewIndicator[AppState.TabIndexProfile] &&
						<div className={"newIndicator" + (AppState.instance.showNewIndicator[AppState.TabIndexProfile] > 0 ? " newIndicatorNumber":"")}>
							{AppState.instance.showNewIndicator[AppState.TabIndexProfile] > 0 && AppState.instance.showNewIndicator[AppState.TabIndexProfile]}
						</div>}
					</Button>
					{AppState.instance.advManager.enabled && <Button className={"dtMenuItem" + (AppState.instance.currentMainTab === AppState.TabIndexAdventure ? " selected":"")}
						onClick={this.OnMenuClicked(AppState.TabIndexAdventure)}>
						{Translate.T("en.Adventures", "de.Abenteuer", "desktop.menu.adventures")}

						{AppState.instance.showNewIndicator[AppState.TabIndexAdventure] &&
						<div className={"newIndicator" + (AppState.instance.showNewIndicator[AppState.TabIndexAdventure] > 0 ? " newIndicatorNumber":"")}>
							{AppState.instance.showNewIndicator[AppState.TabIndexAdventure] > 0 && AppState.instance.showNewIndicator[AppState.TabIndexAdventure]}
						</div>}
					</Button>}
					<Button className={"dtMenuItem" + (AppState.instance.currentMainTab === AppState.TabIndexExplore ? " selected":"")}
						onClick={this.OnMenuClicked(AppState.TabIndexExplore)}>
						{Translate.T("en.Explore", "de.Erforschen", "desktop.menu.explore")}
					</Button>
					<Button className={"dtMenuItem" + (AppState.instance.currentMainTab === AppState.TabIndexBookmark ? " selected":"")}
						onClick={this.OnMenuClicked(AppState.TabIndexBookmark)}>
						{Translate.T("en.Bookmarks", "de.Lesezeichen", "desktop.menu.bookmarks")}

						{AppState.instance.showNewIndicator[AppState.TabIndexBookmark] &&
						<div className={"newIndicator" + (AppState.instance.showNewIndicator[AppState.TabIndexBookmark] > 0 ? " newIndicatorNumber":"")}>
							{AppState.instance.showNewIndicator[AppState.TabIndexBookmark] > 0 && AppState.instance.showNewIndicator[AppState.TabIndexBookmark]}
						</div>}
					</Button>

					<Button className={"dtMenuItem dtMenuItemProfile" + (this.showProfileMenu ? " selected":"")}
						onClick={this.OnProfileMenuClicked}>
						<ProfilePicture
							className="dtMenuProfilePix"
							username={(AppState.instance.IsLoggedIn() && AppState.instance.loginDataChecked) ? AppState.instance.userProfile.username : undefined}
						/>
					</Button>

					<Menu
						id="profileMenu"
						className={AppState.instance.GetPlatformClasses()}
						anchorEl={this.menuAnchor}
						open={this.showProfileMenu}
						onClose={this.OnProfileMenuRequestClose}
					>
						<ListMenu
							menuEntries={menuEntries}
							onItemClicked={this.OnProfileMenuItemClicked}
						/>
					</Menu>
				</div>
			</div>
		);
	}
}

DesktopMenu.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(DesktopMenu);