import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import ListMenu from '../components/ListMenu';
import Translate from '../Translate';
import Tracking from '../Tracking';
import AppState from '../AppState';
import RestApi from '../RestApi';
import Utils from '../Utils';
import AccountRequestPage from './AccountRequestPage';
import ProgressDisplay from '../components/ProgressDisplay';

const styles = theme => ({
});

@observer
class EditProfilePage extends React.Component
{
	loadingCounter = 0;
	@observable progressItems = [];

	componentDidMount()
	{
		Tracking.OnPageView("editprofile");
	}

	renderShareFlag(flag)
	{
		return Utils.GetShareString(flag);
	}

	OnFlagClicked = flagName => () =>
	{
		var currentValue = AppState.instance.userProfile.loginData["share" + flagName];
		++currentValue;
		currentValue = currentValue % 5;
		AppState.instance.userProfile.loginData["share" + flagName] = currentValue;
		this.forceUpdate();

		++this.loadingCounter;
		RestApi.SendRequest("/changeprivacy", {
			shareName: AppState.instance.userProfile.loginData.shareName,
			shareLocation: AppState.instance.userProfile.loginData.shareLocation,
			shareEmail: AppState.instance.userProfile.loginData.shareEmail,
			sharePhoto: AppState.instance.userProfile.loginData.sharePhoto,
			shareBookmarks: AppState.instance.userProfile.loginData.shareBookmarks,
			shareFollowers: AppState.instance.userProfile.loginData.shareFollowers,
			shareFollowing: AppState.instance.userProfile.loginData.shareFollowing,
			shareTrips: AppState.instance.userProfile.loginData.shareTrips,
			shareContent: AppState.instance.userProfile.loginData.shareContent,
			shareUgcActivity: AppState.instance.userProfile.loginData.shareUgcActivity,
			shareFamily: AppState.instance.userProfile.loginData.shareFamily,
			shareContentProfile: AppState.instance.userProfile.loginData.shareContentProfile,
		}).then((loginData) =>
		{
			--this.loadingCounter;
			if (loginData && loginData.isLoggedIn && this.loadingCounter === 0)
			{
				AppState.instance.userProfile.SetLoginData(loginData);
				AppState.instance.userProfile.Save();
			}
		})
		.catch((error) =>
		{
			--this.loadingCounter;
		});
	}

	// OnClearBookmarksClicked = () =>
	// {
	// 	AppState.instance.screenMessage.ShowAlert(Translate.T("en.Confirmation", "de.Bestätigung", "confirmation.title"),
	// 		Translate.T("en.Do you really want to clear your bookmark list?",
	// 			"de.Willst Du wirklich alle Deine Lesezeichen löschen?", "confirmation.clearbookmarks"),
	// 		[{label: Translate.FindTranslation("answer.no"), value: 0}, {label: Translate.FindTranslation("answer.yes"), value: 1}], true, (resultButton) =>
	// 	{
	// 		if (resultButton && resultButton.value)
	// 		{
	// 			AppState.instance.contentStore.RemoveAllBookmarks(false);
	// 		}
	// 	});
	// }

	OnDownloadDataClicked = () =>
	{
		//window.open(RestApi.endpoint + "/dataarchive.csv", '_blank');
		Utils.OpenExternalLink(RestApi.endpoint + "/dataarchive");
	}

	OnChangePasswordClicked = () =>
	{
		AccountRequestPage.ShowPasswordChange();
	}

	OnChangeNewsletter = () =>
	{
		const newValue = !AppState.instance.userProfile.loginData.hasNewsletter;

		RestApi.SendRequestText("/managenewsletter", {
			subscribe: newValue,
		}).then((resultStr) =>
		{
			if (resultStr && resultStr === "ok")
			{
				AppState.instance.userProfile.loginData.hasNewsletter = newValue;
				AppState.instance.userProfile.Save();
				this.forceUpdate();

				if (newValue)
				{
					AppState.instance.screenMessage.Show(
						Translate.T("de.Newsletter erfolgreich abonniert.", "en.Successfully subscribed.", "managenewsletter.subscribe.success"));
				}
				else
				{
					AppState.instance.screenMessage.Show(
						Translate.T("de.Newsletter erfolgreich abgemeldet.", "en.Successfully unsubscribed.", "managenewsletter.unsubscribe.success"));
				}
			}
			else
			{
				this.OnNewsletterError();
			}
		})
		.catch((error) =>
		{
			this.OnNewsletterError();
		});
	}

	OnNewsletterError()
	{
		AppState.instance.screenMessage.Show(
			Translate.T("de.Fehler. Bitte versuche es nochmal.", "en.Error. Please try again.", "managenewsletter.error"));
	}

	OnPhotoClicked = () =>
	{
		AppState.instance.appContainer.PromptImage(this.UploadProfilePhoto);
	}

	GetProgressItem(id)
	{
		for (var i = 0; i < this.progressItems.length; ++i)
		{
			if (this.progressItems[i].id === id)
			{
				return this.progressItems[i];
			}
		}
		return undefined;
	}

	RemoveProgressItem(id)
	{
		for (var i = 0; i < this.progressItems.length; ++i)
		{
			if (this.progressItems[i].id === id)
			{
				this.progressItems.splice(i, 1);
				break;
			}
		}
	}

	UploadProfilePhoto = (file) =>
	{
		var id;

		Utils.UploadActivityImage(file, -1, (uc) =>
		{
			console.log("upload finished:");
			console.log(uc);

			AppState.instance.userProfile.loginData.profilePicture = uc.url;
			AppState.instance.userProfile.Save();
			this.forceUpdate();

			var profile = AppState.instance.socialManager.GetProfile(AppState.instance.userProfile.username, false);
			if (profile)
			{
				profile.profilePicture = uc.url;
				AppState.instance.socialManager.onProfilesChanged.Call();
			}

			if (AppState.instance.profilePage)
			{
				AppState.instance.profilePage.profileComponentUpdateCount++;
				AppState.instance.profilePage.forceUpdate();
			}

			var item = this.GetProgressItem(id);
			if (item)
			{
				item.label = Translate.T("en.Done", "de.Fertig", "photo.upload.done");

				setTimeout(() => {
					item.show = false; // This will fade out the progress item
				}, 500);
			}
		},

		//progress:
		(event) =>
		{
			var item = this.GetProgressItem(id);
			if (item)
			{
				const progress = event.loaded / event.total * 100;
				item.progress = progress;
			}
		},

		//on error
		(error) =>
		{
			console.log("Upload error:");
			console.log(error);

			var item = this.GetProgressItem(id);
			if (item)
			{
				//item.label = Translate.T("en.Error while uploading", "de.Fehler beim Upload", "photo.upload.error");
				item.label = JSON.stringify(error);
				item.error = true;
				this.forceUpdate();
			}
		},
	
		// onLocalImageData
		(dataUrl) =>
		{
			id = Utils.GenerateRandomStr(32);

			this.progressItems.push(
			{
				id: id,
				progress: 0,
				show: true,
				dataUrl: dataUrl,
				label: Translate.T("en.Uploading image", "de.Photo-Upload", "photo.upload.progress"),
				onDone: () =>
				{
					this.RemoveProgressItem(id);
					this.forceUpdate();
				},
				onRetry: () =>
				{
					setTimeout(() => {
						var item = this.GetProgressItem(id);
						if (item)
							item.show = false;
						this.UploadProfilePhoto(file);	
					}, 200);
				},
				onCancel: () =>
				{
					setTimeout(() => {
						var item = this.GetProgressItem(id);
						if (item)
						{
							setTimeout(() => {
								item.show = false;
							}, 100);
						}	
					}, 200);
				}
			});
		},
		true); //isprofilephoto
	}

	OnPostalAddressClicked = () =>
	{
		AppState.instance.screenMessage.ShowTextEdit(Translate.T("en.Edit Postal Address", "de.Postanschrift ändern", "postaladdress.dialog.title"),
			AppState.instance.userProfile.loginData.postalAddress, 3,
			[{label: Translate.TStr("en.Cancel", "de.Abbrechen", "dialog.cancel"), value: 0},
			{label: Translate.TStr("en.Save", "de.Speichern", "dialog.save"), value: 1}], true, (resultButton, text) =>
		{
			if (resultButton && resultButton.value)
			{
				AppState.instance.userProfile.loginData.postalAddress = text;
				AppState.instance.userProfile.Save();
				this.forceUpdate();

				RestApi.SendRequest("/editpostaladdress", {
					postalAddress: text
				}).then((lg) => {
					AppState.instance.userProfile.loginData = lg;
					AppState.instance.userProfile.Save();
					this.forceUpdate();
				});
			}
		});
	}

	render()
	{
		//Tracking.OnRender("EditProfilePage");

		var menuEntries = [];
		menuEntries.push({id: 0, label: Translate.T('en.Username', 'de.Benutzername', 'profile.username'), rightLabel: AppState.instance.userProfile.loginData.username, onClick: this.OnUsernameClicked});
		menuEntries.push({id: 1, label: Translate.T('en.First name', 'de.Vorname', 'profile.firstname'), rightLabel: AppState.instance.userProfile.loginData.firstName, onClick: this.OnFirstNameClicked});
		menuEntries.push({id: 2, label: Translate.T('en.Last name', 'de.Nachname', 'profile.lastname'), rightLabel: AppState.instance.userProfile.loginData.lastName, onClick: this.OnLastNameClicked});

		if (!AppState.instance.userProfile.loginData.postalAddress)
		{
			menuEntries.push({id: 3, label: Translate.T('en.Set Postal Address', 'de.Postanschrift setzen', 'profile.postaladdress.set'),
				rightLabel: AppState.instance.userProfile.loginData.postalAddress, onClick: this.OnPostalAddressClicked});
		}
		else
		{
			menuEntries.push({id: 3, label: Translate.T('en.Edit Postal Address', 'de.Postanschrift ändern', 'profile.postaladdress.edit'),
				rightLabel: AppState.instance.userProfile.loginData.postalAddress, onClick: this.OnPostalAddressClicked});
		}

		menuEntries.push({id: 4, label: Translate.T('en.Change photo', 'de.Foto ändern', 'profile.photo'), onClick: this.OnPhotoClicked});

		if (this.progressItems.length > 0)
		{
			for (var i = 0; i < this.progressItems.length; ++i)
			{
				menuEntries.push({id: 300 + i, content:
					<div className="progressItems">
						<ProgressDisplay data={this.progressItems[i]} key={i} onDone={this.progressItems[i].onDone} isError={this.progressItems[i].error} />
					</div>
				});
			}
		}

		if (AppState.instance.IsLoggedIn() && RestApi.GetAuthProvider() === "credentials")
			menuEntries.push({id: 4, label: Translate.T('en.Change password', 'de.Passwort ändern', 'profile.changepassword'), onClick: this.OnChangePasswordClicked});

		menuEntries.push({id: 5, label: Translate.T('en.Newsletter', 'de.Newsletter', 'profile.newsletter'),
			rightLabel: AppState.instance.userProfile.loginData.hasNewsletter ? Translate.T('en.Subscribed', 'de.Abonniert', 'profile.newsletter.subscribed') :
				Translate.T('en.Tap to subscribe', 'de.Antippen um Newsletter zu abonnieren', 'profile.newsletter.taptosubscribe'),
			onClick: this.OnChangeNewsletter});

		menuEntries.push({id: 6, label: Translate.T('en.Download data archive', 'de.Datenarchiv downloaden', 'profile.downloaddata'), onClick: this.OnDownloadDataClicked});
		//menuEntries.push({id: 7, label: Translate.T('en.Clear Bookmarks', 'de.Lesezeichen löschen', 'profile.clearbookmarks'), onClick: this.OnClearBookmarksClicked});

		var menuEntries2 = [];
		menuEntries2.push({id: 0, label: Translate.T('en.Name', 'de.Name', 'editprofile.name'),
			rightLabel: this.renderShareFlag(AppState.instance.userProfile.loginData.shareName), onClick: this.OnFlagClicked("Name")});
		menuEntries2.push({id: 1, label: Translate.T('en.Check-In Locations', 'de.Besuchte Ausflugsorte', 'editprofile.location'),
			rightLabel: this.renderShareFlag(AppState.instance.userProfile.loginData.shareLocation), onClick: this.OnFlagClicked("Location")});
		menuEntries2.push({id: 2, label: Translate.T('en.Email', 'de.Email', 'peditrofile.email'),
			rightLabel: this.renderShareFlag(AppState.instance.userProfile.loginData.shareEmail), onClick: this.OnFlagClicked("Email")});
		menuEntries2.push({id: 3, label: Translate.T('en.Profile photo', 'de.Profil Foto', 'editprofile.photo'),
			rightLabel: this.renderShareFlag(AppState.instance.userProfile.loginData.sharePhoto), onClick: this.OnFlagClicked("Photo")});
		menuEntries2.push({id: 4, label: Translate.T('en.My Lists', 'de.Meine Listen', 'editprofile.cardcollections'),
			rightLabel: this.renderShareFlag(AppState.instance.userProfile.loginData.shareBookmarks), onClick: this.OnFlagClicked("Bookmarks")});
		menuEntries2.push({id: 5, label: Translate.T('en.Who is following me', 'de.Wer mir folgt', 'editprofile.followers'),
			rightLabel: this.renderShareFlag(AppState.instance.userProfile.loginData.shareFollowers), onClick: this.OnFlagClicked("Followers")});
		menuEntries2.push({id: 6, label: Translate.T('en.Who I am following', 'de.Wem ich folge', 'editprofile.following'),
			rightLabel: this.renderShareFlag(AppState.instance.userProfile.loginData.shareFollowing), onClick: this.OnFlagClicked("Following")});
		menuEntries2.push({id: 7, label: Translate.T('en.My trips', 'de.Meine Ausflüge', 'editprofile.trips'),
			rightLabel: this.renderShareFlag(AppState.instance.userProfile.loginData.shareTrips), onClick: this.OnFlagClicked("Trips")});
		menuEntries2.push({id: 8, label: Translate.T('en.Meine Fotos und Videos', 'de.Meine Fotos und Videos', 'editprofile.content'),
			rightLabel: this.renderShareFlag(AppState.instance.userProfile.loginData.shareContent), onClick: this.OnFlagClicked("Content")});
		menuEntries2.push({id: 8, label: Translate.T('en.Created activity entries', 'de.Eingetragene Ausflugsziele', 'editprofile.ugcActivity'),
			rightLabel: this.renderShareFlag(AppState.instance.userProfile.loginData.shareUgcActivity), onClick: this.OnFlagClicked("UgcActivity")});
		menuEntries2.push({id: 9, label: Translate.T('en.My family info', 'de.Meine Familien-Information', 'editprofile.family'),
			rightLabel: this.renderShareFlag(AppState.instance.userProfile.loginData.shareFamily), onClick: this.OnFlagClicked("Family")});
		menuEntries2.push({id: 10, label: Translate.T('en.What kind of activities I like', 'de.Welche Art von Aktivitäten mir gefallen', 'editprofile.contentprofile'),
			rightLabel: this.renderShareFlag(AppState.instance.userProfile.loginData.shareContentProfile), onClick: this.OnFlagClicked("ContentProfile")});

		return (
			<div
				className="editProfilePage"
				key="editProfilePage"
			>
				<div className="editProfileMenuContainer">
					<ListMenu
						menuEntries={menuEntries}
					/>
				</div>

				<div className="privacyHeader">
					{Translate.T("en.Who can see your information?", "de.Wer kann Deine Informationen sehen?", "profile.privacy.header")}
				</div>

				<div className="editProfileMenuContainer">
					<ListMenu
						menuEntries={menuEntries2}
					/>
				</div>
			</div>
		);
	}
}


EditProfilePage.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EditProfilePage);