import React from 'react';
import Utils from '../Utils';
import AppState from '../AppState';
import RestApi from '../RestApi';

export default class LoginAction extends React.Component
{
	static keyAction = 'loginAction';
	static keyUrl = 'loginForward';

	static Set(action, url)
	{
		if (action)
			Utils.SetLocalStorage(LoginAction.keyAction, action);
		if (url)
			Utils.SetLocalStorage(LoginAction.keyUrl, url);
	}

	static Clear()
	{
		Utils.RemoveLocalStorage(LoginAction.keyAction);
		Utils.RemoveLocalStorage(LoginAction.keyUrl);
	}

	static OnAfterLogin()
	{
		var action = Utils.GetLocalStorage(LoginAction.keyAction);
		if (action)
		{
			LoginAction.ExecuteAction(action);
			Utils.RemoveLocalStorage(LoginAction.keyAction);
		}

		var url = Utils.GetLocalStorage(LoginAction.keyUrl);
		if (url)
		{
			Utils.RemoveLocalStorage(LoginAction.keyUrl);
			AppState.instance.appContainer.GoTo(url);
		}

		var doSub = Utils.GetLocalStorage("subscribenewsletterafterauth", "0");
		//console.log("XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX: " + doSub);
		if (doSub === "1")
		{
			RestApi.SendRequestText("/managenewsletter", {
				subscribe: true,
			}).then((resultStr) =>
			{
				if (resultStr && resultStr === "ok")
				{
					Utils.RemoveLocalStorage("subscribenewsletterafterauth");
				}
			});
		}
	}

	static ExecuteAction(action)
	{
		console.log("executing login action: " + action);

		var parts = action.split('.');
		if (parts.length < 2)
			return;

		var className = parts[0];
		var methodName = parts[1];

		// For security reasons we don't allow all method calls

		// if (className === "DetailPage" && methodName === "ToggleBookmark")
		// {
		// 	if (parts.length !== 3)
		// 		return;

		// 	Utils.ToggleBookmark(Number(parts[2]));
		// }

		if (className === "AdvNotReady" && methodName === "OnAfterLogin")
		{
			if (parts.length !== 3)
				return;

			Utils.ToggleBookmark(Number(parts[2]));
		}
	}
}
