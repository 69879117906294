import React from 'react';
import PropTypes from 'prop-types';
import Button from 'material-ui/Button';
import {withStyles} from 'material-ui/styles';
import Translate from '../Translate';
import {observer} from 'mobx-react';
import MoreHoriz from 'material-ui-icons/MoreHoriz';
import PersonAdd from 'material-ui-icons/PersonAdd';
import { observable } from 'mobx';
import { CircularProgress } from 'material-ui';
import raf from 'raf';
import AppState from '../AppState';
import AccountRequestPage from '../pages/AccountRequestPage';
import Menu, { MenuItem } from 'material-ui/Menu';
import CheckIcon from 'material-ui-icons/Check';
import Divider from 'material-ui/Divider';
import HourglassEmpty from 'material-ui-icons/HourglassEmpty';

const styles = theme => ({
});

@observer
class SocialButton extends React.Component
{
	@observable isLoading = false;
	@observable isMenuOpen = false;
	menuAnchor;

	componentDidMount()
	{
		AppState.instance.socialManager.onProfilesChanged.Add(this.OnProfilesChanged);
	}

	componentWillUnmount()
	{
		AppState.instance.socialManager.onProfilesChanged.Remove(this.OnProfilesChanged);
	}

	OnProfilesChanged = () =>
	{
		this.forceUpdate();
	}

	OnClicked = (e) =>
	{
		if (this.isLoading)
			return;
		const isPending = AppState.instance.socialManager.IsRequestPending(this.props.username);
		if (isPending)
			return;

		e.stopPropagation();
		e.preventDefault();

		const profile = AppState.instance.socialManager.GetProfile(this.props.username);
		if (!profile)
			return;

		raf(() => {
			if (this.props.default === "follow" || profile.disableFriendRequests)
				this.AddFollow(e);
			else
				this.AddFriend(e);
		});
	}

	OnMenuClicked = (e) =>
	{
		if (this.isLoading)
			return;

		e.stopPropagation();
		e.preventDefault();

		this.menuAnchor = e.currentTarget;
		raf(() => {
			this.isMenuOpen = true;
		});
	}

	SocialRequest(type)
	{
		if (AppState.instance.IsLoggedIn())
		{
			this.isLoading = true;

			raf(() =>
			{
				AppState.instance.socialManager.SocialRequest(this.props.username, type)
				.then(() =>
				{
					this.isLoading = false;
				})
				.catch((e) =>
				{
					this.isLoading = false;
				});
			});
		}
		else
		{
			AccountRequestPage.Show();
		}
	}

	AddFriend = (e) =>
	{
		e.stopPropagation();
		e.preventDefault();
		this.OnMenuRequestClose();

		this.SocialRequest("friendrequest");
	}

	AddFamily = (e) =>
	{
		e.stopPropagation();
		e.preventDefault();
		this.OnMenuRequestClose();

		this.SocialRequest("familyrequest");
	}

	AddFollow = (e) =>
	{
		e.stopPropagation();
		e.preventDefault();
		this.OnMenuRequestClose();

		this.SocialRequest("followrequest");
	}

	Unfollow = (e) =>
	{
		e.stopPropagation();
		e.preventDefault();
		this.OnMenuRequestClose();

		this.SocialRequest("unfollowrequest");
	}

	Unfriend = (e) =>
	{
		e.stopPropagation();
		e.preventDefault();
		this.OnMenuRequestClose();

		this.SocialRequest("unfriendrequest");
	}

	MoveToFriends = (toFamily) => (e) =>
	{
		e.stopPropagation();
		e.preventDefault();
		this.OnMenuRequestClose();

		this.isLoading = true;

		raf(() => {raf(() =>
		{
			if (AppState.instance.IsLoggedIn())
			{
				AppState.instance.socialManager.SocialRequest(this.props.username, "movefriend", undefined, undefined, toFamily)
				.then(() =>
				{
					this.isLoading = false;
				})
				.catch((e) =>
				{
					this.isLoading = false;
				});
			}
			else
			{
				AccountRequestPage.Show();
			}
		});});
	}

	renderIcon()
	{
		if (this.isLoading)
			return (<CircularProgress />);

		const isPending = AppState.instance.socialManager.IsRequestPending(this.props.username);
		if (isPending)
			return (<HourglassEmpty />);
		
		if (AppState.instance.socialManager.HasRelationshipWith(this.props.username))
			return (<CheckIcon />);

		return (<PersonAdd />);
	}

	renderLabel()
	{
		if (this.isLoading)
			return (Translate.T('en.Sending', 'de.Sende', 'social.button.loading'));

		const profile = AppState.instance.socialManager.GetProfile(this.props.username);

		if (!profile)
			return null;

		const isPending = AppState.instance.socialManager.IsRequestPending(this.props.username);

		if (isPending)
			return (Translate.T('en.Pending', 'de.Angefragt', 'social.button.pending'));
		if (profile.isFamily)
			return (Translate.T('en.Family', 'de.Familie', 'social.button.family'));
		if (profile.isFriend)
			return (Translate.T('en.Friends', 'de.Freunde', 'social.button.friends'));
		if (profile.iamFollowing)
			return (Translate.T('en.Following', 'de.Following', 'social.button.following'));
		if (profile.isFollowingMe)
			return (Translate.T('en.Follower', 'de.Follower', 'social.button.follower'));

		if (this.props.default === "follow" || profile.disableFriendRequests)
			return (Translate.T('en.Follow', 'de.Folgen', 'social.button.addfollow'));

		return (Translate.T('en.Add Friend', 'de.Freund', 'social.button.addfriend'));
	}

	OnMenuRequestClose = () =>
	{
		this.isMenuOpen = false;
		this.menuAnchor = undefined;
	}

	CancelRequest = (e) =>
	{
		e.stopPropagation();
		e.preventDefault();
		this.OnMenuRequestClose();

		AppState.instance.socialManager.CancelRequest(this.props.username);
	}

	GetTopMenuItems(profile, isPending)
	{
		var result = [];

		const inRelationship = profile.isFamily || profile.isFriend;

		if (!inRelationship && !isPending && !profile.disableFriendRequests)
			result.push(<MenuItem key={0} onClick={this.AddFamily}><PersonAdd /> {Translate.T("en.Add Family", "de.Familienmitglied", "social.button.addfamily")}</MenuItem>);

		if (!inRelationship && !isPending && !profile.disableFriendRequests)
			result.push(<MenuItem key={1} onClick={this.AddFriend}><PersonAdd /> {Translate.T('en.Add Friend', 'de.Freund', 'social.button.addfriend')}</MenuItem>);

		if (!profile.iamFollowing)
			result.push(<MenuItem key={2} onClick={this.AddFollow}><PersonAdd /> {Translate.T("en.Follow", "de.Folgen", "social.button.follow")}</MenuItem>);

		return result;
	}

	GetBottomMenuItems(profile, isPending)
	{
		var result = [];

		if (isPending)
			result.push(<MenuItem key={3} onClick={this.CancelRequest}>{Translate.T("en.Cancel request", "de.Anfrage abbrechen", "social.button.cancel")}</MenuItem>);

		if (profile.isFamily)
			result.push(<MenuItem key={4} onClick={this.MoveToFriends(false)}>{Translate.T("en.Move to Friends", "de.Zu Freunden verschieben", "social.button.tofriends")}</MenuItem>);

		if (profile.isFriend)
			result.push(<MenuItem key={5} onClick={this.MoveToFriends(true)}>{Translate.T("en.Move to Family", "de.In Familie aufnehmen", "social.button.tofamily")}</MenuItem>);

		if (profile.isFriend || profile.isFamily)
			result.push(<MenuItem key={6} onClick={this.Unfriend}>{Translate.T("en.Remove", "de.Entfernen", "social.button.remove")}</MenuItem>);

		if (profile.iamFollowing)
			result.push(<MenuItem key={7} onClick={this.Unfollow}>{Translate.T("en.Unfollow", "de.Nicht mehr folgen", "social.button.unfollow")}</MenuItem>);

		//TODO:
		//if (profile.isFollowingMe)
		//	result.push(<MenuItem key={8} onClick={this.DisallowFollow}>{Translate.T("en.Block follower", "de.Follower blockieren", "social.button.blockfollow")}</MenuItem>);

		return result;
	}

	render()
	{
		if (!this.props.username)
			return null;
		const profile = AppState.instance.socialManager.GetProfile(this.props.username);
		if (!profile)
			return null;

		const hasRelationShip = AppState.instance.socialManager.HasRelationshipWith(this.props.username)
		const isPending = AppState.instance.socialManager.IsRequestPending(this.props.username);

		const topMenu = this.GetTopMenuItems(profile, isPending);
		const bottomMenu = this.GetBottomMenuItems(profile, isPending);
		const isOwn = profile.isOwnProfile;

		if (isOwn)
		{
			return (
				<span className="socialButtonSpan ownProfile">
					{Translate.T("de.Eigenes Profile", "en.Own Profile", "socialbutton.ownprofile")}
				</span>
			);
		}

		return (
			<span className="socialButtonSpan">
				<Button color="primary" onClick={(hasRelationShip || isPending) ? this.OnMenuClicked : this.OnClicked} aria-label="social"
					className={"socialButton" + (hasRelationShip ? " relationship": " norelationship") +
					(isPending ? " pending":" notpending")}>
					{this.renderIcon()}
					{this.renderLabel()}
				</Button>
				<Button color="primary" onClick={this.OnMenuClicked} aria-label="menu-more" className="socialButtonMore">
					<MoreHoriz />
				</Button>

				<Menu
					id="socialMenu"
					anchorEl={this.menuAnchor}
					open={this.isMenuOpen}
					onClose={this.OnMenuRequestClose}
				>
					{topMenu}
					{topMenu.length > 0 && bottomMenu.length > 0 && <Divider light />}
					{bottomMenu}
				</Menu>
			</span>
		);
	}
}

SocialButton.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SocialButton);