import StepBase from './StepBase';
import Translate from '../../Translate';


export default class StepSelectAudience extends StepBase
{
	constructor(props)
	{
		super(props);

		this.AddAnswerOption({
			label: Translate.T("de.LENA Gemeinschaft", "en.LENA Community", "shareanswer.everyone"),
			onClick: this.OnOptionClicked(4),
			id: 4
		});

		this.AddAnswerOption({
			label: Translate.T("de.Followers", "en.Followers", "shareanswer.followers"),
			onClick: this.OnOptionClicked(3),
			id: 3
		});

		this.AddAnswerOption({
			label: Translate.T("de.Freunde", "en.Friends", "shareanswer.friends"),
			onClick: this.OnOptionClicked(2),
			id: 2
		});

		this.AddAnswerOption({
			label: Translate.T("de.Familie", "en.Family", "shareanswer.family"),
			onClick: this.OnOptionClicked(1),
			id: 1
		});

		this.AddAnswerOption({
			label: Translate.T("de.Niemandem", "en.Nobody", "shareanswer.nobody"),
			onClick: this.OnOptionClicked(0),
			id: 0
		});
	}

	GetId()
	{
		return "audience";
	}

	GeneratedDataIds()
	{
		return ["audience"];
	}

	Undo(contextData)
	{
		delete contextData["audience"];
	}

	OnOptionClicked = (shareFlag) => (e, contextData) =>
	{
		this.OnStepDone(contextData, {
			audience: shareFlag
		});
	}

	renderStepLena(contextData)
	{
		if (contextData.goal === "question")
			return this.renderLenaMsg(Translate.T("de.Wen möchtest Du fragen?",
				"en.Who would you like to ask?", "lena.audience.question"));

		if (contextData.goal === "media")
			return this.renderLenaMsg(Translate.T("de.Wen möchtest Du die Fotos/Videos zeigen?",
				"en.Who would you like to show this?", "lena.audience.media"));

		return this.renderLenaMsg(Translate.T("de.Wem möchtest Du davon erzählen?\nJe mehr Menschen von Deinem Beitrag erfahren, desto mehr Herzen kannst Du damit verdienen.",
			"en.Who do you want to tell about it?\nThe more people know about your contribution the more hearts you can earn with it.", "lena.audience.share"));
	}

	renderUserSelection(contextData)
	{
		return this.renderUserMsg(this.GetAnswerOptionLabel(contextData["audience"]), contextData);
	}

	renderUserOptions(contextData)
	{
		var options = this.answerOptions.slice();
		if (contextData.goal === "question")
		{
			// Remove the option "ask nobody"
			options.splice(options.length - 1, 1);
		}

		return this.renderAnswerOptions(contextData, options);
	}
}
