import React from 'react';
import {observer} from 'mobx-react';
import Utils from '../Utils';
import $ from 'jquery';
import { observable } from 'mobx';
import AppState from '../AppState';

@observer
export default class DetailHeaderBar extends React.Component
{
	static instance = null;

	activeParent;
	toolbarPosition = 0;
	oldScrollTop = 0;
	domLink;
	@observable isShown = true;

	componentDidMount = () =>
	{
		DetailHeaderBar.instance = this;

		//this.AddEvent();
		this.toolbarPosition = 0;
		this.domLink = $(".detailHeaderBar");
	}

	componentWillUnmount = () =>
	{
		//this.RemoveEvents();
	}

	// RemoveEvents()
	// {
	// 	if (this.activeParent !== undefined)
	// 	{
	// 		this.activeParent.removeEventListener('scroll', this.OnScroll);
	// 		this.activeParent = undefined;
	// 	}
	// }

	// AddEvent = () =>
	// {
	// 	var parent = this.props.parent();

	// 	if (this.activeParent !== parent)
	// 	{
	// 		if (this.activeParent)
	// 		{
	// 			this.RemoveEvents();
	// 		}

	// 		if (parent !== undefined)
	// 		{
	// 			console.log("event ADDED");
	// 			parent.addEventListener('scroll', this.OnScroll);

	// 			this.activeParent = parent;
	// 		}
	// 	}
		
	// 	setTimeout(this.AddEvent, 100);
	// }

	CalcHeight()
	{
		if (this.props.height !== undefined)
			return this.props.height;
		else
			return undefined;
	}

	OnScroll = (scrollTop) =>
	{
		if (AppState.instance.deviceInfo.desktop)
			return;

		if (!this.isShown)
			return;

		var lightDarkTS = this.props.lightDarkThreshold;
		if (lightDarkTS === undefined)
			lightDarkTS = 0;

		//console.log("scrollTop: " + scrollTop);
		//console.log("  toolbarPosition: " + this.toolbarPosition);

		//const height = this.CalcHeight();

		//var dhb = this.domLink;

		if (this.props.onLightDarkSwitch !== undefined)
		{
			this.props.onLightDarkSwitch(this.domLink, scrollTop < lightDarkTS);
		}

		//var hiddenPosition = - height;

		var delta = scrollTop - this.oldScrollTop;
		if (delta < 0)
		{
			this.domLink.removeClass("detailToolbarHidden");
		}
		else if (delta > 0 && scrollTop > lightDarkTS)
		{
			this.domLink.addClass("detailToolbarHidden");
		}
		//console.log("delta: " + delta);

		// if (scrollTop >= lightDarkTS)
		// {
		// 	this.toolbarPosition -= delta;
				
		// 	if (this.toolbarPosition < hiddenPosition)
		// 		this.toolbarPosition = hiddenPosition;
		// 	else if (this.toolbarPosition > 0)
		// 		this.toolbarPosition = 0;
		// }
		// else
		// {
		// 	this.toolbarPosition = 0;
		// }
		
		/*dhb.css('top', this.toolbarPosition);*/

		this.oldScrollTop = scrollTop;

		// if (this.props.onScroll !== undefined)
		// {
		// 	var info = {
		// 		height: height,
		// 		visibleHeight: height + this.toolbarPosition,
		// 		top: this.toolbarPosition,
		// 		delta: delta
		// 	};
		// 	this.props.onScroll(info);
		// }
	}

	Show()
	{
		this.domLink.removeClass("detailToolbarHidden");
		this.isShown = true;
	}

	Hide()
	{
		if (AppState.instance.deviceInfo.desktop)
			return;
			
		this.domLink.addClass("detailToolbarHidden");
		this.isShown = false;
	}

	render()
	{
		//console.log("RENDER - " + this.props.style.top);

		var initialTop;
		if (this.props.style !== undefined)
			initialTop = this.props.style.top;
		else
			initialTop = '0px';

		if (initialTop !== undefined)
		{
			initialTop = Number(Utils.TrimCssPx(initialTop));
			this.toolbarPosition = initialTop;
		}

		var showClassName = "";
		if (!this.isShown)
		{
			showClassName = " detailToolbarHidden"
		}

		return (
			<div className={"detailHeaderBar " + this.props.additionalClassNames + showClassName} style={this.props.style}>
				{this.props.children}
			</div>
		);
	}
}
