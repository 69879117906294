import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';

const styles = theme => ({	
});

@observer
class Dots extends React.Component
{
	OnDotClicked = (index) => (event) =>
	{
		if (this.props.onClick)
			this.props.onClick(event, index);
	}

	render()
	{
		var index = this.props.index || 0;
		var count = this.props.count || 1;

		var dots = [];
		for (var i = 0; i < count; ++i)
		{
			dots.push(i === index);
		}

		return (
			<div className="dots">
				{dots.map((dot, index) =>
				{
					return (<div onClick={this.OnDotClicked(index)} className={"dot " + (dot ? "active":"inactive")} key={index} />);
				})}
			</div>
		);
	}
}

Dots.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(Dots);