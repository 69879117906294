import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import Utils from './Utils';
import {CircularProgress} from 'material-ui/Progress';
import {observable} from 'mobx';
import AppState from './AppState';

const styles = theme => ({	
});

@observer
class SiteStatus extends React.Component
{
	@observable status;

	componentDidMount()
	{
		// Check status each N seconds
		this.CheckStatus();
		setInterval(this.CheckStatus, 60 * 1000);
	}

	GetSiteStatus()
	{
		var request = new Request("/status.json",
		{
			method: "GET",
			mode: 'cors',
			redirect: 'follow'
		});

		return fetch(request).then((res) =>
			{
				return res.text().then((responseText) =>
				{
					if (Utils.IsJsonString(responseText))
						return JSON.parse(responseText);
					else
						return new Promise((resolve, reject) => { return resolve(undefined);});
				});
			})
			.catch((error) =>
			{
				return undefined;
			});
	}

	CheckStatus = () =>
	{
		this.GetSiteStatus().then((status) =>
		{
			//console.log("SITE STATUS:");
			//console.log(status);

			this.status = status;
			if (status && status.mode === "down")
			{
				AppState.instance.siteDown = true;
			}
			else
			{
				AppState.instance.siteDown = false;
			}
			this.forceUpdate();
		});
	}

	render()
	{
		if (!AppState.instance.siteDown)
			return <span/>;

		var msg;
		if (this.status && this.status.msg)
		{
			msg = this.status.msg[AppState.instance.userProfile.userLang];
		}

		return (
			<div className="siteStatusContainer">
				<img className="siteStatusLogo" src="/assets/logo.svg" alt="brand logo" />
				<div className="siteStatusMessage">
					{msg}
				</div>
				<div className="siteStatusProgressContainer">
					<CircularProgress className="siteStatusProgress"/>
				</div>
			</div>
		);
	}
}

SiteStatus.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(SiteStatus);