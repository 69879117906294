import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import $ from 'jquery';

const styles = theme => ({
});

@observer
class AdvCardFrame extends React.Component
{
	componentDidMount()
	{
		$(window).on('orientationchange', this.OnOrientationChange);
	}

	componentWillUnmount()
	{
		$(window).off('orientationchange', this.OnOrientationChange);
	}

	OnOrientationChange = () =>
	{
		this.forceUpdate();
	}

	render()
	{
		const topDistance = this.props.marginTop || 16;
		const bottomDistance = this.props.marginBottom || 16;
		const marginLeft = this.props.marginLeft || 16;
		const marginRight = this.props.marginRight || 16;
		const cardAspect = 889.0 / 635.0; // poker size cards

		const ww = this.props.containerWidth || window.innerWidth;
		const wh = this.props.containerHeight || window.innerHeight;
		const maxHeight = wh - (topDistance + bottomDistance);
		const maxWidth = ww - (marginLeft + marginRight);

		var ch = maxHeight;
		var cw = ch / cardAspect;
		if (cw > maxWidth)
		{
			cw = maxWidth;
			ch = cw * cardAspect;
		}

		var cardsStyle = {
			left: (ww - cw) * 0.5,
			width: cw,
			top: topDistance,
			height: ch
		};

		var sizeStr = "small";
		if (cw > 200)
			sizeStr = "big";

		return (
			<div
				className={"advcardframe " + sizeStr}
				style={cardsStyle}
			>
				{this.props.children}
			</div>
		);
	}
}

AdvCardFrame.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AdvCardFrame);