import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import Tracking from '../Tracking';
import AppState from '../AppState';
import Map from '../components/Map';
import $ from 'jquery';

const styles = {
	paper: {
		padding: '0px',
		margin: '0px',
		width: '100%',
		height: '100%',
	},
};


@observer
class MapPage extends React.Component
{
	static instance;
	@observable dialogOpen = false;

	constructor(props)
	{
		super(props);
		MapPage.instance = this;
	}

	OnClose = () =>
	{
		AppState.instance.isMapPageVisible = false;
		AppState.instance.appContainer.forceUpdate();
		this.dialogOpen = false;
		AppState.instance.userProfile.mapFilterCategories = [];
		AppState.instance.OnScreenScrolled(this);

		$("#siteContent").css('display', 'block');
		$("#siteBottom").css('display', 'block');
	}

	static Show(catFilter)
	{
		AppState.instance.userProfile.mapFilterCategories = [];
		if (catFilter !== undefined)
			AppState.instance.userProfile.mapFilterCategories.push(catFilter);

		AppState.instance.isMapPageVisible = true;
		AppState.instance.appContainer.forceUpdate();
		MapPage.instance.dialogOpen = true;

		Tracking.OnPageView("map");

		$("#siteContent").css('display', 'none');
		$("#siteBottom").css('display', 'none');
	}

	render()
	{
		//Tracking.OnRender("MapPage");

		if (!this.dialogOpen)
			return null;

		return (
			<div
				key="mapPageDialog"
				//fullScreen={true}
				//open={this.dialogOpen}
				//onClose={this.OnClose}
				//classes={{
				//	paper: this.props.classes.paper,
				//}}
				className={AppState.instance.isLandingPage ? "mapPageDialogLP" : "mapPageDialog"}  
			>
				{this.dialogOpen && <Map id="mapPageMap" onClose={this.OnClose} gestureHandling="greedy" />}

			</div>
		);
	}
}

MapPage.propTypes = {
	classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(MapPage);