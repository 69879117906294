import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import Utils from '../Utils';
import AppState from '../AppState';
import $ from 'jquery';
import Slide from 'material-ui/transitions/Slide';
import { observable } from 'mobx';
import RestApi from '../RestApi';
import SearchResult from './SearchResult';
import Translate from '../Translate';
import SearchField from './SearchField';
import ArrowBack from 'material-ui-icons/ArrowBack';
import ClearIcon from 'material-ui-icons/Clear';


const styles = theme => ({
});

@observer
class LocationSearch extends React.Component
{
	@observable suggestions = [];
	abortedSearches = [];
	lastSearch;
	geoLocation;

	constructor(props)
	{
		super(props);

		this.geoLocation = {
			id: -1,
			title: Translate.FindTranslation("location.current"),
			type: "location",
			img: Utils.RenderNearMeUrl(16, 16, "#e31c46")
		};
	}

	componentDidMount()
	{
		this.LoadData();
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps.q !== this.props.q ||
			prevProps.visible !== this.props.visible)
		{
			if (this.props.visible)
				this.LoadData(this.props.q);
		}
	}

	LoadData(value)
	{
		var loc = AppState.instance.ipLocation;
		if (AppState.instance.GetGpsPosition())
			loc = AppState.instance.GetGpsPosition();

		var query = {};
		query.location = loc;
		query.language = AppState.instance.userProfile.userLang;
		query.token = 0;
		query.count = 5;
		query.q = value;

		if (this.lastSearch && this.lastSearch !== value)
		{
			this.abortedSearches.push(this.lastSearch);
		}

		this.lastSearch = value;

		AppState.instance.OnStartLocationSearch();

		RestApi.SendRequest("/locationsearch", query)
		.then((r) =>
		{
			// Has search been aborted meanwhile?
			for (var i = 0; i < this.abortedSearches.length; ++i)
			{
				if (this.abortedSearches[i] === r.q)
				{
					this.abortedSearches.splice(i, 1);
					return;
				}
			}
			this.lastSearch = undefined;
			this.suggestions = r.items;
			AppState.instance.OnLocationSearchDone();
		})
		.catch((error) =>
		{
			AppState.instance.OnLocationSearchDone();
		});
	};

	OnLocationClicked = (loc) => () =>
	{
		AppState.instance.manualLocation = loc;
		this.OnAfterLocationSet();
	}

	OnGeoLocationClicked = () =>
	{
		if (AppState.instance.GetGpsPosition())
		{
			// Already running -> done
			AppState.instance.manualLocation = undefined;
			this.OnAfterLocationSet();
		}
		else
		{
			var geoLoc = AppState.instance.geoLocation;
			geoLoc.StartInterval(this.OnGeoIntervalStartResult);
		}
	}

	OnGeoIntervalStartResult = (successBool, error) =>
	{
		console.log("OnGeoIntervalStartResult: " + successBool);

		if (successBool === false)
		{
			//TODO: display what to do if user does not succeed

			if (error.code === 1 /*PERMISSION_DENIED*/ ||
				error.code === 2 /*POSITION_UNAVAILABLE*/)
			{
				// Show error message
				AppState.instance.screenMessage.Show(
					Translate.T("de.Zugriff auf die Geräte-Position wurde vom Browser verweigert. Bitte passe Deine Browser-Einstellungen an!",
						"en.Device position access denied by browser. Please adjust your browser settings!", "geolocation.denied.message"));
			}
			else
			{
				AppState.instance.screenMessage.Show(
					Translate.T("de.Fehler beim Zugriff auf die Geräte-Position. Bitte passe Deine Browser-Einstellungen an!",
						"en.Error while accessing device position. Please adjust your browser settings!", "geolocation.generalerror.message"));
			}
		}
		else
		{
			AppState.instance.manualLocation = undefined;
			this.OnAfterLocationSet();
		}
	}

	OnAfterLocationSet()
	{
		AppState.instance.OnManualLocationChanged();
		AppState.instance.OnFilterChanged();
		AppState.instance.isInLocationSearch = false;

		if (this.props.onLocationSet)
		{
			this.props.onLocationSet();
		}
		else
		{
			if (AppState.instance.appInstance)
				AppState.instance.appInstance.OnLeaveSearch();
			AppState.instance.appContainer.forceUpdate();
			$("#searchInput").val(undefined);	
		}
	}

	renderLocation(loc, isFirst, isLast)
	{
		loc.img = Utils.RenderLocationOnUrl(16, 16, "#aaa");
		loc.address = loc.title;
		return (
			<SearchResult
				key={loc.id}
				contentData={loc} columnMode={true} isFirst={isFirst} isLast={isLast}
				onClick={this.OnLocationClicked(loc)}
			/>
		);
	}

	OnSearchField = (searchTerm) =>
	{
		if (this.props.onSearchFieldChanged)
			this.props.onSearchFieldChanged(searchTerm);
	}

	OnSearchFieldEnter = () =>
	{
		if (this.props.onSearchFieldEnter)
			this.props.onSearchFieldEnter();
	}

	render()
	{
		const isVisible = this.props.visible;

		const q = $("#searchlocation").val();
		const hasQ = q && q.length > 0;

		return (
			<Slide in={isVisible} direction='up'>
				<div id="locSearchPage" className={"locSearchPage" + (!isVisible ? " hidden":"")}>

					{this.props.showSearchField && <div className="locSearchPageInput">
						<SearchField
							onSearch={this.OnSearchField}
							onEnter={this.OnSearchFieldEnter}
							breadcrumbs={Translate.FindTranslation("search.default.location")}
							id="searchlocation"
						/>
						<div className="hideSearchClearBtn" />
						<div className="searchFieldIcon" onClick={this.props.onSearchFieldIconClicked}>
							{!hasQ && <ArrowBack className="searchIcon" />}
							{hasQ && <ClearIcon className="searchIcon searchClear" />}
						</div>
					</div>}

					<SearchResult
						contentData={this.geoLocation} columnMode={true} isFirst={true} isLast={!this.suggestions || this.suggestions.length === 0}
						onClick={this.OnGeoLocationClicked}
					/>

					{this.suggestions && this.suggestions.map((loc, index) =>
					{
						return this.renderLocation(loc, false, index === this.suggestions.length - 1)
					})}

				</div>
			</Slide>
		);
	}
}

LocationSearch.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(LocationSearch);