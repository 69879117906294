/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import Tracking from '../Tracking';
import IconButton from 'material-ui/IconButton';
import AppState from '../AppState';
import RestApi from '../RestApi';
import Utils from '../Utils';
import $ from 'jquery';
import NearMeIcon from 'material-ui-icons/NearMe';
import FilterPage from '../pages/FilterPage';
import Button from 'material-ui/Button';
import Translate from '../Translate';
import ArrowLeft from 'material-ui-icons/KeyboardArrowLeft';
import ArrowRight from 'material-ui-icons/KeyboardArrowRight';
import CloseIcon from 'material-ui-icons/Close';
import FilterBar from './FilterBar';
import ArrowBack from 'material-ui-icons/ArrowBack';
import MapLeaflet from './MapLeaflet';
import {CircularProgress} from 'material-ui/Progress';
import AddIcon from 'material-ui-icons/Add';
import RemoveIcon from 'material-ui-icons/Remove';
import MapContent from './MapContent';
import ContentItem from './ContentItem';

const styles = theme => ({
	filterButtonRoot: {
		height: '100%',
		fontWeight: '700',
		fontSize: '12px',
		paddingLeft: '8px',
		paddingRight: '8px'
	},
	closeButtonRoot: {
		width: '32px',
		height: '32px',
		top: '16px',
		left: '16px',
		position: 'absolute',
		zIndex: 1
	},
});

@observer
class Map extends React.Component
{
	@observable markers = [];
	@observable activities = [];
	mapInstance;

	lastLngMin = 0;
	lastLngMax = 0;
	lastLatMin = 0;
	lastLatMax = 0;

	centerLng = 16;
	centerLat = 48;
	@observable zoomFactor = 10;

	elementInterval = 287;
	numRenderedElements = 0;
	needsScrolling = false;

	@observable isLoading = false;
	scrollIsAnimated = false;

	checkSelectedActivityId = -1;
	@observable selectedActivityId;
	@observable selectionFrameIndex = 0;
	mapSizeValid = false;

	containerId;
	containerElement;
	visibilityInterval;
	loadingTriggered = false;

	queue = [];
	_isMounted = false;

	cachedScrollContainer;

	static CreateHighlightMarker(latitude, longitude, activity, categories)
	{
		var catName = "";
		if (categories && categories.length > 0)
			catName = categories[0];

		const iconSize = 32;
		const ptSize = [iconSize, iconSize];
		const ptZero = [0, 0];
		const ptHalf = [iconSize / 2, iconSize / 2];

		const catColor = Utils.GetCategoryColor(catName);
		const categoryIcon = Utils.GetCategoryIconUrl(catName, "white", catColor, iconSize, iconSize);

		if (!activity)
			activity = {
				id: 'dummy'
			};

		const result =
		{
			index: 0,
			position: {
				lat: latitude,
				lng: longitude,
			},
			activity: activity,
			clickable: false,
			icon: {
				url: categoryIcon,
				size: ptSize,
				origin: ptZero,
				anchor: ptHalf
			},
			catColor: catColor
		};

		return result;
	}

	constructor(props)
	{
		super(props);

		this.zoomFactor = this.props.zoomFactor || 10;
		this.centerLat = this.props.centerLat || 48;
		this.centerLng = this.props.centerLng || 16;

		this.containerId = Utils.GenerateRandomStr(32);

		if (this.props.verticalMode)
			this.elementInterval = window.innerHeight * 0.3 + 96;
	}

	componentDidMount()
	{
		this._isMounted = true;

		AppState.instance.filterManager.AddOnFilterChanged(this.OnFilterChanged);

		if (!this.props.centerLat && !this.props.centerLng)
			this.SetCenterToLocation();
		else
			this.ResetAndLoad();

		if (this.props.loadOnVisibility)
		{
			this.visibilityInterval = setInterval(this.OnCheckVisibility, 500);
		}

		if (AppState.instance.isLandingPage ||
			AppState.instance.isMobileLandingPage)
		{
			setTimeout(() => {
				this.SetToCenterPosition();
			}, 1500);
		}

		if (this.props.onInit)
			this.props.onInit(this);

		//this.GetScrollContainer().scroll(this.OnMapCardsScroll);
	}

	SetToCenterPosition()
	{
		if (this.mapInstance.IsValid() && !this.isLoading)
		{
			//console.log("isvalid");
			this.mapInstance.InvalidateSize();
			this.mapInstance.panTo({ lat: this.centerLat, lng: this.centerLng });
			//console.log("lat: " + this.centerLat);
			//console.log("lng: " + this.centerLng);
		}
		else
		{
			//console.log("not valid");
			setTimeout(() => {
				this.SetToCenterPosition();
			}, 500);
		}
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps.centerLat !== this.props.centerLat ||
			prevProps.centerLng !== this.props.centerLng)
		{
			if (this.props.centerLat && this.props.centerLng)
			{
				this.centerLat = this.props.centerLat;			
				this.centerLng = this.props.centerLng;
				if (this._isMounted)
					this.forceUpdate();
				this.SetToCenterPosition();
			}
		}
	}

	componentWillUnmount()
	{
		if (this.visibilityInterval)
		{
			clearInterval(this.visibilityInterval);
			this.visibilityInterval = undefined;
		}

		AppState.instance.filterManager.RemoveOnFilterChanged(this.OnFilterChanged);
		this.activities = [];
		this.markers = [];
		this._isMounted = false;
	}

	OnFilterChanged = () =>
	{
		this.ResetAndLoad();
	}

	ResetAndLoad()
	{
		this.markers = [];
		this.activities = [];
		this.lastLngMin += 0.00001;
		this.UpdateViewportData();
	}

	OnFilterBarScroll = () =>
	{
		var container = $("#" + this.props.id + " .filterBarContainer");
		var sl = container.scrollLeft();

		var contentWidth = $("#" + this.props.id + " .filterBarInnerContainer").outerWidth();

		var toEnd = contentWidth - (container.innerWidth() + sl);

		var backGround = $("#" + this.props.id + " .closeButtonBackground");
		if (toEnd < 16)
		{
			const delta = (16 - 8);
			var opacity;

			if (toEnd < 8)
				opacity = 0;
			else
			{
				opacity = (toEnd - 8) / delta;
			}

			backGround.css('opacity', opacity);
		}
		else
		{
			backGround.css('opacity', 1);
		}
	}

	RenderFilterButton()
	{
		return (
			<div className={"filterButtonMapContainer" + (this.props.onClose ? " withClose":"")}>
				<FilterBar
					topSpacing={8}
					showFiltersButton={true}
					showSortButton={false}
					showFilters={true}
					showLocation={false}
					onScroll={this.props.onClose ? this.OnFilterBarScroll : undefined}
				/>

				{this.props.onClose && <div className="closeButtonBackground"/>}
			</div>
		);
	}

	RenderLocationButton()
	{
		return (
			<div className={"locationOverlay" + (this.IsAtMyLocation() ? " locationOverlayActive" : "")} key="mylocation">
				<Button onClick={this.OnMyLocation}>
					<NearMeIcon
						style={{
							width: 20,
							height: 20,
						}}/>
				</Button>
			</div>
		);
	}

	RenderHighlightButton()
	{
		if (this.IsAtHighlightLocation())
			return undefined;

		const list = this.props.highlightMarkers;
		if (!list || list.length === 0)
			return undefined;

		//var catColor = list[0].catColor;

		return (
			<div className="highlightOverlay" key="highlightOverlay">
				<Button onClick={this.OnHighlightLocation}>
					{/* <div className="highlightRedDot" style={{backgroundColor: catColor}}/> */}
					<ArrowBack />
				</Button>
			</div>
		);
	}

	RenderZoomButtons()
	{
		return (
			<div className="mapZoomButtons" key="mapZoomButtons">
				<Button onClick={this.OnZoomButton(+1)}>
					<AddIcon />
				</Button>
				<Button onClick={this.OnZoomButton(-1)}>
					<RemoveIcon />
				</Button>
			</div>
		);
	}

	OnZoomButton = (direction) => () =>
	{
		this.zoomFactor += direction;
	}

	OnMyLocation = () =>
	{
		if (!this.PanToMyLocation())
		{
			const geoLoc = this.GetGeoLocation();
			if (geoLoc && !geoLoc.IsIntervalRunning())
			{
				geoLoc.StartInterval(this.PanToMyLocation);
			}
		}
	}

	OnHighlightLocation = () =>
	{
		const list = this.props.highlightMarkers;
		if (!list || list.length === 0)
			return;

		this.centerLng = list[0].position.lng;
		this.centerLat = list[0].position.lat;

		this.mapInstance.panTo({ lat: this.centerLat, lng: this.centerLng });
	}

	IsAtHighlightLocation()
	{
		const list = this.props.highlightMarkers;
		if (!list || list.length === 0)
			return false;

		return this.IsAtLocation(list[0].position.lat, list[0].position.lng)
	}

	IsAtMyLocation()
	{
		const loc = this.GetGeoLocation();
		if (loc)
		{
			const lat = loc.latitude;
			const lng = loc.longitude;
			return this.IsAtLocation(lat, lng)
		}
		return false;
	}

	IsAtLocation(lat, lng)
	{
		if (!this.mapInstance)
			return false;

		var c = this.mapInstance.getCenter();
		var diffX = c.lng - lng;
		var diffY = c.lat - lat;
		return (Math.abs(diffX) < 0.001 && Math.abs(diffY) < 0.001);
	}

	GetGeoLocation()
	{
		return AppState.instance.GetGpsPosition();
	}

	PanToMyLocation = () =>
	{
		const loc = this.GetGeoLocation();
		if (loc)
		{
			this.centerLng = loc.longitude;
			this.centerLat = loc.latitude;
			
			this.mapInstance.panTo({ lat: this.centerLat, lng: this.centerLng });

			if (this.zoomFactor < 15)
			{
				this.zoomFactor += 2;
			}

			this.updateTimer = Utils.SetTimer(this.updateTimer, 1000, this.UpdateViewportData, 100);
			// setTimeout(() =>
			// {
			// 	this.lastLngMin += 0.00001;
			// 	this.UpdateViewportData();
			// }, 1000);

			return true;
		}
		return false;
	}

	OnOpenFilter = () =>
	{
		var q = {
			lngMin: this.lastLngMin,
			lngMax: this.lastLngMax,
			latMin: this.lastLatMin,
			latMax: this.lastLatMax
		};

		FilterPage.Show(q);
	}

	SetCenterToLocation()
	{
		const geoloc = this.GetGeoLocation();

		var loc = AppState.instance.ipLocation;
		if (geoloc)
			loc = geoloc;
		if (AppState.instance.manualLocation)
			loc = AppState.instance.manualLocation;

		if (loc.latitude !== undefined && loc.latitude !== 0)
		{
			this.centerLng = loc.longitude;
			this.centerLat = loc.latitude;
			this.ResetAndLoad();
		}
		else if (loc.address !== undefined)
		{
			var query = {};
			query.location = loc;
			
			RestApi.SendRequest("/findlocation", query)
			.then((newLoc) =>
			{
				loc = newLoc;
				if (loc.latitude !== undefined && loc.latitude !== 0)
				{
					this.centerLng = loc.longitude;
					this.centerLat = loc.latitude;
					this.ResetAndLoad();
				}
			});
		}
	}

	OnMapLoaded = (map) =>
	{
		const wasEmpty = !this.mapInstance;
		if (map)
			this.mapInstance = map;

		if (wasEmpty)
		{
			if (this.IsMapSizeValid())
			{
				this.lastLngMin += 0.00001;
				this.UpdateViewportData();
			}
			else
			{
				setTimeout(() =>
				{
					this.lastLngMin += 0.00001;
					this.UpdateViewportData();
				}, 1000);
			}
		}
	}

	OnActivitiesLoaded()
	{
		var markers = [];

		const iconSize = 22;

		const ptSize = [iconSize, iconSize];
		const ptZero = [0, 0];
		const ptHalf = [-iconSize / 2, -iconSize / 2];

		for (var i = 0; i < this.activities.length; ++i)
		{
			var a = this.activities[i];

			AppState.instance.imageManager.Store(a.imageData);

			// Cache this item for the DetailPage
			if (!this.props.disableContentStoreCache)
			{
				var alreadyCached = AppState.instance.contentStore.FindContentItem(a.title);
				if (!alreadyCached)
					AppState.instance.contentStore.SetContentItem(a);
			}

			// Check if this marker is already rendered using the directionMarkers
			if (this.props.directionMarkers)
			{
				var found = false;
				for (var j = 0; j < this.props.directionMarkers.length; ++j)
				{
					if (this.props.directionMarkers[j].activity.id === a.id)
					{
						found = true;
						break;
					}
				}

				if (found)
					continue;
			}

			// Check if this marker is already rendered using the highlightMarkers
			if (this.props.highlightMarkers)
			{
				found = false;
				for (j = 0; j < this.props.highlightMarkers.length; ++j)
				{
					if (this.props.highlightMarkers[j].activity.id === a.id)
					{
						found = true;
						break;
					}
				}

				if (found)
					continue;
			}

			var m = {
				index: i,
				position: {
					lat: a.latitude ? a.latitude : 0,
					lng: a.longitude ? a.longitude : 0,
				},
				activity: a,
				clickable: true,
				icon: {},
				iconSelected: {},
				liveData: a.liveData,
				zIndexOffset: this.props.zIndexOffset
			};

			var mSize = ptSize;
			var mOrigin = ptZero;
			var mAnchor = ptHalf;
			if (this.props.markerIcon)
			{
				const iconData = this.props.markerIcon(a, false);
				const selectedIconData = this.props.markerIcon(a, true);

				m.icon.url = iconData.url;
				m.iconSelected.url = selectedIconData.url;

				if (iconData.size)
					mSize = iconData.size;
				if (iconData.origin)
					mOrigin = iconData.origin;
				if (iconData.anchor)
					mAnchor = iconData.anchor;

				m.zIndexOffset = iconData.zIndexOffset;
			}
			else
			{
				var catName = "";
				if (a.categories && a.categories.length > 0)
					catName = a.categories[0];
				
				const catColor = Utils.GetCategoryColor(catName);

				m.icon.url = Utils.GetCategoryIconUrl(catName, catColor, 'white', iconSize, iconSize);
				m.iconSelected.url = Utils.GetCategoryIconUrl(catName, 'white', '#e31c46', iconSize, iconSize);
			}

			if (m.icon.url)
			{
				m.icon.path = undefined;
				m.iconSelected.path = undefined;

				m.icon.size = mSize;
			    m.icon.origin = mOrigin;
				m.icon.anchor = mAnchor;
				
				m.iconSelected.size = mSize;
			    m.iconSelected.origin = mOrigin;
				m.iconSelected.anchor = mAnchor;
			}
			
			markers.push(m);
		}

		if (this.props.additionalMarkerCoords)
		{
			for (i = 0; i < this.props.additionalMarkerCoords.length; ++i)
			{
				m = {
					index: markers.length,
					position: {
						lat: this.props.additionalMarkerCoords[i].lat,
						lng: this.props.additionalMarkerCoords[i].lng,
					},
					clickable: true,
					icon: {},
					iconSelected: {},
				};
	
				mSize = ptSize;
				mOrigin = ptZero;
				mAnchor = ptHalf;
				if (this.props.markerIcon)
				{
					const iconData = this.props.markerIcon(this.props.additionalMarkerCoords[i], false);
					const selectedIconData = this.props.markerIcon(this.props.additionalMarkerCoords[i], true);
	
					m.icon.url = iconData.url;
					m.iconSelected.url = selectedIconData.url;
	
					if (iconData.size)
						mSize = iconData.size;
					if (iconData.origin)
						mOrigin = iconData.origin;
					if (iconData.anchor)
						mAnchor = iconData.anchor;
				}
				else
				{
					catName = "";					
					const catColor = Utils.GetCategoryColor(catName);
	
					m.icon.url = Utils.GetCategoryIconUrl(catName, catColor, 'white', iconSize, iconSize);
					m.iconSelected.url = Utils.GetCategoryIconUrl(catName, 'white', '#e31c46', iconSize, iconSize);
				}
	
				if (m.icon.url)
				{
					m.icon.path = undefined;
					m.iconSelected.path = undefined;
	
					m.icon.size = mSize;
					m.icon.origin = mOrigin;
					m.icon.anchor = mAnchor;
					
					m.iconSelected.size = mSize;
					m.iconSelected.origin = mOrigin;
					m.iconSelected.anchor = mAnchor;
				}
				
				markers.push(m);
			}
		}

		this.markers = markers;

		if (this.props.onContentLoaded)
			this.props.onContentLoaded(this.activities, this.markers);
	}

	GetMarkerByActivityId(id)
	{
		for (var i = 0; i < this.markers.length; ++i)
		{
			if (this.markers[i].activity.id === id)
				return this.markers[i];
		}
		return undefined;
	}

	OnMarkerClick = (event, marker) =>
	{
		/*if (event)
		{
			event.stopPropagation();
			event.preventDefault();
		}*/

		if (marker.onClick)
		{
			var e = event;
			if (event.originalEvent)
				e = event.originalEvent;

			marker.onClick(e);

			if (e.defaultPrevented)
				return;
		}

		this.selectedActivityId = marker.activity.id;

		// center map around selected marker
		// this.centerLng = marker.activity.longitude;
		// this.centerLat = marker.activity.latitude;
		// this.mapInstance.panTo({ lat: this.centerLat, lng: this.centerLng });

		this.ScrollToSelection();

		if (this.props.onMarkerClicked)
			this.props.onMarkerClicked(marker);
	}

	SelectMarker(activityId)
	{
		this.selectedActivityId = activityId;
	}

	IsMapSizeValid()
	{
		if (!this.mapInstance)
			return false;

		if (!this.mapInstance.IsValid())
			return false;

		var bounds = this.mapInstance.getBounds();
		if (!bounds)
			return false;

		var lngMin = bounds.b.b;
		var lngMax = bounds.b.f;

		var latMin = bounds.f.b;
		var latMax = bounds.f.f;

		return (lngMin !== lngMax && latMin !== latMax);
	}

	UpdateViewportData = () =>
	{
		if (!this.IsMapSizeValid())
			return;

		var bounds = this.mapInstance.getBounds();
		if (!bounds)
			return;

		var lngMin = bounds.b.b;
		var lngMax = bounds.b.f;

		var latMin = bounds.f.b;
		var latMax = bounds.f.f;

		this.zoomFactor = this.mapInstance.getZoom();

		if (this.lastLngMin !== lngMin || this.lastLngMax !== lngMax ||
			this.lastLatMin !== latMin || this.lastLatMax !== latMax)
		{
			this.lastLngMin = lngMin;
			this.lastLngMax = lngMax;
			this.lastLatMin = latMin;
			this.lastLatMax = latMax;

			this.OnViewportChanged();
		}
	}

	CreateSearchQuery()
	{
		var query;
		if (this.props.createQuery)
		{
			query = this.props.createQuery();
		}
		else
		{
			var filterIds = AppState.instance.filterManager.CreateFilterId(AppState.instance.filterStack);
			query = AppState.instance.contentStore.CreateSearchQuery(filterIds);
		}

		if (this.props.ignoreActiveFilter)
		{
			query.filterStack = [];

			query.ageMin = 0;
			query.ageMax = 99;

			query.tripStartDate = new Date();
			query.tripEndDate = Utils.DatePlusDays(query.tripStartDate, 6 * 31);

			query.q = undefined;

			query.filterMinRating = undefined;
			query.filterMaxPrice = undefined;
		}

		query.onlyContent = true;
		query.filterPhoto = this.props.filterPhoto;
		query.count = 999999;
		query.minimalVersion = true;
		query.gridSize = this.props.gridSize || 8;
		query.token = undefined;
	
		query.lngMin = this.lastLngMin;
		query.lngMax = this.lastLngMax;
		query.latMin = this.lastLatMin;
		query.latMax = this.lastLatMax;

		// set query.location to center of map
		query.location = {
			latitude: this.centerLat,
			longitude: this.centerLng
		};
		query.referenceLocation = query.location;

		query.forceInclude = [];
		if (this.props.forceInclude && this.props.forceInclude.length > 0)
		{
			query.forceInclude = query.forceInclude.concat(this.props.forceInclude);
		}
		if (this.props.forceIncludeOnly)
			query.forceIncludeOnly = true;

		return query;
	}

	OnDataLoaded(r)
	{
		if (this.props.mapSimpleMode && r)
		{
			// Only take the forceInclude
			for (var i = r.items.length - 1; i >= 0; --i)
			{
				var aId = r.items[i].id;
				var remove = true;
				if (this.props.forceInclude && this.props.forceInclude.indexOf(aId) >= 0)
				{
					remove = false;
				}

				if (remove)
				{
					r.items.splice(i, 1);
				}
			}
		}

		var diffFound = false;
		if (r && this.activities.length === r.items.length)
		{
			for (i = 0; i < r.items.length; ++i)
			{
				var searchId = r.items[i].id;
				var found = false;
				for (var j = 0; j < this.activities.length; ++j)
				{
					if (this.activities[j].id === searchId)
					{
						found = true;
						break;
					}
				}

				if (!found)
				{
					diffFound = true;
					break;
				}
			}
		}
		else
		{
			diffFound = true;
		}

		if (diffFound || (!r && this.props.additionalMarkerCoords))
		{
			var targetIndex = 9999999;
			var prevId;
			var nextId;
			if (this.selectedActivityId)
			{
				targetIndex = this.GetElementIndexById("mapcard_" + this.selectedActivityId);

				prevId = this.GetElementIdByIndex(targetIndex - 1);
				nextId = this.GetElementIdByIndex(targetIndex + 1);

				//console.log("  need: " + this.selectedActivityId + "," + prevId.substring(8) + "," + nextId.substring(8));
			}

			// put the selected activity on the same index so that the selection does not change
			//var rP;
			//var rS;
			//var rN;

			if (r)
			{
				if (prevId)
					this.PutItemOnPosition(r, Number(prevId.substring(8)), targetIndex - 1);
				this.PutItemOnPosition(r, this.selectedActivityId, targetIndex);
				if (nextId)
					this.PutItemOnPosition(r, Number(nextId.substring(8)), targetIndex + 1);
				//console.log("  r: " + rS + "," + rP + "," + rN);
			}

			//TODO: handle loading queue
			this.activities = r ? r.items : [];
			this.OnActivitiesLoaded();

			setTimeout(this.ScrollToSelection, 100);
		}
		this.isLoading = false;
	}

	PutItemOnPosition(r, activityId, targetIndex)
	{
		if (targetIndex < r.items.length)
		{
			for (var i = 0; i < r.items.length; ++i)
			{
				if (r.items[i].id === activityId)
				{
					if (i === targetIndex)
						return 0;

					var item = r.items[i];
					r.items.splice(i, 1);
					r.items.splice(targetIndex, 0, item);
					return 0;
				}
			}
			return 1;
		}
		return 2;
	}

	OnViewportChanged()
	{
		if (!this.mapInstance)
			return;
			
		var c = this.mapInstance.getCenter();
		this.centerLng = c.lng;
		this.centerLat = c.lat;

		if (this.props.onCenterChanged)
			this.props.onCenterChanged(this.centerLat, this.centerLng);

		this.zoomFactor = this.mapInstance.getZoom();

		var query = this.CreateSearchQuery();

		if (this.isLoading)
		{
			this.Queue(query);
			return;
		}

		this.isLoading = true;
		
		this.ProcessQuery(query);
	}

	Queue(query)
	{
		this.queue.push(query);
	}

	IsQueueLoaded()
	{
		return this.queue.length > 0;
	}

	FlushQueue()
	{
		if (this.IsQueueLoaded())
		{
			var q = this.queue[this.queue.length - 1];
			this.queue = [];
			return q;
		}
		
		return undefined;
	}

	ProcessQuery(query)
	{
		if (query.latMin === query.latMax)
		{
			this.isLoading = false;
			return;
		}

		RestApi.SendRequest(this.props.apiEndpoint || "/search", query)
		.then((r) =>
		{
			if (this.IsQueueLoaded())
			{
				query = this.FlushQueue();
				this.isLoading = false;
				this.ProcessQuery(query);
				return;
			}

			this.OnDataLoaded(r);
		})
		.catch((error) =>
		{
			this.isLoading = false;
			var qStr = query ? JSON.stringify(query) : "";
			Tracking.OnError("Error loading content for map: " + error + ", qStr: " + qStr);

			if (this.props.additionalMarkerCoords)
				this.OnDataLoaded();
		});
	}

	ScrollToSelection()
	{
		if (this.selectedActivityId === undefined)
			return;

		var target = $("#" + this.props.id + " #mapcard_" + this.selectedActivityId);
		var container = this.GetScrollContainer();

		// check if we need to scroll
		/*const containerWidth = container.width();
		const cardWidth = target.width();
		if (containerWidth >= 2 * cardWidth)
		{
			const percentVisible = this.CalcPercentInViewportX(target[0]);
			if (percentVisible > 0.95)
				return;
		}*/

		const offset = target.offset();
		if (offset)
		{
			if (this.props.verticalMode)
			{
				var y = offset.top + container.scrollTop();

				const containerOffsetY = container.offset().top;
				y -= containerOffsetY;

				if (containerOffsetY === 0)
					y -= 12;

				//console.log("scroll target: " + x);
				//container.scrollLeft(x - 16);
				container.animate({scrollTop: y});
			}
			else
			{
				var x = offset.left + container.scrollLeft();

				const containerOffset = container.offset().left;
				x -= containerOffset;

				if (containerOffset === 0)
					x -= 12;

				//console.log("scroll target: " + x);
				//container.scrollLeft(x - 16);
				container.animate({scrollLeft: x});
			}
		}
	}

	GetActiveElementIndex()
	{
		var idx = 0;
		var result = 0;
		$("#" + this.props.id + " .mapCard").each(function()
		{
			if (!$(this).hasClass("mapCardInactive"))
				result = idx;
			idx++;
		});
		return result;
	}

	GetElementIndexById(id)
	{
		var idx = 0;
		var result = 0;
		$("#" + this.props.id + " .mapCard").each(function()
		{
			if ($(this).attr('id') === id)
				result = idx;
			idx++;
		});
		return result;
	}

	GetElementIdByIndex(index)
	{
		var result;

		var idx = 0;
		$("#" + this.props.id + " .mapCard").each(function()
		{
			if (index === idx)
				result = $(this).attr('id');
			idx++;
		});
		return result;
	}

	GetActivityById(id)
	{
		for (var i = 0 ; i < this.activities.length; ++i)
		{
			if (this.activities[i].id === id)
				return this.activities[i];
		}
		return undefined;
	}

	OnMapCardsScroll = (event) =>
	{
		if (this.scrollIsAnimated)
			return;

		var thisPtr = this;

		var maxPercent = 0;
		var maxObjIndex;
		var targetClasses = [];

		var container = this.GetScrollContainer();

		var scrollLeft = 0;

		if (this.props.verticalMode)
		{
			container.each(function()
			{
				if (this.scrollTop !== 0)
					scrollLeft = this.scrollTop;
			});
		}
		else
		{
			container.each(function()
			{
				if (this.scrollLeft !== 0)
					scrollLeft = this.scrollLeft;
			});
		}

		var isAtBeginning = scrollLeft < 40;

		if (container && container.length > 0)
		{
			if (this.props.verticalMode)
			{
				if (container.height() > 450)
					isAtBeginning = true;
			}
			else
			{
				if (container.width() > 450)
					isAtBeginning = true;
			}
			

			const containerOffset = this.props.verticalMode ? container.offset().top : container.offset().left;

			var maxPercentFound = false;

			$("#" + this.props.id + " .mapCard").each(function()
			{
				if (maxPercentFound)
					return;

				targetClasses.push({obj: this, inactive: true});

				var p;
				if (thisPtr.props.verticalMode)
					p = Utils.CalcPercentInViewportY(this, containerOffset);
				else
					p = Utils.CalcPercentInViewportX(this, containerOffset);

				if ((isAtBeginning && p > maxPercent) ||
					(!isAtBeginning && p >= maxPercent))
				{
					maxObjIndex = targetClasses.length - 1;
					maxPercent = p;

					if (p >= 0.9999999)
					{
						maxPercentFound = true;
					}
				}
			});

			if (maxObjIndex !== undefined)
			{
				targetClasses[maxObjIndex].inactive = false;
			}

			for (var i = 0; i < targetClasses.length; ++i)
			{
				var obj = $(targetClasses[i].obj);
				var shouldBeInactive = targetClasses[i].inactive;
				var isInactive = obj.hasClass("mapCardInactive");
				if (shouldBeInactive && !isInactive)
					obj.addClass("mapCardInactive");
				else if (!shouldBeInactive && isInactive)
				{
					obj.removeClass("mapCardInactive");

					var oId = obj.attr('id');
					var aId = Number(oId.substring(8));
					this.ScheduleForSelection(aId);
				}
			}
		}

		AppState.instance.OnScreenScrolled(this);
	}

	ScheduleForSelection(aId)
	{
		//console.log("schedule: " + aId);
		this.checkSelectedActivityId = aId;
		setTimeout(this.CheckSelectedActivityId(aId), 300);
	}

	CheckSelectedActivityId = aId => () =>
	{
		//console.log("CheckSelectedActivityId: " + aId);

		if (this.checkSelectedActivityId === aId)
		{
			if (this.selectedActivityId !== aId)
			{
				//console.log("set: " + aId);
				this.selectedActivityId = aId;
				if (this._isMounted)
					this.forceUpdate();

				// Center map around selected card
				// var activity = this.GetActivityById(aId);
				// if (activity)
				// {
				// 	this.centerLng = activity.longitude;
				// 	this.centerLat = activity.latitude;
				// 	this.mapInstance.panTo({ lat: this.centerLat, lng: this.centerLng });
				// }
			}
		}
	}

	GetScrollContainerSelector()
	{
		if (this.props.verticalMode)
			return "#" + this.props.id + " .mapPageBottom";
		else
			return "#" + this.props.id + " #mapResultCards";
	}

	GetScrollContainer()
	{
		if (!this.cachedScrollContainer)
		{
			this.cachedScrollContainer = $(this.GetScrollContainerSelector());
		}

		return this.cachedScrollContainer;
	}

	OnAfterRender = () =>
	{
		var container = this.GetScrollContainer();
		container.scroll(this.OnMapCardsScroll);

		if (!AppState.instance.deviceInfo.desktop)
		{
			const containerWidth = this.props.verticalMode ? container.height() : container.width();
			const needsScrolling = (containerWidth < this.numRenderedElements * this.elementInterval);
			if (needsScrolling !== this.needsScrolling)
			{
				this.needsScrolling = needsScrolling;
				if (this._isMounted)
					this.forceUpdate();
			}
		}
	}

	OnCheckVisibility = () =>
	{
		if (!this.containerElement)
		{
			this.containerElement = $("#" + this.containerId)[0];
		}

		if (!this.containerElement)
			return;
		
		const percentVisible = Utils.CalcPercentInViewportY(this.containerElement);
		if (percentVisible > 0)
		{
			this.loadingTriggered = true;
			if (this._isMounted)
				this.forceUpdate();
		}

		if (this.loadingTriggered)
		{
			if (this.visibilityInterval)
			{
				clearInterval(this.visibilityInterval);
				this.visibilityInterval = undefined;
			}
		}
	}

	OnCloseClicked = (e) =>
	{
		e.stopPropagation();
		e.preventDefault();

		setTimeout(() => {
			if (this.props.onClose)
				this.props.onClose(e);
		}, 60);
	}

	OnContentClicked = (activity) => (event) =>
	{
		// if (event && event.stopPropagation && event.preventDefault)
		// {
		// 	event.stopPropagation();
		// 	event.preventDefault();
		// }

		if (activity)
		{
			this.selectedActivityId = activity.id;
			this.ScrollToSelection();
		}

		if (this.props.onContentClicked)
			this.props.onContentClicked(event, activity);
	}

	OnBottomScrollButton = (direction) => () =>
	{
		var container = this.GetScrollContainer();
		var x = container.scrollLeft();

		x += direction * this.elementInterval;
		container.animate({scrollLeft: x});
	}

	OnMapMoveStart = () =>
	{
		if (this.props.onTouchStart)
			this.props.onTouchStart();
	}

	OnMapMove = () =>
	{
		var c = this.mapInstance.getCenter();
		this.centerLng = c.lng;
		this.centerLat = c.lat;
	}

	OnMapMoveEnd = () =>
	{
		if (this.props.onTouchEnd)
			this.props.onTouchEnd();

		this.updateTimer = Utils.SetTimer(this.updateTimer, 200, this.UpdateViewportData, 50);
	}

	renderMap()
	{
		var isVisible = true;
		if (!this.loadingTriggered && this.props.loadOnVisibility === true)
			isVisible = false;

		const hasFilterBar = !this.props.mapSimpleMode && !this.props.disableFilterButton;

		return (
			<div className="mapPageTop" key="mapPageTop" id={this.containerId}>
				{this.props.onClose && <IconButton className="closeButton" key="iconButton" classes={{root: this.props.classes.closeButtonRoot}}
					onClick={this.OnCloseClicked} aria-label="Close">
					<CloseIcon />
				</IconButton>}

				{hasFilterBar && this.RenderFilterButton()}
				{this.RenderLocationButton()}
				{this.props.highlightMarkers && this.props.highlightMarkers.length > 0 && this.RenderHighlightButton()}
				{!this.props.disableZoomButtons && this.RenderZoomButtons()}

				{this.isLoading && <div className={"mapLoadingOverlay" + (hasFilterBar ? " withfilterbar":"")}>
					<CircularProgress size={32}/>
				</div>}

				{isVisible && <MapLeaflet
					key="mapLeaflet"
					className="mapContainer"
					zoom={this.zoomFactor}
					center={[this.centerLat, this.centerLng ]}
					onMoveStart={this.OnMapMoveStart}
					onMoveEnd={this.OnMapMoveEnd}
					gestureHandling={this.props.gestureHandling}
					markers={this.markers}
					highlightMarkers={this.props.highlightMarkers}
					onLoad={(map) => {this.OnMapLoaded(map);}}
					onMarkerClick={this.OnMarkerClick}
					selectedActivityId={this.selectedActivityId}
					pathCoordinates={this.props.pathCoordinates}
				/>}
			</div>
		);
	}

	OnScrollingNeeded = (neededX, neededY) =>
	{
		if (neededX !== this.needsScrolling)
		{
			this.needsScrolling = neededX;
			if (this._isMounted)
				this.forceUpdate();
		}
	}

	renderContent()
	{
		const scrollButtons = AppState.instance.deviceInfo.desktop && !this.props.verticalMode;

		var scrollProp = {};
		if (!AppState.instance.deviceInfo.ios)
		{
			scrollProp = {
				overflowX: 'auto'
			};
		}

		var thisPtr = this;

		return (
			<div className={"mapPageBottom" + (scrollButtons ? " withButtons":"")} key="mapPageBottom">

				{this.props.showTitle && <div className="mapResultCardsTitle">
					{!this.props.title && Translate.T("de.Einträge auf der Landkarte", "en.Activities on map", "map.nearby")}
					{this.props.title}
				</div>}

				{scrollButtons && this.needsScrolling && <Button className="bottomScrollButton left" onClick={this.OnBottomScrollButton(-1)} ><ArrowLeft/></Button>}

				<div id="mapResultCards" className={"searchResultCards" + (this.props.verticalMode ? " vertical":"")} style={scrollProp}>
					{this.props.verticalMode && AppState.instance.deviceInfo.desktop &&
					<MapContent
						map={this}
						key="mapContent"
						//mapContentUpdateCount={this.mapContentUpdateCount}
						content={this.activities}
						selectedContentId={this.selectedActivityId}
						showTitle={false}
						containerSelector={this.GetScrollContainerSelector()}
						scrollOffset={24}
					/>}

					{!this.props.verticalMode && AppState.instance.deviceInfo.desktop &&
					<MapContent
						map={this}
						key="mapContent"
						//mapContentUpdateCount={this.mapContentUpdateCount}
						content={this.activities}
						selectedContentId={this.selectedActivityId}
						showTitle={false}
						containerSelector={this.GetScrollContainerSelector()}
						scrollOffset={0}
						minContentWidth={200}
						idealNumContentPerRow={4}
						maxNumRows={1}
						onScrollingNeeded={this.OnScrollingNeeded}
					/>}

					{!AppState.instance.deviceInfo.desktop && this.activities.map(function(activity, index)
					{
						var isFirst = index === 0 || thisPtr.numRenderedElements === 0;

						var isDirectionMarker = false;
						if (thisPtr.props.directionMarkers)
						{
							for (var j = 0; j < thisPtr.props.directionMarkers.length; ++j)
							{
								if (thisPtr.props.directionMarkers[j].activity.id === activity.id)
								{
									isDirectionMarker = true;
									break;
								}
							}
						}

						if (thisPtr.props.hideContentCard)
						{
							if (thisPtr.props.hideContentCard.indexOf(activity.id) >= 0)
								return undefined;
						}

						++thisPtr.numRenderedElements;

						//console.log("MAP ITEM: " + index + ": " + activity.id + ", first: " + isFirst);

						return (
							<div
								className={"mapCard" + (isDirectionMarker ? " mapCardDirection":"") + (isFirst === false ? " mapCardInactive":"")}
								key={"mapcard_" + activity.id + "_" + index}
								id={"mapcard_" + activity.id}
							>
								<ContentItem
									contentData={activity}
									itemType={"content"}
									key={"activity_" + activity.id}
									id={"activity_" + activity.id}
									renderMode={thisPtr.props.contentMode || 'tiny'}
									isLast={index === thisPtr.activities.length - 1}
									isFirst={isFirst}
									actionButton={isDirectionMarker ? (thisPtr.props.activityButton2 ? thisPtr.props.activityButton2 : thisPtr.props.activityButton) : thisPtr.props.activityButton}
									showLocation={thisPtr.props.showLocation}
									maxNumCategories={thisPtr.props.maxNumCategories}
									onClickEnabled={thisPtr.props.disableBottomClick === true ? false : true}
									bookmarkEnabled={false}
									onClick={thisPtr.OnContentClicked(activity)}
								/>
							</div>
						);
					})}
				</div>

				{scrollButtons && this.needsScrolling && <Button className="bottomScrollButton right" onClick={this.OnBottomScrollButton(+1)} ><ArrowRight/></Button>}
			</div>
		);
	}

	render()
	{
		this.cachedScrollContainer = undefined;
		this.numRenderedElements = 0;
		setTimeout(this.OnAfterRender, 100);

		var showContent = false;
		
		this.activities.map((activity) =>
		{
			if (this.props.hideContentCard)
			{
				if (this.props.hideContentCard.indexOf(activity.id) >= 0)
					return undefined;
			}
			showContent = true;
			return true;
		});

		if (this.props.verticalMode)
			showContent = true;
		if (this.props.disableContent)
			showContent = false;

		return (
			<div className={"mapPageContainer" + AppState.instance.GetPlatformClasses() + (this.props.verticalMode ? " vertical":" horizontal")}
				key="mapPageContainer" id={this.props.id}
				data-tk="map"
			>
				{showContent && this.props.verticalMode && this.renderContent()}
				{this.renderMap()}
				{showContent && !this.props.verticalMode && this.renderContent()}
			</div>
		);
	}
}

Map.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Map);