import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import AppState from '../../AppState';
import AdvCard from './AdvCard';
import ContentCardStack from '../ContentCardStack';

const styles = {
};

@observer
class AdvCardStack extends React.Component
{
	stack;
	content = [];
	_mounted = false;
	frontId;

	constructor(props)
	{
		super(props);

		this.UpdateContent();
	}

	componentDidMount()
	{
		this._mounted = true;
		this.UpdateContent();
	}

	componentWillUnmount()
	{
		this._mounted = false;
	}

	componentDidUpdate()
	{
		this.UpdateContent();
	}

	UpdateContent()
	{
		var newContent = [];

		//this.content = [];

		const ids = this.GetIds();

		if (ids)
		{
			for (var i = 0; i < ids.length; ++i)
			{
				newContent.push(
					{
						id: ids[i],
						type: "collectingCard",
						onRender: this.RenderCollectingCard,
						showScrollGradient: false
					});
			}

			var frontId = this.props.frontId !== undefined ? this.props.frontId : this.frontId;

			if (newContent.length > 0 && frontId !== undefined)
			{
				// Rotate frontId to the front
				for (i = 0; i < newContent.length; ++i)
				{
					if (newContent[0].id === frontId)
					{
						break;
					}
					else
					{
						var front = newContent[0];
						newContent.splice(0, 1);
						newContent.push(front);
					}
				}
			}
		}

		var changed = false;

		if (this.content.length !== newContent.length)
		{
			changed = true;
		}
		else
		{
			for (i = 0; i < this.content.length; ++i)
			{
				if (this.content[i].id !== newContent[i].id)
				{
					changed = true;
					break;
				}
			}
		}

		this.content = newContent;

		if (this._mounted && changed)
		{
			if (this.stack)
				this.stack.forceUpdate();
			this.forceUpdate();
		}
	}

	GetIds()
	{
		if (this.props.instanceIds)
			return this.props.instanceIds;
		else if (this.props.cardIds)
			return this.props.cardIds;
		return undefined;
	}

	GetFrontId()
	{
		if (this.content.length > 0)
			return this.content[0].id;
		return undefined;
	}

	// OnThrowOutDecision = (cardData, direction) =>
	// {
	// }

	OnThrowOutEnd = (cardData, direction) =>
	{
		var front = this.content[0];
		this.content.splice(0, 1);

		this.content.push(front);

		this.frontId = this.GetFrontId();

		// We need to change the instance which is going to be passed to ContentCardStack so it's re-rendered
		this.content = this.content.slice();

		if (this.props.onThrowOutEnd)
			this.props.onThrowOutEnd(front.id, this.content[0].id);
		else
			this.forceUpdate();
	}

	OnStackInit = (stack) =>
	{
		this.stack = stack;

		if (this.props.onInit)
			this.props.onInit(this);
	}

	RenderCollectingCard = (content, checkContentData) =>
	{
		if (checkContentData !== false && content.contentData === undefined)
			return null;

		var cardId;
		if (this.props.instanceIds)
		{
			const instance = AppState.instance.advManager.GetCardInstance(content.id);
			if (!instance)
				return null;

			cardId = instance.cardId;
		}
		else if (this.props.cardIds)
		{
			cardId = content.id;
		}
		else
		{
			return null;
		}

		return (
			<div className="inneradvcardstackcard">
				<AdvCard cardId={cardId} isFirst={false} isLast={false}
					showImage={true} showTitle={true} showPlus={false} showNumInstances={false}
					stretched={true}
					onClick={this.props.onCardClick}
				/>
			</div>
		);
	}

	render()
	{
		if (this.content.length === 0)
			return null;

		if (this.content.length === 1)
		{
			return this.RenderCollectingCard(this.content[0], false);
		}

		return (
			<ContentCardStack
				id={this.props.id}
				className={this.props.className || "advcardstack"}
				key={this.props.key}
				onInit={this.OnStackInit}
				content={this.content}
				classNameCardWrapper={this.props.classNameCardWrapper || "advcardstackcard"}
				containerSelector={this.props.containerSelector}
				//onThrowOutDecision={this.OnThrowOutDecision}
				onThrowOutEnd={this.OnThrowOutEnd}
				showBackgroundCards={true}
				backgroundCardYOffset={20}
			/>
		);
	}
}

AdvCardStack.propTypes = {
	classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(AdvCardStack);