import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import SearchIcon from 'material-ui-icons/Search';
import SearchField from './SearchField';
import Translate from '../Translate';
import Slide from 'material-ui/transitions/Slide';
import CategoryTab from './CategoryTab';
import AppState from '../AppState';
import { observable } from '../../node_modules/mobx';
import Button from 'material-ui/Button';
import TextField from 'material-ui/TextField';
import RestApi from '../RestApi';
import $ from 'jquery';
import Utils from '../Utils';
import { CircularProgress } from '../../node_modules/material-ui';
import EmailIcon from 'material-ui-icons/Email';
import SendIcon from 'material-ui-icons/Send';
import ClearIcon from 'material-ui-icons/Clear';

const styles = theme => ({	
});

@observer
class PeopleSearch extends React.Component
{
	@observable searchHasFocus = false;
	searchTerm;
	@observable isLoading = 0;
	@observable isValidEmail = false;
	searchResults = [];

	componentDidMount()
	{
		AppState.instance.contentStore.AddOnDataChanged("profiles", this.OnDataChanged);
	}

	componentWillUnmount()
	{
		AppState.instance.contentStore.RemoveOnDataChanged("profiles", this.OnDataChanged);
	}

	OnSearch = (searchTerm) =>
	{
		this.searchTerm = searchTerm;

		AppState.instance.contentStore.Search(searchTerm, "profiles");

		if (!searchTerm || searchTerm.length === 0)
		{
			this.searchResults = [];
			this.forceUpdate();
		}

		const searchTermIsEmail = Utils.ValidateEmail(this.searchTerm);
		if (searchTermIsEmail)
			this.isValidEmail = true;
	}

	OnSearchFocus = () =>
	{
		this.searchHasFocus = true;
	}

	OnSearchBlur = () =>
	{
		/*setTimeout(() => {
			this.searchHasFocus = false;
		}, 300);*/
	}

	OnSearchResultClicked = (contentData) =>
	{

	}

	OnDataChanged = (action, newData) =>
	{
		if (action === 'request')
		 	++this.isLoading;
		else if (action === 'data' || action === 'error' || action === 'abort' || action === 'eod' || action === 'flushed')
		 	this.isLoading = 0;

		if (action === 'data')
		{
			this.searchResults = newData;
			this.forceUpdate();
			console.log(newData);
		}
		else if (action === 'eod')
		{
		}
		else if (action === "flushed")
		{
		}
	}

	SendEmailInvite = () =>
	{
		const searchTermIsEmail = Utils.ValidateEmail(this.searchTerm);
		const email = searchTermIsEmail ? this.searchTerm : $("#inviteEmailInput").val();
		if (!Utils.ValidateEmail(email))
			return; //TODO: error message

		RestApi.SendRequest("/inviteemail", {
			email: email,
			asFamily: this.props.asFamily
		}).then((emailInfo) =>
		{
			console.log(emailInfo);

			if (emailInfo && emailInfo.magicLink)
			{
				var introTag = emailInfo.receiverFirstName;
				if (introTag)
					introTag = " " + introTag;
				else
					introTag = "";

				const subject = Translate.FindTranslation("inviteemail.subject");
				const body = encodeURIComponent(Translate.FindTranslation("inviteemail.body", [emailInfo.magicLink, emailInfo.senderFirstName, introTag]));
				window.location.href = 'mailto:' + email + "?subject=" + subject + "&body=" + body;
			}
			else
			{
				this.OnInviteError();
			}
		})
		.catch((error) =>
		{
			this.OnInviteError();
		});
	}

	OnInviteError()
	{
		AppState.instance.screenMessage.Show(Translate.T(
			"de.Ich konnte die Einladung leider nicht erstellen. Bitte versuche es später nochmal!",
			"en.I was not able to generate the invite. Please try again later", "inviteemail.generate.error"));
	}

	OnEmailFieldChanged = (event) =>
	{
		const newValue = event.target.value;
		this.isValidEmail = Utils.ValidateEmail(newValue);
	}

	OnEmailFieldKeyPress = (event) =>
	{
		var key = event.key || event.keyCode || event.which;
		if (key === 'Enter' || key === '13')
		{
			event.preventDefault();
			const newValue = $("#inviteEmailInput").val();
			this.isValidEmail = Utils.ValidateEmail(newValue);
			if (this.isValidEmail)
				this.SendEmailInvite();
		}
	}

	OnClearInput = () =>
	{
		$(".peopleSearch #searchInput").val("");
		this.searchTerm = undefined;
		this.isValidEmail = false;
		this.searchResults = [];
		this.forceUpdate();
		setTimeout(() => {
			$(".peopleSearch #searchInput").focus();
		}, 100);
	}

	render()
	{
		const searchTermIsEmail = Utils.ValidateEmail(this.searchTerm);
		const showClearIcon = this.searchTerm && this.searchTerm.length > 0 && this.isLoading === 0;

		return (
			<div className="peopleSearch">
				{!showClearIcon && this.isLoading === 0 && <SearchIcon className="searchIcon" />}
				{showClearIcon && <ClearIcon className="searchIcon" onClick={this.OnClearInput} />}
				{this.isLoading !== 0 && <CircularProgress size={24} className="searchIcon" />}

				<SearchField
					breadcrumbs={Translate.FindTranslation("search.people")}
					onSearch={this.OnSearch}
					onFocus={this.OnSearchFocus}
					onBlur={this.OnSearchBlur}
					minLength={1}
				/>

				{this.searchResults.length === 0 && this.searchTerm && this.searchTerm.length > 0 && this.isLoading === 0 &&
					<div className="inviteEmailContainer">
						{Translate.T("de.Ich konnte diese Person leider nicht finden. Möchtest Du sie per Email einladen?",
							"en.I can't find that person. Would you like to send an Email-Invite?", "lena.question.sendemailinvite")}

						{!searchTermIsEmail && <div className="textFieldAndIcon">
							<EmailIcon className="searchIcon" />

							<TextField
								type="email"
								className={"inviteEmail"}
								onChange={this.OnEmailFieldChanged}
								onKeyPress={this.OnEmailFieldKeyPress}
								placeholder={"Email"}
								defaultValue={searchTermIsEmail ? this.searchTerm : undefined}
								autoComplete={"off"}
								InputProps={{
									disableUnderline: true,
									id: this.props.id || "inviteEmailInput",
								}}
							/>

							{this.isValidEmail && <Button onClick={this.SendEmailInvite} className="sendEmailButton">
								<SendIcon />
							</Button>}
						</div>}

						{searchTermIsEmail && <div className="sendEmailDirect">
							<Button onClick={this.SendEmailInvite}>
								<SendIcon />
								{Translate.T("de.Sende Email", "en.Send Email", "sendemail")}
							</Button>
						</div>}
					</div>
				}

				<Slide in={this.searchHasFocus && this.searchResults.length > 0} direction='up'>
					<div className={"peopleSearchResults" + (!this.searchHasFocus ? " hidden":"")}>

						{this.searchHasFocus && this.searchResults.length > 0 &&
							<CategoryTab isRoot={false} 
								categoryIds={"profiles"}
								renderMode="searchresult"
								itemType={"mixed"}
								contentOnly={false}
								preload={true}
								active={true}
								horizontalContentList={false}
								onContentClick={this.OnSearchResultClicked}
						/>}

					</div>
				</Slide>

			</div>
		);
	}
}

PeopleSearch.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(PeopleSearch);