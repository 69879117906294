/* eslint-disable array-callback-return */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import Direction from './Direction';
import Stack from './Stack';

class ReactSwing extends Component
{
	static propTypes =
	{
		children: PropTypes.node.isRequired,
		setStack: PropTypes.func.isRequired,
		config: PropTypes.object,
		throwout: PropTypes.func,
		throwoutend: PropTypes.func,
		throwoutleft: PropTypes.func,
		throwoutright: PropTypes.func,
		throwin: PropTypes.func,
		throwinend: PropTypes.func,
		dragstart: PropTypes.func,
		dragmove: PropTypes.func,
		dragend: PropTypes.func
	};

	static EVENTS = ['throwout','throwoutend', 'throwoutleft', 'throwoutright', 'throwin', 'throwinend', 'dragstart', 'dragmove','dragend'];
	static DIRECTION = Direction;

	constructor(props, context)
	{
		super(props, context);

		const stack = Stack(props.config);
		this.state = {
			stack: stack,
			cardList: []
		};
	}

	componentDidMount()
	{
		const stack = this.state.stack;

		ReactSwing.EVENTS.map((event) =>
		{
			if (this.props[event])
			{
				stack.on(event, this.props[event]);
			}
		});

		React.Children.forEach(this.props.children, (child, key) =>
		{
			const ref = child.ref || key;
			const element = ReactDOM.findDOMNode(this.refs[`${ref}`]);
			const card = stack.createCard(element);

			ReactSwing.EVENTS.map((event) =>
			{
				if (child.props[event])
				{
					card.on(event, child.props[event]);
				}
			});
		});

		this.setState({
			stack: stack
		});

		if (this.props.setStack)
			this.props.setStack(stack);
	}

	componentDidUpdate(prevProps, prevState)
	{
		var stack = this.state.stack;
		stack.setIsContentScrolling(this.props.isContentScrolling);
		stack.setIScroll(this.props.iScroll);

		if (this.props.children.length > prevProps.children.length)
		{
			//const stack = this.state.stack;
			ReactSwing.EVENTS.map((event) =>
			{
				if (this.props[event])
				{
					stack.on(event, this.props[event]);
				}
			});

			React.Children.forEach(this.props.children, (child, key) =>
			{
				const ref = child.ref || key;
				const element = ReactDOM.findDOMNode(this.refs[`${ref}`]);
				const card = stack.createCard(element);
				let result = prevProps.children.find((c) =>
				{
					return c.key === child.key
				});

				if(!result)
				{
					ReactSwing.EVENTS.map((event) =>
					{
						if (child.props[event])
						{
							card.on(event, child.props[event]);
						}
					});
				}
			});

			this.setState({
				stack: stack
			});
			this.props.setStack(stack);
		}
	}

	render()
	{
		const { children, setStack, config, ...otherProps } = this.props;

		const tagProps = Object.keys(otherProps).reduce((result, key, index) =>
		{
			if (ReactSwing.EVENTS.indexOf(key) === -1)
			{
				if (key !== "isContentScrolling" && key !== "iScroll")
					result[key] = otherProps[key];
			}
			return result;
		}, {});

		return (
			<div {...tagProps}>
				{React.Children.map(children, (child, key) =>
				{
					const ref = child.ref || key;
					const childProps = Object.keys(child.props).reduce((result, key, index) =>
					{
						if (ReactSwing.EVENTS.indexOf(key) === -1)
						{
							result[key] = child.props[key];
						}
						return result;
					}, {});

					childProps.ref = ref;
					return React.createElement(child.type, childProps);
				})}
			</div>
		);
	}
}

export default ReactSwing;