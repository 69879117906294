// created with:
// http://mcg.mbitson.com/#!?mcgpalette0=%234285f4&themename=mcgtheme

export const primaryColorLena = {
	50: '#fce4e9',
    100: '#f7bbc8',
    200: '#f18ea3',
    300: '#eb607e',
    400: '#e73e62',
    500: '#e31c46',
    600: '#e0193f',
    700: '#dc1437',
    800: '#d8112f',
    900: '#d00920',
    A100: '#fffbfb',
    A200: '#ffc8cc',
    A400: '#ff959d',
    A700: '#ff7b86',
    'contrastDefaultColor': 'light',
};

export const secondaryColorLena = {
    50: '#e0eeff',
    100: '#b3d4ff',
    200: '#80b8ff',
    300: '#4d9cfe',
    400: '#2686fe',
    500: '#0071fe',
    600: '#0069fe',
    700: '#005efe',
    800: '#0054fe',
    900: '#0042fd',
    A100: '#ffffff',
    A200: '#f1f3ff',
    A400: '#becaff',
    A700: '#a4b6ff',
    'contrastDefaultColor': 'light',
};