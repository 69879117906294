import AppState from './AppState';
import Delegate from './utils/Delegate';
import RestApi from './RestApi';
import Translate from './Translate';
import Utils from './Utils';

export default class ListManager
{
	lists = [];
	onListsChanged = new Delegate();
	localTransferQueue = [];
	isInTransfer = false;

	Init()
	{
		AppState.instance.AddOnAuthDone(this.OnAuthDone);
	}

	OnAuthDone = () =>
	{
		this.LoadData();
	}

	LoadData()
	{
		if (!AppState.instance.GeneralDataLoadingEnabled())
			return;
			
		this.TransferLocalStorage();

		RestApi.SendRequest("/userlists", undefined, undefined, undefined, "GET")
		.then((r) =>
		{
			if (!this.isInTransfer)
			{
				this.lists = r;
				if (!this.lists)
					this.lists = [];

				this.EnsureDefaultLists();

				this.onListsChanged.Call();
			}
		})
		.catch(() =>
		{
			this.EnsureDefaultLists();
		});
	}

	TransferLocalStorage()
	{
		if (!AppState.instance.IsLoggedIn())
			return;

		var list = this.LoadLocalList();
		if (!list)
			return;
		if (list.items.length === 0)
			return;

		var q = { contentIds: [] };
		for (var i = 0; i < list.items.length; ++i)
			q.contentIds.push(list.items[i].contentId);

		this.isInTransfer = true;
		RestApi.SendRequestText("/locallisttransfer", q)
		.then((r) =>
		{
			if (r === "ok")
			{
				Utils.RemoveLocalStorage("locallist");
			}
			this.isInTransfer = false;
			this.LoadData();
		})
		.catch(() =>
		{
			this.isInTransfer = false;
		});
	}

	RefreshData()
	{
		//TODO: set max refresh interval (~1min)
		this.LoadData();
	}

	GetLists()
	{
		this.EnsureDefaultLists();
		return this.lists;
	}

	GetListById(id)
	{
		for (var i = 0; i < this.lists.length; ++i)
		{
			const list = this.lists[i];
			if (list.id === id)
				return list;
		}

		return undefined;
	}

	GetListByTitle(title)
	{
		for (var i = 0; i < this.lists.length; ++i)
		{
			const list = this.lists[i];
			if (list.title === title)
				return list;
		}

		return undefined;
	}

	GetListIndex(id)
	{
		for (var i = 0; i < this.lists.length; ++i)
		{
			const list = this.lists[i];
			if (list.id === id)
				return i;
		}

		return -1;
	}

	SetListById(id, list)
	{
		for (var i = 0; i < this.lists.length; ++i)
		{
			if (this.lists[i].id === id)
			{
				this.lists[i] = list;
				this.onListsChanged.Call();
				break;
			}
		}
	}

	IsLocalList(listId)
	{
		if (listId >= 0)
			return false;

		for (var i = 0; i < this.lists.length; ++i)
		{
			if (this.lists[i].id === listId)
			{
				return true;
			}
		}

		return false;
	}

	OnNewListContent()
	{
		this.AddListContentIndicator(1);
	}

	AddListContentIndicator(incr)
	{
		if (!AppState.instance.showNewIndicator[AppState.TabIndexBookmark])
			AppState.instance.showNewIndicator[AppState.TabIndexBookmark] = incr;
		else
		{
			if (AppState.instance.showNewIndicator[AppState.TabIndexBookmark] === true)
				AppState.instance.showNewIndicator[AppState.TabIndexBookmark] = incr;
			else
				AppState.instance.showNewIndicator[AppState.TabIndexBookmark] += incr;
		}
	}

	AddToList(listId, contentId)
	{
		this.OnNewListContent();
		
		if (this.IsLocalList(listId))
		{
			return this.AddToListLocal(listId, contentId)
		}

		var q = {
			listId: listId,
			contentId: contentId
		};

		return RestApi.SendRequest("/createuserlistitem", q)
			.then((newList) =>
			{
				if (newList)
				{
					this.SetListById(listId, newList);
					return new Promise((resolve, reject) => { return resolve(newList);});
				}
				else
				{
					return new Promise((resolve, reject) => { return reject(newList);});
				}
			})
			.catch((error) =>
			{
				return new Promise((resolve, reject) => { return reject(error);});
			});
	}

	RemoveFromList(listId, contentId)
	{
		if (this.IsLocalList(listId))
		{
			return this.RemoveFromListLocal(listId, contentId)
		}

		// get confirmation if removing from check-in list
		if (this.IsCheckInList(listId))
		{
			return new Promise((resolve, reject) =>
			{ 
				AppState.instance.screenMessage.ShowAlert(Translate.T("en.Confirmation", "de.Bestätigung", "confirmation.title"),
					Translate.T("en.Do you really want to remove this card from your list of visited places?",
						"de.Willst Du diese Karte wirklich aus Deiner Liste der besuchten Ausflugsziele entfernen?", "confirmation.removefromcheckin"),
					[{label: Translate.FindTranslation("answer.no"), value: 0}, {label: Translate.FindTranslation("answer.yes"), value: 1}], true, (resultButton) =>
				{
					if (resultButton && resultButton.value)
					{
						return this.DoRemoveFromList(listId, contentId)
						.then((result) =>
						{
							return resolve(result);
						})
						.catch((error) =>
						{
							return reject(error);
						});
					}
					else
					{
						return reject(undefined);
					}
				});
			});
		}
		else
		{
			return this.DoRemoveFromList(listId, contentId);
		}
	}

	IsCheckInList(listId)
	{
		var list = this.GetListById(listId);
		if (!list)
			return false;
		return list.flag === 5;
	}

	DoRemoveFromList(listId, contentId)
	{
		var q = {
			listId: listId,
			contentId: contentId
		};

		return RestApi.SendRequest("/deleteuserlistitem", q)
			.then((newList) =>
			{
				if (newList)
				{
					this.SetListById(listId, newList);
					return new Promise((resolve, reject) => { return resolve(newList);});
				}
				else
				{
					return new Promise((resolve, reject) => { return reject(newList);});
				}
			})
			.catch((error) =>
			{
				return new Promise((resolve, reject) => { return reject(error);});
			});
	}

	GetListContent(listId)
	{
		var loc = AppState.instance.GetBestLocation();

		//TODO: ContentDetails might be already loaded -> load cached ones, and don't request those from the server again
		//TODO: cache in localstorage even?
		var q = {
			id: listId,
			referenceLocation: loc,
			language: AppState.instance.userProfile.userLang
		};

		if (this.IsLocalList(listId))
		{
			var list = this.GetListById(listId);

			// We can't use the list ID but need to send the content IDs
			q.id = undefined;

			q.contentIds = [];
			for (var i = 0; i < list.items.length; ++i)
				q.contentIds.push(list.items[i].contentId);
		}

		return RestApi.SendRequest("/userlistcontent", q)
		.then((r) =>
		{
			if (r)
			{
				for (var i = 0; i < r.length; ++i)
				{
					var details = r[i];
					if (details.activity)
					{
						AppState.instance.contentStore.PrepareActivityDetails(details);
						AppState.instance.contentStore.SetActivityDetails(details.activity.name, details);
					}
				}

				return new Promise((resolve, reject) => { return resolve(r);});
			}
			else
			{
				return new Promise((resolve, reject) => { return reject(undefined);});
			}
		})
		.catch((error) =>
		{
			return new Promise((resolve, reject) => { return reject(error);});
		});
	}

	GetContentStatus(contentId)
	{
		var lists = this.GetListsOfContent(contentId);

		var result = {
			bookmarked: false,
			checkedin: false,
			checkInListId: undefined,
			bookmarkLists: [],
		};

		for (var i = 0; i < lists.length; ++i)
		{
			if (this.IsCheckInList(lists[i].id))
			{
				result.checkInListId = lists[i].id;
				result.checkedin = true;
			}
			else
			{
				result.bookmarked = true;
				result.bookmarkLists.push(lists[i].id);
			}
		}

		return result;
	}

	IsContentOnAnyList(contentId)
	{
		var lists = this.GetListsOfContent(contentId);
		return lists.length > 0;
	}

	IsContentOnList(contentId, listId, ignoreCheckIn)
	{
		var list = this.GetListById(listId);
		if (!list)
			return false;

		if (list.items)
		{
			for (var j = 0; j < list.items.length; ++j)
			{
				if (list.items[j].contentId === contentId)
				{
					if (ignoreCheckIn && list.flag === 5)
						continue;
					return true;
				}
			}
		}

		return false;
	}

	GetListsOfContent(contentId, ignoreCheckIn)
	{
		var result = [];

		for (var i = 0; i < this.lists.length; ++i)
		{
			const list = this.lists[i];

			if (list.items)
			{
				for (var j = 0; j < list.items.length; ++j)
				{
					if (list.items[j].contentId === contentId)
					{
						if (ignoreCheckIn && list.flag === 5)
							continue;

						result.push(list);
						break;
					}
				}
			}
		}

		return result;
	}

	CreateList(title, desc, listType)
	{
		if (!title || title.length === 0)
			return new Promise((resolve, reject) => { return reject(undefined);});

		var q = {
			title: title,
			desc: desc,
			listType: listType
		};

		return RestApi.SendRequest("/createuserlist", q)
			.then((newList) =>
			{
				if (newList)
				{
					this.lists.push(newList);
					this.onListsChanged.Call();
					return new Promise((resolve, reject) => { return resolve(newList);});
				}
				else
				{
					return new Promise((resolve, reject) => { return reject(newList);});
				}
			})
			.catch((error) =>
			{
				return new Promise((resolve, reject) => { return reject(error);});
			});
	}

	EditList(listId, title, desc)
	{
		if (!title || title.length === 0)
			return;

		var q = {
			id: listId,
			title: title,
			desc: desc
		};

		return RestApi.SendRequest("/edituserlist", q)
			.then((newList) =>
			{
				if (newList)
				{
					this.SetListById(listId, newList);
					return new Promise((resolve, reject) => { return resolve(newList);});
				}
				else
				{
					return new Promise((resolve, reject) => { return reject(newList);});
				}
			})
			.catch((error) =>
			{
				return new Promise((resolve, reject) => { return reject(error);});
			});
	}

	DeleteList(listId)
	{
		if (this.IsLocalList(listId))
		{
			var index = this.GetListIndex(listId);
			if (index >= 0)
			{
				this.lists.splice(index, 1);
			}

			Utils.RemoveLocalStorage("locallist");
			this.onListsChanged.Call();
			return new Promise((resolve, reject) => { return resolve("ok");});
		}

		return RestApi.SendRequestText("/userlists/" + listId, undefined, undefined, undefined, "DELETE")
			.then((response) =>
			{
				if (response === "ok")
				{
					for (var i = 0; i < this.lists.length; ++i)
					{
						if (this.lists[i].id === listId)
						{
							this.lists.splice(i, 1);
							this.onListsChanged.Call();
							break;
						}
					}
					return new Promise((resolve, reject) => { return resolve(response);});
				}
				else
				{
					return new Promise((resolve, reject) => { return reject(response);});
				}
			})
			.catch((error) =>
			{
				return new Promise((resolve, reject) => { return reject(error);});
			});
	}

	EmptyList(listId)
	{
		if (this.IsLocalList(listId))
		{
			var list = this.GetListById(listId);
			if (list)
			{
				list.items = [];
				this.SaveLocalList(list);
				this.onListsChanged.Call();
				return new Promise((resolve, reject) => { return resolve(list);});
			}
			else
			{
				return new Promise((resolve, reject) => { return reject(undefined);});
			}
		}

		var q = {
			id: listId
		};

		return RestApi.SendRequest("/emptyuserlist", q)
			.then((newList) =>
			{
				if (newList)
				{
					this.SetListById(listId, newList);
					return new Promise((resolve, reject) => { return resolve(newList);});
				}
				else
				{
					return new Promise((resolve, reject) => { return reject(undefined);});
				}
			})
			.catch((error) =>
			{
				return new Promise((resolve, reject) => { return reject(error);});
			});
	}

	EnsureDefaultLists()
	{
		if (this.lists.length === 0)
		{
			// Create a locally-stored default bookmark list

			var list = this.LoadLocalList();
			if (!list)
			{
				list = {
					id: -1,
					ownerId: -1,
					type: 0, //activity
					flag: 0, //custom
					title: Translate.FindTranslation("filter.bookmarks"),
					desc: undefined,
					created: new Date(),
					lastUpdated: new Date(),
					items: [],
					pictures: [],
					nextItemAutoIncId: 0,
					isShared: false,
				};
			}

			this.lists.push(list);
			this.onListsChanged.Call();
		}
	}

	IsDefaultList(list)
	{
		const isBookmarks = list.title === "filter.bookmarks" ||
			list.title === "Lesezeichen" || list.title === "Bookmarks";
		if (isBookmarks)
			return true;

		return (list.flag !== 0); //custom
	}

	AddToListLocal(listId, contentId)
	{
		var list = this.GetListById(listId);
		if (!list)
			return new Promise((resolve, reject) => { return reject(undefined);});

		list.items.push(
			{
				id: list.nextItemAutoIncId++,
				contentId: contentId,
				userId: -1,
				created: new Date(),
			}
		);

		this.SaveLocalList(list);
		this.onListsChanged.Call();
		return new Promise((resolve, reject) => { return resolve(list);});
	}

	RemoveFromListLocal(listId, contentId)
	{
		var list = this.GetListById(listId);
		if (!list)
			return new Promise((resolve, reject) => { return reject(undefined);});

		for (var i = 0; i < list.items.length; ++i)
		{
			if (list.items[i].contentId === contentId)
			{
				list.items.splice(i, 1);
				break;
			}
		}

		this.SaveLocalList(list);
		this.onListsChanged.Call();
		return new Promise((resolve, reject) => { return resolve(list);});
	}

	SaveLocalList(list)
	{
		Utils.SetLocalStorageList("locallist", list);
	}

	LoadLocalList(list)
	{
		var r = Utils.GetLocalStorage("locallist", {});
		if (r)
		{
			return JSON.parse(r);
		}
		else
		{
			return undefined;
		}
	}
}