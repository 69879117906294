import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import Tracking from '../Tracking';
import Utils from '../Utils';
import AppState from '../AppState';
import SwipeableViews from 'react-swipeable-views';
import CategoryTab from '../components/CategoryTab';
import $ from 'jquery';
import Button from 'material-ui/Button';
import {observable} from 'mobx';
import SearchPage from './SearchPage';
import LocationSearch from '../components/LocationSearch';
import WeatherDisplay from '../components/WeatherDisplay';
import Moment from 'moment';
import Translate from '../Translate';
import MapContent from '../components/MapContent';
import KeyboardArrowRight from 'material-ui-icons/KeyboardArrowRight';

const styles = theme => ({
	showAllButton: {
		textTransform: 'none',
		color: '#555',
		padding: '0',
		paddingLeft: '4px',
		minWidth: '0',
		lineHeight: '14px'
	}
});

@observer
class MainPage extends React.Component
{
	scrollPositions = {};
	@observable inSearchPage = false;
	@observable locationSearchTerm;
	@observable mapContentUpdateCount = 0;
	mapMarkers;
	@observable mapContent;
	map;
	@observable selectedContentId;

	static contextTypes =
	{
		router: PropTypes.object
	}

	constructor(props, context)
	{
		super(props, context);
		AppState.instance.mainPageInstance = this;
	}

	componentDidMount()
	{
		AppState.instance.onWeatherChanged.Add(this.OnWeatherChanged);
		AppState.instance.filterManager.AddOnFilterChanged(this.OnFiltersChanged);
	}

	componentWillUnmount()
	{
		AppState.instance.onWeatherChanged.Remove(this.OnWeatherChanged);
		AppState.instance.filterManager.RemoveOnFilterChanged(this.OnFiltersChanged);
	}

	OnWeatherChanged = () =>
	{
		this.forceUpdate();
	}

	OnAuthDone()
	{
	}

	OnActivate()
	{
		if (AppState.instance.deviceInfo.desktop)
		{
			AppState.instance.RefreshContentGrids();
		}
	}

	OnFiltersChanged = () =>
	{
		this.MatchTabWithLocation();
		this.forceUpdate();
	}

	ToggleFilter(filterId, createHistory)
	{
		const isActive = AppState.instance.filterManager.IsFilterActive(filterId);

		if (isActive)
		{
			this.PopFilter(filterId, createHistory);
		}
		else
		{
			this.PushAndScrollFilter(filterId, createHistory);
		}
	}

	MatchTabWithLocation = () =>
	{
		if (AppState.instance.appContainer.urlFilterSetup)
			return;

		//TODO: "locInfo.location" ist auch nur ein filter!

		var newFilterStack = [];

		var searchFound = false;

		var locInfo = Utils.ExtractLocationInfo(window.location.pathname);
		if (locInfo !== undefined && locInfo.root !== true)
		{
			if (locInfo.filters)
			{
				for (var i = 0; i < locInfo.filters.length; ++i)
				{
					var filterName = decodeURIComponent(locInfo.filters[i]);
					if (filterName.indexOf("search") === 0)
					{
						searchFound = true;
					}
					else
					{
						var filterId = AppState.instance.filterManager.GetFilterId(filterName);

						if (filterId)
						{
							newFilterStack.push(filterId);
						}
						else
						{
							//TODO: what to do with "In Mödling" => not all users might have that in their local cache?
							//      => things we don't understand go into the search-box and we send that string to the server to figure out the details
						}
					}
				}
			}
		}
		
		AppState.instance.filterStack = newFilterStack;

		if (!searchFound && this.inSearchPage)
		{
			this.inSearchPage = false;
		}
	}

	OnShowAllClicked = (catId) =>
	{
		this.PushAndScrollFilter(catId);
	}

	OnUrlShowAllClicked = () =>
	{
		if (AppState.instance.appContainer.urlFilterSetup)
		{
			const fs = AppState.instance.appContainer.urlFilterSetup.filterStack;
			for (var i = 0; i < fs.length; ++i)
			{
				this.PushFilter(fs[i]);
			}
		}		
	}

	OnCategoryClicked = (catId) =>
	{
		// We need to clear the filter stack and just show this "category"
		AppState.instance.filterManager.ClearAllFilters();
		this.PushAndScrollFilter(catId);
	}

	PushAndScrollFilter(filterId, createHistory)
	{
		// Save scroll position
		var currentCatId = AppState.instance.filterManager.CreateFilterId(AppState.instance.filterStack);
		this.scrollPositions[currentCatId] = $(window).scrollTop();

		// const currentCat = AppState.instance.tabCategories[AppState.instance.selectedCategoryIndex];
		// var currentCatId;
		// if (currentCat)
		// {
		// 	currentCatId = currentCat.id;
		// 	this.scrollPositions[currentCatId] = $(window).scrollTop();
		// 	//console.log("SCROLLPOS: " + $(window).scrollTop());
		// }

		//const newCatId = catId;// || AppState.instance.tabCategories[tabIndex].id;
		//var scrollPos = 0;
		//if (this.scrollPositions[newCatId] !== undefined)
		//	scrollPos = this.scrollPositions[newCatId];
		//const wasAlreadyShown = this.GetCategoryTabIndex(newCatId) !== undefined;

		this.PushFilter(filterId, createHistory);

		if (!AppState.instance.isLandingPage &&
			!AppState.instance.isProviderPage)
		{
			// if (wasAlreadyShown)
			// {
			// 	$(window).scrollTop(scrollPos);
			// }
			// else
			// {
				Utils.ScrollToTop();
			//}
		}
	}

	PushFilter(filterId, createHistory)
	{
		const currentTopId = AppState.instance.filterManager.GetTopCatId();
		if (currentTopId === filterId)
			return;

		console.log("push filter: " + filterId);
		AppState.instance.filterStack.push(filterId);

		var url = AppState.instance.filterManager.CreateFilterUrl(AppState.instance.filterStack);
		Tracking.OnPageView(url);

		if (createHistory === true || createHistory === undefined)
			this.context.router.history.push(url);

		AppState.instance.filterManager.onFiltersChanged.Call();
	}

	PopFilter(filterId, createHistory)
	{
		for (var i = 0; i < AppState.instance.filterStack.length; ++i)
		{
			if (AppState.instance.filterStack[i] === filterId)
			{
				console.log("pop filter: " + filterId);
				AppState.instance.filterStack.splice(i, 1);
				break;
			}
		}

		var url = AppState.instance.filterManager.CreateFilterUrl(AppState.instance.filterStack);
		Tracking.OnPageView(url);

		if (createHistory === true || createHistory === undefined)
			this.context.router.history.push(url);
	}

	AdjustIconOutline(dataUrl, strokeColor)
	{
		//TODO:

		strokeColor = strokeColor.replace("#", "%23");

		dataUrl = Utils.ReplaceAll(dataUrl, " stroke='transparent'", " stroke='" + strokeColor + "'");
		dataUrl = Utils.ReplaceAll(dataUrl, " stroke-width='2'", " stroke-width='0.5'");

		dataUrl = Utils.ReplaceAll(dataUrl, "strokeWidth", "stroke-width");
		return Utils.ReplaceAll(dataUrl, " fill='%23ccc'", " fill='white' stroke='" + strokeColor + "' stroke-width='0.5'");
	}

	OnShortcutClicked = (filterId) => () =>
	{
		this.PushAndScrollFilter(filterId);
	}

	renderWeatherBar()
	{
		var loc = AppState.instance.GetBestLocation();

		if (!loc)
			return null;

		if (loc.title && !loc.address)
			loc.address = loc.title;

		const now = new Date();

		const date = AppState.instance.userProfile.filterStartDate || now;
		const dateStr = Utils.FormatDateMediumLength(date);

		var lines = [];
		var times = [];
		var nowIndex = -1;

		var weatherForecast;
		var weather;

		if (loc.address)
		{
			var item = AppState.instance.weatherData[loc.address];
			if (item)
			{
				weatherForecast = item.weatherForecast;
				weather = item.weather;
			}
		}

		if (weatherForecast)
		{
			const itemsPerDay = 4;
			const daysAvail = weatherForecast.length / itemsPerDay;
			const maxDate = Utils.DateToDayStart(Moment(now).add(daysAvail, 'days').toDate());
			const tripStartDay = Utils.DateToDayStart(date);
			
			if (tripStartDay <= maxDate &&
				tripStartDay >= Utils.DateToDayStart(now))
			{
				// Find first weather line for selected trip start date
				var daysDiff = Utils.DateDiffInDays(Utils.DateToDayStart(now), tripStartDay);
				var lineStart = Math.floor(daysDiff) * 4;

				for (var i = lineStart; i < lineStart + 4; ++i)
				{
					lines.push(weatherForecast[i]);

					if (i % 4 === 0)
						times.push("9:00");
					else if (i % 4 === 1)
						times.push("12:00");
					else if (i % 4 === 2)
						times.push("15:00");
					else
						times.push("18:00");
				}

				// Merge current weather in it if applicable
				if (Math.abs(daysDiff) < 1 && weather)
				{
					const currentMinutes = now.getHours() * 60 + now.getMinutes();

					var targetIdx;
					if (currentMinutes < 10 * 60 + 30)
						targetIdx = 0;
					else if (currentMinutes > 16 * 60 + 30)
						targetIdx = 3;
					else if (currentMinutes < 13 * 60 + 30)
						targetIdx = 1;
					else
						targetIdx = 2;
					
					nowIndex = targetIdx;
					times[targetIdx] = Moment(now).format("H:mm");
					lines[targetIdx] = weather;
				}
			}
		}

		const isLarge = window.innerWidth > 350;

		return (
			<div className={"mainWeatherBar" + (isLarge ? " large":"")}>
				<div className="location">{loc.address}</div>
				{/* <div className={"date" + (lines.length === 0 ? " noweather":"")}>{dateStr}</div> */}

				{/* {lines.length > 0 && <div className="detailWeather">

					<div className="detailWeatherHeader">
						{times.map((time, index) =>
						{
							return <div className={"detailWeatherHeaderItem" + (nowIndex === index ? " now":"")} key={index}>{time}</div>
						})}
					</div>

					<div className="detailWeatherLine">
						{lines.map((line, index) =>
						{
							return WeatherDisplay.RenderWeatherIcon(line, "detailWeatherIcon" + (nowIndex === index ? " now":""), undefined, index);
						})}
					</div>
				</div>}

				{lines.length === 0 && <div className="noWeather">
					{Translate.T("de.Keine Wetterdaten verfügbar", "en.Weather data unavailable", "noweatherdata")}
				</div>} */}
			</div>
		);
	}

	renderShortcuts()
	{
		var result = [];

		var filters = AppState.instance.filterManager.GetFilters();

		for (var i = 0; i < filters.length; ++i)
		{
			const f = filters[i];
			if (f.showInShortcuts)
			{
				const iconSize = 48;
				const catColor = "#ccc";
				//var strokeColor = Utils.GetCategoryColor(f.title);

				let catIcon = f.titleImage;
				if (!catIcon)
				{
					catIcon = Utils.GetCategoryIconUrl(f.title, "white", catColor, iconSize, iconSize, false);
					if (!catIcon)
						catIcon = Utils.RenderStarUrl(iconSize, iconSize, "white", catColor);
				}

				result.push(
					<Button className="filterShortcut" key={f.id} onClick={this.OnShortcutClicked(f.id)}>
						{catIcon !== undefined &&
							<div
								className="filterShortcutImage"
								style={{backgroundImage: 'url("' + catIcon + '")'}}
							/>}

						<div className="filterShortcutTitle">
							{f.title}
						</div>
					</Button>
				);
			}
		}

		return (
			<div>
				{AppState.instance.deviceInfo.desktop && AppState.instance.IsLoggedIn() &&
				<div className="filterShortcutsTitle">
					{Translate.T("de.Was möchtest Du unternehmen, {0}?", "en.What are you looking for, {0}?", "filtershortcuts.title",
						[AppState.instance.userProfile.loginData.firstName])}
				</div>}
				<div className="filterShortcuts">
					{result}
				</div>
			</div>
		);
	}

	OnSearch(searchTerm)
	{
		if (AppState.instance.isInLocationSearch)
		{
			this.locationSearchTerm = searchTerm;
		}
		else
		{
			this.forceUpdate();
		}
	}

	OnSearchFocus()
	{
		this.inSearchPage = true;
	}

	OnSearchBlur()
	{
	}

	OnLeaveSearch()
	{
		this.inSearchPage = false;
	}

	OnMapContentLoaded(content, markers)
	{
		//this.mapContentUpdateCount++;
		this.mapMarkers = markers;
		this.mapContent = content;

		//console.log(content.length);
	}

	OnMapMarkerClicked(marker)
	{
		this.selectedContentId = marker.activity ? marker.activity.id : undefined;
	}

	renderTopFilterName()
	{
		var str;

		const topFilterId = AppState.instance.filterManager.GetTopCatId();
		if (topFilterId)
		{
			const filter = AppState.instance.filterManager.GetFilter(topFilterId);
			if (filter)
				str = filter.title;
		}

		if (!str)
			return null;

		return (
			<div className="filterShortcutsTitle">
				{str}
			</div>
		);
	}

	OnDefaultFilterClicked = (filterId, contentData, e) =>
	{
		if (AppState.instance.appInstance)
			AppState.instance.appInstance.OnLeaveSearch();

		AppState.instance.appContainer.GoTo(contentData.url, true);
		return false; // don't continue event processing
	}

	render()
	{
		if (!AppState.instance.GeneralDataLoadingEnabled())
			return null;
			
		const { /*classes,*/ theme } = this.props;

		var s = {};
		if (AppState.instance.currentMainTab !== AppState.TabIndexExplore)
			s = {'display': 'none'};

		const filterIdSum = AppState.instance.filterManager.CreateFilterId(AppState.instance.filterStack);

		var urlFilterIdSum;
		if (AppState.instance.appContainer.urlFilterSetup)
		{
			urlFilterIdSum = AppState.instance.filterManager.CreateFilterId(AppState.instance.appContainer.urlFilterSetup.filterStack);
		}

		const isRoot = filterIdSum.length === 0;
		const isDesktop = AppState.instance.deviceInfo.desktop;

		return (
			<div id="mainPage" className="mainPage" style={s}>

				{urlFilterIdSum && isRoot &&
					<div className="tabContainer tabContainerHorizontal withoutMap urlFilterTabContainer">
						<div className="resultCategory">
							<div className="resultCategoryName">
								{AppState.instance.appContainer.urlFilterSetup.title || Translate.T("de.Suchergebnisse", "en.Search Results", "lp.manualfilter.defaulttitle")}
							</div>

							{!isDesktop && <div className="resultCategoryMore">
								<Button className={this.props.classes.showAllButton} onClick={this.OnUrlShowAllClicked}>
									{Translate.T("en.See all", "de.Mehr", "results.showall")}
									<KeyboardArrowRight
										style={{
											width: 20,
											height: 20,
											position: 'relative',
											top: '-1px'
										}}/>
								</Button>
							</div>}

							<CategoryTab
								isRoot={false} categoryIds={urlFilterIdSum}
								key={urlFilterIdSum}
								itemType={"content"}
								preload={false}
								active={true}
								horizontalContentList={true}
								allowIScroll={true}
								autoloadMore={false}
							/>

							{isDesktop && <div className="resultCategoryMoreDesktop">
								<Button className={this.props.classes.showAllButton} onClick={this.OnUrlShowAllClicked}>
									{Translate.T("en.Show all", "de.Alle anzeigen", "results.showall.desktop")}
									<KeyboardArrowRight
										style={{
											width: 20,
											height: 20,
											position: 'relative',
											top: '-1px'
										}}/>
								</Button>
							</div>}
						</div>
					</div>}

				<SwipeableViews
					axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
					index={AppState.instance.isInLocationSearch ? 2 : (this.inSearchPage ? 1 : 0)}
					disabled={true}
					animateTransitions={false}
					className={"swipeView"}
				>
					<div key={filterIdSum}
						id={"tab_" + filterIdSum}
						className={"tabContainer" +
							(isRoot ? " tabContainerHorizontal":"") +
							(this.props.showMap ? " withMap":" withoutMap")}
						dir={theme.direction}
					>
						<div className="mainPageLeft">
							{isRoot && !isDesktop &&
							this.renderWeatherBar()}

							{isRoot &&
							this.renderShortcuts()}

							{!isRoot && AppState.instance.deviceInfo.desktop &&
							this.renderTopFilterName()}

							{!this.props.showMap &&
							<CategoryTab isRoot={isRoot} categoryIds={isRoot ? "" : filterIdSum}
								key={filterIdSum}
								itemType={isRoot ? "mixed" : "N/A"} preload={isRoot}
								active={AppState.instance.currentMainTab === AppState.TabIndexExplore} horizontalContentList={false}
								allowIScroll={true}
								onShowAllClicked={this.OnShowAllClicked}
							/>}

							{this.props.showMap &&
							<MapContent
								map={this.props.showMap}
								key={filterIdSum}
								mapContentUpdateCount={this.mapContentUpdateCount}
								content={this.mapContent}
								markers={this.markers}
								selectedContentId={this.selectedContentId}
							/>}
						</div>
					</div>

					<SearchPage
						visible={this.inSearchPage}
						onDefaultFilterClicked={this.OnDefaultFilterClicked}
					/>
					<LocationSearch visible={AppState.instance.isInLocationSearch} q={this.locationSearchTerm} />
				</SwipeableViews>

			</div>
		);
	}
}

MainPage.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(MainPage);