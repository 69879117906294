import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import Utils from '../Utils';
import Tracking from '../Tracking';
import Rating from './Rating';
import MediaPlayer from './MediaPlayer';
import Translate from '../Translate';
import Button from 'material-ui/Button';
import AppState from '../AppState';
import Map from './Map';
import OpenInNew from 'material-ui-icons/OpenInNew';

const styles = {
};

@observer
class TimelineItemContent extends React.Component
{
	@observable isCollapsed = true;

	OnReviewExpandClicked = () =>
	{
		const review = this.props.content.review;
		const uc = this.props.content.userContent && this.props.content.userContent[0];

		if ((review && review.contentPartnerLink) ||
			(uc && uc.contentPartnerLink))
		{
			if (review)
			{
				Utils.OpenExternalLink(review.contentPartnerLink);
				Tracking.SendEvent("backlink", {
					url: review.contentPartnerLink,
					portal: review.contentPartnerPortal
				});
			}
			else
			{
				Utils.OpenExternalLink(uc.contentPartnerLink);
				Tracking.SendEvent("backlink", {
					url: uc.contentPartnerLink,
					portal: uc.contentPartnerPortal
				});
			}
		}
		else
		{
			this.isCollapsed = false;
			if (this.props.onExpanded)
				this.props.onExpanded();
			if (this.props.onLayoutChanged)
				this.props.onLayoutChanged();
		}
	}

	OnActivityClicked = (title) => () =>
	{
		const url = "/activity/" + Utils.GetUrlName(title);
		AppState.instance.appContainer.context.router.history.push(url);
	}

	getContentImages(content)
	{
		if (content.images && content.images.length > 0)
			return content.images.slice();
		
		if (content.img)
			return [content.img];

		if (content.userContent && content.userContent.length === 1 && content.userContent[0].url)
			return [content.userContent[0].url];

		return undefined;
	}

	renderImages(content)
	{
		if (this.props.context === "activity" && this.props.content.type === "activity")
			return null;
			
		var mediaData;
		var images = this.getContentImages(content);
		if (images)
		{
			mediaData = {};
			mediaData.activity = {};
			mediaData.activity.pictureUrls = images;

			if (this.props.context === "activity")
			{
				// Don't show default/official images
				for (var i = mediaData.activity.pictureUrls.length - 1; i >= 0; --i)
				{
					const url = mediaData.activity.pictureUrls[i];
					if (!Utils.IsUserImage(url))
					{
						mediaData.activity.pictureUrls.splice(i, 1);
					}
				}

				if (mediaData.activity.pictureUrls.length === 0)
					return null;
			}

			var imageHeight = Math.round(window.innerWidth / 1.5);
			
			// display original aspect ratio down to 1.33
			// use original ratio if from LENA
			if (images.length > 0)
			{
				const imgData = AppState.instance.imageManager.Get(images[0]);
				if (imgData)
				{
					const minRatio = AppState.instance.deviceInfo.desktop ? 1.0 : 1.33;

					var ratio = imgData.width / imgData.height;
					if (content.username !== "lena")
						if (ratio < minRatio)
							ratio = minRatio;

					if (AppState.instance.deviceInfo.desktop)
						imageHeight = Math.round(600 / ratio);
					else
						imageHeight = Math.round(window.innerWidth / ratio);
				}
			}

			return (
				<MediaPlayer
					data={mediaData}
					className={"contentImage contentImageColumn"}
					containerClassname="timelineItemContent"
					interactive={true}
					//disableZoom={true}
					disableAuthorLink={true}
					defaultContentHeight={imageHeight}
					onSwitching={this.props.onMediaSwitching}
					onTouchEnd={this.props.onMediaTouchEnd}
					onTouchStart={this.props.onMediaTouchStart}
					onBeforeZoom={this.props.onBeforeMediaZoom}
					onAfterZoom={this.props.onAfterMediaZoom}
				/>
			);
		}
		else
		{
			return null;
		}
	}

	renderContentTitle(content, onClick)
	{
		if (this.props.context === "activity")
			return null;

		if (!content.title)
			return null;

		const openActivity = content.username !== "lena";

		return (
			<div className="reviewTitle" onClick={onClick || (openActivity ? this.OnActivityClicked(content.title) : undefined)}>
				{content.title} <OpenInNew className="reviewTitleLink" style={{width: 14, height: 14}} />
			</div>
		);
	}

	renderContentText(text, isHighlight, descMaxChars, authorColor, contentPartnerPortal)
	{
		if (this.props.context === "activity" && this.props.content.type === "checkin")
			return null;

		if (this.props.context === "activity" && this.props.content.type === "activity")
			return null;

		var collapsedText = true;
		if (this.props.content.username === "lena")
			collapsedText = false;
		//if (review.contentPartnerPortal)
		//	collapsedText = true;

		var reviewText = text;
		if (!reviewText)
			reviewText = "";

		const ending = "...";
		const originalLength = reviewText.length;

		if (collapsedText && this.isCollapsed)
		{
			var maxChars = isHighlight ? 300 : 200;
			if (descMaxChars !== undefined)
				maxChars = descMaxChars;

			reviewText = Utils.TrimTextToMaxChars(reviewText, maxChars, ending);
		}

		//var backgroundColorStyle = {};
		var colorStyle = {};
		if (authorColor)
		{
			//backgroundColorStyle = {backgroundColor: authorColor};
			colorStyle = {color: authorColor};
		}

		var readMore = null;
		if (originalLength !== reviewText.length)
		{
			reviewText = reviewText.substring(0, reviewText.length - ending.length + 3);

			if (contentPartnerPortal)
			{
				var borderColorStyle = {};
				if (authorColor)
				{
					borderColorStyle = {border: "1px solid " + authorColor};
				}
				else
				{
					borderColorStyle = {border: "1px solid #e31c46"};
				}

				readMore = <Button className="reviewTextMoreCP" onClick={this.OnReviewExpandClicked} style={borderColorStyle}>
					<span style={colorStyle}>
						{Translate.T("en.Continue on {0}", "de.Weiterlesen auf {0}", "review.readmore.contentpartner", [contentPartnerPortal])}
					</span>
				</Button>;
			}
			else
			{
				readMore = <span className="reviewTextMore">{Translate.T("en.read more", "de.mehr lesen", "review.readmore")}</span>;
			}
		}

		const html = Utils.TextToHtml(reviewText, "desc");

		return (
			<div className="reviewDesc" onClick={contentPartnerPortal === undefined ? this.OnReviewExpandClicked : undefined}>
				{html}
				{readMore}
			</div>
		);
	}

	renderRating(content)
	{
		if (!content.rating)
			return null;

		return (
			<div className="reviewRating">
				<Rating	value={content.rating} starColor="#e31c46" />
			</div>
		);
	}

	renderDate(content)
	{
		if (!content.userContent || content.userContent.length !== 1)
			return null;

		const uc = content.userContent[0];
		if (!uc)
			return null;

		if (!uc.recordingStart)
			return null;

		const d = new Date(uc.date);
		const recStart = new Date(uc.recordingStart);
		const sameDay = Utils.DateSameDay(d, recStart);
		if (sameDay)
			return null;

		return (
			<div className={"contentDate" + (content.rating > 0 ? " withRating":" noRating")}>
				{Utils.FormatDateLong(recStart)}
			</div>
		);
	}

	/********************************************************************************* */

	renderReview(content)
	{
		const review = content.review;

		return (
			<div className="review">
				{this.renderContentTitle(content)}

				{this.renderRating(content)}

				{this.renderContentText(review.data.text,
					review.data.highlight, review.descMaxChars, review.authorColor, review.contentPartnerPortal)}

				{this.renderImages(content)}
			</div>
		);
	}

	renderExperience(content)
	{
		const uc = content.userContent[0];

		return (
			<div className="review">
				{this.renderContentTitle(content)}

				<div className="ratingDateContainer">
					{this.renderRating(content)}
					{this.renderDate(content)}
				</div>

				{this.renderContentText(content.desc,
					uc.highlight, undefined, uc.authorColor, uc.contentPartnerPortal)}

				{this.renderImages(content)}
			</div>
		);
	}

	OnAdClicked = () =>
	{
		const c = this.props.content;

		Tracking.SendEvent("adclicked", {
			url: c.url,
			adid: c.id,
			title: c.title
		});

		setTimeout(() => {
			const url = c.url;
			if (url.startsWith("http") || url.startsWith("//"))
			{
				Utils.OpenExternalLink(url);
			}
			else if (url === "/abenteuer" || url === "/adventures")
			{
				if (AppState.instance.desktopAppInstance)
				{
					AppState.instance.desktopAppInstance.ChangePage(AppState.TabIndexAdventure);
				}
				else if (AppState.instance.appInstance)
				{
					AppState.instance.appInstance.OnTabbarChanged(undefined, AppState.TabIndexAdventure);
				}
			}
			else
			{
				AppState.instance.appContainer.GoTo(url, true);
			}
		}, 20);
	}

	renderAd(content)
	{
		return (
			<div className={"ad adId" + content.id}>

				{this.props.context === "swipe" &&
					this.renderImages(content)}

				{this.renderContentTitle(content, this.OnAdClicked)}

				{this.renderContentText(content.desc)}

				{this.props.context !== "swipe" &&
					this.renderImages(content)}

				{content.url && <div className="adButton">
					<Button onClick={this.OnAdClicked}>
						{Translate.T("de.Mehr erfahren", "en.Learn More", "timelineitem.ad.button.learnmore")}
					</Button>
				</div>}
			</div>
		);
	}

	renderActivity(content)
	{
		return (
			<div className="review">
				{this.renderContentTitle(content)}

				{this.renderContentText(content.desc)}

				{this.renderImages(content)}
			</div>
		);
	}

	OnActivityMarkerClicked = (event) =>
	{
		event.preventDefault();
		event.stopPropagation();

		const url = "/activity/" + Utils.GetUrlName(this.props.content.title);
		AppState.instance.appContainer.context.router.history.push(url);
	}

	renderCheckIn(content)
	{
		var subline;

		const city = Utils.ExtractCity(content.address);
		if (city)
			subline = city;

		if (content.distance)
		{
			if (subline)
				subline += "\u00A0• ";
			else
				subline = "";
			subline += content.distance.distanceText;
		}

		var highlightMarkers = [];
		if (content.latitude !== 0)
		{
			var marker = Map.CreateHighlightMarker(
				content.latitude,
				content.longitude,
				//this.props.data.activity,
				//this.props.data.categories
			);
			marker.onClick = this.OnActivityMarkerClicked;
			highlightMarkers.push(marker);
		}

		return (
			<div className="review">
				{this.renderContentTitle(content)}

				<div className="checkInSubline">
					{subline}
				</div>

				<Map id="checkInMap" gestureHandling="cooperative"
					//forceInclude={[this.props.data.activity.id]}
					//hideContentCard={[this.props.data.activity.id]}
					zoomFactor={13}
					centerLat={content.latitude}
					centerLng={content.longitude}
					showLocation={false}
					//maxNumCategories={1}
					mapSimpleMode={true}
					disableFilterButton={true}
					highlightMarkers={highlightMarkers}
					loadOnVisibility={false}
					showTitle={false}
					ignoreActiveFilter={true}
					//onInit={(instance) => this.map = instance}
				/>

			</div>
		);
	}

	renderImageContent(content)
	{
		const uc = content.userContent[0];

		return (
			<div className="review">
				{this.renderContentTitle(content)}

				{this.renderRating(content)}

				{this.renderContentText(content.desc,
					uc.highlight, undefined, uc.authorColor, uc.contentPartnerPortal)}

				{this.renderImages(content)}
			</div>
		);
	}

	renderQuestion(content)
	{
		const uc = content.userContent[0];

		return (
			<div className="review">
				{this.renderContentTitle(content)}

				{this.renderRating(content)}

				{this.renderContentText(content.desc,
					uc.highlight, undefined, uc.authorColor, uc.contentPartnerPortal)}

				{this.renderImages(content)}
			</div>
		);
	}

	render()
	{
		if (!this.props.content)
			return null;

		const content = this.props.content;

		return (
			<div className={"timelineItemContent " + content.type}>
				{content.type === "review" && this.renderReview(content)}
				{content.type === "experience" && this.renderExperience(content)}
				{content.type === "ad" && this.renderAd(content)}
				{content.type === "activity" && this.renderActivity(content)}
				{content.type === "checkin" && this.renderCheckIn(content)}
				{(content.type === "image" || content.type === "video") && this.renderImageContent(content)}
				{(content.type === "comment" || content.type === "question") && this.renderQuestion(content)}
			</div>
		);
	}
}

TimelineItemContent.propTypes = {
	classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(TimelineItemContent);