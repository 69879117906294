/* eslint-disable no-unused-vars */
import React from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import {withStyles} from 'material-ui/styles';
import Utils from '../Utils';
import $ from 'jquery';
import Translate from '../Translate';
import Tracking from '../Tracking';
import AppState from '../AppState';
import LiveData from '../models/LiveData';
import SwipeableViews from 'react-swipeable-views';
import ProfileComponent from '../components/ProfileComponent';
//import ParticleSystem from '../components/ParticleSystem';
import raf from 'raf';
import VideoCam from 'material-ui-icons/Videocam';
import PlayCircleOutline from 'material-ui-icons/PlayCircleOutline';
import PauseCircleOutline from 'material-ui-icons/PauseCircleOutline';
import Replay from 'material-ui-icons/Replay';
import KeyboardArrowLeft from 'material-ui-icons/KeyboardArrowLeft';
import KeyboardArrowRight from 'material-ui-icons/KeyboardArrowRight';
import Dots from './Dots';
import FullscreenMedia from './FullscreenMedia';
import ImageGallery from 'react-image-gallery';


const styles = {
	closeButtonRoot: {
		width: '38px',
		height: '38px',
		top: '10px',
		left: '10px',
		position: 'absolute',
		zIndex: 2,
		backgroundColor: 'rgba(255,255,255,0.6)',
		borderRadius: 19,
		color: 'rgba(0,0,0,0.8)',
	},
};

@observer
class MediaPlayer extends React.Component
{
	id;
	@observable photoIndex = 0;
	@observable isZoomed = false;
	defaultContentHeight = Utils.GetMaxContentHeight();
	@observable profileOverlayUsername;
	@observable animateHeart = 0;
	animateHeartTimer;
	//particles;
	//particlesUpdateInterval;
	authorRingCurrent = 0;
	authorRingTarget = 0;
	authorRingLastUpdate = new Date();
	container;
	imageWasDragged = false;

	isLoaded = false;
	wasLoaded = false;
	imageDataLoaded = false;
	unloadTimer;
	_isMounted = false;
	isVisible;
	imageGallery;
	videoNodes = {};
	playingVideo;

	@observable fadeOutThumbnail = false;

	constructor(props)
	{
		super(props);

		//this.particles = new ParticleSystem({className: "heartParticles"});
		//this.UpdateParticleEmitter();
		//this.particles.SetForceField({x: 300, y: 0}, -0.2);
		//this.particles.SetParticleRenderer(this.RenderParticle);

		if (this.props.defaultContentHeight !== undefined)
			this.defaultContentHeight = this.props.defaultContentHeight;

		this.id = Utils.GenerateRandomId(32);

		// const url = this.GetFirstPictureUrl();
		// if (url)
		// {
		// 	this.isLoaded = Utils.WasImageLoaded(url);
		// 	console.log("was loaded before: " + url);
		// }

		//console.log("MP::ctor");
	}

	componentWillReceiveProps(nextProps)
	{
		if (nextProps.defaultContentHeight !== undefined)
			this.defaultContentHeight = nextProps.defaultContentHeight;
	}

	componentDidMount()
	{
		this._isMounted = true;

		//this.particles.Start();
		this.UpdateAuthorRing();

		$(window).on('orientationchange', this.OnOrientationChange);

		if (this.props.onInstance)
			this.props.onInstance(this);

		raf(() =>
		{
			this.CalcVisibility();
		});
		AppState.instance.AddOnScreenScrolled(this.CalcVisibility);

		//console.log("MP::mount");
	}

	componentWillUnmount()
	{
		AppState.instance.RemoveOnScreenScrolled(this.CalcVisibility);

		this._isMounted = false;

		$(window).off('orientationchange', this.OnOrientationChange);

		//if (this.particlesUpdateInterval)
		//	clearInterval(this.particlesUpdateInterval);
		//this.particles.Stop();

		//console.log("MP::unmount");
	}

	// UpdateParticleEmitter()
	// {
	// 	const imageContainer = $("#detailContentOverlay" + this.photoIndex);
	// 	if (imageContainer[0])
	// 	{
	// 		var h = imageContainer.find(".contentHeartTransfer svg");
	// 		if (h[0])
	// 		{				
	// 			var pos = h.offset();
	// 			this.particles.SetEmitData({x: pos.left - 16, y: pos.top - 8}, {x: 0, y: -120}, {x: 0, y: 0}, 0, 0, 0, 10000);
	// 		}
	// 	}
	// }

	// OnCarouselChange = (index) =>
	// {
	// 	this.photoIndex = index;
	// 	this.particles.Clear();
	// 	this.OnContentEvent("contentVisible");
	// }

	OnContentEvent(eventName)
	{
		var contentId;
		if (this.props.data.contentIds)
		{
			contentId = this.props.data.contentIds[this.photoIndex];
		}

		if (this.props.data.activity.id !== undefined)
		{
			Tracking.SendEvent(eventName,
			{
				activityId: this.props.data.activity.id,
				photoIndex: this.photoIndex,
				contentId: contentId,
				latitude: this.props.data.activity.latitude,
				longitude: this.props.data.activity.longitude
			});
		}
	}

	OnAuthorClicked = (contentData, asOverlay) => (e) =>
	{
		e.stopPropagation();
		if (contentData.author_Url && contentData.author_Url.length > 0 &&
			this.props.disableAuthorLink !== true)
		{
			// open author profile page or external link
			if (contentData.author_Url.indexOf("http") >= 0)
			{
				Utils.OpenExternalLink(contentData.author_Url);
			}
			else
			{
				if (asOverlay)
				{
					var parts = contentData.author_Url.split('/');
					this.profileOverlayUsername = parts[parts.length - 1];
				}
				else
				{
					this.context.router.history.push(contentData.author_Url);
					AppState.instance.appContainer.forceUpdate();
				}
			}
		}
	}

	OnProfileOverlayClicked = (e) =>
	{
		console.log(e);
		e.stopPropagation();
		this.profileOverlayUsername = undefined;
	}

	UpdateAuthorRing = () =>
	{
		var showAuthorZoomed = false;
		//if (this.isZoomed /*&& contentData.copyright*/)
		//	showAuthorZoomed = true;
		if (this.profileOverlayUsername)
			showAuthorZoomed = false;

		if (!showAuthorZoomed)
			return;

		var now = new Date();
		var deltaTime = (now - this.authorRingLastUpdate) / 1000.0;
		if (deltaTime > 0.033)
			deltaTime = 0.033;

		var deltaRing = this.authorRingTarget - this.authorRingCurrent;
		if (deltaRing !== 0 || this.authorRingCurrent > 0)
		{
			if (deltaRing > 0)
				this.authorRingCurrent += deltaTime * 10;
			else
				this.authorRingCurrent -= deltaTime * 10;
			if (this.authorRingCurrent >= 1.0)
			{
				this.authorRingCurrent = 1.0;
				this.authorRingTarget = 0;
			}
			else if (this.authorRingCurrent < 0.0)
				this.authorRingCurrent = 0.0;

			$(".contentAuthorPhotoImg").css('borderColor', 'rgba(227, 28, 70, ' + this.authorRingCurrent + ')')
		}

		this.authorRingLastUpdate = now;

		raf(() => {
			this.UpdateAuthorRing();
		});
	}

	// RenderParticle = (p, index, update, deltaTime) =>
	// {
	// 	if (update)
	// 	{
	// 		const container = $(this.container).find(".heartParticles");
	// 		const containerHeight = container.length ? container.height() : 600;
	// 		const containerWidth = container.length ? container.width() : 400;

	// 		const imageContainer = $(this.container).find("#detailContentOverlay" + this.photoIndex);
	// 		const target = imageContainer.find(".contentAuthorPhoto");
	// 		const targetX = target.length ? (target.offset().left + 12) : 300;
	// 		const targetY = target.length ? (target.offset().top + target.height() - 4) : 0;

	// 		var $p = $("#" + p.id);
	// 		if ($p[0])
	// 		{
	// 			if (p.dead)
	// 				$p.css('display', 'none');

	// 			if (p.random === undefined)
	// 			{
	// 				p.random = Math.random();
	// 				p.vx = -50 + 100 * p.random;
	// 			}

	// 			const f = 1.5;
	// 			const s = 100;
	// 			const r2 = 0.5 + p.random * 0.5;

	// 			var sign1 = 1;
	// 			var sign2 = -1;
	// 			if (p.random > 0.5)
	// 			{
	// 				sign1 = -1;
	// 				sign2 = -1;
	// 			}

	// 			var r = sign1 * Math.sin(p.random * 3.2 + r2 * f * p.t) * 0.333 +
	// 					sign2 * Math.cos(r2 + f * 1.33 * p.t) * 0.333;
	// 			if (p.random > 0.5)
	// 				r += Math.sin(p.random + 1.5 + f * 3 * p.t) * (0.333 + r2);

	// 			// Steer towards target
	// 			const tx = targetX;
	// 			//const dx = p.x - tx;
	// 			//const dy = p.y - targetY;
	// 			//const dist = Math.sqrt(dx * dx + dy * dy) / containerHeight;
	// 			//var force = 3.0 - Math.log(dist);
	// 			//if (force < 0) force = 0;
	// 			//force *= 100;
	// 			//const nx = dx / dist;
	// 			//const ny = dy / dist;
	// 			//var driftX = - nx * force * deltaTime * 100;
	// 			//var driftY = - ny * force * deltaTime;

	// 			var driftFactor = p.y / (containerHeight * 0.4);
	// 			if (driftFactor > 1) driftFactor = 1;
	// 			//driftFactor *= driftFactor;

	// 			p.ax = s * r * deltaTime * 150 * driftFactor;// + driftX * (1.0 - driftFactor);
	// 			p.vy -= (1 - driftFactor) * 100 * deltaTime;
	// 			const maxV = 100;
	// 			if (p.vx < -maxV) p.vx = -maxV;
	// 			if (p.vx > +maxV) p.vx = +maxV;

	// 			var deltaXFactor = (tx - p.x) * 20 * (1.0 - driftFactor);
	// 			if (deltaXFactor > 500)
	// 				deltaXFactor = 500;
	// 			p.x += deltaTime * deltaXFactor;

	// 			if (p.x > (containerWidth - 20) && p.vx > 0)
	// 			{
	// 				p.x -= 10 * deltaTime;
	// 				p.vx = -p.vx * (0.6 + Math.random() * 0.4);
	// 				//p.vy *= 0.5;
	// 			}

	// 			//const alpha = 1.0 - p.t / p.maxLifeTime;

	// 			var alpha = 1;
	// 			if (p.y < targetY)
	// 			{
	// 				//p.y = targetY - 1;
	// 				p.vx = 0;
	// 				p.vy = -60;
	// 				//p.vy = 0;
	// 				p.ax = 0;
	// 				p.ay = 0;
	// 				if (p.alphaStart === undefined)
	// 				{
	// 					p.alphaStart = p.t;
	// 					//TODO: trigger animation of target

	// 					this.authorRingTarget = 1.0;
	// 				}

	// 				const timePastTarget = p.t - p.alphaStart;
	// 				const alphaLength = 0.250;
	// 				alpha = 1.0 - timePastTarget / alphaLength;
	// 				if (alpha < 0)
	// 				{
	// 					alpha = 0;
	// 					p.dead = true;
	// 				}
	// 			}

	// 			//if (p.y < -40)
	// 			//	p.dead = true;

	// 			const normalSize = 24;
	// 			var size = 24 * alpha;

	// 			if (p.t < 0.15)
	// 			{
	// 				var sizeScale = 1.0 + p.t / 0.15 * 0.5;
	// 				size *= sizeScale;
	// 			}
	// 			else if (p.t < 0.3)
	// 			{
	// 				sizeScale = 1.5 - (p.t - 0.15) / 0.15 * 0.5;
	// 				size *= sizeScale;
	// 			}

	// 			const offset = (normalSize - size) * 0.5;

	// 			$p.css('left', p.x + offset);
	// 			$p.css('top', p.y + offset);
	// 			$p.css('width', size);
	// 			$p.css('height', size);

	// 			//if (alpha !== 1)
	// 			$p.css('fill', 'rgba(227, 28, 70, ' + alpha + ')');
	// 		}
	// 	}
	// 	else
	// 	{
	// 		return (
	// 			<Favorite className="heartP" style={{top: p.y, left: p.x}} key={p.id} id={p.id}/>
	// 		);
	// 	}
	// }

	// OnHeartTransferClicked = (contentData) => () =>
	// {
	// 	if (!AppState.instance.IsLoggedIn())
	// 	{
	// 		//TODO: display popup
	// 		return;
	// 	}

	// 	this.UpdateParticleEmitter();

	// 	var hearts = AppState.instance.userProfile.loginData.hearts;
	// 	if (hearts === 0)
	// 	{
	// 		//TODO: display popup explaining how to get more hearts (profile overlay with hearts-tab open)
	// 	}
	// 	else
	// 	{
	// 		console.log("heart transfer: " + hearts);
	// 		this.animateHeart = 1;
	// 		this.animateHeartTimer = Utils.SetTimer(this.animateHeartTimer, 600, () => {this.animateHeart = 0;}, 30);
			
	// 		setTimeout(() => {
	// 			this.particles.Emit();
	// 			this.forceUpdate();
	// 		}, 10);
			
	// 		//if (!this.particlesUpdateInterval)
	// 		//	this.particlesUpdateInterval = setInterval(this.UpdateParticles, 330);

	// 		AppState.instance.userProfile.loginData.hearts = hearts - 1;

	// 		var q = {
	// 			destinationId: contentData.authorId,
	// 			contentId: contentData.id,
	// 			amount: 1
	// 		};

	// 		RestApi.SendRequest("/resourcetransfer", q).then((transaction) =>
	// 		{
	// 			if (transaction && transaction.status === "Failed")
	// 			{
	// 				AppState.instance.userProfile.loginData.hearts = AppState.instance.userProfile.loginData.hearts + 1;
	// 			}
	// 		});
	// 	}
	// }

	OnImageAttrLink = (attrLink) => (e) =>
	{
		e.preventDefault();
		e.stopPropagation();
		Utils.OpenExternalLink(attrLink);
	}

	renderImageAttributions(attributions)
	{
		if (this.props.enableContentAuthor === false)
			return null;
			
		var showAuthorFootnote = true;
		//if (this.isZoomed)
		//	showAuthorFootnote = false;

		var showAuthorZoomed = false;
		//if (this.isZoomed)
		//	showAuthorZoomed = true;

		return (
			<div className="detailContentOverlay detailContentOverlayAttr" id="detailContentOverlayAttr">

				{showAuthorFootnote && <div className="contentAuthor">
					{attributions.map((attr, index) =>
					{
						var attrLink = Utils.GetImageAttributionLink(attr);
						//console.log(attrLink);
						return (<div key={index} className="contentAuthorDesc imageAttrLink" onClick={this.OnImageAttrLink(attrLink)}>
							<span className="label">{Translate.T("en.Image", "de.Bild", "content.author.contentType.image")}: <span dangerouslySetInnerHTML={{ __html: attr }}></span></span>
						</div>);
					})}
						
				</div>}

				{showAuthorZoomed && <div className="contentAuthorZoomed contentAuthorZoomedAttr">

					{attributions.map((attr, index) =>
					{
						var attrLink = Utils.GetImageAttributionLink(attr);
						//console.log(attrLink);
						return (<div key={index} className="contentAuthorDescZoomed imageAttrLink" onClick={this.OnImageAttrLink(attrLink)}>
							<span className="label">{Translate.T("en.Image", "de.Bild", "content.author.contentType.image")}: <span dangerouslySetInnerHTML={{ __html: attr }}></span></span>
						</div>);
					})}
				</div>}
			</div>
		);
	}

	static CheckAuthorLinkClick(e)
	{
		var result = false;

		//HACK: the author link is part of the mediaplayer element but clicking it does not work as it's outside of the mediaplayer
		var parents = $(e.target).parents(".detailContainer");
		if (parents.length === 0)
			parents = $(e.target).parents(".contentLink");

		if (parents.length > 0)
		{
			var links = $(parents).find(".imageAttrLink");
			links.each(function ()
			{
				if (!result)
				{
					const linkRect = this.getBoundingClientRect();
					const dist = Utils.GetDistanceFromRect(e.clientX, e.clientY, linkRect);
					if (dist <= 3)
					{
						result = true;
						this.click();
					}
				}
			});	
		}

		return result;
	}

	renderContentOverlay(contentData, index)
	{
		//TEMP:
		//TODO:
		//var contentLiveData = LiveData.GetContentLiveData(this.props.data.liveData, contentData.id);
		var hotData = {};// LiveData.GetHotContentData(contentLiveData, contentData);

		var isVideo = contentData.type === 2;
		
		var showAuthorFootnote = true;
		if (/*this.isZoomed ||*/ contentData.copyright || contentData.isOwnContent)
			showAuthorFootnote = false;

		var showContentStatus = false;
		if (/*!this.isZoomed &&*/ contentData.isOwnContent)
			showContentStatus = true;

		var showAuthorZoomed = false;
		//if (this.isZoomed /*&& contentData.copyright*/)
		//	showAuthorZoomed = true;

		if (this.profileOverlayUsername)
			showAuthorZoomed = false;

		var allowHeartTransfer = false;
		if (contentData.copyright /*&& !contentData.isOwnContent*/)
			allowHeartTransfer = true;
		if (this.props.interactive === false)
			allowHeartTransfer = false;

		//TEMP: not fully implemented yet
		allowHeartTransfer = false;

		var addHeartClass = "";
		if (this.animateHeart === 1)
			addHeartClass = " heartButtonAnimated";

		return (
			<div className="detailContentOverlay" id={"detailContentOverlay" + index}>
				<div className="contentLiveData">
					{hotData.liveContent && <div className="contentLiveDataItem contentLiveDataLive"><VideoCam /><span className="label">{Translate.T("en.LIVE", "de.LIVE", "content.indicator.live")}</span></div>}
					{hotData.newContent && <div className="contentLiveDataItem contentLiveDataNew"><VideoCam /><span className="label">{Translate.T("en.NEW", "de.NEU", "content.indicator.fresh")}</span></div>}
					{!hotData.liveContent && contentData.relative_Time_Description &&
						<div className="contentLiveDataItem contentLiveDataTimeDesc"><span className="label">{contentData.relative_Time_Description}</span></div>}

					{/* <div className="contentLiveDataItem contentLiveDataViewers"><Icon>person</Icon><span className="label">{hotData.numCurrentViewers}</span></div> */}
				</div>

				{showAuthorFootnote && <div className="contentAuthor" onClick={this.OnAuthorClicked(contentData, false)}>
					{contentData.author_Name &&
						<div className="contentAuthorDesc">
							<span className="label">{isVideo ? Translate.T("en.Video", "de.Video", "content.author.contentType.video") :
								Translate.T("en.Image", "de.Bild", "content.author.contentType.image")}: {contentData.author_Name}</span>
						</div>}
				</div>}

				{showContentStatus && <div className="contentAuthor contentStatus">
					<div className="contentAuthorDesc">
						<span className="label">
							{Utils.GetContentStatusStr(contentData.status)}
						</span>
					</div>
				</div>}

				{showAuthorZoomed && <div className="contentAuthorZoomed" onClick={this.OnAuthorClicked(contentData, true)}>
					{contentData.author_Name &&
						<div className="contentAuthorDescZoomed">
							<span className="label">{contentData.author_Name}</span>
						</div>}
					{contentData.profile_Photo_Url &&
						<div className="contentAuthorPhoto">
							<img className="contentAuthorPhotoImg" src={contentData.profile_Photo_Url} alt="profile" />
						</div>}
					
				</div>}

				{/*allowHeartTransfer && <div className={"contentHeartTransfer" + addHeartClass}>
					<Button onClick={this.OnHeartTransferClicked(contentData)}>
						<Favorite />
					</Button>
				</div>*/}

				{this.profileOverlayUsername && <div className="contentAuthorOverlay" onClick={this.OnProfileOverlayClicked}>
					<ProfileComponent username={this.profileOverlayUsername} />
				</div>}
			</div>
		);
	}

	OnOrientationChange = () =>
	{
		// Make sure we adjust the content height to the new browser dimensions. Some browsers take longer to update window.innerHeight => try multiple times :-(
		this.forceUpdate();
		setTimeout(() => {this.forceUpdate()}, 50);
		setTimeout(() => {this.forceUpdate()}, 100);
		setTimeout(() => {this.forceUpdate()}, 200);
	}

	RenderFullscreenSlideCaption = (item, captionEl, isFake) =>
	{
		if (!item.src) {
			captionEl.children[0].innerHTML = '';
			return false;
		}

		const url = item.src;

		if (url.startsWith("/assets/") ||
			(url.indexOf("res.cloudinary.com/lenafamily/") >= 0) ||
			(url.indexOf("buffer-media-uploads.s3") >= 0) ||
			(url.indexOf("d1zjsncpn8gvig.cloudfront.net") >= 0))
		{
			captionEl.children[0].innerHTML = '';
			return false;
		}

		const urlInfo = Utils.GetUrlInfo(Utils.StripWeserv(url));
		var attribution = '';
		if (urlInfo)
		{
			const prefix = Translate.FindTranslation("attribution.picture");
			attribution = "<span class='attrLinkSpan'>" + prefix + "<a target=\"_blank\" href=\"" + urlInfo.protocol + "//" + urlInfo.hostname + "\">" + urlInfo.hostname + "</a></span>";
		}

		captionEl.children[0].innerHTML = attribution;
		return true;
	}

	ZoomImageContainer(zoomIn, onZoomDone, elementId)
	{
		if (this.props.disableZoom === true)
		{
			if (onZoomDone)
				onZoomDone();
			return;
		}

		var items = [];
		if (this.props.data.activity.pictureUrls)
		{
			for (var i = 0; i < this.props.data.activity.pictureUrls.length; ++i)
			{
				const url = Utils.GetImageUrl(this.props.data.activity.pictureUrls[i]);
				if (url !== undefined && url.length > 0)
				{
					var imageData = AppState.instance.imageManager.Get(url);
					if (imageData)
					{
						if (Utils.IsVideoUrl(url))
						{
							const posterUrl = Utils.GetVideoPoster(url);
							const id = Utils.GenerateRandomId(32);

							items.push({
								videosrc: url,
								videoId: id,
								posterUrl: posterUrl,
								w: imageData ? imageData.width : window.innerWidth,
								h: imageData ? imageData.height : Math.round(window.innerWidth * 9 / 16),
								videoW: imageData ? imageData.width : window.innerWidth,
								videoH: imageData ? imageData.height : Math.round(window.innerWidth * 9 / 16),
								title: 'test'
							});
						}
						else
						{
							var fixedUrl = Utils.FixHttpImages(url);
							fixedUrl = Utils.ScaleDownCloudinaryImages(fixedUrl);

							items.push({
								msrc: fixedUrl,
								src: Utils.FixHttpImages(imageData.url),
								w: imageData.width,
								h: imageData.height,
								title: 'test'
							});
						}
					}
				}
			}
		}

		if (items.length === 0)
			return; // No valid image data found in ImageManager

		if (this.props.onBeforeZoom)
			this.props.onBeforeZoom(zoomIn);

		FullscreenMedia.Show(items, this.photoIndex, "#" + this.id +  " .contentImage",
			this.OnFullscreenSlideChanged, this.RenderFullscreenSlideCaption, this.OnFullscreenClosed);
		
		//var containerClassname = ".detailPage";
		//if (this.props.containerClassname)
		//	containerClassname = "." + this.props.containerClassname;

		if (zoomIn)
		{
			//this.isZoomed = true;
			//$(containerClassname + " .imageZoomOverlay").css('display', 'block');

			this.OnContentEvent("contentZoomInImage");
			this.UpdateAuthorRing();
		}
		// else
		// {
		// 	//$(containerClassname + " .detailInfoContainer").css('display', 'block');
		// 	//$(containerClassname + " .mapPageContainer").css('display', 'block');
		// 	//$(containerClassname + " .detailSimilarItems").css('display', 'block');
		// 	//$(containerClassname + " .imageZoomOverlay").css('display', 'none');

		// 	this.profileOverlayUsername = undefined;

		// 	this.OnContentEvent("contentCloseDetails");
		// }

		// var container = $(this.container);// $("#contentContainer");
		// container.animate({
		// 	height: zoomIn ? window.innerHeight : this.defaultContentHeight
		// }, 200, undefined,
		// () => {
			if (zoomIn)
			{
				//$(containerClassname + " .detailInfoContainer").css('display', 'none');
				//$(containerClassname + " .mapPageContainer").css('display', 'none');
				//$(containerClassname + " .detailSimilarItems").css('display', 'none');
			}
			else
			{
				//this.isZoomed = false;
			}
			if (onZoomDone)
				onZoomDone();

			if (this.props.onAfterZoom)
				this.props.onAfterZoom(zoomIn);
		//});

		// if (DetailHeaderBar.instance)
		// {
		// 	if (zoomIn)
		// 		DetailHeaderBar.instance.Hide();
		// 	else
		// 		DetailHeaderBar.instance.Show();
		// }
	}

	OnImageDataLoaded = (isSuccess, url) =>
	{
		if (isSuccess)
		{
			this.imageDataLoaded = true;
			this.fadeOutThumbnail = true;

			setTimeout(() => {
				this.fadeOutThumbnail = false;
				if (this._isMounted)
					this.forceUpdate();
			}, 2000);
		}
		else
		{
			console.log("failed loading: " + url);
		}
	}

	OnVideoStarted = (index) => () =>
	{
		$("#playButton" + index).css("display", "none");
		this.playingVideo = this.videoNodes["v" + index];
		//console.log(this.playingVideo);
	}

	OnVideoPaused = (index) => () =>
	{
		$("#playButton" + index).css("display", "block");
		this.playingVideo = undefined;
	}

	renderDetailImage(url, contentData, index, attributions, usesGallery)
	{
		//var className = this.isZoomed ? "zoomedImage" : "detailImage";
		var className = "detailImage";

		var isCurrentPhoto = index === this.photoIndex;
		const isVideo = Utils.IsVideoUrl(url);

		var renderPlayButton = false;
		var renderReplayButton = false;
		var renderPauseButton = false;

		if (isVideo && isCurrentPhoto)
			renderPlayButton = true;

		//console.log(url + "  =>  " + isVideo + ", " + isCurrentPhoto);

		var fixedUrl;
		var imageDataUrl;
		if (isVideo)
		{
			fixedUrl = Utils.GetVideoPoster(url);
			imageDataUrl = fixedUrl;
		}
		else
		{
			fixedUrl = Utils.FixHttpImages(url);
			imageDataUrl = fixedUrl;
			fixedUrl = Utils.ScaleDownCloudinaryImages(fixedUrl);
			Utils.TestImageUrl(fixedUrl);
		}

		const isDataUrl = url && url.startsWith("data:image/svg");

		if (url && url.length > 0 && !contentData && (!attributions || attributions.length === 0) &&
			(!url.startsWith("/assets/")) &&
			(!isDataUrl) &&
			(fixedUrl.indexOf("res.cloudinary.com/lenafamily/") < 0) &&
			(fixedUrl.indexOf("buffer-media-uploads.s3") < 0) &&
			(fixedUrl.indexOf("d1zjsncpn8gvig.cloudfront.net") < 0))
		{
			const urlInfo = Utils.GetUrlInfo(url);
			if (urlInfo)
			{
				attributions = [];
				attributions.push("<a target=\"_blank\" href=\"" + urlInfo.protocol + "//" + urlInfo.hostname + "\">" + urlInfo.hostname + "</a>");
			}
		}

		//const wasLoadedBefore = Utils.WasImageLoaded(fixedUrl);

		var thumbnailUrl;
		let isPortrait = false;
		const imageData = AppState.instance.imageManager.Get(imageDataUrl);
		//console.log(imageData);
		if (imageData && (imageData.height > imageData.width * 1.2))
			isPortrait = true;

		if ((!this.imageDataLoaded || this.fadeOutThumbnail) /*&& !wasLoadedBefore*/)
		{
			if (imageData)
				thumbnailUrl = imageData.thumbnailDataUrl;

			if (!this.fadeOutThumbnail)
				Utils.OnImageLoaded(fixedUrl, this.OnImageDataLoaded);
		}

		let videoElement;
		if (usesGallery && isVideo)
		{
			const scaledVideoUrl = Utils.ScaleDownCloudinaryVideos(url);
			videoElement = (
				<video
					ref={node => {this.videoNodes["v" + index] = node;}}
					controls
					id={"detailVideo" + index}
					className="galleryVideo"
					playsInline
					preload="auto"
					src={scaledVideoUrl}
					poster={fixedUrl}
					onPlay={this.OnVideoStarted(index)}
					onPause={this.OnVideoPaused(index)}
				/>);
		}

		return (
			<div
				className={className + (isDataUrl ? " detailImageDataUrl":"") + (isPortrait ? " portrait":"")}
				id={"detailImage" + index}
				style={usesGallery && isVideo ? undefined : {backgroundImage: 'url("' + fixedUrl + '")'}}
				onClick={this.props.interactive === false || usesGallery ? undefined : this.OnPictureClicked("detailImage" + index)}
			>
				{thumbnailUrl &&
					<div className={"thumbnailOverlay" + (this.fadeOutThumbnail ? " fadeout":"")}
						style={{backgroundImage: 'url("' + thumbnailUrl + '")'}}
				/>}
				{videoElement}
				{contentData && this.renderContentOverlay(contentData, index)}
				{attributions && attributions.length > 0 && this.renderImageAttributions(attributions)}

				{renderPlayButton && <div id={"playButton" + index} className="detailVideoPlayOverlay" key="play"><PlayCircleOutline /></div>}
				{renderPauseButton && <div className="detailVideoPlayOverlay" key="pause"><PauseCircleOutline /></div>}
				{renderReplayButton && <div className="detailVideoPlayOverlay" key="replay"><Replay /></div>}
			</div>
		);
	}

	FindUserContent(url)
	{
		if (!this.props.data.userContent)
			return undefined;

		for (var i = 0; i < this.props.data.userContent.length; ++i)
		{
			if (this.props.data.userContent[i].url === url)
				return this.props.data.userContent[i];
		}
		return undefined;
	}

	GoToEnd()
	{
		this.OnContentSwiped(this.props.data.activity.pictureUrls.length - 1, this.photoIndex);
		if (this.imageGallery)
			this.imageGallery.slideToIndex(this.photoIndex);
	}

	OnContentSwiped = (newIndex, oldIndex) =>
	{
		this.photoIndex = newIndex;
		this.profileOverlayUsername = undefined;
		this.OnContentEvent("contentImageChanged");

		if (this.playingVideo)
		{
			//console.log("stopping video");
			this.playingVideo.pause();
			this.playingVideo = undefined;
			//console.log("done");
		}
	}

	HasMultipleItems()
	{
		return this.GetNumItems() > 1;
	}

	OnTouchStart = () =>
	{
		this.imageWasDragged = false;

		if (this.HasMultipleItems())
			if (this.props.onTouchStart)
				this.props.onTouchStart();
	}

	OnSwitching = (index, type) =>
	{
		this.imageWasDragged = true;

		if (this.HasMultipleItems())
			if (this.props.onSwitching)
				this.props.onSwitching(index, type);
	}

	OnTouchEnd = () =>
	{
		if (this.HasMultipleItems())
			if (this.props.onTouchEnd)
				this.props.onTouchEnd();
	}

	OnPictureClicked = (elementId) => (e) =>
	{
		if (this.imageWasDragged)
		{
			this.imageWasDragged = false;
			return;
		}

		/*if (!AppState.instance.isLandingPage &&
			!AppState.instance.deviceInfo.desktop)*/
		//{
			this.ZoomImageContainer(!this.isZoomed, undefined, elementId);
		//}

		e.stopPropagation();
		e.preventDefault();
	}

	CalcVisibility = () =>
	{
		if (!this.container)
			return;

		if (this.props.unloadable === false)
		{
			this.OnVisibilitySensor(true);
			return;
		}

		var rect = this.container.getBoundingClientRect();
		const containmentRect = {
			top: 0,
			left: 0,
			bottom: window.innerHeight || document.documentElement.clientHeight,
			right: window.innerWidth || document.documentElement.clientWidth
		};

		const distance = Utils.GetDistanceFromContainer(rect, containmentRect);
		var isNowVisible = distance <= 200; // N pixels buffer

		if (AppState.instance.deviceInfo.ie)
			isNowVisible = true;

		if (isNowVisible !== this.isVisible)
			this.OnVisibilitySensor(isNowVisible);
	}

	OnVisibilitySensor(isVisible)
	{
		this.isVisible = isVisible;

		if (isVisible && !this.isLoaded)
		{
			if (this.unloadTimer)
			{
				//console.log("clearing unloading timer");
				Utils.ClearTimer(this.unloadTimer);
				this.unloadTimer = undefined;
			}

			this.isLoaded = true;
			this.wasLoaded = true;
			this.forceUpdate();
		}
		else if (this.isLoaded && !isVisible)
		{
			// Check if it's still invisible in 5 seconds
			if (!AppState.instance.deviceInfo.desktop)
			{
				this.unloadTimer = Utils.SetTimer(this.unloadTimer, 10000, () =>
				{
					if (this._isMounted && !this.isVisible)
					{
						//console.log("unloading after 10 sec");
						this.isLoaded = false;
						this.imageDataLoaded = false;
						this.forceUpdate();	
					}
				}, 500);
			}
		}
	}

	OnRightClick = () =>
	{
		const num = this.GetNumItems();
		if (num <= 1)
			return;

		if (this.photoIndex < this.GetNumItems() - 1)
			this.photoIndex++;
	}

	OnLeftClick = () =>
	{
		const num = this.GetNumItems();
		if (num <= 1)
			return;

		if (this.photoIndex > 0)
			this.photoIndex--;
	}

	GetNumItems()
	{
		if (this.isLoaded)
		{
			var result = 0;
			if (this.props.data.activity.pictureUrls)
			{
				for (var i = 0; i < this.props.data.activity.pictureUrls.length; ++i)
				{
					if (this.props.data.activity.pictureUrls[i] !== undefined && this.props.data.activity.pictureUrls[i].length > 0)
					{
						++result;
					}
				}
			}

			if (result === 0)
				return 1;

			return result;
		}
		else
		{
			return 1;
		}
	}

	GetFirstPictureUrl()
	{
		if (this.props.data.activity.pictureUrls)
		{
			for (var i = 0; i < this.props.data.activity.pictureUrls.length; ++i)
			{
				if (this.props.data.activity.pictureUrls[i] !== undefined && this.props.data.activity.pictureUrls[i].length > 0)
				{
					return Utils.GetImageUrl(this.props.data.activity.pictureUrls[i]);
				}
			}
		}

		return undefined;
	}

	OnFullscreenSlideChanged = (index) =>
	{
		if (index !== this.photoIndex)
		{
			this.photoIndex = index;
			this.OnContentEvent("contentZoomedImageChanged");
		}
	}

	OnFullscreenClosed = () =>
	{
		this.profileOverlayUsername = undefined;
		this.OnContentEvent("contentZoomOutImage");
	}

	OnDotClicked = (event, index) =>
	{
		if (AppState.instance.deviceInfo.desktop)
		{
			this.photoIndex = index;
			event.stopPropagation();
			event.preventDefault();
			this.OnContentEvent("contentImageChanged");
		}
	}

	RenderGalleryItem = (item) =>
	{
		return (
			<div className="galleryDetailImageWrapper">
				{this.renderDetailImage(item.url, item.userContent, item.i, item.attributions, true)}
			</div>
		);
	}

	RenderVideoThumbnail = (item) =>
	{
		return (
			<div className="image-gallery-thumbnail-inner video">
				<img src={item.thumbnailPoster} className="galleryVideoThumbnail" />
				<div className="detailVideoPlayOverlay"><PlayCircleOutline /></div>
			</div>
		);
	}

	RenderImageThumbnail = (item) =>
	{
		return (
			<div className="image-gallery-thumbnail-inner image">
				<div className="galleryImageThumbnail"
					style={{backgroundImage: 'url("' + item.thumbnail + '")', backgroundColor: 'white'}}
				/>
			</div>
		);
	}

	render()
	{
		var data = this.props.data;

		var thisPtr = this;
		var imageDivs = [];
		let galleryImages = [];

		const useGallery = AppState.instance.deviceInfo.desktop && this.props.allowGallery;

		if (this.isLoaded)
		{
			if (this.props.data.activity.pictureUrls)
			{
				for (var i = 0; i < this.props.data.activity.pictureUrls.length; ++i)
				{
					if (this.props.data.activity.pictureUrls[i] !== undefined && this.props.data.activity.pictureUrls[i].length > 0)
					{
						const url = Utils.GetImageUrl(this.props.data.activity.pictureUrls[i]);
						if (url)
						{
							const attributions = Utils.GetImageAttributions(this.props.data.activity.pictureUrls[i]);

							var isVideo = Utils.IsVideoUrl(url);

							var userContent = this.FindUserContent(url);

							imageDivs.push(
								<div key={"imagecontainer_" + i}
									//onClick={(isVideo || this.props.interactive === false) ? undefined : this.OnOpenLightbox}
								>
									{this.renderDetailImage(url, userContent, i, attributions)}
									{/*attributions && attributions.length > 0 && this.renderImageAttributions(attributions)*/}
								</div>
							);

							let fixedUrl = url;
							if (Utils.IsVideoUrl(url))
								fixedUrl = Utils.GetVideoPoster(url);
							else
								fixedUrl = Utils.FixHttpImages(url);

							//console.log(url + " => " + fixedUrl);

							galleryImages.push({
								original: fixedUrl,
								thumbnail: Utils.ScaleDownCloudinaryImages(fixedUrl, 204),
								url,
								userContent,
								i,
								attributions,
								renderItem: this.RenderGalleryItem,
								thumbnailPoster: isVideo ? Utils.GetVideoPoster(url, 204) : undefined,
								renderThumbInner: isVideo ? this.RenderVideoThumbnail : this.RenderImageThumbnail
							});
						}
					}
				}
			}

			if (imageDivs.length === 0)
			{
				var catIconUrl;
				var isDataUrl = false;
				if (data.categories && data.categories.length > 0)
				{
					catIconUrl = Utils.GetCategoryIconUrl(data.categories[0], "white", "#ccc", 16, 16, true);
					isDataUrl = true;
				}
				else
				{
					catIconUrl = "/assets/placeholder.svg";
				}

				imageDivs.push(
					<div key={"imagecontainer_0"}>
						<div
							className={"detailImage" + (isDataUrl ? " detailImageDataUrl":"")}
							alt="TitleImage"
							style={{backgroundImage: 'url("' + catIconUrl + '")', backgroundColor: 'white'}}
						/>
					</div>
				);
			}
		}
		else
		{
			var imageDataUrl = this.GetFirstPictureUrl();
			if (imageDataUrl)
			{
				if (Utils.IsVideoUrl(imageDataUrl))
					imageDataUrl = Utils.GetVideoPoster(imageDataUrl);

				const imageData = AppState.instance.imageManager.Get(imageDataUrl);
				if (imageData)
				{
					var thumbnailUrl = imageData.thumbnailDataUrl;

					if (thumbnailUrl)
					{
						imageDivs.push(
							<div key={"imagecontainer_thumbnail"}>
								<div
									className={"detailImage"}
									alt="dummy"
									style={{backgroundImage: 'url("' + thumbnailUrl + '")', backgroundColor: 'white'}}
								/>
							</div>
						);

						galleryImages.push({
							original: thumbnailUrl,
							thumbnail: thumbnailUrl,
						});
					}
				}
			}
			
			if (imageDivs.length === 0)
			{
				const dummyImg = "data:image/svg+xml;charset=UTF-8,%3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24'%3e%3c/svg%3e";
				imageDivs.push(
					<div key={"imagecontainer_unloaded"}>
						<div
							className={"detailImage"}
							alt="dummy"
							style={{backgroundImage: 'url("' + dummyImg + '")', backgroundColor: 'white'}}
						/>
					</div>
				);
			}
		}

		var contentHeight = this.defaultContentHeight || 200;
		// if (this.isZoomed)
		// {
		// 	contentHeight = window.innerHeight;
		// 	if (AppState.instance.isLandingPage)
		// 	{
		// 		contentHeight = 667;
		// 	}
		// }

		const numItem = imageDivs ? imageDivs.length : 0;
		const showControls = numItem > 1 && contentHeight >= 100;

		return (
			<div
				className={"mediaContainer" + AppState.instance.GetPlatformClasses()}
				id={this.id}
				ref={(container) => { this.container = container; }}
			>
				{!useGallery &&
					<SwipeableViews
						index={this.photoIndex}
						className={this.props.className ? this.props.className : "contentContainer"}
						//id="contentContainer"
						onChangeIndex={this.OnContentSwiped}
						onSwitching={this.OnSwitching}
						onTouchEnd={this.OnTouchEnd}
						onTouchStart={this.OnTouchStart}
						resistance={imageDivs.length > 1}
						style={{height: contentHeight}}
						disabled={this.props.interactive === false}
					>
						{imageDivs}
					</SwipeableViews>}

				{useGallery &&
					<ImageGallery
						ref={c => this.imageGallery = c}
						items={galleryImages}
						thumbnailPosition="right"
						showBullets={false}
						slideDuration={300}
						startIndex={this.photoIndex}
						onSlide={this.OnContentSwiped}
						showPlayButton={false}
						lazyLoad={true}
					/>}

				{!useGallery && showControls && <Dots index={this.photoIndex} count={this.GetNumItems()} onClick={this.OnDotClicked} />}

				{/* <Fade in={!this.isLoaded} timeout={this.wasLoaded ? 200 : 400}>
					<div className="mediaPlayerBlocker" />
				</Fade> */}

				{!useGallery && AppState.instance.deviceInfo.desktop && showControls && <div className={"leftBtn" + (this.photoIndex === 0 ? " inactive":"")} onClick={this.OnLeftClick}>
					<KeyboardArrowLeft />
				</div>}
				{!useGallery && AppState.instance.deviceInfo.desktop && showControls && <div className={"rightBtn" + ((this.photoIndex === imageDivs.length - 1) ? " inactive":"")} onClick={this.OnRightClick}>
					<KeyboardArrowRight />
				</div>}
			</div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(MediaPlayer);