import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import RestApi from '../RestApi';
import Translate from '../Translate';
import IconButton from 'material-ui/IconButton';
import CloseIcon from 'material-ui-icons/Close';
import Button from 'material-ui/Button';
import TextField from 'material-ui/TextField';
import Utils from '../Utils';
import Snackbar from 'material-ui/Snackbar';
import AppState from '../AppState';
import Chip from 'material-ui/Chip';
import List, { ListItem, ListItemText } from 'material-ui/List';
import Dialog from 'material-ui/Dialog';
import MediaPlayer from './MediaPlayer';
import Email from 'material-ui-icons/Email';
import Phone from 'material-ui-icons/Phone';
import Web from 'material-ui-icons/Web';
import Directions from 'material-ui-icons/Directions';
import DeleteIcon from 'material-ui-icons/Delete';
import AddAPhoto from 'material-ui-icons/AddAPhoto';
import ProgressDisplay from './ProgressDisplay';
import Rating from './Rating';
import $ from 'jquery';
import Map from './Map';
import EuroSymbol from 'material-ui-icons/EuroSymbol';


const styles = {
};

@observer
class ActivityEditor extends React.Component
{
	@observable data;
	changedData = {};
	reportQuery = {};
	@observable displayError = false;
	errorMsg;
	@observable showCategories = false;
	@observable progressItems = [];
	mediaPlayer;

	originalPictureUrls;

	constructor(props)
	{
		super(props);

		this.InitPriceLevel();
	}

	componentDidMount()
	{
		AppState.instance.activityEditor = this;

		this.data = this.props.data;
		this.ResetQuery();
		
		if (this.data && this.data.activity)
		{
			if (this.data.activity.pictureUrls && this.data.activity.pictureUrls.length > 0)
				this.originalPictureUrls = this.data.activity.pictureUrls.slice();
			else
				this.originalPictureUrls = [];

			this.CopyData();

			if (!this.data.contentIds)
				this.data.contentIds = [];

			if (this.data.activity.pictureUrls && (this.data.contentIds.length < this.data.activity.pictureUrls.length))
			{
				const diff = this.data.activity.pictureUrls.length - this.data.contentIds.length;
				for (var i = 0; i < diff; ++i)
					this.data.contentIds.push(undefined);
			}

			this.forceUpdate();
		}
	}

	componentWillUnmount()
	{
		if (!this.props.valueMode)
		{
			this.data.activity.pictureUrls = this.originalPictureUrls;
		}

		AppState.instance.activityEditor = undefined;
	}

	// Causes stack overflow
	// componentDidUpdate(prevProps)
	// {
	// 	this.data = this.props.data;
	// 	if (prevProps.data !== this.props.data)
	// 	{
	// 		this.ResetQuery();

	// 		if (this.props.valueMode && this.data.activity)
	// 		{
	// 			this.CopyData();
	// 		}
	// 	}
	// }

	InitPriceLevel()
	{
		if (this.props.data && this.props.data.activity)
		{
			if (this.props.data.activity.price)
			{
				this.props.data.activity.priceLevel = this.ExtractPriceLevel(this.props.data.activity.price);

				if (this.props.data.activity.priceLevel > 0)
				{
					this.props.data.activity.price = this.RemoveFirstLine(this.props.data.activity.price);
				}
			}
			else
			{
				this.props.data.activity.priceLevel = -1;
			}
		}
	}

	CopyData()
	{
		var validProps = ['name', 'infoUrl', 'address', 'phone', 'email', 'desc', 'openingHours', 'price', 'priceLevel',
			'ageFitMin', 'ageFitMax', 'durationMin', 'durationMax', 'strollerSuitable', 'accessible',
			'dogsWelcome', 'bookingRequired', 'carParking', 'comment', 'pictureUrls'];

		// Copy all values to changedData
		this.changedData = {};
		if (this.data)
			for (var prop in this.data.activity)
				if (validProps.indexOf(prop) >= 0)
					this.changedData[prop] = this.data.activity[prop];
	}

	ResetQuery = () =>
	{
		this.reportQuery = {};
		if (this.data && this.data.activity)
			this.reportQuery.activityId = this.data.activity.id;
		else
			this.reportQuery.activityId = undefined;
		this.reportQuery.fieldNames = [];
		this.reportQuery.fieldValues = [];
	}

	handleChange = name => event =>
	{
		this.changedData[name] = event.target.value;

		if (this.props.valueMode)
		{
			this.data.activity[name] = event.target.value;
			this.forceUpdate();
		}
	}

	handleMultilineChange = name => event =>
	{
		this.changedData[name] = event.target.value;
		if (this.props.valueMode)
			this.data.activity[name] = event.target.value;
		this.forceUpdate();
	}

	handleSwitchChange = name => (event, checked) =>
	{
		console.log("onchanged: " + name + " = " + checked);

		this.changedData[name] = checked;
		this.forceUpdate();
	}

	OnWebsiteClick = () =>
	{
		var url = $(".editInputContainer.infoUrl input").val();
		if (!url || url.length === 0)
			url = this.data.activity.infoUrl;

		if (url && url.length > 0)
		{
			if (!url.startsWith("http"))
				url = "https://" + url;

			Utils.OpenExternalLink(url);
		}
	}

	OnAddressClick = () =>
	{
		var address = $(".editInputContainer.address input").val();
		if (!address || address.length === 0)
			address = this.data.activity.address;

		if (address && address.length > 0)
		{
			Utils.StartNavigation(address);
		}
	}

	renderInput = (fieldName, label) =>
	{
		var valueProp = {};
		if (this.props.valueMode)
			valueProp = {value: this.data.activity[fieldName]};

		var placeholder = this.data.activity[fieldName];
		if (!placeholder || placeholder.length === 0)
			placeholder = label;

		return (
			<div className={"editInputContainer " + fieldName}>
				{/* <div className="editInputTitle">{label}</div> */}
				{fieldName === "infoUrl" && <span className="editInputIcon" onClick={this.OnWebsiteClick}><Web/></span>}
				{fieldName === "email" && <span className="editInputIcon"><Email/></span>}
				{fieldName === "phone" && <span className="editInputIcon"><Phone/></span>}
				{fieldName === "address" && <span className="editInputIcon" onClick={this.OnAddressClick}><Directions/></span>}
				<TextField
					key={fieldName}
					InputLabelProps={{
						shrink: true,
					}}
					placeholder={placeholder}
					{...valueProp}
					onChange={this.handleChange(fieldName)}
					fullWidth
					margin="dense"
				/>

			</div>
		);
	}

	OnClickBoolButton = (fieldName) => () =>
	{
		// current value
		var checked = this.data.activity[fieldName];
		if (this.changedData[fieldName] !== undefined)
			checked = this.changedData[fieldName];

		if (checked === undefined)
			checked = null;

		// rotate to next value
		if (checked === null)
			checked = true;
		else if (checked === true)
			checked = false;
		else
			checked = null;

		this.changedData[fieldName] = checked;

		if (this.props.valueMode)
		{
			this.data.activity[fieldName] = checked;
		}

		this.forceUpdate();
	}

	renderBoolInput = (fieldName, label, smallMargin) =>
	{
		var checked = this.data.activity[fieldName];
		if (this.changedData[fieldName] !== undefined)
			checked = this.changedData[fieldName];
		if (checked === undefined)
			checked = null;

		return (
			<div className={"editInputContainerBoolButton editInputContainer" + (smallMargin ? " editInputContainerSmallMargin" : "")}>
				<Button
					key={fieldName}
					className={"editButtonBool " + fieldName + " " + checked}
					onClick={this.OnClickBoolButton(fieldName)}
				>
					{fieldName === "strollerSuitable" && Utils.RenderStroller(32, 32, "#aaa", 1.0)}
					{fieldName === "accessible" && Utils.RenderWheelchair(32, 32, "#aaa", 1.0)}
					{fieldName === "dogsWelcome" && Utils.RenderDogIcon(32, 32, "none", "#aaa", 1.0)}
					{fieldName === "bookingRequired" && Utils.RenderBooking(32, 32, "#aaa", 1.0)}
					{fieldName === "carParking" && Utils.RenderParking(32, 32, "#aaa", 1.0)}
				</Button>
			</div>
		);
	}

	OnPriceIconsClicked = (nextValue, prevValue, name) =>
	{
		if (nextValue === 1 && prevValue === 1)
			this.changedData.priceLevel = 0;
		else
			this.changedData.priceLevel = nextValue;

		this.forceUpdate();
	}

	CalcFinalPrice()
	{
		var level = this.data.activity.priceLevel;
		if (this.changedData.priceLevel !== undefined && this.changedData.priceLevel !== "")
			level = this.changedData.priceLevel;
		if (level < 0)
			level = 2;

		var currentValue = this.data.activity.price;
		//TODO: remove first line if €€€
		if (this.ExtractPriceLevel(currentValue) > 0)
			currentValue = this.RemoveFirstLine(currentValue);

		if (this.changedData.price !== undefined && this.changedData.price !== "")
			currentValue = this.changedData.price;

		if (level > 0)
		{
			var levelStr = "€";
			if (level === 2)
				levelStr = "€€";
			else if (level === 3)
				levelStr = "€€€";

			return levelStr + "\n" + currentValue;
		}
		else
			return currentValue;
	}

	RemoveFirstLine(str)
	{
		var lines = str.split('\n');
		if (lines.length >= 1)
		{
			lines.splice(0, 1);
		}
		return lines.join("\n");
	}

	RenderPriceIcons(numFilled)
	{
		var priceIconsDesc = [];
		priceIconsDesc.push(Translate.T("en.free", "de.kostenlos", "pricing.desc.0"));
		priceIconsDesc.push(Translate.T("en.low-priced", "de.kostengünstig", "pricing.desc.1"));
		priceIconsDesc.push(Translate.T("en.moderate", "de.moderat", "pricing.desc.2"));
		priceIconsDesc.push(Translate.T("en.expensive", "de.teuer", "pricing.desc.3"));

		return (
			<span key={"priceicons"} className={"priceIcons"}>
				<Rating
					editing={true}
					onStarClick={this.OnPriceIconsClicked}
					value={numFilled} starCount={3} renderStarIcon={(index, value, name) =>
					{
						if (index <= value)
							return <EuroSymbol className="priceIconFull" />;
						else
							return <EuroSymbol className="priceIconEmpty" />;
					}} />
				<span className="priceIconsDesc">{priceIconsDesc[numFilled]}</span>
			</span>);
	}

	ExtractPriceLevel(priceStr)
	{
		if (priceStr)
		{
			priceStr = priceStr.split('\n');
			if (priceStr.length >= 1)
			{
				for (var i = 0; i < priceStr.length; ++i)
				{
					const line = priceStr[i];
					if (line === '€' || line === '€€' || line === '€€€')
					{
						return line.length;
					}

					return undefined;
				}
			}
		}
		return undefined;
	}

	renderMultilineInput = (fieldName, label, id) =>
	{
		var placeholder = undefined;
		if (fieldName !== null)
		{
			placeholder = this.data.activity[fieldName];
			//if (placeholder !== undefined)
			//	placeholder = placeholder.trim();
		}

		var valueProp = {};
		if (this.props.valueMode && placeholder)
			valueProp = {value: placeholder};
		
		var currentValue = placeholder;
		if (this.changedData[id] !== undefined && this.changedData[id] !== "")
			currentValue = this.changedData[id];

		var priceIcons;
		var showPriceDetails = true;
		if (fieldName === "price")
		{
			showPriceDetails = this.props.showPricing !== false;

			var level = this.data.activity.priceLevel;
			if (this.changedData.priceLevel !== undefined && this.changedData.priceLevel !== "")
				level = this.changedData.priceLevel;
			if (level < 0)
				level = 2;

			priceIcons = this.RenderPriceIcons(level);
		}

		var rows = 1;
		if (currentValue !== undefined && currentValue !== "")
		{
			var additionalStyle = {'white-space': 'pre-wrap'};
			if (currentValue.endsWith("\n"))
				currentValue += "_";
			rows = Utils.MeasureTextLines(currentValue, "editPageTest", additionalStyle);
		}
		if (rows > 5)
			rows = 5;
		if (rows < 3 && AppState.instance.deviceInfo.desktop)
			rows = 3;

		return (
			<div className={"editInputContainer editInputContainer" + id}>
				<div className="editInputTitle">{label}</div>

				{priceIcons}

				{showPriceDetails && <TextField
					key={id}
					multiline
					rows={rows}
					InputLabelProps={{
						shrink: true,
					}}
					placeholder={placeholder}
					{...valueProp}
					onChange={this.handleMultilineChange(id)}
					fullWidth
					margin="dense"
				/>}
			</div>
		);
	}

	Submit = () =>
	{
		this.changedData.price = this.CalcFinalPrice();
		this.changedData.priceLevel = undefined;
		delete this.changedData.priceLevel;

		if (this.props.valueMode)
			this.data.activity.price = this.changedData.price;

		if (this.data.categories)
		{
			var cats = [];
			for (var i = 0; i < this.data.categories.length; ++i)
				cats.push(this.data.categories[i]);
			this.changedData["categorynames"] = JSON.stringify(cats);
		}

		var thisPtr = this;
		this.ResetQuery();
		for (var fieldName in this.changedData)
		{
			console.log("changed: " + fieldName + " = " + this.changedData[fieldName]);

			this.reportQuery.fieldNames.push(fieldName);
			this.reportQuery.fieldValues.push(this.changedData[fieldName]);
		}

		console.log(thisPtr.reportQuery);

		this.InitPriceLevel();

		var doSubmit = true;
		if (this.props.onBeforeSubmit)
			doSubmit = this.props.onBeforeSubmit(this.reportQuery);

		if (doSubmit === false)
			return;

		RestApi.ReportEdit(this.reportQuery).then( (resultText) =>
		{
			if (!resultText || resultText.indexOf("error") >= 0)
			{
				thisPtr.errorMsg = Translate.T("en.Error while sending data. Please try again later. Thanks!", "de.Fehler beim Senden der Daten. Biete versuche es später nochmal. Danke!",
					"suggesteditpage.senderror");
				thisPtr.displayError = true;
				if (this.props.onError)
					this.props.onError(thisPtr.errorMsg);
			}
			else
			{
				const token = resultText;
				AppState.instance.SaveMiningToken(token);

				thisPtr.changedData = {};
				thisPtr.ResetQuery();
				if (this.props.onSubmitted)
					this.props.onSubmitted();
			}
		})
		.catch((error) =>
		{
			thisPtr.errorMsg = error;
			thisPtr.displayError = true;
		});
		//TODO: else: error message
	}

	OnSubmitClicked = () =>
	{
		this.Submit();
	}

	handleRequestErrorClose = () =>
	{
		this.displayError = false;
	}

	HandleChipDelete = catName => () =>
	{
		if (this.data.activity && this.data.categories)
		{
			for (var i = 0; i < this.data.categories.length; ++i)
				if (this.data.categories[i] === catName)
				{
					this.data.categories.splice(i, 1);
					this.forceUpdate();
					break;
				}
		}
	}

	OnCategoryClicked = catName => () =>
	{
		if (!this.data.categories)
			this.data.categories = [];

		this.data.categories.push(catName);
		this.showCategories = false;
		this.forceUpdate();
	}

	OnMediaPlayerInstance = (mp) =>
	{
		this.mediaPlayer = mp;
	}

	RemovePicture = () =>
	{
		if (!this.mediaPlayer)
			return;

		setTimeout(() =>
		{
			AppState.instance.screenMessage.ShowAlert(Translate.T("en.Confirmation", "de.Bestätigung", "confirmation.title"),
			Translate.T("en.Do you really want to remove this photo?",
				"de.Willst Du dieses Photo wirklich entfernen?", "confirmation.editentry.removephoto"),
			[{label: Translate.FindTranslation("answer.no"), value: 0}, {label: Translate.FindTranslation("answer.yes"), value: 1}], true, (resultButton) =>
			{
				if (resultButton && resultButton.value)
				{
					this.changedData.pictureUrls.splice(this.mediaPlayer.photoIndex, 1);

					if (this.changedData.pictureUrls === 0)
					{
						if (this.mediaPlayer.isZoomed)
							this.mediaPlayer.OnCloseImageZoom();
						this.mediaPlayer.photoIndex = 0;
					}
					else
					{
						if (this.mediaPlayer.photoIndex >= this.changedData.pictureUrls)
							this.mediaPlayer.photoIndex = this.changedData.pictureUrls - 1;
					}

					// We also operate in "valueMode" but restore "this.data.activity.pictureUrls" on exit
					this.data.activity.pictureUrls = this.changedData.pictureUrls;
					this.forceUpdate();
				}
			});	
		}, 150);
	}

	OnAddPhoto = () =>
	{
		setTimeout(() => {
			AppState.instance.appContainer.PromptImage(this.UploadActivityImage);
		}, 150);
	}

	GetProgressItem(id)
	{
		for (var i = 0; i < this.progressItems.length; ++i)
		{
			if (this.progressItems[i].id === id)
			{
				return this.progressItems[i];
			}
		}
		return undefined;
	}

	RemoveProgressItem(id)
	{
		for (var i = 0; i < this.progressItems.length; ++i)
		{
			if (this.progressItems[i].id === id)
			{
				this.progressItems.splice(i, 1);
				break;
			}
		}
	}

	//TODO: merge with ActivityRenderer
	UploadActivityImage = (file) =>
	{
		var id;

		Utils.UploadActivityImage(file, this.data.activity.id, (uc) =>
		{
			console.log("upload finished:");
			console.log(uc);
			this.data.userContent.push(uc);
			this.changedData.pictureUrls.push(uc.url);
			this.data.contentIds.push(uc.id);

			this.data.activity.pictureUrls = this.changedData.pictureUrls;

			if (this.mediaPlayer)
			{
				// Scroll MediaPlayer to right to show new image
				this.mediaPlayer.GoToEnd();
			}

			var item = this.GetProgressItem(id);
			if (item)
			{
				item.label = Translate.T("en.Done", "de.Fertig", "photo.upload.done");

				setTimeout(() => {
					item.show = false; // This will fade out the progress item	
				}, 500);				
			}
		},

		//progress:
		(event) =>
		{
			var item = this.GetProgressItem(id);
			if (item)
			{
				const progress = event.loaded / event.total * 100;
				item.progress = progress;
			}
		},

		//on error
		(error) =>
		{
			console.log("Upload error:");
			console.log(error);

			var item = this.GetProgressItem(id);
			if (item)
			{
				//item.label = Translate.T("en.Error while uploading", "de.Fehler beim Upload", "photo.upload.error");
				item.label = JSON.stringify(error);
				item.error = true;
				this.forceUpdate();
			}
		},
	
		// onLocalImageData
		(dataUrl) =>
		{
			id = Utils.GenerateRandomStr(32);

			this.progressItems.push(
			{
				id: id,
				progress: 0,
				show: true,
				dataUrl: dataUrl,
				label: Translate.T("en.Uploading image", "de.Photo-Upload", "photo.upload.progress"),
				onDone: () =>
				{
					this.RemoveProgressItem(id);
					this.forceUpdate();
				},
				onRetry: () =>
				{
					setTimeout(() => {
						var item = this.GetProgressItem(id);
						if (item)
							item.show = false;
						this.UploadActivityImage(file);	
					}, 200);
				},
				onCancel: () =>
				{
					setTimeout(() => {
						var item = this.GetProgressItem(id);
						if (item)
						{
							setTimeout(() => {
								item.show = false;
							}, 100);
						}	
					}, 200);
				}
			});
		});
	}

	OnAfterMediaZoom = (zoomedIn) =>
	{
		this.forceUpdate();
	}

	GetCategories()
	{
		var allCats = AppState.instance.contentStore.GetAllCategoriesDirectly();

		allCats.sort(function(a, b) {
			return a.title.localeCompare(b.title);
		});

		return allCats;
	}

	render()
	{
		const { classes } = this.props;

		var selectedCategories = [];
		var remainingCategories = [];
		if (this.data)
		{
			var categories = this.GetCategories();

			if (this.data.categories)
				for (var i = 0; i < this.data.categories.length; ++i)
					selectedCategories.push(this.data.categories[i]);

			for (i = 0; i < categories.length; ++i)
			{
				const cn = categories[i].title;
				if (selectedCategories.indexOf(cn) < 0)
					remainingCategories.push(cn);
			}

			/*remainingCategories.sort(function(a, b)
			{
				if (a < b) return -1;
				if (a > b) return 1;
				return 0;
			});*/
		}

		var hasPictures = (this.changedData.pictureUrls && this.changedData.pictureUrls.length > 0);

		return (
			<div>
				{this.data && this.data.activity && <div className="editContainer">
					{this.props.showHeader && <div className="editIntro">
						Did you come across missing or incorrect information about {this.data.activity.name}? Please let us know using the form below.<br/>Thanks a lot for looking out!
					</div>}

					<div className="mediaPlayerWrapper">

						<MediaPlayer
							data={this.data}
							defaultContentHeight={Utils.GetMaxContentHeight()}
							onAfterZoom={this.OnAfterMediaZoom}
							containerClassname="editContainer"
							onInstance={this.OnMediaPlayerInstance}
						/>

						{hasPictures &&
							<Button className={"editRemovePictureBtn" + (this.mediaPlayer && this.mediaPlayer.isZoomed ? " zoomed":"")} onClick={this.RemovePicture}>
								<DeleteIcon />
							</Button>}

						<Button className={"editAddPictureBtn" + (this.mediaPlayer && this.mediaPlayer.isZoomed ? " zoomed":"")} onClick={this.OnAddPhoto}>
							<AddAPhoto />
						</Button>
					</div>

					{this.progressItems.length > 0 && <div className="progressItems">
						{this.progressItems.map((progressItem, index) =>
						{
							return (<ProgressDisplay data={progressItem} key={index} onDone={progressItem.onDone} isError={progressItem.error} />);
						})}
					</div>}

					<div className="editInnerContainer">

						{this.renderInput('name', 'Name')}

						{<div className="filterCategories">
							<div className="filterCategoryChips" onClick={() => this.showCategories = true}>
								{selectedCategories.map(catName =>
								{
									return (
										<Chip
											label={catName}
											key={catName}
											onDelete={this.HandleChipDelete(catName)}
											onClick={(e) => {e.stopPropagation();}}
										/>
									);
								})}

								{selectedCategories.length === 0 && <div className="activityCategoryNothingSelected">
									{Translate.T("en.Tap to select categories", "de.Antippen um Kategorien auszuwählen", "activity.categories.taptoselect")}
								</div>}

								{selectedCategories.length > 0 && <div className="activityCategoryMore">
									...
								</div>}
							</div>
						</div>}

						{this.renderBoolInput('strollerSuitable', Translate.T('en.Suitable with strollers', 'de.Kinderwagen geeignet', "activity.strollers"), true)}
						{this.renderBoolInput('accessible', Translate.T('en.Accessible', 'de.Rollstuhlgerecht', "activity.accessible"), true)}
						{this.renderBoolInput('dogsWelcome', Translate.T('en.Dogs welcome', 'de.Hunde sind willkommen', "activity.dogs"), true)}
						{this.renderBoolInput('bookingRequired', Translate.T('en.Booking required', 'de.Reservierung notwendig', "activity.booking"), true)}
						{this.renderBoolInput('carParking', Translate.T("en.Parking spot", "de.Parkplatz", "activity.carparking"), true)}

						{this.renderMultilineInput('desc', Translate.T('en.Description', 'de.Beschreibung', "activity.desc"), 'desc')}

						{this.props.showOpening !== false && this.renderMultilineInput('openingHours', Translate.T('en.Opening Hours', 'de.Öffnungszeiten', "activity.opening"), 'openingHours')}

						{this.renderMultilineInput('price', Translate.T('en.Pricing Information', 'de.Preisinformation', "activity.price"), 'price')}

						<div className="editContactTitle">{Translate.T("en.Contact", "de.Kontakt", "detail.contact.title")}</div>

						{this.renderInput('infoUrl', Translate.FindTranslation("activity.website"))}
						{this.renderInput('email', Translate.FindTranslation("activity.email"))}
						{this.renderInput('phone', Translate.FindTranslation("activity.phone"))}
						{this.renderInput('address', Translate.FindTranslation("activity.address"))}

						{this.data.activity && <Map id="newEntryMap" //gestureHandling="cooperative"
							forceInclude={[this.data.activity.id]}
							hideContentCard={[this.data.activity.id]}
							zoomFactor={13}
							centerLat={this.data.activity.latitude}
							centerLng={this.data.activity.longitude}
							showLocation={true}
							maxNumCategories={1}
							mapSimpleMode={true}
							loadOnVisibility={false}
							showTitle={false}
						/>}

						{this.props.showAge !== false && this.renderInput('ageFitMin', Translate.FindTranslation("ageFitMin"))}
						{this.props.showAge !== false && this.renderInput('ageFitMax', Translate.FindTranslation("ageFitMax"))}
						{this.props.showDuration !== false && this.renderInput('durationMin', Translate.FindTranslation("durationMin"))}
						{this.props.showDuration !== false && this.renderInput('durationMax', Translate.FindTranslation("durationMax"))}

						{this.props.showComment !== false && this.renderMultilineInput(null, Translate.T('en.Your personal comment', 'de.Dein persönlicher Kommentar', "activity.personalcomment"), 'comment')}
					</div>

					{this.props.showSubmit !== false && <div className="editSubmitButtonContainer">
						<Button raised color="primary" classes={{root: this.props.classes.button}} onClick={this.OnSubmitClicked}>
							{Translate.T('en.Submit', 'de.Senden', 'suggesteditpage.submit')}
    					</Button>
					</div>}
				</div>}

				<Snackbar
					open={this.displayError}
					autoHideDuration={5000}
					onClose={this.handleRequestErrorClose}
					SnackbarContentProps={{
						'aria-describedby': 'message-id',
					}}
					message={<span id="message-id">{this.errorMsg}</span>}
					action={[
						<IconButton
							key="close"
							aria-label="Close"
							color="inherit"
							className={classes.close}
							onClick={this.handleRequestErrorClose}
						>
							<CloseIcon />
						</IconButton>,
					]}
				/>

				<Dialog
					fullScreen={AppState.instance.deviceInfo.phone}
					open={this.showCategories}
					onClose={() => this.showCategories = false}
					className={"dlgCategorySelector" + (AppState.instance.deviceInfo.phone ? " phone": " nophone")}
				>
					<div className="activityCategoryListHeader">
						<IconButton
							key="close"
							onClick={() => this.showCategories = false}
						>
							<CloseIcon />
						</IconButton>
						<span>
							{Translate.T("en.Select Category", "de.Kategorie wählen", "dialog.categories.title")}
						</span>
					</div>
					<div className="activityCategoryList">
						<List>
							{remainingCategories.map((catName, index) =>
							{
								const catIcon = Utils.GetCategoryIconUrl(catName, "#aaa", "white", 24, 24, false);

								return (
									<ListItem key={index} button onClick={this.OnCategoryClicked(catName)}>
										{catIcon !== undefined && <img alt="" src={catIcon} style={{height: 24, width: 24}} /> }

										{/* {catDataId === ContentStore.BookmarkCategoryId && Utils.RenderStarIcon(24, 24, "#e31c46", "#e31c46", 2.5)}
										{catDataId === ContentStore.SessionBookmarkCategoryId && Utils.RenderStarIcon(24, 24, "#e31c46", "transparent", 2.5)} */}

										{catIcon === undefined && <span style={{height: 24, width: 24}} /> }

										<ListItemText primary={catName} />
									</ListItem>
								);
							})}
						</List>
					</div>
				</Dialog>

			</div>
		);
	}
}

ActivityEditor.propTypes = {
	classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(ActivityEditor);