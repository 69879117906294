import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import Utils from '../Utils';
import Rating from './Rating';
import {Link} from 'react-router-dom';
import AppState from '../AppState';
import Button from 'material-ui/Button';
import MediaPlayer from './MediaPlayer';
import Translate from '../Translate';
import AccountRequestPage from '../pages/AccountRequestPage';
import UserListSelection, {ListSelectionMenu} from './UserListSelection';
import PlaceIcon from 'material-ui-icons/Place';
import SocialButton from './SocialButton';

const styles = theme => ({
	root: {
	  backgroundColor: theme.palette.background.paper,
	},
  });

@observer
class ContentCard extends React.Component
{
	@observable showNewDialog = false;

	renderPlaceholder()
	{
		return <div>PLACEHOLDER</div>;
	}

	OnActionButtonClick = (event) =>
	{
		if (this.props.actionButton.onClick)
		{
			var sr = this.props.contentData;
			this.props.actionButton.onClick(sr.title);
		}
		event.stopPropagation();
	}

	OnCheckInClicked = (e) =>
	{
		e.stopPropagation();
		e.preventDefault();

		const listStatus = AppState.instance.listManager.GetContentStatus(this.props.contentData.id);
		if (!listStatus.checkedin)
			return;

		const checkInListId = listStatus.checkInListId;

		//TODO: merge with code from ActivityRenderer
		AppState.instance.listManager.RemoveFromList(checkInListId, this.props.contentData.id)
		.then(() =>
		{
			this.forceUpdate();
		});
	}

	OnHeartClicked = (e) =>
	{
		e.stopPropagation();
		e.preventDefault();

		//TODO: merge with code from ActivityRenderer

		const bookmarkedLists = AppState.instance.listManager.GetListsOfContent(this.props.contentData.id, true);
		if (bookmarkedLists && bookmarkedLists.length === 1)
		{
			// Currently bookmarked on just 1 list -> remove it
			this.OnSetUserList(bookmarkedLists[0].id);
			return;
		}

		if (!AppState.instance.IsLoggedIn())
		{
			var lists = AppState.instance.listManager.GetLists();
			if (lists.length === 1)
			{
				this.OnSetUserList(lists[0].id);
				return;
			}
		}

		AppState.instance.screenMessage.ShowDialog(undefined, ListSelectionMenu, undefined, undefined, undefined,
			{
				onNewList: this.OnNewList,
				onListSelected: this.OnSetUserList,
				contentId: this.props.contentData.id,
				title: Translate.T("en.Save in list", "de.In Liste speichern", "userlistdialog.saveentry.title")
			}, false, "dialogListSelectionButton", true);
	}

	OnSetUserListDlg = (listId) =>
	{
		if (!this.showNewDialog)
			return;

		this.OnSetUserList(listId);
	}

	OnSetUserList = (listId) =>
	{
		if (listId !== undefined)
		{
			if (AppState.instance.listManager.IsContentOnList(this.props.contentData.id, listId, true))
			{
				AppState.instance.listManager.RemoveFromList(listId, this.props.contentData.id)
					.then(() =>
					{
						this.forceUpdate();
					});
			}
			else
			{
				AppState.instance.listManager.AddToList(listId, this.props.contentData.id)
					.then(() =>
					{
						this.forceUpdate();
					});
			}
		}
	}

	OnNewList = () =>
	{
		const isLoggedIn = AppState.instance.IsLoggedIn();

		if (isLoggedIn)
		{
			this.showNewDialog = true;
		}
		else
		{
			AccountRequestPage.Show();
		}
	}

	OnNewDialogClose = () =>
	{
		this.showNewDialog = false;
	}

	OnContentLink = (e) =>
	{
		if (MediaPlayer.CheckAuthorLinkClick(e))
		{
			e.preventDefault();
			e.stopPropagation();
		}

		if (this.props.onClick)
		{
			this.props.onClick(e);
			if (e.defaultPrevented)
				return;
		}

		const isFilter = this.props.contentData.type === "filter";
		if (isFilter)
		{
			const parentFilterIds = this.props.categoryIds;
			if (parentFilterIds)
			{
				if (typeof parentFilterIds === 'string')
				{
					var parts = parentFilterIds.split('-');
					for (var i = 0; i < parts.length; ++i)
					{
						AppState.instance.mainPageInstance.PushAndScrollFilter(Number(parts[i]), false);
					}
				}
				else
				{
					AppState.instance.mainPageInstance.PushAndScrollFilter(parentFilterIds, false);
				}
			}
			AppState.instance.mainPageInstance.PushAndScrollFilter(this.props.contentData.id);
			e.preventDefault();
			e.stopPropagation();
			return;
		}

		if (this.props.onClickEnabled === false)
		{
			e.preventDefault();
			e.stopPropagation();
			return;
		}

		if (AppState.instance.isLandingPage && !AppState.instance.desktopAppInstance)
		{
			var sr = this.props.contentData;
			window.open(sr.url, "_blank");
			e.preventDefault();
			e.stopPropagation();
			return;
		}

		//HACK:
		//e.preventDefault();
		//e.stopPropagation();
		//AppState.instance.appContainer.GoTo(this.props.contentData.url, true);
	}

	render()
	{
		var sr = this.props.contentData;
		const columnMode = this.props.columnMode;

		var isPlaceholder = false;
		if (this.props.itemType === 'placeholder')
		{
			isPlaceholder = true;
			sr = {
				id: "",
				desc: "",
				title: "",
				categories: [],
				img: "/assets/placeholder.svg",
				distance: { distanceText: ""},
				address: "",
				rating: 0,
				url : "#"
			};
		}

		var isTiny = this.props.renderMode === "tiny";
		var isSuperTiny = this.props.renderMode === "supertiny";
		const isFilter = sr.type === "filter";

		const isProfile = sr.type === "profile";

		if (isProfile && !sr.img)
		{
			//sr.img = ""; //TODO: generate data url for ProfileIcon
		}

		var desc = sr.desc;
		if (desc === undefined || desc === null || desc === "")
			desc = "";// sr.title;

		//var maxDescLines = columnMode ? 2 : 3;
		//var maxDescLines = 3;

		// var additionalStyle = null;
		// if (columnMode)
		// {
		// 	additionalStyle = {'width': 'calc(100% - 48px)', 'max-width': '400px'};
		// }

		//const isPhone = AppState.instance.deviceInfo.phone;
		var descText = "";
		if (!isTiny && !isSuperTiny)
		{
			descText = desc;

			const startIdx = descText.indexOf('[backlink href=');
			if (startIdx >= 0)
			{
				const endIdx = descText.indexOf('/]');
				descText = Utils.CutStr(descText, startIdx, endIdx + 1);
			}

			if (columnMode)
				descText = Utils.TrimTextToMaxChars(descText, 115, '...');
			else
				descText = Utils.TrimTextToMaxChars(descText, 85, '...');

			// if (isPhone)
			// {
			// 	descText = desc;
			// 	if (descText && descText.length > 150)
			// 		descText = descText.substring(0, 150);
			// }
			// else
			// {
			// 	descText = Utils.TrimTextToNumLines(desc, "contentCardDesc" + (columnMode ? " contentCardDescColumn" : ""), maxDescLines, "...", additionalStyle);
			// }
			
			//if (descText.length > 85)
			//	descText = descText.substring(0, 82) + "...";
		}

		var titleText;
		//if (isPhone)
			titleText = sr.title;
		//else
		//	titleText = columnMode ? sr.title : Utils.TrimTextToWidth(sr.title, "contentCardTitle", 220, "...");
		
		var infoLine = "";
		const catNames = sr.categories;
		if (catNames !== undefined && catNames !== null)
		{
			for (var i = 0; i < catNames.length && i < 2; ++i)
			{
				if ((isSuperTiny || isTiny) && Utils.IsInvisibleTextCategory(catNames[i]))
					continue;
				if (catNames[i] === "FamilyFriendly" || (catNames[i] && catNames[i].startsWith("_")))
					continue;

				if (infoLine.length === 0)
					infoLine = catNames[i];
				else
					infoLine += " • " + catNames[i];

				if (this.props.maxNumCategories !== undefined)
					if (i === this.props.maxNumCategories - 1)
						break;
			}
		}

		const listStatus = AppState.instance.listManager.GetContentStatus(sr.id);
		const isBookmarked = listStatus.bookmarked;
		const isCheckedIn = listStatus.checkedin;

		//var fixedUrl = Utils.FixHttpImages(sr.img);
		//Utils.TestImageUrl(fixedUrl);

		var className = "contentCard";
		if (columnMode)
		{
			className += " contentCardColumn";
			if (this.props.isFirst)
				className += " contentCardColumnFirst";
			if (this.props.isLast)
				className += " contentCardColumnLast";
		}
		else
		{
			if (this.props.isFirst)
				className += " contentCardFirst";
			if (this.props.isLast)
				className += " contentCardLast";
		}

		// var hotData = {};
		// if (sr.liveData)
		// 	hotData = LiveData.GetHotData(sr.liveData);

		var mediaData = {};
		mediaData.activity = {};
		mediaData.activity.id = sr.id;
		mediaData.activity.pictureUrls = [sr.img];
		mediaData.liveData = sr.liveData;
		mediaData.userContent = sr.userContent;
		mediaData.categories = sr.categories;

		if (!sr.url && sr.name)
		{
			sr.url = "/activity/" + Utils.GetUrlName(sr.name);
		}

		//if (isFilter && sr.title && sr.url)
		//{
			// Adjust URL
			//var url = AppState.instance.filterManager.CreateFilterUrl(AppState.instance.filterStack);
			//console.log("_________________" + url);
			//console.log("_________________" + sr.title);
			//sr.url = "/";// url + "/";// + sr.title;// encodeURIComponent(sr.title);
			//sr.url = "/activity";
			//console.log("_________________" + sr.url);
		//}

		const bookmarkEnabled = this.props.bookmarkEnabled === false ? false : true;
		const bookmarkClickEnabled = this.props.enableBookmarkClick === false ? false : true;

		return (
			
			<div
				className={className}
				key={"contentCard_" + sr.id}
				id={"contentCard_" + sr.id}
				data-activityid={sr.id}
				/*onClick={this.OnCardClicked}*/
				onMouseOver={this.props.onMouseOver}
				onMouseOut={this.props.onMouseOut}
			>
				{this.props.actionButton && <div className="contentActionButton">
					<Button onClick={this.OnActionButtonClick}>
						{this.props.actionButton.icon}
					</Button>
				</div>}

			<Link
				to={sr.url}
				className="contentLink"
				onClick={this.OnContentLink}
			>
				<MediaPlayer data={mediaData} className={"contentImage" + (columnMode ? " contentImageColumn" : "")}
					interactive={false} disableAuthorLink={true}
					defaultContentHeight={this.props.defaultContentHeight || (columnMode ? 200 : 150)}
					enableContentAuthor={this.props.enableContentAuthor}
				/>
				{/* <div
					key={"contentImage_" + sr.id}
					className={"contentImage" + (columnMode ? " contentImageColumn" : "")}
					alt="TitleImage"
					style={{backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: 'url("' + fixedUrl + '")'}}
				>
					<div className="contentLiveData">
						{hotData.liveContent && <div className="contentLiveDataItem contentLiveDataLive"><Icon>videocam</Icon><span className="label">{Translate.T("en.LIVE", "de.LIVE", "content.indicator.live")}</span></div>}
						{hotData.newContent && <div className="contentLiveDataItem contentLiveDataNew"><Icon>videocam</Icon><span className="label">{Translate.T("en.NEW", "de.NEU", "content.indicator.fresh")}</span></div>}
						{(hotData.numCurrentViewers > 0 || hotData.numCurrentVisitors > 0) && <div className="contentLiveDataItem contentLiveDataViewers"><Icon>person</Icon><span className="label">{hotData.numCurrentViewers + hotData.numCurrentVisitors}</span></div>}
					</div>
				</div> */}

				{!isProfile && !isFilter && isCheckedIn && <div className="checkInIcon" onClick={this.OnCheckInClicked}>
					<PlaceIcon />
				</div>}

				{!isProfile && !isFilter && bookmarkEnabled && isBookmarked &&
					<div className="bookmarkHeartIcon" onClick={bookmarkClickEnabled ? this.OnHeartClicked : undefined}>
						{Utils.RenderStarIcon(24, 24, "white", "#e31c46", 2.5)}
					</div>}
				{/* {isSessionBookmarked && !isBookmarked && <div className="bookmarkHeartIcon" onClick={this.OnHeartClicked}>
					{Utils.RenderStarIcon(24, 24, "#e31c46", "transparent", 2.5)}
				</div>} */}
				{!isProfile && !isFilter && bookmarkEnabled && /*!isSessionBookmarked &&*/ !isBookmarked &&
					<div className="bookmarkHeartIcon" onClick={bookmarkClickEnabled ? this.OnHeartClicked : undefined}>
						{Utils.RenderStarIcon(24, 24, "rgba(255,255,255,0.5)", "transparent", 2.5)}
					</div>}

				<UserListSelection
					dialogMode={true}
					visible={this.showNewDialog}
					onClose={this.OnNewDialogClose}
					setUserList={this.OnSetUserListDlg}
				/>

				<div className="contentCardContent">
					<h3 className={"contentCardTitle" + (columnMode ? " contentCardTitleColumn" : "") + (isPlaceholder ? " contentCardTitlePH" : "")}>				
						{titleText}
					</h3>

					{!isSuperTiny && (this.props.showLocation === undefined || this.props.showLocation === true) &&
						<div className={"contentCardInfoLine" + (columnMode ? " contentCardInfoLineColumn" : "") + (isPlaceholder ? " contentCardInfoLinePH" : "")}>
							{sr.distance && sr.address && sr.distance.distanceText !== undefined && sr.distance.distanceText.length > 0 &&
								Utils.ExtractCity(sr.address) + " • " + sr.distance.distanceText}
						</div>}
					{isSuperTiny && this.props.showDistance &&
						<div className={"contentCardInfoLine" + (columnMode ? " contentCardInfoLineColumn" : "") + (isPlaceholder ? " contentCardInfoLinePH" : "")}>
							{sr.distance && sr.distance.distanceText !== undefined && sr.distance.distanceText.length > 0 &&
								sr.distance.distanceText}
						</div>}

					<div className={"contentCardInfoLine2" + (columnMode ? " contentCardInfoLineColumn2" : "") + (isPlaceholder ? " contentCardInfoLine2PH" : "")}>
						{!isSuperTiny && infoLine}
						{isSuperTiny && (infoLine + " • " + Utils.ExtractCity(sr.address))}
					</div>

					{!isProfile && isSuperTiny === false &&
						<div className={"contentCardRating" + (columnMode ? " contentCardRatingColumn" : "") + (isPlaceholder ? " contentCardRatingPH" : "")}>
							<Rating value={sr.rating}/>
						</div>}

					{/*!isProfile && isTiny === false && isSuperTiny === false &&
						<div className={"contentCardDesc" + (columnMode ? " contentCardDescColumn" : "") + (isPlaceholder ? " contentCardDescPH" : "")}>
							{descText}
					</div>*/}

					{isProfile &&
						<SocialButton username={sr.username}/>}
					
					{/*<div className={"contentCardDistance" + (columnMode ? " contentCardDistanceColumn" : "")}>
						{sr.distance.distanceText}
					</div>*/}

					{this.props.additionalCardContent}
				</div>
			</Link>
			</div>
		);
	}
}

ContentCard.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	contentData: PropTypes.object.isRequired,
	columnMode: PropTypes.bool.isRequired,
	isFirst: PropTypes.bool.isRequired,
	isLast: PropTypes.bool.isRequired,
	itemType: PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(ContentCard);