import React from 'react';
import Translate from '../../Translate';
import StepBase from './StepBase';
import TextField from 'material-ui/TextField';
import Button from 'material-ui/Button';

export default class StepQuestion extends StepBase
{
	GetId()
	{
		return "question";
	}

	GeneratedDataIds()
	{
		return ["question"];
	}

	Undo(contextData)
	{
		delete contextData["question"];
		delete contextData["question_temp"];
	}

	renderStepLena(contextData)
	{
		return this.renderLenaMsg(Translate.T("de.Welche Frage möchtest Du stellen?", "en.What would you like to ask?", "lena.askquestion"));
	}

	renderUserSelection(contextData)
	{
		var text = contextData["question"];
		if (!text)
			text = Translate.T("de.[Leer]", "en.[Empty]", "feedback.empty");
		return this.renderUserMsg(text, contextData);
	}

	OnTextChanged = (contextData) => (event) =>
	{
		contextData["question_temp"] = event.target.value;
		contextData.onChanged();
	}

	OnQuestionDone = (contextData) => () =>
	{
		const question = contextData["question_temp"];
		delete contextData["question_temp"];

		this.OnStepDone(contextData, {
			question: question,
		});
	}

	renderUserOptions(contextData)
	{
		var defaultText = contextData["question_temp"];
		if (!defaultText)
			defaultText = contextData["question"];
		const isEdit = this.IsCurrentlyEdited(contextData);

		return (
			<div className="stepQuestion">

				{isEdit && this.renderCancelEditButton(contextData)}

				<div className="desc">
					<TextField
						key={"descText"}
						multiline
						required={true}
						InputLabelProps={{
							shrink: true,
						}}
						placeholder={Translate.FindTranslation("lena.question.placeholder")}
						defaultValue={defaultText}
						fullWidth
						autoFocus={true}
						margin="dense"
						onChange={this.OnTextChanged(contextData)}
					/>
				</div>

				<div className="continue">
					<Button onClick={this.OnQuestionDone(contextData)}>
						{Translate.T("de.Weiter", "en.Next", "next")}
					</Button>
				</div>
			</div>
		);
	}
}
