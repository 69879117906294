import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import AppState from '../AppState';
import Translate from '../Translate';
//import {Link} from 'react-router-dom';
import Utils from '../Utils';
import PersonIcon from 'material-ui-icons/Person';
import MoreHoriz from 'material-ui-icons/MoreHoriz';
import Menu, { MenuItem } from 'material-ui/Menu';
import Button from 'material-ui/Button';
import raf from 'raf';
import { observable } from 'mobx';

const styles = theme => ({	
});

@observer
class FriendRequests extends React.Component
{
	@observable isLoading = false;
	@observable isMenuOpen = false;
	menuAnchor;

	componentDidMount()
	{
		AppState.instance.socialManager.onProfilesChanged.Add(this.OnProfilesChanged);
	}

	componentWillUnmount()
	{
		AppState.instance.socialManager.onProfilesChanged.Remove(this.OnProfilesChanged);
	}

	OnProfilesChanged = () =>
	{
		this.forceUpdate();
	}

	OnMenuRequestClose = () =>
	{
		this.isMenuOpen = false;
		this.menuAnchor = undefined;
	}

	OnMenuClicked = (e) =>
	{
		if (this.isLoading)
			return;

		e.stopPropagation();
		e.preventDefault();

		this.menuAnchor = e.currentTarget;
		raf(() => {
			this.isMenuOpen = true;
		});
	}

	OnConfirm = (id) => (e) =>
	{
		AppState.instance.socialManager.RequestReaction(id, true, false);
	}

	AddFamily = (id) => (e) =>
	{
		AppState.instance.socialManager.RequestReaction(id, true, true);
	}

	OnDelete = (id) => (e) =>
	{
		AppState.instance.socialManager.RequestReaction(id, false);
	}

	OnClicked = (username) => () =>
	{
		AppState.instance.appContainer.OnProfileClicked(username);
	}

	render()
	{
		var frs = AppState.instance.socialManager.GetPendingRequests();

		// online incoming requests
		for (var i = frs.length - 1; i >= 0; --i)
		{
			if (!frs[i].incoming)
				frs.splice(i, 1);
		}

		if (frs.length === 0)
			return null;

		return (
			<div className="friendRequests">
				<div className="friendRequestsTitle">
					{Translate.T("en.New Friend Requests", "de.Neue Freundschaftsanfragen", "friendrequests.title")}
				</div>

				<div className="friendRequestsList">
				{frs.map((fr, index) =>
				{
					const profile = AppState.instance.socialManager.GetProfile(fr.username);
					if (!profile)
						return null;

					var infoLine;
					if (profile.district)
						infoLine = profile.district;
					else if (profile.state)
						infoLine = profile.state;
					else if (profile.country)
						infoLine = profile.country;

					//infoLine = "Perchtoldsdorf";
					//const url = "/profile/" + fr.username;

					return (
						<div onClick={this.OnClicked(fr.username)} className="friendRequest" key={index}>

							{profile.profilePicture && <div
								className="friendRequestImage"
								style={{backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: 'url("' + Utils.FixHttpImages(profile.profilePicture) + '")'}}
							/>}
							{!profile.profilePicture && <div
								className="friendRequestImage emptyProfilePhoto"
								style={{backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: 'url("' + Utils.FixHttpImages(profile.profilePicture) + '")'}}
							>
								<PersonIcon />
							</div>}

							<div className="friendRequestRightCol">
								<div className="friendRequestTitle">{fr.displayname}</div>
								{infoLine !== undefined && <div className="friendRequestInfoline">{infoLine}</div>}

								<div className="friendRequestButtons">
									<Button className="friendRequestConfirm" onClick={this.OnConfirm(fr.id)}>
										{Translate.T("en.Confirm", "de.Bestätigen", "friendrequest.confirm")}
									</Button>

									<Button color="primary" onClick={this.OnMenuClicked} aria-label="menu-more" className="friendRequestMore">
										<MoreHoriz />
									</Button>

									<Button className="friendRequestDelete" onClick={this.OnDelete(fr.id)}>
										{Translate.T("en.Not Now", "de.Jetzt Nicht", "friendrequest.delete")}
									</Button>
								</div>

								<Menu
									id="socialMenu"
									anchorEl={this.menuAnchor}
									open={this.isMenuOpen}
									onClose={this.OnMenuRequestClose}
								>
									<MenuItem onClick={this.AddFamily(fr.id)}>{Translate.T("en.Confirm and add to Family", "de.Bestätigen und zu Familie hinzufügen", "friendrequest.button.addfamily")}</MenuItem>
									<MenuItem onClick={this.OnConfirm(fr.id)}>{Translate.T("en.Confirm and add to Friends", "de.Bestätigen und zu Freunden hinzufügen", "friendrequest.button.addfriend")}</MenuItem>
								</Menu>
							</div>
						</div>
					);
				})}
				</div>
			</div>
		);
	}
}

FriendRequests.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(FriendRequests);