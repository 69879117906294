import React from 'react';
import Utils from './Utils';
import RestApi from './RestApi'
import Tracking from './Tracking';

export default class ApiInterface extends React.Component
{
	static isConnected = false;
	static OnMessage = null;

	static isWaiting = false;
	static queue = [];

	static Init()
	{
	}

	static IsConnected()
	{
		return ApiInterface.isConnected;
	}

	//@param userProfile UserProfile
	//@return int accountId
	static RegisterUserProfile(userProfile)
	{
		RestApi.RegisterUserProfile(userProfile);
	}

	static Login(username, password)
	{
		RestApi.Login(username, password).then((result) =>
		{
			this.isConnected = true;
		});

		//var userName = userProfile.username + "@xmpp.lena.family";
		//this.props.appState.chat.login("https://xmpp.lena.family:5280/http-bind/", userName, userProfile.password, this.OnChatConnected);
	}

	static Logout()
	{
		RestApi.Logout();
	}

	static SendMessage(msg)
	{
		ApiInterface.queue.push(msg);
		this.ProcessQueue();
	}

	static IsQueueEmpty()
	{
		return ApiInterface.queue.length === 0;
	}

	static ProcessQueue()
	{
		if (ApiInterface.isWaiting)
			return;
		
		if (ApiInterface.queue.length === 0)
			return;

		ApiInterface.isWaiting = true;

		var qItem = ApiInterface.queue[0];
		ApiInterface.queue.splice(0, 1);
		
		RestApi.Lena(qItem).then((messages) =>
		{
			if (messages && ApiInterface.OnMessage !== null)
			{
				//console.log("=============================================");
				//console.log("sent to LENA: " + msg);

				var apiMessages = [];

				for (var i = 0; i < messages.length; ++i)
				{
					var msgBody = messages[i];
					var bodyParts = msgBody.split('|');
					var msgId = bodyParts[0];
					msgBody = msgBody.substring(msgId.length + 1);

					var refOrderId;
					if (msgId.indexOf("@") > 0)
					{
						var idParts = msgId.split('@');
						if (idParts.length === 2)
						{
							msgId = idParts[0];
							refOrderId = idParts[1];
						}
					}

					var apiMessage = {};
					apiMessage.id = Number(msgId);
					if (refOrderId !== undefined)
						apiMessage.refOrderId = Number(refOrderId);
	
					const cmd = Utils.DecodeCommand(msgBody);
					apiMessage.isCommand = cmd !== null;
					apiMessage.sender = "lena"; //TODO: for xmpp
					if (apiMessage.isCommand)
					{
						apiMessage.command = cmd.tag;
						apiMessage.message = cmd.body;
						//console.log("from LENA: CMD:" + cmd.tag);
					}
					else
					{
						apiMessage.message = msgBody;
						//console.log("from LENA: " + msgBody);
					}

					apiMessages.push(apiMessage);

					//console.log("          msg fragment:");
					//console.log(apiMessage);
				}

				if (apiMessages.length > 0)
					ApiInterface.OnMessage(apiMessages);

				//console.log("=============================================");
			}

			ApiInterface.isWaiting = false;
			ApiInterface.ProcessQueue();
		})
		.catch((error) =>
		{
			var qStr = "";
			if (qItem)
				qStr = JSON.stringify(qItem);
			Tracking.OnError("Error sending message to lena: " + error + ", qStr: " + qStr);
			ApiInterface.isWaiting = false;
			ApiInterface.ProcessQueue();
		});
	}

	static SendCommand(cmd, cmdBody)
	{
		//console.log("to LENA: " + cmd + " ; " + cmdBody);

		ApiInterface.SendMessage("[" + cmd + "]" + Utils.Base64Encode(cmdBody) + "[/" + cmd + "]");
	}
}
