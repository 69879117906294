import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import Translate from '../Translate';
import Utils from '../Utils';
import AppState from '../AppState';
import Button from 'material-ui/Button';
import AccountRequestPage from '../pages/AccountRequestPage';
import Tracking from '../Tracking';

const styles = theme => ({	
});

@observer
class LowAmountCard extends React.Component
{
	static OnThrow()
	{
		console.log("lowAmount throw");
	}

	componentDidMount()
	{
		Tracking.SendEvent("lowamountcard");
	}

	GetLocationStr()
	{
		var loc = AppState.instance.ipLocation;
		if (AppState.instance.GetGpsPosition())
			loc = AppState.instance.GetGpsPosition();
		if (AppState.instance.manualLocation)
			loc = AppState.instance.manualLocation;

		var locationStr;
		if (loc)
		{
			if (loc.title && !loc.address)
				loc.address = loc.title;
			locationStr = loc.address;
		}
		else
		{
			if (AppState.instance.userProfile.userLang === "de")
				locationStr = "deiner Nähe";
			else
				locationStr = "your area";
		}
		return locationStr;
	}

	OnRegisterClicked = () =>
	{
		const locationStr = this.GetLocationStr();
		const msg = Translate.T("en.Stay informed about LENA in {0}.", "de.Bleibe informiert über LENA in {0}.", "accountrequest.lowdata", [locationStr]);
		AccountRequestPage.Show(msg);
	}

	render()
	{
		const locationStr = this.GetLocationStr();

		const isLoggedIn = AppState.instance.IsLoggedIn();

		return (
			<div className="lowDataCard">

				<div className="lowDataHeader">
					<div className="intro_logocontainer">
						{Utils.RenderLogo(40, 40, "white", "#e31c46")}
					</div>

					<div className="lowDataIntro problem">
						{Translate.T("de.Oje - scheint als hätte ich im Moment in {0} nicht genug Vorschläge für Dich.",
							"en.Oh no - looks like I don't have enough suggestions in {0} for you yet.",
							"lowdata.problem",
							[locationStr])}
					</div>
				</div>

				<div className="lowDataBody">

					{isLoggedIn && <div className="lowDataIntro statement">
						{Translate.T("de.Du kannst aber gerne einen Blick auf die etwas weiter entfernten Vorschläge werfen. Vielleicht ist etwas dabei für Dich!",
						"en.Feel free to have a look at the options which are a little further away. You never know...", "lowdata.statement.registered")}
					</div>}

					{!isLoggedIn && <div className="lowDataIntro statement">
						{Translate.T("de.Soll ich Dich informieren, sobald ich Dir tolle Vorschläge in {0} bieten kann?",
							"en.Should I inform you as soon as I have great options for you in {0}?",
							"lowdata.statement",
							[locationStr])}
					</div>}

					{!isLoggedIn && <div className="lowDataButtonContainer">
						<Button onClick={this.OnRegisterClicked} className="lowDataRegisterBtn">
							{Translate.T("en.Register now", "de.Jetzt Registrieren", "lowdata")}
						</Button>
					</div>}

					{!isLoggedIn && <div className="lowDataIntro tip">
						{Translate.T("de.Tipp:\nDie Gebiete mit den meisten Registrierungen werde ich natürlich zuerst erschließen.",
							"en.Tip:\nRegions with the most registrations get priority treatment.",
							"lowdata.tip")}
					</div>}

					<div className="lowDataLena">
						LENA
					</div>
				</div>

			</div>
		);
	}
}

LowAmountCard.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(LowAmountCard);