import AppState from './AppState';
import ApiInterface from './ApiInterface';
import LowAmountCard from './components/LowAmountCard';
import Tutorial from './components/Tutorial';

export default class SwipeMessages
{
	messages = [];
	onMessagesChanged;
	customMsgId = -1;
	noMoreSuggestions = false;

	constructor()
	{
		this.OnMessage = this.OnMessage.bind(this);
		ApiInterface.OnMessage = this.OnMessage;
	}

	HasNoMoreSuggestions()
	{
		return this.noMoreSuggestions;
	}

	OnMessagesChanged(cb)
	{
		this.onMessagesChanged = cb;
	}

	OnAuthDone = () =>
	{
		if (window.location.pathname.indexOf("/profile/") >= 0)
		{
			// Don't overwrite the front card - it's a profile card
		}
		else
		{
			var fromLena = Tutorial.GetTutorialMessages();

			for (var i = 0; i < fromLena.length; ++i)
			{
				this.OnMessage([fromLena[i]]);
			}
		}

		if (!Tutorial.IsActive() && !AppState.instance.startedWithActivity)
			ApiInterface.SendCommand("action", "activity.start");
	}

	OnLocationChanged()
	{
		var removed = [];

		// Remove any "lowdata" message
		for (var i = this.messages.length - 1; i >= 0; --i)
		{
			if (this.messages[i].subType === "lowdata")
			{
				removed.push(this.messages[i]);
				this.messages.splice(i, 1);
			}
		}

		for (i = 0; i < removed.length; ++i)
		{
			if (removed[i].onRemoved)
				removed[i].onRemoved();
		}
	}

	OnMessage = (messages) =>
	{
		//console.log("received message from lena:");

		for (var i = messages.length - 1; i >= 0; --i)
		{
			//console.log(messages[i]);
			if (messages[i].isCommand)
			{
				var executed = this.ExecuteCommand(messages[i]);
				if (executed)
				{
					messages.splice(i, 1);
				}
			}
		}

		if (messages.length > 0)
		{
			var m = this.ConvertLenaToInternal(messages);
			if (m)
			{
				//console.log("======================= RECEIVED:");
				//console.log(m);

				var duplicate = false;
				if (m.type === "question")
				{
					const questionId = m.data.id;
					const openQ = this.FindOpenQuestion(questionId);
					if (openQ)
					{
						// The server sent us the same question again because we didn't answer yet
						duplicate = true;
					}
				}

				if (!duplicate)
				{
					this.messages.push(m);
				}
			}
		}

		if (this.onMessagesChanged)
			this.onMessagesChanged();

		this.CheckLoadMore();
	}

	FindOpenQuestion(questionId)
	{
		for (var i = 0; i < this.messages.length; ++i)
		{
			const msg = this.messages[i];
			if (msg.type === "question" && msg.data.id === questionId)
				return msg;
		}
		return undefined;
	}

	CheckLoadMore()
	{
		const numOpen = this.GetNumOpenCards();
		const shouldAutoload = this.ShouldAutoLoad();
		const isEmpty = ApiInterface.IsQueueEmpty();
		//console.log("-----------CHECK AUTOLOAD -----------");
		//console.log("numOpen: " + numOpen + ", should: " + shouldAutoload + ", isEmpty: " + isEmpty + ", noMoreSugg: " + this.noMoreSuggestions);
		if (!this.noMoreSuggestions && numOpen < 2 && shouldAutoload && isEmpty)
		{
			//console.log(" --> autoloading");
			ApiInterface.SendCommand("action", "activity.suggest");
		}
	}

	GetNumOpenCards()
	{
		return this.messages.length;
	}
	
	ShouldAutoLoad()
	{
		if (Tutorial.IsActive())
			return false;
		if (!AppState.instance.GeneralDataLoadingEnabled())
			return false;

		for (var i = 0; i < this.messages.length; ++i)
		{
			const msg = this.messages[i];
			//console.log("  msg.type: " + msg.type);
			if (msg.type === "question")
				return false;
		}
		return true;
	}

	ExecuteCommand(msg)
	{
		if (msg.command === "clearcards")
		{
			//console.log("executing clearcards");

			this.ClearAllMessages();
			return true;
		}
		return false;
	}

	ClearTutorialMessages()
	{
		var removed = [];
		for (var i = this.messages.length - 1; i >= 0; --i)
		{
			if (this.messages[i].isTutorial === true)
			{
				removed.push(this.messages[i]);
				this.messages.splice(i, 1);
			}
		}

		for (i = 0; i < removed.length; ++i)
		{
			if (removed[i].onRemoved)
				removed[i].onRemoved();
		}

		if (removed.length > 0 && this.onMessagesChanged)
			this.onMessagesChanged();
	}

	ClearAllMessages()
	{
		var removed = [];

		// Clear all messages except a few special cases
		for (var i = this.messages.length - 1; i >= 0; --i)
		{
			if (this.messages[i].type !== "customComponent" &&
				this.messages[i].isTutorial !== true &&
				this.messages[i].sticky !== true)
			{
				removed.push(this.messages[i]);
				this.messages.splice(i, 1);
			}
		}

		for (i = 0; i < removed.length; ++i)
		{
			if (removed[i].onRemoved)
				removed[i].onRemoved();
		}

		if (this.onMessagesChanged)
			this.onMessagesChanged();
	}

	AddMessage(componentClass, props, onThrow, atFront, sticky, onRemoved)
	{
		var m = {
			type: "customComponent",
			id: this.customMsgId--,
			data: {
				componentClass: componentClass,
				props: props,
				onThrow: onThrow
			},
			sticky: sticky,
			onRemoved: onRemoved
		};

		if (atFront)
			this.messages.unshift(m);
		else
			this.messages.push(m);
			
		if (this.onMessagesChanged)
			this.onMessagesChanged();
	}

	AddActivityMessageDetails(details, atFront, sticky, onRemoved)
	{
		var m = {
			type: "activity",
			id: this.customMsgId--,
			data: details,
			sticky: sticky,
			onRemoved: onRemoved
		};

		if (atFront)
			this.messages.unshift(m);
		else
			this.messages.push(m);
			
		if (this.onMessagesChanged)
			this.onMessagesChanged();

		return new Promise((resolve, reject) => { return resolve(m); });
	}

	AddActivityMessage(activityId, atFront, sticky, onRemoved)
	{
		return AppState.instance.contentStore.GetActivityDetailsById(activityId)
			.then((details) =>
			{
				if (details)
				{
					//console.log("received details for activity card: " + activityId);
					this.AddActivityMessageDetails(details, atFront, sticky, onRemoved);
				}
				else
				{
					return new Promise((resolve, reject) => { return reject(undefined); });
				}
			})
			.catch((error) =>
			{
				return new Promise((resolve, reject) => { return reject(error); });
			});
	}

	RemoveMessage(id)
	{
		for (var i = 0; i < this.messages.length; ++i)
		{
			const msg = this.messages[i];
			if (msg.id === id)
			{
				this.messages.splice(i, 1);

				if (msg.onRemoved)
					msg.onRemoved();
				break;
			}
		}

		if (this.onMessagesChanged)
			this.onMessagesChanged();
	}

	GetMessage(id)
	{
		for (var i = 0; i < this.messages.length; ++i)
		{
			const msg = this.messages[i];
			if (msg.id === id)
			{
				return this.messages[i];
			}
		}
		return undefined;
	}

	ConvertLenaToInternal(messages)
	{
		var result;

		for (var i = 0; i < messages.length; ++i)
		{
			const msg = messages[i];

			if (msg.isCommand === true)
			{
				if (msg.command.startsWith("activity.suggestion"))
				{
					result = {
						type: "activity",
						id: msg.id,
						refOrderId: msg.refOrderId,
						data: JSON.parse(msg.message),
						isTutorial: msg.isTutorial
					};

					if (result.data.length === 1)
						result.data = result.data[0];

					AppState.instance.contentStore.PrepareActivityDetails(result.data);
					AppState.instance.contentStore.SetActivityDetails(result.data.activity.name, result.data);

					break;
				}
				else if (msg.command === "ui.showcontrols")
				{
					if (msg.message.startsWith("question."))
					{
						var qJson = msg.message.substring(9); // remove "question."

						result = {
							type: "question",
							id: msg.id,
							refOrderId: msg.refOrderId,
							data: JSON.parse(qJson)
						};
						break;
					}
					if (msg.message.startsWith("ad."))
					{
						var aJson = msg.message.substring(3); // remove "ad."
						var adData = JSON.parse(aJson);
						AppState.instance.imageManager.Store(adData.imageData);

						result = {
							type: "ad",
							id: msg.id,
							refOrderId: msg.refOrderId,
							data: adData
						};
						break;
					}
				}
				else if (msg.command === "lowdata")
				{
					result = {
						type: "customComponent",
						subType: "lowdata",
						id: this.customMsgId--,
						data: {
							componentClass: LowAmountCard,
							props: undefined,
							onThrow: LowAmountCard.OnThrow
						}
					};
					break;
				}
				else if (msg.command === "empty")
				{
					this.noMoreSuggestions = true;
					if (this.onMessagesChanged)
						this.onMessagesChanged();
					break;
				}
			}
		}

		return result;
	}
}