import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import RestApi from '../RestApi.js';
import Tracking from '../Tracking';
import Dialog from 'material-ui/Dialog';
import IconButton from 'material-ui/IconButton';
import CloseIcon from 'material-ui-icons/Close';
import BackIcon from 'material-ui-icons/KeyboardArrowLeft';
import ForwardIcon from 'material-ui-icons/KeyboardArrowRight';
import CheckIcon from 'material-ui-icons/Check';
import {primaryColorLena} from '../colors';
import Translate from '../Translate';
import LoginAction from '../utils/LoginAction';
import AppState from '../AppState';
import {ToSPage, PPPage} from './WebContentPage';
import Utils from '../Utils';
import Button from 'material-ui/Button';
import SwipeableViews from 'react-swipeable-views';
import InputTextField from '../components/InputTextField';
import { FormControlLabel } from 'material-ui/Form';
import Checkbox from 'material-ui/Checkbox';
import $ from 'jquery';
import {CircularProgress} from 'material-ui/Progress';

const styles = {
	paper: {
		backgroundColor: primaryColorLena[500],
		/*paddingLeft: '24px',
		paddingRight: '24px',*/
	},
	paperXL: {
		backgroundColor: primaryColorLena[500],
		/*paddingLeft: '24px',
		paddingRight: '24px',*/
		minWidth: '400px',
		height: '600px',
		overflow: 'hidden'
	},
	closeButtonRoot: {
		width: '32px',
		height: '32px',
		marginTop: '16px',
		position: 'absolute',
		left: '16px'
	},
	checked: {
		color: 'white !important',
	},
};

@observer
class AccountRequestPage extends React.Component
{
	static instance = null;
	message;
	@observable dialogOpen = false;
	animateTransition = true;
	@observable step = 0;
	@observable approval0 = false;
	@observable approval1 = false;

	@observable isCompleteNameValid = false;
	@observable isEmailValid = false;
	@observable isPasswordValid = false;
	@observable highlightApproval0 = false;
	@observable highlightEmail = false;
	@observable highlightPassword = false;
	@observable highlightFirstName = false;

	@observable showLoginProgress = false;
	@observable showLoginError = false;
	@observable showVerificationError = false;
	@observable showSuccess = false;
	errorType;

	firstName;
	lastName;
	email;
	password;

	passwordResetToken;
	isSingleLogin = false;
	trackingEvent;

	constructor(props)
	{
		super(props);

		AccountRequestPage.instance = this;
	}

	HandleRequestClose = () =>
	{
		if (this.showLoginProgress)
			return;

		if (this.step === 0 || this.step === 6 || this.isSingleLogin)
		{
			LoginAction.Clear();

			this.dialogOpen = false;
			this.forceUpdate();
		}
		else if (this.step === 4 || this.step === 7)
		{
			this.animateTransition = false;
			this.step = 0;
			this.Reset();
		}
		else
		{
			this.animateTransition = true;
			this.step--;

			if (this.step === 0)
				this.Reset();
		}
	};

	Reset()
	{
		this.step = 0;
		
		this.firstName = undefined;
		this.lastName = undefined;
		this.isCompleteNameValid = false;

		this.email = false;
		this.isEmailValid = false;
		this.isPasswordValid = false;
		this.highlightApproval0 = false;
		this.highlightEmail = false;
		this.highlightPassword = false;
		this.highlightFirstName = false;
		this.approval0 = false;
		this.approval1 = false;
		this.showLoginError = false;
		this.showVerificationError = false;
		this.showLoginProgress = false;
		this.passwordResetToken = undefined;
		this.showSuccess = false;
		this.trackingEvent = undefined;
	}

	static Show(msg, returnAction, returnUrl, trackingEvent)
	{
		LoginAction.Set(returnAction, returnUrl);

		AccountRequestPage.instance.Reset();
		AccountRequestPage.instance.message = msg;
		AccountRequestPage.instance.dialogOpen = true;
		AccountRequestPage.instance.trackingEvent = trackingEvent;

		Tracking.OnPageView("accountrequest");
	}

	static ShowPasswordChange(token)
	{
		AccountRequestPage.instance.Reset();
		AccountRequestPage.instance.message = undefined;
		AccountRequestPage.instance.passwordResetToken = token;
		AccountRequestPage.instance.step = 6;
		AccountRequestPage.instance.dialogOpen = true;

		Tracking.OnPageView("passwordchange");
	}

	static ShowLogin(msg)
	{
		AccountRequestPage.instance.Reset();
		AccountRequestPage.instance.message = msg;
		AccountRequestPage.instance.isSingleLogin = true;
		AccountRequestPage.instance.step = 4;
		AccountRequestPage.instance.dialogOpen = true;
		Tracking.OnPageView("login");
	}

	OnToSClicked = () =>
	{
		AppState.instance.screenMessage.ShowDialog(Translate.T('en.Terms of Service', 'de.Nutzungsbedingungen', 'tos'), ToSPage);
	}

	OnPPClicked = () =>
	{
		AppState.instance.screenMessage.ShowDialog(Translate.T('en.Privacy Policy', 'de.Datenschutzbestimmungen', 'privacypolicy'), PPPage);
	}

	OnFacebookAuth = () =>
	{
		if (this.showLoginProgress)
			return;

		if (!this.approval0)
		{
			this.highlightApproval0 = true;
		}

		if (this.approval0)
		{
			this.showLoginProgress = true;

			if (this.approval1)
				Utils.SetLocalStorage("subscribenewsletterafterauth", "1");

			//TODO: send event only after user returns from facebook and has logged in
			this.OnAccountRegistered('Facebook');

			Tracking.OnPageView("accountregister/facebook");

			var fbUrl;
			if (AppState.instance.isProduction)
				fbUrl = "/auth/facebook";
			else
				fbUrl = RestApi.GetApiUrl() + "/auth/facebook";
			
			document.location.href = fbUrl;

			// Store approvals
			// RestApi.SendRequestText("/beforefacebookauth",
			// {
			// 	approval0: this.approval0,
			// 	approval1: this.approval1,
			// })
			// .then((dataStr) =>
			// {
			// 	if (dataStr === "ok")
			// 	{
			// 		const fbUrl = RestApi.GetApiUrl() + "/auth/facebook";
			// 		document.location.href = fbUrl;	
			// 	}
			// 	else
			// 	{
			// 		this.errorType = undefined;
			// 		this.OnRegisterError();
			// 	}
			// })
			// .catch((error) =>
			// {
			// 	this.errorType = "network";
			// 	this.OnRegisterError();
			// });			
		}
	}

	OnCreateAccount = () =>
	{
		this.animateTransition = true;
		this.step = 1;
		$(".nameContainer #arFirstName").focus();

		Tracking.OnPageView("accountrequest/create");
	}

	OnContinueWithFacebook = () =>
	{
		this.animateTransition = false;
		this.step = 7;
		Tracking.OnPageView("accountrequest/facebook");
	}

	renderIntro()
	{
		return (
			<div className="stepContainer">

				<div className="accountRequestLogo">
					{Utils.RenderLogo(48, 48, "white", "transparent")}
				</div>
				{/* <img key="2" className="accountRequestLogo" src="/assets/logons128.png" alt="brand logo" /> */}

				<div key="3" className="accountRequestMsg">
					{Translate.T("en.Welcome to LENA.", "de.Willkommen bei LENA.", "accountrequest.welcome")}
				</div>

				{this.message && <div key="msg" className="accountRequestCustomMsg">
					{this.message}
				</div>}

				<div className="centerInParent">
					<Button className="accountRequestCreateButton" onClick={this.OnCreateAccount}>
						<div key="label" className="accountRequestCreateLabel">
							{Translate.T("en.Create account", "de.Erstelle Account", "accountrequest.create.account")}
						</div>
					</Button>

					<Button className="accountRequestFacebookButton" onClick={this.OnContinueWithFacebook}>
						<img key="img" className="accountRequestFacebookLogo" src="/assets/facebook.svg" alt="facebook logo" />
						<div key="label" className="accountRequestFacebookButtonLabel">
							{Translate.T("en.Continue with Facebook", "de.Weiter mit Facebook", "accountrequest.continue.facebook")}
						</div>
					</Button>
					
				</div>

				{/* <div className="accountRequestGradient" /> */}

				<div key="5" className="accountRequestDisclaimer">
					{Translate.T("en.Please have a look at LENA's",
						"de.Bitte wirf einen Blick auf die", "accountrequest.disclaimer.part1")} <span className="accountRequestLink"
						onClick={this.OnToSClicked}>
						{Translate.T('en.Terms of Service', 'de.Nutzungsbedingungen', 'tos')}		
					</span> {Translate.T("en.and", "de.und", "accountrequest.disclaimer.part2")} <span className="accountRequestLink"
						onClick={this.OnPPClicked}>
						{Translate.T('en.Privacy Policy', 'de.Datenschutzbestimmungen', 'privacypolicy')}
					</span>{Translate.T("en..", "de..", "accountrequest.disclaimer.part3")}
				</div>
			</div>
		);
	}

	OnValidateName = (name) =>
	{
		return name && name.length >= 2 && name.length <= 50;
	}

	OnValidateEmail = (email) =>
	{
		return Utils.ValidateEmail(email);
	}

	OnValidatePassword = (password) =>
	{
		return password && password.length >= 1;
	}

	OnValidateSetPassword = (password) =>
	{
		if (!password)
			return false;

		if (password.length < 6)
			return false;

		const symbols = "`~!@#$%^&*()_-+={}[]\\|:;\"'<>,.?/";

		for (var i = 0; i < password.length; ++i)
		{
			const c = password.charAt(i);
			if (symbols.indexOf(c) >= 0)
				return true;
		}
		return false;
	}

	ValidateName()
	{
		this.isCompleteNameValid =
			this.firstName
			//&& this.lastName
			&& this.firstName.length >= 2
			//&& this.lastName.length >= 3
		;
	}

	ValidateEmail()
	{
		this.isEmailValid = Utils.ValidateEmail(this.email);
	}

	ValidatePassword()
	{
		this.isPasswordValid = this.OnValidatePassword(this.password);
	}

	ValidateSetPassword()
	{
		this.isPasswordValid = this.OnValidateSetPassword(this.password);
	}

	OnNameSubmit = () =>
	{
		this.ValidateName();

		if (this.isCompleteNameValid)
		{
			this.animateTransition = true;
			this.step++;
			$(".registerEmailContainer #arEmail").focus();

			Tracking.OnPageView("accountrequest/email_tos_newsletter");
		}
		else
		{
			this.highlightFirstName = true;
		}
	}

	OnRegisterAccount = () =>
	{
		if (this.showLoginProgress)
			return;

		this.ValidateEmail();

		if (!this.approval0)
		{
			this.highlightApproval0 = true;
		}

		if (!this.isEmailValid)
		{
			this.highlightEmail = true;
		}

		if (this.isEmailValid && this.approval0)
		{
			this.showLoginProgress = true;

			Tracking.OnPageView("accountregister/credentials");

			RestApi.SendRequestText("/appaccount",
			{
				firstName: this.firstName,
				lastName: this.lastName,
				//password: this.password,
				email: this.email,
				approval0: this.approval0,
				approval1: this.approval1,
				language: AppState.instance.userProfile.userLang,
			})
			.then((dataStr) =>
			{
				this.errorType = undefined;

				if (dataStr && dataStr.startsWith("{"))
				{
					const data = JSON.parse(dataStr);
					if (data.isLoggedIn)
					{
						this.OnRegisterSuccess(data);
					}
					else
					{
						this.OnRegisterError();	
					}
				}
				else if (dataStr && dataStr.length > 0)
				{
					console.log("register error: " + dataStr);
					this.errorType = dataStr;
					this.OnRegisterError();
				}
				else
				{
					this.OnRegisterError();
				}
			})
			.catch((error) =>
			{
				this.errorType = "network";
				this.OnRegisterError();
			});

			this.OnAccountRegistered('Email');
		}
	}

	OnAccountRegistered(label)
	{
		var sendSignupEvent = true;

		if (this.trackingEvent)
		{
			if (this.trackingEvent.category === "Signup")
				sendSignupEvent = false;

			Tracking.SendGoogleEvent(this.trackingEvent.category, this.trackingEvent.action, label || this.trackingEvent.label, this.trackingEvent.value);
		}
		
		if (sendSignupEvent)
		{
			var eventPostfix = "_Mobile";
			if (AppState.instance.deviceInfo.desktop)
				eventPostfix = "_Desktop";
			Tracking.SendGoogleEvent('Signup', 'Signup_B2C' + eventPostfix);
		}
	}

	OnRegisterSuccess(data)
	{
		AppState.instance.userProfile.SetLoginData(data);
		AppState.instance.userProfile.username = data.username;
		LoginAction.OnAfterLogin();

		AppState.instance.userProfile.Save();
		AppState.instance.loginDataChecked = true;
		AppState.instance.appContainer.OnAuthDone();

		// show welcome screen
		this.showLoginProgress = false;
		this.animateTransition = true;
		this.step++;

		Tracking.OnPageView("accountregistered/welcome");
	}

	OnRegisterError()
	{
		this.showLoginProgress = false;
		this.showLoginError = true;
		Tracking.OnPageView("accountrequest/error");
	}

	OnLoginSubmit = () =>
	{
		if (this.showLoginProgress)
			return;

		this.ValidateEmail();
		this.ValidatePassword();

		if (!this.isEmailValid)
		{
			this.highlightEmail = true;
		}

		if (!this.isPasswordValid)
		{
			this.highlightPassword = true;
		}

		if (this.isEmailValid && this.isPasswordValid)
		{
			//TODO: login on backend, display progress and than transition to MainPage
			this.showLoginProgress = true;

			Tracking.OnPageView("accountrequest/login");

			RestApi.SendRequest("/auth/credentials", 
			{
				username: this.email,
				password: this.password,
				rememberMe: true
			})
			.then((sessionInfo) => {

				console.log(sessionInfo);
				// {displayName, sessionId, userId, userName, bearerToken}

				if (sessionInfo)
				{
					this.OnLoginSuccess();
				}
				else
				{
					this.OnLoginError();
				}
			})
			.catch((error) =>
			{
				if (error === 4010)
					this.OnVerificationTimeoutError();
				else
					this.OnLoginError();
			});
		}
	}

	OnVerificationTimeoutError()
	{
		this.showLoginProgress = false;
		this.showVerificationError = true;

		Tracking.OnPageView("accountrequest/login_error_verification_timeout");
	}

	OnLoginError = () =>
	{
		this.showLoginProgress = false;
		this.showLoginError = true;

		Tracking.OnPageView("accountrequest/login_error");
	}

	OnLoginSuccess = () =>
	{
		Tracking.OnPageView("accountrequest/login_success");

		RestApi.GetLoginData().then((data) =>
		{
			if (data.isLoggedIn)
			{
				console.log("Auth 4 credentials");
				AppState.instance.userProfile.SetLoginData(data);
				AppState.instance.userProfile.username = data.username;
				LoginAction.OnAfterLogin();

				AppState.instance.userProfile.Save();
				AppState.instance.loginDataChecked = true;
				AppState.instance.appContainer.OnAuthDone();

				//AppState.instance.appInstance.OnTabbarChanged(undefined, AppState.TabIndexProfile);
				//TODO:
				if (AppState.instance.appInstance)
					AppState.instance.appInstance.OnTabbarChanged(undefined, AppState.TabIndexSwipe);
				this.dialogOpen = false;
				this.showLoginProgress = false;

				if (AppState.instance.deviceInfo.desktop)
				{
					window.location.reload();
				}
			}
		});
	}

	OnEmailFieldEnter = () =>
	{
		this.ValidateEmail();
		this.ValidatePassword();

		if (this.isPasswordValid && this.isEmailValid)
		{
			this.OnLoginSubmit();
		}
		else if (!this.isPasswordValid)
		{
			$(".loginContainer #arPassword").focus();
		}
	}

	OnEmailForgotPasswordEnter = () =>
	{
		this.ValidateEmail();

		if (this.isEmailValid)
		{
			this.OnForgotPasswordEmailSubmit();
		}
		else
		{
			this.highlightEmail = true;
		}
	}

	OnPasswordFieldEnter = () =>
	{
		this.ValidateEmail();
		this.ValidatePassword();

		if (this.isPasswordValid && this.isEmailValid)
		{
			this.OnLoginSubmit();
		}
		else if (!this.isEmailValid)
		{
			$(".loginContainer #arEmail").focus();
		}
	}

	OnSetPasswordFieldEnter = () =>
	{
		this.ValidateSetPassword();

		if (this.isPasswordValid)
		{
			this.OnSetPasswordSubmit();
		}
		else
		{
			this.highlightPassword = true;
		}
	}

	OnWelcomeSubmit = () =>
	{
		LoginAction.Clear();

		this.dialogOpen = false;
		this.forceUpdate();
	}

	OnLogin = () =>
	{
		this.email = undefined;
		this.isEmailValid = false;

		this.password = undefined;
		this.isPasswordValid = false;
		this.animateTransition = false;
		this.step = 4;

		Tracking.OnPageView("accountrequest/show_login");
	}

	OnForgotPassword = () =>
	{
		if (this.showLoginProgress)
			return;

		//if (this.email && this.email.length > 0)
		//	$(".forgotpasswordContainer #arEmail").val(this.email);
		
		this.isEmailValid = false;
		this.animateTransition = true;
		this.email = undefined;

		this.step = 5;

		Tracking.OnPageView("accountrequest/show_forgotpassword");
	}

	OnForgotPasswordEmailSubmit = () =>
	{
		this.ValidateEmail();

		if (!this.isEmailValid)
		{
			this.highlightEmail = true;
		}

		if (this.isEmailValid)
		{
			Tracking.OnPageView("resetpassword");

			RestApi.SendRequest("/resetpassword", 
			{
				email: this.email,
			});

			AppState.instance.screenMessage.ShowAlert(Translate.T("de.Email gesendet", "en.Email sent", "accountrequest.forgotpassword.alert.header"),
				Translate.T("de.Folge den Anweisungen in der Email um Dein Passwort zurückzusetzen",
					"en.Follow the instructions in the email to reset your password", "accountrequest.forgotpassword.alert.text"),
				[{label: "Ok", value: 1}], false, (resultButton) =>
			{
				$(".loginContainer #arEmail").val(this.email);

				this.password = undefined;
				this.isPasswordValid = false;
				this.animateTransition = false;
				this.isSingleLogin = false;
				this.message = undefined;
				this.step = 4;
			});
		}
	}

	OnFirstNameChanged = (newValue, event) =>
	{
		this.highlightFirstName = false;
		this.firstName = newValue;
		this.ValidateName();
	}

	OnFirstNameEnter = () =>
	{
		this.ValidateName();

		if (!this.lastName)
		{
			$(".nameContainer #arLastName").focus();
		}
		else if (this.isCompleteNameValid)
		{
			this.OnNameSubmit();
		}
	}

	OnLastNameChanged = (newValue, event) =>
	{
		this.lastName = newValue;
		this.ValidateName();
	}

	OnLastNameEnter = () =>
	{
		this.ValidateName();
		if (this.isCompleteNameValid)
		{
			this.OnNameSubmit();
		}
		else
		{
			$(".nameContainer #arFirstName").focus();
		}
	}

	OnEmailChanged = (newValue, event) =>
	{
		this.highlightEmail = false;
		this.showLoginError = false;
		this.showVerificationError = false;
		this.email = newValue;
		this.ValidateEmail();
	}

	OnRegisterEmailEnter = () =>
	{
		this.ValidateEmail();

		if (this.isEmailValid)
		{
			$(".registerEmailContainer #arEmail").blur();
		}
		else
		{
			this.highlightEmail = true;
		}

		if (!this.approval0)
		{
			this.highlightApproval0 = true;
		}
	}

	OnPasswordChanged = (newValue, event) =>
	{
		this.highlightPassword = false;
		this.showLoginError = false;
		this.showVerificationError = false;
		this.password = newValue;
		this.ValidatePassword();
	}

	OnSetPasswordChanged = (newValue, event) =>
	{
		this.highlightPassword = false;
		this.showLoginError = false;
		this.showVerificationError = false;
		this.password = newValue;
		this.ValidateSetPassword();
	}

	OnSetPasswordSubmit = () =>
	{
		this.ValidateSetPassword();

		if (!this.isPasswordValid)
		{
			this.highlightPassword = true;
		}
		else
		{
			Tracking.OnPageView("accountrequest/resetpassword");

			this.showLoginProgress = true;
			RestApi.SendRequestText("/resetpassword", 
			{
				token: this.passwordResetToken,
				password: this.password
			})
			.then((responseText) =>
			{
				if (responseText && responseText === "ok")
				{
					this.showLoginProgress = false;
					this.showSuccess = true;
					this.password = undefined;
					this.isPasswordValid = false;
					setTimeout(() =>
					{
						AppState.instance.appContainer.GoTo("/");
						
						if (this.passwordResetToken)
						{
							this.passwordResetToken = undefined;
							this.OnLoginSuccess();
						}
						else
						{
							this.HandleRequestClose();
						}
					}, 800);
				}
				else
				{
					this.showLoginProgress = false;
					this.showLoginError = true;
				}
			})
			.catch((error) =>
			{
				this.showLoginProgress = false;
				this.showLoginError = true;
			});
		}
	}

	renderCreateAccount()
	{
		return (
			<div className="stepContainer nameContainer">

				<div key="3" className="accountRequestMsg">
					{Translate.T("en.What's your name?", "de.Wie ist Dein Name?", "accountrequest.createaccount.header")}
				</div>

				<div className="centerInParent">

				<InputTextField
					className={"arFirstName" + (this.highlightFirstName ? " highlight" : "") + " accountRequestInput"}
					onChange={this.OnFirstNameChanged}
					onEnter={this.OnFirstNameEnter}
					onValidate={this.OnValidateName}
					id="arFirstName"
					label={Translate.T("en.First Name", "de.Vorname", "accountrequest.label.firstname")}
				/>

				<InputTextField
					className="arLastName accountRequestInput"
					onChange={this.OnLastNameChanged}
					onEnter={this.OnLastNameEnter}
					onValidate={this.OnValidateName}
					id="arLastName"
					label={Translate.T("en.Last Name", "de.Nachname", "accountrequest.label.lastname")}
				/>
				
				<div className="arFormSubmit">
					<Button className={this.isCompleteNameValid ? "enabled" : "disabled"} onClick={this.OnNameSubmit}>
						<ForwardIcon />
					</Button>
				</div>
					
				</div>
			</div>
		);
	}

	OnOpenPP = (e) =>
	{
		e.stopPropagation();
		e.preventDefault();

		const label = Translate.T('en.Privacy Policy', 'de.Datenschutzbestimmungen', 'privacypolicy');
		AppState.instance.screenMessage.ShowDialog(label, PPPage);
	}

	OnOpenToS = (e) =>
	{
		e.stopPropagation();
		e.preventDefault();

		const label = Translate.T('en.Terms of Service', 'de.Nutzungsbedingungen', 'tos');
		AppState.instance.screenMessage.ShowDialog(label, ToSPage);
	}

	OnGoToForgotPasswort = (e) =>
	{
		e.stopPropagation();
		e.preventDefault();

		this.showLoginError = false;
		this.animateTransition = false;
		this.showVerificationError = false;
		this.step = 5;
	}

	renderCreateAccountEmail()
	{
		const label0DE = <span>Ja, ich stimme den <span className="arLinkText" onClick={this.OnOpenPP}>Datenschutz-</span> und <span className="arLinkText" onClick={this.OnOpenToS}>Nutzungsbedingungen</span> zu.</span>;
		const label0EN = <span>Yes, I agree with the <span className="arLinkText" onClick={this.OnOpenPP}>Privacy Policy</span> and the <span className="arLinkText" onClick={this.OnOpenToS}>Terms of Service</span>.</span>;

		var label0;
		if (AppState.instance.userProfile.userLang === "de")
		{
			label0 = label0DE;
		}
		else
		{
			label0 = label0EN;
		}

		return (
			<div className="stepContainer registerEmailContainer">

				<div key="3" className="accountRequestMsg">
					{Translate.T("en.And, your email?", "de.Und Deine Email?", "accountrequest.createaccountemail.header")}
				</div>

				<div className="centerInParent">

					<InputTextField
						className={"arEmail" + (this.highlightEmail ? " highlight" : "") + " accountRequestInput"}
						onChange={this.OnEmailChanged}
						onEnter={this.OnRegisterEmailEnter}
						onValidate={this.OnValidateEmail}
						id="arEmail"
						label={Translate.T("en.Email Address", "de.Email Adresse", "accountrequest.label.email")}
						disabled={this.showLoginProgress}
						email={true}
					/>

					<div className="arCheckboxes">
						<FormControlLabel
							control={
								<Checkbox
									disabled={this.showLoginProgress}
									checked={this.approval0}
									onChange={(event) => {this.approval0 = event.target.checked; this.highlightApproval0 = false; }}
									value="checkedApproval0"
									classes={{
										checked: this.props.classes.checked,
									}}
									className={"approval0" + (this.highlightApproval0 ? " highlight":"")}
								/>
							}
							label={label0}
						/>

						<FormControlLabel
							control={
								<Checkbox
									disabled={this.showLoginProgress}
									checked={this.approval1}
									onChange={(event) => {this.approval1 = event.target.checked;}}
									value="checkedApproval1"
									classes={{
										checked: this.props.classes.checked,
									}}
									className="approval1"
								/>
							}
							label={Translate.T("de.Ja, ich stimme dem Erhalt des Newsletters per Email zu. Ich kann diese Einwilligungen jederzeit in den Profil-Einstellungen widerrufen.",
								"en.Yes, I would like to receive the newsletter via email. I can revoke my agreements at anytime using the settings menu.", "accountrequest.newsletter.agreement")}
						/>
					</div>
				
					<div className={"arFormSubmit"}>
						<Button className={(this.isEmailValid && this.approval0 ? "enabled" : "disabled") +
							(this.showLoginProgress ? " inprogress":"")} onClick={this.OnRegisterAccount}>
							<ForwardIcon />
						</Button>

						{this.showLoginProgress && <div className="arLoginProgress">
							<CircularProgress/>
						</div>}

						{this.showLoginError && <div className="arLoginError">
							<div className="arLoginErrorHeader">
								{Translate.T("de.Fehler", "en.Error", "accountrequest.register.error.header")}
							</div>
							<div className="arLoginErrorText">
								{(this.errorType === undefined || this.errorType === "network") && Translate.T("de.Beim Registrieren der Email Addresse ist etwas schiefgelaufen. Versuche es nochmal.",
									"en.Registering this email address didn't work out. Please try again.", "accountrequest.register.error.general")}
								{(this.errorType === "language" || this.errorType === "firstname" || this.errorType === "lastname" || this.errorType === "email") && Translate.T("de.Die angegebenen Daten scheinen ungültig zu sein. Versuche es nochmal.",
									"en.The provided data doesn't look right. Please try again.", "accountrequest.register.error.name")}
								{this.errorType === "emailtaken" && <span>{Translate.T("de.Diese Email-Adresse ist bereits vergeben.",
									"en.This email address is already taken.", "accountrequest.register.error.emailtaken")}
									<span className="registererrortextlink" onClick={this.OnGoToForgotPasswort}>
										{Translate.T("de.Passwort vergessen?", "en.Forgot password?", "accountrequest.register.error.emailtaken.link")}
									</span>
								</span>}
							</div>
							<Button onClick={() => {this.showLoginError = false;}}>
								<CloseIcon />
							</Button>
						</div>}
					</div>
				</div>
			</div>
		);
	}

	OnSetPassword = () =>
	{
		this.animateTransition = false;
		this.step = 6;
	}

	renderWelcome()
	{
		return (
			<div className="stepContainer">

				<div key="3" className="accountRequestMsg">
					{Translate.T("en.Welcome, {0}!", "de.Willkommen, {0}", "accountrequest.welcome.header", [this.firstName])}
				</div>

				<div className="centerInParent">

					<div className="arWelcomeText">
						{Translate.T(
							"en.We've sent you a verification email. Please approve your email within 24 hours.\n\n" +
							"Feel free to use LENA without a password on this device. Please set a password if you'ld like to log in on another device.",
							
							"de.Wir haben Dir eine Verifikations-Email geschickt. Bitte bestätige Deine Email-Adresse in den nächsten 24 Stunden.\n\n" +
							"Auf diesem Gerät kannst du LENA ganz einfach ohne Passwort verwenden. Wenn Du Dich auch mit einem anderen Gerät in Deinen Account einloggen möchtest, "+
							"setze bitte in den Profil-Einstellungen ein Passwort.", "accountrequest.welcome.desc")}
					</div>

					<div className="arSetPassword">
						<Button onClick={this.OnSetPassword}>
							{Translate.T("en.Set password now", "de.Passwort jetzt setzen", "accountrequest.setpassword")}
						</Button>
					</div>

					<div className="arWelcomeText2">
						{Translate.T("en.Have fun!", "de.Viel Spaß!", "accountrequest.welcome.desc2")}
					</div>
				
					<div className={"arFormSubmit"}>
						<Button onClick={this.OnWelcomeSubmit}>
							<ForwardIcon />
						</Button>
					</div>
					
				</div>
			</div>
		);
	}

	renderLogin()
	{
		return (
			<div className="stepContainer loginContainer">

				{this.isSingleLogin && this.message !== undefined && <div className="arSingleLoginMessage">
					{this.message}
				</div>}

				<div key="3" className="accountRequestMsg">
					{Translate.T("en.Log in", "de.Einloggen", "accountrequest.login.header")}
				</div>

				<div className="centerInParent">

					<InputTextField
						className={"arEmail" + (this.highlightEmail ? " highlight" : "") + " accountRequestInput"}
						onChange={this.OnEmailChanged}
						onEnter={this.OnEmailFieldEnter}
						onValidate={this.OnValidateEmail}
						id="arEmail"
						label={Translate.T("en.Email Address", "de.Email Adresse", "accountrequest.label.email")}
						disabled={this.showLoginProgress}
						email={true}
					/>

					<InputTextField
						className={"arPassword" + (this.highlightPassword ? " highlight" : "") + " accountRequestInput"}
						onChange={this.OnPasswordChanged}
						onEnter={this.OnPasswordFieldEnter}
						onValidate={this.OnValidatePassword}
						id="arPassword"
						label={Translate.T("en.Password", "de.Passwort", "accountrequest.label.password")}
						password={true}
						disabled={this.showLoginProgress}
					/>

					<div className={"arFormSubmit"}>
						<Button className={(this.isEmailValid && this.isPasswordValid ? "enabled" : "disabled") +
							(this.showLoginProgress ? " inprogress":"")} onClick={this.OnLoginSubmit}>
							<ForwardIcon />
						</Button>

						{this.showLoginProgress && <div className="arLoginProgress">
							<CircularProgress/>
						</div>}

						{this.showLoginError && <div className="arLoginError">
							<div className="arLoginErrorHeader">
								{Translate.T("de.Fehler", "en.Error", "accountrequest.login.error.header")}
							</div>
							<div className="arLoginErrorText">
								{Translate.T("de.Diese Daten scheinen nicht zu stimmen. Versuche es nochmal.",
									"en.Those credentials don't look right. Please try again.", "accountrequest.login.error.text")}
							</div>
							<Button onClick={() => {this.showLoginError = false;}}>
								<CloseIcon />
							</Button>
						</div>}

						{this.showVerificationError && <div className="arLoginError">
							<div className="arLoginErrorHeader">
								{Translate.T("de.Fehler", "en.Error", "accountrequest.register.error.header")}
							</div>
							<div className="arLoginErrorText">
								{<span>{Translate.T("de.Diese Email-Adresse wurde noch nicht verifiziert. Bitte setze Dein Passwort zurück um diese Email-Adresse zu verifizieren!",
									"en.This email address has not been verified yet. Please reset your password to verify this email address!", "accountrequest.login.error.verificationtimeout")}
									<div className="arLoginVerificationTimeout">
										<Button className="arLoginVerificationTimeoutBtn" onClick={this.OnGoToForgotPasswort}>
											{Translate.T("de.Password zurücksetzen", "en.Reset password", "accountrequest.login.error.resetpassword")}
										</Button>
									</div>
								</span>}
							</div>
							<Button onClick={() => {this.showVerificationError = false;}}>
								<CloseIcon />
							</Button>
						</div>}
					</div>
					
				</div>
			</div>
		);
	}

	renderForgotPassword()
	{
		return (
			<div className="stepContainer forgotpasswordContainer">

				<div key="3" className="accountRequestMsg">
					{Translate.T("en.Forgot your password?", "de.Passwort vergessen?", "accountrequest.forgotpassword.header")}
				</div>

				<div className="centerInParent">

					<div className="arForgotPasswordLine">
						{Translate.T("en.Enter your email to reset your password.", "de.Gib Deine Email-Adresse ein um Dein Passwort zurückzusetzen.")}
					</div>

					<InputTextField
						className={"arEmail" + (this.highlightEmail ? " highlight" : "") + " accountRequestInput"}
						onChange={this.OnEmailChanged}
						onEnter={this.OnEmailForgotPasswordEnter}
						onValidate={this.OnValidateEmail}
						id="arEmail"
						label={Translate.T("en.Email Address", "de.Email Adresse", "accountrequest.label.email")}
						disabled={this.showLoginProgress}
						email={true}
					/>
				
					<div className={"arFormSubmit"}>
						<Button className={(this.isEmailValid ? "enabled" : "disabled") +
							(this.showLoginProgress ? " inprogress":"")} onClick={this.OnForgotPasswordEmailSubmit}>
							<ForwardIcon />
						</Button>
					</div>
					
				</div>
			</div>
		);
	}

	renderSetPassword()
	{
		return (
			<div className="stepContainer setpasswordContainer">

				<div key="3" className="accountRequestMsg">
					{this.passwordResetToken !== undefined && Translate.T("en.Create a password", "de.Passwort setzen", "accountrequest.resetpassword.header")}
					{this.passwordResetToken === undefined && Translate.T("en.Change password", "de.Passwort ändern", "accountrequest.setpassword.header")}
				</div>

				<div className="centerInParent">

					<div className="arSetPasswordLine">
						{Translate.T("en.Your password must include at least one symbol and be 6 or more characters long. Examples for symbols: ~!@#$%*-+=:",
							"de.Dein Passwort muss mindestens 1 Symbol enthalten und 6 oder mehr Zeichen lang sein. Beispiele für Symbole: ~!@#$%*-+=:")}
					</div>

					<InputTextField
						className={"arPassword" + (this.highlightPassword ? " highlight" : "") + " accountRequestInput"}
						onChange={this.OnSetPasswordChanged}
						onEnter={this.OnSetPasswordFieldEnter}
						onValidate={this.showSuccess ? undefined : this.OnValidateSetPassword}
						id="arPassword"
						label={Translate.T("en.Password", "de.Passwort", "accountrequest.label.password")}
						password={true}
						disabled={this.showLoginProgress || this.showSuccess}
					/>
				
					<div className={"arFormSubmit"}>
						<Button className={(this.isPasswordValid ? "enabled" : "disabled") +
							(this.showSuccess ? " success":"") +
							(this.showLoginProgress ? " inprogress":"")} onClick={this.OnSetPasswordSubmit}>
							{!this.showSuccess && <ForwardIcon />}
							{this.showSuccess && <div className="arSuccess">
								<CheckIcon />
							</div>}
						</Button>

						{this.showLoginProgress && <div className="arLoginProgress">
							<CircularProgress/>
						</div>}

						{this.showLoginError && <div className="arLoginError">
							<div className="arLoginErrorHeader">
								{Translate.T("de.Fehler", "en.Error", "accountrequest.login.error.header")}
							</div>
							<div className="arLoginErrorText">
								{Translate.T("de.Das Setzen des Passworts hat nicht geklappt. Versuche es nochmal.",
									"en.Something went wrong. Please try again.", "accountrequest.setpassword.error.text")}
							</div>
							<Button onClick={() => {this.showLoginError = false;}}>
								<CloseIcon />
							</Button>
						</div>}
					</div>
					
				</div>
			</div>
		);
	}

	renderFacebook()
	{
		const label0DE = <span>Ja, ich stimme den <span className="arLinkText" onClick={this.OnOpenPP}>Datenschutz-</span> und <span className="arLinkText" onClick={this.OnOpenToS}>Nutzungsbedingungen</span> zu.</span>;
		const label0EN = <span>Yes, I agree with the <span className="arLinkText" onClick={this.OnOpenPP}>Privacy Policy</span> and the <span className="arLinkText" onClick={this.OnOpenToS}>Terms of Service</span>.</span>;

		var label0;
		if (AppState.instance.userProfile.userLang === "de")
		{
			label0 = label0DE;
		}
		else
		{
			label0 = label0EN;
		}

		return (
			<div className="stepContainer registerEmailContainer">

				<div key="3" className="accountRequestMsg">
					{Translate.T("en.Before that...", "de.Zuvor noch...", "accountrequest.continuefacebook.header")}
				</div>

				<div className="centerInParent">

					<div className="arCheckboxes">
						<FormControlLabel
							control={
								<Checkbox
									disabled={this.showLoginProgress}
									checked={this.approval0}
									onChange={(event) => {this.approval0 = event.target.checked; this.highlightApproval0 = false; }}
									value="checkedApproval0"
									classes={{
										checked: this.props.classes.checked,
									}}
									className={"approval0" + (this.highlightApproval0 ? " highlight":"")}
								/>
							}
							label={label0}
						/>

						<FormControlLabel
							control={
								<Checkbox
									disabled={this.showLoginProgress}
									checked={this.approval1}
									onChange={(event) => {this.approval1 = event.target.checked;}}
									value="checkedApproval1"
									classes={{
										checked: this.props.classes.checked,
									}}
									className="approval1"
								/>
							}
							label={Translate.T("de.Ja, ich stimme dem Erhalt des Newsletters per Email zu. Ich kann diese Einwilligungen jederzeit in den Profil-Einstellungen widerrufen.",
								"en.Yes, I would like to receive the newsletter via email. I can revoke my agreements at anytime using the settings menu.", "accountrequest.newsletter.agreement")}
						/>
					</div>
				
					<div className={"arFormSubmit"}>
						<Button className={(this.approval0 ? "enabled" : "disabled") +
							(this.showLoginProgress ? " inprogress":"")} onClick={this.OnFacebookAuth}>
							<ForwardIcon />
						</Button>

						{this.showLoginProgress && <div className="arLoginProgress">
							<CircularProgress/>
						</div>}
					</div>
					
				</div>
			</div>
		);
	}

	render()
	{
		//Tracking.OnRender("AccountRequestPage");

		var fullScreen = true;
		if (AppState.instance.deviceInfo.desktop ||
			AppState.instance.deviceInfo.tablet)
			fullScreen = false;

		return (
			<Dialog
				fullScreen={fullScreen}
				open={this.dialogOpen}
				onClose={this.HandleRequestClose}
				classes={{
			        paper: !fullScreen ? this.props.classes.paperXL : this.props.classes.paper,
				}}
				className={AppState.instance.isLandingPage ? "accountRequestPageDialogLP" : ""}
			>
				<div className={"accountRequestBackground step" + this.step + (this.showLoginError ? " error":"")}>
					<div className="accountRequestHeaderBar">
						{!this.showLoginProgress && <IconButton color="contrast" classes={{root: this.props.classes.closeButtonRoot}}
							onClick={this.HandleRequestClose} aria-label="Close">

							{(this.step === 0 || this.step === 6 || this.isSingleLogin) && <CloseIcon />}
							{this.step > 0 && this.step !== 3 && this.step !== 6 && !this.isSingleLogin &&
								<BackIcon className="accountRequestBackIcon" />}
						</IconButton>}

						{this.step === 0 && <Button className="accountRequestLoginButton" onClick={this.OnLogin}>
							<div key="label" className="accountRequestLoginLabel">
								{Translate.T("en.Log in", "de.Einloggen", "accountrequest.login")}
							</div>
						</Button>}

						{this.step === 4 && !this.showLoginProgress && <Button className="accountRequestForgotPassword" onClick={this.OnForgotPassword}>
							<div key="label" className="accountRequestLoginLabel">
								{Translate.T("en.Forgot password", "de.Passwort vergessen", "accountrequest.forgotpasswordbutton")}
							</div>
						</Button>}
					</div>

					<SwipeableViews
						axis={'x'}
						index={this.step}
						disabled={true}
						animateTransitions={this.animateTransition}
						className="accountRequestSwipeView"
					>
					
						{this.renderIntro()}

						{this.renderCreateAccount()}

						{this.renderCreateAccountEmail()}

						{this.renderWelcome()}

						{this.renderLogin()}

						{this.renderForgotPassword()}

						{this.renderSetPassword()}

						{this.renderFacebook()}

					</SwipeableViews>
				</div>
			</Dialog>
		);
	}
}

AccountRequestPage.propTypes = {
	classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(AccountRequestPage);