import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import RestApi from '../RestApi';
import Translate from '../Translate';
import Tracking from '../Tracking';
import Dialog from 'material-ui/Dialog';
import IconButton from 'material-ui/IconButton';
import CloseIcon from 'material-ui-icons/Close';
import Button from 'material-ui/Button';
import AppBar from 'material-ui/AppBar';
import Toolbar from 'material-ui/Toolbar';
import Typography from 'material-ui/Typography';
import Switch from 'material-ui/Switch';
import TextField from 'material-ui/TextField';
import Snackbar from 'material-ui/Snackbar';
import AppState from '../AppState';
import AccountRequestPage from './AccountRequestPage';
import Rating from '../components/Rating';
import ExpandMoreIcon from 'material-ui-icons/ExpandMore';
import Utils from '../Utils';
import ExpansionPanel, {
	ExpansionPanelSummary,
	ExpansionPanelDetails,
} from 'material-ui/ExpansionPanel';
import { FormControlLabel, FormGroup } from 'material-ui/Form';

const styles = {
	appBar: {
		position: 'relative',
	},
	closeButtonRoot: {
		width: '24px',
		height: '24px',
	},
	dialogTitle: {
		marginLeft: '14px',
	},
	button: {
	},
	expansion: {
		marginLeft: '-24px',
		marginRight: '-24px',
		boxShadow: 'none'
	},
	expansionSummary: {
		color: '#999',
		fontSize: 16
	},
	anonLabel: {
		color: '#999',
		fontSize: 16,
		fontWeight: 300
	},
};

@observer
class AddReviewPage extends React.Component
{
	static instance = null;

	data;
	changedData = {};
	lastChanged;
	reportQuery = {};
	@observable dialogOpen = false;
	@observable displayError = false;
	errorMsg;
	@observable rating = 5;
	@observable postAnonymous = false;
	text;
	textForHost;
	isEditMode = false;
	editIndex;
	onSubmit;

	constructor(props)
	{
		super(props);

		AddReviewPage.instance = this;
	}

	handleRequestClose = () =>
	{
		this.dialogOpen = false;
	}

	static Show(data, onSubmit)
	{
		Tracking.OnPageView("addreview");
		if (AppState.instance.IsLoggedIn())
		{
			AddReviewPage.instance.data = data;
			AddReviewPage.instance.onSubmit = onSubmit;
			AddReviewPage.instance.Reset();
			AddReviewPage.instance.dialogOpen = true;
		}
		else
		{
			var url = encodeURI("/activity/" + Utils.GetUrlName(data.activity.name) + "#addreview");
			
			AccountRequestPage.Show(Translate.FindTranslation("accountrequest.addreview"), undefined, url);
		}
	}

	Reset()
	{
		this.rating = 5;
		this.text = undefined;
		this.textForHost = undefined;
		this.postAnonymous = false;
		this.isEditMode = false;

		for (var i = 0; i < this.data.reviews.length; ++i)
		{
			if (this.data.reviews[i].isOwnReview)
			{
				this.isEditMode = true;
				this.editIndex = i;

				this.rating = this.data.reviews[i].data.rating;
				this.text = this.data.reviews[i].data.text;
				this.textForHost = this.data.reviews[i].data.textForHost;
				this.postAnonymous = this.data.reviews[i].data.anonymous;

				break;
			}
		}
	}

	OnTextChanged = (event) =>
	{
		this.text = event.target.value;
	}

	OnTextForHostChanged = (event) =>
	{
		this.textForHost = event.target.value;
	}

	OnDelete = () =>
	{
		var query = {};
		query.activityId = this.data.activity.id;

		RestApi.SendRequestText("/deleteuserreview", query).then( (result) =>
		{
			AppState.instance.contentStore.ClearActivityDetails(this.data.activity.name);

			if (this.onSubmit)
				this.onSubmit();
			this.dialogOpen = false;

			AppState.instance.screenMessage.Show("Deine Bewertung wurde gelöscht.");
		})
		.catch((error) =>
		{
			this.errorMsg = error;
			this.displayError = true;
		});
	}

	OnSubmit = () =>
	{
		var query = {};
		query.activityId = this.data.activity.id;
		query.rating = this.rating;
		query.text = this.text;
		query.textForHost = this.textForHost;
		query.anonymous = this.postAnonymous;

		var path = "/reviews";
		if (this.isEditMode)
			path = "/edituserreview";

		RestApi.SendRequest(path, query).then( (resultReview) =>
		{
			AppState.instance.contentStore.ClearActivityDetails(this.data.activity.name);

			if (this.onSubmit)
				this.onSubmit();
			this.dialogOpen = false;

			AppState.instance.screenMessage.Show("Vielen Dank für Deine Bewertung!")
		})
		.catch((error) =>
		{
			this.errorMsg = error;
			this.displayError = true;
		});
	}

	handleRequestErrorClose = () =>
	{
		this.displayError = false;
	};

	OnStarClick = (nextValue, prevValue, name) =>
	{
		this.rating = nextValue;
	}

	render()
	{
		Tracking.OnRender("AddReviewPage");

		if (this.dialogOpen === false)
			return null;

		const { classes } = this.props;

		//const Transition = props => <Slide direction="up" {...props} />

		return (
			<Dialog
				fullScreen
				open={this.dialogOpen}
				onClose={this.handleRequestClose}
				/*transition={Transition}*/
			>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton color="contrast" onClick={this.handleRequestClose} aria-label="Close"
							classes={{root: classes.closeButtonRoot}}
						>
							<CloseIcon />
						</IconButton>
						{!this.isEditMode && <Typography type="title" color="inherit" className={classes.dialogTitle}>
							{Translate.T("en.Add Review", "de.Deine Bewertung", "addreviewpage.title")}
						</Typography>}
						{this.isEditMode && <Typography type="title" color="inherit" className={classes.dialogTitle}>
							{Translate.T("en.Edit Review", "de.Bewertung editieren", "addreviewpage.edittitle")}
						</Typography>}
					</Toolbar>
				</AppBar>

				<div className="editContainer addReviewContainer">
					<div className="addReviewIntro">
						{Translate.T("en.What do you think about ", "de.Was denkst Du über ", "addreviewpage.intro.pre")}
						<span className="addReviewIntroActivity">{this.data.activity.name}</span>
						{Translate.T("en.? Help others by letting them know.", "de.? Hilf anderen Familien in dem Du eine Bewertung abgibst.", "addreviewpage.intro.post")}
					</div>

					<div className="addReviewRating">
						<Rating
							value={this.rating}
							editing={true}
							onStarClick={this.OnStarClick}
							starColor={'#e31c46'}
						/>
					</div>

					<div className={"editInputContainer"}>
						<TextField
							key={"inputText"}
							multiline
							InputLabelProps={{
								shrink: true,
							}}
							placeholder={"Dein Kommentar"}
							defaultValue={this.text}
							fullWidth
							margin="dense"
							onChange={this.OnTextChanged}
						/>
					</div>

					<div className={"editInputContainer"}>

						<ExpansionPanel className={classes.expansion}>
							<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
								{Translate.T("en.Private message for host", "de.Private Nachricht an Betreiber", "addreview.privatemessage")}
							</ExpansionPanelSummary>
							<ExpansionPanelDetails>
								<TextField
									key={"inputTextForHost"}
									multiline
									rows={3}
									InputLabelProps={{
										shrink: true,
									}}
									placeholder={"Deinen Kommentar sieht nur der Betreiber von " + this.data.activity.name}
									defaultValue={this.textForHost}
									fullWidth
									margin="dense"
									onChange={this.OnTextForHostChanged}
								/>
							</ExpansionPanelDetails>
						</ExpansionPanel>
					</div>

					<FormGroup>
						<FormControlLabel
							control={
								<Switch
									checked={this.postAnonymous}
									onChange={(event, checked) => { this.postAnonymous = checked; }}
									classes={{root: classes.expansionSummary}}
								/>
							}
							label="Anonym schreiben"
							classes={{label: classes.anonLabel}}
						/>
					</FormGroup>

					<div className="editSubmitButtonContainer">
						{this.isEditMode && <Button raised onClick={this.OnDelete}>
							{Translate.T('en.Delete review', 'de.Bewertung löschen', 'suggesteditpage.delete')}
    					</Button>}
						<Button raised color="primary" classes={{root: this.props.classes.button}} onClick={this.OnSubmit}>
							{Translate.T('en.Submit', 'de.Senden', 'suggesteditpage.submit')}
    					</Button>
					</div>
				</div>

				<Snackbar
					open={this.displayError}
					autoHideDuration={5000}
					onClose={this.handleRequestErrorClose}
					/*transition={<Slide direction={'up'} />}*/
					SnackbarContentProps={{
						'aria-describedby': 'message-id',
					}}
					message={<span id="message-id">{this.errorMsg}</span>}
					action={[
						<IconButton
							key="close"
							aria-label="Close"
							color="inherit"
							className={classes.close}
							onClick={this.handleRequestErrorClose}
						>
							<CloseIcon />
						</IconButton>,
					]}
					/>

			</Dialog>
		);
	}
}

AddReviewPage.propTypes = {
	classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(AddReviewPage);