import AppState from './AppState';
import Delegate from './utils/Delegate';
import RestApi from './RestApi';
import Utils from './Utils';
import CanvasImageUploader from './utils/CanvasImageUploader';
import $ from 'jquery';
import Tutorial from './components/Tutorial';

export default class AdvManager
{
	config = {};
	instances = {};
	nearbyLocations = [];
	processedNearbyLocations = [];

	onConfigChanged = new Delegate();
	onInstancesChanged = new Delegate();
	onNearbyLocationsChanged = new Delegate();
	onUnprocessedCardInstances = new Delegate();
	hasGeoLocation;
	hasIpLocation;

	enabled = true;

	static StoryFragmentTypeDefault = 0;
	static StoryFragmentTypeTeaser = 1; // ~140 character desc of adventure
	static StoryFragmentTypeIntro = 2; // when starting the adv
	static StoryFragmentTypeOutro = 3; // when the adv is done
	static StoryFragmentTypeLocationReveal = 4; // when a location has been reached
	static StoryFragmentTypeCardReveal = 5; // when a card is revealed
	static StoryFragmentTypeKeyImage = 6;
	static StoryFragmentTypeParentDesc = 7;

	static StoryActionTypeEnterLocation = 0;
	static StoryActionTypeLeaveLocation = 1;
	static StoryActionTypeStayInLocation = 2;
	static StoryActionTypeQuiz = 3;
	static StoryActionTypeImageSearch = 4;
	static StoryActionTypeVideo = 5;
	static StoryActionTypeScript = 6;
	static StoryActionTypeCardReveil = 7;
	static StoryActionTypeUI = 8;

	GetConfig()
	{
		return this.config;
	}

	GetInstances()
	{
		return this.instances;
	}

	Init()
	{
		AppState.instance.AddOnAuthDone(this.OnAuthDone);
		AppState.instance.onIpLocation.Add(this.OnIpLocation);
		AppState.instance.onFirstGeoLocation.Add(this.OnFirstGeoLocation);
		AppState.instance.onGeoLocation.Add(this.OnGeoLocation);
	}

	OnAuthDone = () =>
	{
		this.enabled = /*!AppState.instance.isProduction &&*/ AppState.instance.featureAdv;
		this.LoadData();
		if (AppState.instance.geoLocation.currentLocation || AppState.instance.gpsPositionCheat)
			this.OnGeoLocation();
	}

	OnIpLocation = () =>
	{
		this.hasIpLocation = true;
		this.LoadData();
	}

	OnGeoLocation = () =>
	{
		if (!this.enabled)
			return;

		this.hasGeoLocation = true;
		
		var loc = AppState.instance.GetBestLocation();

		console.log("AdvManager.OnGeoLocation");

		RestApi.SendRequest("/advlocationsearch", {location: loc})
		.then((r) =>
		{
			if (r)
			{
				// Search for signals from the server
				var updateInstances = false;
				for (var i = r.items.length - 1; i >= 0; --i)
				{
					if (r.items[i].type === "event_newcardinstance")
					{
						updateInstances = true;
						r.items.splice(i, 1);
					}
				}

				this.nearbyLocations = r.items;
			}
			else
			{
				this.nearbyLocations = [];
			}

			console.log("this.nearbyLocations:");
			console.log(this.nearbyLocations);

			this.onNearbyLocationsChanged.Call();

			if (updateInstances)
			{
				this.LoadInstances();
			}
		});
	}

	GetNewNearbyLocations(flush)
	{
		var result = [];

		for (var i = 0; i < this.nearbyLocations.length; ++i)
		{
			if (!Utils.GetById(this.processedNearbyLocations, this.nearbyLocations[i].id))
			{
				result.push(this.nearbyLocations[i]);

				if (flush)
					this.processedNearbyLocations.push(this.nearbyLocations[i]);
			}
		}

		result.sort(function(a, b) {
			return a.distance.distanceValue - b.distance.distanceValue;
		});

		return result;
	}

	OnFirstGeoLocation = () =>
	{
		this.hasGeoLocation = true;
		this.LoadData();
	}

	LoadData()
	{
		if ((this.hasGeoLocation || this.hasIpLocation)
			/*&& AppState.instance.GeneralDataLoadingEnabled()*/)
		{
			this.LoadConfig();
			this.LoadInstances();
		}
	}

	LoadConfig()
	{
		if (!this.enabled)
			return;

		var loc = AppState.instance.GetBestLocation();

		RestApi.SendRequest("/advconfig", {location: loc})
		.then((r) =>
		{
			this.config = r;
			if (!this.config)
				this.config = {};

			if (this.config.activities)
			{
				for (var i = 0; i < this.config.activities.length; ++i)
				{
					AppState.instance.imageManager.Store(this.config.activities[i].imageData);
				}
			}

			if (this.config.cardAnimals && this.config.cardAnimals.length > 1)
			{
				this.config.cardAnimals.sort(function(a, b) {
					return b.priority - a.priority;
				});
			}

			this.onConfigChanged.Call();
		});
	}

	LoadInstances()
	{
		if (!this.enabled)
			return;

		RestApi.SendRequest("/advinstances", undefined, undefined, undefined, "GET")
		.then((r) =>
		{
			this.instances = r;
			if (!this.instances)
				this.instances = {
					adventures: [],
					cards: [],
					finishedCardSets: [],
					startedCardSets: []
				};

			console.log("ADV CONFIG:");
			console.log(this.instances);

			this.onInstancesChanged.Call();

			this.CheckUnprocessedCardInstances();
		});
	}

	CheckUnprocessedCardInstances = (onPopupClosed) =>
	{
		if (AppState.instance.cardReceivePopup.IsShown() ||
			Tutorial.IsActive() ||
			!this.HasSeenAdvAnnouncement())
		{
			setTimeout(() => {
				this.CheckUnprocessedCardInstances(onPopupClosed);
			}, 2000);
			return;
		}

		if (this.instances.cards && !AppState.instance.deviceInfo.desktop)
		{
			var unprocessed = [];
			for (var i = 0; i < this.instances.cards.length; ++i)
			{
				if (!this.instances.cards[i].processed)
				{
					unprocessed.push(this.instances.cards[i]);
				}
			}

			if (unprocessed.length > 0)
			{
				this.OnUnprocessedCardInstances(unprocessed, onPopupClosed);					
			}
		}
	}

	OnUnprocessedCardInstances(instances, onPopupClosed)
	{
		this.onUnprocessedCardInstances.Call(instances);

		AppState.instance.cardReceivePopup.Show(instances, this.OnCardReceivedPopupClosed(onPopupClosed));
	}

	OnCardReceivedPopupClosed = (onPopupClosed) => (instances) =>
	{
		for (var i = 0; i < instances.length; ++i)
		{
			instances[i].processed = new Date();
			// Make sure this card is not processed twice (until the server tells us otherwise)
			var ci = Utils.GetById(this.instances.cards, instances[i].id);
			if (ci)
			{
				ci.processed = 1;

				RestApi.SendRequest("/processcardinstance", {id: instances[i].id})
				.then((newInstance) =>
				{
					if (newInstance)
					{
						for (var j = 0; j < this.instances.cards.length; ++j)
						{
							if (this.instances.cards[j].id === newInstance.id)
							{
								console.log("updating card instance: " + newInstance.id);

								//TEMP: server does not set that for debugging purposes atm
								newInstance.processed = new Date();

								this.instances.cards[j] = newInstance;
								break;
							}
						}
					}
				});
			}
		}

		if (instances.length > 0)
		{
			// Switch to card collection screen
			//TODO: handle desktop case
			if (AppState.instance.appInstance)
			{
				if (onPopupClosed)
					onPopupClosed();
				else
					AppState.instance.appInstance.OnTabbarChanged(undefined, AppState.TabIndexAdventure);

				AppState.instance.listManager.AddListContentIndicator(instances.length);

				AppState.instance.appContainer.forceUpdate();	
			}
		}
	}

	GetCards(adventure)
	{
		var result = [];

		// var setIds = {};

		// for (var locationIndex in adventure.locationIndex2CardIds)
		// {
		// 	const locationData = adventure.locationIndex2CardIds[locationIndex];
		// 	for (var j = 0; j < locationData.length; ++j)
		// 	{
		// 		const cardId = locationData[j];
		// 		//if (setIds[cardId] !== undefined)
		// 		//	continue;

		// 		const card = Utils.GetById(this.config.cards, cardId);
		// 		if (card)
		// 		{
		// 			result.push(card);
		// 			setIds[cardId] = true;
		// 		}
		// 	}
		// }

		return result;
	}

	GetCardById(cardId)
	{
		return Utils.GetById(this.config.cards, cardId);
	}

	GetAdventureById(advId)
	{
		return Utils.GetById(this.config.adventures, advId);
	}

	GetCardInstances(cardId)
	{
		return Utils.GetAllByProp(this.instances.cards, "cardId", cardId);
	}

	GetCardInstancesByAdvInstanceId(advInstanceId)
	{
		return Utils.GetAllByProp(this.instances.cards, "advInstanceId", advInstanceId);
	}

	GetCardInstance(instanceId)
	{
		return Utils.GetById(this.instances.cards, instanceId);
	}

	GetCardImages(cardId)
	{
		var result = [];

		var card = Utils.GetById(this.config.cards, cardId);
		if (card)
		{
			for (var i = 0; i < card.pictureIds.length; ++i)
			{
				var img = Utils.GetById(this.config.cardPictures, card.pictureIds[i]);
				if (img)
					result.push(img);
			}
		}

		return result;
	}

	GetAdvInstances(advId)
	{
		return Utils.GetAllByProp(this.instances.adventures, "advId", advId);
	}

	GetAdvInstance(advInstanceId)
	{
		return Utils.GetById(this.instances.adventures, advInstanceId);
	}

	StartAdventure(adventureId, locationSetId)
	{
		var loc = AppState.instance.GetBestLocation();

		return RestApi.SendRequest("/advstart", {
			location: loc,
			adventureId: adventureId,
			locationSetId: locationSetId
		})
		.then((r) =>
		{
			this.instances = r;
			if (!this.instances)
				this.instances = {};

			this.onInstancesChanged.Call();

			var newAdvInstance;
			if (this.instances.adventures)
			{
				var instanceOverview = this.GetAdvInstancesOverview(adventureId);
				newAdvInstance = instanceOverview.startedInstance;
			}

			if (newAdvInstance)
				return new Promise((resolve, reject) => { return resolve(newAdvInstance);});
			else
				return new Promise((resolve, reject) => { return reject("adventure not started");});
		})
		.catch((error) =>
		{
			return new Promise((resolve, reject) => { return reject(error);});
		});
	}

	StopAdventure(instanceId)
	{
		return RestApi.SendRequest("/advstop", {
			instanceId: instanceId,
		})
		.then((r) =>
		{
			this.instances = r;
			if (!this.instances)
				this.instances = {};

			this.onInstancesChanged.Call();

			return new Promise((resolve, reject) => { return resolve(true);});
		})
		.catch((error) =>
		{
			return new Promise((resolve, reject) => { return reject(error);});
		});
	}

	// RestartAdventure(instanceId)
	// {
	// 	const instance = this.GetAdvInstance(instanceId);
	// }

	OnStoryFragmentContinue(advInstanceId)
	{
		this.OnAdventureInput("story", "continue", advInstanceId);
	}

	OnCloseCardInstancePopup(advInstanceId, cardInstanceId)
	{
		this.OnAdventureInput("popup.close", "cardInstance", advInstanceId);
	}

	OnUserLocationChanged(advInstanceId, location)
	{
		if (location)
			this.OnAdventureInput("location", JSON.stringify(location), advInstanceId);
	}

	OnAdventureInput(type, data, advInstanceId)
	{
		return RestApi.SendRequest("/advinput", {
			instanceId: advInstanceId,
			type: type,
			data: data,
		})
		.then((r) =>
		{
			this.OnAdventureInstancesResult(r);
			return new Promise((resolve, reject) => { return resolve(true);});
		})
		.catch((error) =>
		{
			return new Promise((resolve, reject) => { return reject(error);});
		});
	}

	OnAdventureInstancesResult(r, onPopupClosed)
	{
		this.instances = r;
		if (!this.instances)
			this.instances = {};

		this.onInstancesChanged.Call();

		this.CheckUnprocessedCardInstances(onPopupClosed);
	}

	GetAdvInstancesOverview(adventureId)
	{
		const advInstances = this.GetAdvInstances(adventureId);

		var isUnlocked = false;
		var started;
		var finished;
		for (var i = 0; i < advInstances.length; ++i)
		{
			if (advInstances[i].unlocked !== undefined)
				isUnlocked = true;

			if (advInstances[i].started !== undefined)
				started = advInstances[i];

			if (advInstances[i].finished !== undefined)
				finished = advInstances[i];
		}

		return {
			isUnlocked: isUnlocked,
			startedInstance: started,
			finishedInstance: finished
		};
	}

	UploadAdventureImage(file, advInstanceId, onProgress, onError, onLocalImageData, onDone, activityId, onPopupClosed)
	{
		var $canvas = $('<canvas>');

		var uploader = new CanvasImageUploader({
			maxSize: 512,
			jpegQuality: 0.7
		});

		var thisPtr = this;

		uploader.readImageToCanvas(file, $canvas, function ()
		{
			uploader.saveCanvasToImageData($canvas[0]);

			var dataUrl = uploader.getCanvasDataUrl($canvas[0]);
			if (onLocalImageData)
				onLocalImageData(dataUrl);

			var blob = Utils.DataURItoBlob(dataUrl);

			var formData = new FormData();

			if (AppState.instance.deviceInfo.ios)
			{
				formData.append("data", dataUrl);
			}
			else
			{
				var newFile = new File( [blob], 'image.jpeg', { type: 'image/jpeg' } );
				formData.append("file", newFile);
			}

			if (advInstanceId !== undefined)
				formData.append("instanceId", advInstanceId);
			if (activityId !== undefined)
				formData.append("activityId", activityId);
			formData.append("type", "image");

			var loc = AppState.instance.ipLocation;
			if (AppState.instance.GetGpsPosition())
				loc = AppState.instance.GetGpsPosition();
			formData.append("locationStr", JSON.stringify(loc));

			RestApi.SendRequestText("/advinput", formData, false, onProgress).then( (ucStr) =>
			{
				var isError = true;
				var uc;
				if (ucStr && ucStr.startsWith("{"))
				{
					uc = JSON.parse(ucStr);
					if (uc && uc.adventures)
					{
						isError = false;
						thisPtr.OnAdventureInstancesResult(uc, onPopupClosed);
					}
				}
				else if (ucStr)
				{
					uc = ucStr;
				}

				if (isError)
				{
					if (onError)
					{
						if (uc)
							onError(uc);
						else
							onError("Error while uploading");
					}
					else
						AppState.instance.screenMessage.Show('There was an error uploading your photo!');
				}
				else
				{
					if (onDone)
						onDone(uc);
				}
			})
			.catch(error =>
			{
				if (onError)
					onError(error);
				else
					AppState.instance.screenMessage.Show('There was an error uploading your photo!');
			});
		});
	}

	GetCardIdsOfLocation(locationId)
	{
		var result = [];

		if (!this.config.cardSets)
			return result;

		for (var i = 0; i < this.config.cardSets.length; ++i)
		{
			const cs = this.config.cardSets[i];

			for (var j = 0; j < cs.locationSetIds.length; ++j)
			{
				const locSet = Utils.GetById(this.config.locationSets, cs.locationSetIds[j]);

				for (var k = 0; k < locSet.locationIds.length; ++k)
				{
					if (locationId === locSet.locationIds[k])
					{
						result.push(cs.cardIds[j]);
					}
				}
			}
		}

		return result;
	}

	GetCardIdsOfActivity(activityId)
	{
		var result = [];

		if (!this.config.cardSets)
			return result;

		for (var i = 0; i < this.config.cardSets.length; ++i)
		{
			const cs = this.config.cardSets[i];

			for (var j = 0; j < cs.locationSetIds.length; ++j)
			{
				const locSet = Utils.GetById(this.config.locationSets, cs.locationSetIds[j]);

				for (var k = 0; k < locSet.locationIds.length; ++k)
				{
					const loc = Utils.GetById(this.config.locations, locSet.locationIds[k]);
					if (loc.activityId === activityId)
					{
						result.push(cs.cardIds[j]);
					}
				}
			}
		}

		return result;
	}

	GetActivitiesOfCard(cardId)
	{
		const ids = this.GetActivityIdsOfCard(cardId);

		var result = [];

		for (var i = 0; i < ids.length; ++i)
		{
			const activityData = Utils.GetById(this.config.activities, ids[i]);
			if (activityData)
				result.push(activityData);
		}

		return result;
	}

	GetActivityIdsOfCard(cardId)
	{
		var result = [];

		for (var i = 0; i < this.config.cardSets.length; ++i)
		{
			const cs = this.config.cardSets[i];

			var idx = -1;
			for (var j = 0; j < cs.cardIds.length; ++j)
			{
				if (cs.cardIds[j] === cardId)
				{
					idx = j;
					break;
				}
			}

			if (idx < 0)
				continue;

			const locSet = Utils.GetById(this.config.locationSets, cs.locationSetIds[idx]);

			if (locSet)
			{
				for (var k = 0; k < locSet.locationIds.length; ++k)
				{
					const loc = Utils.GetById(this.config.locations, locSet.locationIds[k]);
					if (loc.activityId)
					{
						if (!result.includes(loc.activityId))
							result.push(loc.activityId);
					}
				}
			}
		}

		return result;
	}

	OnAdvAnnouncementCta()
	{
		const key = "advannouncementcount";
		var count = Utils.GetLocalStorageNumber(key);
		count++;
		Utils.SetLocalStorage(key, count);
	}

	HasSeenAdvAnnouncement()
	{
		const key = "advannouncementcount";
		var count = Utils.GetLocalStorageNumber(key);
		return count >= 1;
	}

	IsCardNew(cardId)
	{
		const instances = this.GetCardInstances(cardId);
		if (instances.length === 0)
			return false;

		const now = new Date();

		for (var i = 0; i < instances.length; ++i)
		{
			const instance = instances[i];

			if (!instance.processed)
				return true;

			const processed = new Date(instance.processed);
			if (processed)
			{
				if (Utils.DateSameDay(now, processed))
					return true;
			}

			const received = new Date(instance.received);
			if (received)
			{
				if (Utils.DateSameDay(now, received))
					return true;
			}
		}

		return false;
	}
}