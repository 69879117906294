import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import ProfilePicture from './ProfilePicture';
import AppState from '../AppState';
import Utils from '../Utils';
import DeleteIcon from 'material-ui-icons/Delete';
import { observable } from '../../node_modules/mobx';
import Translate from '../Translate';

const styles = {
};

@observer
class SocialComment extends React.Component
{
	@observable deleted = false;

	OnProfileLoaded = () =>
	{
		this.forceUpdate();
	}

	OnPersonClicked = () =>
	{
		const comment = this.props.comment;
		if (!comment)
			return;
		AppState.instance.appContainer.OnProfileClicked(comment.sender);
	}

	OnDeleteComment = () =>
	{
		const comment = this.props.comment;
		if (!comment)
			return;

		AppState.instance.screenMessage.ShowAlert(Translate.T("en.Confirmation", "de.Bestätigung", "confirmation.title"),
			Translate.T("en.Do you really want to delete your comment? (Sent hearts can't be withdrawn though.)",
			"de.Willst Du diesen Kommentar wirklich löschen? (Verschenke Herzen werden jedoch nicht zurückgesendet.)", "confirmation.deleteSocialComment"),
			[{label: Translate.FindTranslation("answer.no"), value: 0}, {label: Translate.FindTranslation("answer.yes"), value: 1}], true, (resultButton) =>
		{
			if (resultButton && resultButton.value)
			{
				this.DoDeleteComment();
			}
		});
	}

	DoDeleteComment()
	{
		const comment = this.props.comment;
		if (!comment)
			return;

		AppState.instance.socialManager.DeleteMessage(comment.id, this.props.contentId)
		.then((message) =>
		{
			if (message === "ok")
			{
				// We can't delete the hearts of a comment, just the text
				this.props.comment.text = undefined;

				if (comment.hearts > 0)
				{
					this.forceUpdate();
				}
				else
				{
					this.deleted = true;
				}

				if (this.props.onDeleted)
					this.props.onDeleted(comment.id);
			}
			else
			{
				AppState.instance.screenMessage.Show(
					Translate.T("de.Fehler beim Löschen des Kommentars. Bitte versuche es später nochmal.",
						"en.Error while deleting comment. Please try again later.", "social.comment.delete"));
			}
		});
	}

	render()
	{
		if (!this.props.comment)
			return null;
		if (this.deleted)
			return null;

		const comment = this.props.comment;
		const profile = AppState.instance.socialManager.GetProfile(comment.sender, true, this.OnProfileLoaded);

		var showDelete = false;
		if (profile && profile.isOwnProfile)
		{
			if (comment.text && comment.text.length > 0)
				showDelete = true;
		}

		return (
			<div className="socialComment" key={this.props.key}>
				<ProfilePicture className="csCommentPhoto"
					onClick={this.OnPersonClicked}
					username={comment.sender}
				/>

				<div className="csName" onClick={this.OnPersonClicked}>
					{profile && profile.firstName && <span className="csFirstName">{profile.firstName}</span>}
					{profile && profile.lastName && <span className="csLastName">{profile.lastName}</span>}
					{profile && !profile.firstName && !profile.lastName && profile.username && <span className="csUsername">{profile.username}</span>}
				</div>

				<div className="relTime">
					{comment.relativeTimeDesc}
				</div>

				<div>
					<div className={"csCommentMessage"}>
						{comment.hearts > 0 && <div className="csCommentHeart">
							{Utils.RenderHeartIcon(24, 24, "#e31c46", "#e31c46", 1)}
							{comment.hearts > 1 && <span className="numHearts">+{comment.hearts}</span>}
						</div>}
						{comment.text}

						{showDelete && <span className="csCommentDelete">
								<DeleteIcon onClick={this.OnDeleteComment} />
						</span>}
					</div>
				</div>

			</div>
		);
	}
}

SocialComment.propTypes = {
	classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(SocialComment);