import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import {observer} from 'mobx-react';
import ListMenu from '../components/ListMenu';
import Translate from '../Translate';
import Tracking from '../Tracking';
import AppState from '../AppState';
import TextField from 'material-ui/TextField';
import Utils from '../Utils';
import $ from 'jquery';
import Map from '../components/Map';
import Button from 'material-ui/Button';

const styles = theme => ({
	button: {
	  margin: theme.spacing.unit,
	},
	input: {
	  display: 'none',
	},
});

class LogMessageDialog extends React.Component
{
	render()
	{
		return (
			<div className="logmessages">
				{this.props.messages.map((msg, index) =>
				{
					return (
						<div className={"type " + msg.type} key={index}>
							{msg.value}
						</div>
					);
				})}
			</div>
		);
	}
}

@observer
class SettingsPage extends React.Component
{
	static instance = null;
	searchQueue = [];
	searchTimer;

	constructor(props)
	{
		super(props);
		SettingsPage.instance = this;
	}

	componentDidMount()
	{
		Tracking.OnPageView("settings");
	}

	OnLanguageSelected = () =>
	{
	}

	OnLanguageMenuClicked = () =>
	{
		Translate.ShowLanguageSelection(this.OnLanguageSelected);
		this.forceUpdate();
	}

	OnLogoutClicked = () =>
	{
		AppState.instance.OnLoggedOut();
	}

	OnDeleteAccountClicked = () =>
	{
		AppState.instance.screenMessage.CloseDialog(); // close settings page so that alert is visible

		AppState.instance.screenMessage.ShowAlert(Translate.T("en.Confirmation", "de.Bestätigung", "confirmation.title"),
			Translate.T("en.Your account is about to be completely deleted, including all your personal information.  Do you really want to delete your account?",
				"de.Dein Account würde komplett gelöscht werden - inklusive aller persönlichen Informationen. Willst Du wirklich Deinen Account löschen?", "confirmation.deleteaccount"),
			[{label: Translate.FindTranslation("answer.no"), value: 0}, {label: Translate.FindTranslation("answer.yes"), value: 1}], true, (resultButton) =>
		{
			if (resultButton && resultButton.value)
			{
				AppState.instance.OnDeleteAccount();
			}
		});
	}

	OnTextFieldChanged = (event) =>
	{
		const minLength = 3;

		const newValue = event.target.value;
		if (!newValue || newValue.length >= minLength)
			this.Enqueue(newValue);
	}

	Enqueue(value)
	{
		this.searchQueue.push(value);
		this.searchTimer = Utils.SetTimer(this.searchTimer, 500, this.FireSearch, 50);
	}

	FireSearch = () =>
	{
		if (this.searchQueue.length > 0)
		{
			const lastEntry = this.searchQueue[this.searchQueue.length - 1];
			this.searchQueue = [];

			this.OnSearch(lastEntry);
		}
	}

	OnSearch(address)
	{
		if (!address || address.length === 0)
		{
			AppState.instance.ClearGpsCheatLocation();
			this.forceUpdate();
			return;
		}

		AppState.instance.SetGpsCheatAddress(address)
		.then((loc) =>
		{
			this.forceUpdate();
		})
		.catch((error) =>
		{
			this.forceUpdate();
		});
	}

	OnCenterChanged = (lat, lng) =>
	{
		AppState.instance.SetGpsCheatLocation({
			latitude: lat,
			longitude: lng
		});
		this.forceUpdate();
	}

	OnTextFieldKeyPress = (event) =>
	{
		var key = event.key || event.keyCode || event.which;
		if (key === 'Enter' || key === '13')
		{
			event.preventDefault();

			var selector = "#gpsAddressInput";

			$(selector).blur();
			this.Enqueue($(selector).val());
		}
	}

	CreateMapQuery = () =>
	{
		var loc = AppState.instance.GetBestLocation();

		var query = {};
		
		query.location = loc;
		query.referenceLocation = loc;

		query.language = AppState.instance.userProfile.userLang;

		query.q = undefined;
		
		return query;
	}

	GetMarkerIcon = (item, selected) =>
	{
		var result = {};

		const iconSize = 16;

		result.url = Utils.RenderStarUrl(iconSize, iconSize, "#e31c46", "white");
		result.size = [iconSize, iconSize];
		result.origin = [0, 0];
		result.anchor = [-iconSize / 2, -iconSize / 2];

		return result;
	}

	ClearGpsCheat = () =>
	{
		AppState.instance.ClearGpsCheatLocation();
		this.forceUpdate();
	}

	ResetLocalStorage = () =>
	{
		Utils.RemoveLocalStorage("adv_inform");
		Utils.RemoveLocalStorage("adv_register");
		Utils.RemoveLocalStorage("advannouncementcount");
		Utils.RemoveLocalStorage("tutorialdone");
		Utils.RemoveLocalStorage("tutorialdone2");
		Utils.RemoveLocalStorage("bottomtutorialdone");
	}

	ShowLogs = () =>
	{
		var logs = Utils.GetLogMessages();

		AppState.instance.screenMessage.ShowDialog("Log Messages", LogMessageDialog, undefined, undefined, undefined,
			{messages: logs});
	}

	render()
	{
		Tracking.OnRender("SettingsPage");

		const isLoggedIn = AppState.instance.IsLoggedIn();

		var menuEntries = [];
		//this.menuEntries.push({id: 1, label: 'Notifications', component: null});
		//this.menuEntries.push({id: 2, label: 'Payment Method', component: null});
		menuEntries.push({id: 0, label: Translate.T('en.Language', 'de.Sprache', 'settings.language'), rightLabel: Translate.CurrentLanguageLabel(), onClick: this.OnLanguageMenuClicked});

		if (isLoggedIn)
		{
			menuEntries.push({id: 1, label: Translate.T('en.Log Out', 'de.Ausloggen', 'settings.logout'), onClick: this.OnLogoutClicked});
			menuEntries.push({id: 2, label: Translate.T('en.Delete Account', 'de.Profil löschen', 'settings.deleteaccount'), onClick: this.OnDeleteAccountClicked});
		}
			
		menuEntries.push({id: 3, label: Translate.T('en.Version', 'de.Version', 'settings.version'), rightLabel: AppState.instance.versionTag});

		if (AppState.instance.versionTag === "dev")
		{
			menuEntries.push({id: 4, label: "Reset LocalStorage", onClick: this.ResetLocalStorage});
			menuEntries.push({id: 5, label: "Log Messages", onClick: this.ShowLogs});
		}

		return (
			<div
				className="settingsPage"
				key="settingsPage"
			>
				<div className="settingsMenuContainer">
					<ListMenu
						menuEntries={menuEntries}
					/>
				</div>

				{AppState.instance.versionTag === "dev" &&
					<div className="gpsCheat">
						<div className="title">GPS Cheat</div>

						{AppState.instance.gpsPositionCheat &&
							<Button onClick={this.ClearGpsCheat}>Clear</Button>}

						<TextField
							type="search"
							onChange={this.OnTextFieldChanged}
							onKeyPress={this.OnTextFieldKeyPress}
							placeholder={((AppState.instance.gpsPositionCheat && AppState.instance.gpsPositionCheat.address) ||
								!AppState.instance.gpsPositionCheat) ? "Address" : "Manual"}
							autoComplete={"off"}
							InputProps={{
								disableUnderline: true,
								id: "gpsAddressInput",
							}}
						/>

						{AppState.instance.gpsPositionCheat &&
						<div>
							{AppState.instance.gpsPositionCheat.address &&
								<div className="result">{"Found: " + AppState.instance.gpsPositionCheat.address}</div>}
							<div className="coords">{"(" + AppState.instance.gpsPositionCheat.latitude + ", " +
								AppState.instance.gpsPositionCheat.longitude + ")"}</div>
							<Map
								id="gpsCheatMap"
								gestureHandling="greedy"
								zoomFactor={18}
								ignoreActiveFilter={true}
								mapSimpleMode={true}
								disableFilterButton={true}
								disableContent={true}
								disableContentStoreCache={true}
								createQuery={this.CreateMapQuery}
								apiEndpoint={"/advmap"}
								markerIcon={this.GetMarkerIcon}
								centerLat={AppState.instance.gpsPositionCheat.latitude}
								centerLng={AppState.instance.gpsPositionCheat.longitude}
								onCenterChanged={this.OnCenterChanged}
							/>
						</div>}
					</div>}
			</div>
		);
	}
}


SettingsPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SettingsPage);