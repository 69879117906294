import {observable} from 'mobx';
import Utils from '../Utils';
import Trip from './Trip';
import Delegate from '../utils/Delegate';
import RestApi from '../RestApi';

class UserProfile
{
	@observable id;
	@observable username;
	@observable password;
	@observable email;
	@observable language;
	@observable address;
	@observable zip;
	@observable district;
	@observable state;
	@observable country;
	@observable latitude;
	@observable longitude;
	@observable loginData = {};
	@observable userStartDate;
	@observable userLang;

	filterMaxDuration = 2 * 3600;
	filterMaxDistance = 100;
	filterMinRating;
	filterMaxPrice;
	filterMinAge = 0;
	filterMaxAge = 99;
	filterAgeGroups = [];
	filterStartDate;
	filterEndDate;
	filterSortBy;
	filterSortAsc = true;

	@observable mapFilterCategories = [];

	@observable searchStack = [];

	// Trip Planning
	@observable trip = new Trip();
	@observable trips = [];

	@observable entries = [];

	onTripChanged = new Delegate();

	SetLoginData(data)
	{
		this.loginData = data;

		if (data && data.ageGroups)
			this.filterAgeGroups = data.ageGroups;
		else
			this.filterAgeGroups = [];

		if (data && data.minAge)
			this.filterMinAge = data.minAge;
		else
			this.filterMinAge = 0;

		if (data && data.maxAge)
			this.filterMaxAge = data.maxAge;
		else
			this.filterMaxAge = 99;
	}

	SetAgeGroups(ageGroups, onDone)
	{
		var newValues = [];

		for (var i = 0; i < 5; ++i)
		{
			if (ageGroups[i])
				newValues.push(i);
		}

		this.filterAgeGroups = newValues;

		var q = {
			ageGroups: newValues
		};

		RestApi.SendRequest("/setage", q)
		.then((loginData) =>
		{
			if (loginData)
			{
				this.SetLoginData(loginData);
				if (onDone)
					onDone();
			}
		});
	}

	//@return bool True if loaded, false otherwise
	Load()
	{
		this.id = undefined;
		const jsonStr = Utils.GetLocalStorage('userprofile');
		if (jsonStr)
		{
			const jsonObj = JSON.parse(jsonStr);
			for (var prop in jsonObj)
				this[prop] = jsonObj[prop];

			if (this.trip === undefined || this.trip.activities === undefined)
				this.trip = new Trip();
			this.onTripChanged = new Delegate();

			if (typeof this.filterStartDate === "string")
			{
				this.filterStartDate = new Date(this.filterStartDate);
			}
			if (typeof this.filterEndDate === "string")
			{
				this.filterEndDate = new Date(this.filterEndDate);
			}

			// clear old trip dates if in past
			const now = new Date();
			if (this.filterEndDate < now)
			{
				this.filterStartDate = undefined;
				this.filterEndDate = undefined;
			}

			return (this.id !== undefined);
		}
		else
		{
			return false;
		}
	}

	Save()
	{
		var onTripChanged = this.onTripChanged;
		
		try
		{
			this.onTripChanged = undefined;
			Utils.SetLocalStorage('userprofile', JSON.stringify(this));
			this.onTripChanged = onTripChanged;
		}
		catch (error)
		{
			this.onTripChanged = onTripChanged;
		}
	}

	OnSearch(searchTerm)
	{
		if (searchTerm === undefined || searchTerm.length === 0)
			return;

		var lastSearch;
		if (this.searchStack.length > 0)
			lastSearch = this.searchStack[this.searchStack.length - 1];

		var add = true;
		var changed = false;
		if (lastSearch)
		{
			if (searchTerm.indexOf(lastSearch) === 0)
			{
				// Replace top of the stack as the new term is longer
				add = false;
				changed = true;
				this.searchStack[this.searchStack.length - 1] = searchTerm;
			}
			else if (lastSearch.indexOf(searchTerm) === 0)
			{
				// Old term is longer, don't add the new one
				add = false;
			}
		}

		if (add)
		{
			changed = true;
			this.searchStack.push(searchTerm);

			if (this.searchStack.length > 100)
				this.searchStack.splice(0, this.searchStack.length - 100);
		}

		if (changed)
			this.Save();
	}

	GetSearchQueries(filter, maxCount)
	{
		var result = [];

		for (var i = this.searchStack.length - 1; i >= 0; --i)
		{
			var sq = this.searchStack[i];
			if ((filter.length === 0 || sq.indexOf(filter) >= 0) && (filter !== sq))
			{
				result.push(sq);
				if (result.length === maxCount)
					break;
			}
		}

		return result;
	}

	OnTripChanged(trip)
	{
		if (trip)
		{
			for (var i = 0; i < this.trips.length; ++i)
			{
				if (this.trips[i].id === trip.id)
				{
					var q = {};
					for (var prop in trip)
						q[prop] = trip[prop];
					q.clientId = q.id;
					q.id = 0;
					RestApi.SendRequestText("/journals/0", q, undefined, undefined, "PUT").then((responseTrip) =>
					{
						console.log(responseTrip);
					});
					break;
				}
			}
		}

		this.Save();
		this.onTripChanged.Call();
	}

	AddOnTripChanged(cb)
	{
		this.onTripChanged.Add(cb);
	}

	RemoveOnTripChanged(cb)
	{
		this.onTripChanged.Remove(cb);
	}

	LoadTrips()
	{
		/*var thisPtr = this;
		RestApi.SendRequestText("/journals", undefined, undefined, undefined, "GET").then((str) =>
		{
			if (str)
			{
				thisPtr.trips = JSON.parse(str);
				for (var i = 0; i < thisPtr.trips.length; ++i)
					thisPtr.trips[i].id = thisPtr.trips[i].clientId;
				thisPtr.Save();
				thisPtr.onTripChanged.Call();
			}
		});*/
	}

	EnsureActiveTrip()
	{
		var trip = this.trip;
		if (!trip.active)
		{
			this.trip = new Trip();
			this.trip.id = Trip.GenerateId();
			this.trip.active = true;
			var defaults = Utils.GenerateDefaultTripDate();
			this.trip.date = defaults.date;
			this.trip.startTime = defaults.time;
		}
		return trip;
	}

	AddTrip(trip)
	{
		var found = false;
		for (var i = 0; i < this.trips.length; ++i)
		{
			if (this.trips[i].id === trip.id)
			{
				found = true;
				break;
			}
		}
		if (!found)
		{
			trip.clientId = trip.id;
			RestApi.SendRequest("/journals", trip).then((responseTrip) =>
			{
				console.log(responseTrip);
			});

			this.trips.push(trip);
			this.OnTripChanged(/*trip  don't PUT just created entry to server again*/);
		}
	}

	DeleteTrip(id)
	{
		for (var i = 0; i < this.trips.length; ++i)
		{
			var trip = this.trips[i];

			if (trip.id === id)
			{
				var q = {};
				for (var prop in trip)
					q[prop] = trip[prop];
				q.clientId = q.id;
				q.id = 0;
				RestApi.SendRequestText("/journals/0", q, undefined, undefined, "DELETE").then((responseTrip) =>
				{
				});

				this.trips.splice(i, 1);
				this.OnTripChanged();
				break;
			}
		}
	}
}

export default UserProfile;