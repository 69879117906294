import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import Utils from '../Utils';
import AppState from '../AppState';
import CategoryTab from '../components/CategoryTab';
import Slide from 'material-ui/transitions/Slide';
import ContentItem from '../components/ContentItem';

const styles = theme => ({
});

@observer
class SearchPage extends React.Component
{
	GetFilterSelection()
	{
		var filters = AppState.instance.filterManager.GetFilters();

		var result = [];

		for (var i = 0; i < filters.length; ++i)
		{
			const f = filters[i];
			if (f.showInSearchDialog)
			{
				result.push(f);
			}
		}

		return result;
	}

	OnFilterClicked = (filterId) => (contentData, e) =>
	{
		if (this.props.onDefaultFilterClicked)
			return this.props.onDefaultFilterClicked(filterId, contentData, e);
		else
			return true;
	}

	renderFilter(f, isFirst, isLast)
	{
		var contentItem = {
			id: f.id,
			title: f.title,
			img: Utils.GetCategoryIconUrl(f.title, "#aaa", "white", 32, 32, true),
			type: "filter",
			url: "/" + encodeURIComponent(f.title)
		};

		return (
			<ContentItem
				contentData={contentItem}
				itemType={"searchresult"}
				key={f.id}
				renderMode={'medium'}
				isLast={isLast}
				isFirst={isFirst}
				onClick={this.OnFilterClicked(f.id)}
			/>
		);
	}

	render()
	{
		const q = AppState.instance.contentStore.searchQuery;
		
		var defaultItems;
		if (!q || q.length < 3)
		{
			defaultItems = [];
			var filters = this.GetFilterSelection();
			for (var i = 0; i < filters.length; ++i)
			{
				defaultItems.push(this.renderFilter(filters[i], i === 0, i === filters.length - 1));
			}
		}

		var filterIdSum = "";
		for (i = 0; i < AppState.instance.filterStack.length; ++i)
		{
			if (filterIdSum.length > 0)
				filterIdSum += "-";
			filterIdSum += AppState.instance.filterStack[i];
		}

		const isVisible = this.props.visible;

		return (
			<Slide in={isVisible} direction='up'>
				<div id="searchPage" className={"searchPage" + (!isVisible ? " hidden":"")}>

					{!this.props.hideDefaults && defaultItems}

					{defaultItems === undefined && isVisible &&
						<CategoryTab isRoot={false} 
							categoryIds={filterIdSum}
							renderMode="searchresult"
							itemType={"mixed"}
							contentOnly={this.props.contentOnly}
							preload={true}
							active={(AppState.instance.currentMainTab === AppState.TabIndexExplore) ||
								(AppState.instance.currentMainTab === AppState.TabIndexTimeline) ||
								(AppState.instance.deviceInfo.desktop)}
							horizontalContentList={false}
							onContentClick={this.props.onContentClick}
					/>}

				</div>
			</Slide>
		);
	}
}

SearchPage.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(SearchPage);