import React from 'react';
import {observer} from 'mobx-react';
import AppState from '../../AppState';
import Button from 'material-ui/Button';
import Translate from '../../Translate';
import Utils from '../../Utils';
import ArrowBack from 'material-ui-icons/ArrowBack';
import LandingPageFooter from '../LandingPageFooter';
import Tracking from '../../Tracking';
import { observable } from 'mobx';
import AddCircleOutline from 'material-ui-icons/AddCircleOutline';
import RemoveCircleOutline from 'material-ui-icons/RemoveCircleOutline';
import LockIcon from 'material-ui-icons/Lock';
import ArrowRight from 'material-ui-icons/ArrowForward';
import Collapse from 'material-ui/transitions/Collapse';

@observer
export default class AdvAnnouncement extends React.Component
{
	advManager;
	@observable faqState = {};
	@observable showDisclaimer = false;

	constructor(props)
	{
		super(props);

		this.advManager = AppState.instance.advManager;
	}

	componentDidMount()
	{
		Tracking.OnPageView("AdvAnnouncement");
	}

	OnCta = () =>
	{
		if (this.props.onCta)
			this.props.onCta();
	}

	OnLogoClicked = () =>
	{
		if (this.props.onLogo)
			this.props.onLogo();
	}

	OnBackClicked = () =>
	{
		if (this.props.onBackClicked)
			this.props.onBackClicked();
	}

	IsFaqOpen(itemId)
	{
		return this.faqState[itemId] === true;
	}

	OnFaqClicked = (itemId) => () =>
	{
		const isOpen = this.IsFaqOpen(itemId);
		if (isOpen)
			this.faqState[itemId] = false;
		else
		{
			this.faqState[itemId] = true;

			Tracking.SendEvent("adventure_faq", {
				itemId: itemId
			});
		}

		this.forceUpdate();
	}

	RenderFaqItem(itemId, q, a)
	{
		const isOpen = this.IsFaqOpen(itemId);
		return (
			<div className={"faqItem " + (isOpen ? "open":"close")} onClick={this.OnFaqClicked(itemId)}>
				<div className="header">
					<div className="icon">
						{isOpen && <RemoveCircleOutline/>}
						{!isOpen && <AddCircleOutline/>}
					</div>
					<div className="question">{q}</div>
				</div>

				<Collapse in={isOpen} timeout="auto">
					<div className="answer">{a}</div>
				</Collapse>
			</div>
		);
	}

	OnDisclaimer = () =>
	{
		this.showDisclaimer = !this.showDisclaimer;
	}

	render()
	{
		return (
			<div className={"advAnnouncement" + (this.props.showLenaIcon ? " withLogo": " noLogo")}>
				{this.props.showLenaIcon &&
					<div className="logo">
						<Button onClick={this.OnLogoClicked}>
							{Utils.RenderLogo(40, 40, "white", "#e31c46")}
						</Button>
					</div>}
				{!this.props.showLenaIcon &&
					<div className="logo back">
						<Button onClick={this.OnBackClicked}>
							<ArrowBack />
						</Button>
					</div>}

				<div className="content">
					<div className="section section0">
						<div className="imageintro" />
						
						<div className="content">
							<h1 className="title title0">
								<span className="pre">{Translate.T("de.LENA", "en.LENA", "advannouncement.title0.pre")}</span>
								<span className="mid">{Translate.T("de.s ", "en.'s ", "advannouncement.title0.mid")}</span>
								<span className="post">{Translate.T("de.Abenteuer", "en.Adventures", "advannouncement.title0.post")}</span>
							</h1>
							
							<h2 className="claim0">{Translate.T("de.Bist Du bereit für aufregende Abenteuer während Deiner Familienausflüge?",
								"en.Are your ready for exciting adventures during your family trips?", "advannouncement.claim0")}</h2>

							<div className="intro">{Translate.T("de.Jetzt neu", "en.Introducing", "advannouncement.intro")}</div>

							<div className="text text0">{Translate.T("de.Erlebe gemeinsam mit Deinen Kindern fantastische, interaktive Geschichten und schnappe Dir dabei LENAs Abenteuerkarten.",
								"en.Experience fantastic, interactive stories together with your kids and fetch LENA's adventure cards on the go.", "advannouncement.text0")}</div>
							<div className="image0" />
						</div>
					</div>

					<div className="section section05">
						<div className="title title05">{Translate.T("de.Interaktive Abenteuer", "en.Interactive Adventures", "advannouncement.title05")}</div>
						<div className="text text05">{Translate.T("de.Verwende Dein Smartphone um in LENAs Abenteuer einzutauchen und löse gemeinsam mit Deiner Familie rätselhafte Aufgaben.\n" +
							"Nebenbei erfährst Du auf unterhaltsame Art und Weise viel Wissenswertes über die Tier- und Pflanzenwelt.",
							"en.Dive into LENA's Adventures using your smartphone und solve immersive puzzles together with your family.\n" +
							"Along the way you will learn a lot about the wildlife in an entertaining way.", "advannouncement.text05")}</div>
						<div className="image05" />
					</div>

					<div className="section section1">
						<div className="title title1">{Translate.T("de.Tierische Helden", "en.Animal Heroes", "advannouncement.title1")}</div>
						<div className="text text1">{Translate.T("de.Triff auf Deinen Ausflügen tierische Helden aus LENAs Abenteuer.\n" +
							"Mit etwas Glück werden sie zu Deinen digitalen Gefährten.",
							"en.Meet animal heroes during your LENA Adventures.\n" +
							"If you are lucky they become your digital companions.", "advannouncement.text1")}</div>
						<div className="image1" />
					</div>

					<div className="section section2">
						<div className="title title2">{Translate.T("de.Abenteuerkarten sammeln", "en.Collect Adventure Cards", "advannouncement.title2")}</div>
						<div className="text text2">{Translate.T("de.Als Belohnung für bestandene Abenteuer erhältst Du eine von LENAs Abenteuerkarten.\n" +
							"Für manche Rätsel brauchst Du Wissen oder Geschicklichkeit. Für andere Abenteuer musst Du Ausdauer und Teamgeist beweisen.",
							"en.Collect one of LENA's Adventure Cards for each finished adventure.\n" +
							"You will need cleverness for some puzzles. For others you will have to show perseverance and team spirit.", "advannouncement.text2")}</div>
						<div className="image2" />
					</div>

					<div className="section section6">
						<div className="title title6">{Translate.T("de.So funktioniert's", "en.How it works", "advannouncement.title6")}</div>
						<div className="text text6">
							<ol>
								<li>{Translate.T("de.Starte LENA auf Deinem Handy und erstelle einen Account.","en.Open LENA on your smartphone and create an account.", "advannouncement.text6.li0")}</li>
								<li>{Translate.T("de.Suche Dir ein Familien-Ausflugsziel aus, bei dem Du Abenteuerkarten sammeln kannst.", "en.Pick a destination for your family trip where you can collect Adventure Cards.", "advannouncement.text6.li1")}</li>
								<li>{Translate.T("de.Begebe Dich zum Ausflugsziel und öffne LENA. Das Kartensammeln kann beginnen.\nEs gibt Karten, die Du sofort erhältst, wenn Du dich beim jeweiligen Ausflugsziel befindest," +
									" aber auch Karten, die Du bekommst, wenn Du ein Foto eines gesuchten Objektes hochlädst (z.B. Fichtenzapfen).",
									"en.Get to the trip destination and open LENA. The fun can start.\nSome cards you will receive instantly when you find yourself at the destination. "+
									"For other cards you have to take a photo of a seeked object and upload it using LENA (e.g. pine cones).", "advannouncement.text6.li2")}</li>
								<li>{Translate.T("de.Viel Spaß beim Vervollständigen Deiner Kartensets!", "en.Have fun completing your card sets!", "advannouncement.text6.li3")}</li>	
							</ol>
						</div>
					</div>

					<div className="section section3">

						<h1 className="title title0">
							<span className="pre">{Translate.T("de.LENA", "en.LENA", "advannouncement.title0.pre")}</span>
							<span className="mid">{Translate.T("de.s ", "en.'s ", "advannouncement.title0.mid")}</span>
							<span className="post">{Translate.T("de.Abenteuer", "en.Adventures", "advannouncement.title0.post")}</span>
						</h1>
						
						<div className="text text3">{Translate.T("de.Bei ausgewählten Ausflugszielen und nur bei LENA. Halte Ausschau nach dem LENA Logo.",
							"en.At selected destinations and only at LENA. Watch out for LENA's Logo and updates on this website.", "advannouncement.text3")}</div>						
					</div>

					<div className="section section4">
						
						{!AppState.instance.deviceInfo.desktop && <Button onClick={this.OnCta}>
							{this.props.ctaLabel}
							{!this.props.ctaLabel && Translate.T("de.Suche Dein erstes Abenteuer", "en.Find your first adventure", "advannouncement.cta")}
						</Button>}

						{AppState.instance.deviceInfo.desktop && <div className="desktopCta">

							<div className="cta">
								{Translate.T("en.Find your first Adventure Cards with your smartphone",
									"de.Finde Deine ersten Abenteuerkarten mit Deinem Handy", "advannouncement.opensmartphone")}
							</div>

							<div className="lpBrowser">
								<div className="lpBrowserBar">
									<div className="lpBrowserBarInner">
										<LockIcon />
										<span>www.lena.app</span>
									</div>
									<div className="lpBrowserArrow">
										<ArrowRight />
									</div>
								</div>
							</div>

						</div>}
					</div>

					{/*AppState.instance.deviceInfo.desktop &&
						<div className="section sectionCompetition">

							<div className="title titleCompetition">{Translate.T("de.Winter Gewinnspiel", "en.Winter Competition", "advannouncement.titlecompetition")}</div>

							<div className="subTitle">{Translate.T("de.Gewinne\nAbenteuerkarten, Glitzer-Sticker und Poster", "en.Win\nAdventure Cards, Glitter Stickers and Poster", "competition.title")}</div>

							<div className="how">{Translate.T("de.Unter den ersten 100 gesammelten Abenteuerkarten verlosen wir bis 15. Februar für Deine kleinen Abenteurer:",
								"en.Within the first 100 collected Adventure Cards we draw the following prizes until February 15th, 2019:", "competition.how")}</div>

							<div className="prizes">
								<div className="prize">{Translate.T("de.3x LENAs Abenteuer Kartenset bestehend aus 5 lustigen Spielkarten der tierischen Helden, 1 Poster Deines LENA Lieblingstiers und dem LENA Abenteuer Sticker-Set",
									"en.3x LENA's Adventure Cardsets compiled of 5 fun playcards of animal heroes, 1 poster of your favorite animal hero and 1 LENA Adventure glitter sticker set", "competition.prize0")}</div>
								<div className="prize">{Translate.T("de.10x LENAs Abenteuer Kartenset bestehend aus 5 lustigen Spielkarten der tierischen Helden",
									"en.10x LENA's Adventure Cardsets compiled of 5 fun playcards of animal heroes", "competition.prize1")}</div>
								<div className="prize">{Translate.T("de.87x Je eine LENA Abenteuerkarte nach Wahl", "en.87x 1 LENA Adventure Card of your choice", "competition.prize2")}</div>
							</div>

							<div className="disclaimerBtn" onClick={this.OnDisclaimer}>
								{Translate.T("de.Teilnahmebedingungen", "en.Terms and conditions", "competition.disclaimer.button")}
							</div>

							{this.showDisclaimer && <div className="disclaimer" onClick={this.OnDisclaimer}>
								{Translate.T("de.Der Gewinn kann nicht übertragen oder in bar abgelöst werden. Es gelten die Nutzungsbedingungen und Datenschutzbestimmungen von Lena24 e.U.",
									"en.The prizes can't be transferred or redeemed for cash. Terms of Service and Privacy Policy of Lena24 e.U. apply.", "competition.disclaimer")}
							</div>}

						</div>*/}

					<div className="section sectionFAQ">
						<div className="title titleFAQ">{Translate.T("de.Häufig gestellte Fragen", "en.Frequently asked questions", "advannouncement.titlefaq")}</div>
						
						{this.RenderFaqItem("q0", Translate.T("de.Wie erfahre ich bei welchem Ausflugsziel Abenteuerkarten gesammelt werden können?", "en.How can I find out at which destinations Adventure Cards can be collected?", "advannouncement.q0"),
							Translate.T("de.Mittels eines Smartphones www.lena.app aufrufen und einloggen. Im Menü wählst Du 'Abenteuer' (das Feuer-Symbol) und findest dort die Abenteuerkarten, die Du sammeln kannst. Im Moment gibt es das Kartenset 'Kira' und 'Felix'. Die verschiedenen Karten kannst Du anklicken. Dabei erfährst Du bei welchem Ausflugsziel, die Karten gefunden werden können. Alternativ kannst Du auf das Landkarten-Symbol klicken, um zu sehen, welche Abenteuerkarten in Deiner Nähe ergattert werden können.",
							"en.Using your smartphone, go to www.lena.app and log in. In the bottom menu, choose 'Adventures' (the fire icon). You can now find the available Adventure Cards that you can collect. " +
							"At the moment I have the card set 'Kira' and 'Felix' available for you. Tabbing any card, you will find at which destinations this card is to be found. Alternatively, you can tab on the map icon to see which Adventure Cards are available near you.", "advannouncement.a0"))}

						{this.RenderFaqItem("q1", Translate.T("de.Ich möchte eine ganz konkrete Abenteuerkarte sammeln. Wie erfahre ich, wo genau diese Karte gefunden werden kann?",
							"en.I would like to collect a very specific Adventure Card. How do I find out where I can find it?", "advannouncement.q1"),
							Translate.T("de.Mittels eines Smartphones www.lena.app aufrufen und einloggen. Im Menü wählst Du 'Abenteuer' (das Feuer-Symbol) und findest dort die Abenteuerkarten, die Du sammeln kannst. Die verschiedenen Karten kannst Du anklicken. Dabei erfährst Du bei welchem Ausflugsziel, die gewünschte Karte gefunden werden kann. ",
							"en.Using your smartphone, go to www.lena.app and log in. In the bottom menu, choose 'Adventures' (the fire icon). You can now find the available Adventure Cards that you can collect. " +
							"Tabbing any card, you will find at which destinations this card is to be found.", "advannouncement.a1"))}

						{this.RenderFaqItem("cardset", Translate.T("de.Wozu gibt es Karten-Sets?", "en.What are those Card Sets?", "advannouncement.q2"),
							Translate.T("de.Beim Vervollständigen von Karten-Sets wirst Du mit Bonuskarten belohnt. Weiters wird es schon in wenigen Wochen möglich sein, vollständige Sets als reale Karten zu beziehen.",
							"en.Completing card sets will reward you with bonus cards. Furthermore, in a few weeks it will be possible to obtain complete sets as real (physical) cards.", "advannouncement.a2"))}

						{this.RenderFaqItem("multiple_cards", Translate.T("de.Kann ich eine Karte auch mehrmals sammeln?", "en.Can I also collect a card several times?", "advannouncement.q3"),
							Translate.T("de.Ja, Du kannst jede Karte beliebig oft sammeln und bekommst mit jeder Karte weitere Informationen über das Tier oder die Pflanzen. Allerdings kannst Du jede Karte nur einmal am Tag bekommen.",
							"en.Yes, you can collect each card as often as you like and get more information about the animal or plant with each card. However, you can only get each card once a day.", "advannouncement.a3"))}

						{this.RenderFaqItem("adventures", Translate.T("de.Was sind LENAs Abenteuer?", "en.What are LENA's Adventures", "advannouncement.q4"),
							Translate.T("de.In Kürze kannst Du bei bestimmten Ausflugszielen interaktive Abenteuer bewältigen. Lass Dich von den Geschichten inspirieren, löse die Rätsel und sammle weitere Abenteuerkarten.",
							"en.In the near future you can experience interactive adventures at certain destinations. Be inspired by the stories, solve the puzzles and collect more Adventure Cards.", "advannouncement.a4"))}
					</div>

					<div className="section section5">
						<img src="/assets/logos/a.jpg" alt="" />
						<img src="/assets/logos/b.jpg" alt="" />
						<img src="/assets/logos/c.jpg" alt="" />
						<img src="/assets/logos/d.png" alt="" />
						<img src="/assets/logos/e.png" alt="" />
					</div>

					{this.props.showLenaIcon && <LandingPageFooter />}
				</div>
				
			</div>
		);
	}
}
