import React from 'react';
import AppState from '../../AppState';
import StepBase from './StepBase';
import Translate from '../../Translate';
import PhotoAlbum from 'material-ui-icons/PhotoAlbum';
import PlaceIcon from 'material-ui-icons/Place';
import QuestionAnswer from 'material-ui-icons/QuestionAnswer';
import FeedbackIcon from 'material-ui-icons/Feedback';
import AccountRequestPage from '../../pages/AccountRequestPage';

export default class StepSelectGoal extends StepBase
{
	answerOptionsNotLoggedIn = [];

	constructor(props)
	{
		super(props);

		this.AddAnswerOption({
			icon: <PhotoAlbum />,
			label: Translate.T("en.Share trip experience", "de.Ausflugserfahrung teilen", "ugctrigger.createexperience"),
			onClick: this.OnOptionClicked("experience"),
			id: "experience"
		});

		this.AddAnswerOption({
			icon: <PlaceIcon />,
			label: Translate.T("de.Ich bin hier", "en.Check-In", "ugctrigger.checkin"),
			onClick: this.OnOptionClicked("checkin"),
			id: "checkin"
		});

		this.AddAnswerOption({
			icon: <PhotoAlbum />,
			label: Translate.T("en.Add Photo/Video", "de.Foto/Video hinzufügen", "ugctrigger.photovideo"),
			onClick: this.OnOptionClicked("media"),
			id: "media"
		});

		this.AddAnswerOption({
			icon: <QuestionAnswer />,
			label: Translate.T("en.Ask Question", "de.Frage stellen", "ugctrigger.askquestion"),
			onClick: this.OnOptionClicked("question"),
			id: "question"
		});

		this.AddAnswerOption({
			icon: <FeedbackIcon />,
			label: Translate.T("en.Give Feedback", "de.Feedback geben", "ugctrigger.feedback"),
			onClick: this.OnOptionClicked("feedback"),
			id: "feedback"
		});

		// this.AddAnswerOption({
		// 	label: Translate.T("en.Create New Activity", "de.Neues Ausflugsziel eintragen", "ugctrigger.newactivity"),
		// 	onClick: this.OnOptionClicked("newactivity"),
		// 	id: "newactivity"
		// });

		this.answerOptionsNotLoggedIn.push({
			label: Translate.T("de.Persönlichen Account anlegen", "en.Create personal account", "lena.selectgoal.register"),
			onClick: this.OnRegister,
			id: "register"
		});
	}

	GetId()
	{
		return "goal";
	}

	GeneratedDataIds()
	{
		return ["goal"];
	}

	IsEditEnabled(contextData) { return false; }

	Undo(contextData)
	{
		delete contextData["goal"];
	}

	OnRegister = (e, contextData) =>
	{
		setTimeout(() => {
			AccountRequestPage.Show();
		}, 50);
	}

	OnOptionClicked = (goalId) => (e, contextData) =>
	{
		this.OnStepDone(contextData, {
			goal: goalId
		});
	}

	renderStepLena(contextData)
	{
		if (AppState.instance.IsLoggedIn())
			return this.renderLenaMsg(Translate.T("de.Hallo {0}, wie kann ich Dir helfen?", "en.Hi {0}, how can I help you?", "createxp.whichgoal",
				[AppState.instance.userProfile.loginData.firstName]));
		else
			return this.renderLenaMsg(Translate.T("de.Hallo! Ich bin LENA und helfe Dir gerne bei Deiner Freizeitgestaltung!\nWer bist Du?",
			"en.Hi! I'm LENA and can help you find great things to do for your family!\nWho are you?", "lena.initial.unknown"));
	}

	renderUserSelection(contextData)
	{
		return this.renderUserMsg(this.GetAnswerOptionLabel(contextData["goal"]), contextData);
	}

	renderUserOptions(contextData)
	{
		if (AppState.instance.IsLoggedIn())
			return this.renderAnswerOptions(contextData, this.answerOptions);
		else
			return this.renderAnswerOptions(contextData, this.answerOptionsNotLoggedIn);
	}
}
