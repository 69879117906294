import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import AppState from '../AppState';
import Translate from '../Translate';
import { observable } from 'mobx';
import ShareIcon from 'material-ui-icons/Share';
import Utils from '../Utils';
import Button from 'material-ui/Button';
import SocialComment from './SocialComment';
import Menu, { MenuItem } from 'material-ui/Menu';
import Divider from 'material-ui/Divider';
import CheckIcon from 'material-ui-icons/Check';
import WarningIcon from 'material-ui-icons/Warning';
import ErrorIcon from 'material-ui-icons/Error';
import SocialInteraction from './SocialInteraction';
import RestApi from '../RestApi';

const styles = {
};

@observer
class ContentSocial extends React.Component
{
	maxNumComments = 3;
	changeCount = 0;
	shareMenuAnchor;
	@observable showShareMenu = false;
	@observable showHeartComments = false;

	constructor(props)
	{
		super(props);

		if (this.props.defaultNumComments !== undefined)
			this.maxNumComments = this.props.defaultNumComments;
	}

	OnTextFieldChanged = (event) =>
	{
		this.newComment = event.target.value;
		if (this.props.onCommentChanged)
			this.props.onCommentChanged(this.newComment);
	}

	OnHeartStatusClicked = () =>
	{
		if (!this.showHeartComments)
			this.showHeartComments = true;
	}

	OnMessageSent = (message, withHeart) =>
	{
		this.maxNumComments++;

		if (withHeart)
		{
			if (this.props.content === undefined)
				this.props.content = 1;
			else
				this.props.content.hearts++;
		}

		if (!this.props.content.userMessages)
			this.props.content.userMessages = [message];
		else
			this.props.content.userMessages.push(message);
			
		const merged = this.MergeMessages(this.props.content.userMessages);
		if (merged)
			this.maxNumComments--;

		if (this.props.onLayoutChanged)
			this.props.onLayoutChanged();
		this.forceUpdate();
	}

	MergeMessages(userMessages)
	{
		if (!userMessages || userMessages.length < 2)
			return false;

		var merged = false;
		for (var i = userMessages.length - 1; i >= 1; --i)
		{
			var m = userMessages[i];
			var p = userMessages[i - 1];

			if (!m.text && !p.text &&
				m.sender === p.sender &&
				(m.hearts > 0 && p.hearts > 0))
			{
				this.changeCount++;
				p.hearts += m.hearts;
				userMessages.splice(i, 1);
				merged = true;
				continue;
			}
		}

		return merged;
	}

	ShowMoreComments = () =>
	{
		this.maxNumComments += 5;
		const numComments = this.GetNumTextComments();

		if (this.maxNumComments < numComments && (numComments - this.maxNumComments <= 3))
			this.maxNumComments = numComments;

		if (this.props.onLayoutChanged)
			this.props.onLayoutChanged();
		this.forceUpdate();
	}

	OnCommentsClicked = () =>
	{
		const numComments = this.GetNumTextComments();
		this.maxNumComments = numComments;
		if (this.props.onLayoutChanged)
			this.props.onLayoutChanged();
		this.forceUpdate();
	}

	OnOpenShareMenu = (e) =>
	{
		this.shareMenuAnchor = e.currentTarget;
		this.showShareMenu = true;
		e.stopPropagation();
		e.preventDefault();
	}

	OnCloseShareMenu = () =>
	{
		this.showShareMenu = false;
	}

	OnChangeShare = (flag) => () =>
	{
		this.OnCloseShareMenu();

		if (this.props.onChangeShare)
			this.props.onChangeShare(flag);
	}

	OnOpenStatus = (statusFlag) => () =>
	{
		if (statusFlag === 1) // pending approval
		{
			AppState.instance.screenMessage.Show(Translate.T(
				"de.Überprüfung ausstehend!\nDu möchtest Deinen Beitrag mit der LENA Community teilen - toll! Vorher muss ich ihn jedoch noch prüfen. Deine Familie, Freunde und Follower können ihn jedoch schon sehen!",
				"en.Pending approval!\nYou want to share this post with the LENA Community - great! I need to approve it first though. Your family, friends and follower can already see your post!", "social.share.hint.pendingapproval"));
		}
		else if (statusFlag === 2) // rejected
		{
			AppState.instance.screenMessage.Show(Translate.T(
				"de.Beitrag abgelehnt!\nDein Beitrag erfüllt nicht die Richtlinien und ist daher nicht für die LENA Community geeignet!",
				"en.Post Rejected!\nYour post is not in line with our terms and therefore not appropriate for the LENA Community!", "social.share.hint.rejected"));
		}
	}

	OnCommentDeleted = (commentId) =>
	{
		this.forceUpdate();
	}

	GetNumTextComments()
	{
		var result = 0;

		const content = this.props.content;
		if (content.userMessages)
		{
			for (var i = 0; i < content.userMessages.length; ++i)
			{
				const comment = content.userMessages[i];
				if (comment.text && comment.text.length > 0)
					++result;
				else if (this.showHeartComments)
					++result;
			}
		}

		return result;
	}

	OnFacebookShare = (e) =>
	{
		e.stopPropagation();
		e.preventDefault();
		this.OnCloseShareMenu();

		if (AppState.instance.deviceInfo.ios)
		{
			// On iOS we need to open the new window in this click-handler and set the target URL later.
			// Otherwise iOS does not open the window (in an async handler)
			var windowReference = window.open();

			RestApi.SendRequestText("/gentimelineshareurl", {id: this.props.content.id})
			.then((shareUrlPrefix) =>
			{
				var shareUrl = "https://www.lena.app" + shareUrlPrefix;
				var href = Utils.CreateFacebookShareUrl(shareUrl, this.props.content.desc);

				windowReference.location = href;
			});
		}
		else
		{
			RestApi.SendRequestText("/gentimelineshareurl", {id: this.props.content.id})
			.then((shareUrlPrefix) =>
			{
				var shareUrl = "https://www.lena.app" + shareUrlPrefix;
				Utils.ShareOnFacebook(shareUrl, this.props.content.desc);
			});
		}
	}

	OnEmailShare = (e) =>
	{
		e.preventDefault();
		this.OnCloseShareMenu();

		RestApi.SendRequestText("/gentimelineshareurl", {id: this.props.content.id})
		.then((shareUrlPrefix) =>
		{
			var shareUrl = "https://www.lena.app" + shareUrlPrefix;
			const body = encodeURIComponent(shareUrl);
			window.location.href = "mailto:?body=" + body;
		});
	}

	render()
	{
		if (!this.props.content)
			return null;

		const content = this.props.content;

		const numHearts = content.hearts || 0;
		const numComments = this.GetNumTextComments();

		var shownComments = [];
		if (content.userMessages && this.maxNumComments > 0)
		{
			for (var i = content.userMessages.length - 1; i >= 0; --i)
			{
				const comment = content.userMessages[i];

				if (!this.showHeartComments && comment.hearts > 0 && !comment.text)
					continue;

				shownComments.unshift(comment);
				if (shownComments.length >= this.maxNumComments)
					break;
			}
		}

		const sharedWithStr = Utils.GetShareString(content.share);

		var menuItems = [];
		for (i = 4; i >= 0; --i)
		{
			menuItems.push(
				(<MenuItem className={"shareMenuItem" + (content.share === i ? " checked":" unchecked")} key={i} onClick={this.OnChangeShare(i)}>{content.share === i && <CheckIcon/>}{Utils.GetShareString(i)}</MenuItem>)
			);
		}

		if (menuItems.length > 0)
		{
			menuItems.push(
				(<Divider component="li" key="divider" />)
			);
		}

		menuItems.push(
			(<MenuItem className={"shareMenuItem facebook"} key={"facebook"}
				onClick={this.OnFacebookShare}
			>
				<img key="img" className="facebookShareLogo" src="/assets/facebookinv.svg" alt="facebook logo" />
				{Translate.T("de.Auf Facebook teilen", "en.Share on Facebook", "timeline.share.facebook")}
			</MenuItem>)
		);
		menuItems.push(
			(<MenuItem className={"shareMenuItem email"} key={"email"}
				onClick={this.OnEmailShare}
			>
				<div className="emailShareLogo">@</div>
				{Translate.T("de.Per Email teilen", "en.Share via Email", "timeline.share.email")}
			</MenuItem>)
		);

		var isOwnContent = false;
		if (AppState.instance.IsLoggedIn())
			isOwnContent = content.username === AppState.instance.userProfile.username;

		var statusFlag;
		if (isOwnContent)
		{
			if (content.share === 4 && (content.status === "0" || content.status === 0)) // pending approval
				statusFlag = 1;
			else if (content.status === "2" || content.status === 2) // rejected
				statusFlag = 2;
		}

		const isSmallScreen = window.innerWidth < 350;

		return (
			<div className="contentSocial">
				<div className="socialOverview">
					<Button className="socialHeartsBtn" onClick={this.OnHeartStatusClicked}>
						{Utils.RenderHeartIcon(16, 16,  numHearts > 0 ? "#e31c46" : "#aaa", numHearts > 0 ? "#e31c46" : "none", 2)}
						{numHearts}
					</Button>
					<Button className="socialCommentsBtn" onClick={this.OnCommentsClicked}>
						{Utils.RenderCommentIcon(16, 16, "#aaa", "none", 2)}
						{!isSmallScreen && numComments !== 1 && Translate.T("en.{0} Comments", "de.{0} Kommentare", "social.buttons.comments", [numComments])}
						{!isSmallScreen && numComments === 1 && Translate.T("en.{0} Comment", "de.{0} Kommentar", "social.buttons.comment", [numComments])}
						{isSmallScreen && <span>{numComments}</span>}
					</Button>

					<Button className="socialShareBtn" onClick={isOwnContent ? this.OnOpenShareMenu : undefined}>
						<ShareIcon />
						{sharedWithStr}
					</Button>

					{statusFlag && <Button className={"socialStatusBtn status" + statusFlag} onClick={this.OnOpenStatus(statusFlag)}>
						{statusFlag === 1 && <WarningIcon />}
						{statusFlag === 2 && <ErrorIcon />}
					</Button>}

					<Menu
						anchorEl={this.shareMenuAnchor}
						open={this.showShareMenu}
						onClose={this.OnCloseShareMenu}
					>
						{menuItems}
					</Menu>
				</div>

				{numComments > 0 && this.maxNumComments < numComments && <div className="loadMoreComments">
					<Button onClick={this.ShowMoreComments}>
						{Translate.T("de.Weitere Kommentare laden", "en.Load more comments", "social.buttons.showmorecomments")}
					</Button>
				</div>}

				{shownComments.length > 0 && <div className="socialComments">
					{shownComments.map((comment) =>
					{
						return (
							<SocialComment key={comment.id + "_" + this.changeCount}
								comment={comment}
								contentId={content.id}
								onDeleted={this.OnCommentDeleted}
							/>
						);
					})}
				</div>}

				<SocialInteraction
					receiverTimelineItemId={content.id}
					enableSendHeart={!isOwnContent}
					onMessageSent={this.OnMessageSent}
					onCommentChanged={this.props.onCommentChanged}
				/>
			</div>
		);
	}
}

ContentSocial.propTypes = {
	classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(ContentSocial);