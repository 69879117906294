import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import AppState from '../../AppState';
import Translate from '../../Translate';
import AdvCard from './AdvCard';
import Utils from '../../Utils';
import AdvAnnouncement from './AdvAnnouncement';
import Button from 'material-ui/Button';
import AdvCardMap from './AdvCardMap';
import Tracking from '../../Tracking';
import PhotoCamera from 'material-ui-icons/PhotoCamera';
import { observable } from 'mobx';
import { CircularProgress } from 'material-ui';
import AccountRequestPage from '../../pages/AccountRequestPage';

const styles = theme => ({
});

@observer
class AdvCardFetcher extends React.Component
{
	advManager;
	@observable isUploading = 0;
	@observable uploadProgress = 0;

	constructor(props)
	{
		super(props);

		this.advManager = AppState.instance.advManager;
	}

	componentDidMount()
	{
		this.advManager.onConfigChanged.Add(this.OnConfigChanged);
		this.advManager.onInstancesChanged.Add(this.OnInstancesChanged);
		AppState.instance.onGeoLocation.Add(this.OnGeoPosition);

		Tracking.SendEvent("cardfetcher", {activityId: this.props.activityId});
	}

	componentWillUnmount()
	{
		AppState.instance.onGeoLocation.Remove(this.OnGeoPosition);
		this.advManager.onConfigChanged.Remove(this.OnConfigChanged);
		this.advManager.onInstancesChanged.Remove(this.OnInstancesChanged);
	}

	OnConfigChanged = () =>
	{
		this.forceUpdate();
	}

	OnInstancesChanged = () =>
	{
		this.forceUpdate();
	}

	OnGeoPosition = () =>
	{
		this.forceUpdate();
	}

	OnCardClicked = (e) =>
	{
		e.stopPropagation();
	}

	OnMarkerClicked = (marker) =>
	{
	}

	OnHintClicked = () =>
	{
		if (this.props.onHintClicked)
		{
			this.props.onHintClicked();
		}
		else if (AppState.instance.desktopAppInstance)
		{
			AppState.instance.desktopAppInstance.ChangePage(AppState.TabIndexAdventure);
		}
		else
		{
			AppState.instance.screenMessage.ShowDialog(undefined, AdvAnnouncement, undefined, undefined, undefined, {
				onCta: this.OnAdvHintCta,
				onBackClicked: this.OnAdvHintCta,
				ctaLabel: Translate.T("de.Schließen", "en.Close", "cardcollectionpage.hint.close")
			}, false, undefined, true);
		}
	}

	OnAdvHintCta = () =>
	{
		AppState.instance.advManager.OnAdvAnnouncementCta();
		AppState.instance.screenMessage.CloseDialog();
	}

	UploadObjectPhoto = () =>
	{
		setTimeout(() => {
			AppState.instance.appContainer.PromptImage(this.UploadImage);
		}, 50);
	}

	UploadImage = (file) =>
	{
		this.isUploading = 1;

		this.advManager.UploadAdventureImage(file, undefined,
			this.OnUploadProgress,
			this.OnUploadError,
			this.OnLocalImageData,
			this.OnUploadDone,
			this.props.activityId,
			this.OnPopupClosed);
	}

	OnPopupClosed = () =>
	{
		console.log("popup closed");
	}

	OnUploadDone = () =>
	{
		this.isUploading = 2;
	}

	OnUploadProgress = (event) =>
	{
		this.uploadProgress = Math.round(event.loaded / event.total * 100);
		//console.log("upload: " + this.uploadProgress);
	}

	OnUploadError = (errorStr) =>
	{
		this.isUploading = -1;
		console.log("Upload error:");
		console.log(errorStr);

		// tooearly, instancecap, invalidlocation, objectdetection
		if (errorStr === "tooearly")
		{
			AppState.instance.screenMessage.Show(Translate.T("de.Du hast die Karte erst kürzlich bekommen. Probiere es ab morgen nochmal.",
				"en.You received this card recently. Please try again tomorrow.", "cardfetcher.imagerecognition.error.tooearly"));
		}
		else if (errorStr === "instancecap")
		{
			AppState.instance.screenMessage.Show(Translate.T("de.Du hast diese Karte schon oft erhalten. Suche doch mal eine andere.",
				"en.You received this card often. Please try another card instead.", "cardfetcher.imagerecognition.error.instancecap"));
		}
		else if (errorStr === "invalidlocation")
		{
			AppState.instance.screenMessage.Show(Translate.T("de.Du kannst diese Karte an Deiner aktuellen Position nicht erhalten.",
				"en.You can't receive this card at your current location.", "cardfetcher.imagerecognition.error.invalidlocation"));
		}
		else //if (errorStr === "objectdetection")
		{
			AppState.instance.screenMessage.Show(Translate.T("de.Oje - ich konnte das Objekt am Foto leider nicht erkennen. Bitte versuche eine andere Perspektive.",
				"en.Sorry - I was not able to recognize the object in your photo. Please try a different perspective.", "cardfetcher.imagerecognition.error.objectdetection"));
		}
	}

	OnLocalImageData = (dataUrl) =>
	{
		//console.log("preview of image upload: " + dataUrl);
	}

	OnLoginButton = () =>
	{
		setTimeout(() => {
			AccountRequestPage.Show();	
		}, 10);
	}

	OnCardClickedDesktop = () =>
	{
		
	}

	render()
	{
		const isLoggedIn = AppState.instance.IsLoggedIn();
		const isDesktop = AppState.instance.deviceInfo.desktop;

		const cardIds = this.advManager.GetCardIdsOfActivity(this.props.activityId);
		if (cardIds.length === 0)
			return null;

		const loc = AppState.instance.GetBestLocation();

		var hasAll = true;
		for (var i = 0; i < cardIds.length; ++i)
		{
			var instances = this.advManager.GetCardInstances(cardIds[i]);
			if (instances.length === 0)
			{
				hasAll = false;
				break;
			}
		}

		var needsImageRecon = false;
		for (i = 0; i < cardIds.length; ++i)
		{
			const card = this.advManager.GetCardById(cardIds[i]);
			if ((card.spawnTriggers & 4) === 4)
			{
				needsImageRecon = true;
				break;
			}
		}

		var isNearActivity = false;
		var defaultZoom = 18;
		if (this.props.activityLat && this.props.activityLng)
		{
			const distanceMeters = Utils.CalcDistanceMeters(loc.latitude, loc.longitude, this.props.activityLat, this.props.activityLng);
			//console.log("D: " + distanceMeters);
			isNearActivity = distanceMeters < 1000;

			if (distanceMeters > 350)
				defaultZoom = 14;
			else if (distanceMeters > 200)
				defaultZoom = 15;
			else if (distanceMeters > 140)
				defaultZoom = 16;
			else if (distanceMeters > 100)
				defaultZoom = 17;
		}

		return (
			<div className="collectingCards">
				<div className="detailSectionTitle">
					{hasAll && Translate.T("de.Alle Karten eingesammelt", "en.All cards collected", "cardfetcher.title.hasall")}
					{!hasAll && cardIds.length === 1 && Translate.T("de.Schnapp Dir die Abenteuerkarte beim Ausflugsziel", "en.Catch the adventure card at the venue", "cardfetcher.title.singular")}
					{!hasAll && cardIds.length !== 1 && Translate.T("de.Schnapp Dir die Abenteuerkarten beim Ausflugsziel", "en.Catch the adventure cards at the venue", "cardfetcher.title.plural")}

					<Button className="hint" onClick={this.OnHintClicked}>?</Button>
				</div>

				{!isLoggedIn &&
				<div className="advLoginHint cardFetcher">
					<div className="advLoginHintText">
						{Translate.T("en.Create account to start collecting adventure cards.", "de.Sammle Deine ersten Abenteuerkarten mit Deinem persönlichen Account.", "bookmarks.loginrequest")}
					</div>
					<Button onClick={this.OnLoginButton}>
						{Translate.T("en.Create Account", "de.Jetzt registrieren", "createaccount")}
					</Button>
				</div>}
				
				<div className="cards">
					{cardIds.map((cardId, index) =>
					{
						return (
							<AdvCard key={cardId + "_" + index} cardId={cardId} isFirst={index === 0} isLast={index === cardIds.length - 1}
								showTitle={true} showPlus={false} showNumInstances={false}
								showCheckIfAvailable={true}
								context="AdvCardFetcher"
								onClick={isDesktop ? this.OnCardClickedDesktop : undefined}
							/>
						);
					})}
				</div>

				{!hasAll && needsImageRecon && isLoggedIn && /*isNearActivity &&*/
					<div className="imageReconButton">
						<Button onClick={this.isUploading === 0 || this.isUploading === 2 ? this.UploadObjectPhoto : undefined}>
							{this.isUploading === 1 && <CircularProgress variant="determinate" size={24} value={this.uploadProgress}/>}
							{this.isUploading !== 1 && <PhotoCamera/>}
							{Translate.T("de.Foto hochladen", "en.Upload photo", "cardfetcher.uploadphoto")}
						</Button>
					</div>}

				{!hasAll && needsImageRecon && isLoggedIn &&
					<div className="explainImageRecon">
						<PhotoCamera/>
						{Translate.T("de.Erhalte Karten mit diesem Symbol, indem Du ein Foto des entsprechenden Objektes am Ausflugsort hochlädst.\nIch erkenne automatisch, ob Du das richtige Objekt gefunden hast. Viel Spaß!",
							"en.To receive cards with this symbol upload a photo of the corresponding object at the venue.\nI will automatically detect if you found the right object. Have fun!", "cardfetcher.explainImageRecon")}
					</div>}

				{!hasAll && isNearActivity && !AppState.instance.deviceInfo.desktop && isLoggedIn &&
					<AdvCardMap
						id={"advCardFetcherMap"}
						title={Translate.T("de.Gehe zum Kartensymbol um die Abenteuerkarte aufzuheben",
							"en.Move to the card symbol to collect the adventure card", "cardfetcher.map.title")}
						defaultZoom={defaultZoom}
						onMarkerClicked={this.OnMarkerClicked}
					/>}
			</div>
		);
	}
}

AdvCardFetcher.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AdvCardFetcher);