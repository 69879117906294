import StepBase from './StepBase';
import Translate from '../../Translate';
import AppState from '../../AppState';

export default class StepFinish extends StepBase
{
	constructor(props)
	{
		super(props);

		this.AddAnswerOption({
			label: Translate.T("de.Nein, Beitrag löschen", "en.No, delete contribution", "finishanswer.nodelete"),
			onClick: this.OnOptionClicked("delete"),
			id: "delete"
		});

		this.AddAnswerOption({
			label: Translate.T("de.Ja", "en.Yes", "finishanswer.yes"),
			onClick: this.OnOptionClicked("yes"),
			id: "yes"
		});
	}

	GetId()
	{
		return "finish";
	}

	GeneratedDataIds()
	{
		return ["finish"];
	}

	Undo(contextData) {}

	OnOptionClicked = (finishId) => (e, contextData) =>
	{
		if (finishId === "delete")
		{
			contextData.bot.OnCancel();
		}
		else if (finishId === "yes")
		{
			contextData.progress = true;
			contextData.onChanged();

			AppState.instance.chatBotManager.Upload(contextData, () =>
			{
				contextData.progress = false;
				contextData.success = true;
				contextData.onChanged();

				this.OnStepDone(contextData, {
					finish: finishId
				});
			}, () =>
			{
				contextData.progress = false;
				contextData.error = true;
				contextData.onChanged();

				//TODO: what to do in case of error -> LENA should explain and offer options
				// this.OnStepDone(contextData, {
				// 	finish: finishId
				// });
			});
		}
		else
		{
			this.OnStepDone(contextData, {
				finish: finishId
			});
		}
	}

	renderStepLena(contextData)
	{
		const audience = contextData["audience"];

		if (audience === 1) // family
			return this.renderLenaMsg(Translate.T("de.Alles klar! Soll ich es Deiner Familie nun senden?",
				"en.All clear! Should I send it to the family now?", "createxp.finish.family"));

		if (audience === 2) // friend
			return this.renderLenaMsg(Translate.T("de.Alles klar! Soll ich es Deinen Freunden und Deiner Familie nun senden?",
				"en.All clear! Should I send it to friends and family now?", "createxp.finish.friends"));

		if (audience === 3) // followers
			return this.renderLenaMsg(Translate.T("de.Alles klar! Soll ich es Deinen Followern, Freunden und Deiner Familie nun senden?",
				"en.All clear! Should I send it to your followers, friends and family now?", "createxp.finish.followers"));

		if (audience === 4) // everyone
			return this.renderLenaMsg(Translate.T("de.Alles klar! Soll ich es der LENA Community nun senden?",
				"en.All clear! Should I send it to the LENA Community now?", "createxp.finish.everyone"));

		return this.renderLenaMsg(Translate.T("de.Alles klar! Soll ich es nun speichern?",
			"en.All clear! Should I save it now?", "createxp.finish.nobody"));
	}

	renderUserSelection(contextData)
	{
		return this.renderUserMsg(this.GetAnswerOptionLabel(contextData["finish"]), contextData);
	}

	renderUserOptions(contextData)
	{
		return this.renderAnswerOptions(contextData, this.answerOptions);
	}
}
