import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import AppState from '../../AppState';
import Map from '../Map';
import Utils from '../../Utils';

const styles = theme => ({
});

@observer
class AdvCardMap extends React.Component
{
	advManager;

	constructor(props)
	{
		super(props);

		this.advManager = AppState.instance.advManager;
	}

	componentDidMount()
	{
		AppState.instance.onGeoLocation.Add(this.OnGeoPosition);
	}

	componentWillUnmount()
	{
		AppState.instance.onGeoLocation.Remove(this.OnGeoPosition);
	}

	OnGeoPosition = () =>
	{
		this.forceUpdate();
	}

	CreateMapQuery = () =>
	{
		var loc = AppState.instance.GetBestLocation();

		var query = {};
		
		query.referenceLocation = loc;

		query.language = AppState.instance.userProfile.userLang;
		
		query.cardLocationsOnly = this.props.cardLocationsOnly !== undefined ? this.props.cardLocationsOnly : true;

		return query;
	}

	GetMarkerIcon = (item, selected) =>
	{
		var result = {};

		const allowAdvMarker = this.props.showAdventureMarkers !== false;

		if (allowAdvMarker && item.type === "advlocation" && item.title === "adventurelocation")
		{
			result.url = "/assets/cards/map_adv";

			result.url += selected ? "_s.png" : ".png";

			result.size = [44, 24];
			result.anchor = [-44 / 2, -24 / 2];

			result.zIndexOffset = 1000;
		}
		else if (item.type === "advlocation")
		{
			const locId = item.id;
			var cardIds = this.advManager.GetCardIdsOfLocation(locId);

			// Already fetched all cards from that location?
			var hasAll = true;
			for (var i = 0; i < cardIds.length; ++i)
			{
				var instances = this.advManager.GetCardInstances(cardIds[i]);
				if (instances.length === 0)
				{
					hasAll = false;
					break;
				}
			}

			if (hasAll)
				result.url = "/assets/cards/map_card_check";
			else
				result.url = "/assets/cards/map_card_new";

			result.url += selected ? "_s.png" : ".png";

			result.size = [16, 22];
			result.anchor = [-16 / 2, -22 / 2];

			result.zIndexOffset = 900;
		}
		else
		{
			const iconSize = 28;

			var catName = "";
			const a = item;
			if (a.categories && a.categories.length > 0)
				catName = a.categories[0];
			
			//const catColor = Utils.GetCategoryColor(catName);

			if (selected)
				result.url = Utils.GetCategoryIconUrl(catName, 'white', '#e31c46', iconSize, iconSize);
			else
				result.url = Utils.GetCategoryIconUrl(catName, "#ccc", 'white', iconSize, iconSize);

			// if (selected)
			// 	result.url = Utils.RenderStarUrl(iconSize, iconSize, "white", "#e31c46");
			// else
			// 	result.url = Utils.RenderStarUrl(iconSize, iconSize, "#ccc", "white");

			result.size = [iconSize, iconSize];
			result.anchor = [-iconSize / 2, -iconSize / 2];
		}
		
		result.origin = [0, 0];

		return result;
	}

	OnMarkerClicked = (marker) =>
	{
		if (this.props.onMarkerClicked)
			this.props.onMarkerClicked(marker);
	}

	render()
	{
		//const cardIds = this.advManager.GetCardIdsOfActivity(this.props.activityId);
		//if (cardIds.length === 0)
		//	return null;

		const loc = AppState.instance.GetBestLocation();
		var centerLat = loc.latitude;
		var centerLng = loc.longitude;

		var defaultZoom = this.props.defaultZoom || 15;

		return (
			<div className="advcardmap">
				{this.props.title && <div className="title">{this.props.title}</div>}
				<Map
					id={this.props.id}
					gestureHandling={this.props.gestureHandling || "cooperative"}
					ignoreActiveFilter={true}
					mapSimpleMode={false}
					disableFilterButton={true}
					disableContent={true}
					disableContentStoreCache={true}
					createQuery={this.CreateMapQuery}
					apiEndpoint={"/advmap"}
					markerIcon={this.GetMarkerIcon}
					onMarkerClicked={this.OnMarkerClicked}
					centerLat={centerLat}
					centerLng={centerLng}
					zoomFactor={defaultZoom}
					onTouchStart={this.props.onMapStartMove}
					onTouchEnd={this.props.onMapMoved}
					onInit={this.props.onInit}
				/>
			</div>
		);
	}
}

AdvCardMap.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AdvCardMap);