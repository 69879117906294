import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import Dialog from 'material-ui/Dialog';
import Tracking from '../Tracking';
import FilterContainer from '../components/FilterContainer';
import IconButton from 'material-ui/IconButton';
import CloseIcon from 'material-ui-icons/Close';
import AppState from '../AppState';
import Button from 'material-ui/Button';
import Translate from '../Translate';
import ReactIScroll from '../iscroll/react-iscroll';
import iScroll from 'iscroll/build/iscroll-probe.js';
import Slide from 'material-ui/transitions/Slide';
import $ from 'jquery';
import RestApi from '../RestApi';

const styles = {
	paper: {
		paddingLeft: '24px',
		paddingRight: '24px',
		width: '100%',
		margin: '0px',
	},
	paperXL: {
		paddingLeft: '24px',
		paddingRight: '24px',
		width: '100%',
		margin: '0px',
		minWidth: '400px',
		minHeight: '600px'
	},
	closeButtonRoot: {
		width: '32px',
		height: '32px',
	}
};

function Transition(props)
{
	return <Slide direction="up" {...props} />;
}

@observer
class FilterPage extends React.Component
{
	@observable dialogOpen = false;
	iScrollInstance;
	clearCounter = 0;
	checkedFilters = {};
	lastScrollTop = 0;
	resultCount;
	@observable isLoading = false;
	additionalQueryParams;

	constructor(props)
	{
		super(props);

		AppState.instance.filterPageInstance = this;
	}

	HandleRequestClose = () =>
	{
		if (AppState.instance.filterBackup)
		{
			AppState.instance.RestoreFilterBackup(AppState.instance.filterBackup, true);
			AppState.instance.filterBackup = undefined;
		}

		AppState.instance.RedirectOnFilterChanged(undefined);

		this.dialogOpen = false;
	}

	static Show(queryParams)
	{
		AppState.instance.filterBackup = AppState.instance.CreateFilterBackup();

		AppState.instance.RedirectOnFilterChanged(AppState.instance.filterPageInstance.OnFilterChanged);

		AppState.instance.filterPageInstance.additionalQueryParams = queryParams;
		AppState.instance.filterPageInstance.dialogOpen = true;
		AppState.instance.filterPageInstance.resultCount = undefined;

		Tracking.OnPageView("filter");
	}

	GetFinalFilterStack()
	{
		var query = AppState.instance.CreateFilterBackup();

		if (!query.filterStack)
			query.filterStack = [];

		for (var filterId in this.checkedFilters)
			if (this.checkedFilters[filterId] === true)
			{
				var found = false;
				for (var j = 0; j < query.filterStack.length; ++j)
				{
					if (query.filterStack[j] === Number(filterId))
					{
						found = true;
						break;
					}
				}
				if (!found)
					query.filterStack.push(Number(filterId));
			}

		return query.filterStack;
	}

	UpdateResultCount()
	{
		this.isLoading = true;

		var query = AppState.instance.CreateFilterBackup();

		var startEnd = AppState.instance.contentStore.GetStartEndDateFilter();
		query.tripStartDate = startEnd.start;
		query.tripEndDate = startEnd.end;

		query.location = AppState.instance.GetBestLocation();
		
		query.filterStack = this.GetFinalFilterStack();

		if (this.additionalQueryParams)
		{
			for (var p in this.additionalQueryParams)
			{
				query[p] = this.additionalQueryParams[p];
			}
		}

		if (query.searchRadius)
			query.searchRadius *= 1000;

		RestApi.SendRequestText("/filterresult", query).then((data) =>
		{
			console.log(data);

			if (data !== undefined)
				this.resultCount = Number(data);
			else
				this.resultCount = undefined;

			this.isLoading = false;
			this.forceUpdate();
		})
		.catch((error) =>
		{
			this.resultCount = undefined;
			this.isLoading = false;
		});
	}

	OnFilterChanged = () =>
	{
		this.UpdateResultCount();
	}

	RenderHeader()
	{
		return (
			<div className="filterPageHeader">
				<IconButton
					classes={{root: this.props.classes.closeButtonRoot}}
					onClick={this.HandleRequestClose} aria-label="Close"
					className="btnCloseFilterPage"
				>
					<CloseIcon />
				</IconButton>

				<Button onClick={this.OnClear} className="btnClearFilter">
					{Translate.T("en.Clear", "de.Löschen", "filter.clear")}
				</Button>
			</div>
		);
	}

	RenderFooter()
	{
		var resultCountStr;
		if (this.resultCount > 100)
			resultCountStr = "100+";
		else if (this.resultCount > 0)
			resultCountStr = this.resultCount;

		return (
			<div className="filterPageFooter">
				<Button onClick={this.OnShowResult} className="btnShowResult">
					{this.resultCount === undefined && Translate.T("en.Show activities", "de.Zeige Aktivitäten", "filter.showresult")}
					{this.resultCount === 0 && Translate.T("en.No activities found", "de.Keine Aktivitäten gefunden", "filter.showresult.empty")}
					{this.resultCount === 1 && Translate.T("en.Show 1 activity", "de.Zeige 1 Aktivität", "filter.showresult.one")}
					{this.resultCount > 1 && Translate.T("en.Show {0} activities", "de.Zeige {0} Aktivitäten", "filter.showresult.multiple", [resultCountStr])}
				</Button>
			</div>
		);
	}

	OnShowResult = () =>
	{
		AppState.instance.RedirectOnFilterChanged(undefined);

		AppState.instance.filterStack = this.GetFinalFilterStack();
		this.checkedFilters = {};

		// Don't restore backup when we close this dialog
		AppState.instance.filterBackup = undefined;
		AppState.instance.OnFilterChanged();

		if (this.additionalQueryParams)
		{
			//HACK: set clear exit behaviour
			// Map Mode
			this.dialogOpen = false;
		}
		else
		{
			var url = AppState.instance.filterManager.CreateFilterUrl(AppState.instance.filterStack);
			Tracking.OnPageView(url);
			AppState.instance.mainPageInstance.context.router.history.push(url);

			AppState.instance.mainPageInstance.forceUpdate();

			this.dialogOpen = false;
			if (AppState.instance.appInstance)
				AppState.instance.appInstance.OnTabbarChanged(undefined, AppState.TabIndexExplore);
		}
	}

	OnClear = () =>
	{
		this.clearCounter++;
		AppState.instance.ClearFilter(false);
		this.checkedFilters = {};
		this.forceUpdate();
		this.UpdateResultCount();
	}

	OnScrollRefresh = (iScrollInstance) =>
	{
		this.iScrollInstance = iScrollInstance;
	}

	OnScroll = (iScrollInstance) =>
	{
		const st = iScrollInstance.y;

		if (!AppState.instance.deviceInfo.desktop)
		{
			const delta = st - this.lastScrollTop;		
			if (st > 0)
			{
				$(".filterPageHeader").removeClass("hidden");
			}
			else if (delta < -2)
			{
				$(".filterPageHeader").addClass("hidden");
			}
			else if (delta > 2)
			{
				$(".filterPageHeader").removeClass("hidden");
			}
		}

		this.lastScrollTop = st;
	}

	OnFilterContainerChanged = () =>
	{
		if (this.iScrollInstance)
		{
			setTimeout(() =>
			{
				this.iScrollInstance.refresh();
			}, 300);
		}
	}

	OnToggleFilter = (filterId) =>
	{
		const isChecked = this.IsFilterChecked(filterId);
		if (isChecked)
		{
			this.checkedFilters[filterId] = false;
		}
		else
		{
			this.checkedFilters[filterId] = true;
		}
		this.OnFilterChanged();
	}

	IsFilterChecked = (filterId) =>
	{
		const isChecked = this.checkedFilters[filterId];
		if (isChecked === undefined)
		{
			return AppState.instance.filterManager.IsFilterActive(filterId);
		}

		return isChecked;
	}

	render()
	{
		//Tracking.OnRender("FilterPage");

		/*if (!AppState.instance.mainPageInstance)
			return null;*/

		var fullScreen = true;
		if (AppState.instance.deviceInfo.desktop ||
			AppState.instance.deviceInfo.tablet)
			fullScreen = false;

		var iScrollOptions = {
			mouseWheel: true,
			scrollbars: false,
			scrollX: false,
			scrollY: true,
			freeScroll: false,
			fadeScrollbars: true,
			bounce: true,
			momentum: true,
			eventPassthrough: false,
			tap: true,
			click: true,
			interactiveScrollbars: false,
			probeType: 3
		};

		return (
			<Dialog
				fullScreen={fullScreen}
				open={this.dialogOpen}
				onClose={this.HandleRequestClose}
				classes={{
					paper: AppState.instance.deviceInfo.desktop ? this.props.classes.paperXL : this.props.classes.paper,
				}}
				className={AppState.instance.isLandingPage ? "filterPageDialog filterPageDialogLP" : "filterPageDialog"}
				TransitionComponent={Transition}
			>
				{this.RenderHeader()}

				{!AppState.instance.deviceInfo.desktop && <ReactIScroll
					key={"scroller"} id={"filterPageScroller"}
					iScroll={iScroll}
					options={iScrollOptions}
					//onScrollStart={this.OnScrollStart}
					onScroll={this.OnScroll}
					//onScrollEnd={this.OnScrollEnd}
					onRefresh={this.OnScrollRefresh}
					className={"filterPageScroller"}
					//onDestroy={this.OnScrollDestroy(msg.id)}
				>
					<FilterContainer
						onContainerChanged={this.OnFilterContainerChanged}
						clearCounter={this.clearCounter}
						onToggleFilter={this.OnToggleFilter}
						isFilterChecked={this.IsFilterChecked}
					/>
				</ReactIScroll>}

				{AppState.instance.deviceInfo.desktop &&
				<FilterContainer
					onContainerChanged={this.OnFilterContainerChanged}
					clearCounter={this.clearCounter}
					onToggleFilter={this.OnToggleFilter}
					isFilterChecked={this.IsFilterChecked}
				/>}

				{this.RenderFooter()}
			</Dialog>
		);
	}
}

FilterPage.propTypes = {
	classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(FilterPage);