import React from 'react';
import Button from 'material-ui/Button';
import AppState from './AppState';
import Delegate from './utils/Delegate';
import RestApi from './RestApi';
import Translate from './Translate';
import raf from 'raf';

export default class SocialManager
{
	profiles = [];
	friendRequests = [];
	onProfilesChanged = new Delegate();
	isRequested = {};
	onLoadedCallbacks = {};

	Init()
	{
		AppState.instance.AddOnAuthDone(this.OnAuthDone);
	}

	OnAuthDone = () =>
	{
		this.RefreshData();

		this.UpdateFriendRequests();
		setInterval(() =>
		{
			this.UpdateFriendRequests();
		}, 2 * 60 * 1000);
	}

	SendMessage(timelineItemId, username, text, hearts, parentId, idType, id)
	{
		var q = {
			timelineItem: timelineItemId,
			receiver: username,
			parentId: parentId,
			text: text,
			hearts: hearts,
			receiverIdType: idType,
			receiverId: id
		};

		return RestApi.SendRequest("/sendmessage", q)
		.then((message) =>
		{
			return message;
		});
	}

	DeleteMessage(msgId, timelineItemId, receiverUsername)
	{
		var q = {
			id: msgId,
			receiverTimelineId: timelineItemId,
			receiverUsername: receiverUsername,
		};

		return RestApi.SendRequestText("/deletemessage", q)
		.then((message) =>
		{
			return message;
		});
	}

	UpdateFriendRequests()
	{
		if (!AppState.instance.GeneralDataLoadingEnabled())
			return;
			
		RestApi.SendRequest("/getfriendrequests", {})
		.then((frs) =>
		{
			if (frs)
				this.OnFriendRequestsReceived(frs);
		})
		.catch((error) =>
		{
			console.log("error while fetching friend requests: ");
			console.log(error);
		});
	}

	OnFriendRequestsReceived(frs)
	{
		// var changed = false;
		// if (frs)
		// {
		// 	for (var i = 0; i < frs.length; ++i)
		// 	{
		// 		var existing = this.GetFriendRequestById(frs[i].id);
		// 		if (existing)
		// 		{
		// 			if ((!existing.accepted && frs[i].accepted) ||
		// 				(!existing.declined && frs[i].declined))
		// 			{
		// 				changed = true;
		// 			}
		// 		}
		// 		else
		// 		{
		// 			changed = true;
		// 		}
		// 	}
		// }
		// else
		// {
		// 	if (this.friendRequests && this.friendRequests.length > 0)
		// 		changed = true;
		// }

		this.friendRequests = frs;
		//if (changed)
			this.onProfilesChanged.Call();

		this.UpdatePendingRequestsIndicator();
	}

	UpdatePendingRequestsIndicator()
	{
		var frs = this.GetPendingRequests();
		// incoming requests
		for (var i = frs.length - 1; i >= 0; --i)
		{
			if (!frs[i].incoming)
				frs.splice(i, 1);
		}

		AppState.instance.showNewIndicator[AppState.TabIndexProfile] = frs.length > 0 ? frs.length : undefined;
		if (AppState.instance.appContainer)
			AppState.instance.appContainer.forceUpdate();
	}

	GetFriendRequestById(id)
	{
		for (var i = 0; i < this.friendRequests.length; ++i)
		{
			if (this.friendRequests[i].id === id)
				return this.friendRequests[i];
		}
		return undefined;
	}

	GetFriendRequestByUsername(username)
	{
		for (var i = 0; i < this.friendRequests.length; ++i)
		{
			if (this.friendRequests[i].username === username)
				return this.friendRequests[i];
		}
		return undefined;
	}

	RefreshData()
	{
		for (var i = 0; i < this.profiles.length; ++i)
		{
			this.LoadProfile(this.profiles[i].username);
		}
	}

	GetSuccessMessage(profile, type)
	{
		const name = this.GetDisplayName(profile);

		if (type === "friendrequest" || type === "familyrequest")
			return (
				Translate.T("de.Anfrage an {0} erfolgreich gesendet.",
					"en.Successfully sent request to {0}.",
					"social.friendrequest.success", [name])
			);

		if (type === "unfriendrequest")
			return (
				Translate.T("de.Erfolgreich aus Freundesliste entfernt.",
					"en.Successfully removed from friends list.",
					"social.unfriendrequest.success")
			);

		if (type === "followrequest")
			return (
				Translate.T("de.Du folgst nun {0}.",
					"en.You are now following {0}.",
					"social.follow.success", [name])
			);

		if (type === "unfollowrequest")
			return (
				Translate.T("de.Du folgst nun {0} nicht mehr.",
					"en.You are not following {0} anymore.",
					"social.unfollow.success", [name])
			);

		if (type === "movefriend")
			return (
				Translate.T("de.{0} erfolgreich verschoben.",
					"en.{0} successfully moved.",
					"social.movefriend.success", [name])
			);
	}

	GetDisplayName(profile)
	{
		if (profile.firstName && profile.lastName)
			return profile.firstName + " " + profile.lastName;
		if (profile.firstName)
			return profile.firstName;
		if (profile.lastName)
			return profile.lastName;
		return undefined;
	}

	OnResendVerificationError(e)
	{
		AppState.instance.screenMessage.Show(
			Translate.T("de.Fehler beim Senden der Email. Bitte probiere es später nochmal!",
				"en.Error while sending email. Please try again later.", "social.resendverification.error"));
	}

	ResendVerification = () =>
	{
		AppState.instance.screenMessage.OnRequestClose();

		RestApi.SendRequestText("/resendverification", {})
		.then((msg) =>
		{
			if (msg === "ok")
			{
				AppState.instance.screenMessage.Show(
					Translate.T("de.Email gesendet.", "en.Email send.", "social.resendverification.success"));
			}
			else
			{
				this.OnResendVerificationError();
			}
		})
		.catch((e) =>
		{
			this.OnResendVerificationError(e);
		});
	}

	SocialRequest(username, type, requestId, accept, asFamily)
	{
		if (!AppState.instance.IsLoggedIn())
		{
			AppState.instance.screenMessage.Show(
				Translate.T("de.Du musst eingeloggt sein um mit anderen in Kontakt zu treten.",
					"en.You need to be logged in to get in contact with others.", "social.error.notloggedin"));
			return new Promise((resolve, reject) => { return reject("not logged in"); });
		}

		if (!AppState.instance.userProfile.loginData.isVerified)
		{
			AppState.instance.screenMessage.Show(
				(<div className="errorEmailNotVerified">
					{Translate.T("de.Du musst Deine Email-Adresse verifizieren um mit anderen in Kontakt zu treten.",
						"en.You need to verify your email address to get in contact with others.", "social.error.notverified")}
					<Button onClick={this.ResendVerification}>
						{Translate.T("de.Verifizierungsemail nochmal senden",
							"en.Resend verification email", "social.error.notverified.resend")}
					</Button>
				</div>)
			);
			return new Promise((resolve, reject) => { return reject("not verified"); });
		}

		return RestApi.SendRequest("/" + type, {username: username,
			requestId: requestId, accept: accept, asFamily: asFamily})
		.then((profileDataList) =>
		{
			this.UpdateFriendRequests();

			//console.log(profileDataList);
			if (profileDataList)
			{
				for (var i = 0; i < profileDataList.length; ++i)
				{
					this.UpdateProfile(profileDataList[i]);
					if (profileDataList[i].username === username)
					{
						AppState.instance.screenMessage.Show(this.GetSuccessMessage(profileDataList[i], type));
					}
				}
			}
			else
			{
				//console.log("!profileData");
				AppState.instance.screenMessage.Show(
					Translate.T("de.Fehler bei der Anfrage. Bitte versuche es später nochmal!",
						"en.Error during the request. Please try again later!", "social.error"));
			}
		})
		.catch((e) =>
		{
			//console.log("catch");
			AppState.instance.screenMessage.Show(
				Translate.T("de.Fehler bei der Anfrage. Bitte versuche es später nochmal!",
					"en.Error during the request. Please try again later!", "social.error"));
			return new Promise((resolve, reject) => { return reject(e); });
		});
	}

	HasRelationshipWith(username)
	{
		const profile = this.GetProfile(username);
		if (!profile)
			return false;
		return (profile.isFamily || profile.isFriend || profile.isFollowingMe || profile.iamFollowing);
	}

	IsRequestPending(username)
	{
		for (var i = 0; i < this.friendRequests.length; ++i)
		{
			const fr = this.friendRequests[i];
			if (fr.username === username)
			{
				if (!fr.accepted && !fr.declined)
					return true;
			}
		}
		return false;
	}

	GetPendingRequests()
	{
		var result = [];

		for (var i = 0; i < this.friendRequests.length; ++i)
		{
			const fr = this.friendRequests[i];
			if (!fr.accepted && !fr.declined)
				result.push(fr);
		}

		return result;
	}

	CancelRequest(username)
	{
		RestApi.SendRequest("/cancelfriendrequest", {username: username})
		.then((frs) =>
		{
			this.OnFriendRequestsReceived(frs);
		});
	}

	RequestReaction(requestId, accept, asFamily)
	{
		this.SocialRequest(undefined, "/requestreaction", requestId, accept, asFamily);
	}

	GetProfiles()
	{
		return this.profiles;
	}

	GetProfileIndex(username)
	{
		for (var i = 0; i < this.profiles.length; ++i)
		{
			if (this.profiles[i].username === username)
				return i;
		}

		return -1;
	}

	GetProfile(username, loadIfNotFound, cb)
	{
		for (var i = 0; i < this.profiles.length; ++i)
		{
			if (this.profiles[i].username === username)
			{
				return this.profiles[i];
			}
		}

		if (loadIfNotFound || loadIfNotFound === undefined)
		{
			this.Subscribe(username, cb);

			if (!this.isRequested[username])
			{
				this.LoadProfile(username)
					.then((profile) =>
					{
						if (profile)
						{
							const cbs = this.onLoadedCallbacks[profile.username];
							if (cbs)
							{
								for (var i = 0; i < cbs.length; ++i)
									cbs[i](profile);
							}
							this.onLoadedCallbacks[profile.username] = undefined;
						}
					});
			}
		}

		return undefined;
	}

	Unsubscribe(username, cb)
	{
		var cbs = this.onLoadedCallbacks[username];
		if (cbs)
		{
			for (var i = cbs.length - 1; i >= 0; --i)
				if (cbs[i] === cb)
				{
					cbs.splice(i, 1);
					break;
				}
		}
	}

	Subscribe(username, cb)
	{
		if (cb)
		{
			var list = this.onLoadedCallbacks[username];
			if (list === undefined)
				list = [];
			list.push(cb);
			this.onLoadedCallbacks[username] = list;
		}
	}

	LoadProfile(username)
	{
		this.isRequested[username] = true;

		return RestApi.SendRequest("/publicprofile", {username: username})
		.then((profile) =>
		{
			this.UpdateProfile(profile);
			return profile;
		})
		.catch(() =>
		{
		});
	}

	UpdateProfile(profile)
	{
		if (!profile)
			return;

		const idx = this.GetProfileIndex(profile.username);
		if (idx >= 0)
		{
			this.profiles[idx] = profile;
		}
		else
		{
			this.profiles.push(profile);
		}

		if (!profile.displayName)
			profile.displayName = this.CalcDisplayName(profile);

		AppState.instance.imageManager.Store(profile.imageData);

		raf(() => {
			this.onProfilesChanged.Call();
		});
	}

	CalcDisplayName(profile)
	{
		if (profile.firstName && profile.lastName)
			return profile.firstName + " " + profile.lastName;
		if (profile.firstName)
			return profile.firstName;
		if (profile.lastName)
			return profile.lastName;
		return profile.username;
	}
}