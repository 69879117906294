import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import AppState from '../AppState';
import Utils from '../Utils';
import Moment from 'moment';
import Dialog, {DialogActions} from 'material-ui/Dialog';
import TimePicker from './TimePicker';
import InfiniteCalendar from 'react-infinite-calendar';
import Button from 'material-ui/Button';
import Translate from '../Translate';

const styles = {
	dialogPaper: {
		margin: 0
	},
	timePickerHeader: {
		background: 'rgb(227, 28, 70)',
	},
};

@observer
class ConfigDateTime extends React.Component
{
	@observable showCalendar = false;
	@observable showTimePicker = false;

	@observable today = new Date();
	date = new Date();
	time = 9 * 60;
	timePicker;

	minDate;
	maxDate;

	calendarLocale;

	isInForceUpdate = false; //HACK: bug workaround

	static Init()
	{
		var data = AppState.instance.configDateTime;

		data.calendarLocaleEn = {
			name: 'en-US',
			blank: 'Select a date...',
			headerFormat: 'dddd, MMM Do',
			longHeaderFormat: 'MMM Do, YYYY',
			todayLabel: {
				long: 'Today',
			},
			weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
			weekStartsOn: 0,
			locale: require('date-fns/locale/en'),
		};
		data.calendarLocaleDe = {
			name: 'de-DE',
			blank: 'Wähle einen Tag...',
			headerFormat: 'dddd, D. MMM',
			longHeaderFormat: 'D.M.YYYY',
			todayLabel: {
				long: 'Heute',
			},
			weekdays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
			weekStartsOn: 1,
			locale: require('date-fns/locale/de'),
		};
	}

	constructor(props)
	{
		super(props);

		this.minDate = new Date(this.today.getFullYear(), this.today.getMonth() - 12, this.today.getDate());
		this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth() + 12, this.today.getDate());

		var data = AppState.instance.configDateTime;
		this.calendarLocale = (AppState.instance.userProfile.userLang === "de") ? data.calendarLocaleDe : data.calendarLocaleEn;
	}

	componentDidMount()
	{
		if (this.props.date)
		{
			this.date = new Date(this.props.date);
			this.time = this.props.time;
			this.forceUpdate();
		}
	}

	componentDidUpdate(prevProps)
	{
		if (this.props.minDate)
			this.minDate = this.props.minDate;
		if (this.props.maxDate)
			this.maxDate = this.props.maxDate;

		if (this.props.date)
		{
			var changed = false;
			if (this.time !== this.props.time)
				changed = true;

			var newDate = new Date(this.props.date);
			if (Utils.CompareDates(newDate, this.date) !== 0)
				changed = true;

			if (changed && Utils.IsValidDate(newDate) && !this.isInForceUpdate)
			{
				this.date = newDate;
				this.time = this.props.time;
				this.isInForceUpdate = true;
				try
				{
					this.forceUpdate();
				}
				catch (e)
				{
					this.isInForceUpdate = false;
				}
			}
		}

		if (!prevProps.openTimePicker && this.props.openTimePicker === true)
		{
			this.timePicker = this.time;
			this.showTimePicker = true;
		}

		if (!prevProps.openDatePicker && this.props.openDatePicker === true)
		{
			this.showCalendar = true;
		}
	}

	OpenTimeSelection = () =>
	{
		setTimeout(() => {
			this.timePicker = this.time;
			this.showTimePicker = true;	
		}, 50);
	}

	CloseTimeSelection = () =>
	{
		this.showTimePicker = false;
		if (this.props.onCloseTimePicker)
			this.props.onCloseTimePicker();
	}

	OpenCalendar = () =>
	{
		setTimeout(() => {
			this.showCalendar = true;	
		}, 50);
	}

	OnCloseCalendar = () =>
	{
		this.showCalendar = false;
		if (this.props.onCloseDatePicker)
			this.props.onCloseDatePicker();
	}

	OnDateSelected = (date) =>
	{
		this.date = date;

		if (this.props.onDateSelected)
			this.props.onDateSelected(date);

		this.showCalendar = false;
	}

	renderDate(date)
	{
		var dayStr = "";

		if (this.calendarLocale && date !== undefined)
		{
			var m = Moment(date);
			dayStr = m.format(this.calendarLocale.headerFormat);

			if (this.today.getFullYear() !== date.getFullYear())
				dayStr = m.format(this.calendarLocale.longHeaderFormat);

			var mToday = Moment(this.today);
			m.startOf('day');
			mToday.startOf('day');
			if (m.isSame(mToday))
				dayStr = this.calendarLocale.todayLabel.long;

			var mTomorrow = Moment(this.today);
			mTomorrow.add(1, 'd');
			mTomorrow.startOf('day');
			if (m.isSame(mTomorrow))
				dayStr = Translate.FindTranslation("tomorrow");
		}

		return (
			<Button raised onClick={this.OpenCalendar}>
				{dayStr}
      		</Button>
		);
	}

	renderTime(time)
	{
		var startTime = Utils.MinutesToTimeStr(time);
		return (
			<Button raised onClick={this.OpenTimeSelection}>
				{startTime}
      		</Button>
		);
	}

	render()
	{
		if (this.props.dialogOnly)
		{
			return this.RenderDialogs();
		}

		return (
			<div className="tripConfig">
				<div className="tripConfigDate">{this.renderDate(this.date)}</div>
				<div className="tripConfigTime">{this.renderTime(this.time)}</div>

				{this.RenderDialogs()}
			</div>
		);
	}

	RenderCalendar()
	{
		const themeColor = "rgb(227, 28, 70)";

		const calendarTheme = {
			accentColor: themeColor,
			floatingNav: {
				background: 'rgba(255, 255, 255, 0.8)',
				chevron: themeColor,
				color: themeColor,
			},
			headerColor: themeColor,
			selectionColor: themeColor,
			textColor: {
				active: '#FFF',
				default: '#333',
			},
			todayColor: themeColor,
			weekdayColor: 'white'
		};

		var cal = null;
		if (this.showCalendar)
		{
			cal =
				<Dialog open={this.showCalendar} onClose={this.OnCloseCalendar}
					classes={{paper: this.props.classes.dialogPaper}}
					className={"configDateTimeDialog " + AppState.instance.GetPlatformClasses()}
				>
					<div id="calendarPosition">
						<InfiniteCalendar
							width='100vw'
							height={300}
							selected={this.date}
							className="calendar"
							rowHeight={48}
							minDate={this.minDate}
							min={this.minDate}
							max={this.maxDate}
							locale={this.calendarLocale}
							theme={calendarTheme}
							displayOptions={{
								showHeader: true
							}}
							onSelect={this.OnDateSelected}
						/>
					</div>
				</Dialog>;
		}
		return cal;
	}

	OnTimePickerChanged = (timeDate) =>
	{
		this.timePicker = timeDate.getHours() * 60 + timeDate.getMinutes();
	}

	OnTimeSelected = () =>
	{
		setTimeout(() => {
			this.time = this.timePicker;
			this.showTimePicker = false;
	
			if (this.props.onTimeSelected)
				this.props.onTimeSelected(this.time);	
		}, 50);
	}

	RenderDialogs()
	{
		var hours = Math.floor(this.time / 60);
		var timeDate = new Date();
		timeDate.setHours(hours);
		timeDate.setMinutes(this.time - hours * 60);

		return (
			<div className="configDateTimeDialogs">

				{this.RenderCalendar()}

				<Dialog
					open={this.showTimePicker}
					onClose={this.CloseTimeSelection}
					className={"configDateTimeDialog " + AppState.instance.GetPlatformClasses()}
				>
					<TimePicker
						mode={AppState.instance.userProfile.userLang === 'en' ? '12h' : '24h'}
						value={timeDate}
						onChange={this.OnTimePickerChanged}
					/>
					
					<DialogActions>
						<Button onClick={this.CloseTimeSelection} color="primary">
							{Translate.T("en.Cancel", "de.Abbrechen", "picker.cancel")}
						</Button>
						<Button onClick={this.OnTimeSelected} color="primary">
							{Translate.T("en.OK", "de.OK", "picker.ok")}
						</Button>
					</DialogActions>
				</Dialog>

			</div>
		);
	}
}

ConfigDateTime.propTypes = {
	classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(ConfigDateTime);