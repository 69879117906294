import React from 'react';

export default class DetectSwipe extends React.Component
{
	enabled = false;
	preventDefault = false;
	threshold = 20;

	node;

	startX;
    startY;
    isMoving = false;

	componentDidMount()
	{
		this.enabled = 'ontouchstart' in document.documentElement;

		this.node = this.refs.detectSwipe;
		this.node.addEventListener('touchstart', this.onTouchStart, false);
	}

	componentWillUnmount()
	{
        this.node.removeEventListener('touchstart', this.onTouchStart);
	}

	onTouchStart = (e) =>
	{
		if (e.touches.length === 1)
		{
			this.startX = e.touches[0].pageX;
			this.startY = e.touches[0].pageY;
			this.isMoving = true;
			this.node.addEventListener('touchmove', this.onTouchMove, false);
			this.node.addEventListener('touchend', this.onTouchEnd, false);
		}
	}
	
	onTouchMove = (e) =>
	{
		if (this.preventDefault) { e.preventDefault(); }

		if (this.isMoving)
		{
			var x = e.touches[0].pageX;
			var y = e.touches[0].pageY;
			var dx = this.startX - x;
			var dy = this.startY - y;
			var dir;
			var ratio = window.devicePixelRatio || 1;

			if (Math.abs(dx) > Math.abs(dy))
			{
				if (Math.abs(dx) * ratio >= this.threshold)
				{
					dir = dx > 0 ? 'left' : 'right';
				}
				else if (Math.abs(dy) * ratio >= this.threshold)
				{
					dir = dy > 0 ? 'up' : 'down'
				}
			}
			else
			{
				if (Math.abs(dy) * ratio >= this.threshold)
				{
					dir = dy > 0 ? 'up' : 'down'
				}
				else if (Math.abs(dx) * ratio >= this.threshold)
				{
					dir = dx > 0 ? 'left' : 'right';
				}
			}

			if (dir)
			{
				this.onTouchEnd();
				if (this.props.onSwipe)
					this.props.onSwipe(dir);
			}
		}
	}
	
	onTouchEnd = () =>
	{
		this.node.removeEventListener('touchmove', this.onTouchMove);
		this.node.removeEventListener('touchend', this.onTouchEnd);
		this.isMoving = false;
	}

	render()
	{
		return (
			<div id="detectSwipe" ref="detectSwipe"
				className={this.props.className}
				onClick={this.props.onClick}
			>
				{this.props.children}
			</div>
		);
	}
}