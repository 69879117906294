import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import Translate from '../Translate';
import Button from 'material-ui/Button';
import RcTooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import AppState from '../AppState';
import Utils from '../Utils';
import List, { ListItem, ListItemText } from 'material-ui/List';
import Checkbox from 'material-ui/Checkbox';
import Rating from '../components/Rating';
import TripDateTime from './TripDateTime';
import EuroSymbol from 'material-ui-icons/EuroSymbol';
import AgeSelection from './AgeSelection';

const styles = {
};

const Handle = Slider.Handle;
const handle = (props) =>
{
	const { value, dragging, index, ...restProps } = props;
	return (
		<RcTooltip
			prefixCls="rc-slider-tooltip"
			overlay={value}
			visible={dragging}
			placement="top"
			key={index}
		>
			<Handle value={value} {...restProps} />
		</RcTooltip>
	);
};

@observer
class FilterContainer extends React.Component
{
	@observable showAllFilters = false;
	lastClearCounter = -1;
	@observable ageButtonStates = [];

	componentDidMount()
	{
		AppState.instance.filterManager.AddOnFilterChanged(this.OnFiltersChanged);

		var newFlags = [];
		const ageGroups = AppState.instance.userProfile.filterAgeGroups;
		newFlags.push(ageGroups && ageGroups.indexOf(0) >= 0);
		newFlags.push(ageGroups && ageGroups.indexOf(1) >= 0);
		newFlags.push(ageGroups && ageGroups.indexOf(2) >= 0);
		newFlags.push(ageGroups && ageGroups.indexOf(3) >= 0);
		newFlags.push(ageGroups && ageGroups.indexOf(4) >= 0);

		this.ageButtonStates = newFlags;
	}

	componentWillUnmount()
	{
		AppState.instance.filterManager.RemoveOnFilterChanged(this.OnFiltersChanged);
	}

	OnFiltersChanged = () =>
	{
		this.forceUpdate();

		if (this.props.onContainerChanged)
			this.props.onContainerChanged();
	}

	OnDistanceChanged = (newValue) =>
	{
		AppState.instance.userProfile.filterMaxDistance = newValue;
		this.forceUpdate();
	}

	OnAfterDistanceChanged = (newValue) =>
	{
		AppState.instance.userProfile.filterMaxDistance = newValue;
		AppState.instance.OnFilterChanged();
		this.forceUpdate();
	}

	OnDurationChanged = (newValue) =>
	{
		AppState.instance.userProfile.filterMaxDuration = newValue;
		AppState.instance.OnFilterChanged();
		this.forceUpdate();
	}

	GetOrAddFilterGroup(name, list)
	{
		for (var i = 0; i < list.length; ++i)
		{
			if (list[i].title === name)
				return list[i];
		}

		var newGroup = {
			title: name,
			items: []
		};
		list.push(newGroup);
		return newGroup;
	}

	GetFilterGroups()
	{
		var filters = AppState.instance.filterManager.GetFilters();

		var result = [];

		for (var i = 0; i < filters.length; ++i)
		{
			const f = filters[i];
			if ((f.showInFilterDialog || f.showInFilterDialogDetails) && f.groupName)
			{
				var group = this.GetOrAddFilterGroup(f.groupName, result);
				group.items.push(f);
			}
		}

		for (i = 0; i < result.length; ++i)
		{
			group = result[i];
			group.items.sort(function(a,b) {return a.title.localeCompare(b.title);} );
		}

		return result;
	}

	GetFilterSelection()
	{
		var filters = AppState.instance.filterManager.GetFilters();

		var result = [];

		for (var i = 0; i < filters.length; ++i)
		{
			const f = filters[i];
			if (f.showInFilterDialog ||
				(this.showAllFilters && f.showInFilterDialogDetails))
			{
				if (!f.groupName)
					result.push(f);
			}
		}

		if (this.showAllFilters)
		{
			for (i = 0; i < filters.length; ++i)
			{
				const f = filters[i];
				if (f.showInFilterDialogDetails && !f.groupName)
				{
					// Check if filter already contained
					var found = false;
					for (var j = 0; j < result.length; ++j)
					{
						if (result[j].id === f.id)
						{
							found = true;
							break;
						}
					}
					if (!found)
						result.push(f);
				}
			}
		}

		return result;
	}

	OnShowAllFilters = () =>
	{
		this.showAllFilters = true;

		if (this.props.onContainerChanged)
			this.props.onContainerChanged();
	}

	OnFilterClicked = (f) => () =>
	{
		this.props.onToggleFilter(f.id);
		//AppState.instance.mainPageInstance.ToggleFilter(f.id, false);
		this.forceUpdate();
	}

	HandleFilterChecked = (f) => (e) =>
	{
		//e.preventDefault();
		//e.stopPropagation();

		this.props.onToggleFilter(f.id);
		//AppState.instance.mainPageInstance.ToggleFilter(f.id, false);
		this.forceUpdate();
	}

	OnRatingStarClick = (nextValue, prevValue, name) =>
	{
		if (nextValue === AppState.instance.userProfile.filterMinRating)
		{
			this.OnClearRating();
			return;
		}

		AppState.instance.userProfile.filterMinRating = nextValue;
		AppState.instance.OnFilterChanged();
		this.forceUpdate();
	}

	OnClearRating = () =>
	{
		AppState.instance.userProfile.filterMinRating = undefined;
		AppState.instance.OnFilterChanged();
		this.forceUpdate();
	}

	OnPriceStarClick = (nextValue, prevValue, name) =>
	{
		if (nextValue - 1 === AppState.instance.userProfile.filterMaxPrice)
		{
			this.OnClearPrice();
			return;
		}

		AppState.instance.userProfile.filterMaxPrice = nextValue - 1;
		AppState.instance.OnFilterChanged();
		this.forceUpdate();
	}

	OnClearPrice = () =>
	{
		AppState.instance.userProfile.filterMaxPrice = undefined;
		AppState.instance.OnFilterChanged();
		this.forceUpdate();
	}

	OnClickSink = (e) =>
	{
		e.stopPropagation();
		e.preventDefault();
	}

	OnAgeButtonClicked = (e, ageGroupIdx) =>
	{
		this.ageButtonStates[ageGroupIdx] = !this.ageButtonStates[ageGroupIdx];

		AppState.instance.userProfile.SetAgeGroups(this.ageButtonStates, () =>
		{
			AppState.instance.OnFilterChanged();
			this.forceUpdate();
		});
	}

	render()
	{
		var thisPtr = this;

		const frontFilters = this.GetFilterSelection();
		const filterGroups = this.GetFilterGroups();

		var forcedValue = {};
		if (this.lastClearCounter !== this.props.clearCounter)
		{
			forcedValue.value = AppState.instance.userProfile.filterMaxDistance;
			this.lastClearCounter = this.props.clearCounter;
		}

		var priceIconsDesc = [];
		priceIconsDesc.push(Translate.T("en.Free", "de.Kostenlos", "pricing.desc.Upper.0"));
		priceIconsDesc.push(Translate.T("en.Low-priced", "de.Kostengünstig", "pricing.desc.Upper.1"));
		priceIconsDesc.push(Translate.T("en.Moderate", "de.Moderat", "pricing.desc.Upper.2"));
		priceIconsDesc.push(Translate.T("en.Expensive", "de.Teuer", "pricing.desc.Upper.3"));

		var priceDesc;
		var priceStarIndex;
		if (AppState.instance.userProfile.filterMaxPrice !== undefined)
		{
			priceDesc = priceIconsDesc[AppState.instance.userProfile.filterMaxPrice];
			priceStarIndex = AppState.instance.userProfile.filterMaxPrice + 1;
		}

		return (
			<div className="filterContainer" onClick={this.OnClickSink}>

				<div className="filterFiltersFeature">
					{Translate.T("en.Date & Time", "de.Tag & Uhrzeit", "filter.datetime")}

					<TripDateTime />
				</div>

				<div className="filterLocation">
					<div className="filterSectionTitle">
						{Translate.T("en.Search Radius", "de.Suchradius", "filter.location.maxdistance")}
					</div>

					<div className="filterLocationValue">
						{AppState.instance.userProfile.filterMaxDistance} km
					</div>

					<div className="filterLocationSlider">
						<Slider min={0} max={100} step={5} defaultValue={AppState.instance.userProfile.filterMaxDistance}
							handle={handle} onChange={this.OnDistanceChanged} onAfterChange={this.OnAfterDistanceChanged}
							{...forcedValue}
						/>
					</div>
				</div>

				<div className="filterFiltersFeature">
					<div className="filterSectionTitle">
						{Translate.T("en.Age of Kids", "de.Alter der Kinder", "filter.kidsage")}
					</div>
					
					<AgeSelection onClick={this.OnAgeButtonClicked} states={this.ageButtonStates} />
				</div>

				<div className="filterFiltersFeature">
					<div className="filterSectionTitle">
						{Translate.T("en.Minimum Rating", "de.Mindest Bewertung", "filter.minrating")}
					</div>
					{AppState.instance.userProfile.filterMinRating !== undefined && <Button className="clearRatingButton" onClick={this.OnClearRating}>
						{Translate.T("en.Clear", "de.Löschen", "filter.rating.clear")}
					</Button>}
					<Rating
						value={AppState.instance.userProfile.filterMinRating}
						editing={true}
						onStarClick={this.OnRatingStarClick}
						starColor={'#e31c46'}
					/>
				</div>

				<div className="filterFiltersFeature">
					<div className="filterSectionTitle">
						{Translate.T("en.Maximum Price", "de.Maximal Preis", "filter.maxprice")}
					</div>
					{AppState.instance.userProfile.filterMaxPrice !== undefined && <Button className="clearPriceButton" onClick={this.OnClearPrice}>
						{Translate.T("en.Clear", "de.Löschen", "filter.rating.clear")}
					</Button>}

					{priceDesc && <div className="priceIconDesc">
						{priceDesc}
					</div>}

					<Rating
						value={priceStarIndex}
						editing={true}
						onStarClick={this.OnPriceStarClick}
						starColor={'#e31c46'}
						starCount={4} renderStarIcon={(index, value, name) =>
							{
								if (index === 1)
								{
									// "free" icon
									if (value >= 1)
									{
										return <span className="crossedIcon full"><EuroSymbol className="priceIconFull" /></span>;
									}
									else
									{
										return <span className="crossedIcon empty"><EuroSymbol className="priceIconEmpty" /></span>;
									}
								}
								else
								{
									if (index <= value)
										return <EuroSymbol className="priceIconFull" />;
									else
										return <EuroSymbol className="priceIconEmpty" />;
								}
							}}
					/>
				</div>

				<div className="filterFilters">
					<div className="filterSectionTitle">
						{!this.showAllFilters && Translate.T("en.Popular Filters", "de.Beliebte Filter", "filter.popularfilters")}
						{this.showAllFilters && Translate.T("en.All Filters", "de.All Filter", "filter.allfilters")}
					</div>

					<List>
						{frontFilters.map((f, index) =>
						{
							const isActive = this.props.isFilterChecked(f.id);
							const catIcon = Utils.GetCategoryIconUrl(f.title, isActive ? "#e31c46" : "#aaa", "white", 24, 24, false);

							return (
								<ListItem className={"filterCategoryListItem" + (isActive ? " active":"")} key={f.id} button onClick={thisPtr.OnFilterClicked(f)}>
									{catIcon !== undefined && <img alt="" src={catIcon} style={{height: 24, width: 24}} /> }
									{catIcon === undefined && <span style={{height: 24, width: 24}} /> }
									<ListItemText primary={f.title} />
									<Checkbox
										checked={isActive}
										//onChange={this.HandleFilterChecked(f)}
										value={"filterDialogFilterCB"}
										className="filterDialogFilterCB"
										color="primary"
									/>
								</ListItem>
							);
						})}
					</List>
					{!this.showAllFilters && <Button className="showAllFiltersButton" onClick={this.OnShowAllFilters}>
						{Translate.T("en.See all", "de.Mehr", "results.showall")}
					</Button>}
				</div>

				{filterGroups.map((group, groupIndex) =>
				{
					return (
					<div className="filterGroup" key={groupIndex}>
						<div className="filterSectionTitle">
							{group.title}
						</div>

						<List>
							{group.items.map((f, index) =>
							{
								const isActive = this.props.isFilterChecked(f.id);
								const catIcon = Utils.GetCategoryIconUrl(f.title, isActive ? "#e31c46" : "#aaa", "white", 24, 24, false);

								return (
									<ListItem className={"filterCategoryListItem" + (isActive ? " active":"")} key={f.id} button onClick={thisPtr.OnFilterClicked(f)}>
										{catIcon !== undefined && <img alt="" src={catIcon} style={{height: 24, width: 24}} /> }
										{catIcon === undefined && <span style={{height: 24, width: 24}} /> }
										<ListItemText primary={f.title} />
										<Checkbox
											checked={isActive}
											//onChange={this.HandleFilterChecked(f)}
											value="filterDialogFilterCB"
											className="filterDialogFilterCB"
											color="primary"
										/>
									</ListItem>
								);
							})}
						</List>
					</div>
					);
				})}

			</div>
		);
	}
}

FilterContainer.propTypes = {
	classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(FilterContainer);