import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import ContentCard from './ContentCard';
import SearchResult from './SearchResult';
import AppState from '../AppState';

const styles = theme => ({
	root: {
	  backgroundColor: theme.palette.background.paper,
	},
  });

@observer
class ContentItem extends React.Component
{
	render()
	{
		var columnMode = true;
		if (this.props.renderMode === 'small' || this.props.renderMode === 'tiny' ||
			this.props.renderMode === 'supertiny')
			columnMode = false;

		if (this.props.itemType.indexOf('searchresult') >= 0 ||
			this.props.renderMode === "searchresult")
		{
			return (
				<SearchResult
					onClick={this.props.onClick}
					contentData={this.props.contentData}
					columnMode={columnMode}
					isFirst={this.props.isFirst}
					isLast={this.props.isLast} />
			);
		}
		else if (!this.props.contentData || this.props.contentData.id === undefined)
		{
			return <div>LOADING</div>;
		}
		else if (!this.props.contentData.url)
		{
			return <div></div>;
		}
		else
		{
			return (
				<ContentCard renderMode={this.props.renderMode} contentData={this.props.contentData} columnMode={columnMode}
					isFirst={this.props.isFirst} isLast={this.props.isLast} itemType={this.props.itemType}
					actionButton={this.props.actionButton}
					showLocation={this.props.showLocation}
					maxNumCategories={this.props.maxNumCategories}
					onClickEnabled={this.props.onClickEnabled}
					bookmarkEnabled={this.props.bookmarkEnabled}
					onClick={this.props.onClick}
					categoryIds={this.props.categoryIds}
					defaultContentHeight={AppState.instance.deviceInfo.desktop ? 175 : undefined}
				/>
			);
		}
	}
}

ContentItem.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	itemType: PropTypes.string.isRequired,
	contentData: PropTypes.object.isRequired,
	renderMode: PropTypes.string.isRequired,
	isFirst: PropTypes.bool.isRequired,
	isLast: PropTypes.bool.isRequired,
};

export default withStyles(styles, { withTheme: true })(ContentItem);