import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import { observable } from 'mobx';
import ProfilePicture from './ProfilePicture';
import AppState from '../AppState';
import Utils from '../Utils';
import TextField from 'material-ui/TextField';
import SendIcon from 'material-ui-icons/Send';
import $ from 'jquery';
import Button from 'material-ui/Button';
import Translate from '../Translate';
import AccountRequestPage from '../pages/AccountRequestPage';

const styles = {
};

@observer
class SocialInteraction extends React.Component
{
	@observable newComment;
	inputId;

	constructor(props)
	{
		super(props);
		this.inputId = "sctf__" + Utils.GenerateRandomStr(32);
	}

	OnProfileLoaded = () =>
	{
		this.forceUpdate();
	}

	OnSelfClicked = () => {}

	OnTextFieldChanged = (event) =>
	{
		this.newComment = event.target.value;
		if (this.props.onCommentChanged)
			this.props.onCommentChanged(this.newComment);
	}

	OnTextFieldKeyPress = (event) =>
	{
		// var key = event.key || event.keyCode || event.which;
		// if (key === 'Enter' || key === '13')
		// {
		// 	event.preventDefault();

		// 	$(event.target).blur();
		// 	if (this.props.onEnter)
		// 		this.props.onEnter();
		// }
	}

	OnSendHeartClicked = () =>
	{
		this.DoSendMessage(true);
	}

	ClearComment()
	{
		$("#" + this.inputId).val("");
		this.newComment = undefined;
	}

	SendMessage = () =>
	{
		this.DoSendMessage(false);
	}

	DoSendMessage(withHeart)
	{
		if (!AppState.instance.IsLoggedIn())
		{
			AccountRequestPage.Show();
			return;
		}

		if ((this.newComment && this.newComment.length > 0) || withHeart)
		{
			const text = this.newComment;
			this.ClearComment();

			AppState.instance.socialManager.SendMessage(this.props.receiverTimelineItemId, this.props.receiverUsername, text,
				withHeart ? 1 : undefined, undefined, this.props.receiverType, this.props.receiverId)
			.then((message) =>
			{
				if (this.props.onMessageSent)
					this.props.onMessageSent(message, withHeart);
			});
		}
	}

	render()
	{
		return (
			<div className="socialInteraction">
				<ProfilePicture className="csMyPhoto"
					onClick={this.OnSelfClicked}
					username={AppState.instance.IsLoggedIn() ? AppState.instance.userProfile.username : undefined}
				/>

				<TextField
					type="text"
					className={"csCommentInput"}
					onChange={this.OnTextFieldChanged}
					onKeyPress={this.OnTextFieldKeyPress}
					placeholder={AppState.instance.IsLoggedIn() ?
						Translate.FindTranslation("social.comment.placeholder") :
						Translate.FindTranslation("social.comment.placeholder.notloggedin")}
					autoComplete={"off"}
					InputProps={{
						disableUnderline: true,
						id: this.inputId,
					}}
				/>

				{this.props.enableSendHeart && <Button
					className={"socialSendHeartBtn" + (this.newComment && this.newComment.length > 0 ? " filled": " empty")}
					onClick={this.OnSendHeartClicked}
				>
					{Utils.RenderHeartIcon(24, 24, "#e31c46", "none", 2)}
				</Button>}

				{this.newComment && this.newComment.length > 0 &&
					<Button className="csSendCommentIcon" onClick={this.SendMessage}>
						<SendIcon />
					</Button>}
			</div>
		);
	}
}

SocialInteraction.propTypes = {
	classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(SocialInteraction);