import Utils from "./Utils";

//import AppState from './AppState';
//import Delegate from './utils/Delegate';

export default class ImageManager
{
	images = [];

	Get(_url)
	{
		var url = Utils.StripWeserv(_url);
		const minimizedUrl = Utils.MinimizeUrl(url);
		
		for (var i = 0; i < this.images.length; ++i)
		{
			var image = this.images[i];
			if (!image.minimizedUrl)
				image.minimizedUrl = Utils.MinimizeUrl(image.url);
			if (image.minimizedUrl === minimizedUrl)
				return image;
		}

		return undefined;
	}

	Store(imageData)
	{
		if (!imageData)
			return;

		for (var i = 0; i < imageData.length; ++i)
		{
			const data = imageData[i];
			if (this.Get(data.url))
				continue;

			this.images.push(data);

			//console.log("new image data:");
			//console.log(data);
		}
	}
}