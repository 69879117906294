import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import AppState from '../AppState';
import { observable } from '../../node_modules/mobx';
import Dialog from 'material-ui/Dialog';
import Translate from '../Translate';
import AdvCardStack from '../components/adventure/AdvCardStack';
import { Button } from 'material-ui';
import AdvCardFrame from '../components/adventure/AdvCardFrame';
import Tracking from '../Tracking';

const styles = {
};

@observer
class CardReceivePopup extends React.Component
{
	cardInstances;
	onClosed;
	@observable show = false;
	stack;
	@observable frontId;

	constructor(props)
	{
		super(props);
		AppState.instance.cardReceivePopup = this;
	}

	Show(cardInstances, onClosed)
	{
		this.cardInstances = cardInstances;
		this.onClosed = onClosed;

		this.show = true;
		AppState.instance.appContainer.forceUpdate();

		Tracking.OnPageView("CardReceivePopup");
	}

	IsShown()
	{
		return this.show;
	}

	OnClose = () =>
	{
		if (this.onClosed)
			this.onClosed(this.cardInstances);

		this.show = false;

		this.onClosed = undefined;
		this.cardInstances = undefined;
	}

	OnCta = () =>
	{
		setTimeout(() => {
			this.OnClose();
		}, 50);
	}

	OnThrowOutEnd = (oldFrontId, newFrontId) =>
	{
		this.frontId = newFrontId;
	}

	OnCardClick = () =>
	{

	}

	render()
	{
		var fullScreen = true;
		if (AppState.instance.deviceInfo.desktop ||
			AppState.instance.deviceInfo.tablet)
			fullScreen = false;

		var instance;
		if (this.stack)
		{
			const frontInstanceId = this.stack.GetFrontId();
			if (frontInstanceId)
				instance = AppState.instance.advManager.GetCardInstance(frontInstanceId);
		}
		else if (this.cardInstances && this.cardInstances.length > 0)
		{
			instance = this.cardInstances[0];
		}

		var ids = [];
		if (this.cardInstances)
			for (var i = 0; i < this.cardInstances.length; ++i)
				ids.push(this.cardInstances[i].id);

		//console.log("new front id: " + this.frontId);

		return (
			<Dialog
				id="cardReceivePopup" className={"cardReceivePopup" + AppState.instance.GetPlatformClasses()}
				open={this.show}
				fullScreen={fullScreen}
				onClose={this.OnClose}
			>
				<div className="new">
					{Translate.T("de.Neu!", "en.New!", "cardreceive.new")}
				</div>
				<div className="title">
					{this.cardInstances && this.cardInstances.length === 1 && Translate.T("de.Eine neue Karte!", "en.One new card!", "cardreceive.popup.title")}
					{this.cardInstances && this.cardInstances.length !== 1 && Translate.T("de.{0} neue Karten!", "en.{0} new cards!", "cardreceive.popup.title.plural", [this.cardInstances.length])}
				</div>

				<AdvCardFrame
					marginTop={104}
					marginBottom={136}
					containerWidth={AppState.instance.deviceInfo.desktop ? 700 : undefined}
					containerHeight={AppState.instance.deviceInfo.desktop ? 600 : undefined}
				>
					<AdvCardStack
						instanceIds={ids}
						frontId={this.frontId}
						classNameCardWrapper={"receivedcard"}
						containerSelector={"#cardReceivePopup"}
						onInit={(stackInstance) => this.stack = stackInstance}
						onThrowOutEnd={this.OnThrowOutEnd}
						onCardClick={this.OnCardClick}
					/>
				</AdvCardFrame>

				{instance && <div className="reason">
					{instance.source === "partset" && Translate.T("de.Du hast die erste Karte des Sets gefunden", "en.You have found the first card of the set", "cardreceive.reasons.partset")}
					{instance.source === "set" && Translate.T("de.Du hast das Kartenset vervollständigt", "en.You have finished the card set", "cardreceive.reasons.set")}
					{instance.source === "gps" && Translate.T("de.Du hast den magischen Ort dieser Karte gefunden", "en.You have found the magical place of this card", "cardreceive.reasons.gps")}
					{instance.source === "default" && Translate.T("de.Gratuliere!\nDu startest mit einer neuen Gefährtin in die Abenteuerwelt.",
						"en.Congratulations!\nMeet your new fellow for a good start.", "cardreceive.reasons.default")}
					{instance.source === "logic" && Translate.T("de.Du hast Dir diese Karte wirklich verdient", "en.You really earned this card", "cardreceive.reasons.logic")}
					{instance.source === "photo" && Translate.T("de.Gut gemacht! Du hast das entsprechende Foto geschossen.", "en.Well done! You took the right photo.", "cardreceive.reasons.logic")}
				</div>}

				<div className="cta">
					<Button onClick={this.OnCta}>
						{Translate.T("de.OK", "en.OK", "cardreceive.popup.cta")}
					</Button>
				</div>

			</Dialog>
		);
	}
}

CardReceivePopup.propTypes = {
	classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(CardReceivePopup);