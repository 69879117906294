import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import PhotoSwipe from '../utils/PhotoSwipe';
import { observable } from '../../node_modules/mobx';
import $ from 'jquery';
import Dots from './Dots';
import AppState from '../AppState';
import Utils from '../Utils';

const styles = theme => ({	
});

@observer
class FullscreenMedia extends React.Component
{
	static instance = null;

	items = [];
	options = {};
	@observable show = false;
	thumbnailSelector;
	photoIndex = 0;
	photoSwipe;
	onSlideChanged;
	onRenderImageCaption;
	onClosed;

	updateIntervalId;

	constructor(props)
	{
		super(props);
		FullscreenMedia.instance = this;
	}

	static Show(items, index, thumbnailSelector, onSlideChanged, onRenderImageCaption, onClosed)
	{
		FullscreenMedia.instance.DoShow(items, index, thumbnailSelector, onSlideChanged, onRenderImageCaption, onClosed);
	}

	StartUpdate()
	{
		this.StopUpdate();
		this.updateIntervalId = setInterval(this.Update, 100);
	}

	StopUpdate()
	{
		if (this.updateIntervalId)
		{
			clearInterval(this.updateIntervalId);
			this.updateIntervalId = undefined;
		}
	}

	OnRenderImageCaptionVoid = (item, captionEl, isFake) =>
	{
		if(!item.title) {
			captionEl.children[0].innerHTML = '';
			return false;
		}
		captionEl.children[0].innerHTML = item.title;
		return true;
	}

	DoShow(items, index, thumbnailSelector, onSlideChanged, onRenderImageCaption, onClosed)
	{
		if (!items || items.length === 0)
			return;

		this.onClosed = onClosed;
		this.photoIndex = index;
		this.options.index = index || 0;
		this.options.counterEl = false;
		this.options.shareEl = false;

		this.thumbnailSelector = thumbnailSelector;
		if (thumbnailSelector)
			this.options.getThumbBoundsFn = this.GetThumbBoundsFn;
		else
			this.options.getThumbBoundsFn = undefined;

		this.options.showHideOpacity = true;
		this.options.tapToToggleControls = false;

		if (onRenderImageCaption)
			this.options.addCaptionHTMLFn = onRenderImageCaption;
		else
			this.options.addCaptionHTMLFn = this.OnRenderImageCaptionVoid;

		this.options.isClickableElement = this.IsClickableElement;
		this.options.getDoubleTapZoom = this.GetDoubleTapZoom;

		this.onSlideChanged = onSlideChanged;

		this.items = items;

		var firstVideoId;

		for (var i = 0; i < this.items.length; ++i)
		{
			var item = this.items[i];

			item.initialPosition = {x:0, y:0};

			if (item.videosrc)
			{
				const autoplay = index === i;
				const posterUrl = item.posterUrl;
				var posterStr;
				if (posterUrl)
					posterStr = ' poster="' + posterUrl + '" ';

				const scaledVideoUrl = Utils.ScaleDownCloudinaryVideos(item.videosrc);
				const startMuted = false;

				item.html = '<video ' + (startMuted ? "muted":"") + ' controls id="' + item.videoId + '" className="fsVideoPlayer" ' + (autoplay ? "autoplay":"") +
					' playsInline preload="auto" src="' + scaledVideoUrl + '" ' + posterStr + '></video>';

				if (i === 0)
					firstVideoId = item.videoId;
			}
		}

		if (firstVideoId)
		{
			setTimeout(() => {
				var video = $("#" + firstVideoId);
				video.trigger('play');	
			}, 300);
		}

		this.show = true;

		this.StartUpdate();
	}

	GetDoubleTapZoom = (isMouseClick, item) =>
	{
		if (isMouseClick)
		{
    		return 1;
		}
		else if (item.videosrc)
		{
			return 1;
		}
		else
		{
    		return item.initialZoomLevel < 0.7 ? 1 : 1.33;
    	}
    }

	IsClickableElement = (el) =>
	{
		return el.tagName === 'A' || el.tagName === 'VIDEO';
	}

	OnHandleClose = () =>
	{
		this.show = false;

		for (var i = 0; i < this.items.length; ++i)
		{
			var currentItem = this.items[i];
			this.PauseVideo(currentItem);
		}

		this.StopUpdate();

		if (this.onClosed)
			this.onClosed();

		//WORKAROUND: when the photo was zoomed, the bottom bar disappeared
		if (AppState.instance.appInstance)
			AppState.instance.appInstance.SetSiteBottomVisibility(true);
	}

	PauseVideo(item)
	{
		if (item.videosrc)
		{
			var video = $("#" + item.videoId);
			video.trigger('pause');
		}
	}

	GetThumbBoundsFn = (index) =>
	{
		// find thumbnail element
		var thumbnail = $(this.thumbnailSelector);
	
		// get window scroll Y
		var pageYScroll = window.pageYOffset || document.documentElement.scrollTop; 
		// optionally get horizontal scroll
	
		// get position of element relative to viewport
		if (thumbnail && thumbnail.length > 0 && thumbnail[0])
		{
			var rect = thumbnail[0].getBoundingClientRect();
			return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
		}
		else
		{
			return {x:0, y:pageYScroll, w:window.innerWidth};
		}
	
		// Good guide on how to get element coordinates:
		// http://javascript.info/tutorial/coordinates
	}

	OnDotClicked = (event, index) =>
	{
		if (AppState.instance.deviceInfo.desktop && this.photoSwipe)
		{
			this.photoIndex = index;
			this.photoSwipe.goTo(index);
			event.stopPropagation();
			event.preventDefault();
		}
	}

	OnSlideChanged = (ps) =>
	{
		const oldIndex = this.photoIndex;
		if (oldIndex < this.items.length)
			this.PauseVideo(this.items[oldIndex]);

		this.photoIndex = ps.getCurrentIndex();

		$(".fullscreenMedia .dot").removeClass("active");
		$(".fullscreenMedia .dot").addClass("inactive");

		$(".fullscreenMedia .dot:nth-child(" + (this.photoIndex + 1) + ")").addClass("active");

		if (this.onSlideChanged)
			this.onSlideChanged(this.photoIndex);
	}

	Update = () =>
	{
		if (!this.show)
			return;

		if (this.items.length === 0)
			return;

		for (var i = 0; i < this.items.length; ++i)
		{
			var currentItem = this.items[i];
			const currentIsVideo = currentItem && currentItem.videosrc;
			if (currentIsVideo)
			{
				var videoRatio = currentItem.videoW / currentItem.videoH;
				var screenRatio = window.innerWidth / window.innerHeight;
	
				var w;
				var h;
				var t;
				if (videoRatio > screenRatio)
				{
					// limit is window.innerWidth
					w = window.innerWidth;
					h = Math.floor(w / videoRatio);
	
					t = Math.floor((window.innerHeight - h) * 0.5);
				}
				else
				{
					h = window.innerHeight;
					w = Math.floor(h * videoRatio);
					t = 0;
				}
	
				var video = $("#" + currentItem.videoId);
	
				video.css('width', w);
				video.css('height', h);
				video.css('top', t);
			}
		}

		if (this.photoIndex < this.items.length)
		{
			const c = this.items[this.photoIndex];
			if (c.videosrc)
			{
				$(".pswp__caption").css('display', 'none');
			}
			else
			{
				$(".pswp__caption").css('display', 'block');
			}
		}
	}

	render()
	{
		return (
			<div className={"fullscreenMedia" + (this.show ? " visible":" hidden")}>

				{this.items.length > 1 && <Dots index={this.photoIndex} count={this.items.length} onClick={this.OnDotClicked} />}

				<PhotoSwipe
					isOpen={this.show}
					items={this.items}
					options={this.options}
					onClose={this.OnHandleClose}
					afterChange={this.OnSlideChanged}
					onInit={(instance) => this.photoSwipe = instance}
				/>

			</div>
		);
	}
}

FullscreenMedia.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(FullscreenMedia);