import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import AppState from '../../AppState';
import Button from 'material-ui/Button';
import AdvCard from './AdvCard';
import Translate from '../../Translate';

const styles = theme => ({
});

@observer
class AdvImageSearch extends React.Component
{
	advManager;

	constructor(props)
	{
		super(props);

		this.advManager = AppState.instance.advManager;
	}

	componentDidMount()
	{
		this.advManager.onConfigChanged.Add(this.OnConfigChanged);
		this.advManager.onInstancesChanged.Add(this.OnInstancesChanged);
	}

	componentWillUnmount()
	{
		this.advManager.onConfigChanged.Remove(this.OnConfigChanged);
		this.advManager.onInstancesChanged.Remove(this.OnInstancesChanged);
	}

	OnConfigChanged = () =>
	{
		this.forceUpdate();
	}

	OnInstancesChanged = () =>
	{
		this.forceUpdate();
	}

	OnUpload = () =>
	{
		setTimeout(() => {
			AppState.instance.appContainer.PromptImage(this.UploadImage);
		}, 50);
	}

	UploadImage = (file) =>
	{
		this.advManager.UploadAdventureImage(file, this.props.advInstanceId,
			this.OnUploadProgress,
			this.OnUploadError,
			this.OnLocalImageData);
	}

	OnUploadProgress = (event) =>
	{
		//const progress = event.loaded / event.total * 100;
		//console.log("upload: " + progress);
	}

	OnUploadError = (error) =>
	{
		console.log("Upload error:");
		console.log(error);
	}

	OnLocalImageData = (dataUrl) =>
	{
		//console.log("preview of image upload: " + dataUrl);
	}

	render()
	{
		const data = this.props.data;
		if (data === undefined || this.props.advInstanceId === undefined)
			return null;

		var allFound = true;
		for (var i = 0; i < data.length; ++i)
		{
			if (data[i].found === undefined)
			{
				allFound = false;
				break;
			}
		}

		return (
			<div className="advimagesearch">
				{data.map((item, index) =>
				{
					const cardId = item.cardId;
					const isFound = item.found !== undefined;
					//const foundImageUrl = item.foundImageUrl;
					//const card = this.advManager.GetCardById(cardId);

					var overlay = (
						<div className={"overlay" + (isFound ? " found":" notfound")} />
					);

					return (
						<div className="advimageitem" key={index}>
							<AdvCard key={cardId} cardId={cardId} isFirst={index === 0} isLast={index === data.length - 1}
								showTitle={true} showPlus={false} showNumInstances={false}
								showImage={true}
								//onClick={this.OnCardClicked}
								additionalElement={overlay}
							/>
							
						</div>
					);
				})}

				{!allFound && <div className="upload">
					<Button onClick={this.OnUpload}>
						{Translate.T("de.Foto Upload", "en.Photo Upload", "adventure.imagesearch.upload")}
					</Button>
				</div>}
			</div>
		);
	}
}

AdvImageSearch.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AdvImageSearch);