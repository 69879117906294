import Utils from './Utils';
import RestApi from './RestApi';
import StepSelectGoal from './components/steps/StepSelectGoal';
import StepSelectActivity from './components/steps/StepSelectActivity';
import StepSelectDate from './components/steps/StepSelectDate';
import StepSelectAudience from './components/steps/StepSelectAudience';
import StepSelectReview from './components/steps/StepSelectReview';
import StepUploadFiles from './components/steps/StepUploadFiles';
import StepFinish from './components/steps/StepFinish';
import StepFeedback from './components/steps/StepFeedback';
import StepClosingMessage from './components/steps/StepClosingMessage';
import StepCheckInActivity from './components/steps/StepCheckInActivity';
import StepQuestion from './components/steps/StepQuestion';

export default class ChatBotManager
{
	steps = [];
	goals = {};

	Init()
	{
		this.steps.push(new StepSelectGoal());
		this.steps.push(new StepSelectActivity());
		this.steps.push(new StepSelectDate());
		this.steps.push(new StepSelectAudience());
		this.steps.push(new StepSelectReview());
		this.steps.push(new StepUploadFiles());
		this.steps.push(new StepFinish());
		this.steps.push(new StepFeedback());
		this.steps.push(new StepClosingMessage());
		this.steps.push(new StepCheckInActivity());
		this.steps.push(new StepQuestion());
		//this.steps.push(new StepComingSoon());

		this.AddGoal("get_goal", ["goal"]);
		this.AddGoal("experience", ["activity", "date", "rating", "desc", "files", "audience", "finish", "closingmsg"]);
		this.AddGoal("checkin", ["checkinActivity", "checkinNotFound", "closingmsg"]);
		this.AddGoal("media", ["activity", "files", "audience", "finish", "closingmsg"]);
		this.AddGoal("question", ["activity", "question", "audience", "finish", "closingmsg"]);
		this.AddGoal("feedback", ["feedback", "closingmsg"]);
		/*this.AddGoal("photo", ["comingsoon"]);
		this.AddGoal("newactivity", ["comingsoon"]);*/
	}

	Upload(contextData, onSuccess, onError)
	{
		const contentData = contextData["activity"];
		const date = contextData["date"];
		const rating = contextData["rating"];
		var text = contextData["desc"];
		const files = contextData["files"];
		const share = contextData["audience"];
		const goal = contextData["goal"];

		const activityIds = contentData ? [contentData.id] : undefined;

		var type;
		if (goal === "image")
			type = 1;
		else if (goal === "video")
			type = 2;
		else if (goal === "experience" || goal === "media")
			type = 3;
		else if (goal === "checkin")
			type = 4;
		else if (goal === "question")
		{
			text = contextData["question"];
			type = 5;
		}

		var locStr;
		if (contentData && contentData.latitude && contentData.longitude)
		{
			var loc = {
				latitude: contentData.latitude,
				longitude: contentData.longitude
			};
			locStr = JSON.stringify(loc);
		}

		var query = {
			activityIds: activityIds,
			type: type,
			recordingStart: date,
			title: contentData ? contentData.title : undefined,
			desc: text,
			rating: rating,
			pictures: files,
			share: share,
			copyright: true,
			location: locStr
		};

		RestApi.SendRequest("/usercontent", query).then( (responseObj) =>
		{
			if (responseObj)
			{
				if (onSuccess)
					onSuccess(responseObj);
			}
			else
			{
				if (onError)
					onError();
			}
		})
		.catch((error) =>
		{
			if (onError)
				onError();
		});
	}

	GetSteps(_goalId)
	{
		const goalId = _goalId || "get_goal";
		return this.goals[goalId].steps;
	}

	GetStepFromId(stepId)
	{
		for (var i = 0; i < this.steps.length; ++i)
		{
			if (this.steps[i].GetId() === stepId)
				return this.steps[i];
		}
		return undefined;
	}

	AddGoal(goalId, neededData)
	{
		this.goals[goalId] = {
			neededData: neededData,
			steps: this.GetStepsForDataIds(neededData)
		};
	}

	GetStepsForDataIds(neededData)
	{
		var result = [];

		for (var i = 0; i < neededData.length; ++i)
		{
			const dataId = neededData[i];
			const step = this.FindStepForDataId(dataId);
			if (step)
			{
				// Don't add twice
				var found = false;
				for (var j = 0; j < result.length; ++j)
				{
					if (result[j].GetId() === step.GetId())
					{
						found = true;
						break;
					}
				}

				if (!found)
					result.push(step);
			}
		}

		return result;
	}

	FindStepForDataId(dataId)
	{
		for (var i = 0; i < this.steps.length; ++i)
		{
			const step = this.steps[i]
			const delivers = step.GeneratedDataIds();
			if (delivers.indexOf(dataId) >= 0)
				return step;
		}

		return undefined;
	}

	GetVersionId()
	{
		var result = "";
		for (var i = 0; i < this.steps.length; ++i)
		{
			result += this.steps[i].GetId() + ";";
		}
		for (var goalId in this.goals)
		{
			result += goalId + ";";
		}
		return result;
	}

	SaveState(chatBot, id)
	{
		var steps = [];
		for (var i = 0; i < chatBot.steps.length; ++i)
		{
			steps.push(chatBot.steps[i].GetId());
		}

		chatBot.contextData.bot = undefined;
		const mp = chatBot.contextData.mediaPlayer;
		chatBot.contextData.mediaPlayer = undefined;
		try
		{
			const state = {
				contextData: chatBot.contextData,
				steps: steps,
				version: this.GetVersionId()
			};
	
			const stateStr = JSON.stringify(state);
			Utils.SetLocalStorage("chatbot_" + id, stateStr);
		}
		finally
		{
			chatBot.contextData.mediaPlayer = mp;
			chatBot.contextData.bot = chatBot;
		}
	}

	IsStateAvailable(id)
	{
		const stateStr = Utils.GetLocalStorage("chatbot_" + id, "");
		return stateStr;
	}

	LoadState(chatBot, id)
	{
		const stateStr = Utils.GetLocalStorage("chatbot_" + id, "");
		if (!stateStr)
			return false;

		const state = JSON.parse(stateStr);

		if (state.version !== this.GetVersionId())
			return false;

		const stepIds = state.steps;
		var newSteps = [];
		for (var i = 0; i < stepIds.length; ++i)
		{
			const newStep = this.GetStepFromId(stepIds[i]);
			if (!newStep)
				return false;

			newSteps.push(newStep);
		}

		const date = state.contextData["date"];
		if (typeof date === 'string')
		{
			state.contextData["date"] = new Date(date);
		}

		chatBot.contextData = state.contextData;
		chatBot.steps = newSteps;

		return true;
	}

	DeleteState(id)
	{
		Utils.RemoveLocalStorage("chatbot_" + id);
	}
}