import React from 'react';

export default class LiveData extends React.Component
{
	static GetContentLiveData(activityLiveData, contentid)
	{
		if (!activityLiveData)
			return undefined;
		if (activityLiveData.content)
		{
			for (var i = 0; i < activityLiveData.content.length; ++i)
			{
				var c = activityLiveData.content[i];
				if (c.id === contentid)
					return c;
			}
		}
		return undefined;
	}

	static ContainsHotData(activityLiveData)
	{
		var hotData = LiveData.GetHotData(activityLiveData);

		if (hotData.liveContent || hotData.newContent)
			return true;

		//TODO: increase threshold
		if (hotData.numCurrentViewers >= 1)
			return true;

		return false;
	}

	static GetHotData(activityLiveData)
	{
		// #current viewers
		// #current visitors
		// hot content indicator: content added today or live

		var result = {};

		if (!activityLiveData)
			return result;

		if (activityLiveData.viewers)
			result.numCurrentViewers = activityLiveData.viewers._1n;

		if (activityLiveData.visitors)
			result.numCurrentVisitors = activityLiveData.visitors._1h;

		if (activityLiveData.events)
		{
			for (var j = 0; j < activityLiveData.events.length; ++j)
			{
				if (activityLiveData.events[j].eventType === "ContentAdd")
				{
					if (activityLiveData.events[j].events[j]._1w)
						result.newContent = true;
				}
			}
		}

		if (activityLiveData.content)
		{
			for (var i = 0; i < activityLiveData.content.length; ++i)
			{
				var c = activityLiveData.content[i];
				for (j = 0; j < c.events.length; ++j)
				{
					if (c.events[j].eventType === "ContentAdd")
					{
						if (c.events[j]._1h)
							result.liveContent = true;
						else if (c.events[j]._1d)
							result.newContent = true;
					}
				}
			}
		}

		return result;
	}

	static GetHotContentData(contentLiveData, contentData)
	{
		// #current viewers
		// hot content indicator: content added today or live

		var result = {};

		if (!contentLiveData)
			return result;

		if (contentLiveData.viewers)
			result.numCurrentViewers = contentLiveData.viewers._1n;

		if (contentLiveData.events)
		{
			for (var j = 0; j < contentLiveData.events.length; ++j)
			{
				if (contentLiveData.events[j].eventType === "ContentAdd")
				{
					if (contentLiveData.events[j]._1n)
						result.liveContent = true;
					else if (contentLiveData.events[j]._1d)
						result.newContent = true;
				}
			}
		}

		if (contentData)
		{
			if (contentData.recordingStart)
			{
				var d = new Date(contentData.recordingStart);
				var hours = (new Date() - d) / 3600000;
				var mins = hours * 60;
				if (mins <= 1)
					result.liveContent = true;
				else if (hours <= 24)
					result.newContent = true;
			}
		}

		return result;
	}
}
