import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import $ from 'jquery';
import Utils from '../Utils';
import raf from 'raf';
import ContentCard from './ContentCard';
import AppState from '../AppState';

const styles = theme => ({	
});

@observer
class ContentGrid extends React.Component
{
	id;
	width;
	contentWidth;
	_isMounted = false;

	constructor(props)
	{
		super(props);

		this.id = Utils.GenerateRandomId(32);

		AppState.instance.onContentGridRefresh.Add(this.OnRefresh);
	}

	componentDidMount()
	{
		this._isMounted = true;
		this.CheckSize();
	}

	componentWillUnmount()
	{
		AppState.instance.onContentGridRefresh.Remove(this.OnRefresh);
		this._isMounted = false;
	}

	CheckSize()
	{
		const c = $("#" + this.id);
		if (c)
		{
			const w = c.width();
			if (w !== this.width)
			{
				this.width = w;
				this.OnWidthChanged();
			}
		}
	}

	OnRefresh = () =>
	{
		raf(() =>
		{
			this.CheckSize();
		});
	}

	OnWidthChanged()
	{
		const minWidth = this.props.minContentWidth || 1;
		const maxWidth = this.props.maxContentWidth || this.width;
		const idealPerRow = this.props.idealNumContentPerRow || 3;
		const spacing = this.props.contentSpacing || 0;

		this.perRow = idealPerRow;
		this.contentWidth = (this.width - spacing * (this.perRow - 1)) / this.perRow;

		while (this.contentWidth > maxWidth)
		{
			this.perRow++;
			if (this.perRow > 20)
			{
				this.perRow = 20;
				break;
			}
			this.contentWidth = (this.width - spacing * (this.perRow - 1)) / this.perRow;
		}

		while (this.contentWidth < minWidth)
		{
			this.perRow--;
			if (this.perRow < 1)
			{
				this.perRow = 1;
				break;
			}
			this.contentWidth = (this.width - spacing * (this.perRow - 1)) / this.perRow;
		}

		this.contentWidth = Math.floor(this.contentWidth);

		const singleRow = this.props.maxNumRows === 1;
		if (singleRow)
		{
			this.perRow = this.props.content.length;
		}

		if (this._isMounted)
			this.forceUpdate();

		if (this.props.onScrollingNeeded)
		{
			var neededX = false;
			var neededY = false;

			if (singleRow)
			{
				var neededWidth = this.props.content.length * this.contentWidth;
				if (this.props.content.length > 1)
					neededWidth += (this.props.content.length - 1) * spacing;
				neededX = neededWidth > this.width;
			}
			else
			{
				const numRows = Math.ceil(this.props.content.length / this.perRow);
				neededY = numRows > 1;
			}
			this.props.onScrollingNeeded(neededX, neededY);
		}
	}

	OnRenderContentDefault = (content, index, contentList,
		contentWidth, numContentPerRow, isFirstContentInRow, isLastContentInRow) =>
	{
		return (
			<div
				className="contentGridCellContent"
			>
				{content && <ContentCard
					contentData={content}
					columnMode={false}
					isFirst={false}
					isLast={false}
					itemType={"content"}
					showLocation={true}
					defaultContentHeight={Math.floor(contentWidth / 1.5)}
					onClick={this.props.onContentClick}
				/>}
			</div>
		);
	}

	render()
	{
		const spacing = this.props.contentSpacing || 0;
		const numRows = this.props.content ? Math.ceil(this.props.content.length / this.perRow) : 0;
		const indexInLastRow = (numRows - 1) * this.perRow;
		const singleRow = this.props.maxNumRows === 1;
		
		const onRender = this.props.onRenderContent || this.OnRenderContentDefault;

		return (
			<div
				className={"contentGrid" + (this.props.className || "")}
				key={this.props.key}
				id={this.id}
			>
				{this.props.content && this.props.content.map((content, index) =>
				{
					const isRowFirst = (index % this.perRow) === 0;
					const isRowLast = (index % this.perRow) === (this.perRow - 1);
					const isLastRow = singleRow || (index >= indexInLastRow);

					var style = {
						width: this.contentWidth,
						marginRight: isRowLast ? 0 : spacing,
						marginBottom: isLastRow ? 0 : spacing
					};
					
					return (
						<div
							className={"contentGridCell" +
							(isRowFirst ? " first":"") +
							(isRowLast ? " last":"")}
							style={style}
							key={index}
						>
							{onRender(content, index, this.props.content,
								this.contentWidth, this.perRow, isRowFirst, isRowLast)}
						</div>
					);
				})}
			</div>
		);
	}
}

ContentGrid.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(ContentGrid);