import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import AppState from '../AppState';
import Button from 'material-ui/Button';
import AdvCardMap from '../components/adventure/AdvCardMap';
import BackIcon from 'material-ui-icons/KeyboardArrowLeft';
import { observable } from 'mobx';
import ContentCard from '../components/ContentCard';
import Slide from 'material-ui/transitions/Slide';
import AdvAdventure from '../components/adventure/AdvAdventure';
import Tracking from '../Tracking';
import CloseIcon from 'material-ui-icons/Close';

const styles = theme => ({
});

@observer
class AdventureMapPage extends React.Component
{
	advManager;
	map;
	@observable showBackButton = true;
	@observable showContentPopup = false;
	popupContentItemDisplay;
	popupAdventureId;

	constructor(props)
	{
		super(props);

		this.advManager = AppState.instance.advManager;
	}

	componentDidMount()
	{
		Tracking.OnPageView("AdventureMapPage");
	}

	componentWillUnmount()
	{
	}

	OnMarkerClicked = (marker) =>
	{
		if (!marker.activity)
			return;

		const data = marker.activity;

		if (data.type === "advlocation")
		{
			const locId = data.id;

			//if (data.title === "cardlocation")
			//{
				const cardIdsAtLocation = this.advManager.GetCardIdsOfLocation(locId);
				if (cardIdsAtLocation.length > 0)
				{
					this.showBackButton = false;
					this.showContentPopup = false;
					AppState.instance.cardDetailsPopup.ShowMultiple(cardIdsAtLocation, this.OnDetailsClosed);
				}
			// }
			// else if (data.title === "adventurelocation")
			// {
			// 	const adventureIds = data.adventureIds;
			// 	if (adventureIds && adventureIds.length > 0)
			// 	{
			// 		this.popupAdventureId = adventureIds[0]; //TODO: support multiple adventures
			// 		this.popupContentItemDisplay = undefined;
			// 		this.showContentPopup = false;
			// 		this.showContentPopup = true;
			// 	}
			// }
		}
		else if (data.type === "content")
		{
			// Show small popup
			this.popupAdventureId = undefined;
			this.popupContentItemDisplay = data;
			this.showContentPopup = false;
			this.showContentPopup = true;
		}

		Tracking.SendEvent("advmap_marker_clicked", {type: data.type, id: data.id});
	}

	OnDetailsClosed = (cardIds, reason) =>
	{
		this.showBackButton = true;

		if (reason === "activity")
		{
			if (this.props.onBackClick)
				this.props.onBackClick();
		}
	}

	OnActivityClicked = () =>
	{
		if (this.props.onBackClick)
			this.props.onBackClick();
	}

	OnMapStartMove = () =>
	{
		this.OnClosePopup();
	}

	OnClosePopup = () =>
	{
		this.showContentPopup = false;
		if (this.map)
			this.map.SelectMarker(undefined);
	}

	OnAdventureCta = (advId) =>
	{
		if (AppState.instance.cardCollectionPageInstance)
		{
			if (this.props.onBackClick)
				this.props.onBackClick();

			AppState.instance.cardCollectionPageInstance.OnAdventureCta(advId);
		}
	}

	render()
	{
		if (AppState.instance.isShowingDetailPage > 0)
			return null;

		return (
			<div className="adventureMapPage">

				{this.showBackButton && <div className="adventureMapPageTopBar">
					{<Button className="back" onClick={this.props.onBackClick}>
						<BackIcon />
					</Button>}
				</div>}

				<AdvCardMap
					defaultZoom={10}
					gestureHandling="greedy"
					onMarkerClicked={this.OnMarkerClicked}
					onMapStartMove={this.OnMapStartMove}
					onInit={(instance) => this.map = instance}
					cardLocationsOnly={false}
					showAdventureMarkers={false}
				/>

				<div className={"contentPopup" + (this.popupAdventureId ? " adventure":"")
					 + (this.popupContentItemDisplay ? " content":"")
					 + (this.showContentPopup ? " visible":"")}>
 					<Slide direction="up" in={this.showContentPopup}>
					 	<div>
						 	<div className="closeBtnFrame">
								<Button onClick={this.OnClosePopup}>
									<CloseIcon/>
								</Button>
							</div>
							{this.popupContentItemDisplay && <ContentCard
								contentData={this.popupContentItemDisplay}
								columnMode={false}
								isFirst={false}
								isLast={false}
								itemType="content"
								renderMode="tiny"
								defaultContentHeight={100}
								maxNumCategories={1}
								onClick={this.OnActivityClicked}
								bookmarkEnabled={false}
							/>}
							{this.popupAdventureId &&
								<div className="adventurePopupContainer">
									
									<AdvAdventure
										adventureId={this.popupAdventureId}
										onCta={this.OnAdventureCta}
									/>
								</div>}
						</div>
					</Slide>
				</div>
			</div>
		);
	}
}

AdventureMapPage.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AdventureMapPage);