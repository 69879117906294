import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import AppState from '../AppState';
import $ from 'jquery';
import Utils from '../Utils';
import prefix from 'vendor-prefix';
import ReactIScroll from '../iscroll/react-iscroll';
import iScroll from 'iscroll/build/iscroll-probe.js';
import Cancel from 'material-ui-icons/Cancel';
import ReactSwing from '../components/swing/ReactSwing';
import Reparentable from './Reparentable';

const styles = theme => ({
});

@observer
class CardPack extends React.Component
{
	isActivated;
	stack;
	windowInnerWidth;
	windowInnerHeight;
	portalRef;
	cardDataForEnd;
	cards = [];
	lastTopCardId;
	iScrollOptions;
	_isMounted = false;

	constructor(props)
	{
		super(props);

		this.windowInnerHeight = window.innerHeight;
		this.windowInnerWidth = window.innerWidth;

		this.iScrollOptions = {
			mouseWheel: true,
			scrollbars: false,
			scrollX: false,
			scrollY: true,
			freeScroll: false,
			fadeScrollbars: true,
			bounce: false,
			momentum: true,
			eventPassthrough: false,
			tap: true,
			click: true,
			interactiveScrollbars: false, //TODO: set to true on PC
			probeType: 2
		};

		this.UpdateCardInstances(this.props.cards);
	}

	componentDidMount()
	{
		this._isMounted = true;

		this.UpdatePageDimensions();
		var thisPtr = this;
		$(window).on('resize orientationchange', function() {
			thisPtr.UpdatePageDimensions();
		});

		this.isActivated = true;

		if (this.props.onInit)
			this.props.onInit(this);

		this.UpdateCardInstances(this.props.cards);
	}

	componentDidUpdate(prevProps)
	{
		if (this.props.visible !== prevProps.visible)
		{
			if (this.props.visible)
			{
				this.OnActivate();
			}
			else
			{
				this.OnDeactivate();
			}
		}

		this.UpdateCardInstances(this.props.cards);
	}

	componentWillUnmount()
	{
		this._isMounted = false;
	}

	GetCardState(id)
	{
		for (var i = 0; i < this.cards.length; ++i)
		{
			if (this.cards[i].id === id)
			{
				return this.cards[i];
			}
		}
		return undefined;
	}

	GetCardElement(cardId)
	{
		const cardState = this.GetCardState(cardId);
		if (cardState)
			return cardState.element;
		return undefined;
	}

	UpdateCardInstances(input, callForceUpdate)
	{
		var newCards = [];

		for (var i = 0; i < input.length && i <= 1; ++i)
		{
			var neededCard = input[i];

			var cardState = this.GetCardState(neededCard.id);
			if (!cardState)
			{
				cardState = {
					element: this.RenderCard(neededCard),
					id: neededCard.id,
					data: neededCard
				};
			}

			if (cardState.element)
			{
				newCards.push(cardState);
			}
			else
			{
				// Card element could not be rendered (e.g. because content details are not loaded yet)
				// => don't show an empty card
			}
		}

		var changed = false;

		if (callForceUpdate !== false)
		{
			if (this.cards.length !== newCards.length)
			{
				changed = true;
			}
			else
			{
				for (i = 0; i < this.cards.length; ++i)
				{
					if (this.cards[i].id !== newCards[i].id)
					{
						changed = true;
						break;
					}
				}
			}
		}

		this.cards = newCards;

		if (changed && this._isMounted)
		{
			//console.log("CardStack.forceUpdate() from UpdateCardInstances");
			this.forceUpdate();
		}
	}

	OnActivate()
	{
		var thisPtr = this;
		this.isActivated = true;

		setTimeout(() =>
		{
			for (var i = 0; i < thisPtr.cards.length; ++i)
			{
				var iInstance = thisPtr.cards[i].iScrollInstance;
				if (iInstance)
				{
					iInstance.enable();
					iInstance.refresh();
				}
			}
		}, 100);

		//Reset the top card (sometimes it gets rotated when leaving this page)
		this.ResetTopCardPosition();
	}

	OnDeactivate()
	{
		this.isActivated = false;

		for (var i = 0; i < this.cards.length; ++i)
		{
			var iInstance = this.cards[i].iScrollInstance;
			if (iInstance)
			{
				iInstance.disable();
			}
		}

		//Reset the top card (sometimes it gets rotated when leaving this page)
		this.ResetTopCardPosition();
	}

	IsBusy()
	{
		// check for scrolling and dragging on top card
		if (this.cards.length > 0)
		{
			var cardData = this.cards[0]; //the top card

			if (cardData.isCardScrolled)
				return true;
			if (cardData.cardIsDragged)
				return true;
		}

		return false;
	}

	ResetTopCardPosition()
	{
		if (this.stack && this.cards.length > 0)
		{
			var cardData = this.cards[0]; //the top card

			var card = this.stack.getTopCard();
			if (card)
			{
				cardData.isCardScrolled = true; // So that dragging of the card is canceled
				card.reset();
				cardData.isCardScrolled = false;
			}
		}
		$(".cardReactionIcon").css('transform', "scale(0)");
	}

	/*ShowScrollGradient(cardData, show)
	{
		cardData.showScrollGradient = show;
		this.forceUpdate();
	}*/

	OnScrollRefresh = (cardId) => (iScrollInstance) =>
	{
		var cardData = this.GetCardState(cardId);
		if (!cardData)
			return;

		cardData.iScrollInstance = iScrollInstance;

		//TODO: inform owner of CardStack instance?

		// Bug workaround: iscrolls which are created in the background (e.g. when the user is on another page) can cause problems on the forefront page
		// experienced problem: scrolling on frontpage triggers iScroll._end => onClick event on frontpage
		if (!this.isActivated)
		{
			iScrollInstance.disable();
		}
	}

	OnScrollDestroy = (cardId) => (iScrollInstance) =>
	{
		var cardData = this.GetCardState(cardId);
		if (!cardData)
			return;

		if (cardData.iScrollInstance)
			delete cardData.iScrollInstance;
		//TODO: inform owner of CardStack instance?
	}

	SetIsCardScrolled(id, flag, iScrollInstance)
	{
		// if (!cardData.isCardScrolled && flag)
		// 	console.log("isCardScrolled => " + flag);
		// else if (cardData.isCardScrolled && !flag)
		// 	console.log("isCardScrolled => " + flag);

		var cardData = this.GetCardState(id);
		if (!cardData)
			return;

		cardData.isCardScrolled = flag;

		if (this.stack)
		{
			this.stack.setIsContentScrolling(flag);
			this.stack.setIScroll(iScrollInstance);
		}
	}

	OnScrollStart = (cardId) => (iScrollInstance) =>
	{
		var cardData = this.GetCardState(cardId);
		if (!cardData)
			return;
		//console.log("SCROLL START. Dragged: " + cardData.cardIsDragged);

		if (cardData.cardIsDragged)
		{
			//console.log("!!!!!!!!!!!!! MUST NOT HAPPEN!");
			//iScrollInstance.disable();
			return;
		}
		
		this.SetIsCardScrolled(cardId, false, iScrollInstance);
		cardData.scrollStart = iScrollInstance.y;
	}

	OnScroll = (cardId) => (iScrollInstance) =>
	{
		var cardData = this.GetCardState(cardId);
		if (cardData)
		{
			if (cardData.scrollStart !== iScrollInstance.y)
			{
				this.SetIsCardScrolled(cardId, true, iScrollInstance);
				cardData.scrollStart = iScrollInstance.y;
			}
		}
		//console.log("OnScroll: " + cardData.iScrollInstance.y);

		AppState.instance.OnScreenScrolled(this);
	}

	OnScrollEnd = (cardId) => (iScrollInstance) =>
	{
		this.SetIsCardScrolled(cardId, false, iScrollInstance);
		//cardData.cardIsDragged = false;
		//console.log("scroll_end");
	}

	UpdatePageDimensions()
	{
		this.windowInnerHeight = window.innerHeight;
		this.windowInnerWidth = window.innerWidth;

		if (AppState.instance.isLandingPage)
		{
			this.windowInnerWidth = 375;
			this.windowInnerHeight = 667;
		}
	}

	EnableDragging(id)
	{
		this.SetDraggingEnabled(id, true);
	}

	DisableDragging(id)
	{
		this.SetDraggingEnabled(id, false);
	}

	SetDraggingEnabled(id, enabled)
	{
		const cardData = this.GetCardState(id);
		if (!cardData)
			return;
		this.SetIsCardScrolled(id, !enabled, cardData.iScrollInstance);
	}

	RefreshCard(id)
	{
		//console.log("RefreshCard: " + id);
		const cardData = this.GetCardState(id);
		if (!cardData)
			return;

		setTimeout(() => {
			if (cardData.iScrollInstance)
				cardData.iScrollInstance.refresh();
		}, 50);
	}

	ScrollTo(id, minPosition)
	{
		const cardData = this.GetCardState(id);
		if (!cardData)
			return;

		if (cardData.iScrollInstance)
		{
			if (cardData.iScrollInstance.y > minPosition)
			{
				cardData.iScrollInstance.scrollTo(0, minPosition, 400, iScroll.utils.ease.circular);
			}
		}
	}

	// OnBeforeLinkClicked = (linkName) =>
	// {
	// 	if (this.cardIsDragged)
	// 		return false;

	// 	return true;
	// }

	RenderCard(cardInputProp)
	{
		var renderedCard = cardInputProp.onRender(cardInputProp);
		if (!renderedCard)
			return null;

		const cardId = cardInputProp.id;

		return (
			<ReactIScroll
				key={cardId}
				id={cardId}
				className={"chatCardScroller"}

				iScroll={iScroll}
				options={this.iScrollOptions}
				onScrollStart={this.OnScrollStart(cardId)}
				onScroll={this.OnScroll(cardId)}
				onScrollEnd={this.OnScrollEnd(cardId)}
				onRefresh={this.OnScrollRefresh(cardId)}
				onDestroy={this.OnScrollDestroy(cardId)}
			>
				<div className={"chatActivityCard " + cardInputProp.type} key={cardId}>
					{renderedCard}
				</div>
				{(cardInputProp.showScrollGradient === undefined || cardInputProp.showScrollGradient === true) &&
					<div className="chatCardScrollGradient" />}
			</ReactIScroll>
		);
	}

	NudgeCard()
	{
		if (this.stack)
		{
			var topCard = this.stack.getTopCard();
			if (topCard)
			{
				topCard.dragTo(-60, 0, 300);
				setTimeout(() => {
					topCard.dragTo(60, 0, 200, -60, 0);

					setTimeout(() => {
						topCard.dragTo(60, 0, 300);
						setTimeout(() => {
							topCard.dragTo(-60, 0, 200, 60, 0);

							setTimeout(() => {
								this.OnDragEnd();
							}, 400);
						}, 400);
					}, 400);
				}, 400);
			}
		}
	}

	Pose()
	{
		if (this.stack)
		{
			var topCard = this.stack.getTopCard();
			if (topCard)
			{
				topCard.dragTo(65, 5, 300);
			}
		}
	}

	GetTopCardData()
	{
		return this.cards.length > 0 ? this.cards[0] : undefined;
	}

	IsDragEnabled = () =>
	{
		const cardData = this.GetTopCardData();
		if (!cardData)
			return false;

		return ! (cardData.isCardScrolled === true);
	}

	OnDragStart = () =>
	{
		const cardData = this.GetTopCardData();
		if (!cardData)
			return;

		this.OnDragStartInternal(cardData);
	}

	OnDragStartInternal(cardData)
	{
		if (!cardData.cardIsDragged)
		{
			//console.log("OnDragStart");
			cardData.cardIsDragged = true;

			if (cardData.iScrollInstance)
			{
				//console.log("iScroll.disable: " + cardData.id);
				cardData.iScrollInstance.disable();
			}
		}
	}

	OnDragEnd = () =>
	{
		const cardData = this.GetTopCardData();
		if (cardData)
			this.OnDragEndInternal(cardData);
	}

	OnDragEndInternal(cardData)
	{
		if (cardData.cardIsDragged)
		{
			//console.log("OnDragEnd");
			cardData.cardIsDragged = false;
			if (cardData.iScrollInstance)
			{
				//console.log("iScroll.enable: " + cardData.id);
				cardData.iScrollInstance.enable();
			}
		}
	}

	OnCardRest = () =>
	{
		const cardData = this.GetTopCardData();
		if (cardData)
		{
			this.OnDragEndInternal(cardData);
			return cardData.resetSpring;
		}
	}

	CalcRotation = (coordinateX, coordinateY, element, maxRotation) =>
	{
		const cardData = this.GetTopCardData();
		if (!cardData)
			return 0;

		if (cardData.isCardScrolled)
		{
			coordinateX = 0;
			coordinateY = 0;
			maxRotation = 0;
		}

		const horizontalOffset = Math.min(Math.max(coordinateX / element.offsetWidth, -1), 1);
		const verticalOffset = 1;//(coordinateY > 0 ? 1 : -1) * Math.min(Math.abs(coordinateY) / 100, 1);
		const rotation = horizontalOffset * verticalOffset * maxRotation;

		// const fontFactor = 3 * 100;
		// var scaledOffset = horizontalOffset * fontFactor;
		// if (scaledOffset > 100)
		// 	scaledOffset = 100;
		// else if (scaledOffset < -100)
		// 	scaledOffset = -100;

		var cardObj = $(element);
		var ic = cardObj.find(".cardReactionIcon");

		if (ic && ic.length === 2 && cardData.data.contentData && cardData.data.contentData.activity)
		{
			var iconTop = this.windowInnerHeight * 0.25;//horizAbs;
			//if (cardData.iScrollInstance)
			//	iconTop -= cardData.iScrollInstance.y;

			var windowWidth = this.windowInnerWidth;
			if (windowWidth > 560)
				windowWidth = 560;

			var iconLeft = windowWidth * 0.2;
			if (horizontalOffset < 0)
				iconLeft = windowWidth * 0.5;

			var idx = 0;
			if (coordinateX > 0)
				idx = 1;

			var scale = 1.0 - Math.abs(horizontalOffset);
			scale *= scale;
			scale = 1.0 - scale;

			var borderColorFactor = scale * 5;

			scale *= 3;
			if (scale < 0.1)
				scale = 0;
			if (scale > 1.5)
				scale = 1.5;
			
			const tr = prefix('transform');

			const newValue = 'translate(' + iconLeft + 'px, ' + iconTop + 'px) scale(' + scale + ')';

			if (this.prevValue !== newValue)
			{
				ic[idx].style[tr] = newValue;
				ic[1 - idx].style[tr] = 'scale(0)';

				this.prevValue = newValue;

				var attrStr = "border-color: #e0e0e0 !important";
				if (borderColorFactor > 0.6 && coordinateX > 0)
					attrStr = "border-color: #e31c46 !important";
				$("#swipePage .userListSelector button").attr('style', attrStr);
			}
		}
	
		return rotation;
	}

	// @return = Cancel Dragging?
	CalcTransform = (element, coordinateX, coordinateY, rotation) =>
	{
		//console.log("CalcTransform:" + coordinateX + "/" + coordinateY + ", isCardScrolled: " + cardData.isCardScrolled);

		const cardData = this.GetTopCardData();
		if (!cardData)
			return true;

		if (cardData.isCardScrolled)
		{
			coordinateX = 0;
			coordinateY = 0;
			rotation = 0;
		}

		const wasDragged = cardData.cardIsDragged;
		const isDragging = (coordinateX !== 0 || coordinateY !== 0 || rotation !== 0);
		//cardData.cardIsDragged = 

		element.style[prefix('transform')] = 'translate3d(0, 0, 0) translate(' + coordinateX + 'px, ' + coordinateY + 'px) rotate(' + rotation + 'deg)';

		// Scale background cards
		const showBackgroundCards = this.props.showBackgroundCards;
		if (AppState.instance.deviceInfo.ios || showBackgroundCards)
		{
			var distance = Math.sqrt(coordinateX * coordinateX + coordinateY * coordinateY);
			var scaleFactor = distance / 130;
			scaleFactor = Math.min(1.0, scaleFactor);
			var scale = 0.95 + scaleFactor * 0.05;

			// If top card is sent back, set scale to 1.0
			//const topCardData = cardData.data;
			//if (topCardData && topCardData.sendback)
			//	scale = 1.0;

			var yTransform = "";
			if (showBackgroundCards)
			{
				const maxYOffset = this.props.backgroundCardYOffset || 20.0;
				var yOffset = (1.0 - scaleFactor) * maxYOffset;
				yTransform = ' translate(0px, ' + yOffset + 'px)';
			}

			var backgroundElements = $(".backgroundCards ." + this.props.classNameCardWrapper);
			backgroundElements.each(function()
			{
				this.style[prefix('transform')] = 'scale(' + scale + ')' + yTransform;
			});
		}

		if (!wasDragged && isDragging)
		{
			this.OnDragStartInternal(cardData);
		}

		return cardData.isCardScrolled;
	}

	CalcThrowOutConfidence = (xOffset, yOffset, element) => 
	{
		const cardData = this.GetTopCardData();
		if (!cardData)
			return 0;

		if (cardData.isCardScrolled)
			return 0;

		const factor = 3;
		var cardWidth = this.props.cardWidth || this.windowInnerWidth;
		return Math.min(Math.abs(xOffset) / cardWidth * factor, 1);
	}

	HandleOnCardThrowOut(cardData)
	{
		//console.log("_____THROWOUT");

		this.cardDataForEnd = cardData;

		if (this.props.onThrowOutDecision)
		{
			this.props.onThrowOutDecision(cardData.data, cardData.lastThrowDirection);

			// Make sure the card is not thrown back into center
			cardData.resetSpring = true;
		}
	}

	OnCardThrowoutLeft = () =>
	{
		const cardData = this.GetTopCardData();
		if (!cardData)
			return;

		cardData.lastThrowDirection = -1;
		this.HandleOnCardThrowOut(cardData);
	}

	OnCardThrowoutRight = () =>
	{
		const cardData = this.GetTopCardData();
		if (!cardData)
			return;

		cardData.lastThrowDirection = +1;
		this.HandleOnCardThrowOut(cardData);
	}

	OnCardThrowoutEnd = () =>
	{
		//console.log("_____END");

		const cardData = this.cardDataForEnd;
		this.cardDataForEnd = undefined;

		if (!cardData)
			return;

		// Reset flag in case this card is reused
		//cardData.sendback = undefined;

		if (this.props.onThrowOutEnd)
			this.props.onThrowOutEnd(cardData.data, cardData.lastThrowDirection);

		cardData.lastThrowDirection = undefined;

		setTimeout(() => {
			var attrStr = "border-color: #e0e0e0 !important";
			$("#swipePage .userListSelector button").attr('style', attrStr);
		}, 800);

		/*if (this.stack)
		{
			var topCard = this.stack.getTopCard();
			if (topCard)
			{
				topCard.reset();
			}
		}*/
	}

	UpdateTopCardElement()
	{
		if (this.cards.length > 0)
		{
			var topCard = this.cards[0];
			topCard.element = this.RenderCard(topCard.data);
			if (this._isMounted)
				this.forceUpdate();
		}
	}

	GetTopCard()
	{
		if (this.stack)
			return this.stack.getTopCard();
		return undefined;
	}

	render()
	{
		var topCard;
		var backgroundCard;

		this.UpdateCardInstances(this.props.cards, false);

		//console.log("CardStack cards:");
		//console.log(this.cards);

		if (this.cards.length > 0)
		{
			topCard = this.cards[0];

			if (this.cards.length > 1)
			{
				backgroundCard = this.cards[1];
			}
		}

		var stackConfig = {
			dragEnabled: this.IsDragEnabled,
			OnDragEnd: this.OnDragEnd,
			OnDragStart: this.OnDragStart,
			OnCardRest: this.OnCardRest,
			rotation: this.CalcRotation,
			maxRotation: 18,
			minThrowOutDistance: this.windowInnerWidth * 1.6,
			maxThrowOutDistance: this.windowInnerWidth * 1.7,
			throwOutConfidence: this.CalcThrowOutConfidence,
			transform: this.CalcTransform
		}

		var tr = prefix('transform');

		var backgroundWithShadow = false;//topCard === undefined;
		var backgroundStyle = {};
		const showBackgroundCards = this.props.showBackgroundCards;
		if (AppState.instance.deviceInfo.ios || showBackgroundCards)
		{
			if (backgroundCard && backgroundCard.element)
				backgroundWithShadow = true;

			var styleStr = 'scale(0.95)';

			if (showBackgroundCards)
			{
				const maxYOffset = this.props.backgroundCardYOffset || 20.0;
				styleStr += ' translate(0px, ' + maxYOffset + 'px)';
			}

			backgroundStyle[tr] = styleStr;
		}

		var scaleZero = {};
		scaleZero[tr] = 'scale(0)';

		if (topCard)
		{
			if (this.lastTopCardId !== topCard.id)
			{
				// New top card -> enable/reset iScroll
				setTimeout(() => {
					if (topCard.iScrollInstance)
					{
						topCard.iScrollInstance.enable();
						topCard.iScrollInstance.refresh();
					}	
				}, 100);
			}
			this.lastTopCardId = topCard.id;
		}
		else
		{
			this.lastTopCardId = undefined;
		}

		//console.log("CardStack.render with #cards: " + this.props.cards.length);

		return (
			<div id={this.props.id} className={this.props.className} key={this.props.key}>

				<div className="backgroundCards">
					<div
						className={this.props.classNameCardWrapper + (backgroundWithShadow ? " withShadow":"")}
						key={"backgroundCards"}
						style={backgroundStyle}
					>
						{backgroundCard && backgroundCard.element &&
						<Reparentable
							key={backgroundCard.id}
							uid={"uid" + backgroundCard.id}
							className="reparentable"
						>
							{backgroundCard.element}
						</Reparentable>}
					</div>
				</div>

				<ReactSwing
					className="stack"
					setStack={(stack)=> {this.stack = stack;}}
					throwoutleft={this.OnCardThrowoutLeft}
					throwoutright={this.OnCardThrowoutRight}
					throwoutend={this.OnCardThrowoutEnd}
					config={stackConfig}
					isContentScrolling={topCard ? topCard.data.isCardScrolled : undefined}
					iScroll={topCard ? topCard.data.iScrollInstance : undefined}
					key={"reactSwing"}
				>
					<div className={this.props.classNameCardWrapper + " " + this.props.classNameCardWrapper + "Top"} >

						{topCard && topCard.element &&
						<Reparentable
							key={topCard.id}
							uid={"uid" + topCard.id}
							className="reparentable"
						>
							{topCard.element}
						</Reparentable>}
						
						<div className="cardReactionIcon cardReactionIconNeg" id="reactionIconNeg" style={scaleZero}>
							<div className="cardReactionIconNegBG" />
							<Cancel />
						</div>
						<div className="cardReactionIcon cardReactionIconPos" id="reactionIconPos" style={scaleZero}>
							{Utils.RenderStarIcon(0, 0, "#e31c46", "#e31c46", 2.5)}
						</div>
					</div>
				</ReactSwing>
			</div>
		);
	}
}

CardPack.propTypes =
{
	classes: PropTypes.object,
	theme: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(CardPack);