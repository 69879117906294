/* es_____lint-disable no-unused-vars */
import React from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import {withStyles} from 'material-ui/styles';
import PropTypes from 'prop-types';
import Utils from '../Utils';
import $ from 'jquery';
import Spinner from 'react-spinkit';
import Tracking from '../Tracking';
import AppState from '../AppState';
import Button from 'material-ui/Button';
import DetailHeaderBar from '../components/DetailHeaderBar';
import ActivityRenderer from '../components/ActivityRenderer';
import ArrowBack from 'material-ui-icons/ArrowBack';
import LandingPageFooter from '../components/LandingPageFooter';
//import SearchField from '../components/SearchField';
//import SearchIcon from 'material-ui-icons/Search';
import SearchPage from './SearchPage';
import Translate from '../Translate';

const styles = {
	closeButtonRoot: {
		width: '38px',
		height: '38px',
		top: '10px',
		left: '10px',
		position: 'absolute',
		zIndex: 2,
		backgroundColor: 'rgba(255,255,255,0.6)',
		borderRadius: 19,
		color: 'rgba(0,0,0,0.8)',
	},
};


@observer
class DetailPage extends React.Component
{
	domLink;
	data;
	popped = false;
	detailsLoaded = false;
	@observable weblinks = [];
	@observable isMoreMenuOpen = false;
	isLoading = 0;
	@observable showOpenDetails = false;
	showEditAfterLoad = false;
	addReviewAfterLoad = false;
	@observable searchHasFocus = false;
	
	defaultContentHeight = Utils.GetMaxContentHeight();

	static isMounted = false;

	static contextTypes =
	{
		router: PropTypes.object
	}

	constructor(props)
	{
		super(props);

		//AppState.instance.contentStore.Pause();

		// Setup data structure
		this.data = {};
		this.data.activity = {};
		this.data.activity.pictureUrls = [];
		this.data.openingHours = [];

		// Prefill with what we already know. Ideally everything above the fold.
		this.data.activity.name = undefined;

		if (this.props.match)
			this.data.activity.name = this.props.match.params.title;
		if (!this.data.activity.name)
			this.data.activity.name = this.props.title;

		if (AppState.instance.ipLocationLoaded)
			this.LoadData();
		else
			AppState.instance.AddOnIpLocation(this.LoadData);

		//this.historyLengthAtStart = window.history.length;
		DetailPage.isMounted = true;
	}

	componentDidMount()
	{
		AppState.instance.AddOnAuthDone(this.OnAuthDone);

		this.domLink = $(".detailPage");
		//this.domLink.scroll(this.OnScroll);

		//this.domLink.css('height', window.innerHeight);

		AppState.instance.isShowingDetailPage++;
		AppState.instance.appContainer.forceUpdate();

		$("#siteContent").css('display', 'none');
		$("#siteBottom").css('display', 'none');

		Utils.ScrollToTop();
	}

	componentDidUpdate(prevProps)
	{
		// A new content entry to display? (e.g. because user clicked an item below the map)
		if (this.props.match)
		{
			const name0 = Utils.GetUrlName(this.data.activity.name);
			const name1 = Utils.GetUrlName(this.props.match.params.title);

			if (name0 !== name1)
			{
				this.data = {};
				this.data.activity = {};
				this.data.activity.pictureUrls = [];
				this.data.openingHours = [];

				// Prefill with what we already know. Ideally everything above the fold.
				this.data.activity.name = this.props.match.params.title;

				$(".detailPage").scrollTop(0);
				Utils.ScrollToTop();

				this.LoadData();
			}
		}
	}

	componentWillUnmount()
	{
		--AppState.instance.isShowingDetailPage;
		DetailPage.isMounted = false;

		AppState.instance.RemoveOnAuthDone(this.OnAuthDone);
		AppState.instance.contentStore.Resume();
		AppState.instance.OnScreenScrolled(this);

		if (AppState.instance.isShowingDetailPage === 0)
		{
			$("#siteContent").css('display', 'block');
			$("#siteBottom").css('display', 'block');
		}
		if (AppState.instance.appInstance && AppState.instance.isShowingDetailPage === 0)
			AppState.instance.appInstance.AdjustUiToUrl(false);
		AppState.instance.appContainer.forceUpdate();
	}

	OnAuthDone = () =>
	{
		if (this.detailsLoaded && AppState.instance.IsLoggedIn())
		{
			if (this.data.activity && this.data.activity.name)
				AppState.instance.contentStore.ClearActivityDetails(this.data.activity.name);
				
			this.LoadData();
		}
	}

	LoadData = () =>
	{
		var thisPtr = this;

		var toLoadName = this.data.activity.name;

		Tracking.OnPageView("activity/" + Utils.GetUrlName(toLoadName));

		var ci = AppState.instance.contentStore.FindContentItem(toLoadName);
		if (ci && !this.data.activity.infoUrl)
		{
			this.data = {};
			this.data.activity = {};
			this.data.activity.pictureUrls = [];
			this.data.openingHours = [];
			this.data.activity.name = ci.title;
			this.data.activity.infoUrl = "/activity/" + Utils.GetUrlName(ci.title);
			this.data.activity.address = ci.address;
			this.data.activity.categories = ci.categoryIds;
			this.data.activity.latitude = ci.latitude;
			this.data.activity.longitude = ci.longitude;
			this.data.activity.desc = ci.desc;
			this.data.distance = ci.distance;
			this.data.activity.id = ci.id;
			this.data.activity.pictureUrls.push(ci.img);
			this.data.activity.rating = ci.rating;
			this.data.activity.accessible = ci.accessible;
			this.data.activity.bookingRequired = ci.bookingRequired;
			this.data.activity.carParking = ci.carParking;
			this.data.activity.dogsWelcome = ci.dogsWelcome;
			this.data.activity.strollerSuitable = ci.strollerSuitable;
			this.data.categories = ci.categories;
			if (DetailPage.isMounted)
			{
				setTimeout(() => {
					this.forceUpdate();	
				}, 0);
			}
		}
		else
		{
			this.isLoading++;
		}

		AppState.instance.contentStore.GetActivityDetails(toLoadName, (details) =>
		{
			if (!details)
			{
				thisPtr.isLoading--;
				return;
			}

			thisPtr.isLoading--;
			thisPtr.data = details;
			thisPtr.detailsLoaded = true;
			if (DetailPage.isMounted)
				thisPtr.forceUpdate();

			Tracking.SendEvent("contentDetails",
			{
				activityId: details.activity.id,
				latitude: details.activity.latitude,
				longitude: details.activity.longitude
			});
		});
	}

	GetHeaderBarParent()
	{
		return this.domLink[0];
	}

	OnBackClicked = () =>
	{
		//setTimeout(() => {
			this.GoBack();
		//}, 50);
	}

	OnCardFetcherHintClicked = () =>
	{
		this.GoBack();
		if (AppState.instance.desktopAppInstance)
		{
			AppState.instance.desktopAppInstance.ChangePage(AppState.TabIndexAdventure);
		}
	}

	GoBack()
	{
		Tracking.FlushVisibleItemsTracking();
		
		if (AppState.instance.startedWithActivity)
		{
			AppState.instance.startedWithActivity = false;
			AppState.instance.appContainer.GoTo("/");
			if (DetailPage.isMounted)
				this.forceUpdate();

			//HACK for bugfix: scrolling on swipe-card does not work if user comes directly from /activity/xyz URL
			if (AppState.instance.swipePageInstance)
				AppState.instance.swipePageInstance.OnActivate();

			if (AppState.instance.desktopAppInstance)
				AppState.instance.desktopAppInstance.OnBackFromDetailPageStart();
		}
		else
		{
			AppState.instance.startedWithActivity = false;
			AppState.instance.appContainer.GoBack();

			//HACK: On iOS we have to go back twice sometimes
			// if (AppState.instance.deviceInfo.ios)
			// {
			// 	setTimeout(() => {
			// 		if (DetailPage.isMounted)
			// 		{
			// 			AppState.instance.appContainer.GoBack();
			// 		}
			// 	}, 100);
			// }
		}	
	}

	OnDataInRendererChanged = (onlySizeChanged) =>
	{
		//console.log("OnDataInRendererChanged");
		if (onlySizeChanged !== true)
			this.LoadData();
	}

	OnAfterAddToTrip = () =>
	{
		this.GoBack();
	}

	OnHeaderBarLightDarkSwitch = (headerBar, isLight) =>
	{
		if (isLight)
		{
			// Just display the arrow
			headerBar.removeClass("detailToolbarBGShow").addClass("detailToolbarBGHidden");
			$(".toolbarButton").removeClass("toolbarButtonDark").addClass("toolbarButtonLight");
		}
		else
		{
			headerBar.removeClass("detailToolbarBGHidden").addClass("detailToolbarBGShow");
			$(".toolbarButton").removeClass("toolbarButtonLight").addClass("toolbarButtonDark");
		}
	}

	OnScroll = () =>
	{
		if (DetailHeaderBar.instance)
			DetailHeaderBar.instance.OnScroll(this.domLink.scrollTop());

		$(".shareDropDown").css('display', 'none');
		if (this.isMoreMenuOpen === true)
			this.isMoreMenuOpen = false;

		AppState.instance.OnScreenScrolled(this);
	}

	OnCategoryClicked = (catName) =>
	{
		//TODO: repair
		// const catId = AppState.instance.contentStore.GetCategoryId(catName);
		// if (catId !== undefined)
		// {
		// 	this.GoBack();
		// 	AppState.instance.appInstance.OnTabbarChanged(undefined, AppState.TabIndexExplore);
		// 	AppState.instance.mainPageInstance.OnCategoryClicked(catId);
		// }
	}

	OnOpenMainSite = () =>
	{
		window.open("https://www.lena.app", "_self");
	}

	OnSearch = (searchTerm) =>
	{
		//console.log(searchTerm);

		AppState.instance.lastEnteredSearchString = searchTerm;

		AppState.instance.userProfile.OnSearch(searchTerm);
		AppState.instance.contentStore.Search(searchTerm);

		this.forceUpdate();
	}

	OnSearchFocus = () =>
	{
		this.searchHasFocus = true;
		//console.log("OnFocus");
	}

	OnSearchBlur = () =>
	{
		setTimeout(() => {
			this.searchHasFocus = false;
			//console.log("OnSearchBlur");
		}, 300);
	}

	OnEnter = () =>
	{
		//this.searchHasFocus = false;
		//console.log("OnEnter");
	}

	render()
	{
		//Tracking.OnRender("activity/" + this.data.activity.name);

		var cornerIcon = <ArrowBack />;
		var additionalIconClass = "";
		if (AppState.instance.startedWithActivity)
		{
			cornerIcon = Utils.RenderLogo(40, 40, "white", "#e31c46");
			additionalIconClass = " detailBackLogo";
		}

		return (
			<div className={AppState.instance.isLandingPage ? "detailPageDialogLP":"detailPageDialog" + AppState.instance.GetPlatformClasses()}>

				{AppState.instance.deviceInfo.desktop && AppState.instance.IsLoggedIn() &&
				<Button className="desktopDetailBackBtn" onClick={this.OnBackClicked}>
					{Translate.T("de.Zurück", "en.Back", "back")}
				</Button>}

				{!AppState.instance.deviceInfo.desktop && <DetailHeaderBar
					parent={this.GetHeaderBarParent}
					height={56}
					lightDarkThreshold={this.defaultContentHeight}
					onLightDarkSwitch={this.OnHeaderBarLightDarkSwitch}
					//onScroll={this.OnHeaderBarScroll}
					additionalClassNames="detailToolbar detailToolbarBGHidden"
				>
					<Button className={"toolbarButton detailBackIcon toolbarButtonLight" + additionalIconClass} onClick={this.OnBackClicked}>
						{cornerIcon}
					</Button>
				</DetailHeaderBar>}

				<div
					id={this.props.id}
					key={this.data.activity.name}
					className="detailPage"
					onScroll={this.OnScroll}
				>
					<ActivityRenderer data={this.data}
						onChanged={this.OnDataInRendererChanged}
						onAddToTrip={this.OnAfterAddToTrip}
						allowAddTripButton={false}
						onCategoryClicked={this.OnCategoryClicked}
						ignoreActiveFilter={true}
						onCardFetcherHintClicked={AppState.instance.desktopAppInstance ? this.OnCardFetcherHintClicked : undefined}
					/>
					{(this.isLoading > 0) && <div className="detailLoadingContainer">
						<Spinner color="#e31c46"/>
					</div>}

					{AppState.instance.deviceInfo.desktop && <div className="detailPageFooter">
						<LandingPageFooter />
					</div>}

					{AppState.instance.deviceInfo.desktop && <div className={"detailSearchPageContainer" + (this.searchHasFocus ? " visible": " invisible")}>
						<SearchPage visible={this.searchHasFocus}
							searchTerm={AppState.instance.lastEnteredSearchString}
							hideDefaults={true}
							contentOnly={true}
						/>
					</div>}

				</div>
			</div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(DetailPage);