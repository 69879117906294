import AppState from './AppState';
import Delegate from './utils/Delegate';

export default class FilterManager
{
	filters = [];
	onFiltersChanged = new Delegate();

	Init()
	{
		AppState.instance.AddOnIpLocation(this.OnIpLocationLoaded);
		AppState.instance.AddOnAuthDone(this.OnAuthDone);
	}

	AddOnFilterChanged(onChanged)
	{
		this.onFiltersChanged.Add(onChanged);
	}

	RemoveOnFilterChanged(onChanged)
	{
		this.onFiltersChanged.Remove(onChanged);
	}

	OnAuthDone = () =>
	{
		this.LoadCategories();
	}

	OnIpLocationLoaded = () =>
	{
		this.LoadCategories();
	}

	LoadCategories()
	{
		if (!AppState.instance.GeneralDataLoadingEnabled())
		{
			setTimeout(() => {
				this.LoadCategories();
			}, 300);
			return;
		}

		AppState.instance.contentStore.GetCategories(this.OnFiltersDataChanged);
	}

	GetFilters()
	{
		return this.filters;
	}

	GetFilter(filterId)
	{
		for (var i = 0; i < this.filters.length; ++i)
		{
			const filter = this.filters[i];
			if (filter.id === filterId)
				return filter;
		}

		return undefined;
	}

	GetTopCatId()
	{
		//TODO: check if "showInPage" ?
		const stack = AppState.instance.filterStack;
		if (stack && stack.length > 0)
			return stack[stack.length - 1];
		return undefined;
	}

	IsFilterActive(filterId)
	{
		for (var i = 0; i < AppState.instance.filterStack.length; ++i)
		{
			if (AppState.instance.filterStack[i] === filterId)
				return true;
		}
		return false;
	}

	CreateFilterId(listOfIds)
	{
		var filterId = "";
		if (listOfIds)
		{
			for (var i = 0; i < listOfIds.length; ++i)
			{
				if (filterId.length > 0)
					filterId += "/";
				filterId += listOfIds[i];
			}
		}
		return filterId;
	}

	GetActiveFilters(excludeFilterbar, excludeSearchField)
	{
		var result = [];

		for (var i = 0; i < AppState.instance.filterStack.length; ++i)
		{
			var f = this.GetFilter(AppState.instance.filterStack[i]);

			if (!f)
				continue;

			if (excludeFilterbar && f.showInFilterBar)
				continue;
			if (excludeSearchField && f.showInSearchField)
				continue;

			result.push(f);
		}

		return result;
	}

	GetFilterCacheKey()
	{
		var result = "";

		for (var i = 0; i < this.filters.length; ++i)
		{
			const filter = this.filters[i];
			result += filter.title;
		}

		return result;
	}

	GetFilterId(filterName)
	{
		var _filterName = filterName.toLowerCase();

		for (var i = 0; i < this.filters.length; ++i)
		{
			const filter = this.filters[i];
			if (filter.title.toLowerCase() === _filterName)
				return filter.id;
		}

		return undefined;
	}

	GetFilterName(filterId)
	{
		for (var i = 0; i < this.filters.length; ++i)
		{
			const filter = this.filters[i];
			if (filter.id === filterId)
				return filter.title;
		}

		return undefined;
	}

	ClearAllFilters()
	{
		AppState.instance.filterStack = [];
	}

	OnFiltersDataChanged = (action, r) =>
	{
		if (action === 'data')
		{
			this.filters = r;
			this.onFiltersChanged.Call();
		}
	}

	CreateFilterUrl(filterStack)
	{
		var url = "";
		for (var i = 0; i < filterStack.length; ++i)
		{
			url += "/" + encodeURIComponent(this.GetFilterName(filterStack[i]));
		}
		return url;
	}

	GetNumSetFilters()
	{
		var result = 0;

		const up = AppState.instance.userProfile;

		// Search radius < 100km
		if (up.filterMaxDistance < 100)
			++result;

		// min rating
		if (up.filterMinRating !== undefined)
			++result;

		// max price
		if (up.filterMaxPrice !== undefined)
			++result;

		// age min/max
		// Disabled: that would be always on
		//if (up.filterMinAge > 0 || up.filterMaxAge < 99)
		//	++result;

		// all filters which are not displayed in filterbar or as breadcrumb
		var activeFilters = this.GetActiveFilters(true, true);
		result += activeFilters.length;

		return result;
	}
}