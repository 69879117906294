import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import Translate from '../Translate';
import Button from 'material-ui/Button';
import Utils from '../Utils';
import Tracking from '../Tracking';
import Rating from './Rating';
import AppState from '../AppState';
import SocialButton from './SocialButton';


const styles = {
	container: {
		width: 'calc(100% - 70px)',
		flexGrow: 1,
		position: 'relative',
		zIndex: 10,
		display: 'inline-block'
	  },
	  suggestionsContainerOpen: {
		position: 'absolute',
		width: '100%',
		zIndex: 10,
		marginTop: 0,
		marginBottom: 0,
		left: 8,
	  },
	  suggestion: {
		display: 'block',
	  },
	  suggestionsList: {
		margin: 0,
		padding: 0,
		listStyleType: 'none',
	  },
	textField:
	{
		width: '100%',
		border: '1px solid #e0e0e0',
		marginTop: '8px',
		marginBottom: '0px',
		marginLeft: '8px',
		paddingBottom: '4px',
		paddingTop: '4px',
		paddingLeft: '8px',
		paddingRight: '8px',
		backgroundColor: 'white'
	},
	closebutton: {
		width: '32px',
		height: '32px',
	},
	filterShortInfoItem : {
		width: '40px',
		minWidth: '0',
		height: '40px',
		borderRadius: '2px',
		border: '1px solid #ccc',
		padding: '8px'
	},
	filterShortInfoItemChecked : {
		width: '40px',
		minWidth: '0',
		height: '40px',
		borderRadius: '2px',
		padding: '8px',
		backgroundColor: '#e31c46',
		border: '1px solid #e31c46'
	},
};

@observer
class UserReview extends React.Component
{
	@observable isCollapsed = true;

	componentDidMount()
	{
		const vw = window.innerWidth;
		if (vw >= 1000 && !this.props.review.contentPartnerPortal)
			this.isCollapsed = false;
	}

	OnWeblinkClicked = (e) =>
	{
		var url = e.target.dataset.url;
		if (url)
		{
			if (url.startsWith("http"))
			{
				Utils.OpenExternalLink(url);
			}
			else
			{
				AppState.instance.appContainer.context.router.history.push(url);
			}
			var aId = this.props.activity ? this.props.activity.id : this.props.review.activityId;
			Tracking.SendEvent("weblinkClicked", {u: url, activityId: aId});
		}
	}

	OnReviewTextClicked = () =>
	{
		var review = this.props.review;
		if (review.contentPartnerLink)
		{
			Utils.OpenExternalLink(review.contentPartnerLink);

			Tracking.SendEvent("backlink", {
				url: review.contentPartnerLink,
				portal: review.contentPartnerPortal
			});
		}
		else
		{
			this.isCollapsed = false;
			if (this.props.onExpanded)
				this.props.onExpanded();
		}
	}

	OnEditReview = () =>
	{
		if (this.props.onEdit)
			this.props.onEdit();
	}

	static GetReviewStatusText(status)
	{
		return Utils.GetContentStatusStr(status);
	}

	render()
	{
		var collapsedText = this.props.collapsedText;
		var review = this.props.review;

		if (review.contentPartnerPortal)
			collapsedText = true;

		var isOwn = review.isOwnReview;

		//const extraPadding = this.props.extraPaddingMeasure || 0;
		//const padding = 24 + extraPadding;

		var reviewText = review.data.text;
		if (!reviewText)
			reviewText = "";

		const ending = "...";// mehr lesen";
		const originalLength = reviewText.length;

		const isHighlight = this.props.highlight === true;

		var backgroundColorStyle = {};
		var colorStyle = {};
		if (review.authorColor)
		{
			backgroundColorStyle = {backgroundColor: review.authorColor};
			colorStyle = {color: review.authorColor};
		}

		if (collapsedText && this.isCollapsed)
		{
			//TODO: too slow
			//const reviewTestStyle = {'padding-left': padding, 'padding-right': padding};
			//reviewText = Utils.TrimTextToNumLines(reviewText, "reviewText", 3, ending, reviewTestStyle);
			var maxChars = isHighlight ? 400 : 85;
			if (review.descMaxChars !== undefined)
				maxChars = review.descMaxChars;

			reviewText = Utils.TrimTextToMaxChars(reviewText, maxChars, ending);

			//var ww = Utils.MeasureTextWidth2(reviewText, 16);
			//console.log("width of " + reviewText + ": " + ww);
		}

		var readMore = null;
		if (originalLength !== reviewText.length)
		{
			reviewText = reviewText.substring(0, reviewText.length - ending.length + 3);

			if (review.contentPartnerPortal)
			{
				var borderColorStyle = {};
				if (review.authorColor)
				{
					borderColorStyle = {border: "1px solid " + review.authorColor};
				}
				else
				{
					borderColorStyle = {border: "1px solid #e31c46"};
				}

				readMore = <Button className="reviewTextMoreCP" onClick={this.OnReviewTextClicked} style={borderColorStyle}>
					<span style={colorStyle}>
						{Translate.T("en.Continue on {0}", "de.Weiterlesen auf {0}", "review.readmore.contentpartner", [review.contentPartnerPortal])}
					</span>
				</Button>;
			}
			else
			{
				readMore = <span className="reviewTextMore">{Translate.T("en.read more", "de.mehr lesen", "review.readmore")}</span>;
			}
		}

		var firstChar;
		var author = review.author_Name;
		if (author)
		{
			firstChar = author.charAt(0).toUpperCase();
		}
		else
		{
			author = Translate.T("en.Anonymous", "de.Anonym", "review.author.anonymous");
			firstChar = "A";
		}

		var profilePhoto;
		if (review.profile_Photo_Url)
		{
			profilePhoto = (
				<img className={"reviewUserPhoto" + (review.profile_Photo_Url.indexOf("google") >= 0 ? "" : " reviewUserPhotoRound")} src={review.profile_Photo_Url} alt="user profile"
					data-url={review.author_Url} onClick={this.OnWeblinkClicked}
				/>
			);
		}
		else
		{
			profilePhoto = (
				<div
					className="reviewUserPhoto reviewUserPhotoRound reviewUserPhotoAnonymous"
					data-url={review.author_Url} onClick={this.OnWeblinkClicked}
					style={backgroundColorStyle}
				>
					{firstChar}
				</div>
			);
		}

		var username;
		if (review.source === "LENA" && review.author_Url && review.author_Url.length > 0)
		{
			var parts = review.author_Url.split("/");
			username = parts[parts.length - 1];
		}

		const aId = this.props.activity ? this.props.activity.id : this.props.review.activityId;

		return (
			<div className="reviewContainer" key={"review_" + aId}>
				{profilePhoto}
				<div className="reviewUserName"
					data-url={review.author_Url} onClick={this.OnWeblinkClicked}
					style={isHighlight ? colorStyle : {}}
				>
					{author}
				</div>
				<div className="reviewRating">
					<Rating	value={review.data.rating} starColor="#777" />
				</div>
				<div className="reviewDate">{review.relative_Time_Description}</div>

				{username !== undefined && <div className="reviewFollowProfile">
					<SocialButton username={username} default="follow" />
				</div>}

				{/*review.source === "LENA" && review.isOwnReview === false && review.iamFollowing === false && <div className="reviewFollowProfile">
					<Button color="primary" onClick={this.FollowProfile} aria-label="Follow" style={colorStyle}>
						<PersonAdd />
						{Translate.T('en.Follow', 'de.Follow', 'account.button.follow')}
					</Button>
				</div>}
				{review.source === "LENA" && review.iamFollowing && <div className="reviewFollowProfile following">
					<Button color="primary" onClick={this.UnfollowProfile} aria-label="Follow" style={colorStyle}>
						<Check />
						{Translate.T('en.Following', 'de.Following', 'account.button.following')}
					</Button>
				</div>*/}

				<div className="reviewText" id={"reviewText" + aId} onClick={review.contentPartnerPortal === undefined ? this.OnReviewTextClicked : undefined}>
					{reviewText}
					{readMore}
				</div>

				{isOwn && <div className="reviewOwn">
					<div className="reviewOwnStatus">
						<div className="reviewOwnStatusTitle">
							{Translate.T("en.Status:", "de.Status:", "review.status.title")}
						</div>
						<div className={"reviewOwnStatusValue reviewOwnStatusValue" + review.data.status}>
							{UserReview.GetReviewStatusText(review.data.status)}
						</div>
					</div>
					<div className="reviewOwnButton">
						<Button onClick={this.OnEditReview}>
							{Translate.T("en.Edit review", "de.Bewertung editieren", "button.editreview")}
						</Button>
					</div>
				</div>}
			</div>
		);
	}
}

UserReview.propTypes = {
	classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(UserReview);