import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import ClickAwayListener from 'material-ui/utils/ClickAwayListener';
import Tooltip from 'material-ui/Tooltip';
import { observable } from 'mobx';

const styles = theme => ({
});

@observer
class TooltipWrapper extends React.Component
{
	@observable open = false;

	static tooltips = [];

	componentDidMount()
	{
		TooltipWrapper.tooltips.push(this);
	}

	componentWillUnmount()
	{
		const index = TooltipWrapper.tooltips.indexOf(this);
		if (index > -1)
		{
			TooltipWrapper.tooltips.splice(index, 1);
		}
	}

	OnClick = (e) =>
	{
		e.stopPropagation();

		// Close all others
		const all = TooltipWrapper.tooltips;
		for (var i = 0; i < all.length; ++i)
		{
			if (all[i] !== this)
				all[i].OnClose();
		}

		this.open = !this.open;
	}

	OnClose = () =>
	{
		this.open = false;
	}

	render()
	{
		return (
			<ClickAwayListener onClickAway={this.OnClose}>
				<Tooltip
					className="tooltipWrapper"
					title={this.props.title}
					placement="top"
					open={this.open}
					onClose={this.OnClose}
					leaveDelay={5000}
				>
					<span onClick={this.OnClick}>
						{this.props.children}
					</span>
				</Tooltip>
			</ClickAwayListener>
		);
	}
}

TooltipWrapper.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TooltipWrapper);