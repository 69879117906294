import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import AppState from '../AppState';
import Button from 'material-ui/Button';
import AccountRequestPage from './AccountRequestPage';
import BackIcon from 'material-ui-icons/KeyboardArrowLeft';
import Translate from '../Translate';
import { observable } from 'mobx';
import AdvCard from '../components/adventure/AdvCard';
import AdvAnnouncement from '../components/adventure/AdvAnnouncement';
import Utils from '../Utils';
import AdventureDetailsPage from './AdventureDetailsPage';
import AdventureInstancePage from './AdventureInstancePage';
import ReactIScroll from '../iscroll/react-iscroll';
import iScroll from 'iscroll/build/iscroll-probe.js';
import $ from 'jquery';
import AdventureMapPage from './AdventureMapPage';
import CompetitionPage from './CompetitionPage';
import AdvAdventure from '../components/adventure/AdvAdventure';

const styles = theme => ({
});

const iScrollOptions = {
	mouseWheel: true,
	scrollbars: false,
	scrollX: false,
	scrollY: true,
	freeScroll: false,
	fadeScrollbars: true,
	bounce: true,
	momentum: true,
	eventPassthrough: false,
	tap: true,
	click: true,
	interactiveScrollbars: false,
	probeType: 3,
};

const iScrollOptionsDesktop = {
	mouseWheel: true,
	scrollbars: true,
	scrollX: false,
	scrollY: true,
	freeScroll: false,
	fadeScrollbars: true,
	bounce: false,
	momentum: false,
	eventPassthrough: false,
	tap: true,
	click: true,
	interactiveScrollbars: true,
	probeType: 3,
};

@observer
class CardCollectionPage extends React.Component
{
	advManager;
	@observable showAdventureDetailsId;
	@observable showAdventureInstance;
	iScrollRight;
	@observable showAdventureMap = false;
	@observable showCompetition = false;
	@observable showAdvAnnouncement = false;
	lastScrollPos = 0;
	scrollingAtCards = true;
	scrollingAtTop = true;
	lastCardPositionUpdate;

	constructor(props)
	{
		super(props);

		this.advManager = AppState.instance.advManager;
		AppState.instance.cardCollectionPageInstance = this;
	}

	componentDidMount()
	{
		this.advManager.onConfigChanged.Add(this.OnConfigChanged);
		this.advManager.onInstancesChanged.Add(this.OnInstancesChanged);
		this.CheckScrollReady();

		this.CheckShowAnnouncement();
	}

	CheckShowAnnouncement()
	{
		this.showAdvAnnouncement = !AppState.instance.advManager.HasSeenAdvAnnouncement();
		if (this.showAdvAnnouncement)
		{
			if (AppState.instance.appInstance)
				AppState.instance.appInstance.forceUpdate();
		}
	}

	CheckScrollReady()
	{
		if (this.iScrollRight)
		{
			this.SetCardPositions(this.iScrollRight);
		}
		else
		{
			setTimeout(() => {
				this.CheckScrollReady();
			}, 300);
		}
	}

	componentWillUnmount()
	{
		this.advManager.onConfigChanged.Remove(this.OnConfigChanged);
		this.advManager.onInstancesChanged.Remove(this.OnInstancesChanged);
		AppState.instance.cardCollectionPageInstance = undefined;
	}

	OnActivate()
	{
		this.CheckShowAnnouncement();
		this.forceUpdate();
	}

	OnConfigChanged = () =>
	{
		this.forceUpdate();
		AppState.instance.appContainer.forceUpdate();
		if (AppState.instance.appInstance)
			AppState.instance.appInstance.forceUpdate();
	}

	OnInstancesChanged = () =>
	{
		this.forceUpdate();
		AppState.instance.appContainer.forceUpdate();
		if (AppState.instance.appInstance)
			AppState.instance.appInstance.forceUpdate();
	}

	OnAnimalCardClicked = (animalId) => (cardId, e) =>
	{
		// Show all cards of that animal

		const config = this.advManager.GetConfig();
		const animal = Utils.GetById(config.cardAnimals, animalId);
		if (!animal)
			return;

		var visibleCardIds = this.GetVisibleAnimalCardIds(animal);

		visibleCardIds = Utils.ReverseList(visibleCardIds);

		AppState.instance.cardDetailsPopup.ShowMultiple(visibleCardIds);
	}

	OnCardClicked = (cardId, e) =>
	{
		AppState.instance.cardDetailsPopup.Show(cardId);
	}

	renderCard(card, isFirst, isLast, showTitle, showPlus, showNumInstances)
	{
		return (
			<AdvCard key={card.id} cardId={card.id} isFirst={isFirst} isLast={isLast}
				showTitle={showTitle} showPlus={showPlus} showNumInstances={showNumInstances}
				onClick={this.OnCardClicked}
				imageSize="_s"
			/>
		);
	}

	GetVisibleAnimalCardIds(animal)
	{
		var maxIndex = animal.cardIds.length - 1;

		var visibleCardIds = [];
		for (var i = 0; i <= maxIndex; ++i)
		{
			const cardId = animal.cardIds[i];
			const instances = this.advManager.GetCardInstances(cardId);
			if (instances.length > 0)
				visibleCardIds.push(cardId);
		}

		// Show at least first card of animal
		if (visibleCardIds.length === 0 && animal.cardIds.length > 0)
		{
			visibleCardIds.push(animal.cardIds[0]);
		}

		return visibleCardIds;
	}

	renderAnimalCards(animal, index)
	{
		if (!animal.cardIds)
			return null;

		var visibleCardIds = this.GetVisibleAnimalCardIds(animal);

		return (
			<div className={"animalcards layer" + index} key={animal.id} id={"animalcards" + animal.id}>

				<div className="name">{animal.name}</div>

				<div className="cards">
					{visibleCardIds.map((cardId, index) =>
					{
						const position = visibleCardIds.length - 1 - index;
						return (
							<div key={cardId} className={"animalcard i" + index + " p" + position}>
								<AdvCard key={cardId} cardId={cardId} isFirst={false} isLast={false}
									showImage={true} showTitle={true} showPlus={false} showNumInstances={false}
									showWhenNew={position === 0}
									disableUnavailable={true}
									onClick={this.OnAnimalCardClicked(animal.id)}
									imageSize="_s"
								/>
							</div>
						);
					})}
				</div>

			</div>
		);
	}

	renderCardSet(cardSetId)
	{
		const cardSet = Utils.GetById(this.advManager.config.cardSets, cardSetId);
		if (!cardSet)
			return null;

		let title = Utils.GetLocaText(cardSet.title, AppState.instance.userProfile.userLang);

		return (
			<div className="cardSet" key={cardSetId}>
				<div className="title">{title}</div>

				<div className="cards">
					{cardSet.cardIds.map((cardId, index) =>
					{
						return (
							<AdvCard key={cardId} cardId={cardId} isFirst={index === 0} isLast={index === cardSet.cardIds.length - 1}
								showImage={true} showTitle={true} showPlus={false} showNumInstances={true}
								showWhenNew={true}
								onClick={this.OnCardClicked}
								imageSize="_s"
							/>
						);
					})}
				</div>
			</div>
		);
	}

	renderAnimalSets(animal, isFirst, isLast)
	{
		return (
			<div
				className={"animalset" + (isFirst ? " first":"") + (isLast ? " last":"")}
				key={animal.id} id={"animalset" + animal.id}
			>
				{animal.cardSetIds.map((cardSetId) =>
				{
					return this.renderCardSet(cardSetId);
				})}
			</div>
		);
	}

	SaveScrollPosition()
	{
		if (this.iScrollRight)
			this.lastScrollPos = this.iScrollRight.y;
		else
			this.lastScrollPos = undefined;

		//console.log("stored: " + this.lastScrollPos);
	}

	RestoreScrollPosition()
	{
		//console.log("restoring: " + this.lastScrollPos);

		if (this.lastScrollPos !== undefined &&
			this.iScrollRight)
		{
			//console.log("done");
			this.iScrollRight.scrollTo(0, this.lastScrollPos);
			this.lastScrollPos = undefined;
			setTimeout(() => {
				this.SetCardPositions(this.iScrollRight);
			}, 10);
		}
	}

	CloseDetailsPage()
	{
		this.showAdventureDetailsId = undefined;
		this.RestoreScrollPosition();
	}

	CloseInstancePage()
	{
		this.showAdventureInstance = undefined;
		this.RestoreScrollPosition();
	}

	ShowInstancePage(instance)
	{
		this.iScrollRight = undefined;
		this.showAdventureDetailsId = undefined;
		this.showAdventureInstance = instance;
	}

	OnBackClicked = () =>
	{
		if (this.showAdventureInstance)
		{
			this.CloseInstancePage();
		}
		else if (this.showAdventureDetailsId)
		{
			this.CloseDetailsPage();
		}
		else
		{
			if (AppState.instance.previousMainTab)
				AppState.instance.appInstance.OnTabbarChanged(undefined, AppState.instance.previousMainTab);
			else
				AppState.instance.appInstance.OnTabbarChanged(undefined, AppState.TabIndexSwipe);
		}
	}

	OnHintClicked = () =>
	{
		if (this.props.onHintClicked)
		{
			this.props.onHintClicked();
		}
		else
		{
			AppState.instance.screenMessage.ShowDialog(undefined, AdvAnnouncement, undefined, undefined, undefined, {
				onCta: this.OnAdvHintCta,
				onBackClicked: this.OnAdvHintCta,
				ctaLabel: Translate.T("de.Schließen", "en.Close", "cardcollectionpage.hint.close")
			}, false, undefined, true);
		}
	}

	OnMapClicked = () =>
	{
		this.iScrollRight = undefined;
		this.showAdventureMap = true;
	}

	OnCompetitionClicked = () =>
	{
		this.iScrollRight = undefined;
		this.showCompetition = true;
	}

	OnAdvHintCta = () =>
	{
		AppState.instance.advManager.OnAdvAnnouncementCta();
		AppState.instance.screenMessage.CloseDialog();
	}

	static OnBackClickedStatic = (e) =>
	{
		if (AppState.instance.cardCollectionPageInstance)
			AppState.instance.cardCollectionPageInstance.OnBackClicked(e);
	}

	static OnHintClickedStatic = (e) =>
	{
		if (AppState.instance.cardCollectionPageInstance)
			AppState.instance.cardCollectionPageInstance.OnHintClicked(e);
	}

	static OnMapClickedStatic = (e) =>
	{
		if (AppState.instance.cardCollectionPageInstance)
			AppState.instance.cardCollectionPageInstance.OnMapClicked(e);
	}

	static OnCompetitionClickedStatic = (e) =>
	{
		if (AppState.instance.cardCollectionPageInstance)
			AppState.instance.cardCollectionPageInstance.OnCompetitionClicked(e);
	}

	static RenderTopBar()
	{
		if (AppState.instance.isShowingDetailPage > 0)
			return null;

		if (AppState.instance.screenMessage.IsDialogOpen())
			return null;

		if (AppState.instance.cardCollectionPageInstance.showAdvAnnouncement && !AppState.instance.deviceInfo.desktop)
		{
			return (
				<AdvAnnouncement onCta={AppState.instance.cardCollectionPageInstance.OnAdvAnnouncementCta} onBackClicked={AppState.instance.cardCollectionPageInstance.OnAdvAnnouncementCta}/>
			);
		}
		// If the AdvAnnouncemnt is going to be shown, don't render this top bar
		// if (AppState.instance.bookmarkPageInstance && AppState.instance.bookmarkPageInstance.showAdvAnnouncement)
		// {
		// 	return null;
		// }

		//console.log("instance: " + AppState.instance.cardCollectionPageInstance);

		const config = AppState.instance.cardCollectionPageInstance ?
			AppState.instance.cardCollectionPageInstance.advManager.GetConfig() : undefined;

		const showLeftColumn = AppState.instance.cardCollectionPageInstance &&
			!AppState.instance.cardCollectionPageInstance.showAdventureInstance &&
			!AppState.instance.cardCollectionPageInstance.showAdventureDetailsId &&
			!AppState.instance.cardCollectionPageInstance.showAdventureMap &&
			!AppState.instance.cardCollectionPageInstance.showCompetition;

		const showRightColumn = showLeftColumn;

		const showTopButtons = !AppState.instance.cardDetailsPopup.IsShown();

		return (
			<div className="cardCollectionPage">
				<div className="cardCollectionTopBar">
					{showTopButtons && !AppState.instance.deviceInfo.desktop &&
						<Button className="back" onClick={CardCollectionPage.OnBackClickedStatic}>
							<BackIcon />
						</Button>}

					{showTopButtons && showLeftColumn &&
						<Button className={"title" + (AppState.instance.cardCollectionPageInstance.scrollingAtTop ? " atTop":"")}
							id="cardCollectionTopBarTitle"
							onClick={AppState.instance.cardCollectionPageInstance.OnTopBarTitleClicked}
						>
							{AppState.instance.cardCollectionPageInstance.scrollingAtCards && Translate.T("cardcollection.sectiontitle.cards")}
							{!AppState.instance.cardCollectionPageInstance.scrollingAtCards && Translate.T("cardcollection.sectiontitle.adventures")}
						</Button>}

					{showTopButtons && showLeftColumn &&
						<Button className="hint" onClick={CardCollectionPage.OnHintClickedStatic}>
							?
						</Button>}
				</div>
				<div className="cardCollectionBottomBar">
					{/*showTopButtons && !AppState.instance.deviceInfo.desktop && showLeftColumn &&
						<div className="competitionBtnContainer">
							<div className="ribbon"/>
							<Button className="competitionBtn" onClick={CardCollectionPage.OnCompetitionClickedStatic}>
								{Translate.T("de.Gewinnspiel", "en.Competition", "cardcollection.competition.cta")}
							</Button>
					</div>*/}
					{showTopButtons && !AppState.instance.deviceInfo.desktop && showLeftColumn &&
						<Button className="mapBtn" onClick={CardCollectionPage.OnMapClickedStatic}>
							<img alt="map" src={Utils.RenderMapUrl(24, 24, "white")} />
						</Button>}
				</div>
				<div className="content">
					{showLeftColumn && <div className="left">

						<div className="topBarSpacing"/>

						{config.cardAnimals && config.cardAnimals.map((animal, index) =>
						{
							return AppState.instance.cardCollectionPageInstance.renderAnimalCards(animal, index);
						})}
					</div>}

					{showRightColumn && AppState.instance.cardCollectionPageInstance.renderRightColumn()}
				</div>
			</div>
		);
	}

	OnTopBarTitleClicked = () =>
	{
		if (!this.iScrollRight)
			return;

		var firstAdv = $(".cardCollectionPage .right .adventure").first();
		if (!firstAdv)
			return;

		const offset = firstAdv.offset();
		if (offset.top <= 80)
		{
			// currently at the adventures -> scroll to top
			this.iScrollRight.scrollTo(0, 0, 500, iScroll.utils.ease.circular);
		}
		else
		{
			// -> scroll to adventures
			const pos = firstAdv.position();
			this.iScrollRight.scrollTo(0, - pos.top + 56, 500, iScroll.utils.ease.circular);
		}
	}

	OnAdventureCta = (advId) =>
	{
		var instanceOverview = this.advManager.GetAdvInstancesOverview(advId);

		var isStarted = instanceOverview.startedInstance !== undefined;
		var isFinished = instanceOverview.finishedInstance !== undefined;

		this.SaveScrollPosition();
		this.iScrollRight = undefined;

		if (isFinished || isStarted)
		{
			this.showAdventureInstance = instanceOverview.startedInstance;
		}
		else
		{
			this.showAdventureDetailsId = advId;
		}

		//AppState.instance.appContainer.forceUpdate();
		if (AppState.instance.appInstance)
			AppState.instance.appInstance.forceUpdate();
	}

	renderAdventure(adv, isFirst, isLast)
	{
		return (
			<AdvAdventure
				key={adv.id}
				adventureId={adv.id}
				onCta={this.OnAdventureCta}
				//onBackgroundClick={this.OnAdventureCta}
				className={(isFirst ? "first":"") + (isLast ? " last":"")}
			/>
		);
	}

	OnRightScrollRefresh = (iScrollInstance) =>
	{
		//console.log("A");
		this.iScrollRight = iScrollInstance;

		this.RestoreScrollPosition();
	}

	OnRightScroll = (iScrollInstance) =>
	{
		//console.log("B: " + iScrollInstance.y);

		//const now = new Date();
		//const maxDate = this.lastCardPositionUpdate ? Utils.DatePlusMilliseconds(this.lastCardPositionUpdate, 16) : now;
		//if (!this.lastCardPositionUpdate || (maxDate < now))
		//{
		//	this.lastCardPositionUpdate = now;
			this.SetCardPositions(iScrollInstance);
			this.SaveScrollPosition();
		//}
	}

	SetCardPositions(iScrollInstance)
	{
		if (!this.advManager.enabled)
			return;

		const config = this.advManager.GetConfig();
		if (!config.cardAnimals)
			return;
			
		var positions = this.CalcRelevantPositions();

		// for (var prop in positions)
		// {
		// 	positions[prop].top += iScrollInstance.y;
		// 	positions[prop].bottom += iScrollInstance.y;
		// }

		//console.log(positions);

		var topPos = 56;
		var maxPos;
		if (positions.adventures !== undefined)
		{
			maxPos = positions.adventures.top - 48;
		}

		for (var i = 0; i < config.cardAnimals.length; ++i)
		{
			const animalId = config.cardAnimals[i].id;
			const contentPos = positions["animal" + animalId];

			if (!contentPos)
				continue;

			// Animal tries to stay at top
			var newY = topPos;

			var cardHeight = contentPos.leftCardHeight;

			if (contentPos.bottom < (cardHeight + topPos))
			{
				newY = contentPos.bottom - cardHeight;
				//var delta = (cardHeight + topPos) - contentPos.bottom;

				//Fade out
			}
			else if (contentPos.top > topPos)
			{
				newY = contentPos.top;
			}

			if (maxPos !== undefined && (newY + cardHeight) > maxPos)
				newY = maxPos - cardHeight;

			$("#animalcards" + animalId).css('top', newY);
		}

		var titleChanged = false;
		if (positions.adventures)
		{
			if (positions.adventures.top <= 80)
			{
				if (this.scrollingAtCards)
				{
					this.scrollingAtCards = false;
					titleChanged = true;
				}
			}
			else
			{
				if (!this.scrollingAtCards)
				{
					this.scrollingAtCards = true;
					titleChanged = true;
				}
			}
		}

		if (iScrollInstance.y > -10)
		{
			if (!this.scrollingAtTop)
			{
				this.scrollingAtTop = true;
				titleChanged = true;
			}
		}
		else
		{
			if (this.scrollingAtTop)
			{
				this.scrollingAtTop = false;
				titleChanged = true;
			}
		}

		if (titleChanged)
		{
			var titleEl = $("#cardCollectionTopBarTitle");
			if (titleEl && titleEl.length > 0)
			{
				if (this.scrollingAtTop)
					titleEl.addClass("atTop");
				else
					titleEl.removeClass("atTop");

				var translation = Translate.FindTranslation("cardcollection.sectiontitle." + (this.scrollingAtCards ? "cards":"adventures"));
				if (translation !== undefined)
					titleEl.find(".translate").html(translation);
			}
		}
	}

	CalcRelevantPositions()
	{
		var result = {};

		const bottomOffset = 16;

		const siteYOffset = AppState.instance.deviceInfo.desktop ? 80 : 0;

		const config = this.advManager.GetConfig();
		if (config.cardAnimals)
		{
			for (var i = 0; i < config.cardAnimals.length; ++i)
			{
				const animalId = config.cardAnimals[i].id;
				var el = $("#animalset" + animalId);
				//var pos = el.position();
				var pos = el.offset();

				if (!pos)
					continue;

				var cardHeight = $("#animalcards" + animalId + " .animalcard.i0").height();

				result["animal" + animalId] = {
					top: pos.top - siteYOffset,
					bottom: pos.top + el.height() - bottomOffset - siteYOffset,
					leftCardHeight: $("#animalcards" + animalId).height() + cardHeight
				};
			}
		}

		var firstAdv = $(".cardCollectionPage .right .adventure").first();
		if (firstAdv)
		{
			const offset = firstAdv.offset();

			if (offset)
			{
				result.adventures = {
					//top: firstAdv.position().top,
					//bottom: firstAdv.position().top + 100, //TODO:
					top: offset.top - siteYOffset,
					bottom: offset.top - siteYOffset + 100, //TODO:
				};
			}
		}

		return result;
	}

	OnRightScrollEnd = (iScrollInstance) =>
	{
		this.SetCardPositions(iScrollInstance);
		this.SaveScrollPosition();
	}

	OnLoginButton = () =>
	{
		setTimeout(() => {
			AccountRequestPage.Show(undefined, undefined, "/abenteuer");
		}, 10);
	}

	OnRightSideClicked = (e) =>
	{
		// Check if user clicked on any of the ".animalcard" in ".left"
		var found = false;
		var links = $(".left .advcard");
		links.each(function ()
		{
			if (!found)
			{
				const linkRect = this.getBoundingClientRect();
				const dist = Utils.GetDistanceFromRect(e.clientX, e.clientY, linkRect);
				if (dist <= 1)
				{
					found = true;
					this.click();
				}
			}
		});	
	}

	renderRightColumn()
	{
		// var s = {};
		// if (AppState.instance.currentMainTab !== AppState.TabIndexAdventure)
		// 	s = {'display': 'none'};

		const config = this.advManager.GetConfig();
		//const instances = this.advManager.GetInstances();

		setTimeout(() => {
			if (this.iScrollRight)
				this.iScrollRight.refresh();

			this.CheckScrollReady();
		}, 200);

		return (
			<div className="right" onClick={this.OnRightSideClicked}>
				<ReactIScroll
					iScroll={iScroll}
					options={AppState.instance.deviceInfo.desktop ? iScrollOptionsDesktop : iScrollOptions}
					onScroll={this.OnRightScroll}
					onScrollEnd={this.OnRightScrollEnd}
					onRefresh={this.OnRightScrollRefresh}
					className="rightScroller"
				>
					<div>
						<div className="topBarSpacing"/>

						{!AppState.instance.IsLoggedIn() &&
							<div className="advLoginHint">
								<div className="advLoginHintText">
									{Translate.T("en.Create account to start collecting adventure cards.", "de.Sammle Deine ersten Abenteuerkarten mit Deinem persönlichen Account.", "bookmarks.loginrequest")}
								</div>
								<Button onClick={this.OnLoginButton}>
									{Translate.T("en.Create Account", "de.Jetzt registrieren", "createaccount")}
								</Button>
							</div>}

						{config.cardAnimals && config.cardAnimals.map((animal, index) =>
						{
							return this.renderAnimalSets(animal, index === 0, index === config.cardAnimals.length - 1);
						})}

						<div className="adventureTitle">
							{Translate.T("de.Deine Abenteuer", "en.Your adventures", "cardcollectionpage.adventures.title")}
						</div>

						{config.adventures && config.adventures.map((adv, index) =>
						{
							if (adv.id === "haubiversum_sol")
								return null;
							return this.renderAdventure(adv, index === 0, index === config.adventures.length - 1);
						})}

						<div className="bottomspace" />
					</div>
				</ReactIScroll>
			</div>
		);
	}

	OnMapPageBack = () =>
	{
		this.showAdventureMap = false;
	}

	OnCompetitionPageBack = () =>
	{
		this.showCompetition = false;
	}

	OnAdvAnnouncementCta = () =>
	{
		AppState.instance.advManager.OnAdvAnnouncementCta();

		this.showAdvAnnouncement = false;
	}

	render()
	{
		if (this.showAdventureMap)
		{
			return (
				<AdventureMapPage
					onBackClick={this.OnMapPageBack}
				/>
			);
		}

		if (this.showCompetition)
		{
			return (
				<CompetitionPage
					onBackClick={this.OnCompetitionPageBack}
				/>
			);
		}

		if (this.showAdventureInstance)
		{
			return (
				<AdventureInstancePage instanceId={this.showAdventureInstance.id} />
			);
		}

		if (this.showAdventureDetailsId)
		{
			return (
				<AdventureDetailsPage adventureId={this.showAdventureDetailsId} />
			);
		}

		if (AppState.instance.deviceInfo.desktop)
		{
			return CardCollectionPage.RenderTopBar();
		}
		else
		{
			return null;
		}

		// var s = {};
		// if (AppState.instance.currentMainTab !== AppState.TabIndexAdventure)
		// 	s = {'display': 'none'};

		// const config = this.advManager.GetConfig();
		// //const instances = this.advManager.GetInstances();

		// return (
		// 	<div id="cardCollectionPage" className="cardCollectionPage" style={s}>
		// 	</div>
		// );
	}
}

CardCollectionPage.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(CardCollectionPage);