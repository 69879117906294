import React from 'react';
import Translate from '../../Translate';
import StepBase from './StepBase';
import AppState from '../../AppState';
import Utils from '../../Utils';
import RestApi from '../../RestApi';
import AddAPhoto from 'material-ui-icons/AddAPhoto';
import VideoCall from 'material-ui-icons/VideoCall';

export default class StepUploadFiles extends StepBase
{
	anotherAnswerOptions = [];

	constructor(props)
	{
		super(props);

		this.AddAnswerOption({
			label: Translate.T("de.Nein", "en.No", "photoanswer.no"),
			onClick: this.OnDone,
			id: "no"
		});

		this.AddAnswerOption({
			icon: <AddAPhoto />,
			label: Translate.T("de.Foto hinzufügen", "en.Add Photo", "photoanswer.addphoto"),
			onClick: this.OnAddPhoto,
			id: "photo"
		});

		this.AddAnswerOption({
			icon: <VideoCall />,
			label: Translate.T("de.Video hinzufügen", "en.Add Video", "photoanswer.addvideo"),
			onClick: this.OnAddVideo,
			id: "video"
		});

		this.anotherAnswerOptions.push({
			label: Translate.T("de.Weiter", "en.Continue", "photoanswer.continue"),
			onClick: this.OnDone,
			id: "done"
		});

		this.anotherAnswerOptions.push({
			icon: <AddAPhoto />,
			label: Translate.T("de.Weiteres Foto hinzufügen", "en.Add Another Photo", "photoanswer.addAnotherPhoto"),
			onClick: this.OnAddPhoto,
			id: "photo"
		});

		this.anotherAnswerOptions.push({
			icon: <VideoCall />,
			label: Translate.T("de.Weiteres Video hinzufügen", "en.Add Another Video", "photoanswer.addAnotherVideo"),
			onClick: this.OnAddVideo,
			id: "video"
		});
	}

	GetId()
	{
		return "files";
	}

	GeneratedDataIds()
	{
		return ["files"];
	}

	Undo(contextData)
	{
		var files = contextData["files_temp"];
		if (files)
		{
			for (var i = 0; i < files.length; ++i)
			{
				RestApi.SendRequestText("/usercontent/" + files[i].id, undefined, undefined, undefined, "DELETE").then((voidResponse) =>
				{
				});
			}
		}

		files = contextData["files"];
		if (files)
		{
			for (i = 0; i < files.length; ++i)
			{
				RestApi.SendRequestText("/usercontenturl/" + encodeURIComponent(files[i]), undefined, undefined, undefined, "DELETE").then((voidResponse) =>
				{
				});
			}
		}

		delete contextData["files"];
		delete contextData["files_temp"];
		delete contextData.uploadFile;
		delete contextData.uploadId;
		delete contextData.isImage;
	}

	OnDone = (e, contextData) =>
	{
		const files = contextData["files_temp"];

		var fileUrls = [];
		if (files)
		{
			for (var i = 0; i < files.length; ++i)
			{
				for (var j = 0; j < files[i].pictures.length; ++j)
					fileUrls.push(files[i].pictures[j]);
			}
		}

		delete contextData["files_temp"];
		delete contextData.progressItems;
		delete contextData.uploadFile;
		delete contextData.uploadId;
		delete contextData.isImage;

		var currentFiles = contextData["files"];
		if (currentFiles)
		{
			for (i = currentFiles.length - 1; i >= 0; --i)
				fileUrls.unshift(currentFiles[i]);
		}

		this.OnStepDone(contextData, {
			files: fileUrls
		});
	}

	OnAddPhoto = (e, contextData) =>
	{
		setTimeout(() => {
			AppState.instance.appContainer.PromptImage(this.UploadImage(contextData));
		}, 50);
	}

	OnAddVideo = (e, contextData) =>
	{
		setTimeout(() => {
			AppState.instance.appContainer.PromptVideo(this.UploadVideo(contextData));
		}, 50);
	}

	GetProgressItem(id, contextData)
	{
		if (!contextData.progressItems)
			return undefined;

		for (var i = 0; i < contextData.progressItems.length; ++i)
		{
			if (contextData.progressItems[i].id === id)
			{
				return contextData.progressItems[i];
			}
		}
		return undefined;
	}

	RemoveProgressItem(id, contextData)
	{
		if (!contextData.progressItems)
			return;

		for (var i = 0; i < contextData.progressItems.length; ++i)
		{
			if (contextData.progressItems[i].id === id)
			{
				contextData.progressItems.splice(i, 1);
				break;
			}
		}
	}

	UploadImage = (contextData) => (file) =>
	{
		this.DoUploadImage(contextData, file);
	}

	UploadVideo = (contextData) => (file) =>
	{
		this.DoUploadVideo(contextData, file);
	}

	OnUserContentCreated = (contextData) => (uc) =>
	{
		console.log("upload finished:");
		console.log(uc);

		const id = contextData.uploadId;

		var files = contextData["files_temp"];
		if (!files)
			files = [];

		files.push(uc);
		contextData["files_temp"] = files;

		// Save the uploaded file
		var pis = contextData.progressItems;
		contextData.progressItems = undefined;
		try
		{
			AppState.instance.chatBotManager.SaveState(contextData.bot, contextData.bot.props.id);
		}
		finally
		{
			contextData.progressItems = pis;
		}

		// if (this.mediaPlayer)
		// {
		// 	// Scroll MediaPlayer to right to show new image
		// 	this.mediaPlayer.GoToEnd();
		// }

		var item = this.GetProgressItem(id, contextData);
		if (item)
		{
			item.label = Translate.T("en.Done", "de.Fertig", "photo.upload.done");
			contextData.progressItemsCounter++;
			contextData.onChanged();
			setTimeout(() => {
				item.show = false; // This will fade out the progress item	
				contextData.progressItemsCounter++;
				contextData.onChanged();
			}, 500);				
		}

		if (this.IsStepDone(contextData))
			this.OnDone(undefined, contextData);

		delete contextData.uploadId;
		contextData.onChanged();
	}

	OnUploadProgress = (contextData) => (event) =>
	{
		const id = contextData.uploadId;

		//console.log("progress: " + id);
		//console.log(contextData);

		var item = this.GetProgressItem(id, contextData);
		if (item)
		{
			const progress = event.loaded / event.total * 100;
			item.progress = progress;
			console.log("update");
			contextData.progressItemsCounter++;
			contextData.onChanged();
		}
	}

	OnUploadError = (contextData) => (error) =>
	{
		const id = contextData.uploadId;

		console.log("Upload error:");
		console.log(error);

		var item = this.GetProgressItem(id, contextData);
		if (item)
		{
			//item.label = Translate.T("en.Error while uploading", "de.Fehler beim Upload", "photo.upload.error");
			if (error.message)
				item.label = "Error: " + error.message;
			else if (typeof error === "string")
				item.label = error;
			else
				item.label = JSON.stringify(error);
			item.error = true;
			contextData.progressItemsCounter++;
			contextData.onChanged();
		}
	}

	OnLocalImageData = (contextData) => (dataUrl) =>
	{
		if (contextData.uploadId === undefined)
			contextData.uploadId = Utils.GenerateRandomStr(32);

		if (contextData.progressItems === undefined)
			contextData.progressItems = [];

		contextData.progressItems.push(
		{
			id: contextData.uploadId,
			progress: 0,
			show: true,
			dataUrl: dataUrl,
			label: Translate.T("en.Uploading file", "de.Datei Upload", "file.upload.progress"),
			onDone: () =>
			{
				this.RemoveProgressItem(contextData.uploadId, contextData);
				delete contextData.uploadId;
				contextData.progressItemsCounter++;
				contextData.onChanged();
			},
			onRetry: () =>
			{
				setTimeout(() => {
					var item = this.GetProgressItem(contextData.uploadId, contextData);
					if (item)
					{
						item.show = false;
						contextData.progressItemsCounter++;
						contextData.onChanged();
					}
					delete contextData.uploadId;
					if (contextData.isImage)
						this.DoUploadImage(contextData, contextData.uploadFile);	
					else
						this.DoUploadVideo(contextData, contextData.uploadFile);	
				}, 200);
			},
			onCancel: () =>
			{
				setTimeout(() => {
					var item = this.GetProgressItem(contextData.uploadId, contextData);
					if (item)
					{
						setTimeout(() => {
							item.show = false;
							delete contextData.uploadId;
							contextData.progressItemsCounter++;
							contextData.onChanged();
						}, 100);
					}	
				}, 200);
			}
		});
		contextData.onChanged();
	}

	DoUploadImage(contextData, file)
	{
		if (!contextData.progressItemsCounter)
			contextData.progressItemsCounter = 1;

		contextData.uploadFile = file;
		contextData.isImage = true;

		Utils.UploadActivityImage(file, undefined,
			this.OnUserContentCreated(contextData),
			this.OnUploadProgress(contextData),
			this.OnUploadError(contextData),
			this.OnLocalImageData(contextData),
			false, false);
	}

	DoUploadVideo(contextData, file)
	{
		if (!contextData.progressItemsCounter)
			contextData.progressItemsCounter = 1;

		contextData.uploadFile = file;
		contextData.isImage = false;

		Utils.UploadActivityVideo(file, undefined,
			this.OnUserContentCreated(contextData),
			this.OnUploadProgress(contextData),
			this.OnUploadError(contextData),
			this.OnLocalImageData(contextData),
			false, false, 60, 50 * 1024 * 1024);
	}

	renderStepLena(contextData)
	{
		if (contextData.goal === "media")
			return this.renderLenaMsg(Translate.T("de.Welche Fotos oder Videos möchtest Du hinzufügen?",
				"en.Which photos or videos would you like to add?", "lena.media.whichphotos"));

		return this.renderLenaMsg(Translate.T("de.Möchtest Du Fotos oder Videos hinzufügen?",
			"en.Would you like to add some photos or videos?", "createxp.addphoto"));

		//return this.renderLenaMsg(Translate.T("de.Möchtest Du Fotos oder Videos hinzufügen?",
		//	"en.Would you like to add some photos or videos?", "createxp.addphotovideo"));
	}

	OnAddFile(contextData)
	{
		if (this.IsStepDone(contextData))
			this.OnStartEdit(contextData);

		AppState.instance.appContainer.PromptImage(this.UploadImage(contextData));
	}

	OnRemoveFile(contextData, url)
	{
		if (this.IsStepDone(contextData))
		{
			this.StartEdit(contextData);

			var files = contextData["files"];
			var newFiles = [];
			for (var i = 0; i < files.length; ++i)
			{
				if (files[i] === url)
				{
					RestApi.SendRequestText("/usercontenturl/" + encodeURIComponent(url), undefined, undefined, undefined, "DELETE").then((voidResponse) => {});
				}
				else
				{
					newFiles.push(files[i]);
				}
			}

			this.OnStepDone(contextData, {
				files: newFiles
			});
		}
		else
		{
			files = contextData["files_temp"];
			if (files)
			{
				for (i = 0; i < files.length; ++i)
				{
					if (files[i].url === url)
					{
						RestApi.SendRequestText("/usercontent/" + files[i].id, undefined, undefined, undefined, "DELETE").then((voidResponse) => {});
						files.splice(i, 1);
						break;
					}
				}
				if (files.length === 0)
					files = undefined;
			}
			contextData["files_temp"] = files;

			AppState.instance.chatBotManager.SaveState(contextData.bot, contextData.bot.props.id);
			contextData.onChanged();
		}
	}

	renderUserSelection(contextData)
	{
		const files = contextData["files"];
		
		if (!files || files.length === 0)
		{
			return this.renderUserMsg(this.GetAnswerOptionLabel("no"), contextData);
		}

		return this.renderFiles(contextData, files);
	}

	renderUserOptions(contextData)
	{
		const files = contextData["files_temp"];

		var fileUrls = [];
		if (files)
		{
			for (var i = 0; i < files.length; ++i)
			{
				for (var j = 0; j < files[i].pictures.length; ++j)
					fileUrls.push(files[i].pictures[j]);
			}
		}

		return (
			<div className="stepFiles">

				{(!fileUrls || fileUrls.length === 0) && this.renderAnswerOptions(contextData, this.answerOptions)}

				{fileUrls && fileUrls.length > 0 && this.renderFiles(contextData, fileUrls)}
				{(!fileUrls || fileUrls.length === 0) && this.renderFilesProgress(contextData)}

				{fileUrls && fileUrls.length > 0 && this.renderAnswerOptions(contextData, this.anotherAnswerOptions)}

			</div>
		);
	}
}
