import React from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import $ from 'jquery';
import Utils from './Utils';
import Tracking from './Tracking';
import AppState from './AppState';
import Dialog from 'material-ui/Dialog';
import List, { ListItem, ListItemText } from 'material-ui/List';
import Moment from 'moment';
import RadioButtonChecked from 'material-ui-icons/RadioButtonChecked';
import RadioButtonUnchecked from 'material-ui-icons/RadioButtonUnchecked';

@observer
export default class Translate extends React.Component
{
	static remainingLanguages = 0;
	static languages = {};
	@observable static showLanguageSelection = false;
	static showLanguageSelectionCallback;
	static staticTranslations = {};

	static Init()
	{
		Translate.SetUserLang();

		Translate.AddTranslation("de", "language.de", "Deutsch");
		Translate.AddTranslation("en", "language.de", "German");

		Translate.AddTranslation("de", "language.en", "Englisch");
		Translate.AddTranslation("en", "language.en", "English");

		Translate.AddTranslation("en", "search.nearby", "Near you");
		Translate.AddTranslation("de", "search.nearby", "In der Nähe");

		Translate.AddTranslation("en", "tomorrow", "Tomorrow");
		Translate.AddTranslation("de", "tomorrow", "Morgen");

		Translate.AddTranslation("en", "close", "Close");
		Translate.AddTranslation("de", "close", "Schließen");

		Translate.AddTranslation("en", "search.default", "Where • What • Who");
		Translate.AddTranslation("de", "search.default", "Wo • Was • Wer");

		Translate.AddTranslation("en", "search.activities", "Search activities");
		Translate.AddTranslation("de", "search.activities", "Suche Ausflugsziele");

		Translate.AddTranslation("en", "search.people", "Search People • Name • Email");
		Translate.AddTranslation("de", "search.people", "Suche Personen • Name • Email");

		Translate.AddTranslation("en", "search.default.location", "City • District • State");
		Translate.AddTranslation("de", "search.default.location", "Stadt • Bezirk • Bundesland");

		//Translate.AddTranslation("en", "search.default", "Search for getaways and locations");
		//Translate.AddTranslation("de", "search.default", "Suche nach Ausflügen und Orten");

		Translate.AddTranslation("en", "search.everywhere", "Everywhere");
		Translate.AddTranslation("de", "search.everywhere", "Überall");

		/*Translate.AddTranslation("en", "search.default", "Outing, Place, State,...");
		Translate.AddTranslation("de", "search.default", "Ausflugsziel, Ort, Bezirk,...");*/

		Translate.AddTranslation("en", "share.email.title", "Trip to '{0}'");
		Translate.AddTranslation("de", "share.email.title", "Ausflug zu '{0}'");

		Translate.AddTranslation("en", "upload.video.error.duration", "Error: max video length: {0} sec");
		Translate.AddTranslation("de", "upload.video.error.duration", "Fehler: max. Videolänge: {0} Sek.");

		Translate.AddTranslation("en", "upload.video.error.size", "Error: max video size: {0} MB");
		Translate.AddTranslation("de", "upload.video.error.size", "Fehler: max. Videogröße: {0} MB");

		Translate.AddTranslation("en", "share.email.body", "Would you like to join me on a trip to {0} in {1}?\n Please let know what you think!\n\n{2}");
		Translate.AddTranslation("de", "share.email.body", "Hast du Lust auf einen Ausflug zu {0} in {1}?\n Lass mich bitte wissen, was du darüber denkst!\n\n{2}");

		Translate.AddTranslation("en", "mainmenu.chat", "Lena");
		Translate.AddTranslation("de", "mainmenu.chat", "Lena");

		Translate.AddTranslation("en", "mainmenu.explore", "Explore");
		Translate.AddTranslation("de", "mainmenu.explore", "Suche");

		Translate.AddTranslation("en", "mainmenu.saved", "Saved");
		Translate.AddTranslation("de", "mainmenu.saved", "Gespeichert");

		Translate.AddTranslation("en", "mainmenu.profile", "Profile");
		Translate.AddTranslation("de", "mainmenu.profile", "Profil");

		Translate.AddTranslation("en", "attribution.picture", "Image: ");
		Translate.AddTranslation("de", "attribution.picture", "Bild: ");

		Translate.AddTranslation("en", "createxp.howWasIt.placeholder", "Describe your experience");
		Translate.AddTranslation("de", "createxp.howWasIt.placeholder", "Beschreibe Deine Erfahrung");

		Translate.AddTranslation("en", "lena.feedback.placeholder", "What do you like and what should I improve?");
		Translate.AddTranslation("de", "lena.feedback.placeholder", "Was gefällt Dir und was sollte ich verbessern?");

		Translate.AddTranslation("en", "lena.question.placeholder", "Deine Frage");
		Translate.AddTranslation("de", "lena.question.placeholder", "Your question");

		Translate.AddTranslation("en", "filter.icon.title.accessible", "Accessible");
		Translate.AddTranslation("de", "filter.icon.title.accessible", "Barrierefrei");
		Translate.AddTranslation("en", "filter.icon.title.strollersuitable", "Baby carriages suitable");
		Translate.AddTranslation("de", "filter.icon.title.strollersuitable", "Kinderwagen geeignet");
		Translate.AddTranslation("en", "filter.icon.title.dogswelcome", "Dogs Welcome");
		Translate.AddTranslation("de", "filter.icon.title.dogswelcome", "Hunde willkommen");
		Translate.AddTranslation("en", "filter.icon.title.bookingnotrequired", "Booking\nnot required");
		Translate.AddTranslation("de", "filter.icon.title.bookingnotrequired", "Buchung\nnicht notwendig");
		Translate.AddTranslation("en", "filter.icon.title.openrightnow", "Open right now");
		Translate.AddTranslation("de", "filter.icon.title.openrightnow", "Jetzt geöffnet");
		Translate.AddTranslation("en", "filter.icon.title.allweather", "All weather");
		Translate.AddTranslation("de", "filter.icon.title.allweather", "Bei jedem Wetter");

		Translate.AddTranslation("en", "filter.location.default", "City, Region, State,...");
		Translate.AddTranslation("de", "filter.location.default", "Stadt, Bezirk, Bundesland,...");

		Translate.AddTranslation("en", "category.search", "Search");
		Translate.AddTranslation("de", "category.search", "Suche");

		Translate.AddTranslation("en", "location.current", "Current location");
		Translate.AddTranslation("de", "location.current", "Aktuelle Position");

		Translate.AddTranslation("de", "accountrequest.bookmark", "Speichere die Liste Deiner Lieblingsausflüge.");
		Translate.AddTranslation("en", "accountrequest.bookmark", "Save your list of favorite trips.");
		Translate.AddTranslation("de", "accountrequest.addreview", "Teile anderen Deine Meinung mit.");
		Translate.AddTranslation("en", "accountrequest.addreview", "Let others know what you think.");
		Translate.AddTranslation("de", "accountrequest.swipebookmarks", "Swipe durch Deine gespeicherten Lesezeichen.");
		Translate.AddTranslation("en", "accountrequest.swipebookmarks", "Swipe through your saved trips.");
		Translate.AddTranslation("de", "accountrequest.matchindicator", "Speichere Deine persönlichen Interessen und Bedürfnisse um bessere Vorschläge zu bekommen.");
		Translate.AddTranslation("en", "accountrequest.matchindicator", "Save your personal interests and needs to get better suggestions.");
		Translate.AddTranslation("de", "accountrequest.addphoto", "Bereichere Aktivitäten mit eigenen Photos und Videos.");
		Translate.AddTranslation("en", "accountrequest.addphoto", "Enrich activities with your own photos and videos.");
		Translate.AddTranslation("de", "accountrequest.suggestedit", "Nimm an unserer Community teil.");
		Translate.AddTranslation("en", "accountrequest.suggestedit", "Be part of our community.");

		Translate.AddTranslation("de", "inviteemail.subject", "Freundschaftsanfrage - LENA");
		Translate.AddTranslation("en", "inviteemail.subject", "Friend invite - LENA");

		Translate.AddTranslation("de", "inviteemail.body", "Hallo{2},\r\n\r\nIch möchte Dich gerne als Freund in der Familien-App LENA hinzufügen.\r\n" +
			"Bitte klicke dazu einfach auf folgenden Link und registriere Dir einen Account:\r\n{0}\r\n\r\nLiebe Grüße, {1}");
		Translate.AddTranslation("en", "inviteemail.body", "Hi{2},\r\n\r\nI'ld like to add you in the family app LENA to my list of friends.\r\n" +
			"Please just click the following link and register for an account:\r\n{0}\r\n\r\nThanks and cheers, {1}");
		
		Translate.AddTranslation("de", "filter.bookmarks", "Lesezeichen");
		Translate.AddTranslation("en", "filter.bookmarks", "Bookmarks");

		Translate.AddTranslation("de", "filter.checkin", "Besuchte Ausflugsziele");
		Translate.AddTranslation("en", "filter.checkin", "Visited Places");

		Translate.AddTranslation("de", "filter.family", "Familie");
		Translate.AddTranslation("en", "filter.family", "Family");

		Translate.AddTranslation("de", "filter.friends", "Freunde");
		Translate.AddTranslation("en", "filter.friends", "Friends");

		Translate.AddTranslation("de", "filter.followers", "Followers");
		Translate.AddTranslation("en", "filter.followers", "Followers");

		Translate.AddTranslation("de", "filter.following", "Ich folge");
		Translate.AddTranslation("en", "filter.following", "I am following");

		Translate.AddTranslation("de", "filter.transferred", "Neue Lesezeichen");
		Translate.AddTranslation("en", "filter.transferred", "New Bookmarks");

		Translate.AddTranslation("de", "filter.transferred.desc", "");
		Translate.AddTranslation("en", "filter.transferred.desc", "");

		Translate.AddTranslation("de", "filter.sessionbookmarks", "Swipe Liste");
		Translate.AddTranslation("en", "filter.sessionbookmarks", "Swipe List");

		Translate.AddTranslation("de", "social.comment.placeholder", "Schreibe einen Kommentar...");
		Translate.AddTranslation("en", "social.comment.placeholder", "Write a comment...");

		Translate.AddTranslation("de", "social.comment.placeholder.notloggedin", "Einloggen um zu kommentieren...");
		Translate.AddTranslation("en", "social.comment.placeholder.notloggedin", "Log in to comment...");

		Translate.AddTranslation("de", "question.kidsage", "Wie alt sind Deine Kinder?\nWähle mehrere Altersgruppen aus, wenn Du mehrere Kinder hast.");
		Translate.AddTranslation("en", "question.kidsage", "How old are your kids?\nSelect multiple age groups if needed.");
		
		Translate.AddTranslation("de", "question.raining", "Bist Du bei Regen trotzdem draußen unterwegs?");
		Translate.AddTranslation("en", "question.raining", "Are you going to make outdoor trips even in the rain?");

		Translate.AddTranslation("de", "answer.yes", "Ja");
		Translate.AddTranslation("en", "answer.yes", "Yes");

		Translate.AddTranslation("de", "answer.no", "Nein");
		Translate.AddTranslation("en", "answer.no", "No");

		Translate.AddTranslation("de", "answer.ifnecessary", "Sehr ungern");
		Translate.AddTranslation("en", "answer.ifnecessary", "Rather not");

		Translate.AddTranslation("de", "answer.sometimes", "Manchmal");
		Translate.AddTranslation("en", "answer.sometimes", "Sometimes");

		Translate.AddTranslation("de", "answer.somewhat", "Ein wenig");
		Translate.AddTranslation("en", "answer.somewhat", "Somewhat");

		Translate.AddTranslation("de", "question.dog", "Begleitet Dich ein Hund bei Euren Ausflügen?");
		Translate.AddTranslation("en", "question.dog", "Do you take a dog with you on your trips?");

		Translate.AddTranslation("de", "question.accessible", "Benötigt Ihr bei Euren Ausflügen einen Rollstuhl?");
		Translate.AddTranslation("en", "question.accessible", "Do you need a wheel chair on your trips?");

		Translate.AddTranslation("de", "question.stroller", "Ist eines Eurer Kinder bei den Ausflügen im Kinderwagen?");
		Translate.AddTranslation("en", "question.stroller", "Is one of your kids in a stroller during your trips?");

		Translate.AddTranslation("de", "question.rating", "Sind ausgezeichnete Kunden-Bewertungen entscheidend für Deine Auswahl?");
		Translate.AddTranslation("en", "question.rating", "Are great user ratings essential for your selection?");

		Translate.AddTranslation("de", "question.overhead", "Bist Du bereit für einen Ausflug aufwendigere Vorbereitungen in Kauf zu nehmen (z.B. Reservierung tätigen)?");
		Translate.AddTranslation("en", "question.overhead", "Are you willing to do some preperations (like upfront-booking) for your trips?");

		Translate.AddTranslation("de", "question.budget", "Bist Du bereit für einen tollen Ausflug auch mal mehr Geld auszugeben?");
		Translate.AddTranslation("en", "question.budget", "Are you willing to spend quite some money for a great trip?");

		Translate.AddTranslation("de", "question.physically_active", "Würdet Ihr Euch als körperlich aktive Familie bezeichnen?");
		Translate.AddTranslation("en", "question.physically_active", "Would you consider yourselves a physically active family?");

		Translate.AddTranslation("de", "question.outdoorsy", "Ist es für Euch wichtig Eure Freizeit im Freien zu verbringen?");
		Translate.AddTranslation("en", "question.outdoorsy", "Is it important for you to spend your family leisure time outdoors?");

		Translate.AddTranslation("de", "question.animals", "Seid Ihr gerne im Kontakt mit Tieren?");
		Translate.AddTranslation("en", "question.animals", "Do you like spending time with animals during your trips?");

		Translate.AddTranslation("de", "question.culture", "Seid Ihr an Kunst und Kultur interessiert?");
		Translate.AddTranslation("en", "question.culture", "Are you interested in art and culture?");

		Translate.AddTranslation("en", "swipe_listdone_header", "You reached the end of this list");
		Translate.AddTranslation("de", "swipe_listdone_header", "Du hast das Ende Deiner Liste erreicht");
		Translate.AddTranslation("en", "swipe_listdone_body", "Keep on swiping to restart");
		Translate.AddTranslation("de", "swipe_listdone_body", "Swipe einfach weiter um neu zu starten");
		Translate.AddTranslation("en", "button.swipe_listdone_button", "Restart");
		Translate.AddTranslation("de", "button.swipe_listdone_button", "Neu starten");

		Translate.AddTranslation("en", "button.swipe_questiondone_button", "Show new suggestions");
		Translate.AddTranslation("de", "button.swipe_questiondone_button", "Neue Vorschläge ansehen");

		Translate.AddTranslation("en", "swipe_allquestionsanswered_header", "All questions answered");
		Translate.AddTranslation("de", "swipe_allquestionsanswered_header", "Alle Fragen beantwortet");
		Translate.AddTranslation("en", "swipe_allquestionsanswered_body", "Your profile is complete. From now on your suggestions will be a better match.");
		Translate.AddTranslation("de", "swipe_allquestionsanswered_body", "Dein Profil ist nun komplett. Deine Vorschläge sind nun besser auf Dich abgestimmt.");

		Translate.AddTranslation("en", "swipe_listempty_header", "This list is empty");
		Translate.AddTranslation("de", "swipe_listempty_header", "Diese Liste ist leer");
		Translate.AddTranslation("en", "swipe_listempty_body", "Add some entries to restart swiping");
		Translate.AddTranslation("de", "swipe_listempty_body", "Füge Einträge hinzu um wieder zu swipen");

		Translate.AddTranslation("de", "privacy.sharewith.0", "Nur ich");
		Translate.AddTranslation("en", "privacy.sharewith.0", "Only myself");
		Translate.AddTranslation("de", "privacy.sharewith.1", "Familie");
		Translate.AddTranslation("en", "privacy.sharewith.1", "Family");
		Translate.AddTranslation("de", "privacy.sharewith.2", "Freunde");
		Translate.AddTranslation("en", "privacy.sharewith.2", "Friends");
		Translate.AddTranslation("de", "privacy.sharewith.3", "Followers");
		Translate.AddTranslation("en", "privacy.sharewith.3", "Followers");
		Translate.AddTranslation("de", "privacy.sharewith.4", "LENA Community");
		Translate.AddTranslation("en", "privacy.sharewith.4", "LENA Community");

		Translate.AddTranslation("de", "activity.website", "Webseite");
		Translate.AddTranslation("en", "activity.website", "Website");

		Translate.AddTranslation("de", "activity.email", "Email");
		Translate.AddTranslation("en", "activity.email", "Email");

		Translate.AddTranslation("de", "activity.phone", "Telefon");
		Translate.AddTranslation("en", "activity.phone", "Phone");

		Translate.AddTranslation("de", "activity.address", "Adresse");
		Translate.AddTranslation("en", "activity.address", "Address");

		Translate.AddTranslation("de", "ageFitMin", "Geeignetes Mindestalter");
		Translate.AddTranslation("en", "ageFitMin", "Age Fit - Minimum Age");

		Translate.AddTranslation("de", "ageFitMax", "Geeignetes Maximalalter");
		Translate.AddTranslation("en", "ageFitMax", "Age Fit - Maximum Age");

		Translate.AddTranslation("de", "durationMin", "Minimale Besuchsdauer");
		Translate.AddTranslation("en", "durationMin", "Visit Duration - Minimum");

		Translate.AddTranslation("de", "durationMax", "Maximale Besuchsdauer");
		Translate.AddTranslation("en", "durationMax", "Visit Duration - Maximum");

		Translate.AddTranslation("de", "instructionsPullToRefresh", "Ziehen für Aktualisierung");
		Translate.AddTranslation("en", "instructionsPullToRefresh", "Pull down to refresh");

		Translate.AddTranslation("de", "instructionsReleaseToRefresh", "Loslassen für Aktualisierung");
		Translate.AddTranslation("en", "instructionsReleaseToRefresh", "Release to refresh");

		Translate.AddTranslation("de", "instructionsRefreshing", "Aktualisieren");
		Translate.AddTranslation("en", "instructionsRefreshing", "Refreshing");

		Translate.AddTranslation("de", "cardcollection.sectiontitle.cards", "Deine Abenteuerkarten");
		Translate.AddTranslation("en", "cardcollection.sectiontitle.cards", "Your Adventure Cards");

		Translate.AddTranslation("de", "cardcollection.sectiontitle.adventures", "Deine Abenteuer");
		Translate.AddTranslation("en", "cardcollection.sectiontitle.adventures", "Your Adventures");	

		Translate.AddQuestionInfo("kidsage", "agegroup", "Children", "Kinder");
		Translate.AddQuestionInfo("raining", "nature_people", "Outdoor", "Im Freien");
		Translate.AddQuestionInfo("dog", "dog", "Animals", "Tiere");
		Translate.AddQuestionInfo("accessible", "accessible", "Accessible", "Barrierefreiheit");
		Translate.AddQuestionInfo("stroller", "child_friendly", "Accessible", "Barrierefreiheit");
		Translate.AddQuestionInfo("physically_active", "fitness_center", "Active", "Aktivität");
		Translate.AddQuestionInfo("outdoorsy", "nature_people", "Outdoor", "Im Freien");
		Translate.AddQuestionInfo("rating", "star", "Rating", "Bewertungen");
		Translate.AddQuestionInfo("overhead", "event", "Planning" , "Planung");
		Translate.AddQuestionInfo("budget", "event", "Planning" , "Planung");
		Translate.AddQuestionInfo("animals", "dog", "Animals", "Tiere");
		Translate.AddQuestionInfo("culture", "color_lens", "Art & Culture", "Kunst & Kultur");
		

		Translate.remainingLanguages = 2;
		Translate.LoadLanguage("English_US", "en");
		Translate.LoadLanguage("German", "de");
	}

	static AddQuestionInfo(key, icon, topic_en, topic_de)
	{
		Translate.AddTranslation("de", "question.icon.question." + key, icon);
		Translate.AddTranslation("en", "question.icon.question." + key, icon);

		Translate.AddTranslation("de", "question.topic.question." + key, topic_de);
		Translate.AddTranslation("en", "question.topic.question." + key, topic_en);
	}

	static AreLanguagesLoaded()
	{
		return Translate.remainingLanguages === 0;
	}

	static IsLanguageSupported(langKey)
	{
		var strings = Translate.languages[langKey];
		if (strings === undefined)
			return false;
		else
			return true;
	}

	static LoadLanguage(langFile, langKey)
	{
		Translate.languages[langKey] = [];

		// $.getJSON("https://d2fme0r2x7x2j8.cloudfront.net/assets/lang/LENA_Landingpage_" + langFile + ".json", function (response)
		// {
		// 	Translate.languages[langKey] = response;
		// 	Translate.remainingLanguages--;
		// 	if (Translate.remainingLanguages === 0)
		// 	{
		// 		Translate.OnAllLanguagesLoaded();
		// 	}
		// });

		//TEMP:
		Translate.remainingLanguages--;
		if (Translate.remainingLanguages === 0)
		{
			Translate.OnAllLanguagesLoaded();
		}
	}

	static SetUserLang()
	{
		//AppState.instance.userProfile.userLang = Utils.GetLocalStorage("userLang");
		if (AppState.instance.userProfile.userLang === undefined || AppState.instance.userProfile.userLang === null)
		{
			AppState.instance.userProfile.userLang = navigator.language || navigator.userLanguage;
		}
		else
		{
			console.log("stored user language: " + AppState.instance.userProfile.userLang);
		}

		if (AppState.instance.userProfile.userLang.length > 2)
			AppState.instance.userProfile.userLang = AppState.instance.userProfile.userLang.substring(0, 2);

		console.log("user language: " + AppState.instance.userProfile.userLang);
	}

	static OnAllLanguagesLoaded()
	{
		if (Translate.IsLanguageSupported(AppState.instance.userProfile.userLang) === false)
		{
			// Default to english
			AppState.instance.userProfile.userLang = "en";
		}
		console.log("set language to: " + AppState.instance.userProfile.userLang);

		if (AppState.instance.userProfile.userLang === "de")
			Moment.locale("de");
		else
			Moment.locale("en-ie");

		Translate.TranslatePage();
		AppState.instance.OnAllLanguagesLoaded();
	}

	static TStr()
	{
		if (arguments.length === 0)
		{
			console.error("Translate.TStr called without arguments");
			return "";
		}

		var parameters = undefined;
		var key;
		var defaults = {};
		for (var i = 0; i < arguments.length; ++i)
		{
			const arg = arguments[i];

			if (arg.constructor === Array)
			{
				parameters = arg;
				continue;
			}

			var isDefault = false;
			if (arg.length > 3 && arg.charAt(2) === ".")
			{
				if (arg.charAt(0) === arg.charAt(0).toLowerCase() &&
					arg.charAt(1) === arg.charAt(1).toLowerCase())
				{
					var lang = arg.substring(0, 2);
					defaults[lang] = arg.substring(3);
					isDefault = true;
				}
			}
		
			if (isDefault === false)
			{
				key = arg;
			}
		}

		for (var defaultLang in defaults)
		{
			Translate.AddTranslation(defaultLang, key, defaults[defaultLang]);
		}

		return Translate.FindTranslation(key, parameters);
	}

	static T()
	{
		if (arguments.length === 0)
		{
			console.error("Translate.T called without arguments");
			return "";
		}

		/*console.error("DEBUG:");
		for (i = 0; i < arguments.length; ++i)
		{
			console.log("   " + arguments[i]);
		}*/

		var parameters = undefined;
		var key;
		var defaults = {};
		for (var i = 0; i < arguments.length; ++i)
		{
			const arg = arguments[i];

			if (arg.constructor === Array)
			{
				parameters = arg;
				continue;
			}

			var isDefault = false;
			if (arg.length > 3 && arg.charAt(2) === ".")
			{
				if (arg.charAt(0) === arg.charAt(0).toLowerCase() &&
					arg.charAt(1) === arg.charAt(1).toLowerCase())
				{
					var lang = arg.substring(0, 2);
					defaults[lang] = arg.substring(3);
					isDefault = true;
				}
			}
		
			if (isDefault === false)
			{
				key = arg;
			}
		}

		for (var defaultLang in defaults)
		{
			Translate.AddTranslation(defaultLang, key, defaults[defaultLang]);
		}

		var parameterProps = {};
		if (parameters !== undefined)
		{
			for (i = 0; i < parameters.length; ++i)
				parameterProps["data-param" + i] = parameters[i];
		}

		return (
			<span className="translate" data-langkey={key} {...parameterProps}>
				{Translate.FindTranslation(key, parameters)}
			</span>
		);
	}

	static ReplaceVars(format, parameters)
	{
		if (parameters === undefined)
			return format;

		var result = format;
		for (var i = 0; i < parameters.length; ++i)
		{
			result = Utils.ReplaceAll(result, "{" + i + "}", parameters[i]);
		}
		return result;
	}

	static FindTranslation(key, parameters)
	{
		if (!Translate.IsLanguageSupported(AppState.instance.userProfile.userLang))
			return undefined;
	
		var strings = Translate.languages[AppState.instance.userProfile.userLang];
		for (var i = 0; i < strings.length; ++i)
		{
			if (strings[i].term === key)
				return Translate.ReplaceVars(strings[i].definition, parameters);
		}

		strings = Translate.staticTranslations[AppState.instance.userProfile.userLang];
		if (strings !== undefined)
			return Translate.ReplaceVars(strings[key], parameters);
	
		return undefined;
	}

	static TranslatePage()
	{
		$('[data-langkey]').each(function()
		{
			var key = $(this).attr('data-langkey');
			var param0 = $(this).attr('data-param0');
			var parameters = undefined;
			if (param0 !== undefined)
			{
				parameters = [];
				for (var i = 0; i < 100; ++i)
				{
					var param = $(this).attr('data-param' + i);
					if (param === undefined)
						break;
					parameters.push(param);
				}
			}
			var translation = Translate.FindTranslation(key, parameters);
			if (translation !== undefined)
				$(this).html(translation);

			//console.log("page trans: " + key + " with " + AppState.instance.userProfile.userLang + " => " + translation);
		});
	}

	static handleRequestClose = () => {
		Translate.showLanguageSelection = false;
		Translate.showLanguageSelectionCallback = undefined;
	};

	static ShowLanguageSelection(callback)
	{
		Translate.showLanguageSelection = true;
		Translate.showLanguageSelectionCallback = callback;
	}

	static RenderMenuItem(menuEntry, index, menuEntries)
	{
		var onClick = this.OnMenuItemClicked;
		if (menuEntry.onClick !== undefined)
			onClick = menuEntry.onClick;

		return (
			<ListItem
				divider
				button
				onClick={onClick}
				data-id={menuEntry.id}
		  		className={"listMenuItem" + ((index === 0) ? " listMenuItemFirst" : "") + ((index === menuEntries.length - 1) ? " listMenuItemLast" : "")}
				key={index}
			>
				{menuEntry.rightLabel}
				<ListItemText primary={menuEntry.label}/>
			</ListItem>
		);
	}

	static RenderLanguageSelection()
	{
		var menuEntries = [];
		var i = 0;
		for (var langKey in Translate.languages)
		{
			var iconName = (AppState.instance.userProfile.userLang === langKey) ? <RadioButtonChecked/> : <RadioButtonUnchecked/>;

			menuEntries.push({
				id: i,
				label: <span className="modalLanguageEntry">{Translate.T("language." + langKey)}</span>,
				rightLabel: iconName,
				onClick: Translate.OnLanguageSelected
			});
			++i;
		}
		
		return(
			<Dialog
				id="modalLanguageSelection" className={AppState.instance.GetPlatformClasses()}
				open={Translate.showLanguageSelection} onClose={Translate.handleRequestClose}>
				<List>
					{menuEntries.map((menuEntry, index) =>
					{
						return Translate.RenderMenuItem(menuEntry, index, menuEntries);
					})}
				</List>
			</Dialog>
		);
	}

	static OnLanguageSelected(e)
	{
		var itemId = undefined;
		if (e.target.dataset.id === undefined)
		{
			var parents = $(e.target).parents(".listMenuItem");
			if (parents.length > 0)
			{
				itemId = parseInt(parents[0].dataset.id, 10);	
			}
		}
		else
		{
			itemId = parseInt(e.target.dataset.id, 10);
		}

		var i = 0;
		for (var langKey in Translate.languages)
		{
			if (i === itemId)
			{
				Translate.SwitchLanguage(langKey);
				//TODO: send to server
				//Utils.SetLocalStorage("userLang", AppState.instance.userProfile.userLang);

				if (Translate.showLanguageSelectionCallback !== undefined)
				{
					Translate.showLanguageSelectionCallback(langKey);
				}
				break;
			}
			++i;
		}

		Translate.showLanguageSelection = false;
		Translate.showLanguageSelectionCallback = undefined;
	}

	static SwitchLanguage(newLangKey)
	{
		if (AppState.instance.userProfile.userLang === newLangKey)
			return;

		AppState.instance.userProfile.userLang = newLangKey;

		if (newLangKey === "de")
			Moment.locale("de");
		else
			Moment.locale("en-ie");

		AppState.instance.userProfile.Save();
		console.log("switched language to: " + newLangKey);
		Translate.TranslatePage();

		Tracking.SendEvent("languageSwitch", {language: newLangKey});
	}

	static AddTranslation(langKey, key, value)
	{
		if (Translate.staticTranslations[langKey] === undefined)
			Translate.staticTranslations[langKey] = {};
		
		Translate.staticTranslations[langKey][key] = value;
		//console.log("add trans: " + key + "@" + langKey + " = " + value);
	}

	static CurrentLanguageLabel()
	{
		return Translate.T("language." + AppState.instance.userProfile.userLang);
	}
}
