import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import Button from 'material-ui/Button';
import { observable } from '../../../node_modules/mobx';
import Translate from '../../Translate';
import Utils from '../../Utils';
import AppState from '../../AppState';
import SocialList from '../SocialList';

const styles = theme => ({

});

@observer
class SocialLists extends React.Component
{
	@observable listIndex;
	socialList;
	username;

	constructor(props)
	{
		super(props);

		if (this.props.username)
		{
			this.username = this.props.username;
		}
		else
		{
			var locInfo = Utils.ExtractLocationInfo(window.location.pathname);
			this.username = locInfo.username;
		}
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps.username !== this.props.username ||
			prevProps.token !== this.props.token)
		{
			if (this.props.username)
			{
				this.username = this.props.username;
				this.DoSelectList(0);
			}
		}
	}

	componentDidMount()
	{
		AppState.instance.socialManager.onProfilesChanged.Add(this.OnDataChanged);
		this.DoSelectList(0);
	}

	componentWillUnmount()
	{
		AppState.instance.socialManager.onProfilesChanged.Remove(this.OnDataChanged);
	}

	OnDataChanged = () =>
	{
		this.forceUpdate();
	}

	OnListClicked = (index) => () =>
	{
		this.DoSelectList(index);
	}

	DoSelectList(index)
	{
		const profile = AppState.instance.socialManager.GetProfile(this.username, true, this.OnProfileLoaded);
		if (profile)
		{
			this.socialList = profile[this.GetListName(index)];
			this.listIndex = index;
		}
	}

	OnProfileLoaded = () =>
	{
		this.DoSelectList(0);
	}

	GetListName(index)
	{
		if (index === 0)
			return "family";
		if (index === 1)
			return "friends";
		if (index === 2)
			return "followers";
		if (index === 3)
			return "following";
	}

	render()
	{
		return (
			<div className={"socialLists"}>

				<div className="menu">

					<Button className={"slMenuItem" + (this.listIndex === 0 ? " selected":"")}
						onClick={this.OnListClicked(0)}>
						{Translate.T("en.Family", "de.Familie", "profile.family")}
					</Button>
					<Button className={"slMenuItem" + (this.listIndex === 1 ? " selected":"")}
						onClick={this.OnListClicked(1)}>
						{Translate.T("en.Friends", "de.Freunde", "profile.friends")}
					</Button>
					<Button className={"slMenuItem" + (this.listIndex === 2 ? " selected":"")}
						onClick={this.OnListClicked(2)}>
						{Translate.T("en.Followers", "de.Followers", "profile.followers")}
					</Button>
					<Button className={"slMenuItem" + (this.listIndex === 3 ? " selected":"")}
						onClick={this.OnListClicked(3)}>
						{Translate.T("en.Following", "de.Following", "profile.following")}
					</Button>

				</div>

				<div className="list">
					{this.socialList && this.socialList.length > 0 &&
						<SocialList
							usernames={this.socialList}
						/>}
				</div>

				{AppState.instance.deviceInfo.desktop && this.props.onEmptyCta && (!this.socialList || this.socialList.length === 0) &&
				<div className="emptyList">
					<div className="text">
						{this.listIndex === 0 && Translate.T("de.Du hast noch keine Familie auf LENA", "en.You don't have family on LENA yet", "sociallist.empty.family")}
						{this.listIndex === 1 && Translate.T("de.Du hast noch keine Freunde", "en.You don't have friends yet", "sociallist.empty.friends")}
						{this.listIndex === 2 && Translate.T("de.Du hast noch keine Follower. Teile interessante Ausflugsberichte und Fotos um das zu ändern.",
							"en.You don't have any followers yet. Share interesting trip experiences and photos to get started", "sociallist.empty.followers")}
						{this.listIndex === 3 && Translate.T("de.Du folgst noch niemandem auf LENA", "en.You are not following anyone yet", "sociallist.empty.following")}
					</div>
					{this.listIndex !== 2 && <Button className="emptyListCta" onClick={this.props.onEmptyCta}>
						{this.listIndex === 0 && Translate.T("de.Suche nach Deiner Familie", "en.Search for your family", "sociallist.empty.cta.family")}
						{this.listIndex === 1 && Translate.T("de.Suche nach Freunden", "en.Search for friends", "sociallist.empty.cta.friends")}
						{this.listIndex === 3 && Translate.T("de.Suche nach interessanten Personen", "en.Search for interesting people to follow", "sociallist.empty.cta.following")}
					</Button>}
				</div>}
				
			</div>
		);
	}
}

SocialLists.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SocialLists);