import React from 'react';
import Tracking from '../Tracking';
import RestApi from '../RestApi';
import Utils from '../Utils';
import Delegate from './Delegate';
import AppState from '../AppState';

export default class GeoLocation extends React.Component
{
	hasPermission;
	onPosition = new Delegate();
	permission;
	watchId;
	currentLocation;
	onStartSuccess;

	lastSendLat = 0;
	lastSendLong = 0;

	Init(onPosition)
	{
		this.AddOnPosition(onPosition);

		this.permission = Utils.GetLocalStorage('geolocationperm', false);
		var wasRunning = Utils.GetLocalStorage('geointerval', false);

		console.log("GeoLocation.Init => perm: " + this.permission + ", interval: " + wasRunning);
		
		if (this.HasPermission() === true /*&& wasRunning === true*/)
			this.StartInterval();
	}

	AddOnPosition(cb)
	{
		this.onPosition.Add(cb);
	}

	RemoveOnPosition(cb)
	{
		this.onPosition.Remove(cb);
	}

	HasPermission()
	{
		return this.permission;
	}

	IsIntervalRunning()
	{
		return this.watchId !== undefined;
	}

	StartInterval(onStartSuccess)
	{
		console.log("GeoLocation.StartInterval");

		if (this.watchId !== undefined)
			this.StopInterval();

		this.onStartSuccess = onStartSuccess;

		if (navigator.geolocation)
		{
			this.watchId = navigator.geolocation.watchPosition(this.OnGeoSuccess, this.OnGeoError, this.GetOptions());
		}
		else
		{
			this.OnGeoApiNotAvail();
		}
	}

	StopInterval()
	{
		if (this.watchId !== undefined)
		{
			console.log("GeoLocation.StopInterval");

			navigator.geolocation.clearWatch(this.watchId);
			this.watchId = undefined;
			Utils.SetLocalStorage('geointerval', false);
			this.currentLocation = undefined;
		}
	}

	// calls the onPosition or onError callback
	// UpdateLocation()
	// {
	// 	if (navigator.geolocation)
	// 	{
	// 		//TODO: if we are asking the first time show a popup explaining why we are doing this

	// 		navigator.geolocation.getCurrentPosition(this.OnGeoSuccess, this.OnGeoError, this.GetOptions());
	// 	}
	// 	else
	// 	{
	// 		this.OnGeoApiNotAvail();
	// 	}
	// }

	// ====================================================================================

	OnGeoSuccess = (position) =>
	{
		//console.log("OnGeoSuccess:");
		//console.log(position);

		if (AppState.instance.gpsPositionCheat)
		{
			position = {
				coords: {
					latitude: AppState.instance.gpsPositionCheat.latitude,
					longitude: AppState.instance.gpsPositionCheat.longitude,
				}
			};
		}

		this.SetPermission(true);

		// if (this.onStartSuccess)
		// {
		// 	this.onStartSuccess(true);
		// 	this.onStartSuccess = undefined;
		// }
		Utils.SetLocalStorage('geointerval', true);

		var thisPtr = this;

		const diffMeters = Utils.DiffInMeters(this.lastSendLat, this.lastSendLong,
			position.coords.latitude, position.coords.longitude);
		if (diffMeters < 1)
			return;

		this.lastSendLat = position.coords.latitude;
		this.lastSendLong = position.coords.longitude;

		RestApi.SendRequest("/location", {lat: position.coords.latitude, lon: position.coords.longitude})
		.then((geoLocation) =>
		{
			//console.log("got loc from api");

			if (geoLocation !== undefined && geoLocation !== null)
			{
				thisPtr.currentLocation = geoLocation;
				thisPtr.currentLocation.latitude = position.coords.latitude;
				thisPtr.currentLocation.longitude = position.coords.longitude;

				if (thisPtr.onStartSuccess)
				{
					thisPtr.onStartSuccess(true);
					thisPtr.onStartSuccess = undefined;
				}

				thisPtr.onPosition.Call(geoLocation);
			}

			if (thisPtr.watchId === undefined)
				thisPtr.StartInterval();
		});
	}

	OnGeoApiNotAvail()
	{
		this.OnGeoError({code: 2 /*POSITION_UNAVAILABLE*/, message: "navigator.geolocation not supported"})
	}
	
	OnGeoError = (error) =>
	{
		if (AppState.instance.gpsPositionCheat)
		{
			var position = {
				coords: {
					latitude: AppState.instance.gpsPositionCheat.latitude,
					longitude: AppState.instance.gpsPositionCheat.longitude,
				}
			};
			this.OnGeoSuccess(position);
			return;
		}

		if (error.code === 1 /*PERMISSION_DENIED*/)
		{
			this.SetPermission(false);

			if (this.onStartSuccess)
			{
				this.onStartSuccess(false, error);
				this.onStartSuccess = undefined;
			}

			this.StopInterval();

			Tracking.SendEvent("geolocation_permission_denied", {error});
		}
		else
		{
			if (this.onStartSuccess)
			{
				this.onStartSuccess(false, error);
				this.onStartSuccess = undefined;
			}

			this.StopInterval();
			
			Tracking.SendEvent("geolocation_error", {error});
		}

		console.log("geolocation error: " + error.code + ": " + error.message);
	}

	SetPermission(perm)
	{
		this.permission = perm;
		Utils.SetLocalStorage('geolocationperm', perm);
	}

	GetOptions()
	{
		return {
			enableHighAccuracy: true,
			timeout: 30000,
			maximumAge: 60000
		};
	}
}
