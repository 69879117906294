import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import AppState from '../AppState';
import Button from 'material-ui/Button';
import $ from 'jquery';
import FilterPage from '../pages/FilterPage';
import Translate from '../Translate';
import FilterList from 'material-ui-icons/FilterList';
import NearMeIcon from 'material-ui-icons/NearMe';
import ArrowDropDown from 'material-ui-icons/ArrowDropDown';
import ArrowDropUp from 'material-ui-icons/ArrowDropUp';
import TripDateTime from './TripDateTime';
import UserListSelection from './UserListSelection';
import ProfilePicture from './ProfilePicture';

const styles = theme => ({
});


@observer
class SortSelectionMenu extends React.Component
{
	OnSortClicked = (set) => () =>
	{
		if (this.props.onSortSelected)
		{
			const isSortingSet = AppState.instance.userProfile.filterSortBy;
			var isSortingAsc = AppState.instance.userProfile.filterSortAsc;

			if (set === isSortingSet)
				isSortingAsc = !isSortingAsc;
			else
			{
				if (set === "relevance" || set === "rating")
					isSortingAsc = false;
				else
					isSortingAsc = true;
			}

			this.props.onSortSelected(set, isSortingAsc);
			this.forceUpdate();
		}
	}

	render()
	{
		const isSortingSet = AppState.instance.userProfile.filterSortBy;
		const isSortingAsc = AppState.instance.userProfile.filterSortAsc;

		const icon = isSortingAsc ? <ArrowDropUp className="filterSortIcon" /> : <ArrowDropDown className="filterSortIcon" />;

		return (
			<div className="sortSelectionMenu">
				<Button onClick={this.OnSortClicked("relevance")}>
					<span className="sortSelectionName">{Translate.T("en.Relevance", "de.Relevanz", "filterbar.button.sort.relevance")}</span>
					{isSortingSet === "relevance" && icon}
				</Button>
				<Button onClick={this.OnSortClicked("price")}>
					<span className="sortSelectionName">{Translate.T("en.Price", "de.Preis", "filterbar.button.sort.price")}</span>
					{isSortingSet === "price" && icon}
				</Button>
				<Button onClick={this.OnSortClicked("rating")}>
					<span className="sortSelectionName">{Translate.T("en.Rating", "de.Bewertung", "filterbar.button.sort.rating")}</span>
					{isSortingSet === "rating" && icon}
				</Button>
				<Button onClick={this.OnSortClicked()}>
					<span className="sortSelectionName">{Translate.T("en.Distance", "de.Distanz", "filterbar.button.sort.distance")}</span>
					{((isSortingSet === undefined) || (isSortingSet === "distance")) && icon}
				</Button>
				<Button onClick={this.OnSortClicked("duration")}>
					<span className="sortSelectionName">{Translate.T("en.Travel Duration", "de.Anreisedauer", "filterbar.button.sort.duration")}</span>
					{isSortingSet === "duration" && icon}
				</Button>
				<Button onClick={this.OnSortClicked("tripduration")}>
					<span className="sortSelectionName">{Translate.T("en.Trip Duration", "de.Ausflugsdauer", "filterbar.button.sort.tripduration")}</span>
					{isSortingSet === "tripduration" && icon}
				</Button>
			</div>
		);
	}
}

@observer
class FilterBar extends React.Component
{
	componentDidMount()
	{
		//if (!AppState.instance.isLandingPage)
		//	$($("#tabsContainer").parents().find("> div")[0]).css('paddingBottom', '56px');

		AppState.instance.filterManager.AddOnFilterChanged(this.OnFiltersChanged);
	}

	componentWillUnmount()
	{
		AppState.instance.filterManager.RemoveOnFilterChanged(this.OnFiltersChanged);
	}

	OnFiltersChanged = () =>
	{
		this.forceUpdate();
	}

	OnOpenFilterDialog = () =>
	{
		FilterPage.Show();
	}

	OnApplyFilter = (f) => () =>
	{
		if (AppState.instance.mainPageInstance)
			AppState.instance.mainPageInstance.ToggleFilter(f.id);
		AppState.instance.OnFilterChanged();
		//this.forceUpdate();
	}

	renderFilterButton(f, index, isLast)
	{
		const isActive = AppState.instance.filterManager.IsFilterActive(f.id);
		return (
			<div className={"filterBarItem" + (isLast ? " last":"") + (isActive ? " active":"")} key={index}>
				<Button onClick={this.OnApplyFilter(f)}>
					{f.title}
				</Button>
			</div>
		);
	}

	GetFilterButtonSelection()
	{
		var filters = AppState.instance.filterManager.GetFilters();

		var temp = [];

		for (var i = 0; i < filters.length; ++i)
		{
			const f = filters[i];
			if (f.showInFilterBar)
			{
				temp.push({
					filter: f,
					active: AppState.instance.filterManager.IsFilterActive(f.id) ? 1 : 0
				});
			}
		}

		temp.sort(function(a,b) {return (a.active > b.active) ? -1 : ((b.active > a.active) ? +1 : 0);} );

		var result = [];
		for (i = 0; i < temp.length; ++i)
		{
			result.push(temp[i].filter);
		}

		return result;
	}

	OnOpenLocationDialog = () =>
	{
		if (this.props.onLocationButton)
		{
			this.props.onLocationButton();
		}
		else
		{
			if (AppState.instance.isInLocationSearch)
			{
				AppState.instance.isInLocationSearch = false;
				if (AppState.instance.appInstance)
					AppState.instance.appInstance.OnLeaveSearch();
			}
			else
			{
				AppState.instance.isInLocationSearch = true;
				this.forceUpdate();
				if (AppState.instance.mainPageInstance)
					AppState.instance.mainPageInstance.forceUpdate();
				$("#searchInput").focus();
			}
		}
	}

	OnOpenSortDialog = () =>
	{
		AppState.instance.screenMessage.ShowDialog(undefined, SortSelectionMenu, undefined, undefined, undefined,
		{
			onSortSelected: this.OnSortSelectionButton
		}, false, "dialogSortSelectionButton", true);
	}

	OnSortSelectionButton = (set, asc) =>
	{
		AppState.instance.userProfile.filterSortBy = set;
		AppState.instance.userProfile.filterSortAsc = asc;

		AppState.instance.OnFilterChanged();
		AppState.instance.screenMessage.CloseDialog();
		this.forceUpdate();
	}

	OnScroll = (e) =>
	{
		if (this.props.onScroll)
			this.props.onScroll();
	}

	OnOpenProfileDialog = () =>
	{
		if (AppState.instance.appInstance)
			AppState.instance.appInstance.OnTabbarChanged(undefined, AppState.TabIndexProfile);
	}

	render()
	{
		var scrollProp = {};
		var ios = AppState.instance.deviceInfo.ios;
		if (!ios)
		{
			scrollProp = {
				overflowX: 'auto'
			};
		}

		var badgeValue = AppState.instance.filterManager.GetNumSetFilters();

		const isSortingSet = AppState.instance.userProfile.filterSortBy;
		const isSortingAsc = AppState.instance.userProfile.filterSortAsc;

		var filters = this.GetFilterButtonSelection();

		const manualLocation = AppState.instance.manualLocation;
		if (manualLocation && manualLocation.title && !manualLocation.address)
			manualLocation.address = manualLocation.title;
		const isManualLocation = manualLocation !== undefined && manualLocation.address && manualLocation.address.length > 0;

		const isGeoLocation = AppState.instance.GetGpsPosition();
		//const isLocationSet = isManualLocation || isGeoLocation;

		const showFiltersButton = this.props.showFiltersButton === undefined ? true : this.props.showFiltersButton;
		const showSortButton = this.props.showSortButton === undefined ? true : this.props.showSortButton;
		const showFilters = this.props.showFilters === undefined ? true : this.props.showFilters;
		const showLocation = this.props.showLocation === undefined ? true : this.props.showLocation;
		const showDateTime = this.props.showDateTime === undefined ? true : this.props.showDateTime;
		const showUserList = this.props.showUserList === undefined ? false : this.props.showUserList;
		const showProfile = this.props.showProfile === undefined ? false : this.props.showProfile;

		var iconProfile = 
			<ProfilePicture className="filterProfilePhoto"
				username={AppState.instance.IsLoggedIn() ? AppState.instance.userProfile.username : undefined}
			/>;

		return (
			<div id="tabsContainer" style={{ ...this.props.style, paddingBottom: '1px', zIndex: 1, overflow: 'hidden',
				boxShadow: '0 1px 3px 0px #ccc'}}>

				<div style={{height: this.props.topSpacing || 60}} />

				<div className={"filterBarContainer" + (ios ? " ios":"")} style={scrollProp} onScroll={this.OnScroll}>

					<span className="filterBarInnerContainer">

						{showProfile && <div className={"filterBarItem filterProfileButton"} key="filterProfileButton">
							<Button onClick={this.OnOpenProfileDialog}>
								<div>
									{iconProfile}
									{AppState.instance.showNewIndicator[AppState.TabIndexProfile] &&
										<div className={"newIndicator" + (AppState.instance.showNewIndicator[AppState.TabIndexProfile] > 0 ? " newIndicatorNumber":"")}>
											{AppState.instance.showNewIndicator[AppState.TabIndexProfile] > 0 && AppState.instance.showNewIndicator[AppState.TabIndexProfile]}
										</div>}
								</div>
							</Button>
						</div>}

						{showFiltersButton && <div className={"filterBarItem filterDialogButton" + (badgeValue > 0 ? " active":"")} key="filterDialogButton">
							<Button onClick={this.OnOpenFilterDialog}>
								{!badgeValue && Translate.T("en.Filters", "de.Filter", "filterbar.button.filters.empty")}
								{badgeValue > 0 && Translate.T("en.Filters • {0}", "de.Filter • {0}", "filterbar.button.filters.set", [badgeValue])}
								{!badgeValue && <FilterList className="filterIcon"
									style={{width: 16, height: 16, paddingLeft: 8}}
								/>}
							</Button>
						</div>}

						{showDateTime && <TripDateTime onDateTimeButton={this.props.onDateTimeButton} />}

						{showLocation && <div className={"filterBarItem filterNearMeButton" + (isManualLocation ? " active":"")} key="filterNearMeButton">
							<Button onClick={this.OnOpenLocationDialog} className={isManualLocation ? " manual": (isGeoLocation ? " geo":"")}>
								{isManualLocation && manualLocation.address}
								{<NearMeIcon className={"filterNearMeIcon" + (isManualLocation ? " manual": (isGeoLocation ? " geo":""))} />}
							</Button>
						</div>}

						{showSortButton && <div className={"filterBarItem filterSortButton" + (isSortingSet !== undefined ? " active":"")} key="filterSortingButton">
							<Button onClick={this.OnOpenSortDialog}>
								{isSortingSet === "relevance" && Translate.T("en.Relevance", "de.Relevanz", "filterbar.button.sort.relevance")}
								{isSortingSet === "price" && Translate.T("en.Price", "de.Preis", "filterbar.button.sort.price")}
								{isSortingSet === "rating" && Translate.T("en.Rating", "de.Bewertung", "filterbar.button.sort.rating")}
								{((isSortingSet === undefined) || (isSortingSet === "distance")) && Translate.T("en.Distance", "de.Distanz", "filterbar.button.sort.distance")}
								{isSortingSet === "duration" && Translate.T("en.Travel Duration", "de.Anreisedauer", "filterbar.button.sort.duration")}
								{isSortingSet === "tripduration" && Translate.T("en.Trip Duration", "de.Ausflugsdauer", "filterbar.button.sort.tripduration")}
								{!isSortingAsc && <ArrowDropDown className="filterSortIcon" />}
								{isSortingAsc && <ArrowDropUp className="filterSortIcon" />}
							</Button>
						</div>}

						{showUserList && <UserListSelection
							className="filterBarItem"
							listType={this.props.userListType || 0}
							title={this.props.userListTitle}
							getUserList={this.props.getUserList}
							setUserList={this.props.setUserList}
							onButtonClicked={this.props.onUserListButtonClicked}
							enableCheckIn={this.props.userListEnableCheckIn}
							enableUsers={this.props.userListEnableUser}
						/>}

						{showFilters && filters.map((f, index) =>
						{
							return this.renderFilterButton(f, index, index === filters.length - 1);
						})}

						{this.props.additionalItems}
					</span>
					
				</div>

				
			</div>
	  );
	}
}

FilterBar.propTypes =
{
	classes: PropTypes.object,
	theme: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(FilterBar);