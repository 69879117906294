/* eslint-disable no-loop-func */
import {observable} from 'mobx';
import ApiInterface from './ApiInterface';
import RestApi from './RestApi';
import UserProfile from './models/UserProfile';
import IpLocation from './models/IpLocation';
import Tracking from './Tracking';
import Utils from './Utils';
import ContentStore from './ContentStore';
import FilterManager from './FilterManager';
import ListManager from './ListManager';
import SocialManager from './SocialManager';
import ImageManager from './ImageManager';
import AdvManager from './AdvManager';
import GeoLocation from './utils/GeoLocation';
import Delegate from './utils/Delegate';
import ConfigDateTime from './components/ConfigDateTime';
import Tutorial from './components/Tutorial';
import WeatherDisplay from './components/WeatherDisplay';
import raf from 'raf';
import ChatBotManager from './ChatBotManager';
import $ from 'jquery';

export default class AppState
{
	static instance = null;

	static TabIndexTimeline = 0;
	static TabIndexSwipe = 1;
	static TabIndexExplore = 2;
	static TabIndexBookmark = 3;	
	static TabIndexProfile = 4;
	static TabIndexChatBot = 5;
	static TabIndexAdventure = 6;
	static TabIndexJournal = 999; // disabled

	isProduction = true;
	isLandingPage = false;
	isMobileLandingPage = false;
	isProviderPage = false;

	@observable previousMainTab;
	@observable currentMainTab = 0;
	@observable showNewIndicator = [];

	@observable filterStack = [];

	@observable username;
	@observable password;
	@observable loading = false;
	@observable messages = [];
	@observable newMessage = false;
	@observable userProfile = new UserProfile();
	@observable loginDataChecked = false;
	@observable isInAuthProcess = true;
	
	@observable locationReported = false;
	@observable tabbarAvailable = false;
	@observable themeColor = '#e31c46';

	lastLenaLocationReportLat = 0;
	lastLenaLocationReportLong = 0;
	lastLenaLocationReportId = 0;

	referrer;
	@observable rootUrl;
	@observable versionTag;
	@observable contentStore = new ContentStore();
	@observable filterManager = new FilterManager();
	@observable imageManager = new ImageManager();

	@observable listManager = new ListManager();
	socialManager = new SocialManager();
	chatBotManager = new ChatBotManager();
	advManager = new AdvManager();
	@observable swipeListId;

	timelinePageInstance = null;
	mainPageInstance = null;
	bookmarkPageInstance = null;
	chatPageInstance = null;
	swipePageInstance = null;
	journalPageInstance = null;
	filterPageInstance = null;
	adventuresPageInstance = null;
	cardCollectionPageInstance = null;
	cardReceivePopup = null;
	cardDetailsPopup = null;
	profilePage = null;
	appContainer = null;
	appInstance = null;
	desktopAppInstance = null;
	activityEditor = undefined;
	lastEnteredSearchString;
	bottomTutorialInstance;

	@observable isInLocationSearch = false;
	@observable isLocationSearching = 0;

	@observable ipLocation = new IpLocation();
	@observable ipLocationLoaded = false;
	geoLocation = new GeoLocation();
	@observable manualLocation;
	startedWithActivity = false;
	startedWithActivityProcessed = false;
	startedWithActivityName;
	screenMessage;
	landingPage;
	providerPage;

	showWebcontentAtStart;

	onIpLocation = new Delegate();
	onFirstGeoLocation = new Delegate();
	onGeoLocation = new Delegate();
	isFirstGeoLocation = true;
	onAuthDone = new Delegate();
	onScreenScrolled = new Delegate();
	onWeatherChanged = new Delegate();
	onContentGridRefresh = new Delegate();
	lastOnScreenFired = new Date();
	onStartLocationSearch = new Delegate();
	onLocationSearchDone = new Delegate();

	deviceInfo;
	@observable uploadProgress = 0;

	@observable configDateTime = {};

	filterBackup; // created when FilterPage is shown. Can be used to reset changed filters in the FilterPage
	onFilterChangedRedirect; // set to hook the OnFilterChanged method

	swipeData = {};

	weatherData = {};

	// count in this session
	numSwipeRight = 0;
	numSwipeLeft = 0;
	deferredAppInstallPrompt;

	isBetaTester = false;
	//featureSocial = false;
	featureAdv = true;
	@observable chatBotActive = false;
	activeChatBot;
	chatBotInitialGoal;

	isShowingDetailPage = 0;

	isMapPageVisible = false;

	gpsPositionCheat;

	constructor()
	{
		AppState.instance = this;

		Utils.Init();
		this.deviceInfo = Utils.GetDeviceInfo();

		for (var i = 0; i < 10; ++i)
			this.showNewIndicator[i] = false;

		this.userProfile.Load();
		var manLocStr = Utils.GetLocalStorage('manuallocation');
		if (manLocStr !== undefined && manLocStr.length > 0)
		{
			this.manualLocation = JSON.parse(manLocStr);
		}

		manLocStr = Utils.GetLocalStorage('gpsPositionCheat');
		if (manLocStr !== undefined && manLocStr.length > 0)
		{
			this.gpsPositionCheat = JSON.parse(manLocStr);
			// Also activiate GPS by default
			Utils.SetLocalStorage('geolocationperm', true);	
		}

		this.geoLocation.Init(this.OnGeoLocation);
		//geoip2.city(this.OnIPSuccess);

		this.versionTag = this.GetVersionTag();

		this.isProduction = process.env.NODE_ENV === 'production' && window.location.hostname.indexOf('localhost') < 0;

		const isLocal = window.location.hostname.indexOf("localhost") >= 0 || window.location.hostname.indexOf("10.0.0.44") >= 0;

		if (this.isProduction && !isLocal)
		{
			
			if (window.location.hostname.indexOf("staging") >= 0)
				this.rootUrl = "https://staging.lena.app";
			else
				this.rootUrl = "https://www.lena.app";
		}
		else
		{
			var port = window.location.port;
			if (port === undefined || port.length === 0)
				port = 3000;
			this.rootUrl = "http://" + window.location.hostname + ":" + port;
		}

		const actIndex = window.location.pathname.indexOf("/activity/");
		this.startedWithActivity = actIndex >= 0;
		if (this.startedWithActivity)
		{
			this.startedWithActivityName = window.location.pathname.substring(actIndex + 10);
		}

		this.contentStore.Init();
		this.filterManager.Init();
		this.listManager.Init();
		this.socialManager.Init();
		this.advManager.Init();

		ConfigDateTime.Init();

		this.swipeData.show = false;

		Tutorial.Init();

		setInterval(this.UpdateWeather, 60 * 1000);

		window.addEventListener('beforeinstallprompt', (e) => {
			// Prevent Chrome 67 and earlier from automatically showing the prompt
			e.preventDefault();
			// Stash the event so it can be triggered later.
			AppState.instance.deferredAppInstallPrompt = e;
			console.log("! received beforeinstallprompt");
		});

		window.addEventListener('appinstalled', () => {
			Tracking.SendEvent("a2hs_installed");
			console.log("! a2hs installed");
		});

		$(window).on('resize orientationchange', this.OnWindowResize);
	}

	GeneralDataLoadingEnabled()
	{
		if (this.deviceInfo.desktop)
			return true;
		return !this.startedWithActivity;
	}

	OnAllLanguagesLoaded()
	{
		this.chatBotManager.Init();
	}

	CanPromptA2HS()
	{
		return this.deferredAppInstallPrompt;
	}

	PromptA2HS(onSuccess, onDismissed)
	{
		if (!this.deferredAppInstallPrompt)
			return;

		this.deferredAppInstallPrompt.prompt();

		this.deferredAppInstallPrompt.userChoice
		.then((choiceResult) =>
		{
			if (choiceResult.outcome === 'accepted')
			{
				if (onSuccess)
					onSuccess();
			}
			else
			{
				if (onDismissed)
					onDismissed();
			}
			AppState.instance.deferredAppInstallPrompt = undefined;
		});
	}

	GetMiningTokens()
	{
		return Utils.GetLocalStorageList("miningtokens");
	}

	SaveMiningToken(token)
	{
		var tokens = Utils.GetLocalStorageList("miningtokens");

		if (tokens.indexOf(token) < 0)
		{
			tokens.push(token);
			Utils.SetLocalStorageList("miningtokens", tokens);
		}
	}

	OnScreenScrolled(initiator)
	{
		var delta = new Date().getTime() - this.lastOnScreenFired.getTime();
		const maxInterval = 200;

		if (delta > maxInterval)
		{
			this.lastOnScreenFired = new Date();
			this.onScreenScrolled.Call(initiator);
		}
		else
		{
			// queue and check if another event fired meanwhile
			setTimeout(() => {
				var delta2 = new Date().getTime() - this.lastOnScreenFired.getTime();
				if (delta2 > maxInterval)
				{
					this.lastOnScreenFired = new Date();
					this.onScreenScrolled.Call(initiator);
				}		
			}, maxInterval + 50);
		}
	}

	AddOnScreenScrolled(cb)
	{
		this.onScreenScrolled.Add(cb);
	}

	RemoveOnScreenScrolled(cb)
	{
		this.onScreenScrolled.Remove(cb);
	}

	GetPlatformClasses()
	{
		const di = this.deviceInfo;
		return (di.ios ? " ios" : " noios")
			+ (di.desktop ? " desktop" : " nodesktop")
			+ (di.tablet ? " tablet" : " notablet")
			+ (di.phone ? " phone" : " nophone")
			+ (di.ie ? " iex" : " noiex");
	}

	IsWebContentUrlPart(urlPart)
	{
		return urlPart === "tos" || urlPart === "pp" || urlPart === "imprint" ||
			urlPart === "features" || urlPart === "activities" || urlPart === "start" ||
			urlPart === "about";
	}

	DecideOnPageMode()
	{
		this.isMobileLandingPage = false;
		this.isLandingPage = false;
		this.isProviderPage = false;

		const path = window.location.pathname;
		var parts = path.split('/');
		for (var i = parts.length - 1; i >= 0; --i)
			if (parts[i].trim().length === 0)
				parts.splice(i, 1);

		if (parts.length === 1 && this.IsWebContentUrlPart(parts[0]))
		{
			this.showWebcontentAtStart = parts[0];
		}

		if (this.deviceInfo.desktop)
		{
			if (window.location.hostname.indexOf("provider.lena.") >= 0)
				this.isProviderPage = true;
			else if (parts.length === 0)
				this.isLandingPage = true;
			else if (parts.length === 1 && this.IsWebContentUrlPart(parts[0]))
				this.isLandingPage = true;
			else if (parts.length === 1 && parts[0] === "provider")
				this.isProviderPage = true;

			var locInfo = Utils.ExtractLocationInfo(window.location.pathname);
			if (locInfo.filters && locInfo.filters.length > 0)
				if (!this.isProviderPage)
					this.isLandingPage = true;

			if (!this.isProviderPage)
				this.isLandingPage = true;
		}
		else
		{
			// on mobile: default is landingpage
			this.isMobileLandingPage = true;

			if (Utils.IsStandaloneMode())
				this.isMobileLandingPage = false;

			// except: has account, /activity/ or other filters
			if (parts.length > 0 && !AppState.instance.showWebcontentAtStart)
			{
				this.isMobileLandingPage = false;

				if (window.location.hostname.indexOf("provider.lena.") >= 0)
					this.isProviderPage = true;
				else if (parts.length === 1 && parts[0] === "provider")
					this.isProviderPage = true;
			}
			else
			{
				var tempProfile = new UserProfile();
				if (tempProfile.Load())
				{
					if (tempProfile.loginData && tempProfile.loginData.isLoggedIn)
					{
						this.isMobileLandingPage = false;
					}
				}
			}

			if (this.isMobileLandingPage)
				Tracking.OnPageView("mlp");
		}
	}

	UpdateWeather = () =>
	{
		var loc = this.ipLocation;
		if (this.GetGpsPosition())
			loc = this.GetGpsPosition();
		if (this.manualLocation)
			loc = this.manualLocation;

		if (!loc)
			return;

		if (!this.isProviderPage)
			this.UpdateWeatherData(loc);
	}

	OnRestApiInit()
	{
		// fetch our public IP first
		Utils.GetPublicIp().then(ip =>
		{
			if (ip)
			{
				RestApi.SendRequest("/estinit", {ip: ip})
				.then((location) =>
				{
					if (location)
					{
						this.ipLocation = location;
					}
					else
					{
						this.ipLocation = {};
					}
					this.OnIpLocationLoaded();
				});
			}
			else
			{
				this.OnIpLocationLoaded();
			}
		})
		.catch((e) =>
		{
			this.OnIpLocationLoaded();
		});
	}

	OnGeoLocation = (location) =>
	{
		if (this.isFirstGeoLocation)
		{
			this.isFirstGeoLocation = false;
			this.onFirstGeoLocation.Call(location);
		}

		this.onGeoLocation.Call(location);

		//this.geoLocation = location;
		if (this.IsLoggedIn())
			this.UpdateLenaLocation();
		else
			this.UpdateWeatherData(location);
	}

	GetVersionTag()
	{ 
		var metas = document.getElementsByTagName('meta'); 
	 
		for (var i = 0; i < metas.length; i++)
		{
			if (metas[i].getAttribute("name") === "version")
			{
				return metas[i].getAttribute("content");
			}
		}
		return "1.0";
	}

	OnIPSuccess = (location) =>
	{
		this.ipLocation.ip = undefined;// location.traits.ip_address;
		this.ipLocation.address = location.city.names.en;
		this.ipLocation.zip = location.postal.code;
		this.ipLocation.state = location.subdivisions[0].names.en;
		this.ipLocation.country = location.country.iso_code;
		this.ipLocation.latitude = location.location.latitude;
		this.ipLocation.longitude = location.location.longitude;

		this.OnIpLocationLoaded();
	}

	OnIpLocationLoaded()
	{
		this.ipLocationLoaded = true;
		console.log("received iplocation: ");
		console.log(this.ipLocation);

		Tracking.Init(this);

		if (this.IsLoggedIn())
			this.UpdateLenaLocation();
		else
			this.UpdateWeatherData(this.ipLocation);

		this.onIpLocation.Call();
	}

	ApplyFilterSetup(setup)
	{
		this.ClearFilter(false);

		this.filterStack = setup.filterStack;

		if (setup.ageMin)
			this.userProfile.filterMinAge = setup.ageMin;
		if (setup.ageMax)
			this.userProfile.filterMaxAge = setup.ageMax;

		if (setup.tripStartDate)
			this.userProfile.filterStartDate = Utils.RoundDate(new Date(setup.tripStartDate), 1);
		if (setup.tripEndDate)
			this.userProfile.filterEndDate = Utils.RoundDate(new Date(setup.tripEndDate), 1);

		if (setup.filterMaxPrice)
			this.userProfile.filterMaxPrice = setup.filterMaxPrice;

		if (setup.filterMinRating)
			this.userProfile.filterMinRating = setup.filterMinRating;

		if (setup.searchDuration)
			this.userProfile.filterMaxDuration = setup.searchDuration;

		this.manualLocation = setup.location;
		

		if (setup.q && setup.q.length > 0)
		{
			if (this.appInstance)
				this.appInstance.OnSearch(setup.q);
		}
		else
		{
			if (this.manualLocation)
			{
				this.OnManualLocationChanged();
			}
			this.OnFilterChanged();
		}
	}

	OnFilterChanged()
	{
		if (this.onFilterChangedRedirect)
		{
			this.onFilterChangedRedirect();
		}
		else
		{
			this.userProfile.Save();

			// ContentStore reloads filters -> informs FilterManager -> FilterManager calls all callbacks
			this.contentStore.OnFilterChanged();
		}
	}

	AddOnIpLocation(onChanged)
	{
		this.onIpLocation.Add(onChanged);
	}

	RemoveOnIpLocation(onChanged)
	{
		this.onIpLocation.Remove(onChanged);
	}

	OnAuthDone()
	{
		this.isInAuthProcess = false;

		if (this.IsLoggedIn())
		{
			// Check special permissions
			if (this.userProfile && this.userProfile.loginData && this.userProfile.loginData.roles && this.userProfile.loginData.roles.indexOf("BT") >= 0)
			{
				this.isBetaTester = true;
				//this.featureSocial = true;
				//this.featureAdv = true;
				//this.appInstance.OnTabbarChanged(undefined, AppState.TabIndexTimeline);
			}
		}

		this.onAuthDone.Call();
		this.userProfile.LoadTrips();

		if (this.IsLoggedIn())
		{
			Tracking.SendEvent("auth", {
				id: this.userProfile
			});

			// Claim mining token (if any)
			// Use case: user adds UGC on LandingPage and then registers account -> match new account with mined data
			var tokens = this.GetMiningTokens();
			for (var i = 0; i < tokens.length; ++i)
			{
				var q = {
					token: tokens[i]
				};
				RestApi.SendRequestText("/setminingowner", q)
				.then((response) =>
				{
					if (response === "ok")
					{
						var newtokens = this.GetMiningTokens();
						newtokens.splice(newtokens.indexOf(q.token), 1);
						Utils.SetLocalStorageList("miningtokens", newtokens);
					}
				});
			}

			// Send friend requests
			tokens = this.GetInviteTokens();
			for (i = 0; i < tokens.length; ++i)
			{
				q = {
					token: tokens[i]
				};
				RestApi.SendRequestText("/acceptinvite", q)
				.then((response) =>
				{
					//if (response && response.startsWith("["))
					//{
					var newtokens = this.GetInviteTokens();
					newtokens.splice(newtokens.indexOf(q.token), 1);
					Utils.SetLocalStorageList("invitetokens", newtokens);
					//}
				});
			}
		}
	}

	AddOnAuthDone(onDone)
	{
		this.onAuthDone.Add(onDone);

		if (!this.isInAuthProcess)
			onDone();
	}

	RemoveOnAuthDone(onDone)
	{
		this.onAuthDone.Remove(onDone);
	}

	OnManualLocationChanged()
	{
		Utils.SetLocalStorage('manuallocation', this.manualLocation !== undefined ? JSON.stringify(this.manualLocation) : '');
		this.UpdateLenaLocation();

		if (this.swipePageInstance)
			this.swipePageInstance.messages.OnLocationChanged();
	}

	// OnAsyncLoadingProgress()
	// {
	// 	// inform Lena about current location
	// 	if (!this.locationReported)
	// 	{
	// 		if (ApiInterface.IsConnected() && this.ipLocationLoaded)
	// 		{
	// 			var locStr = JSON.stringify(this.ipLocation);
	// 			console.log("sending location info: " + locStr);
	// 			ApiInterface.SendCommand("location", locStr);
	// 			this.locationReported = true;
	// 		}
	// 	}
	// }

	UpdateLenaLocation()
	{
		if (this.isInAuthProcess)
			return;

		var loc;
		if (this.ipLocationLoaded)
			loc = this.ipLocation;
		if (this.GetGpsPosition())
			loc = this.GetGpsPosition();
		if (this.manualLocation)
			loc = this.manualLocation;

		if (loc)
		{
			if (loc.latitude)
			{
				var delta0 = Math.abs(loc.latitude - this.lastLenaLocationReportLat);
				var delta1 = Math.abs(loc.longitude - this.lastLenaLocationReportLong);
				if (delta0 < 0.001 && delta1 < 0.001) //100m
				{
					return;
				}
			}

			if (loc.id !== undefined)
			{
				if (loc.id === this.lastLenaLocationReportId)
					return;
			}

			this.lastLenaLocationReportLat = loc.latitude;
			this.lastLenaLocationReportLong = loc.longitude;
			this.lastLenaLocationReportId = loc.id;

			var locStr = JSON.stringify(loc);
			//console.log("sending location info to lena: " + locStr);
			ApiInterface.SendCommand("location", locStr);

			this.UpdateWeatherData(loc);
		}
		else
		{
			this.ResetWeatherData();
		}
	}

	ResetWeatherData()
	{
		/*this.weatherLoc = undefined;
		this.weather = undefined;
		this.weatherForecast = undefined;*/
		raf(() =>
		{
			this.onWeatherChanged.Call();
		});
	}

	UpdateWeatherData(loc)
	{
		if (!this.GeneralDataLoadingEnabled())
			return null;

		var q = {
			location: loc
		};

		var thisPtr = this;

		RestApi.SendRequest("/weather", q)
		.then((r) =>
		{
			if (r)
			{
				if (loc.title && !loc.address)
					loc.address = loc.title;
				//console.log("received weather for: " + loc.address);

				var item = thisPtr.weatherData[loc.address];
				if (!item)
					item = {};

				item.weatherLoc = loc;
				item.weather = WeatherDisplay.DecodeWeather(r.weather);
				item.weatherForecast = WeatherDisplay.DecodeWeatherForecast(r.weatherForecast);

				thisPtr.weatherData[loc.address] = item;

				thisPtr.ResetWeatherData();
			}
			else
			{
				thisPtr.ResetWeatherData();
			}
		})
		.catch((e) =>
		{
			thisPtr.ResetWeatherData();
		});
	}

	UpdateLenaTripDate()
	{
		if (this.isInAuthProcess)
			return;

		var startEnd = this.contentStore.GetStartEndDateFilter();

		var q = {
			start: startEnd.start,
			end: startEnd.end
		};

		var str = JSON.stringify(q);
		console.log("sending trip date to lena: " + str);
		ApiInterface.SendCommand("tripdate", str);
	}

	IsLoggedIn()
	{
		if (this.userProfile.loginData === undefined)
			return false;
		return this.userProfile.loginData.isLoggedIn;
	}

	OnLoggedOut()
	{
		RestApi.Logout().then((r) =>
		{
			this.userProfile.loginData.isLoggedIn = false;
			this.userProfile.loginData.authProviders = [];
			this.userProfile.loginData.accessTokens = [];
			this.userProfile.loginData.firstName = "";
			this.userProfile.loginData.lastName = "";
			this.userProfile.loginData.username = "";
			this.userProfile.loginData.profilePicture = "";
			this.userProfile.Save();
			//window.open("/", '_self');
			window.open(this.rootUrl, "_self");
			window.location.reload();
		});
	}

	OnDeleteAccount()
	{
		RestApi.SendRequestText("/deleteaccount", {})
		.then((resultStr) =>
		{
			if (resultStr && resultStr === "ok")
			{
				this.userProfile = new UserProfile();
				Utils.RemoveLocalStorage('userprofile');
				window.open(this.rootUrl, "_self");
				window.location.reload();
			}
			else
			{
				//TODO:
			}
		})
		.catch((error) =>
		{
			//TODO:
		});
	}

	CreateFilterBackup()
	{
		var result = {};

		result.filterStack = this.filterStack.slice();

		result.ageMin = this.userProfile.filterMinAge;
		result.ageMax = this.userProfile.filterMaxAge;
		result.ageGroups = this.userProfile.filterAgeGroups;

		result.tripStartDate = new Date(this.userProfile.filterStartDate);
		result.tripEndDate = new Date(this.userProfile.filterEndDate);

		result.filterMinRating = this.userProfile.filterMinRating;
		result.filterMaxPrice = this.userProfile.filterMaxPrice;

		result.sortBy = this.userProfile.filterSortBy;
		result.sortAsc = this.userProfile.filterSortAsc;

		result.searchRadius = this.userProfile.filterMaxDistance;
		result.searchDuration = this.userProfile.filterMaxDuration;

		return result;
	}

	RestoreFilterBackup(backup, callOnChanged)
	{
		this.filterStack = backup.filterStack.slice();

		this.userProfile.filterMinAge = backup.ageMin;
		this.userProfile.filterMaxAge = backup.ageMax;
		this.userProfile.filterAgeGroups = backup.ageGroups;

		this.userProfile.filterStartDate = new Date(backup.tripStartDate);
		this.userProfile.filterEndDate = new Date(backup.tripEndDate);

		this.userProfile.filterMinRating = backup.filterMinRating;
		this.userProfile.filterMaxPrice = backup.filterMaxPrice;

		this.userProfile.filterSortBy = backup.sortBy;
		this.userProfile.filterSortAsc = backup.sortAsc;

		this.userProfile.filterMaxDistance = backup.searchRadius;
		this.userProfile.filterMaxDuration = backup.searchDuration;

		this.OnFilterChanged();
	}

	ClearFilter(callOnChanged)
	{
		// Reset all values as much as possible

		this.filterStack = [];

		this.userProfile.filterMinAge = 0;
		this.userProfile.filterMaxAge = 99;
		this.userProfile.filterAgeGroups = [];

		this.userProfile.filterStartDate = undefined;
		this.userProfile.filterEndDate = undefined;

		this.userProfile.filterMinRating = undefined;
		this.userProfile.filterMaxPrice = undefined;

		this.userProfile.filterSortBy = undefined;
		this.userProfile.filterSortAsc = true;

		this.userProfile.filterMaxDistance = 100;
		this.userProfile.filterMaxDuration = 2 * 3600;

		if (callOnChanged === undefined || callOnChanged === true)
			this.OnFilterChanged();
	}

	RedirectOnFilterChanged(cb)
	{
		this.onFilterChangedRedirect = cb;
	}

	EnsureValidTripDates()
	{
		if (this.userProfile.filterStartDate)
			if (!Utils.IsValidDate(this.userProfile.filterStartDate))
				this.userProfile.filterStartDate = undefined;

		if (this.userProfile.filterEndDate)
			if (!Utils.IsValidDate(this.userProfile.filterEndDate))
				this.userProfile.filterEndDate = undefined;
	}

	GetInviteTokens()
	{
		return Utils.GetLocalStorageList("invitetokens");
	}

	OnInviteToken(token)
	{
		var tokens = Utils.GetLocalStorageList("invitetokens");

		if (tokens.indexOf(token) < 0)
		{
			tokens.push(token);
			Utils.SetLocalStorageList("invitetokens", tokens);
		}
	}

	GetPageByIndex(index)
	{
		if (index === AppState.TabIndexTimeline)
		{
			return this.timelinePageInstance;
		}
		else if (index === AppState.TabIndexSwipe)
		{
			return this.swipePageInstance;
		}
		else if (index === AppState.TabIndexExplore)
		{
			return this.mainPageInstance;
		}
		else if (index === AppState.TabIndexBookmark)
		{
			return this.bookmarkPageInstance;
		}
		else if (index === AppState.TabIndexAdventure)
		{
			return this.cardCollectionPageInstance;
		}
		else if (index === AppState.TabIndexProfile)
		{
			return this.profilePage;
		}
		return undefined;
	}

	RefreshContentGrids()
	{
		this.onContentGridRefresh.Call();
	}

	OnWindowResize = () =>
	{
		this.RefreshContentGrids();
	}

	OnStartLocationSearch()
	{
		this.isLocationSearching++;
		this.onStartLocationSearch.Call();
	}

	OnLocationSearchDone()
	{
		this.isLocationSearching--;
		if (this.isLocationSearching < 0)
			this.isLocationSearching = 0;

		if (this.isLocationSearching === 0)
		{
			this.onLocationSearchDone.Call();
		}
	}

	GetBestLocation()
	{
		var loc = AppState.instance.ipLocation;
		if (AppState.instance.geoLocation.currentLocation)
			loc = AppState.instance.geoLocation.currentLocation;
		if (AppState.instance.manualLocation)
			loc = AppState.instance.manualLocation;
		if (this.gpsPositionCheat)
			loc = this.gpsPositionCheat;
		return loc;
	}

	SetGpsCheatAddress(address)
	{
		return RestApi.SendRequest("/findaddress", {address: address})
		.then((loc) =>
		{
			if (loc)
			{
				this.SetGpsCheatLocation(loc);
				return new Promise((resolve, reject) => { return resolve(loc);});
			}
			else
			{
				this.ClearGpsCheatLocation();
				return new Promise((resolve, reject) => { return reject(undefined);});
			}
		})
		.catch((error) =>
		{
			this.ClearGpsCheatLocation();
			return new Promise((resolve, reject) => { return reject(error);});
		});
	}

	SetGpsCheatLocation(loc)
	{
		this.gpsPositionCheat = loc;
		Utils.SetLocalStorage('gpsPositionCheat', this.gpsPositionCheat !== undefined ? JSON.stringify(this.gpsPositionCheat) : '');

		this.geoLocation.onPosition.Call(loc);
	}

	ClearGpsCheatLocation()
	{
		this.gpsPositionCheat = undefined;
		Utils.RemoveLocalStorage('gpsPositionCheat');
	}

	GetGpsPosition()
	{
		if (this.gpsPositionCheat)
			return this.gpsPositionCheat;
		if (this.geoLocation.currentLocation)
			return this.geoLocation.currentLocation;
		return undefined;
	}
}
