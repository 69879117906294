import React from 'react';
import Translate from '../../Translate';
import AppState from '../../AppState';
import StepBase from './StepBase';
import RestApi from '../../RestApi';
import { CircularProgress } from 'material-ui';
import Map from '../Map';

export default class StepCheckInActivity extends StepBase
{
	map;

	GetId()
	{
		return "checkinActivity";
	}

	GeneratedDataIds()
	{
		return ["checkinActivity"];
	}

	IsEditEnabled(contextData) { return false; }

	OnGoalChanged(goalId, contextData)
	{
		if (goalId === "checkin")
		{
			contextData.enableUpdate = true;
			if (AppState.instance.geoLocation.IsIntervalRunning())
				this.UpdateOptions(contextData);
			else
				AppState.instance.geoLocation.StartInterval(this.OnGpsInitDone(contextData));
		}
	}

	OnGpsInitDone = (contextData) => () =>
	{
		this.UpdateOptions(contextData);
	}

	UpdateOptions(contextData)
	{
		if (AppState.instance.deviceInfo.desktop)
		{
			this.OnLoadingError(contextData, "platform");
			return;
		}

		contextData.currentLocation = undefined;
		if (AppState.instance.GetGpsPosition())
			contextData.currentLocation = AppState.instance.GetGpsPosition();

		if (!contextData.currentLocation)
		{
			this.OnLoadingError(contextData, "location");
			return;
		}

		contextData.progress = true;
		contextData.onChanged();

		var q = {
			location: contextData.currentLocation
		};
		RestApi.SendRequest("/checkinsearch", q).then((response) =>
		{
			if (response)
			{
				this.answerOptions = [];

				const items = response.items;
				if (items && items.length > 0)
				{
					contextData.checkInItems = items;

					for (var i = 0; i < items.length; ++i)
					{
						this.AddAnswerOption({
							label: items[i].title,
							onClick: this.OnActivityClicked(items[i]),
							id: i
						});
					}

					delete contextData.error;
					contextData.progress = false;
					contextData.onChanged();

					setTimeout(() => {
						if (this.map)
							this.map.SetToCenterPosition();
					}, 1000);
				}
				else
				{
					this.OnLoadingError(contextData, "activities");
				}
			}
			else
			{
				this.OnLoadingError(contextData, "loading");
			}

			if (contextData.enableUpdate)
			{
				setTimeout(() => {
					this.UpdateOptions(contextData);
				}, 10 * 1000);
			}
		})
		.catch((error) =>
		{
			this.OnLoadingError(contextData, "loading");
		});
	}

	//OnActivityClicked = (item) => (e, contextData) =>
	OnActivityClicked = (contextData) => (e, activity) =>
	{
		delete contextData.enableUpdate;

		var q = {
			location: contextData.currentLocation,
			activityTitle: activity.title
		};

		RestApi.SendRequest("/checkin", q).then((userListItem) =>
		{
			if (userListItem)
			{
				AppState.instance.listManager.OnNewListContent();
				AppState.instance.listManager.RefreshData();

				this.OnStepDone(contextData, {
					checkinActivity: activity
				});
			}
			else
			{
				this.OnLoadingError(contextData, "checkin");
			}
		})
		.catch((error) =>
		{
			this.OnLoadingError(contextData, "checkin");
		});
	}

	OnLoadingError(contextData, error)
	{
		contextData.error = error;
		delete contextData.checkInItems;

		this.answerOptions = [];

		if (error === "activities")
		{
			//TODO:
			// this.AddAnswerOption({
			// 	label: Translate.T("de.Fehlendes Ausflugsziel melden", "en.Report missing place", "lena.checkin.reportmissing"),
			// 	onClick: this.OnReportMissing,
			// 	id: "report"
			// });	
		}

		this.AddAnswerOption({
			label: Translate.T("de.Schließen", "en.Close", "lena.checkin.nolocation.close"),
			onClick: this.OnCloseNoLocation,
			id: "close"
		});

		contextData.progress = false;
		contextData.onChanged();
	}

	OnReportMissing = (e, contextData) =>
	{
		//TODO: new goal
	}

	OnCloseNoLocation = (e, contextData) =>
	{
		delete contextData.enableUpdate;
		contextData.bot.Reset(true);
	}

	Undo(contextData)
	{
		delete contextData["checkinActivity"];
		delete contextData.currentLocation;
		delete contextData.enableUpdate;
		delete contextData.error;
	}

	renderStepLena(contextData)
	{
		if (contextData.error === "location")
			return this.renderLenaMsg(Translate.T("de.Ich kann leider Deine aktuelle Position nicht bestimmen. Ist Dein GPS aktiviert und hat Dein Internet-Browser die nötigen Zugriffsrechte?",
				"en.I can't determine your current position. Is your GPS activated and does your internet browser have the necessary rights?", "lena.checkin.error.location"));

		if (contextData.error === "loading")
			return this.renderLenaMsg(Translate.T("de.Ich habe gerade ein Problem die Ausflugsziele in Deiner Nähe zu finden. Bitte versuche es später nochmal.",
				"en.I'm having trouble finding the activities near you. Please try again later.", "lena.checkin.error.loading"));

		// if (contextData.error === "activities")
		// 	return this.renderLenaMsg(Translate.T("de.Ich kann leider kein Ausflugsziel in Deiner Nähe finden. Übersehe ich etwas?",
		// 		"en.I can't find any activities near you. Am i missing something?", "lena.checkin.error.activities"));
		if (contextData.error === "activities")
			return this.renderLenaMsg(Translate.T("de.Ich kann leider im Moment kein Ausflugsziel in Deiner Nähe finden. Aber halte weiterhin Ausschau nach bekannten Orten.",
				"en.I can't find any activities near you at the moment. But I keep on scanning for nearby locations.", "lena.checkin.error.activities"));
				 
		if (contextData.error === "platform")
			return this.renderLenaMsg(Translate.T("de.Auf diesem Gerät ist ein Check-In nicht möglich. Du kannst mit Deinem Mobilgerät direkt am Ausflugsort einchecken.",
				"en.Checking in with this device is not possible. You can use your mobile device to check in directly at the activity location.", "lena.checkin.error.platform"));

		return this.renderLenaMsg(Translate.T("de.Bei welchem Ausflugsziel bist Du denn gerade?", "en.At which place are you at the moment?", "lena.checkin.whichplace"));
	}

	renderUserSelection(contextData)
	{
		return this.renderUserMsg(contextData["checkinActivity"].title, contextData);
	}

	renderUserOptions(contextData)
	{
		if (contextData.progress && !contextData.checkInItems)
		{
			return (<div className="loadingCheckIn"> <CircularProgress /></div>);
		}

		if (!contextData.checkInItems)
			return this.renderAnswerOptions(contextData, this.answerOptions);

		var forceIncludes = [];
		contextData.checkInItems.forEach(element => {
			forceIncludes.push(element.id);
		});

		return (
			<div>
				<Map
					id="checkinMapContainer"
					gestureHandling="cooperative"
					forceInclude={forceIncludes}
					zoomFactor={15}
					maxNumCategories={1}
					contentMode={"supertiny"}
					disableBottomClick={true}
					disableFilterButton={true}
					mapSimpleMode={true}
					ignoreActiveFilter={true}
					loadOnVisibility={false}
					centerLat={contextData.currentLocation.latitude}
					centerLng={contextData.currentLocation.longitude}
					onInit={(instance) => this.map = instance}
					onContentClicked={this.OnActivityClicked(contextData)}
				/>
				{/*this.renderAnswerOptions(contextData, this.answerOptions)*/}
			</div>
		);
	}
}
