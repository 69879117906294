/* eslint-disable jsx-a11y/href-no-hash */
import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';

const styles = theme => ({	
});

class EmailLink extends React.Component
{
	OnEmail = (p0, p1) => () =>
	{
		window.location.href = 'mailto:' + p0 + '@' + p1;
	}

	render()
	{
		var email = this.props.email;
		if (!email)
			return undefined;

		var parts = email.split('@');

		var txt;
		if (this.props.icon)
			txt = this.props.icon;
		else
			txt = (
				<span>
					<span>{parts[0]}</span>
					&#x40;
					<span>{parts[1]}</span>
				</span>
			);

		return (
			<a className={this.props.className} href="#" onClick={this.OnEmail(parts[0], parts[1])}>
				{txt}
			</a>
		);
	}
}

EmailLink.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(EmailLink);