import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import Translate from '../Translate';
import Tracking from '../Tracking';
import UserReview from '../components/UserReview';
import Rating from '../components/Rating';
import AppState from '../AppState';

const styles = {
	addReviewButton: {
		color: 'white',
		backgroundColor: '#e31c46',
	}
};

@observer
class AllReviewsPage extends React.Component
{
	componentDidMount()
	{
		Tracking.OnPageView("allreviews");
	}

	render()
	{
		Tracking.OnRender("AllReviewsPage");

		var reviews = [];
		if (this.props.reviews !== undefined)
		{
			for (var i = 0; i < this.props.reviews.length; ++i)
			{
				reviews.push(
					<UserReview key={i} activity={this.props.activity} review={this.props.reviews[i]} collapsedText={false} />
				);
			}
		}

		var scrollProp = {};
		if (!AppState.instance.deviceInfo.ios)
		{
			scrollProp = {
				overflowY: 'auto'
			};
		}

		return (
			<div className="allReviewsPage" key="allReviewsPage" style={scrollProp}>
				{this.props.reviews && <div className="detailSection detailSectionReviews">
					<div className="detailSectionTitle detailSectionTitleReviews">
						{Translate.T("de.Bewertungen", "en.Reviews", "detail.reviews.title")}
					</div>
					{this.props.activity.rating && <div className="ratingContainer">
						<Rating value={this.props.activity.rating} starColor="#e31c46" />
					</div>}
					<div className="detailReviews">
						{reviews}
					</div>
				</div>}
			</div>
		);
	}
}

AllReviewsPage.propTypes = {
	classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(AllReviewsPage);