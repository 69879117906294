import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import Snackbar from 'material-ui/Snackbar';
import IconButton from 'material-ui/IconButton';
import CloseIcon from 'material-ui-icons/Close';
import AppBar from 'material-ui/AppBar';
import Toolbar from 'material-ui/Toolbar';
import Typography from 'material-ui/Typography';
import Dialog, {
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from 'material-ui/Dialog';
import AppState from '../AppState';
import Slide from 'material-ui/transitions/Slide';
import Button from 'material-ui/Button';
import TextField from 'material-ui/TextField';

const styles = {
	close: {
		width: 32,
		height: 32,
	},
	/*appBar: {
		position: 'relative',
	},*/
	dialogTitle: {
		marginLeft: '8px',
	},
	closeButtonRoot: {
		width: '38px',
		height: '38px',
		marginLeft: '-4px',
	},
	alertDialog: {
		zIndex: 9999
	}
};

function Transition(props)
{
	return <Slide direction="down" {...props} />;
}

@observer
class ScreenMessage extends React.Component
{
	message;
	@observable isShown = false;

	dialogTitle;
	dialogContent;
	dialogClassName;
	hideHeaderBar;
	@observable dialogOpen = false;
	topIcon;
	onTopIconPressed;
	headerBar = true;

	@observable alertProps = {};
	@observable alertOpen = false;

	componentDidMount()
	{
		AppState.instance.screenMessage = this;
	}

	Show(msg)
	{
		this.message = msg;
		this.isShown = true;
	}

	ShowDialog(title, comp, compInstance, topIcon, onTopIconPressed, props, headerBar, dialogClassName, hideHeaderBar)
	{
		if (this.dialogOpen)
		{
			this.CloseDialog();
		}

		this.dialogTitle = title;
		if (compInstance === undefined)
		{
			var _props = props || {};
			_props.id = 'generalDialogContent';
			this.dialogContent = React.createElement(comp, _props, null);
		}
		else
			this.dialogContent = compInstance;
		this.topIcon = topIcon;
		this.onTopIconPressed = onTopIconPressed;

		this.dialogClassName = dialogClassName;
		this.headerBar = headerBar === undefined ? true : headerBar;
		this.hideHeaderBar = hideHeaderBar;

		this.dialogOpen = true;
	}

	OnRequestClose = (event) =>
	{
		this.isShown = false;
	}

	HandleDialogRequestClose = () =>
	{
		if (this.onTopIconPressed)
		{
			var continueClose = this.onTopIconPressed();
			if (continueClose !== undefined && continueClose === false)
				return;
		}
		this.CloseDialog();
	}

	CloseDialog()
	{
		this.dialogOpen = false;
	}

	IsDialogOpen()
	{
		return this.dialogOpen;
	}

	ShowAlert(title, text, buttons, allowHide, onResult)
	{
		this.alertProps = {
			title: title,
			mode: "alert",
			text: text,
			buttons: buttons,
			allowHide: allowHide,
			onResult: onResult
		};		
		this.alertOpen = true;
	}

	ShowTextEdit(title, text, lines, buttons, allowHide, onResult, placeholder)
	{
		this.alertProps = {
			title: title,
			mode: "edit",
			text: text,
			lines: lines,
			buttons: buttons,
			allowHide: allowHide,
			onResult: onResult,
			placeholder: placeholder
		};		
		this.alertOpen = true;
	}

	OnAlertTextChanged = (event) =>
	{
		const text = event.target.value;
		this.alertProps.changedText = text;
	}

	OnAlertClose = () =>
	{
		if (this.alertProps.allowHide)
		{
			this.alertOpen = false;
			if (this.alertProps.onResult)
			{
				this.alertProps.onResult(undefined);
				this.alertProps.onResult = undefined;
			}
		}
	}

	OnAlertButtonClicked = (index) => () =>
	{
		this.alertOpen = false;
		if (this.alertProps.onResult)
		{
			this.alertProps.onResult(this.alertProps.buttons[index], this.alertProps.changedText);
			this.alertProps.onResult = undefined;
		}
	}

	render()
	{
		var classes = this.props.classes;

		return (
			<div className="screenMessageContainer">
				<Dialog
					classes={{root: AppState.instance.isLandingPage ? classes.alertDialogLP : classes.alertDialog}}
					className={(AppState.instance.isLandingPage ? "alertDialogLP":"alertDialog") + " " + this.alertProps.mode}
					open={this.alertOpen}
					transition={Transition}
					keepMounted
					onClose={this.OnAlertClose}
				>
					{this.alertProps.title !== undefined &&
						<DialogTitle id="alert-dialog-slide-title">
							{this.alertProps.title}
						</DialogTitle>}

					{this.alertProps.text !== undefined && this.alertProps.mode === "alert" &&
						<DialogContent>
							<DialogContentText id="alert-dialog-slide-description">
								{this.alertProps.text}
							</DialogContentText>
						</DialogContent>}

					{this.alertProps.mode === "edit" &&
						<DialogContent>
							<TextField
								key={"alertInput"}
								multiline
								rows={this.alertProps.lines}
								InputLabelProps={{
									shrink: true,
								}}
								placeholder={this.alertProps.placeholder}
								defaultValue={this.alertProps.text}
								fullWidth
								margin="dense"
								onChange={this.OnAlertTextChanged}
								autoFocus={true}
							/>
						</DialogContent>}

					<DialogActions>
						{this.alertProps.buttons && this.alertProps.buttons.map((button, index) =>
						{
							return (
								<Button key={index} onClick={this.OnAlertButtonClicked(index)} color="primary">
									{button.label}
								</Button>
							);
						})}
					</DialogActions>
				</Dialog>

				<Snackbar
					className="screenMessage"
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					open={this.isShown}
					autoHideDuration={6000}
					onClose={this.OnRequestClose}
					SnackbarContentProps={{
						'aria-describedby': 'message-id',
					}}
					message={<span id="message-id">{this.message}</span>}
					action={[
						<IconButton
							key="close"
							aria-label="Close"
							color="inherit"
							className={classes.close}
							onClick={this.OnRequestClose}
						>
							<CloseIcon />
						</IconButton>,
					]}
				/>
				
				<Dialog
					fullScreen
					open={this.dialogOpen}
					onClose={this.HandleDialogRequestClose}
					className={(AppState.instance.isLandingPage ? "screenMessageDialogLP" : "screenMessageDialog") +
						(this.dialogClassName ? (" " + this.dialogClassName) : "") +
						(AppState.instance.GetPlatformClasses())}
				>
					{this.headerBar && <AppBar className={classes.appBar}>
						<Toolbar>
							<IconButton color="contrast" onClick={this.HandleDialogRequestClose} aria-label="Close"
								classes={{root: classes.closeButtonRoot}}
							>
								{this.topIcon === undefined && <CloseIcon />}
								{this.topIcon}
							</IconButton>
							<Typography type="title" color="inherit" className={classes.dialogTitle}>
								{this.dialogTitle}
							</Typography>
						</Toolbar>
					</AppBar>}
					{this.headerBar && <div className="screenMessageHeaderBarBackground" style={{height: 62}} />}

					{!this.headerBar && !this.hideHeaderBar && <div style={{position: 'absolute', top: 0, left: 0}}>
						<IconButton color="contrast" onClick={this.HandleDialogRequestClose} aria-label="Close"
							classes={{root: classes.closeButtonRoot}}
						>
							{this.topIcon === undefined && <CloseIcon />}
							{this.topIcon}
						</IconButton>
					</div>}

					{this.dialogContent}
				</Dialog>

			</div>
		);
	}
}

ScreenMessage.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ScreenMessage);