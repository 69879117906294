import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import Translate from '../Translate';
import Utils from '../Utils';
import Button from 'material-ui/Button';
import AccountRequestPage from '../pages/AccountRequestPage';
import raf from 'raf';

const styles = theme => ({	
});

@observer
class RegisterCard extends React.Component
{
	static OnThrow()
	{
		console.log("RegisterCard.OnThrow");
	}

	OnRegisterClicked = (e) =>
	{
		e.preventDefault();
		e.stopPropagation();

		raf(() =>
		{
			AccountRequestPage.Show();
		});
	}

	render()
	{
		return (
			<div className="registerCard">

				<div className="registerCardHeader">
					<div className="intro_logocontainer">
						{Utils.RenderLogo(40, 40, "white", "#e31c46")}
					</div>

					<div className="registerCardIntro problem">
						{Translate.T("de.Gut gemacht! Du hast schon einige tolle Ausflugsziele für Deine Familie gesammelt.",
							"en.Well done! You already collected some great things to do for your family.",
							"registercard.intro")}
					</div>
				</div>

				<div className="registerCardBody">

					<div className="registerCardIntro statement">
						{Translate.T("de.Um sie nicht zu verlieren, kannst Du Dich in Windeseile kostenlos registrieren!",
							"en.Register your account for free to not lose them!",
							"registercard.register")}
					</div>

					<div className="registerCardLena">
						LENA
					</div>

					<div className="registerCardButtonContainer">
						<Button onClick={this.OnRegisterClicked} className="registerCardBtn">
							{Translate.T("en.Register now", "de.Jetzt Registrieren", "registercard.cta")}
						</Button>
					</div>
					
				</div>

			</div>
		);
	}
}

RegisterCard.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(RegisterCard);