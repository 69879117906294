import React from 'react';
import Translate from '../../Translate';
import StepBase from './StepBase';
import TextField from 'material-ui/TextField';
import Button from 'material-ui/Button';
import RestApi from '../../RestApi';

export default class StepFeedback extends StepBase
{
	GetId()
	{
		return "feedback";
	}

	GeneratedDataIds()
	{
		return ["feedback"];
	}

	Undo(contextData)
	{
		delete contextData["feedback"];
		delete contextData["feedback_temp"];
	}

	renderStepLena(contextData)
	{
		return this.renderLenaMsg(Translate.T("de.Ich liebe Feedback! Immer her damit!", "en.I love feedback! Bring it on!", "createxp.givefeedback"));
	}

	renderUserSelection(contextData)
	{
		var text = contextData["feedback"];
		if (!text)
			text = Translate.T("de.[Leer]", "en.[Empty]", "feedback.empty");
		return this.renderUserMsg(text, contextData);
	}

	OnTextChanged = (contextData) => (event) =>
	{
		contextData["feedback_temp"] = event.target.value;
		contextData.onChanged();
	}

	OnFeedbackDone = (contextData) => () =>
	{
		const feedback = contextData["feedback_temp"];
		delete contextData["feedback_temp"];

		if (feedback && feedback.length > 0)
			this.SendFeedback(feedback);

		this.OnStepDone(contextData, {
			feedback: feedback,
		});
	}

	SendFeedback(text)
	{
		RestApi.SendRequest("/feedback", {text: text});
	}

	renderUserOptions(contextData)
	{
		const defaultText = contextData["feedback_temp"];

		return (
			<div className="stepFeedback">

				<div className="desc">
					<TextField
						key={"descText"}
						multiline
						required={true}
						InputLabelProps={{
							shrink: true,
						}}
						placeholder={Translate.FindTranslation("lena.feedback.placeholder")}
						defaultValue={defaultText}
						fullWidth
						autoFocus={true}
						margin="dense"
						onChange={this.OnTextChanged(contextData)}
					/>
				</div>

				<div className="continue">
					<Button onClick={this.OnFeedbackDone(contextData)}>
						{Translate.T("de.Senden", "en.Send", "send")}
					</Button>
				</div>
			</div>
		);
	}
}
