import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import AppState from '../../AppState';
import { Dialog } from 'material-ui';

const styles = theme => ({
});

@observer
class AdvCardPopup extends React.Component
{
	advManager;

	constructor(props)
	{
		super(props);

		this.advManager = AppState.instance.advManager;
	}

	componentDidMount()
	{
		this.advManager.onConfigChanged.Add(this.OnConfigChanged);
		this.advManager.onInstancesChanged.Add(this.OnInstancesChanged);
	}

	componentWillUnmount()
	{
		this.advManager.onConfigChanged.Remove(this.OnConfigChanged);
		this.advManager.onInstancesChanged.Remove(this.OnInstancesChanged);
	}

	OnConfigChanged = () =>
	{
		this.forceUpdate();
	}

	OnInstancesChanged = () =>
	{
		this.forceUpdate();
	}

	OnCardClicked = (e) =>
	{
		e.stopPropagation();

		if (this.props.onClick)
			this.props.onClick(this.props.instanceId, e);
	}

	render()
	{
		const instanceId = this.props.instanceId;
		if (instanceId === undefined)
			return null;

		var instance = this.advManager.GetCardInstance(instanceId);
		if (!instance)
			return null;

		var instances = this.advManager.GetCardInstances(instance.cardId);

		const card = this.advManager.GetCardById(instance.cardId);

		const cardImages = this.advManager.GetCardImages(card.id);
		const cardImage = cardImages.length > 0 ? cardImages[0] : {};

		return (
			<Dialog open={true} onClose={this.props.onClose}
				className={"cardInstancePopup " + AppState.instance.GetPlatformClasses()}
			>
				<div
					className={"advcard instance"} key={card.id}
					onClick={this.OnCardClicked}
				>
					<div
						className="cardImage"
						style={{backgroundImage: 'url("' + cardImage.content + '")'}}
					/>
					<div className="title">{card.title}</div>
					<div className="category">{card.category}</div>
					{instances && this.props.showNumInstances && <div className="numInstances">{instances.length}</div>}
				</div>
			</Dialog>
		);
	}
}

AdvCardPopup.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AdvCardPopup);