import React from 'react';
import {observer} from 'mobx-react';
import Utils from '../Utils';
//import {observable} from 'mobx';
//import Utils from '../Utils';
import $ from 'jquery';
//import PropTypes from 'prop-types';
import MainPage from '../pages/MainPage';
import iScroll from 'iscroll';

@observer
export default class HeaderBar extends React.Component
{
	static instance = null;

	activeParent;
	position = -1000; // current position in pixels
	metaPosition = 0; // current meta position (0, 1 or 2), or the new position the bar is moving towards
	oldScrollTop = 0;
	oldDelta = 0;
	significantDeltaSum = 0;
	resetDeltaSumAt = new Date();
	startPullEnabled = true;
	endPullEnabled = true;
	wasOverStart = false;
	wasOverEnd = false;
	fullVisibilityEnabled = false;
	animationDuration = 300;
	heightCache;
	scrollStopsCache;
	scrollerHeightCache;
	clientHeightCache;
	isInAutoMode = false;
	scrollDistanceSinceStart = 0;

	componentDidMount = () =>
	{
		HeaderBar.instance = this;

		this.AddEvent();
		if (this.props.height !== undefined)
		{
			this.position = - this.props.height;
		}
		else
		{
			if (this.props.stayFixed === true)
			{
				this.position = 0; //TODO: start with headerbar all visible?
			}
			else
			{
				this.position = 0;
			}
		}

		console.log("headerbar init: " + this.position);
	}

	componentWillUnmount = () =>
	{
		this.RemoveEvents();
	}

	RemoveEvents()
	{
		if (this.activeParent !== undefined)
		{
			this.activeParent.removeEventListener('scroll', this.OnScroll);
			this.activeParent = undefined;
		}
	}

	AddEvent = () =>
	{
		var parent = this.props.parent();

		if (this.activeParent !== parent)
		{
			if (this.activeParent)
			{
				this.RemoveEvents();
			}

			if (parent !== undefined)
			{
				console.log("event ADDED");
				parent.addEventListener('scroll', this.OnScroll);

				this.activeParent = parent;
				this.significantDeltaSum = 0;
			}
		}
		
		setTimeout(this.AddEvent, 100);
	}

	OnPulledAtStart()
	{
		if (!this.fullVisibilityEnabled)
			return;

		const metaPos = this.GetMetaPosition();
		if (metaPos === 2) // Already fully visible
			return;
		//console.log("pulled at start");

		this.SetMetaPosition(metaPos + 1);
	}

	OnPulledAtEnd()
	{
		const metaPos = this.GetMetaPosition();
		if (metaPos !== 0)
			this.SetMetaPosition(0);

		if (this.props.onPulledAtEnd)
			this.props.onPulledAtEnd();
	}

	OnSmallScroll(isDown)
	{
		const metaPos = this.GetMetaPosition();

		var newPos = metaPos + (isDown ? 1 : -1);
		if (newPos < 0 || newPos > 2)
			return;

		if (newPos === 2)
			return; // Full visibility can only be triggered by pull-at-start

		this.SetMetaPosition(newPos);
	}

	OnBigScroll(isDown)
	{
		this.OnSmallScroll(isDown);
	}

	OnScrollingContentChanged()
	{
		this.scrollerHeightCache = undefined;
		this.clientHeightCache = undefined;
	}

	SetMetaPosition(pos, preCalcYPos, animate)
	{
		if (pos < 0 || pos > 2)
		{
			console.log("invalid pos: " + pos);
			return;
		}
		//console.log("NEW META POS: " + pos);

		if (this.metaPosition === pos)
			return;

		this.heightCache = undefined;
		this.scrollStopsCache = undefined;

		const stops = HeaderBar.instance.CalcScrollStops();
		const height = HeaderBar.instance.CalcHeight();
		const lastHeight = stops[stops.length - 1];

		var yPos;
		if (pos === 0)
		{
			yPos = - height;
		}
		else if (pos === 1)
		{
			yPos = - height + lastHeight;
		}
		else
		{
			yPos = 0;
		}

		if (preCalcYPos !== undefined)
			yPos = preCalcYPos;

		var info = {
			height: height,
			stops: stops,
			lastHeight: lastHeight,
			visibleHeight: height + yPos,
			top: yPos,
			metaPos: pos,
			oldMetaPos: this.metaPosition
		};

		if (this.props.onPositionChange !== undefined)
			if (this.props.onPositionChange(info) === false)
				return;

		this.metaPosition = pos;
		var jump = false;
		if (animate !== undefined && animate === false)
			jump = true;
		this.AnimateTo(yPos, jump);
	}

	AnimateTo(pos, jump)
	{
		var domElement = document.getElementById('headerBar');

		if (jump === true)
		{
			Utils.SetTransitionTime(domElement, 0);
		}
		else
		{
			Utils.SetTransitionTime(domElement, 0.3);
		}
		
		var translateValue = "translate(0, " + pos + "px)";
		Utils.SetTransformValue(domElement, translateValue);

		var thisPtr = this;
		var timeout = 350;
		if (jump === true)
			timeout = 50;
		setTimeout(function()
		{
			thisPtr.position = pos;
			MainPage.OnContentChanged();
		}, timeout);
	}

	OnScrollStart = (scrollTop) =>
	{
		//console.log("START ----------------------");
		this.significantDeltaSum = 0;
		this.startPullEnabled = true;
		this.endPullEnabled = true;
		this.oldScrollTop = scrollTop;
		this.oldDelta = 0;
		this.scrollDistanceSinceStart = 0;
	}

	OnScrollEnd = (scrollTop) =>
	{
		//console.log("END ----------------------");
		this.significantDeltaSum = 0;
		this.startPullEnabled = true;
		this.endPullEnabled = true;
		this.wasOverEnd = false;
		this.wasOverStart = false;
		this.oldScrollTop = scrollTop;

		if (Math.abs(this.oldScrollTop) < 5)
			this.fullVisibilityEnabled = true;
		else
			this.fullVisibilityEnabled = false;
	}

	OnScroll = (scrollTop, iScrollInstance) =>
	{
		if (this.isInAutoMode === true)
			return;

		var thisPtr = this;

		if (this.props.onBeforeScroll !== undefined)
		{
			if (this.props.onBeforeScroll(scrollTop) === false)
			{
				//this.oldScrollTop = scrollTop;
				this.isInAutoMode = true;
				iScrollInstance.scrollTo(0, this.oldScrollTop, 50, iScroll.utils.ease.circular);
				
				setTimeout(function()
				{
					thisPtr.isInAutoMode = false;
				}, 100);

				this.significantDeltaSum = 0;
				return;
			}
		}

		var delta = this.oldScrollTop - scrollTop;
		this.scrollDistanceSinceStart += Math.abs(delta);

		//console.log("delta: " + delta);
		//console.log("    distance: " + this.scrollDistanceSinceStart);

		// Don't scroll content when headerbar fully visible and user wants to close headerbar
		//const metaPos = this.GetMetaPosition();
		if (this.position > -1) // metaPos == 2
		{
			if (delta < 0 && this.scrollDistanceSinceStart < 50)
			{
				this.SetMetaPosition(1);
				this.isInAutoMode = true;
				iScrollInstance.scrollTo(0, this.oldScrollTop, 50, iScroll.utils.ease.circular);
				
				setTimeout(function()
				{
					thisPtr.isInAutoMode = false;
				}, 100);
			}
			return;
		}

		//console.log("scrollTop: " + scrollTop);
		//console.log("  toolbarPosition: " + this.position);
		
		var isSignificant = false;
		if (delta < 0 || Math.abs(delta) > 5)
			isSignificant = true;

		const clientHeight = this.getClientHeight();
		const scrollerHeight = this.getScrollerHeight();

		if (this.wasOverEnd || this.wasOverStart)
		{
			// Don't move headerbar because of bounce-back animation of scroller
			isSignificant = false;
		}

		if (isSignificant)
			this.significantDeltaSum += delta;

		if ((delta < 0 && this.oldDelta > 0) ||
			(delta > 0 && this.oldDelta < 0))
		{
			// Scrolling direction changed -> reset sum
			this.significantDeltaSum = 0;
		}

		if (scrollTop < 0)
		{
			this.wasOverStart = true;
			
			// Scrolled to top of container -> move headerbar one step down
			if (scrollTop < -40 && this.startPullEnabled)
			{
				//console.log("OVER START");

				this.startPullEnabled = false;
				this.OnPulledAtStart();
			}
		}
		else if (scrollTop > clientHeight - scrollerHeight)
		{
			//console.log("pulled at end: " + overEnd);
			this.wasOverEnd = true;

			const overEnd = scrollTop - (clientHeight - scrollerHeight);
			if (overEnd > 20 && this.endPullEnabled)
			{
				this.endPullEnabled = false;
				//console.log("OVER END");
				this.OnPulledAtEnd();
			}
		}

		//console.log("    deltasum: " + this.significantDeltaSum);

		if (Math.abs(this.significantDeltaSum) > 40)
		{
			//console.log("BIG SIG");
			this.OnBigScroll(this.significantDeltaSum > 0);
			this.significantDeltaSum = 0;
		}
		else if (this.significantDeltaSum < -5 || this.significantDeltaSum > 15)
		{
			//console.log("SMALL SIG");
			this.OnSmallScroll(this.significantDeltaSum > 0);
			this.significantDeltaSum = 0;
		}

		//console.log("CURRENT: " + $(".headerBar").offset().top + " | toolbarPosition: " + this.position);
			
		this.oldDelta = delta;
		this.oldScrollTop = scrollTop;
	}

	// Data fetchers:

	CalcHeight()
	{
		if (this.heightCache !== undefined)
			return this.heightCache;

		if (this.props.height !== undefined)
			this.heightCache = this.props.height;
		else
			this.heightCache = $("#headerBar").outerHeight();

		return this.heightCache;
	}

	CalcScrollStops()
	{
		if (this.scrollStopsCache !== undefined)
			return this.scrollStopsCache;

		var result = [];
		var stops = $("#headerBar .scrollStop");
		for (var i = 0; i < stops.length; ++i)
		{
			result.push($(stops[i]).outerHeight());
		}

		if (result.length === 0)
			result.push(this.CalcHeight());

		this.scrollStopsCache = result;
		return result;
	}

	GetMetaPosition()
	{
		//0 == invisible
		//1 == only last row visible
		//2 == fully visible, including mktClaim or Filter
		return this.metaPosition;
	}

	getClientHeight()
	{
		if (this.clientHeightCache !== undefined)
			return this.clientHeightCache;

		if (this.props.content !== undefined)
		{
			var content = this.props.content();
			this.clientHeightCache = $(content).outerHeight();
		}
		else
		{
			this.clientHeightCache = undefined;
		}

		return this.clientHeightCache;
	}

	getScrollerHeight()
	{
		if (this.scrollerHeightCache !== undefined)
			return this.scrollerHeightCache;

		if (this.props.content !== undefined)
		{
			var content = this.props.content();
			this.scrollerHeightCache = $(content).parent().outerHeight();
		}
		else
		{
			this.scrollerHeightCache = undefined;
		}

		return this.scrollerHeightCache;
	}

	render()
	{
		return (
			<div className="headerBar" id="headerBar">
				{this.props.children}
			</div>
		);
	}
}
