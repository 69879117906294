import {observable} from 'mobx';
import Utils from '../Utils';

export default class Trip
{
	@observable id;
	@observable title = "";
	@observable desc = "";
	@observable date = new Date();
	@observable activities = [];  //.title, .startTime, .duration
	@observable active = false;
	@observable startTime = 9 * 60;
	@observable pictures = [];
	@observable activityPictures = [];

	FromObject(obj)
	{
		for (var prop in obj)
			this[prop] = obj[prop];
	}

	static IsEmpty(trip)
	{
		if (trip.title && trip.title.length > 0)
			return false;
		if (trip.desc && trip.desc.length > 0)
			return false;
		if (trip.activities && trip.activities.length > 0)
			return false;
		if (trip.pictures && trip.pictures.length > 0)
			return false;
		return true;
	}

	static GenerateId()
	{
		return Utils.CreateGuid();
	}

	static UpdateTimes(trip)
	{
		for (var i = 0; i < trip.activities.length; ++i)
		{
			var a = trip.activities[i];
			if (i === 0)
			{
				a.startTime = trip.startTime;
			}
			else
			{
				a.startTime = trip.activities[i - 1].startTime + trip.activities[i - 1].duration;
				a.startTime = Math.ceil(a.startTime / 15) * 15;
			}

			//console.log(i + ": " + a.title + ": START: " + a.startTime + ", DURATION: " + a.duration);
		}
	}

	static AddOrRemoveActivity(trip, title, duration)
	{
		var wasRemoved = false;
		for (var i = 0; i < trip.activities.length; ++i)
		{
			if (trip.activities[i].title === title)
			{
				wasRemoved = true;
				trip.activities.splice(i, 1);
				break;
			}
		}

		if (!wasRemoved)
		{
			trip.activities.push({title: title, startTime: 0, duration: duration ? duration : 120});
		}
		Trip.UpdateTimes(trip);
	}

	static RemoveItem(trip, index)
	{
		trip.activities.splice(index, 1);
		Trip.UpdateTimes(trip);
	}

	static RemoveNoneTitles(trip)
	{
		for (var i = trip.activities.length - 1; i >= 0; --i)
		{
			if (!trip.activities[i].title)
			{
				trip.activities.splice(i, 1);
			}
		}
	}
}