import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import ContentItem from './ContentItem';
import HorizontalScroller from './HorizontalScroller';
import AppState from '../AppState';
import VisibilitySensor from 'react-visibility-sensor';
import ContentGrid from './ContentGrid';
import { Button } from 'material-ui';
import Translate from '../Translate';
import { observable } from 'mobx';

const styles = theme => ({
	root: {
		backgroundColor: theme.palette.background.paper,
	},
});

@observer
class ContentList extends React.Component
{
	totalItemCount = 0;
	totalItemCountExclPlaceholders = 0;
	isLoading = 0;
	@observable isEod = false;

	componentDidMount()
	{
		AppState.instance.contentStore.AddOnDataChanged(this.props.categoryIds, this.OnDataChanged);
	}

	componentWillUnmount()
	{
		AppState.instance.contentStore.RemoveOnDataChanged(this.props.categoryIds, this.OnDataChanged);
	}

	OnDataChanged = (action, newData, maxTotal) =>
	{
		//console.log("OnDataChanged: " + action + ", " + maxTotal + ", " + this.props.categoryIds);
		if (action === 'request')
		 	++this.isLoading;
		else if (action === 'data' || action === 'error' || action === 'abort' || action === 'eod')
		{
			this.isLoading = 0;
			this.forceUpdate();

			if (action === 'eod')
				this.isEod = true;
		}
	}

	OnVisibilitySensor = (isVisible) =>
	{
		//console.log("ContentList.OnVisibilitySensor: " + isVisible + ", " + this.isLoading);
		if (this.props.autoloadMore === false)
			return;

		if (isVisible)
			this.doLoadMore();
	}

	OnManualLoadMore = () =>
	{
		if (!this.isEod)
			this.doLoadMore();
	}

	doLoadMore()
	{
		if (this.isLoading === 0)
		{
			++this.isLoading;
			AppState.instance.contentStore.RequestMoreContent(this.props.categoryIds, this.props.childIndex);
		}
	}

	handleLazyLoading(contentItem)
	{
		var renderPlaceholder = false;

		if (contentItem)
		{
			if (contentItem.id !== undefined && contentItem.onLoad)
			{
				if (!contentItem.loaded)
				{
					if (!contentItem.loadRequested)
					{
						contentItem.loadRequested = true;
						contentItem.onLoad(contentItem)
							.then((data) =>
							{
								data.loaded = true;
								this.forceUpdate();
							});
					}
					renderPlaceholder = true;
				}
			}
		}

		return renderPlaceholder;
	}

	renderItem(contentItem, index, forcedKey)
	{
		const isFirst = index === 0;
		const isLast = index === this.totalItemCount - 1;

		var renderPlaceholder = this.handleLazyLoading(contentItem);

		if (contentItem === null || renderPlaceholder)
		{
			return (
				<ContentItem
					contentData={{
						id: contentItem === null ? 0 : contentItem.id
					}}
					itemType="placeholder"
					key={forcedKey ? forcedKey : index}
					renderMode={this.props.horizontal ? 'small' : 'medium'}
					isLast={isLast}
					isFirst={isFirst}/>
			);	
		}

		// if (contentItem === null)
		// {
		// 	contentItem = {
		// 		id: "",
		// 		desc: "",
		// 		title: "",
		// 		categories: [],
		// 		img: "/assets/placeholder.svg",
		// 		distance: { distanceText: ""},
		// 		address: "",
		// 		rating: 0,
		// 		url : "#",
		// 		type: "searchresult placeholder"
		// 	};
		// }

		if (this.props.contentOnly && contentItem.type !== "content")
			return null;

		return (
			<ContentItem
				contentData={contentItem}
				itemType={contentItem.type}
				categoryIds={contentItem.type === "filter" ? this.props.categoryIds : undefined}
				key={forcedKey ? forcedKey : index}
				renderMode={this.props.renderMode ? this.props.renderMode : (this.props.horizontal ? 'small' : 'medium')}
				onClick={this.props.onContentClick}
				isLast={isLast}
				isFirst={isFirst}/>
		);
	}

	render()
	{
		var maxCount = this.props.maxCount || (AppState.instance.deviceInfo.desktop ? 8 : 6);
		if (!this.props.horizontal)
			maxCount = -1;

		var items = [];

		var queries = [];

		if (this.props.itemType === 'searchresult')
		{
			// Add some past search queries
			queries = AppState.instance.userProfile.GetSearchQueries(AppState.instance.contentStore.searchQuery, 3);
		}

		var numAddItems = this.props.maxTotal;
		if (numAddItems > 10)
			numAddItems = 10;
		if (this.props.horizontal)
			numAddItems = 0;

		var index = 0;
		this.totalItemCountExclPlaceholders = queries.length + this.props.contentData.length;
		this.totalItemCount = this.totalItemCountExclPlaceholders + numAddItems;

		const useContentGrid = this.props.itemType !== 'searchresult' && this.props.renderMode !== "searchresult" && AppState.instance.deviceInfo.desktop;

		for (; index < queries.length; ++index)
		{
			var d = {
				id: index,
				title: queries[index],
				type: 'query',
				url: '/search?q=' + queries[index],
				img: '/assets/search.svg'
			};

			if (useContentGrid)
				items.push(d);
			else
				items.push(this.renderItem(d, items.length, queries[index] + "_" + index));
		}

		for (; (index - queries.length) < this.props.contentData.length; ++index)
		{
			var i = index - queries.length;

			if (useContentGrid)
			{
				items.push(this.props.contentData[i]);
				this.handleLazyLoading(this.props.contentData[i]);
			}
			else
				items.push(this.renderItem(this.props.contentData[i], items.length));

			if (items.length === maxCount)
				break;
		}

		if (this.props.horizontal)
		{
			if (useContentGrid)
			{
				return (<ContentGrid
					content={items}
					minContentWidth={250}
					maxContentWidth={450}
					idealNumContentPerRow={4}
					contentSpacing={16}
					onContentClick={this.props.onContentClick}
				/>);
			}
			else
			{
				return (
					<HorizontalScroller items={items} allowIScroll={this.props.allowIScroll}>
						{items}
					</HorizontalScroller>
				);
			}
		}
		else
		{
			var placeholders = [];
			for (i = 0; i < numAddItems; ++i)
			{
				placeholders.push(this.renderItem(null, index + i));
			}

			var isEmpty = true;
			if (this.props.contentData && this.props.contentData.length > 0)
			{
				if (this.props.contentData[0] !== null)
					isEmpty = false;
			}

			return (
				<div key={this.props.key} className={"searchResultVertical" + (isEmpty ? " empty":" filled")}>

					{!useContentGrid && items}
					{useContentGrid && <ContentGrid
						content={items}
						minContentWidth={250}
						maxContentWidth={400}
						idealNumContentPerRow={4}
						contentSpacing={16}
						onContentClick={this.props.onContentClick}
					/>}

					{this.props.autoloadMore === false && !this.isEod &&
						<div className="manualLoadMore">
							<Button onClick={this.OnManualLoadMore}>
								{Translate.T("de.Mehr anzeigen", "en.Show more", "results.manualloadmore")}
							</Button>
						</div>}

					{placeholders.length > 0 && this.props.autoloadMore !== false &&
						<VisibilitySensor
							onChange={this.OnVisibilitySensor}
							key={"placeholderSensor" + this.totalItemCountExclPlaceholders + "_" + numAddItems}
							active={true}
							partialVisibility={true}
						>
							<div style={{width: 10, height: 10}}>
								{placeholders}
							</div>
						</VisibilitySensor>}
				</div>
			);
		}
	}
}

ContentList.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	// categoryIds: PropTypes.oneOfType([
	// 	PropTypes.string,
	// 	PropTypes.number]).isRequired,
	itemType: PropTypes.string.isRequired,
	maxTotal: PropTypes.number.isRequired,
	horizontal: PropTypes.bool.isRequired,
	key: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(ContentList);