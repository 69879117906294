import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import AppState from '../../AppState';
import Utils from '../../Utils';
import Map from '../Map';
import Translate from '../../Translate';
import Button from 'material-ui/Button';

const styles = theme => ({
});

@observer
class AdvMap extends React.Component
{
	advManager;
	map;

	constructor(props)
	{
		super(props);

		this.advManager = AppState.instance.advManager;
	}

	componentDidMount()
	{
		this.advManager.onConfigChanged.Add(this.OnConfigChanged);
		this.advManager.onInstancesChanged.Add(this.OnInstancesChanged);
	}

	componentWillUnmount()
	{
		this.advManager.onConfigChanged.Remove(this.OnConfigChanged);
		this.advManager.onInstancesChanged.Remove(this.OnInstancesChanged);
	}

	OnConfigChanged = () =>
	{
		this.forceUpdate();
	}

	OnInstancesChanged = () =>
	{
		this.forceUpdate();
	}

	CreateMapQuery = () =>
	{
		var loc = AppState.instance.GetBestLocation();

		var query = {};
		
		query.location = loc;
		query.referenceLocation = loc;

		query.language = AppState.instance.userProfile.userLang;

		query.q = undefined;
		
		return query;
	}

	GetMarkerIcon = (item, selected) =>
	{
		var result = {};

		const iconSize = 24;

		// if (item.profilePicture)
		// {
		// 	result.url = item.profilePicture;
		// }
		// else
		// {
			if (selected)
				result.url = Utils.RenderStarUrl(iconSize, iconSize, "white", "#e31c46");
			else
				result.url = Utils.RenderStarUrl(iconSize, iconSize, "#e31c46", "white");
		// }

		result.size = [iconSize, iconSize];
		result.origin = [0, 0];
		result.anchor = [-iconSize / 2, -iconSize / 2];

		return result;
	}

	OnMarkerClicked = (marker) =>
	{
	}

	OnCheat = () =>
	{
		const target = this.props.target;
		var lat = target.values[0];
		var lng = target.values[1];

		var location = {
			latitude: lat,
			longitude: lng
		};
		this.advManager.OnUserLocationChanged(this.props.advInstanceId, location);
	}

	render()
	{
		const target = this.props.target;
		if (target === undefined)
			return null;

		//TODO: support polygon
		if (target.type !== 0) // circle
			return null;

		var targetCenterLat = target.values[0];
		var targetCenterLng = target.values[1];

		var markers = [];

		markers.push({
			lat: target.values[0],
			lng: target.values[1],
			type: "target"
		});

		return (
			<div
				className={"advmap" + (this.props.enableCheat ? " withCheat":"")}
			>
				<Map
					id="advMap"
					gestureHandling="greedy"
					ignoreActiveFilter={true}
					mapSimpleMode={false}
					disableFilterButton={true}
					disableContent={true}
					disableContentStoreCache={true}
					createQuery={this.CreateMapQuery}
					apiEndpoint={"/advmap"}
					markerIcon={this.GetMarkerIcon}
					onMarkerClicked={this.OnMarkerClicked}
					onInit={(instance) => this.map = instance}
					centerLat={targetCenterLat}
					centerLng={targetCenterLng}
					additionalMarkerCoords={markers}
				/>

				{this.props.enableCheat &&
					<Button onClick={this.OnCheat}>
						{Translate.T("de.Springe zum Ziel", "en.Jump to target", "advmap.cheat")}
					</Button>}
			</div>
		);
	}
}

AdvMap.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AdvMap);