import React from 'react';

export default class Delegate extends React.Component
{
	callbacks = [];

	Add(cb)
	{
		if (cb !== undefined && cb !== null)
		{
			this.Remove(cb);
			this.callbacks.push(cb);
		}
	}

	Remove(cb)
	{
		if (cb !== undefined && cb !== null)
		{
			for (var i = 0; i < this.callbacks.length; ++i)
			{
				if (this.callbacks[i] === cb)
				{
					this.callbacks.splice(i, 1);
					break;
				}
			}
		}
	}

	Call(param0, param1, param2, param3)
	{
		for (var i = 0; i < this.callbacks.length; ++i)
		{
			this.callbacks[i](param0, param1, param2, param3);
		}
	}
}
