import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import AppState from '../AppState';
import Utils from '../Utils';
import AdvCard from '../components/adventure/AdvCard';
import Button from 'material-ui/Button';
import Translate from '../Translate';
import AdvStoryFragment from '../components/adventure/AdvStoryFragment';
import AdvManager from '../AdvManager';
import AdvCardPopup from '../components/adventure/AdvCardPopup';
import AdvMap from '../components/adventure/AdvMap';
import AdvImageSearch from '../components/adventure/AdvImageSearch';
import Tracking from '../Tracking';

const styles = theme => ({
});

@observer
class AdventureInstancePage extends React.Component
{
	advManager;

	constructor(props)
	{
		super(props);

		this.advManager = AppState.instance.advManager;
	}

	componentDidMount()
	{
		this.advManager.onConfigChanged.Add(this.OnConfigChanged);
		this.advManager.onInstancesChanged.Add(this.OnInstancesChanged);

		if (AppState.instance.GetGpsPosition())
			this.OnGeoLocation(AppState.instance.GetGpsPosition());
		//else: TODO: can't use adventure without geo location!

		AppState.instance.onGeoLocation.Add(this.OnGeoLocation);

		const instance = this.advManager.GetAdvInstance(this.props.instanceId);
		Tracking.SendEvent("AdventureInstancePage", {adventureId: instance ? instance.advId : undefined});
	}

	componentWillUnmount()
	{
		AppState.instance.onGeoLocation.Remove(this.OnGeoLocation);
		this.advManager.onConfigChanged.Remove(this.OnConfigChanged);
		this.advManager.onInstancesChanged.Remove(this.OnInstancesChanged);
	}

	OnConfigChanged = () =>
	{
		this.forceUpdate();
	}

	OnInstancesChanged = () =>
	{
		this.forceUpdate();
	}

	OnStop = () =>
	{
		this.advManager.StopAdventure(this.props.instanceId)
		.then((result) =>
		{
			AppState.instance.adventuresPageInstance.CloseInstancePage();
		})
		.catch((error) =>
		{
			//TODO:
		});
	}

	OnRestart = () =>
	{
		AppState.instance.adventuresPageInstance.CloseInstancePage();
		const instance = this.advManager.GetAdvInstance(this.props.instanceId);
		AppState.instance.adventuresPageInstance.showAdventureDetailsId = instance.advId;
	}

	OnGeoLocation = (location) =>
	{
		this.advManager.OnUserLocationChanged(this.props.instanceId, location);
	}

	OnStoryContinue = (story) => () =>
	{
		this.advManager.OnStoryFragmentContinue(this.props.instanceId);
	}

	OnCardPopupClose = (cardInstanceId) => () =>
	{
		this.CloseCardPopup(cardInstanceId);
	}

	OnCardPopupCardClick = (cardInstanceId) => () =>
	{
		this.CloseCardPopup(cardInstanceId);
	}

	CloseCardPopup(cardInstanceId)
	{
		this.advManager.OnCloseCardInstancePopup(this.props.instanceId, cardInstanceId);
	}

	render()
	{
		if (this.props.instanceId === undefined)
			return null;

		const instanceId = this.props.instanceId;

		const instance = Utils.GetById(this.advManager.instances.adventures, instanceId);
		if (!instance)
			return null;

		const action = instance.action;

		const adv = this.advManager.GetAdventureById(instance.advId);
		if (!adv)
			return null;

		const teaser = adv.teaser;

		const cardInstances = this.advManager.GetCardInstancesByAdvInstanceId(instanceId);
		
		const isUnlocked = instance ? instance.unlocked !== undefined : false;
		const isLocked = !isUnlocked;
		//const isStarted = instance ? instance.started !== undefined : false;
		const isFinished = instance ? (instance.finished !== undefined) : false;

		var locationSets = [];
		for (var i = 0; i < adv.locationSetTitles.length; ++i)
		{
			const locSet = Utils.GetByProp(this.advManager.config.locationSets, "title", adv.locationSetTitles[i]);
			if (locSet)
				locationSets.push(locSet);
		}

		var cardInstanceIdPopup;
		if (action && action.parameters && action.parameters["type"] === "cardInstance.popup")
		{
			cardInstanceIdPopup = Number(action.parameters["id"]);
		}

		var targetLocation;
		if (action && action.type === AdvManager.StoryActionTypeEnterLocation &&
			action.parameters && action.parameters["locationIndex"] !== undefined)
		{
			const locIndex = Number(action.parameters["locationIndex"]);
			const locSet = Utils.GetById(this.advManager.config.locationSets, instance.locationSetId);
			const locId = locSet.locationIds[locIndex];
			targetLocation = Utils.GetById(this.advManager.config.locations, locId);
			console.log(targetLocation);
		}

		const imageSearch = instance.imageSearch;

		return (
			<div
				className="adventureInstancePage" key={instance.id}
			>
				{teaser && teaser.pictureUrl &&
					<div
						className={"keyImage" + (isLocked ? " grayscale":"")}
						style={{backgroundImage: 'url("' + teaser.pictureUrl + '")'}}
					/>}

				<div className="title">{adv.title}</div>

				{teaser &&
					<div className="teaser">
						{teaser.text}
					</div>}

				{cardInstances &&
					<div className="cards">
						{cardInstances.map((cardInstance, index) =>
						{
							//TODO: onClick = show card popup
							return (
								<AdvCard key={cardInstance.id} cardId={cardInstance.cardId} isFirst={index === 0} isLast={index === cardInstances.length - 1}
									showTitle={true} showPlus={true} showNumInstances={false}
								/>
							);
						})}
					</div>}

				{instance.stories && instance.stories.map((story, index) =>
				{
					return (
						<AdvStoryFragment
							story={story}
							key={index}
							onContinue={action && action.type === AdvManager.StoryActionTypeUI &&
								action.parameters["type"] === "story.continue" && action.parameters["id"] === story.id ? this.OnStoryContinue(story) : undefined}
						/>
					);
				})}

				{imageSearch &&
					<AdvImageSearch
						advInstanceId={instanceId}
						data={imageSearch}
					/>}

				{targetLocation &&
					<AdvMap
						advInstanceId={instanceId}
						target={targetLocation}
						enableCheat={true}
					/>}

				<div className="instanceActions">
					{!isFinished && <Button onClick={this.OnStop}>
						{Translate.T("de.Verlasse Abenteuer", "en.Leave Adventure", "adventure.instance.stop")}
					</Button>}
					{isFinished && <Button onClick={this.OnRestart}>
						{Translate.T("de.Wiederhole Abenteuer", "en.Restart Adventure", "adventure.instance.restart")}
					</Button>}
				</div>

				{cardInstanceIdPopup &&
					<AdvCardPopup
						instanceId={cardInstanceIdPopup}
						onClose={this.OnCardPopupClose(cardInstanceIdPopup)}
						onClick={this.OnCardPopupCardClick(cardInstanceIdPopup)}
					/>}
			</div>
		);
	}
}

AdventureInstancePage.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AdventureInstancePage);