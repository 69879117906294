import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import Translate from '../Translate';
import SocialButton from './SocialButton';
import ProfilePicture from './ProfilePicture';
import HorizontalScroller from './HorizontalScroller';
import AppState from '../AppState';

const styles = {
};

@observer
class TimelineProfilesItem extends React.Component
{
	profiles = [];

	constructor(props)
	{
		super(props);

		this.UpdateData();
	}

	componentDidUpdate(prevProps)
	{
		this.UpdateData();
	}

	UpdateData()
	{
		this.profiles = [];
		const content = this.props.content;
		if (!content || !content.desc || content.desc.length < 2)
			return;

		this.profiles = JSON.parse(content.desc);
	}

	renderTitle(content)
	{
		var label;

		if (content.type === "profiles-influencers")
			label = Translate.T("de.Personen, die interessante Ausflugsziele und Erfahrungsberichte für Dich haben könnten",
				"en.People who might have interesting family activities for you", "timeline.profilesSuggestions.influencers");

		if (content.type === "profiles-friends")
			label = Translate.T("de.Personen, die Du vielleicht kennen könntest",
				"en.People you might know", "timeline.profilesSuggestions.friends");
		
		return (
			<div className="title">{label}</div>
		);
	}

	OnProfileClicked = (profile) => () =>
	{
		AppState.instance.appContainer.OnProfileClicked(profile.username);
	}

	renderProfile(profile, index, isLast)
	{
		var displayName = profile.username;
		if (profile.firstName && profile.lastName)
			displayName = profile.firstName + " " + profile.lastName;
		else if (profile.firstName)
			displayName = profile.firstName;
		else if (profile.lastName)
			displayName = profile.lastName;

		return (
			<div className={"profile" + (isLast ? " last":"")} key={index}>
				<ProfilePicture className="timelineProfilePhoto" username={profile.username} onClick={this.OnProfileClicked(profile)}/>

				<div className="timelineProfileName" onClick={this.OnProfileClicked(profile)}>
					<span className="displayName">{displayName}</span>
				</div>

				{this.props.content.type === "profiles-influencers" && <div className="numFollowers">
					{Translate.T("de.{0} Personen folgen {1}", "en.{0} people are following {1}", "timeline.profilesSuggestions.areFollowing",
						[profile.numFollowers, displayName])}
				</div>}

				{this.props.content.type === "profiles-friends" && <div className="numMutualFriends">
					{profile.numMutualFriends === 1 && Translate.T("de.1 gemeinsamer Freund", "en.1 mutual friend", "timeline.profilesSuggestions.numMutualFriends")}
					{profile.numMutualFriends !== 1 && Translate.T("de.{0} gemeinsame Freunde", "en.{0} mutual friends", "timeline.profilesSuggestions.numMutualFriends",
						[profile.numMutualFriends])}
				</div>}

				<SocialButton username={profile.username} default={this.props.content.type === "profiles-influencers" ? "follow" : undefined} />
			</div>
		);
	}

	render()
	{
		const content = this.props.content;

		return (
			<div className={"timelineItem timelineProfilesItem " + content.type} key={this.props.key}>
				{this.renderTitle(content)}

				<HorizontalScroller allowIScroll={false} className="profiles">
					{this.profiles.map((profile, index) =>
					{
						return this.renderProfile(profile, index, index === this.profiles.length - 1);
					})}
				</HorizontalScroller>
			</div>
		);
	}
}

TimelineProfilesItem.propTypes = {
	classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(TimelineProfilesItem);