import React from 'react';
import {observer} from 'mobx-react';
import { observable } from 'mobx';
import Translate from '../Translate';
import ArrowDropDown from 'material-ui-icons/ArrowDropDown';
import CheckIcon from 'material-ui-icons/Check';
import ErrorIcon from 'material-ui-icons/Error';
import Button from 'material-ui/Button';
import AppState from '../AppState';
import Dialog, {DialogActions, DialogContent, DialogContentText, DialogTitle} from 'material-ui/Dialog';
import $ from 'jquery';
import {CircularProgress} from 'material-ui/Progress';
import AccountRequestPage from '../pages/AccountRequestPage';
import Utils from '../Utils';
import Input from 'material-ui/Input';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';

@observer
export class ListSelectionMenu extends React.Component
{
	OnCreateNewList = () =>
	{
		AppState.instance.screenMessage.CloseDialog();
		if (this.props.onNewList)
			this.props.onNewList();
	}

	OnListSelected = (listId) => () =>
	{
		AppState.instance.screenMessage.CloseDialog();
		if (this.props.onListSelected)
			this.props.onListSelected(listId);
	}

	render()
	{
		var lists = AppState.instance.listManager.GetLists();
		if (lists)
			lists = lists.slice();
		const isLoggedIn = AppState.instance.IsLoggedIn();

		// Remove checkin and people lists
		if (lists)
		{
			for (var i = lists.length; i >= 0; --i)
			{
				if (!lists[i])
					continue;
				if (AppState.instance.listManager.IsCheckInList(lists[i].id))
				{
					if (!this.props.enableCheckIn)
						lists.splice(i, 1);
				}
				else if (lists[i].flag !== 0)
				{
					if (!this.props.enableUsers)
						lists.splice(i, 1);
				}
			}
		}

		var activeLists = [];
		if (this.props.contentId !== undefined)
			activeLists = AppState.instance.listManager.GetListsOfContent(this.props.contentId);

		return (
			<div className="dateSelectionMenu listSelectionMenu">

				{this.props.title && <div className="listSelectionMenuTitle">
					{this.props.title}
				</div>}

				{lists.map((list) =>
				{
					if (this.props.listType >= 0)
						if (list.type !== this.props.listType)
							return null;

					var translated = Translate.FindTranslation(list.title);
					if (!translated)
						translated = list.title;

					var isBookmarked = false;
					if (activeLists.indexOf(list) >= 0)
					isBookmarked = true;

					return (
						<Button onClick={this.OnListSelected(list.id)} key={list.id}>
							{this.props.contentId !== undefined && <div className="userListSelectionBookmarkIcon" >{Utils.RenderStarIcon(16, 16, isBookmarked ? "#e31c46" : "#aaa", isBookmarked ? "white" : "white", 2.5)}</div>}
							<span className="dateSelectionName">{translated}</span>
							{list.items && list.items.length > 1 && <span className="dateSelectionDate">{list.items.length} {Translate.T("en.entries", "de.Einträge", "userlistselection.entries")}</span>}
							{list.items && list.items.length === 1 && <span className="dateSelectionDate">{list.items.length} {Translate.T("en.entry", "de.Eintrag", "userlistselection.entries.one")}</span>}
							{(!list.items || list.items.length === 0) && <span className="dateSelectionDate">{Translate.T("en.No entries", "de.Keine Einträge", "userlistselection.entries.none")}</span>}
						</Button>
					);
				})}

				<Button className="last singleline" onClick={this.OnCreateNewList}>
					<span className="dateSelectionName">
						{isLoggedIn && Translate.T("en.Create new bookmark list", "de.Erstelle neue Lesezeichen Liste", "userlistselection.createnew")}
						{!isLoggedIn && Translate.T("en.Login to create new bookmark list", "de.Einloggen um neue Lesezeichen Liste zu erstellen", "userlistselection.createnew.login")}
					</span>
				</Button>
			</div>
		);
	}
}

const styles = theme => ({
	cssUnderline: {
		'&:after': {
			backgroundColor: '#e31c46',
		},
	},
});

@observer
class UserListSelection extends React.Component
{
	@observable showNewDialog = false;
	@observable isCreatingList;

	lastRenderedListId;
	lastRenderedNumItems;
	highlightSet;

	componentDidMount()
	{
		AppState.instance.listManager.onListsChanged.Add(this.OnListsChanged);
	}

	componentWillUnmount()
	{
		AppState.instance.listManager.onListsChanged.Remove(this.OnListsChanged);
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps.visible === false && this.props.visible === true)
		{
			setTimeout(() => {
				$("#newlist_title").focus();
			}, 100);
		}
	}

	OnListsChanged = () =>
	{
		// Default selection
		var currentSelection;
		if (this.props.getUserList)
			currentSelection = this.props.getUserList();
		else
			currentSelection = AppState.instance.swipeListId;

		if (currentSelection === undefined || currentSelection === -1)
		{
			var lists = AppState.instance.listManager.GetLists();
			if (lists.length > 0)
			{
				if (this.props.setUserList)
				{
					//TODO: why was that needed?
					//this.props.setUserList(lists[0].id);

					if (AppState.instance.swipeListId === undefined || AppState.instance.swipeListId === -1)
						AppState.instance.swipeListId = lists[0].id;
				}
				else
					AppState.instance.swipeListId = lists[0].id;
			}
		}

		this.forceUpdate();
	}

	OnClick = (e) =>
	{
		if (this.props.onButtonClicked)
			this.props.onButtonClicked(e);

		AppState.instance.screenMessage.ShowDialog(undefined, ListSelectionMenu, undefined, undefined, undefined,
		{
			onListSelected: this.OnListSelected,
			onNewList: this.OnNewList,
			title: this.props.title,
			listType: this.props.listType,
			enableCheckIn: this.props.enableCheckIn,
			enableUsers: this.props.enableUsers,
		}, false, "dialogListSelectionButton", true);
	}

	OnNewList = () =>
	{
		this.isCreatingList = undefined;
		const isLoggedIn = AppState.instance.IsLoggedIn();

		if (isLoggedIn)
		{
			this.showNewDialog = true;

			setTimeout(() => {
				$("#newlist_title").focus();
			}, 300);
		}
		else
		{
			AccountRequestPage.Show();
		}
	}

	OnListSelected = (listId) =>
	{
		if (this.props.setUserList)
			this.props.setUserList(listId);
		else
			AppState.instance.swipeListId = listId;

		if (this.props.onListSelected)
			this.props.onListSelected(listId);
	}

	OnCloseNewDialog = () =>
	{
		this.isCreatingList = undefined;
		this.showNewDialog = false;

		if (this.props.onClose)
			this.props.onClose();
	}

	OnCreateNewListOk = () =>
	{
		var title = $("#newlist_title").val();
		var desc = $("#newlist_desc").val();

		this.isCreatingList = 1;

		AppState.instance.listManager.CreateList(title, desc, this.props.listType >= 0 ? this.props.listType : 0)
		.then((newList) =>
		{
			if (newList)
			{
				if (this.props.setUserList)
					this.props.setUserList(newList.id);
				else
					AppState.instance.swipeListId = newList.id;

				this.isCreatingList = 2;
				setTimeout(() => {
					this.isCreatingList = undefined;
					this.showNewDialog = false;
					if (this.props.onClose)
						this.props.onClose();
				}, 500);
			}
		})
		.catch(() =>
		{
			this.isCreatingList = 3;
		});
	}

	OnTextFieldKeyPress = (event) =>
	{
		var key = event.key || event.keyCode || event.which;
		if (key === 'Enter' || key === '13')
		{
			event.preventDefault();
			$("#newlist_title").blur();
			this.OnCreateNewListOk();
		}
	}

	render()
	{
		var lists = AppState.instance.listManager.GetLists();
		var selectedList;

		var currentSelection;
		if (this.props.getUserList)
			currentSelection = this.props.getUserList();
		else
			currentSelection = AppState.instance.swipeListId;

		if (currentSelection !== undefined)
		{
			selectedList = AppState.instance.listManager.GetListById(currentSelection);
		}
		else if (lists.length > 0)
		{
			selectedList = lists[0];
		}

		var numItems;
		var translated;
		if (selectedList === undefined)
		{
			return null;
		}
		else
		{
			if (selectedList.items)
				numItems = selectedList.items.length;
			else
				numItems = 0;

			translated = Translate.FindTranslation(selectedList.title);
			if (!translated)
				translated = selectedList.title;
		}

		var hightlightNum = (this.lastRenderedListId === selectedList.id && numItems > this.lastRenderedNumItems);
		if (hightlightNum)
		{
			this.highlightSet = new Date();
			setTimeout(() => {
				this.highlightSet = undefined;
				$(".numItems").removeClass("highlight");
			}, 300);
		}
		else if (this.highlightSet)
		{
			const ms = (new Date().getTime() - this.highlightSet.getTime());
			if (ms < 1000)
				hightlightNum = true;
		}

		/*if (hightlightNum)
		{
			setTimeout(() => {
				$("#userListSelector_numItems")
			}, 100);
		}*/

		this.lastRenderedListId = selectedList.id;
		this.lastRenderedNumItems = numItems;

		return (
			<div
				className={"userListSelector " + this.props.className}
			>
				{!this.props.dialogMode && <Button onClick={this.OnClick} >
					{(translated + " • ")}
					<span id="userListSelector_numItems" className={"numItems" + (hightlightNum ? " highlight":"")}>{numItems}</span>
					{hightlightNum && <div className={"userListSelectorHighlightStar" + (numItems < 10 ? " single":"")}>{Utils.RenderStarIcon(30, 30, "#e31c46", "#e31c46", 1.0)}</div>}

					<ArrowDropDown />
				</Button>}

				<Dialog
					open={this.showNewDialog || (this.props.dialogMode === true && this.props.visible === true)}
					onClose={this.OnCloseNewDialog}
					aria-labelledby="Create new list"
				>
					<DialogTitle id="form-dialog-title">{Translate.T("en.New list", "de.Neue Liste", "userlistselection.newdialog.title")}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							{Translate.T("en.Please select a title.", "de.Bitte wähle einen Titel.", "userlistselection.newdialog.content")}
						</DialogContentText>
							<Input
								fullWidth
								autoFocus
								classes={{
									underline: this.props.classes.cssUnderline,
								}}
								id="newlist_title"
								onKeyPress={this.OnTextFieldKeyPress}
								className="newCardCollectionTitle"
							/>

						{/* <TextField
							margin="dense"
							id="newlist_desc"
							label={Translate.T("en.Description", "de.Beschreibung", "userlistselection.newdialog.field.desc")}
							type="text"
							fullWidth
							color="primary"
						/> */}

					</DialogContent>
					<DialogActions>
						{(this.isCreatingList === undefined || this.isCreatingList === 3) && <Button onClick={this.OnCloseNewDialog} color="primary">
							{Translate.T("en.Cancel", "de.Abbrechen", "userlistselection.newdialog.cancel")}
						</Button>}
						<Button className="newlistdialogBtn" onClick={(this.isCreatingList === undefined || this.isCreatingList === 3) ? this.OnCreateNewListOk : undefined} color="primary">
							{this.isCreatingList === undefined && Translate.T("en.OK", "de.OK", "userlistselection.newdialog.ok")}
							{this.isCreatingList === 1 && <CircularProgress />}
							{this.isCreatingList === 2 && <span><CheckIcon />{Translate.T("en.Done", "de.Fertig", "userlistselection.newdialog.done")}  </span>}
							{this.isCreatingList === 3 && <span><ErrorIcon />{Translate.T("en.Error - Please try again", "de.Fehler - Bitte nochmal versuchen", "userlistselection.newdialog.error")}  </span>}
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

UserListSelection.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserListSelection);