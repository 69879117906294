import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import {withStyles} from 'material-ui/styles';
import CheckIcon from 'material-ui-icons/Check';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import Translate from '../Translate';
import $ from 'jquery';

const styles = theme => ({
});

@observer
class InputTextField extends React.Component
{
	@observable isValid = false;
	@observable clearTextPassword = false;

	OnTextFieldChanged = (event) =>
	{
		if (this.props.onValidate)
		{
			this.isValid = this.props.onValidate(event.target.value, event);
		}
		if (this.props.onChange)
		{
			this.props.onChange(event.target.value, event);
		}
	}

	OnTextFieldKeyPress = (event) =>
	{
		var key = event.key || event.keyCode || event.which;
		if (key === 'Enter' || key === '13')
		{
			event.preventDefault();
			if (this.props.onEnter)
			{
				this.props.onEnter(event.target.value, event);
			}
		}
	}

	OnShowHidePassword = () =>
	{
		this.clearTextPassword = !this.clearTextPassword;
		setTimeout(() => {
			$("#" + this.props.id).focus();
		}, 50);
	}

	render()
	{
		return (
			<div className={this.props.className + "Container"}>

				<div className={this.props.className + "Label"}>
					{this.props.label}
				</div>

				{this.props.password && <div className={this.props.className + "PasswordLabel"} onClick={this.OnShowHidePassword}>
					{this.clearTextPassword ?
						Translate.T("en.Hide", "de.Verbergen", "inputfield.password.hide") :
						Translate.T("en.Show", "de.Anzeigen", "inputfield.password.show")}
				</div>}

				<TextField
					type={this.props.password && !this.clearTextPassword ? "password" : 
						(this.props.email ? "email" : "text")}
					className={this.props.className}
					onChange={this.OnTextFieldChanged}
					onKeyPress={this.OnTextFieldKeyPress}
					placeholder={this.props.placeholder}
					key={this.props.key}
					disabled={this.props.disabled}
					InputProps={{
						disableUnderline: true,
						id: this.props.id,
						onFocus: this.props.onFocus,
						onBlur: this.props.onBlur,
					}}
				/>

				{this.isValid && <div className="inputTextFieldValid">
					<CheckIcon />
				</div>}
			</div>
		);
	}
}

InputTextField.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InputTextField);