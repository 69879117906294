/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Utils from '../Utils';
import Translate from '../Translate';

export default class ReactPullToRefresh extends Component
{
	ptr;
	initialized = false;

	containerId;
	triggerId;

	constructor(props)
	{
		super(props);

		this.handleRefresh = this.handleRefresh.bind(this);
		this.containerId = "pulltorefresh_container_" + Utils.GenerateRandomStr(32);
		this.triggerId = "pulltorefresh_trigger_" + Utils.GenerateRandomStr(32);
	}

	init()
	{
		if (!this.initialized)
		{
			this.ptr = PullToRefresh.init({
				mainElement: '#' + this.containerId,
				triggerElement: '#' + this.triggerId,
				onRefresh: this.handleRefresh,
				refreshTimeout: 200,
				instructionsPullToRefresh: Translate.FindTranslation("instructionsPullToRefresh"),
				instructionsReleaseToRefresh: Translate.FindTranslation("instructionsReleaseToRefresh"),
				instructionsRefreshing: Translate.FindTranslation("instructionsRefreshing")
			});
			this.initialized = true;
		}
	}

	componentDidMount()
	{
		if (!this.props.disabled)
		{
			this.init();
		}
	}

	componentDidUpdate()
	{
		if (!this.props.disabled)
		{
			this.init();
		}
	}

	componentWillUnmount()
	{
		if (this.ptr)
			this.ptr.destroy();
	}

	handleRefresh()
	{
		return new Promise((resolve, reject) =>
		{
			if (this.props.onRefresh)
				this.props.onRefresh(resolve, reject);
			else
				resolve();
		});
	}

	render()
	{
		const {
			children,
			disabled,
			className
		} = this.props;

		if (disabled)
		{
			return (
				<div className={className}>
					{children}
				</div>
			);
		}

		return (
			<div id={this.containerId} className={className}>
				<div id={this.triggerId}>
					{children}
				</div>
			</div>
		);
	}
}

ReactPullToRefresh.propTypes = {
	onRefresh: PropTypes.func,
	disabled: PropTypes.bool,
	className: PropTypes.string,
};