import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import VisibilitySensor from 'react-visibility-sensor';
import AppState from '../AppState';
import TimelineItem from './TimelineItem';
import { CircularProgress } from 'material-ui';
import Translate from '../Translate';
import Utils from '../Utils';
import { observable } from 'mobx';
import ReactPullToRefresh from './PullToRefresh';
import Button from 'material-ui/Button';
import $ from 'jquery';

const styles = theme => ({
});

@observer
class Timeline extends React.Component
{
	id;
	contentId;
	registeredContentId;
	items = [];
	isLoading = 0;
	initialLoadDone = false;
	isEod = false;
	hasReceivedData = false;
	isCompMounted = false;
	onLoadingDone;

	@observable refreshing = false;
	@observable switchContainer = false;
	selectedItem;

	constructor(props)
	{
		super(props);

		this.id = Utils.GenerateRandomId(32);

		// Maybe some data was loaded before
		var contentId = this.CalcContentId();
		var existingData = AppState.instance.contentStore.GetExistingCategoryContent(contentId);
		if (existingData)
		{
			this.hasReceivedData = true;
			this.items = existingData;
		}
	}

	componentDidUpdate(prevProps)
	{
		if (this.props.username !== prevProps.username ||
			this.props.activityId !== prevProps.activityId ||
			this.props.isOwnUser !== prevProps.isOwnUser)
		{
			this.GenerateContentId();
			this.Refresh();
		}

		if (this.props.selectedItem /*!== prevProps.selectedItem*/)
		{
			this.DoSelectItem(this.props.selectedItem);
		}
	}

	componentDidMount()
	{
		this.isCompMounted = true;
		if (this.props.onInit)
			this.props.onInit(this);

		if (this.props.selectedItem)
			this.DoSelectItem(this.props.selectedItem);
	}

	componentWillUnmount()
	{
		if (this.registeredContentId)
		{
			AppState.instance.contentStore.RemoveOnDataChanged(this.registeredContentId, this.OnDataChanged);
			this.registeredContentId = undefined;
		}

		this.isCompMounted = false;
	}

	DoSelectItem(item)
	{
		const changed = (!this.selectedItem && item) || (this.selectedItem.id !== item.id);

		this.selectedItem = item;

		// If loaded, scroll to it
		if (this.IsLoaded(item.id))
		{
			this.ScrollTo(item.id);
		}
		else
		{
			// add at current scroll position
			var insertIndex;
			const visibleId = this.GetMostVisibleItemId();
			if (visibleId !== undefined)
			{
				insertIndex = this.GetIndexOfItemId(visibleId);
			}

			if (insertIndex)
			{
				this.items.splice(insertIndex, 0, item);
			}
			else
			{
				this.items.unshift(item);
			}

			setTimeout(() => {
				this.ScrollTo(item.id);
			}, 200);
		}

		if (changed)
			this.forceUpdate();
	}

	GetIndexOfItemId(itemId)
	{
		for (var i = 0; i < this.items.length; ++i)
		{
			if (this.items[i].id === itemId)
				return i;
		}
		return undefined;
	}

	GetMostVisibleItemId()
	{
		var maxPercent = 0;
		var maxPercentFound = false;
		var maxObject;

		$("#" + this.id + " .timelineItem").each(function()
		{
			if (maxPercentFound)
				return;

			var p = Utils.CalcPercentInViewportY(this);
			if (p > maxPercent)
			{
				maxObject = this;
				maxPercent = p;

				if (p >= 0.9999999)
				{
					maxPercentFound = true;
				}
			}
		});

		if (maxObject)
		{
			var oId = $(maxObject).attr('id');
			oId = Utils.ReplaceAll(oId, "tli_", "");
			return Number(oId);
		}
		else
		{
			return undefined;
		}
	}

	ScrollTo(itemId)
	{
		var target = $("#tli_" + itemId);
		var container = $('html, body');

		const offset = target.offset();
		if (offset)
		{
			const y = offset.top - 104;

			container.animate({scrollTop: y}, 500);
		}
	}

	IsLoaded(itemId)
	{
		for (var i = 0; i < this.items.length; ++i)
		{
			if (this.items[i].id === itemId)
				return true;
		}
		return false;
	}

	OnContentIdChanged()
	{
		if (this.contentId !== this.registeredContentId)
		{
			if (this.registeredContentId)
				AppState.instance.contentStore.RemoveOnDataChanged(this.registeredContentId, this.OnDataChanged);
			this.registeredContentId = undefined;

			if (this.contentId)
			{
				this.registeredContentId = this.contentId;
				AppState.instance.contentStore.AddOnDataChanged(this.registeredContentId, this.OnDataChanged);
			}
		}
	}

	CalcContentId()
	{
		var result;
		if (this.props.isOwnUser)
			result = "timeline/";
		else if (this.props.activityId)
			result = "timeline/activity/" + this.props.activityId;
		else
			result = "timeline/" + this.props.username;

		if (this.props.forcedItem)
		{
			if (!result.endsWith("/"))
				result += "/";
			result += "forced/" + this.props.forcedItem;
		}

		return result;
	}

	GenerateContentId()
	{
		this.contentId = this.CalcContentId();
		this.OnContentIdChanged();
	}

	Refresh()
	{
		if (!this.contentId)
			this.GenerateContentId();

		if (this.contentId)
		{
			if (this.registeredContentId)
				AppState.instance.contentStore.RemoveOnDataChanged(this.registeredContentId, this.OnDataChanged);
			this.registeredContentId = undefined;

			AppState.instance.contentStore.DeleteContent(this.contentId);
			this.isEod = false;
			this.contentId = undefined;
			this.isLoading = 0;
			if (this.props.active === false)
			{
				this.items = [];
				this.forceUpdate();
			}
			this.RequestMoreItems(true);
		}
		else
		{
			this.OnLoadingDone();
		}
	}

	IsMainTimeline()
	{
		var username;
		if (AppState.instance.IsLoggedIn())
		{
			username = AppState.instance.userProfile.username;
		}

		return !this.props.isOwnUser && this.props.username === username;
	}

	IsOwnProfileTimeline()
	{
		return this.props.isOwnUser;
	}

	IsActiveOnScreen()
	{
		if (this.props.context === "activity")
			return true;

		if (this.IsOwnProfileTimeline() && (AppState.instance.currentMainTab !== AppState.TabIndexProfile))
			return false;

		if (this.IsMainTimeline() || this.IsOwnProfileTimeline())
		{
			if (AppState.instance.isShowingDetailPage > 0)
				return false;
		}

		return true;
	}

	OnVisibilitySensor = (isVisible) =>
	{
		if (isVisible && this.IsActiveOnScreen() && this.isLoading === 0)
		{
			if (this.props.loadMoreButton && this.initialLoadDone)
			{
				// Only the first load is automatically
				return;
			}

			++this.isLoading;
			this.RequestMoreItems();
		}
	}

	RequestMoreItems(force)
	{
		this.initialLoadDone = true;
		
		if (this.isEod || (this.props.active === false && !force))
		{
			this.OnLoadingDone();
			return;
		}

		if ((this.props.username && this.props.username.length > 0) ||
			(this.props.isOwnUser === true) ||
			(this.props.activityId >= 0))
		{
			if (!this.contentId)
				this.GenerateContentId();
			AppState.instance.contentStore.RequestMoreContent(this.contentId);
		}
		else
		{
			this.OnLoadingDone();
		}
	}

	OnDataChanged = (action, newData, maxTotal) =>
	{
		//console.log("Timeline.OnDataChanged: " + this.registeredContentId);

		if (action === 'request')
		{
			++this.isLoading;
			if (this.isCompMounted)
				this.forceUpdate();
		}
		else if (action === 'eod')
		{
			this.isLoading = 0;
			this.isEod = true;

			if (this.isCompMounted)
				this.forceUpdate();
		}
		else if (action === 'data' || action === 'error' || action === 'abort')
		{
			if (newData)
			{
				for (var i = 0; i < newData.length; ++i)
				{
					AppState.instance.imageManager.Store(newData[i].imageData);

					if (newData[i].userContent && newData[i].userContent.length > 0)
					{
						for (var j = 0; j < newData[i].userContent.length; ++j)
						{
							AppState.instance.imageManager.Store(newData[i].userContent[j].imageData);
						}
					}
				}
			}

			var prevSelectedIndex = -1;
			if (this.selectedItem)
			{
				for (i = 0; this.items.length; ++i)
				{
					if (this.items[i].id === this.selectedItem.id)
					{
						prevSelectedIndex = i;
						break;
					}
				}
			}

			this.hasReceivedData = true;
			this.isLoading = 0;
			if (action === 'data')
				this.items = newData;

			if (this.selectedItem && !this.IsLoaded(this.selectedItem.id))
			{
				// Insert again at previous index
				if (prevSelectedIndex >= 0)
				{
					this.items.splice(prevSelectedIndex, 0, this.selectedItem);
				}
				else
				{
					this.items.push(this.selectedItem);
				}
			}


			if (this.isCompMounted)
				this.forceUpdate();

			if (this.props.onLayoutChanged)
				this.props.onLayoutChanged();

			this.OnLoadingDone();
		}
	}

	OnLoadingDone()
	{
		this.isLoading = 0;
		if (this.onLoadingDone)
		{
			this.onLoadingDone();
			this.onLoadingDone = undefined;
		}
	}

	OnContentDeleted = (contentId) => () =>
	{
		this.Refresh();
		// for (var i = this.items.length - 1; i >= 0; --i)
		// {
		// 	if (this.items[i].id === contentId)
		// 	{
		// 		this.items.splice(i);
		// 		this.forceUpdate();
		// 	}
		// }
	}

	OnPullDown = (resolve, reject) =>
	{
		this.onLoadingDone = resolve;
		this.Refresh();
	}

	OnLoadMoreButton = () =>
	{
		if (this.isLoading === 0)
		{
			++this.isLoading;
			this.RequestMoreItems();
		}
	}

	OnBeforeMediaZoom = (zoomIn) =>
	{
		if (!AppState.instance.deviceInfo.desktop)
		{
			if (zoomIn && AppState.instance.appInstance)
				AppState.instance.appInstance.SetSiteBottomVisibility(false, true);
		}
	}
	
	OnAfterMediaZoom = (zoomIn) =>
	{
		if (!AppState.instance.deviceInfo.desktop)
		{
			if (!zoomIn && AppState.instance.appInstance)
				AppState.instance.appInstance.SetSiteBottomVisibility(true, true);
		}
	}

	render()
	{
		//this.renderCount = this.items.length;
		//console.log("rendered with: " + this.items.length);

		return (
			<div className="timeline" id={this.id}>

				{this.props.isOwnUser && this.hasReceivedData && this.items.length === 0 &&
					<div>
						<div className="ownTimelineEmpty">
							<div className="ownTimelineEmptyLogo">
								{Utils.RenderLogo(40, 40, "white", "#e31c46")}
							</div>
							{Translate.T("de.Du hast noch keine Ausflüge mit Deiner Familie geteilt.",
								"en.You haven't shared any trips with your family yet.", "timeline.owntimelineempty")}
						</div>
					</div>}

					<ReactPullToRefresh
						onRefresh={this.OnPullDown}
						disabled={this.props.pullToRefreshDisabled}
					>
						{this.items.map((item, index) =>
						{
							//TODO: forcedItem highlighten
							return (<TimelineItem
								key={index + "_" + item.id}
								id={"tli_" + item.id}
								isSelected={this.selectedItem ? (this.selectedItem.id === item.id) : false}
								content={item}
								onDeleted={this.OnContentDeleted(item.id)}
								context={this.props.context}
								onLayoutChanged={this.props.onLayoutChanged}
								onMediaSwitching={this.props.onMediaSwitching}
								onMediaTouchEnd={this.props.onMediaTouchEnd}
								onMediaTouchStart={this.props.onMediaTouchStart}
								onBeforeMediaZoom={this.OnBeforeMediaZoom}
								onAfterMediaZoom={this.OnAfterMediaZoom}
							/>);
						})}

						{this.props.loadMoreButton && !this.isEod && !this.isLoading && <div className="timelineLoadMore">
							<Button onClick={this.OnLoadMoreButton}>
								{Translate.T("de.Mehr laden", "en.Load more", "timeline.loadmore")}
							</Button>
						</div>}

						{(!this.hasReceivedData || !this.props.loadMoreButton) && <VisibilitySensor
							onChange={this.OnVisibilitySensor}
							key={"timelineBottomSensor" + this.items.length}
							active={true}
							partialVisibility={true}
						>
							<div className="timelineVisibilitySensor">
								{this.isLoading > 0 && <CircularProgress />}
								{this.isEod && !this.props.isOwnUser && !this.props.disableEodMsg && <div className="timelineNoMoreData">
									{Utils.RenderLogo(32, 32, "white", "#e31c46")}
									{this.items.length > 0 && Translate.T("de.Ich habe im Moment keine weiteren Neuigkeiten für Dich.",
										"en.I don't have more news for you right now.", "timeline.nomoredata")}
									{this.items.length === 0 && Translate.T("de.Ich kann keine Beiträge finden.",
										"en.I can't find any posts to show you.", "timeline.nodata")}
								</div>}
							</div>
						</VisibilitySensor>}
					</ReactPullToRefresh>
			</div>
		);
	}
}

Timeline.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Timeline);