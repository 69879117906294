import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import AppState from '../../AppState';
import Translate from '../../Translate';
import { Button } from 'material-ui';
import Tracking from '../../Tracking';
import AccountRequestPage from '../../pages/AccountRequestPage';
import Utils from '../../Utils';
import CheckIcon from 'material-ui-icons/Check';
import Collapse from 'material-ui/transitions/Collapse';

const styles = theme => ({
});

@observer
class AdvNotReady extends React.Component
{
	componentDidMount()
	{
		Tracking.OnPageView("AdvNotReady");
	}

	static OnAfterLogin()
	{
		console.log("AdvNotReady.OnAfterLogin");
		Tracking.SendEvent("adv_register");
	}

	static OnAfterLoginInform()
	{
		console.log("AdvNotReady.OnAfterLoginInform");
		Tracking.SendEvent("adv_inform");
	}

	OnYes = () =>
	{
		if (AppState.instance.IsLoggedIn())
		{
			Tracking.SendEvent("adv_register");
			Utils.SetLocalStorage("adv_register", "1");
			this.forceUpdate();
		}
		else
		{
			Tracking.SendEvent("adv_register_not_logged_in");
			AccountRequestPage.Show(undefined, "AdvNotReady.OnAfterLogin", undefined, {
				category: 'adventure',
				action: 'adv_register'
			});
		}
	}

	OnInform = () =>
	{
		if (AppState.instance.IsLoggedIn())
		{
			Tracking.SendEvent("adv_inform");
			Utils.SetLocalStorage("adv_inform", "1");
			this.forceUpdate();
		}
		else
		{
			Tracking.SendEvent("adv_inform_not_logged_in");
			AccountRequestPage.Show(undefined, "AdvNotReady.OnAfterLoginInform", undefined, {
				category: 'adventure',
				action: 'adv_inform'
			});
		}
	}

	OnUninform = () =>
	{
		Tracking.SendEvent("adv_uninform");
		Utils.RemoveLocalStorage("adv_inform");
		this.forceUpdate();
	}

	render()
	{
		// wenn schon vorgemerkt, dann loading-icon im AdvAnnouncement

		const isRegistered = Utils.GetLocalStorage("adv_register", "0") === "1";
		const isInformed = Utils.GetLocalStorage("adv_inform", "0") === "1";

		return (
			<div className="advnotready">
				
				<Collapse in={isRegistered} timeout="auto">

					<div className="title">
						<CheckIcon/>
						{Translate.T("de.Erfolgreich für Testbetrieb vorgemerkt", "en.Successfully registered for try-out", "advnotready.registered.title")}
					</div>

					<div className="desc">
						{Translate.T("de.Wir werden Dich per Email informieren, sobald Du das erste LENA Abenteuer erleben kannst.",
							"en.We will let you know via Email as soon as you can experience your first LENA Adventure.",
							"advnotready.registered.desc")}
					</div>
				
				</Collapse>

				<Collapse in={!isRegistered} timeout="auto">

					<div className="title">{Translate.T("de.Danke für Dein Interesse", "en.Thanks for your interest", "advnotready.title")}</div>

					<div className="desc">
						{Translate.T("de.Unsere ersten Abenteuer befinden sich gerade im Testbetrieb.\n\n" +
							"Möchtest Du eine(r) der ersten sein, die LENAs Abenteuer kostenlos testen dürfen?",
							"en.We are currently testing our first adventures.\n\nWould you like to be one of the next to try out LENA's Adventures for free?",
							"advnotready.title")}
					</div>

					<div className="buttons">
						<Button className="register" onClick={this.OnYes}>
							{Translate.T("de.Ja, für kostenlosen Test vormerken", "en.Yes, register for free try-out", "advnotready.buttons.yes")}		
						</Button>

						{!isRegistered &&
							<Button className="inform" onClick={isInformed ? this.OnUninform : this.OnInform}>
								{!isInformed && Translate.T("de.Nein, nur via Email informieren",
									"en.No, just inform me via Email", "advnotready.buttons.inform")}
								{isInformed &&
									<span>
										<CheckIcon/>
										{Translate.T("de.Du wirst beim offiziellen Start per Email informiert",
											"en.You will be informed via Email at the official launch", "advnotready.buttons.informed")}
									</span>}
							</Button>}
					</div>
				
				</Collapse>

				<div className="owl">
					<img src="/assets/cards/owl.png" alt="owl" />
				</div>
				
			</div>
		);
	}
}

AdvNotReady.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AdvNotReady);