import React from 'react';
import Translate from '../../Translate';
import DateRangeIcon from 'material-ui-icons/DateRange';
import StepBase from './StepBase';
import Utils from '../../Utils';

export default class StepSelectDate extends StepBase
{
	constructor(props)
	{
		super(props);

		this.AddAnswerOption({
			label: Translate.T("de.Heute", "en.Today", "dateanswer.today"),
			onClick: this.OnDateToday,
			id: "today"
		});

		this.AddAnswerOption({
			label: Translate.T("de.Gestern", "en.Yesterday", "dateanswer.yesterday"),
			onClick: this.OnDateYesterday,
			id: "yesterday"
		});

		this.AddAnswerOption({
			icon: <DateRangeIcon />,
			onClick: this.OnDateCalendar,
			id: "specificdata"
		});

		this.AddAnswerOption({
			label: Translate.T("de.Kann mich nicht erinnern", "en.I can't remember", "dateanswer.notmatter"),
			onClick: this.OnIgnoreDate,
			id: "ignore"
		});
	}

	GetId()
	{
		return "date";
	}

	GeneratedDataIds()
	{
		return ["date"];
	}

	Undo(contextData)
	{
		delete contextData["date"];
	}

	OnDateToday = (e, contextData) =>
	{
		this.OnStepDone(contextData, {
			date: Utils.DateToDayStart(new Date())
		});
	}

	OnDateYesterday = (e, contextData) =>
	{
		this.OnStepDone(contextData, {
			date: Utils.DateToDayStart(Utils.DatePlusDays(new Date(), -1))
		});
	}

	OnIgnoreDate = (e, contextData) =>
	{
		this.OnStepDone(contextData, {
			date: undefined
		});
	}

	OnDateCalendar = (e, contextData) =>
	{
		contextData.bot.ShowCalendar();
	}

	OnDateSelected = (contextData, date) =>
	{
		this.OnStepDone(contextData, {
			date: date
		});
	}

	renderStepLena(contextData)
	{
		return this.renderLenaMsg(Translate.T("de.Sehr nett, und wann warst Du dort?", "en.Nice, and when have you been there?", "createxp.when"));
	}

	renderUserSelection(contextData)
	{
		const date = contextData["date"];

		var label;
		if (!date)
			label = Translate.T("de.Kann mich nicht erinnern", "en.I can't remember", "dateanswer.notmatter");
		else if (Utils.IsToday(date))
			label = Translate.T("de.Heute", "en.Today", "dateanswer.today");
		else if (Utils.IsYesterday(date))
			label = Translate.T("de.Gestern", "en.Yesterday", "dateanswer.yesterday");
		else
		{
			label = Utils.FormatDateLong(date);
		}

		return this.renderUserMsg(label, contextData);
	}

	renderUserOptions(contextData)
	{
		return (
			<div className="stepDateSelection">
				{this.renderAnswerOptions(contextData, this.answerOptions)}
			</div>
		);
	}
}
