/* eslint-disable no-useless-concat */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import Utils from '../Utils';
import Translate from '../Translate';
import Button from 'material-ui/Button';
import { observable } from 'mobx';
import IconButton from 'material-ui/IconButton';
import CloseIcon from 'material-ui-icons/Close';
import Slide from 'material-ui/transitions/Slide';
import Modal from 'material-ui/Modal';
import Checkbox from 'material-ui/Checkbox';
import { FormGroup, FormControlLabel } from 'material-ui/Form';
import TextField from 'material-ui/TextField';
import RestApi from '../RestApi';
import CheckIcon from 'material-ui-icons/Check';
import {CircularProgress} from 'material-ui/Progress';
import Switch from 'material-ui/Switch';
import Tracking from '../Tracking';

const styles = theme => ({
});

@observer
class BusinessMessage extends React.Component
{
	@observable checkboxGroups = {};
	@observable coopErrorMsg;
	@observable coopSuccess = false;
	@observable coopSendEnabled = false;
	@observable preferPhone = false;

	componentDidMount()
	{
	}

	HandleChecked = (group, name) => (event) =>
	{
		if (this.checkboxGroups[group] === undefined)
			this.checkboxGroups[group] = {};

		this.checkboxGroups[group][name] = event.target.checked;

		this.UpdateSendEnabled();
	}

	HandleTextField = (group, name) => (event) =>
	{
		if (this.checkboxGroups[group] === undefined)
			this.checkboxGroups[group] = {};

		this.checkboxGroups[group][name] = event.target.value;

		this.UpdateSendEnabled();
	}

	renderCheckbox(group, name, label)
	{
		var value;
		if (this.checkboxGroups[group] !== undefined)
			value = this.checkboxGroups[group][name];

		return (
			<FormControlLabel
				key={name}
				control={
					<Checkbox
						checked={value}
						onChange={this.HandleChecked(group, name)}
						value={name}
						className="lpCoopCheckbox"
						color="primary"
					/>
				}
				label={label}
			/>
		);	
	}

	renderTextInput(group, name, label)
	{
		//var value;
		//if (this.checkboxGroups[group] !== undefined)
		//	value = this.checkboxGroups[group][name];

		return (
			<TextField
				onChange={this.HandleTextField(group, name)}
				//value={value}
				className={"lpCoopTextField " + name}
				label={label}
			/>
		);	
	}

	GetFormValue(group, name)
	{
		if (this.checkboxGroups[group] === undefined)
			return undefined;
		return this.checkboxGroups[group][name];
	}

	CreateCoopQuery()
	{
		var q = {};

		q.type = "";
		for (var name in this.checkboxGroups['businessType'])
		{
			const v = this.checkboxGroups['businessType'][name];
			if (v === true)
				q.type += name + ", ";
		}

		const other = this.GetFormValue('businessType', 'other');
		if (other)
			q.type += other;

		q.name = this.GetFormValue('contact', 'name');
		q.company = this.GetFormValue('contact', 'company');
		q.email = this.GetFormValue('contact', 'email');
		q.phone = this.GetFormValue('contact', 'phone');

		q.callback = "";
		if (this.preferPhone)
			q.callback = "via phone. TOPIC: " + this.props.type;
		else
			q.callback = "via email. TOPIC: " + this.props.type;

		q.sendCopy = true;// this.GetFormValue('contact', 'copy');

		q.message = this.GetFormValue('contact', 'message');

		q.messageType = "b2b_coop";
		if (this.props.type === "plus")
			q.messageHeader = "b2b_plus";
		else if (this.props.type === "premium")
			q.messageHeader = "b2b_premium";

		return q;
	}

	UpdateSendEnabled()
	{
		var result = true;

		var q = this.CreateCoopQuery();
		if (!q.name)
			result = false;
		else if (!q.company)
			result = false;

		if (this.props.phoneOptional)
		{
			if (!q.email)
				result = false;
		}
		else
		{
			if (!q.email || !q.phone)
				result = false;
		}

		if (!this.props.disableMessage && !this.props.optionalMessage)
			if (!q.message)
				result = false;

		if (result)
		{
			if (!Utils.ValidateEmail(q.email))
				result = false;
		}

		this.coopSendEnabled = result;
	}

	OnTimeToUpdateCoopSend = () =>
	{
		this.forceUpdate();
	}

	OnSendCoop = () =>
	{
		if (this.coopSuccess === true)
		{
			// Already sent successfully
			if (this.props.onClose)
				this.props.onClose();
			return;
		}

		if (this.coopSuccess !== false)
			return;

		this.UpdateSendEnabled();
		if (!this.coopSendEnabled)
			return;

		// show progress icon
		this.coopSuccess = undefined;
		this.coopErrorMsg = undefined;

		var q = this.CreateCoopQuery();

		const errorText = Translate.T("en.Error while sending message.\nPlease try again later or via email. Thanks!",
			"de.Fehler beim Senden der Nachricht.\nBitte versuchen Sie es später nochmal oder per Email. Danke!",
			"lp.coop.senderror");

		//console.log(q);

		// setTimeout(() => {
		// 	this.coopSuccess = false;
		// 	this.coopErrorMsg = Translate.T("en.Error while sending message. Please try again later or via email. Thanks!",
		// 		"de.Fehler beim Senden der Nachricht. Biete versuchen Sie es später nochmal oder per Email. Danke!",
		// 		"lp.coop.senderror");
		// }, 3000);

		RestApi.SendRequestText("/b2bmessage", q).then( (resultText) =>
		{
			if (resultText && resultText === "ok")
			{
				this.coopSuccess = true;
			}
			else
			{
				this.coopSuccess = false;
				this.coopErrorMsg = errorText;
			}
		})
		.catch((error) =>
		{
			this.coopSuccess = false;
			this.coopErrorMsg = errorText;
		});

		if (this.props.trackingEvent)
			Tracking.SendGoogleEvent(this.props.trackingEvent.category, this.props.trackingEvent.action,
				this.props.trackingEvent.label, this.props.trackingEvent.value);
	}

	render()
	{
		return (
			<Modal
				open={this.props.open}
				onClose={this.props.onClose}
				keepMounted={true}
			>
				<Slide in={this.props.open} direction='up' timeout={300}>
					<div className="lpCoopContainer">
						<div className="lpCoopInnerContainer">
							<IconButton color="primary" onClick={this.props.onClose} aria-label="Close" className="lpCoopClose">
								<CloseIcon/>
							</IconButton>

							<div className="lpCoopTitle">
								{this.props.title}
							</div>
							<div className="lpCoopIntro">
								{this.props.subtitle}
							</div>

							<div className="lpCoopContact">
								{/* <div className="lpCoopContactTitle">
									{Translate.T("en.Contact Details", "de.Kontaktdaten", "lp.footer.cooperations.contact.title")}
								</div> */}

								<FormGroup row={false}>
									{this.renderTextInput('contact', 'name', Translate.T("en.My Name *", "de.Mein Name *", "contact.name"))}
									{this.renderTextInput('contact', 'company', Translate.T("en.Company Name *", "de.Firmenname *", "contact.company"))}
									{this.renderTextInput('contact', 'email', Translate.T("en.Email *", "de.Email *", "contact.email"))}
									{this.props.phoneOptional && this.renderTextInput('contact', 'phone', Translate.T("en.Phone", "de.Telefon", "contact.phone.optional"))}
									{!this.props.phoneOptional && this.renderTextInput('contact', 'phone', Translate.T("en.Phone *", "de.Telefon *", "contact.phone"))}
								</FormGroup>

								<div className="bmPriceSwitch">
									<span onClick={() => {this.preferPhone = false;}}>
										{Translate.T("de.Ich bevorzuge Email", "en.I prefer email", "businessmessage.prefer.prefix")}
									</span>
									<Switch
										value="contactType"
										color="primary"
										checked={this.preferPhone}
										onChange={() => {this.preferPhone = !this.preferPhone;}}
									/>
									<span onClick={() => {this.preferPhone = true;}}>
										{Translate.T("de.Telefon", "en.Phone", "businessmessage.prefer.phone")}
									</span>
								</div>

							</div>

							{(this.props.checkboxTitle || !this.props.disableMessage) && <div className="lpCoopBusinessType">
								{this.props.checkboxTitle && <div className="lpCoopBusinessTypeTitle">
									{this.props.checkboxTitle}
								</div>}

								<FormGroup row={false}>
									{this.props.checkboxes && this.props.checkboxes.map((cb, index) =>
									{
										return this.renderCheckbox('businessType', cb.id, cb.label);
									})}
									{this.props.showOther && this.renderTextInput('businessType', 'other', Translate.T("en.Other:", "de.Was anderes", "businesstype.other"))}

									{!this.props.disableMessage && <TextField
										id="contactmsg"
										label={this.props.optionalMessage ? Translate.T("en.My message", "de.Meine Nachricht", "contact.message.optional") :
											Translate.T("en.My message *", "de.Meine Nachricht *", "contact.message")}
										multiline
										rows="4"
										defaultValue=""
										className="lpCoopMessage"
										margin="normal"
										onChange={this.HandleTextField('contact', 'message')}
									/>}
								</FormGroup>
							</div>}

							
							<div className="bmButtonContainer">
								<Button onClick={this.OnSendCoop} className={"lpCoopSend" + (this.coopSendEnabled ? "": " disabled")}>
									{this.coopSuccess === undefined && <CircularProgress className="lpCoopProgress" />}
									{this.coopSuccess === true && <CheckIcon className="lpCoopSuccess"/>}

									{this.coopSuccess === false && !this.props.isRequest && Translate.T("en.Send Message", "de.Nachricht senden", "lp.coop.sendmessage")}
									{this.coopSuccess === undefined && !this.props.isRequest && Translate.T("en.Sending Message", "de.Nachricht wird gesendet", "lp.coop.sendingmessage")}
									{this.coopSuccess === true && !this.props.isRequest && Translate.T("en.Success", "de.Nachricht erfolgreich gesendet", "lp.coop.sentmessage")}

									{this.coopSuccess === false && this.props.isRequest && Translate.T("en.Send Request", "de.Anfrage senden", "lp.coop.sendrequest")}
									{this.coopSuccess === undefined && this.props.isRequest && Translate.T("en.Sending Request", "de.Anfrage wird gesendet", "lp.coop.sendingrequest")}
									{this.coopSuccess === true && this.props.isRequest && Translate.T("en.Success", "de.Anfrage erfolgreich gesendet", "lp.coop.sentrequest")}
								</Button>
							</div>

							{this.coopErrorMsg !== undefined && <div className="lpCoopError">
								{this.coopErrorMsg}
							</div>}
						</div>
					</div>
				</Slide>
			</Modal>
		);
	}
}

BusinessMessage.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(BusinessMessage);