import React from 'react';
import {withStyles} from 'material-ui/styles';
import AppBase from './AppBase';
import PropTypes from 'prop-types';
import DetailPage from './pages/DetailPage';
import SuggestEditPage from './pages/SuggestEditPage';
import AddReviewPage from './pages/AddReviewPage';
import MainPage from './pages/MainPage';
import BookmarkPage from './pages/BookmarkPage';
import ProfilePage from './pages/ProfilePage';
import ProfileComponent from './components/ProfileComponent';
import HeaderBar from './components/HeaderBar';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import Tracking from './Tracking';
import {Route} from 'react-router-dom';
import BottomNavigation, { BottomNavigationAction } from 'material-ui/BottomNavigation';
import SwipeableViews from 'react-swipeable-views';
import AppState from './AppState';
import $ from 'jquery';
import Utils from './Utils';
import FilterPage from './pages/FilterPage';
import MapPage from './pages/MapPage';
import Tutorial from './components/Tutorial';
import TopHeader from './components/TopHeader';
import FilterBar from './components/FilterBar';
import SwipePage from './pages/SwipePage';
import MobileLandingPage from './pages/MobileLandingPage';
import TimelinePage from './pages/TimelinePage';
import ChatBotPage, {ChatBotHeaderBar} from './pages/ChatBotPage';
import AdvAnnouncement from './components/adventure/AdvAnnouncement';
import CardCollectionPage from './pages/CardCollectionPage';
import BottomTutorial from './components/BottomTutorial';
import EditProfilePage from './pages/EditProfilePage';
import AccountRequestPage from './pages/AccountRequestPage';
import Translate from './Translate';


function TabContainer({ children, dir })
{
	return (
		<div dir={dir} style={{ }}>
			{children}
		</div>
	);
}
  
TabContainer.propTypes =
{
	children: PropTypes.node.isRequired,
	dir: PropTypes.string.isRequired,
};

const styles = theme => ({
	root: {
	  backgroundColor: theme.palette.background.paper,
	},
	tabsRoot: {
		position: 'absolute',
		top: '0px',
		zIndex: '1',
		backgroundColor: theme.palette.background.paper,
		boxShadow: '0 1px 3px 0px rgba(0, 0, 0, 0.08)',
		width: '100%',
	},
	filterButtonRoot: {
		height: '100%',
		fontWeight: '700',
		fontSize: '12px',
		paddingLeft: '8px',
		paddingRight: '8px'
	},
	mapButtonRoot: {
		height: '100%',
		fontWeight: '700',
		fontSize: '12px',
		paddingLeft: '8px',
		paddingRight: '8px'
	},
	bottomNavRoot : {
		height: '56px',
		zIndex: 1
	},
	selectedIconOnly : {
		paddingTop: '8px'
	},
	badge: {
		position: 'absolute',
		top: '19px',
		left: '71px'
	},
	textField:
	{
		width: '100vw',
		border: '1px solid #e0e0e0',
		borderLeft: '0px solid white',
		borderRight: '0px solid white',
		//borderRight: '1px solid white',
		paddingBottom: '4px',
		paddingTop: '4px',
		paddingLeft: '12px',
		paddingRight: '12px',
		/*borderRadius: '4px',*/
		/*boxShadow: '0 1px 3px 0 rgba(0,0,0,.08)',*/
		backgroundColor: '#fbfbfb',
		//position: 'absolute',
		marginLeft: 0,
		borderRadius: 0,
		//top: 8
	},
});

@observer
class App extends AppBase
{
	renderedLocation;

	@observable headerBarMode = 0; // 0 == mktClaim, 1 == transition to filter mode, 2 === filter
	headerBarModeTriggered = false;

	lastScrollTop = 0;
	showMenus = false;
	areTabsSticky = false;
	disableScrolling = false; // use when horizontal scrolling is triggered
	disableScrollingTimer; // use when horizontal scrolling is triggered

	@observable searchHasFocus = false;

	heightOnLoad;
	siteBottomClassInit = "";

	addedNearbyLocations = [];

	static contextTypes =
	{
		router: PropTypes.object
	}

	constructor(props)
	{
		super(props);

		AppState.instance.appInstance = this;

		$(window).scroll(this.OnScroll);

		if (window.location.pathname.indexOf("/profile") >= 0 ||
			AppState.instance.appContainer.urlFilterSetup)
		{
		}
		else
		{
			AppState.instance.contentStore.Pause();
		}
	}

	componentDidMount()
	{
		this.SetTabsSticky(true);

		if (AppState.instance.deviceInfo.ios)
			$(".filterOverlay").css('top', '-82px'); // Move the button out of the lower 56px special apple area, otherwise the user must tab twice

		if (AppState.instance.appContainer.urlFilterSetup)
			this.OnTabbarChanged(undefined, AppState.TabIndexExplore);
		else
			this.OnTabbarChanged(undefined, AppState.TabIndexSwipe);

		this.renderedLocation = AppState.instance.appContainer.GetCurrentLocation();
		this.AdjustUiToUrl(true);

		if (AppState.instance.mainPageInstance)
			AppState.instance.mainPageInstance.OnAuthDone();

		AppState.instance.onWeatherChanged.Add(this.OnWeatherChanged);

		this.OnNearbyLocationsChanged();
		AppState.instance.advManager.onNearbyLocationsChanged.Add(this.OnNearbyLocationsChanged);

		if (AppState.instance.startedWithActivity)
			this.OnStartedWithActivity();
	}

	componentWillUnmount()
	{
		AppState.instance.advManager.onNearbyLocationsChanged.Remove(this.OnNearbyLocationsChanged);
		AppState.instance.onWeatherChanged.Remove(this.OnWeatherChanged);
	}

	componentWillReceiveProps(nextProps)
	{
		// will be true
		const newLocation = AppState.instance.appContainer.GetCurrentLocation();
		if (newLocation !== this.renderedLocation)
		{
			this.renderedLocation = newLocation;
			//console.log("LOC CHANGED: " + newLocation);
			this.AdjustUiToUrl();
		}
	}

	shouldComponentUpdate(nextProps, nextState)
	{
		// Prevents some refresh bugs
		return true;
	}

	OnStartedWithActivity()
	{
		if (!AppState.instance.swipePageInstance)
		{
			console.log("STRANGE!!!");
			setTimeout(() => {
				this.OnStartedWithActivity();
			}, 100);
			return;
		}

		console.log("OnStartedWithActivity");

		const toLoadName = AppState.instance.startedWithActivityName;
		Tracking.OnPageView("activity/" + toLoadName);

		AppState.instance.contentStore.GetActivityDetails(toLoadName, (details) =>
		{
			this.isLoading--;
			if (details)
			{
				AppState.instance.swipePageInstance.AddActivityCardDetails(details, true, true, this.OnStartedWithActivityEnd);	
				AppState.instance.startedWithActivityProcessed = true;

				if (!Tutorial.HasFinishedSwipeTutorial())
				{
					setTimeout(() => {
						AppState.instance.swipePageInstance.NudgeCard();
					}, 1000);
				}
			}

			// Tracking.SendEvent("contentDetails",
			// {
			// 	activityId: details.activity.id,
			// 	latitude: details.activity.latitude,
			// 	longitude: details.activity.longitude
			// });
		});
	}

	OnStartedWithActivityEnd = () =>
	{
		if (AppState.instance.startedWithActivity)
		{
			console.log("OnStartedWithActivityEnd");
			Tracking.FlushVisibleItemsTracking();
			AppState.instance.startedWithActivity = false;
			AppState.instance.appContainer.GoTo("/");
			AppState.instance.appContainer.forceUpdate();
			this.forceUpdate();
		}
	}

	OnNearbyLocationsChanged = () =>
	{
		//console.log("App.OnNearbyLocationsChanged");

		var processNow = true;
		if (!AppState.instance.swipePageInstance)
		{
			processNow = false;
		}
		else
		{
			if (AppState.instance.isInAuthProcess || AppState.instance.swipePageInstance.IsBusy())
			{
				processNow = false;
			}
		}

		if (!processNow)
		{
			//console.log("waiting with OnNearbyLocationsChanged");
			setTimeout(() => {
				this.OnNearbyLocationsChanged();
			}, 100);
			return;	
		}

		if (!AppState.instance.GeneralDataLoadingEnabled())
		{
			setTimeout(() => {
				this.OnNearbyLocationsChanged();
			}, 1000);
			return;	
		}

		if (Tutorial.IsActive())
		{
			//console.log("waiting with OnNearbyLocationsChanged for tutorial");
			setTimeout(() => {
				this.OnNearbyLocationsChanged();
			}, 1000);
			return;	
		}

		console.log("executing OnNearbyLocationsChanged");

		const newLocations = AppState.instance.advManager.GetNewNearbyLocations(true);

		for (var i = 0; i < newLocations.length; ++i)
		{
			const loc = newLocations[i];
			if ((loc.categoryIds && loc.categoryIds.length === 1) ||
				(loc.type === "content"))
			{
				if (loc.distance.distanceValue < 500 /*meters*/)
				{
					// Show as swipe card
					var activityId;
					if (loc.type === "content")
						activityId = loc.id;
					else
						activityId = loc.categoryIds[0];

					if (!this.addedNearbyLocations.includes(activityId))
					{
						console.log("adding nearby location: " + activityId);
						//if (loc.type === "content")
						//das sind keine details, sondern contentitem!
						//	AppState.instance.swipePageInstance.AddActivityCardDetails(loc, true, true);
						//else
						AppState.instance.swipePageInstance.AddActivityCard(activityId, true, true);

						this.addedNearbyLocations.push(activityId);
					}
				}
			}
		}
	}

	OnProfileClicked(username)
	{
		const url = "/profile/" + username;
		AppState.instance.appContainer.context.router.history.push(url);
	}

	OnWeatherChanged = () =>
	{
		this.forceUpdate();
	}

	OnHistoryChanged()
	{
		
	}

	OnAdvAnnouncementCta = () =>
	{
		AppState.instance.advManager.OnAdvAnnouncementCta();
		AppState.instance.screenMessage.CloseDialog();
		AppState.instance.appContainer.GoTo("/");
		this.OnTabbarChanged(undefined, AppState.TabIndexAdventure);
	}

	OnAdvAnnouncementLogo = () =>
	{
		AppState.instance.screenMessage.CloseDialog();
		AppState.instance.appContainer.GoTo("/");
	}

	AdjustUiToUrl(firstTime)
	{
		var newSearchFieldValue;

		const currentLocation = window.location.href;
		const searchIdx = currentLocation.indexOf("/search");
		if (searchIdx >= 0)
		{
			var q = currentLocation.substring(searchIdx + 7);
			if (q.indexOf("?q=") === 0 || q.indexOf("&q=") === 0)
				q = q.substring(3);
			else
				q = "";
			q = decodeURI(q);

			newSearchFieldValue = q;
		}

		var locInfo = Utils.ExtractLocationInfo(window.location.pathname);

		if (newSearchFieldValue)
		{
			//console.log("setting search field to: " + newSearchFieldValue);
			
			//$("#searchInput").val(newSearchFieldValue);
			AppState.instance.lastEnteredSearchString = newSearchFieldValue;
			AppState.instance.contentStore.Search(newSearchFieldValue);

			this.HideCover();
		}

		if (AppState.instance.mainPageInstance)
			AppState.instance.mainPageInstance.MatchTabWithLocation();

		//TODO: use different solution to show profiles (normal popup window?)
		if (window.location.pathname.indexOf("/profile") >= 0)
		{
			const username = locInfo.username;
			this.OnTabbarChanged(undefined, AppState.TabIndexSwipe);

			if (AppState.instance.swipePageInstance)
				AppState.instance.swipePageInstance.AddCard(ProfileComponent, {username: username}, ProfileComponent.OnThrowCard, true);
		}

		if (window.location.pathname.indexOf("/timeline") >= 0)
		{
			//console.log(window.location.search);
			this.OnTabbarChanged(undefined, AppState.TabIndexTimeline);
		}

		if (window.location.pathname.indexOf("/adventures") >= 0 ||
			window.location.pathname.indexOf("/abenteuer") >= 0)
		{
			AppState.instance.screenMessage.ShowDialog(undefined, AdvAnnouncement, undefined, undefined, undefined, {
				onCta: this.OnAdvAnnouncementCta,
				onLogo: this.OnAdvAnnouncementLogo,
				showLenaIcon: true
			}, false, undefined, true);
		}

		if (window.location.pathname === "/editprofile")
		{
			//this.OnTabbarChanged(undefined, AppState.TabIndexProfile);
			if (AppState.instance.IsLoggedIn())
			{
				AppState.instance.screenMessage.ShowDialog(Translate.T('en.Edit profile', 'de.Profil bearbeiten', 'editprofile'), EditProfilePage);
			}
			else
			{
				AccountRequestPage.Show(undefined, undefined, "/editprofile");
			}
		}

		if (this.searchHasFocus)
		{
			this.OnSearchFocus();

			// setTimeout(() => {
			// 	$("#searchInput").val(AppState.instance.lastEnteredSearchString);
			// 	// We are not setting the focus on the input field so the user can better view the search results after coming back from a detailpage
			// }, 250);			
		}

		this.forceUpdate();
	}

	GoTo(url, push)
	{
		AppState.instance.appContainer.GoTo(url, push);
	}

	GoBack()
	{
		AppState.instance.appContainer.GoBack();
	}

	OnBottomNavigationChanged = (event, index) =>
	{
		this.OnTabbarChanged(event, index);

		event.stopPropagation();
		event.preventDefault();
	}

	OnMobileLandingPageDone()
	{
		this.OnTabbarChanged(undefined, AppState.TabIndexSwipe);
		this.forceUpdate();
	}

	OnTabbarChanged = (event, index) =>
	{
		/*if (index === AppState.TabIndexProfile)
		{
			const isLoggedIn = AppState.instance.IsLoggedIn();
			if (!isLoggedIn)
			{
				var msg;
				AccountRequestPage.Show(msg);
				return;
			}
		}*/

		if (AppState.instance.startedWithActivity && AppState.instance.startedWithActivityProcessed)
		{
			this.OnStartedWithActivityEnd();
		}

		if (index !== AppState.TabIndexSwipe && (window.location.pathname.indexOf("/profile") >= 0))
		{
			AppState.instance.appContainer.context.router.history.push("/");
		}

		AppState.instance.previousMainTab = AppState.instance.currentMainTab;

		if (index !== AppState.instance.previousMainTab)
		{
			this.HideCover();

			this.OnLeaveSearch();

			if (AppState.instance.contentStore.IsPaused() && !Tutorial.IsActive())
				AppState.instance.contentStore.Resume();

			//TEMP:
			// if (!Tutorial.IsActive() && AppState.instance.bottomTutorialInstance &&
			// 	!AppState.instance.startedWithActivity)
			// 	AppState.instance.bottomTutorialInstance.Start();
		}

		var oldPage = AppState.instance.GetPageByIndex(AppState.instance.previousMainTab);
		if (oldPage && oldPage.OnDeactivate)
			oldPage.OnDeactivate();

		//console.log("OnTabbarChanged: " + index);
		AppState.instance.currentMainTab = index;

		if (!AppState.instance.isLandingPage &&
			!AppState.instance.isMobileLandingPage &&
			!AppState.instance.isProviderPage)
		{
			if (index === AppState.TabIndexSwipe)
			{
				$("#root").addClass("rootDisableScroll");
			}
			else
			{
				$("#root").removeClass("rootDisableScroll");
			}
		}

		var newPage = AppState.instance.GetPageByIndex(index);
		if (newPage && newPage.OnActivate)
			newPage.OnActivate();

		if (!AppState.instance.isLandingPage &&
			!AppState.instance.isProviderPage)
			Utils.ScrollToTop();

		if (index === AppState.TabIndexTimeline)
		{
			$("#timelinePage").css('display', 'block');
			$("#swipePage").css('display', 'none');
			$("#mainPage").css('display', 'none');
			$("#bookmarkPage").css('display', 'none');
			$("#journalPage").css('display', 'none');
			$(".filterOverlay").css('display', 'none');

			Tracking.OnPageView("timeline");
		}
		else if (index === AppState.TabIndexSwipe)
		{
			$("#timelinePage").css('display', 'none');
			$("#swipePage").css('display', 'block');
			$("#mainPage").css('display', 'none');
			$("#bookmarkPage").css('display', 'none');
			$("#journalPage").css('display', 'none');
			$(".filterOverlay").css('display', 'none');

			Tracking.OnPageView("swipe");
		}
		else if (index === AppState.TabIndexExplore)
		{
			$("#timelinePage").css('display', 'none');
			$("#swipePage").css('display', 'none');
			$("#mainPage").css('display', 'block');
			$("#bookmarkPage").css('display', 'none');
			$("#journalPage").css('display', 'none');
			$(".filterOverlay").css('display', 'block');

			Tracking.OnPageView("explore");
		}
		else if (index === AppState.TabIndexBookmark)
		{
			$("#timelinePage").css('display', 'none');
			$("#swipePage").css('display', 'none');
			$("#mainPage").css('display', 'none');
			$("#bookmarkPage").css('display', 'block');
			$("#journalPage").css('display', 'none');
			$(".filterOverlay").css('display', 'block');

			Tracking.OnPageView("bookmark");
		}
		else if (index === AppState.TabIndexAdventure)
		{
			$("#timelinePage").css('display', 'none');
			$("#swipePage").css('display', 'none');
			$("#mainPage").css('display', 'none');
			$("#bookmarkPage").css('display', 'none');
			$("#journalPage").css('display', 'none');
			$(".filterOverlay").css('display', 'block');

			Tracking.OnPageView("adventure");
		}
		/*else if (index === AppState.TabIndexJournal)
		{
			$("#timelinePage").css('display', 'none');
			$("#swipePage").css('display', 'none');
			$("#mainPage").css('display', 'none');
			$("#bookmarkPage").css('display', 'none');
			$("#journalPage").css('display', 'block');
			$(".filterOverlay").css('display', 'none');

			Tracking.OnPageView("journal");
		}*/
		else if (index === AppState.TabIndexProfile)
		{
			$("#timelinePage").css('display', 'none');
			$("#swipePage").css('display', 'none');
			$("#mainPage").css('display', 'none');
			$("#bookmarkPage").css('display', 'none');
			$("#journalPage").css('display', 'none');
			$(".filterOverlay").css('display', 'none');

			Tracking.OnPageView("account");
		}

		AppState.instance.OnScreenScrolled(this);
	}

	OnSearch = (searchTerm, onDone) =>
	{
		AppState.instance.lastEnteredSearchString = searchTerm;

		//console.log($("#siteContent").offset().top - $("#tabsContainer").outerHeight());
		$('html, body').animate({
			scrollTop: $("#siteContent").offset().top - $("#tabsContainer").outerHeight()
		}, 300);

		if (!AppState.instance.isInLocationSearch)
		{
			AppState.instance.userProfile.OnSearch(searchTerm);
			AppState.instance.contentStore.Search(searchTerm);

			if (!AppState.instance.deviceInfo.desktop)
			{
				var newLocation = '/search?q=' + encodeURI(searchTerm);
				if (searchTerm === "")
					newLocation = '/search';

				const currentLoc = AppState.instance.appContainer.GetCurrentLocation();
				if (currentLoc.indexOf("/search") >= 0)
				{
					var parts = currentLoc.split('/');
					var newUrl = "";
					for (var i = 0; i < parts.length; ++i)
					{
						if (parts[i].length === 0)
							continue;

						if (parts[i].indexOf("search") === 0)
							newUrl += newLocation;
						else
							newUrl += "/" + parts[i];
					}
					this.context.router.history.replace(newUrl);
				}
				else
				{
					if (currentLoc === "/")
						this.context.router.history.push(newLocation);
					else
						this.context.router.history.push(currentLoc + newLocation);
				}
			}
		}

		AppState.instance.mainPageInstance.OnSearch(searchTerm);
	}

	OnSearchFocus = () =>
	{
		this.searchHasFocus = true;

		if (!AppState.instance.deviceInfo.desktop)
		{
			this.SetSiteBottomVisibility(false);
		}
		AppState.instance.mainPageInstance.OnSearchFocus();
	}

	OnSearchBlur = () =>
	{
		// if (!AppState.instance.deviceInfo.desktop)
		// {
		// 	this.SetSiteBottomVisibility(true);
		// }
		AppState.instance.mainPageInstance.OnSearchBlur();
	}

	OnLeaveSearch = () =>
	{
		this.searchHasFocus = false;

		if (!AppState.instance.deviceInfo.desktop)
		{
			setTimeout(() => {
				this.SetSiteBottomVisibility(true);	
			}, 500);
		}
		if (AppState.instance.mainPageInstance)
			AppState.instance.mainPageInstance.OnLeaveSearch();
	}

	SetSiteBottomVisibility(show, force)
	{
		if (!AppState.instance.deviceInfo.phone)
			show = true;

		if (this.searchHasFocus)
			show = true;

		var topFlag = show;
		if (force !== true)
		{
			if (AppState.instance.currentMainTab !== AppState.TabIndexExplore)
				topFlag = true;
			else if ($(window).height() >= 600)
				topFlag = true;
		}

		this.showMenus = show;

		var siteBottomDOM = document.getElementById('siteBottom');
		if (siteBottomDOM)
		{
			var classPrefix = "siteBottom" + AppState.instance.currentMainTab + " ";
			if (this.searchHasFocus)
			{
				siteBottomDOM.className = classPrefix + "siteBottomHidden2";
				this.siteBottomClassInit = "siteBottomHidden2";
			}
			else if (topFlag)
			{
				siteBottomDOM.className = classPrefix;
				this.siteBottomClassInit = "";
			}
			else
			{
				siteBottomDOM.className = classPrefix + "siteBottomHidden";
				this.siteBottomClassInit = "siteBottomHidden";
			}
		}

		if (this.areTabsSticky)
		{
			this.SetTopMenuVisibility(show);
		}
	}

	SetTopMenuVisibility(show)
	{
		// Show searchbar when search-tab is visible
		if (AppState.instance.currentMainTab === AppState.TabIndexExplore)
		{
		}
		else
		{
			show = false;
		}

		if (AppState.instance.currentMainTab === AppState.TabIndexBookmark)
			show = true;

		var searchBarDOM = document.getElementById('searchBarContainer');
		if (searchBarDOM)
		{
			if (show)
				searchBarDOM.className = "";
			else
				searchBarDOM.className = "searchBarHidden";
		}

		var tabsContainerDOM = document.getElementById('tabsContainer');
		if (tabsContainerDOM)
		{
			if (show)
				tabsContainerDOM.className = "";
			else
				tabsContainerDOM.className = "tabsContainerHidden";
		}
	}

	SetTabsSticky(sticky)
	{
		if (!this.areTabsSticky && sticky && this.showMenus)
		{
			console.log("-> STICKY");

			this.SetTopMenuVisibility(false);
		}
		if (this.areTabsSticky && !sticky)
		{
			console.log("-> not STICKY");

			this.SetTopMenuVisibility(true);
		}

		this.areTabsSticky = sticky;
	}

	GetSiteBottomVisibility()
	{
		if (!AppState.instance.deviceInfo.phone)
			return true;

		var siteBottomDOM = document.getElementById('siteBottom');
		return (siteBottomDOM.className !== "siteBottomHidden");
	}

	OnFilterCloseRequest = () =>
	{
		HeaderBar.instance.SetMetaPosition(1);
	}

	OnScroll = () =>
	{
		const st = $(window).scrollTop();
		const delta = st - this.lastScrollTop;

		if (delta < 0)
		{
			this.SetSiteBottomVisibility(true);
		}
		else if (st > 0)
		{
			this.SetSiteBottomVisibility(false);
		}

		this.lastScrollTop = st;
	}

	OnOpenFilter = () =>
	{
		setTimeout(() => {
			FilterPage.Show();	
		}, 60);
	}

	OnOpenMap = () =>
	{
		setTimeout(() => {
			this.OnOpenMapInternal();
		}, 150);
	}
	OnOpenMapInternal()
	{
		MapPage.Show();
	}

	renderSiteContent()
	{
		var firstPage;
		//if (AppState.instance.featureSocial)
		//{
			if (AppState.instance.chatBotActive)
				firstPage = (<ChatBotPage/>);
			else
				firstPage = (<TimelinePage/>);
		//}

		//console.log("index: " + AppState.instance.currentMainTab);
		return (
			<div id="siteContent" className={this.headerBarMode === 0 ? "siteContentInitial" : ""}>
				<SwipeableViews
					axis={'x'}
					index={AppState.instance.currentMainTab}
					disabled={true}
					animateTransitions={false}
				>
					{firstPage}

					<SwipePage/>

					<MainPage />

					<BookmarkPage/>

					{/*<JournalPage/> */}

					<ProfilePage/>

				</SwipeableViews>

			</div>
		);
	}

	renderStickyContainerContent()
	{
		//console.log("renderStickyContainerContent: " + AppState.instance.currentMainTab);
		return (
			<div>
				{AppState.instance.currentMainTab === AppState.TabIndexTimeline &&
				AppState.instance.isShowingDetailPage === 0 &&
					<ChatBotHeaderBar />
				}

				{AppState.instance.currentMainTab === AppState.TabIndexExplore &&
				AppState.instance.isShowingDetailPage === 0 &&
				!AppState.instance.isMapPageVisible &&
					<TopHeader />
				}

				{AppState.instance.currentMainTab === AppState.TabIndexExplore &&
					AppState.instance.mainPageInstance &&
					AppState.instance.isShowingDetailPage === 0 &&
					!AppState.instance.isMapPageVisible &&
					<FilterBar />
				}

				{!AppState.instance.isMobileLandingPage && !AppState.instance.startedWithActivity &&
					<Route path="/activity/:title" component={DetailPage} />}

				{!AppState.instance.isMobileLandingPage && <MapPage />}

				{/*AppState.instance.currentMainTab === AppState.TabIndexJournal && JournalPage.RenderTabs()*/}

				{AppState.instance.currentMainTab === AppState.TabIndexBookmark && AppState.instance.bookmarkPageInstance &&
					AppState.instance.isShowingDetailPage === 0 &&
					AppState.instance.bookmarkPageInstance.RenderSubPage()}

				{AppState.instance.currentMainTab === AppState.TabIndexAdventure && AppState.instance.adventuresPageInstance &&
					!AppState.instance.deviceInfo.desktop &&
					AppState.instance.adventuresPageInstance.RenderTopBar()}

				{AppState.instance.currentMainTab === AppState.TabIndexAdventure && /*AppState.instance.cardCollectionPageInstance &&*/
					!AppState.instance.deviceInfo.desktop &&
					CardCollectionPage.RenderTopBar()}

				{this.renderSiteContent()}
			</div>
		);
	}

	HideCover()
	{
		Utils.ScrollToTop();

		if (!Tutorial.IsActive())
			AppState.instance.contentStore.Resume();
		Tutorial.OnCoverLifted();
	}

	OnTutorialEnded()
	{
		//ApiInterface.SendCommand("action", "activity.start");
		this.forceUpdate();
		AppState.instance.contentStore.Resume();

		//TEMP:
		//AppState.instance.bottomTutorialInstance.Start();
	}

	OnHorizontalScrolling()
	{
		//console.log("SCROLL");
		// a horizontal container is being scrolled, disable vertical scrolling
		if (AppState.instance.deviceInfo.ios)
		{
			this.disableScrolling = true;

			// re-enable scrolling after N milliseconds, except if horizontal scrolling is happening again
			this.disableScrollingTimer = Utils.SetTimer(this.disableScrollingTimer, 200, () =>
			{
				this.disableScrolling = false;
			}, 50);
		}
	}

	OnHorizontalScrollingEnd()
	{
		//console.log("END");
		// enable vertical scrolling
		if (AppState.instance.deviceInfo.ios)
			this.disableScrolling = false;
	}

	render()
	{
		//console.log("App.render: " + this.renderedLocation);

		//var iconChat = AppState.instance.currentMainTab === 0 ? "chat_bubble" : "chat_bubble_outline";
		var iconChat = <div className="menuIconChat" style={{width: 24, height: 24}}>{Utils.RenderCardIcon(false, 24, 24, "#e31c46")}</div>;
		if (AppState.instance.currentMainTab !== AppState.TabIndexSwipe)
			iconChat = <div className="menuIconChat" style={{width: 24, height: 24}}>{Utils.RenderCardIcon(true, 24, 24, "rgba(0, 0, 0, 0.54)")}</div>;

		var iconExplore;
		if (AppState.instance.currentMainTab === AppState.TabIndexExplore)
			iconExplore = <div className="menuIconExplore" style={{width: 24, height: 24}}>{Utils.RenderSearchIcon(false, 24, 24, "#e31c46")}</div>;
		else
			iconExplore = <div className="menuIconExplore" style={{width: 24, height: 24}}>{Utils.RenderSearchIcon(true, 24, 24, "rgba(0, 0, 0, 0.54)")}</div>;

		var iconSaved = <div className="menuIconChat" style={{width: 24, height: 24}}>{Utils.RenderStarIcon(24, 24, "#e31c46", "#e31c46", 2.5)}</div>;
		if (AppState.instance.currentMainTab !== AppState.TabIndexBookmark)
			iconSaved = <div className="menuIconChat" style={{width: 24, height: 24}}>{Utils.RenderStarIcon(24, 24, "rgba(0, 0, 0, 0.54)", "rgba(0, 0, 0, 0)", 2.5)}</div>;

		// var iconJournals = <div className="menuIconJournal" style={{width: 24, height: 24}}>{Utils.RenderAddCircleIcon(24, 24, "#e31c46", false)}</div>;
		// if (AppState.instance.currentMainTab !== AppState.TabIndexJournal)
		// 	iconJournals = <div className="menuIconJournal" style={{width: 24, height: 24}}>{Utils.RenderAddCircleIcon(24, 24, "rgba(0, 0, 0, 0.54)", true)}</div>;
		
		// var iconProfile;
		// if (AppState.instance.currentMainTab === AppState.TabIndexProfile)
		// 	iconProfile = <div className="menuIconProfile" style={{width: 24, height: 24}}><PersonIcon /></div>;
		// else
		// 	iconProfile = <div className="menuIconProfile" style={{width: 24, height: 24}}><PersonOutlineIcon /></div>;

		var iconTimeline;
		if (AppState.instance.currentMainTab === AppState.TabIndexTimeline)
			iconTimeline = <div className="menuIconTimeline" style={{width: 24, height: 24}}>{Utils.RenderListIcon(24, 24, "#e31c46", "#e31c46", 2.5, 2.5)}</div>;
		else
			iconTimeline = <div className="menuIconTimeline" style={{width: 24, height: 24}}>{Utils.RenderListIcon(24, 24, "rgba(0, 0, 0, 0.54)", "none", 2.0, 2.0)}</div>;

		var iconAdventure = <div className="menuIconAdventure" style={{width: 24, height: 24}}>{Utils.RenderFireIcon(24, 24, "white", "white", "rgba(0, 0, 0, 0.54)", 2.0)}</div>;

		//var siteBottomClassInit = "siteBottomHidden";
		var siteBottomClassInit = this.siteBottomClassInit;
		if (AppState.instance.currentMainTab === AppState.TabIndexSwipe)
			siteBottomClassInit = "";
		if (!AppState.instance.deviceInfo.phone)
			siteBottomClassInit = "";

		return (
			<div id="app" className={"app" + AppState.instance.GetPlatformClasses()} >

				{/* {(AppState.instance.currentMainTab > 0) && this.renderIntroHeader()} */}

				{AppState.instance.isMobileLandingPage && <MobileLandingPage />}

				{!AppState.instance.isMobileLandingPage && this.renderStickyContainerContent()}
			
				{!AppState.instance.isMobileLandingPage && <div id="siteBottom" className={"siteBottom" + AppState.instance.currentMainTab + " " + siteBottomClassInit}>

					{/*AppState.instance.currentMainTab === AppState.TabIndexJournal && JournalPage.RenderFab()*/}
					{AppState.instance.currentMainTab === AppState.TabIndexBookmark && AppState.instance.bookmarkPageInstance && AppState.instance.bookmarkPageInstance.RenderFab()}

					<BottomNavigation
						className={this.props.classes.bottomNavRoot}
						value={AppState.instance.currentMainTab}
						onChange={this.OnBottomNavigationChanged}
						showLabels={false}
						id="main_tabbar"
					>
						<BottomNavigationAction value={AppState.TabIndexTimeline} showLabel={false}
							classes={{selectedIconOnly: this.props.classes.selectedIconOnly}}
							icon={iconTimeline} />

						<BottomNavigationAction value={AppState.TabIndexSwipe} showLabel={false} classes={{selectedIconOnly: this.props.classes.selectedIconOnly}}
							icon={iconChat} />

						<BottomNavigationAction value={AppState.TabIndexExplore} showLabel={false} classes={{selectedIconOnly: this.props.classes.selectedIconOnly}}
							icon={iconExplore} />

						<BottomNavigationAction value={AppState.TabIndexBookmark} showLabel={false} classes={{selectedIconOnly: this.props.classes.selectedIconOnly}}
							icon={<div>{iconSaved}{AppState.instance.showNewIndicator[AppState.TabIndexBookmark] && <div className={"newIndicator" + (AppState.instance.showNewIndicator[AppState.TabIndexBookmark] > 0 ? " newIndicatorNumber":"")}>
							{AppState.instance.showNewIndicator[AppState.TabIndexBookmark] > 0 && AppState.instance.showNewIndicator[AppState.TabIndexBookmark]}
							</div>}</div>} />

						{/* <BottomNavigationAction value={AppState.TabIndexJournal} showLabel={false} classes={{selectedIconOnly: this.props.classes.selectedIconOnly}}
							icon={<div>{iconJournals}{AppState.instance.showNewIndicator[AppState.TabIndexJournal] && <div className="newIndicator"></div>}</div>} /> */}

						{/* <BottomNavigationAction value={AppState.TabIndexProfile} showLabel={false} classes={{selectedIconOnly: this.props.classes.selectedIconOnly}}
							icon={<div>{iconProfile}{AppState.instance.showNewIndicator[AppState.TabIndexProfile] && <div className={"newIndicator" + (AppState.instance.showNewIndicator[AppState.TabIndexProfile] > 0 ? " newIndicatorNumber":"")}>
							{AppState.instance.showNewIndicator[AppState.TabIndexProfile] > 0 && AppState.instance.showNewIndicator[AppState.TabIndexProfile]}
							</div>}</div>} /> */}

						<BottomNavigationAction value={AppState.TabIndexAdventure} showLabel={false} classes={{selectedIconOnly: this.props.classes.selectedIconOnly}}
							icon={<div>{iconAdventure}{AppState.instance.showNewIndicator[AppState.TabIndexAdventure] &&
								<div className={"newIndicator" + (AppState.instance.showNewIndicator[AppState.TabIndexAdventure] > 0 ? " newIndicatorNumber":"")}>
							{AppState.instance.showNewIndicator[AppState.TabIndexAdventure] > 0 && AppState.instance.showNewIndicator[AppState.TabIndexAdventure]}
							</div>}</div>} />

					</BottomNavigation>
				</div>}

				{!AppState.instance.isMobileLandingPage && <SuggestEditPage/>}
				{!AppState.instance.isMobileLandingPage && <AddReviewPage/>}
				{!AppState.instance.isMobileLandingPage && <Tutorial/>}
				{!AppState.instance.isMobileLandingPage && <BottomTutorial/>}
				{!AppState.instance.isMobileLandingPage && <FilterPage/>}
				{!AppState.instance.isMobileLandingPage && <CardCollectionPage/>}
			</div>
		);
	}
}

export default withStyles(styles)(App);