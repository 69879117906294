import React, {Component} from 'react';
import {withStyles} from 'material-ui/styles';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import AppState from '../AppState';
import Timeline from '../components/Timeline';
import { ChatBot } from '../components/ChatBot';
import MyCommunity from '../components/desktop/MyCommunity';
//import Translate from '../Translate';
//import Button from 'material-ui/Button';
import $ from 'jquery';
import { observable } from '../../node_modules/mobx';
import Utils from '../Utils';

const styles = theme => ({

});

@observer
class TimelinePage extends Component
{
	username;
	timeline;
	onAuthDoneCalled = false; // Some hack needed because this feature is loaded late because of beta-test status
	_isMounted = false;
	selectedTimelineItem;
	selectedTimelineItemCounter = 0;
	selectedMapItem;
	selectedMapItemCounter = 0;
	@observable selectedUsername;
	myUsername;
	forcedItem;

	constructor(props)
	{
		super(props);

		AppState.instance.timelinePageInstance = this;
		AppState.instance.AddOnAuthDone(this.OnAuthDone);

		if (window.location.pathname.indexOf("/timeline") >= 0)
		{
			var params = Utils.ParseUrlQueryParams(false);

			this.forcedItem = params.i + "/" + params.u + "/" + params.t;
		}
	}

	componentDidMount()
	{
		this._isMounted = true;
		this.OnAuthDone();

		if (AppState.instance.deviceInfo.desktop)
		{
			this.OnScroll();
			$(window).on('scroll', this.OnScroll);
		}

		if (this.props.selectedUsername)
			this.ShowUsername(this.props.selectedUsername);
	}

	componentDidUpdate(prevProps)
	{
		if (this.props.selectedUsername && this.props.selectedUsername !== prevProps.selectedUsername)
		{
			this.ShowUsername(this.props.selectedUsername);
		}
		if (!this.props.selectedUsername && this.props.selectedUsername !== prevProps.selectedUsername && this.selectedUsername)
		{
			this.CloseUsername();
		}
	}
	
	componentWillUnmount()
	{
		if (AppState.instance.deviceInfo.desktop)
		{
			$(window).off('scroll', this.OnScroll);
		}

		this._isMounted = false;
	}

	ShowUsername(username)
	{
		this.selectedUsername = username;
	}

	CloseUsername()
	{
		this.selectedUsername = undefined;
	}

	OnAuthDone = () =>
	{
		if (AppState.instance.IsLoggedIn())
		{
			this.username = AppState.instance.userProfile.username;
			this.myUsername = this.username;
			if (this._isMounted)
				this.forceUpdate();
		}
		else
		{
			this.myUsername = undefined;
		}
		this.onAuthDoneCalled = true;
	}

	OnActivate()
	{
		if (!this.onAuthDoneCalled && AppState.instance.IsLoggedIn())
		{
			this.OnAuthDone();
		}
		else if (AppState.instance.loginDataChecked && !AppState.instance.IsLoggedIn())
		{
			this.username = "_";
			this.forceUpdate();
		}

		if (this.timeline)
			this.timeline.Refresh();

		//HACK:
		if (AppState.instance.chatBotManager.IsStateAvailable("lena"))
		{
			AppState.instance.chatBotActive = true;
			AppState.instance.appContainer.forceUpdate();
		}

		this.OnScroll();
	}

	OnDeactivate()
	{
	}

	OnTimelineInit = (instance) =>
	{
		this.timeline = instance;
	}

	OnChatBotActivated = (botInstance, newGoal) =>
	{
		AppState.instance.chatBotActive = true;
		AppState.instance.chatBotInitialGoal = newGoal;
		AppState.instance.appContainer.forceUpdate();
	}

	OnChatBotDeactivated = () =>
	{
		AppState.instance.chatBotActive = false;
		AppState.instance.chatBotInitialGoal = undefined;
		AppState.instance.appContainer.forceUpdate();
	}

	OnChatBotFinished = () =>
	{
		if (this.timeline)
			this.timeline.Refresh();
	}

	OnCommunityItemClicked = (item, itemType) =>
	{
		if (itemType === "timelineItem")
		{
			this.selectedTimelineItemCounter++;
			this.selectedTimelineItem = item;

			this.selectedMapItemCounter++;
			this.selectedMapItem = item;

			this.forceUpdate();
		}
	}

	OnScroll = () =>
	{
		if (!AppState.instance.deviceInfo.desktop)
			return;

		if (this.timeline)
		{
			const visibleId = this.timeline.GetMostVisibleItemId();
			if (visibleId !== undefined)
			{
				const index = this.timeline.GetIndexOfItemId(visibleId);
				if (index !== undefined)
				{
					this.OnMostVisibleItemChanged(this.timeline.items[index], index);
				}
			}
		}
	}

	OnMostVisibleItemChanged(item, index)
	{
		if (this.selectedMapItem && this.selectedMapItem.id === item.id)
			return;

		//console.log(item);
		this.selectedMapItemCounter++;
		this.selectedMapItem = item;
		this.forceUpdate();
	}

	OnCommunityPanelOpened = (panelName, username, expanded) =>
	{
		if (expanded)
		{
			this.selectedUsername = username;
		}
		else
		{
			this.selectedUsername = undefined;
		}
	}

	OnShowUserPosts = (username) =>
	{
		this.selectedUsername = username;
		Utils.ScrollToTop();

		if (!username)
			if (this.props.onUserClosed)
	 			this.props.onUserClosed();
	}

	render()
	{
		var s = {};
		if (AppState.instance.currentMainTab !== AppState.TabIndexTimeline)
			s = {'display': 'none'};

		return (
			<div id="timelinePage" className={"timelinePage" + AppState.instance.GetPlatformClasses() +
				(AppState.instance.chatBotActive ? " chatBotActive": " chatBotInactive")} key="timelinePage" style={s}>

				{/*!AppState.instance.deviceInfo.desktop &&*/
				<ChatBot
					intro={!AppState.instance.deviceInfo.desktop}
					id="intro"
					onActivated={this.OnChatBotActivated}
					onDeactivated={AppState.instance.deviceInfo.desktop ? this.OnChatBotDeactivated : undefined}
					onFinished={AppState.instance.deviceInfo.desktop ? this.OnChatBotFinished : undefined}
				/>}			

				{AppState.instance.GeneralDataLoadingEnabled() &&
					<Timeline
						active={AppState.instance.currentMainTab === AppState.TabIndexTimeline}
						username={this.selectedUsername || this.username}
						isOwnUser={this.selectedUsername === this.myUsername ? true : undefined}
						onInit={this.OnTimelineInit}
						selectedItem={this.selectedTimelineItem}
						selectedItemCounter={this.selectedTimelineItemCounter}
						onMostVisibleItemChanged={this.OnMostVisibleItemChanged}
						forcedItem={this.forcedItem}
					/>}

				{AppState.instance.deviceInfo.desktop &&
				<div className="myCommunityContainer">
					<MyCommunity
						onItemClicked={this.OnCommunityItemClicked}
						selectedItem={this.selectedMapItem}
						selectedItemCounter={this.selectedMapItemCounter}
						selectedUsername={this.selectedUsername}
						onPanelOpened={this.OnCommunityPanelOpened}
						onShowUserPosts={this.OnShowUserPosts}
					/>
				</div>}

			</div>
		);
	}
}

TimelinePage.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(TimelinePage);