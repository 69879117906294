import React from 'react';
import {observer} from 'mobx-react';
import AppState from '../AppState';
import ReactIScroll from '../iscroll/react-iscroll';
import iScroll from 'iscroll/build/iscroll-probe.js';


@observer
export default class HorizontalScroller extends React.Component
{
	//@observable items = [];
	iScrollInstance;
	//renderedItemCount = 0;
	//isAutoScroll = false;
	//useMomentum = false;

	componentDidMount()
	{
		//this.useMomentum = $(window).width() > 430;
	}

	// componentWillReceiveProps(nextProps)
	// {
	// 	this.items = nextProps.items;
	// }

	OnScrollRefresh = (iScrollInstance) =>
	{
		this.iScrollInstance = iScrollInstance;
	};

	OnScroll = () =>
	{
		AppState.instance.OnScreenScrolled(this);
	}

	OnScrollIScroll = () =>
	{
		AppState.instance.OnScreenScrolled(this);
		if (AppState.instance.appInstance)
			AppState.instance.appInstance.OnHorizontalScrolling();
	}

	OnScrollEndIScroll = () =>
	{
		if (AppState.instance.appInstance)
			AppState.instance.appInstance.OnHorizontalScrollingEnd();
	}

	render()
	{
		if (AppState.instance.deviceInfo.ios && this.props.allowIScroll)
		{
			setTimeout(() =>
			{
				if (this.iScrollInstance)
					this.iScrollInstance.refresh();
			}, 100);

			var iScrollOptions = {
				mouseWheel: true,
				scrollbars: false,
				scrollX: true,
				scrollY: false,
				freeScroll: false,
				fadeScrollbars: true,
				bounce: true,
				momentum: true,
				eventPassthrough: true,
				//preventDefault: false,
				tap: true,
				click: true,
				interactiveScrollbars: false,
				probeType: 3,
			};

			return (
				<ReactIScroll
					key={this.props.key}
					id={"horizontalscroller"}
					className={"horizontalscroller ios " + this.props.className}
					iScroll={iScroll}
					options={iScrollOptions}
					onScrollEnd={this.OnScrollEndIScroll}
					onRefresh={this.OnScrollRefresh}
					onScroll={this.OnScrollIScroll}
				>
					<div className={(this.props.defaultClassName || "searchResultCards") + " ios " + this.props.className}>
						{this.props.children}
					</div>
				</ReactIScroll>
			);
		}
		else
		{
			var scrollProp = {
				overflowX: 'auto'
			};
			return (
				<div className={(this.props.defaultClassName || "searchResultCards") + " noneios " + this.props.className}
					style={scrollProp}
					onScroll={this.OnScroll}
				>
					{this.props.children}
				</div>
			);
		}
	}
}
