import React from 'react';
import Translate from '../../Translate';
import StepBase from './StepBase';
import Rating from '../Rating';
import TextField from 'material-ui/TextField';
import Button from 'material-ui/Button';

export default class StepSelectReview extends StepBase
{
	GetId()
	{
		return "review";
	}

	GeneratedDataIds()
	{
		return ["rating", "desc"];
	}

	Undo(contextData)
	{
		delete contextData["rating"];
		delete contextData["desc"];
		delete contextData["rating_temp"];
		delete contextData["desc_temp"];
	}

	OnStartEditInternal(contextData)
	{
		contextData["rating_temp"] = contextData["rating"];
		contextData["desc_temp"] = contextData["desc"];
	}

	renderStepLena(contextData)
	{
		return this.renderLenaMsg(Translate.T("de.Wie hat es Euch gefallen?", "en.How was it?", "createxp.howWasIt"));
	}

	renderUserSelection(contextData)
	{
		if (contextData.descSkipped === true)
		{
			return this.renderUserMsg(Translate.T("de.Überspringen", "en.Skip", "skip"), contextData);
		}

		const rating = contextData["rating"] || 5;
		const text = contextData["desc"];

		var label = (
			<span className="review">
				<Rating
					value={rating}
					editing={false}
					starColor={'#e31c46'}
					size={16}
				/>
				{text !== undefined && <span className="reviewText">{text}</span>}
			</span>
		);

		return this.renderUserMsg(label, contextData);
	}

	OnStarClick = (contextData) => (nextValue) =>
	{
		contextData["rating_temp"] = nextValue;
	}

	OnTextChanged = (contextData) => (event) =>
	{
		contextData["desc_temp"] = event.target.value;
	}

	OnDescriptionDone = (contextData) => () =>
	{
		const rating = contextData["rating_temp"] || 5;
		const desc = contextData["desc_temp"];

		delete contextData["rating_temp"];
		delete contextData["desc_temp"];
		delete contextData["descSkipped"];

		this.OnStepDone(contextData, {
			rating: rating,
			desc: desc
		});
	}

	OnSkip = (contextData) => () =>
	{
		delete contextData["rating_temp"];
		delete contextData["desc_temp"];

		this.OnStepDone(contextData, {
			descSkipped: true
		});
	}

	renderUserOptions(contextData)
	{
		const rating = contextData["rating_temp"] || 5;
		const defaultText = contextData["desc_temp"];
		const isEdit = this.IsCurrentlyEdited(contextData);

		return (
			<div className="stepReview">

				{isEdit && this.renderCancelEditButton(contextData)}

				<div className="rating">
					<Rating
						value={rating}
						editing={true}
						onStarClick={this.OnStarClick(contextData)}
						starColor={'#e31c46'}
						size={32}
					/>
				</div>

				<div className="desc">
					<TextField
						key={"descText"}
						multiline
						InputLabelProps={{
							shrink: true,
						}}
						placeholder={Translate.FindTranslation("createxp.howWasIt.placeholder")}
						defaultValue={defaultText}
						fullWidth
						margin="dense"
						onChange={this.OnTextChanged(contextData)}
					/>
				</div>

				<div className="continue">
					<Button onClick={this.OnSkip(contextData)}>
						{Translate.T("de.Überspringen", "en.Skip", "skip")}
					</Button>
					<Button onClick={this.OnDescriptionDone(contextData)}>
						{Translate.T("de.Weiter", "en.Continue", "continue")}
					</Button>
				</div>
			</div>
		);
	}
}
