import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import Utils from '../Utils';
import Translate from '../Translate';
import AppState from '../AppState';
import DetectSwipe from '../DetectSwipe';
import { observable } from 'mobx';
import { Button } from 'material-ui';
import ForwardIcon from 'material-ui-icons/KeyboardArrowRight';
import Tracking from '../Tracking';

const styles = theme => ({	
});

@observer
class BottomTutorial extends React.Component
{
	@observable currentStep = -1;

	constructor(props)
	{
		super(props);

		AppState.instance.bottomTutorialInstance = this;
	}

	Start()
	{
		if (Utils.GetLocalStorage("bottomtutorialdone", "false") !== "true" &&
			!Utils.IsStandaloneMode())
		{
			this.currentStep = 0;
			Tracking.SendEvent("bottomtutorial0");
		}
	}

	OnEnd()
	{
		Utils.SetLocalStorage("bottomtutorialdone", "true");
		Tracking.SendEvent("bottomtutorial_end");
		this.currentStep = -1;

		if (AppState.instance.swipePageInstance)
			AppState.instance.swipePageInstance.NudgeCard();
	}

	OnSwipe = () =>
	{
		this.OnNextStepClicked();
	}

	OnNextStepClicked = () =>
	{
		if (this.currentStep === 3)
			this.OnEnd();
		else
		{
			this.currentStep++;
			Tracking.SendEvent("bottomtutorial" + this.currentStep);
		}
	}

	renderTooltip(label)
	{
		return (
			<div className={"tutorialTooltip bottom step" + this.currentStep}>
				{label}

				<div className="next">
					<Button>
						<ForwardIcon />
					</Button>
				</div>
			</div>
		);
	}

	render()
	{
		if (this.currentStep < 0)
			return null;

		return (
			<DetectSwipe
				//onSwipe={this.OnSwipe}
				className="tutorialContainer bottom"
				onClick={this.OnNextStepClicked}
			>
				
				{this.currentStep === 0 && this.renderTooltip(
					Translate.T("de.Du kannst Deine gesammelten Ausflugsziele hier einsehen.",
						"en.You can inspect your collected destinations here.", "bottomtutorial.steplabel.bookmarks")
				)}

				{this.currentStep === 1 && this.renderTooltip(
					Translate.T("de.Hier kannst selbst nach tollen Ausflugszielen suchen.",
						"en.You can search for great destinations yourself here.", "bottomtutorial.steplabel.explore")
				)}

				{this.currentStep === 2 && this.renderTooltip(
					Translate.T("de.Lass Dich von Deinen Freunden inspirieren und werde Teil der LENA Community.",
						"en.Get inspired by your friends and become part of the LENA community.", "bottomtutorial.steplabel.timeline")
				)}

				{this.currentStep === 3 && this.renderTooltip(
					Translate.T("de.Erlebe fantastische, interaktive Geschichten und schnappe Dir dabei LENAs Abenteuerkarten.",
						"en.Experience fantastic, interactive stories and fetch LENA's adventure cards on the go.", "bottomtutorial.steplabel.adventure")
				)}

			</DetectSwipe>
		);
	}
}

BottomTutorial.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(BottomTutorial);