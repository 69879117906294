import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import AppState from '../AppState';
import { observable } from '../../node_modules/mobx';
import Dialog from 'material-ui/Dialog';
import AdvCard from '../components/adventure/AdvCard';
import { Button } from 'material-ui';
import CloseIcon from 'material-ui-icons/Close';
import AdvCardStack from '../components/adventure/AdvCardStack';
import AdvCardFrame from '../components/adventure/AdvCardFrame';
import ContentCard from '../components/ContentCard';
import Translate from '../Translate';
import Tracking from '../Tracking';
import HorizontalScroller from '../components/HorizontalScroller';
import PlaceIcon from 'material-ui-icons/Place';
import Utils from '../Utils';
import AccountRequestPage from './AccountRequestPage';

const styles = {
};

@observer
class CardDetailsPopup extends React.Component
{
	cardIds = [];
	activities = {};

	onClosed;
	context;
	@observable show = false;

	stack;
	@observable frontCardId;

	constructor(props)
	{
		super(props);
		AppState.instance.cardDetailsPopup = this;
	}

	Show(cardId, onClosed, context)
	{
		this.cardIds = [cardId];
		this.onClosed = onClosed;
		this.context = context;

		this.activities = {};
		this.activities[cardId] = AppState.instance.advManager.GetActivitiesOfCard(cardId);

		this.show = true;
		AppState.instance.appContainer.forceUpdate();

		Tracking.OnPageView("CardDetailsPopup/" + cardId);
	}

	ShowMultiple(cardIds, onClosed, context)
	{
		this.cardIds = cardIds;
		this.onClosed = onClosed;
		this.context = context;

		this.activities = {};
		for (var i = 0; i < cardIds.length; ++i)
		{
			this.activities[cardIds[i]] = AppState.instance.advManager.GetActivitiesOfCard(cardIds[i]);
		}

		this.show = true;
		AppState.instance.appContainer.forceUpdate();

		Tracking.OnPageView("CardDetailsPopup/" + cardIds.join(","));
	}

	IsShown()
	{
		return this.show;
	}

	OnClose = () =>
	{
		if (this.onClosed)
		{
			if (this.cardIds.length === 1)
				this.onClosed(this.cardIds[0]);
			else
				this.onClosed(this.cardIds);
		}

		this.show = false;

		this.onClosed = undefined;
		this.context = undefined;
		this.cardIds = [];
		this.activities = {};
	}

	OnCloseCallback(reason)
	{
		if (this.onClosed)
		{
			if (this.cardIds.length === 1)
				this.onClosed(this.cardIds[0], reason);
			else
				this.onClosed(this.cardIds, reason);

			this.onClosed = undefined;
		}
	}

	OnActivityClicked = () =>
	{
		this.OnCloseCallback("activity");
		this.OnClose();
	}

	OnThrowOutEnd = (oldFrontId, newFrontId) =>
	{
		this.frontCardId = newFrontId;
	}

	GetFrontCardId()
	{
		var cardId = this.frontCardId;
		if (this.stack)
		{
			cardId = this.stack.GetFrontId();
		}
		else if (this.cardIds.length > 0)
		{
			cardId = this.cardIds[0];
		}
		return cardId;
	}

	SortActivities(activities)
	{
		var result = activities.slice();

		result.sort(function(a, b) {
			return a.distance.distanceValue - b.distance.distanceValue;
		});

		return result;
	}

	OnLoginButton = () =>
	{
		setTimeout(() => {
			AccountRequestPage.Show(undefined, undefined, "/abenteuer");
		}, 10);
	}

	render()
	{
		var fullScreen = true;
		if (AppState.instance.deviceInfo.desktop ||
			AppState.instance.deviceInfo.tablet)
			fullScreen = false;

		const cardId = this.GetFrontCardId();
		var activities = this.activities[cardId];
		var sortedActivities;
		if (activities)
			sortedActivities = this.SortActivities(activities);

		const frontCard = AppState.instance.advManager.GetCardById(cardId);
		const frontCardInstances = AppState.instance.advManager.GetCardInstances(cardId);

		return (
			<Dialog
				id="cardDetailsPopup" className={"cardDetailsPopup" + AppState.instance.GetPlatformClasses()}
				open={this.show}
				fullScreen={fullScreen}
				onClose={this.OnClose}
			>
				<div className="frame" onClick={this.OnClose}>

					<div className="closeBtnFrame">
						<Button onClick={this.OnClose}>
							<CloseIcon/>
						</Button>
					</div>

					{this.cardIds.length > 0 &&
						<AdvCardFrame
							marginTop={56}
							marginBottom={184}
							containerWidth={AppState.instance.deviceInfo.desktop ? 700 : undefined}
							containerHeight={AppState.instance.deviceInfo.desktop ? 600 : undefined}
						>
							{this.cardIds.length > 1 &&
								<AdvCardStack
									cardIds={this.cardIds}
									frontId={this.frontCardId}
									classNameCardWrapper={"receivedcard"}
									containerSelector={"#cardDetailsPopup"}
									onInit={(stackInstance) => this.stack = stackInstance}
									onThrowOutEnd={this.OnThrowOutEnd}
									onCardClick={() => {}}
								/>}
							{this.cardIds.length === 1 &&
								<AdvCard cardId={this.cardIds[0]} isFirst={false} isLast={false}
									showImage={true} showTitle={true} showPlus={false} showNumInstances={false}
									stretched={true} showValueHint={true}
								/>}
						</AdvCardFrame>}

					{!(sortedActivities && sortedActivities.length > 0) && frontCardInstances.length === 0 &&
					frontCard && frontCard.spawnTriggers === 16 &&
						<div className="finishSetHint">
							<span>
								{Utils.RenderCardIcon(false, 24, 24, "white")}
								{Translate.T("de.Vervollständige das Kartenset um die Karte zu erhalten",
									"en.Complete the card set to receive this card", "advcard.tooltip.spawntrigger.fullset")}
							</span>
						</div>}

					{!(sortedActivities && sortedActivities.length > 0) && frontCardInstances.length === 0 &&
					frontCard && frontCard.spawnTriggers === 1 && /* DEFAULT CARD */
						<div className="finishSetHint">
							<span>
								{Translate.T("de.Account anlegen um diese Karte zu erhalten",
									"en.Create account to get this card", "advcard.tooltip.spawntrigger.default")}
							</span>
							<div>
								<Button onClick={this.OnLoginButton}>
									{Translate.T("en.Create Account", "de.Jetzt registrieren", "createaccount")}
								</Button>
							</div>
						</div>}

					{sortedActivities && sortedActivities.length > 0 &&
						<div className={"where" + (sortedActivities.length === 1 ? " single":"")}>
							<div className="title">
								{sortedActivities.length === 1 && <span><PlaceIcon className="spawnTriggerIcon"/>{Translate.T("de.Finde diese Karte bei diesem Ausflugsziel", "en.Catch the card at this location", "carddetailspopup.where.title.singular")}</span>}
								{sortedActivities.length !== 1 && <span><PlaceIcon className="spawnTriggerIcon"/>{Translate.T("de.Finde diese Karte bei diesen Ausflugszielen", "en.Catch the card at these locations", "carddetailspopup.where.title")}</span>}
							</div>

							<HorizontalScroller allowIScroll={true} className="list">
							
								{sortedActivities.map((activity, index) =>
								{
									return (
									<ContentCard
										contentData={activity}
										key={index}
										columnMode={false}
										isFirst={index === 0}
										isLast={index === sortedActivities.length - 1}
										itemType="content"
										renderMode="supertiny"
										showDistance={true}
										defaultContentHeight={100}
										maxNumCategories={1}
										onClick={this.OnActivityClicked}
										enableBookmarkClick={false}
										enableContentAuthor={false}
									/>
									);	
								})}
							
							</HorizontalScroller>
						</div>}

				</div>
			</Dialog>
		);
	}
}

CardDetailsPopup.propTypes = {
	classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(CardDetailsPopup);