import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import AppState from '../AppState';
import Button from 'material-ui/Button';
import Utils from '../Utils';
import Translate from '../Translate';
import AccessTimeIcon from 'material-ui-icons/AccessTime';
import ConfigDateTime from './ConfigDateTime';
import { observable } from 'mobx';
import raf from 'raf';

const styles = theme => ({
});

@observer
class DateSelectionMenu extends React.Component
{
	OnDaySelected = (date) => () =>
	{
		if (this.props.onDateSelected)
			this.props.onDateSelected(date, date);
	}

	OnDayRangeSelected = (start, end) => () =>
	{
		if (this.props.onDateSelected)
			this.props.onDateSelected(start, end);
	}

	OnSelectDate = () =>
	{
		if (this.props.onCallDateSelection)
			this.props.onCallDateSelection();
	}

	OnClearDate = () =>
	{
		if (this.props.onCallClearDate)
			this.props.onCallClearDate();
	}

	render()
	{
		var today = Utils.DateToDayStart(new Date());
		var tomorrow = Utils.DatePlusDays(today, 1);

		var dayOfWeek = today.getDay(); // Sunday == 0

		var deltaThisFriday;
		if (dayOfWeek === 0)
			deltaThisFriday = -2;
		else
			deltaThisFriday = 5 - dayOfWeek;

		var tweStart = Utils.DatePlusDays(today, deltaThisFriday);
		var tweEnd = Utils.DatePlusDays(tweStart, 2);
		
		var nweekStart = Utils.DatePlusDays(tweEnd, 1);
		var nweekEnd = Utils.DatePlusDays(tweEnd, 6);

		var nweStart = Utils.DatePlusDays(tweStart, 7);
		var nweEnd = Utils.DatePlusDays(tweEnd, 7);

		const isTimeSet = AppState.instance.userProfile.filterStartDate !== undefined;

		return (
			<div className="dateSelectionMenu">
				<Button onClick={this.OnDaySelected(today)}>
					<span className="dateSelectionName">{Translate.T("en.Today", "de.Heute", "dateselection.today")}</span>
					<span className="dateSelectionDate">{Utils.FormatDateShort(today)}</span>
				</Button>
				<Button onClick={this.OnDaySelected(tomorrow)}>
					<span className="dateSelectionName">{Translate.T("en.Tomorrow", "de.Morgen", "dateselection.tomorrow")}</span>
					<span className="dateSelectionDate">{Utils.FormatDateShort(tomorrow)}</span>
				</Button>
				<Button onClick={this.OnDayRangeSelected(tweStart, tweEnd)}>
					<span className="dateSelectionName">{Translate.T("en.This Weekend", "de.Dieses Wochenende", "dateselection.thisweekend")}</span>
					<span className="dateSelectionDate">{Utils.FormatDateShort(tweStart)} - {Utils.FormatDateShort(tweEnd)}</span>
				</Button>
				<Button onClick={this.OnDayRangeSelected(nweekStart, nweekEnd)}>
					<span className="dateSelectionName">{Translate.T("en.Next Week", "de.Nächste Woche", "dateselection.nextweek")}</span>
					<span className="dateSelectionDate">{Utils.FormatDateShort(nweekStart)} - {Utils.FormatDateShort(nweekEnd)}</span>
				</Button>
				<Button onClick={this.OnDayRangeSelected(nweStart, nweEnd)}>
					<span className="dateSelectionName">{Translate.T("en.Next Weekend", "de.Nächstes Wochenende", "dateselection.nextweekend")}</span>
					<span className="dateSelectionDate">{Utils.FormatDateShort(nweStart)} - {Utils.FormatDateShort(nweEnd)}</span>
				</Button>
				<Button className={"singleline" + (!isTimeSet ? " last":"")} onClick={this.OnSelectDate}>
					<span className="dateSelectionName">{Translate.T("en.Choose Date", "de.Wähle Datum", "dateselection.choosedate")}</span>
				</Button>
				{isTimeSet && <Button className="last singleline" onClick={this.OnClearDate}>
					<span className="dateSelectionName">{Translate.T("en.Clear Filter", "de.Lösche Datum", "dateselection.clearfilter")}</span>
				</Button>}
			</div>
		);
	}
}

@observer
class TimeSelectionMenu extends React.Component
{
	OnTimeSelected = (hourStart, hourEnd) => () =>
	{
		if (this.props.onTimeSelected)
			this.props.onTimeSelected(hourStart * 60, hourEnd * 60);
	}

	OnSelectTime = () =>
	{
		if (this.props.onCallTimeSelection)
			this.props.onCallTimeSelection();
	}

	OnSelectEndDate = () =>
	{
		if (this.props.onCallDateSelection)
			this.props.onCallDateSelection();
	}

	render()
	{
		var now = new Date().getHours();

		var today = Utils.DateToDayStart(new Date());
		var isToday = Utils.DateSameDay(AppState.instance.userProfile.filterStartDate, today);

		return (
			<div className="dateSelectionMenu">
				{isToday && <Button onClick={this.OnTimeSelected(now, now + 2)}>
					<span className="dateSelectionName">{Translate.T("en.Now", "de.Jetzt", "timeselection.now")}</span>
					<span className="dateSelectionDate">{Utils.FormatTimeRange(now, now + 2)}</span>
				</Button>}
				<Button onClick={this.OnTimeSelected(7, 21)}>
					<span className="dateSelectionName">{Translate.T("en.Any Time", "de.Jederzeit", "timeselection.anytime")}</span>
					<span className="dateSelectionDate">{Utils.FormatTimeRange(7, 21)}</span>
				</Button>
				<Button onClick={this.OnTimeSelected(7, 12)}>
					<span className="dateSelectionName">{Translate.T("en.Morning", "de.Vormittags", "timeselection.morning")}</span>
					<span className="dateSelectionDate">{Utils.FormatTimeRange(7, 12)}</span>
				</Button>
				<Button onClick={this.OnTimeSelected(11, 14)}>
					<span className="dateSelectionName">{Translate.T("en.Noon", "de.Mittags", "timeselection.noon")}</span>
					<span className="dateSelectionDate">{Utils.FormatTimeRange(11, 14)}</span>
				</Button>
				<Button onClick={this.OnTimeSelected(12, 17)}>
					<span className="dateSelectionName">{Translate.T("en.Afternoon", "de.Nachmittags", "timeselection.afternoon")}</span>
					<span className="dateSelectionDate">{Utils.FormatTimeRange(12, 17)}</span>
				</Button>
				<Button onClick={this.OnTimeSelected(17, 21)}>
					<span className="dateSelectionName">{Translate.T("en.Evening", "de.Abends", "timeselection.evening")}</span>
					<span className="dateSelectionDate">{Utils.FormatTimeRange(17, 21)}</span>
				</Button>

				<Button className="singleline" onClick={this.OnSelectTime}>
					<span className="dateSelectionName">{Translate.T("en.Choose Time", "de.Wähle Uhrzeit", "timeselection.choosetime")}</span>
				</Button>
				<Button className="last singleline" onClick={this.OnSelectEndDate}>
					<span className="dateSelectionName">{Translate.T("en.Choose End Date", "de.Wähle Enddatum", "timeselection.chooseenddate")}</span>
				</Button>
			</div>
		);
	}
}

@observer
class TripDateTime extends React.Component
{
	@observable openTimePicker = false;
	@observable openDatePicker = false;
	selectedDateIsEndDate = false;

	componentDidMount()
	{
		AppState.instance.filterManager.AddOnFilterChanged(this.OnFiltersChanged);
	}

	componentWillUnmount()
	{
		AppState.instance.filterManager.RemoveOnFilterChanged(this.OnFiltersChanged);
	}

	OnFiltersChanged = () =>
	{
		this.forceUpdate();
	}

	OnOpenDateDialog = () =>
	{
		var cont = true;
		if (this.props.onDateTimeButton)
			cont = this.props.onDateTimeButton();

		if (cont)
		{
			raf(() =>
			{
				AppState.instance.screenMessage.ShowDialog(undefined, DateSelectionMenu, undefined, undefined, undefined,
				{
					onDateSelected: this.OnDateSelectionButton,
					onCallDateSelection: this.OnCallDateSelection,
					onCallClearDate: this.OnCallClearDate
				}, false, "dialogDateSelectionButton", true);
			});
		}
	}

	OnOpenTimeDialog = () =>
	{
		raf(() =>
		{
			AppState.instance.screenMessage.ShowDialog(undefined, TimeSelectionMenu, undefined, undefined, undefined,
			{
				onTimeSelected: this.OnTimeSelectionButton,
				onCallTimeSelection: this.OnCallTimeSelection,
				onCallDateSelection: this.OnCallEndDateSelection
			}, false, "dialogDateSelectionButton", true);
		});
	}

	OnCallDateSelection = () =>
	{
		AppState.instance.screenMessage.CloseDialog();
		this.selectedDateIsEndDate = false;
		this.openDatePicker = true;
	}

	OnCallEndDateSelection = () =>
	{
		AppState.instance.screenMessage.CloseDialog();
		this.selectedDateIsEndDate = true;
		this.openDatePicker = true;
	}

	OnCloseDateSelection = () =>
	{
		this.openDatePicker = false;
	}

	OnCloseTimeSelection = () =>
	{
		this.openTimePicker = false;
	}

	OnCallTimeSelection = () =>
	{
		AppState.instance.screenMessage.CloseDialog();
		this.openTimePicker = true;
	}

	OnCallClearDate = () =>
	{
		AppState.instance.userProfile.filterStartDate = undefined;
		AppState.instance.userProfile.filterEndDate = undefined;

		AppState.instance.UpdateLenaTripDate();
		AppState.instance.OnFilterChanged();
		AppState.instance.screenMessage.CloseDialog();
		this.forceUpdate();
	}

	OnDateSelectionButton = (start, end) =>
	{
		start = Utils.DateToHours(start, 7);
		end = Utils.DateToHours(end, 21);

		AppState.instance.userProfile.filterStartDate = start;
		AppState.instance.userProfile.filterEndDate = end;

		AppState.instance.UpdateLenaTripDate();
		AppState.instance.OnFilterChanged();
		AppState.instance.screenMessage.CloseDialog();
		this.forceUpdate();
	}

	OnTimeSelectionButton = (startMinutes, endMinutes) =>
	{
		AppState.instance.userProfile.filterStartDate.setHours(0, startMinutes, 0, 0);

		// Set end to same day
		AppState.instance.userProfile.filterEndDate = new Date(AppState.instance.userProfile.filterStartDate);
		AppState.instance.userProfile.filterEndDate.setHours(0, endMinutes, 0, 0);

		AppState.instance.UpdateLenaTripDate();
		AppState.instance.OnFilterChanged();
		AppState.instance.screenMessage.CloseDialog();
		this.forceUpdate();
	}

	OnSortSelectionButton = (set, asc) =>
	{
		AppState.instance.userProfile.filterSortBy = set;
		AppState.instance.userProfile.filterSortAsc = asc;

		AppState.instance.OnFilterChanged();
		AppState.instance.screenMessage.CloseDialog();
		this.forceUpdate();
	}

	OnDialogDateSelected = (date, endDate) =>
	{
		this.openDatePicker = false;

		if (this.selectedDateIsEndDate)
		{
			this.OnDateSelectionButton(AppState.instance.userProfile.filterStartDate, date);
		}
		else
		{
			this.OnDateSelectionButton(date, date);
		}
	}

	OnDialogStartTimeSelected = (newMinutes, endMinutes) =>
	{
		this.openTimePicker = false;

		if (!endMinutes)
		{
			endMinutes = newMinutes + 2 * 60;
		}
		
		this.OnTimeSelectionButton(newMinutes, endMinutes);
	}

	GetTimeStr(start, end)
	{
		const startHour = start.getHours() + start.getMinutes() / 60;
		const endHour = end.getHours() + end.getMinutes() / 60;

		var now = new Date().getHours();

		if (startHour === now && endHour === now + 2)
			return Translate.T("en.Now", "de.Jetzt", "timeselection.now");
		if (startHour === 7 && endHour === 21)
			return Translate.T("en.Any Time", "de.Jederzeit", "timeselection.anytime");
		if (startHour === 7 && endHour === 12)
			return Translate.T("en.Morning", "de.Vormittags", "timeselection.morning");
		if (startHour === 12 && endHour === 17)
			return Translate.T("en.Afternoon", "de.Nachmittags", "timeselection.afternoon");
		if (startHour === 17 && endHour === 21)
			return Translate.T("en.Evening", "de.Abends", "timeselection.evening");
		if (startHour === 11 && endHour === 14)
			return Translate.T("en.Noon", "de.Mittags", "timeselection.noon");

		if (endHour === startHour + 2)
			return Utils.FormatTime(startHour);

		return Utils.FormatTimeRange(startHour, endHour);
	}

	render()
	{
		var isTimeSet = AppState.instance.userProfile.filterStartDate !== undefined;
		if (!Utils.IsValidDate(AppState.instance.userProfile.filterStartDate))
			isTimeSet = false;

		var isSingleDay = false;
		var isStartToday = false;
		var isStartTomorrow = false;
		var renderToday = false;
		var renderTomorrow = false;
		var isAnytime = false;
		var timeStr;
		var currentStartTime = 7 * 60;
		if (isTimeSet)
		{
			isSingleDay = Utils.DateSameDay(AppState.instance.userProfile.filterStartDate, AppState.instance.userProfile.filterEndDate);

			var today = Utils.DateToDayStart(new Date());
			var tomorrow = Utils.DatePlusDays(today, 1);
			isStartToday = Utils.DateSameDay(AppState.instance.userProfile.filterStartDate, today);
			isStartTomorrow = Utils.DateSameDay(AppState.instance.userProfile.filterStartDate, tomorrow);

			renderToday = isSingleDay && isStartToday;
			renderTomorrow = isSingleDay && isStartTomorrow;

			if (isSingleDay)
			{
				timeStr = this.GetTimeStr(AppState.instance.userProfile.filterStartDate, AppState.instance.userProfile.filterEndDate);
				isAnytime = AppState.instance.userProfile.filterStartDate.getHours() === 7 &&
					AppState.instance.userProfile.filterEndDate.getHours() === 21;
			}

			currentStartTime = AppState.instance.userProfile.filterStartDate.getHours() * 60 +
				AppState.instance.userProfile.filterStartDate.getMinutes();
		}

		return (
			<div className="tripDateTimeContainer">

				<ConfigDateTime
					dialogOnly={true}
					openTimePicker={this.openTimePicker}
					openDatePicker={this.openDatePicker}
					date={this.selectedDateIsEndDate ? AppState.instance.userProfile.filterEndDate : AppState.instance.userProfile.filterStartDate}
					minDate={this.selectedDateIsEndDate ? AppState.instance.userProfile.filterStartDate : new Date()}
					time={currentStartTime}
					onDateSelected={this.OnDialogDateSelected}
					onTimeSelected={this.OnDialogStartTimeSelected}
					onCloseDatePicker={this.OnCloseDateSelection}
					onCloseTimePicker={this.OnCloseTimeSelection}
				/>

				<div className={"filterBarItem filterTimeButton" + (isTimeSet > 0 ? " active":"")} key="filterTimeButton">
					<Button onClick={this.OnOpenDateDialog}>
						{!isTimeSet && <AccessTimeIcon className="filterTimeIcon" />}
						{renderToday && Translate.T("en.Today", "de.Heute", "dateselection.today")}
						{renderTomorrow && Translate.T("en.Tomorrow", "de.Morgen", "dateselection.tomorrow")}
						{isTimeSet && !renderToday && !renderTomorrow && Utils.FormatDateShort(AppState.instance.userProfile.filterStartDate)}
					</Button>
				</div>

				{isTimeSet && <div className={"filterBarItem filterTimeButton2" + (isAnytime ? "":" active")} key="filterTimeButton2">
					<Button onClick={this.OnOpenTimeDialog}>
						{!isSingleDay && Utils.FormatDateShort(AppState.instance.userProfile.filterEndDate)}
						{timeStr}
					</Button>
				</div>}

			</div>
	  );
	}
}

TripDateTime.propTypes =
{
	classes: PropTypes.object,
	theme: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(TripDateTime);