/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import AppState from '../AppState';
import { Map, Marker, TileLayer, Polyline } from 'react-leaflet';
import { primaryColorLena } from '../colors';

require('../utils/leaflet-gesture-handling.min.js');

const styles = theme => ({	
});


@observer
class MapLeaflet extends React.Component
{
	leafletmap;
	numLoadTries = 0;
	moveEndCounter = 0;
	polyline;

	componentDidMount()
	{
		this.leafletmap = this.refs.leafletmap.leafletElement;
		this.CheckLeafletLoaded();
	}

	CheckLeafletLoaded = () =>
	{
		if (this.IsValid())
		{
			if (this.props.onLoad)
				this.props.onLoad(this);
		}
		else
		{
			this.numLoadTries++;
			if (this.numLoadTries < 20)
			{
				setTimeout(() => {
					this.CheckLeafletLoaded();
				}, 100);
			}
		}
	}

	IsValid()
	{
		return this.leafletmap._loaded && this.leafletmap._mapPane;
	}

	InvalidateSize()
	{
		if (this.IsValid())
			this.leafletmap.invalidateSize({pan: false});
	}

	getBounds()
	{
		if (this.IsValid())
		{
			var bounds = this.leafletmap.getBounds();

			var result = {};

			result.b = {};
			result.f = {};

			result.b.b = bounds.getWest();
			result.b.f = bounds.getEast();

			result.f.b = bounds.getSouth();
			result.f.f = bounds.getNorth();

			return result;
		}
	}

	getCenter()
	{
		if (this.IsValid())
			return this.leafletmap.getCenter();
	}

	getZoom()
	{
		if (this.IsValid())
			return this.leafletmap.getZoom();
	}

	panTo(latLng)
	{
		if (this.IsValid())
			this.leafletmap.panTo(latLng);
	}

	OnMarkerClick = (marker) => (e) =>
	{
		if (this.props.onMarkerClick)
			this.props.onMarkerClick(e, marker);
	}

	OnMoveStart = () =>
	{
		this.moveEndCounter = 0;
		if (this.props.onMoveStart)
			this.props.onMoveStart();
	}

	OnMove = () =>
	{
		if (this.props.onMove)
			this.props.onMove();
	}

	OnMoveEnd = () =>
	{
		// this.moveEndCounter++;

		// if (this.moveEndCounter === 1)
		// {
		// 	// Usually leaflet fires another moveend event afterwards, so wait for that to happen
		// 	setTimeout(() => {
		// 		if (this.moveEndCounter === 1)
		// 		{
		// 			// Didn't happen => we don't wait any longer
		// 			if (this.props.onMoveEnd)
		// 				this.props.onMoveEnd();
		// 		}
		// 	}, 1000);
		// }
		// else
		// {
			if (this.props.onMoveEnd)
				this.props.onMoveEnd();
		//}
	}

	GetGeoLocation()
	{
		return AppState.instance.GetGpsPosition();
	}

	render()
	{
		//console.log("render: " + this.props.selectedActivityId);

		var myLocationIcon = L.divIcon({
			className: 'myLocationMarker',
			html: "<div class=myLocationMarkerInner />"
		});

		const geoLoc = this.GetGeoLocation();

		if (this.props.pathCoordinates && !this.polyline)
		{
			this.polyline = [];
			for (let i = 0; i < this.props.pathCoordinates.length; i += 2)
			{
				this.polyline.push([this.props.pathCoordinates[i],
					this.props.pathCoordinates[i + 1]]);
			}
		}

		return (
			<Map
				className={this.props.className}
				center={this.props.center}
				zoom={this.props.zoom}
				onMovestart={this.OnMoveStart}
				onMove={this.OnMove}
				onMoveend={this.OnMoveEnd}
				zoomControl={false}
				gestureHandling={this.props.gestureHandling === "cooperative" ? true : false}
				ref="leafletmap"
			>
				<TileLayer
					//url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					//attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a>"

					url="https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png"
					attribution="Wikimedia maps | &copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a>"
					//tileSize={128}
				/>

				{this.polyline &&
					<Polyline color={primaryColorLena[500]} positions={this.polyline} />}

				{this.props.highlightMarkers && this.props.highlightMarkers.map((marker, index) =>
				{
					var highlightIcon = L.icon({
						iconUrl: marker.icon.url,
						iconSize: [marker.icon.size[0], marker.icon.size[1]],
					});

					return (
						<Marker
							key={'highlightmarker_' + (marker.activity ? marker.activity.id : "") + "_" + index}
							position={marker.position}
							icon={highlightIcon}
							//style={{zIndex: 5}}
							onClick={this.OnMarkerClick(marker)}
						/>
					);
				})}

				{this.props.markers.map((marker, index) =>
				{
					const ref = 'marker_' + (marker.activity ? marker.activity.id : "") + "_" + index;
					const isSelected = marker.activity ? marker.activity.id === this.props.selectedActivityId : false;

					var markerIcon = L.icon({
						iconUrl: isSelected ? marker.iconSelected.url : marker.icon.url,
						iconSize: [marker.icon.size[0], marker.icon.size[1]],
					});

					var zIndex = 0;
					if (marker.zIndexOffset)
						zIndex += marker.zIndexOffset;
					if (isSelected)
						zIndex += 2000;

					return (
						<Marker
							key={ref}
							position={marker.position}
							icon={markerIcon}
							zIndexOffset={zIndex}
							onClick={this.OnMarkerClick(marker)}
						/>
					);
				})}

				{geoLoc &&
					<Marker
						key={"mylocation_marker"}
						position={{
							lat: geoLoc.latitude,
							lng: geoLoc.longitude}}
						icon={myLocationIcon}
						zIndexOffset={4000}
					/>
				}
			</Map>
		);
	}
}

MapLeaflet.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(MapLeaflet);