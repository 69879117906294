import React from 'react';
import AppState from './AppState';
import 'whatwg-fetch';
import Utils from './Utils';
import Delegate from './utils/Delegate';

export default class RestApi extends React.Component
{
	static endpoint;
	static inAuthRetry = false;
	static retryData = [];
	static onNetworkStatus = new Delegate();
	static networkStatus = 1;

	static Init()
	{
		if (process.env.NODE_ENV === 'production' && window.location.hostname.indexOf('localhost') < 0 &&
			window.location.hostname.indexOf('10.0.0.44') < 0)
		{
			if (window.location.hostname.indexOf("staging") >= 0)
				//RestApi.endpoint = "https://apistaging.lena.app";
				RestApi.endpoint = "https://api.lena.app";
			else
				RestApi.endpoint = "https://api.lena.app";
		}
		else
		{
			RestApi.endpoint = "http://" + window.location.hostname + ":5000";
			//RestApi.endpoint = "https://" + window.location.hostname + ":5443";
		}

		setInterval(() =>
		{
			RestApi.SendRequestText("/ska", {});
		}, 5 * 60 * 1000);
	}

	//ATTENTION: don't just redirect current window to an API URL (api.lena.app), this would jump out the PWA standalone context
	static GetApiUrl()
	{
		return RestApi.endpoint;
	}

	//@param userProfile UserProfile
	//@return int accountId
	static RegisterUserProfile(userProfile)
	{
		return RestApi.SendRequest("/websiteaccount", userProfile);
	}

	static Login(username, password)
	{
		return RestApi.SendRequest("/auth/credentials", { username, password });
	}

	static GetAuthProvider()
	{
		const up = AppState.instance.userProfile;

		if (up && up.loginData && 
			up.loginData.isLoggedIn && up.loginData.authProviders &&
			up.loginData.authProviders.length > 0)
		{
			// prefer "credentials"
			for (var i = 0; i < up.loginData.authProviders.length; ++i)
			{
				if (up.loginData.authProviders[i] === "credentials")
					return up.loginData.authProviders[i];
			}
			return up.loginData.authProviders[0];
		}

		return undefined;
	}

	static Auth()
	{
		var token;
		var provider;

		const up = AppState.instance.userProfile;
		if (up && up.loginData && 
			/*up.loginData.isLoggedIn &&*/ up.loginData.accessTokens &&
			up.loginData.accessTokens.length > 0)
		{
			// prefer "credentials"
			for (var i = 0; i < up.loginData.authProviders.length; ++i)
			{
				if (up.loginData.authProviders[i] === "credentials")
				{
					token = up.loginData.accessTokens[i];
					provider = up.loginData.authProviders[i];
					break;		
				}
			}

			if (!token)
			{
				token = up.loginData.accessTokens[0];
				provider = up.loginData.authProviders[0];
			}
		}

		if (token && provider)
		{
			return RestApi.SendRequest("/auth/" + provider, 
			{
				accessToken: token,
				rememberMe: true,
				provider: provider
			});
		}
		else
		{
			return new Promise((resolve, reject) => { return resolve(null);});
		}
	}

	static Logout()
	{
		var data = {provider: "logout"};
		return RestApi.SendRequest("/auth/logout", data);
	}

	static GetLoginData()
	{
		return RestApi.SendRequest("/logindata", {});
	}

	//@return array[string]
	static Lena(msg)
	{
		return RestApi.SendRequest("/lena", { 'message': msg });
	}

	static Search(searchQuery)
	{
		return RestApi.SendRequest("/search", searchQuery);
	}

	static GetBookmarks(query)
	{
		return RestApi.SendRequest("/bookmarks", query);
	}

	static Details(detailsQuery)
	{
		return RestApi.SendRequest("/details", detailsQuery);
	}

	static Bookmark(activityId, session)
	{
		return RestApi.SendRequestText("/bookmark", { activityId: activityId, session: session });
	}

	static RemoveBookmark(activityId, session)
	{
		return RestApi.SendRequestText("/removebookmark", { activityId: activityId, session: session });
	}

	static ReportEdit(query)
	{
		return RestApi.SendRequestText("/publicdatadelta", query);
	}

	static SendEvent(event)
	{
		return RestApi.SendRequestText("/events", event);
	}

	static SendRequest(resource, data, stringify, onProgress, method)
	{
		return RestApi.SendRequestText(resource, data, stringify, onProgress, method).then((responseText) =>
		{
			if (responseText === undefined || responseText === null || responseText === "")
			{
				return new Promise((resolve, reject) => { return resolve(undefined);});
			}
	
			if (Utils.IsJsonString(responseText))
				return JSON.parse(responseText);
			else
				return new Promise((resolve, reject) => { return resolve(undefined);});
		});
	}

	static createCORSRequest(method, url)
	{
		var xhr = new XMLHttpRequest();
		if ("withCredentials" in xhr) {
	  
		  // Check if the XMLHttpRequest object has a "withCredentials" property.
		  // "withCredentials" only exists on XMLHTTPRequest2 objects.
		  xhr.open(method, url, true);
	  
		} else if (typeof XDomainRequest !== "undefined") {
	  
		  // Otherwise, check if XDomainRequest.
		  // XDomainRequest only exists in IE, and is IE's way of making CORS requests.
		  xhr = new XDomainRequest();
		  xhr.open(method, url);
	  
		} else {
	  
		  // Otherwise, CORS is not supported by the browser.
		  xhr = null;
	  
		}
		return xhr;
	}

	static futch(url, data, opts={}, onProgress)
	{
		return new Promise( (res, rej)=>
		{
			//var xhr = new XMLHttpRequest();
			var xhr = RestApi.createCORSRequest(opts.method || 'GET', url);
			xhr.withCredentials = true;
			if (opts.headers)
			{
				opts.headers.forEach(function(hValue, hKey, z)
				{
					if (hKey !== "content-type")
						xhr.setRequestHeader(hKey, hValue);
				});

				// for (var pair of opts.headers.entries())
				// {
				// 	if (pair[0] !== "content-type")
				// 		xhr.setRequestHeader(pair[0], pair[1]);
				// }
			}
			//xhr.open(opts.method || 'GET', url);
			// for (var k in opts.headers||{})
			// {
			// 	if (k !== "append" && k !== "delete" && k !== "get" && k !== "has" && k !== "set" && k !== "keys" && k !== "values" && k !== "forEach" && k !== "entries")
			// 	{
			// 		console.log("HEADER: " + k + " = " + opts.headers[k]);
			// 		xhr.setRequestHeader(k, opts.headers[k]);
			// 	}
			// }
			//xhr.setRequestHeader("Accept", "application/json");
			//xhr.setRequestHeader("Content-Type", "application/json");
			xhr.onload = e => res(e.target.responseText);
			xhr.onreadystatechange = function()
			{ 
				if (xhr.status === 401)
				{
					res(401);
					//console.log(request.responseText);
				}
	   		}
			xhr.onerror = rej;
			if (xhr.upload && onProgress)
				xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
			xhr.send(data);
		});
	}

	static HandleAuthCase(resource, data, stringify, onProgress, method)
	{
		//console.log("============= not auth");

		// Session timeout?
		if (RestApi.inAuthRetry === false && AppState.instance.userProfile.loginData.isLoggedIn)
		{
			console.log("============= session timeout");

			// Try to auth again
			RestApi.inAuthRetry = true;
			return RestApi.Auth().then((authRes) =>
			{
				RestApi.inAuthRetry = false;
				//console.log("============= auth done");

				if (authRes && authRes.userId)
				{
					//console.log("============= try again");

					// try initial call again
					return RestApi.SendRequestText(resource, data, stringify, onProgress, method);
				}
				else
				{
					return new Promise((resolve, reject) => { return reject(401);});
				}
			})
			.catch((error) =>
			{
				RestApi.inAuthRetry = false;
				return new Promise((resolve, reject) => { return reject(401);});
			});
		}
		else
		{
			return new Promise((resolve, reject) => { return reject(401);});
		}
	}

	static SendRequestText(resource, data, stringify, onProgress, method)
	{
		//console.log("=======================================");
		//console.log("REST call: " + resource);
		//console.log(data);

		var headerObject;
		if (stringify === undefined || stringify === true)
		{
			headerObject = new Headers(
				{
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				});
		}
		else
		{
			headerObject = new Headers(
				{
			 		'Accept': 'application/json',
			 		//'Content-Type': 'image/jpeg'
			 	});
		}

		if (method === undefined)
			method = "POST";

		var request = new Request(RestApi.endpoint + resource,
		{
			method: method,
			// if futch is used, don't process/read the data, otherwise it will be processed twice which does not work with file-uploads/FormData
			body: onProgress ? "" : ((stringify === undefined || stringify === true) ? JSON.stringify(data) : data),
			mode: 'cors',
			credentials: 'include',
			redirect: 'follow',
			headers: headerObject,
		});

		if (onProgress)
		{
			var body = (stringify === undefined || stringify === true) ? JSON.stringify(data) : data;
			if (method === "GET" || method === "HEAD")
				body = undefined;

			return RestApi.futch(RestApi.endpoint + resource, body, request, onProgress).then((res) =>
			{
				if (res === 401)
				{
					return RestApi.HandleAuthCase(resource, data, stringify, onProgress, method);
				}
				else
				{
					return res;
				}
				//console.log("  REST response: " + JSON.stringify(result));
			})
			.catch((error) =>
			{
				console.log("REST ERROR: " + error);
				return new Promise((resolve, reject) => { return reject(error);});
			});
		}
		else
		{
			//console.log("Calling REST: " + resource + ": {" + JSON.stringify(data) + "}");

			return fetch(request).then((res) =>
			{
				if (res.status === 401)
				{
					if (resource.indexOf("/auth/") < 0)
					{
						return RestApi.HandleAuthCase(resource, data, stringify, onProgress, method);
					}
					else
					{
						// Auth failed, check reason
						return res.text().then((authFailedText) =>
						{
							if (authFailedText && authFailedText.indexOf("ccount verification timed out") >= 0)
								return new Promise((resolve, reject) => { return reject(4010);}); 	
							else
								return new Promise((resolve, reject) => { return reject(401);}); 	
						});						
					}
				}
				else
				{
					var retry = RestApi.GetRetryEntry(resource, data, stringify, onProgress, method);
					if (retry)
						RestApi.RemoveRetryEntry(retry);

					if (RestApi.networkStatus === 0)
					{
						RestApi.networkStatus = 1;
						RestApi.OnNewNetworkStatus();
					}

					return res.text().then((result) =>
					{
						//console.log("REST RESULT: " + result);
						return result;
					});
				}
			})
			.catch((error) =>
			{
				if (RestApi.inAuthRetry || (resource.indexOf("/auth/") >= 0))
				{
					if (error === 4010)
						return new Promise((resolve, reject) => { return reject(error);});
					else
						return new Promise((resolve, reject) => { return reject(401);});
				}
				else
				{
					//console.log("REST ERROR: " + error);
					//console.log(error);
					var retry = RestApi.GetRetryEntry(resource, data, stringify, onProgress, method);
					if (!retry)
						retry = RestApi.CreateRetryEntry(resource, data, stringify, onProgress, method);
					
					retry.numTries += 1;
					//console.log("failed tries of " + retry.resource + ": " + retry.numTries);

					if (retry.numTries >= 3)
					{
						if (RestApi.networkStatus === 1 && error !== 401)
						{
							RestApi.OnLostConnection();
						}
						return new Promise((resolve, reject) => { return reject(error);});
					}
					else
					{
						if (RestApi.networkStatus === 1)
						{
							// Check if we have network at all
							if (!Utils.CheckNetConnection())
							{
								RestApi.OnLostConnection();
							}
						}
						return RestApi.HandleRetry(retry);
					}
				}
			});
		}
	}

	static GetRetryEntry(resource, data, stringify, onProgress, method)
	{
		for (var i = 0; i < RestApi.retryData.length; ++i)
		{
			var r = RestApi.retryData[i];
			if (r.resource === resource && r.data === data && r.stringify === stringify && r.onProgress === onProgress && r.method === method)
				return r;
		}
		return undefined;
	}

	static CreateRetryEntry(resource, data, stringify, onProgress, method)
	{
		var r = {
			resource: resource,
			data: data,
			stringify: stringify,
			onProgress: onProgress,
			method: method,
			numTries: 0
		};
		if (r.method === undefined)
			r.method = "POST";
		RestApi.retryData.push(r);
		return r;
	}

	static RemoveRetryEntry(r)
	{
		for (var i = 0; i < RestApi.retryData.length; ++i)
		{
			if (RestApi.retryData[i] === r)
			{
				RestApi.retryData.splice(i, 1);
				return;
			}
		}
	}

	static HandleRetry(r)
	{
		var delay = 200;
		if (r.numTries === 2)
			delay = 1200;
		else if (r.numTries > 2)
			delay = 3000;

		return new Promise(resolve => setTimeout(resolve, delay))
		.then(() =>
		{
			//console.log("___ retrying after timeout: " + r.resource);
			return RestApi.SendRequestText(r.resource, r.data, r.stringify, r.onProgress, r.method);
		});
	}

	static OnLostConnection()
	{
		RestApi.networkStatus = 0;
		RestApi.OnNewNetworkStatus();
	}

	static OnNewNetworkStatus()
	{
		RestApi.onNetworkStatus.Call(RestApi.networkStatus);
	}

	static AddOnNetworkStatus(cb)
	{
		RestApi.onNetworkStatus.Add(cb);
	}

	static RemoveOnNetworkStatus(cb)
	{
		RestApi.onNetworkStatus.Remove(cb);
	}
}
