import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import Translate from '../Translate';
import Tracking from '../Tracking';
import Dialog from 'material-ui/Dialog';
import IconButton from 'material-ui/IconButton';
import CloseIcon from 'material-ui-icons/Close';
import AppBar from 'material-ui/AppBar';
import Toolbar from 'material-ui/Toolbar';
import Typography from 'material-ui/Typography';
import AppState from '../AppState';
import AccountRequestPage from './AccountRequestPage';
import ActivityEditor from '../components/ActivityEditor';
import Utils from '../Utils';

const styles = {
	appBar: {
		position: 'relative',
	},
	closeButtonRoot: {
		width: '24px',
		height: '24px',
	},
	dialogTitle: {
		marginLeft: '14px',
	},
};

@observer
class SuggestEditPage extends React.Component
{
	static instance = null;

	data;
	@observable dialogOpen = false;

	constructor(props)
	{
		super(props);

		SuggestEditPage.instance = this;
	}

	HandleRequestClose = () =>
	{
		this.dialogOpen = false;
	}

	static Show(data)
	{
		Tracking.OnPageView("suggestedit");

		if (AppState.instance.IsLoggedIn())
		{
			SuggestEditPage.instance.data = data;
			//SuggestEditPage.instance.ResetQuery();
			SuggestEditPage.instance.dialogOpen = true;
		}
		else
		{
			var url = encodeURI("/activity/" + Utils.GetUrlName(data.activity.name) + "#edit");
			
			AccountRequestPage.Show(Translate.FindTranslation("accountrequest.bookmark"), undefined, url);
		}
	}

	OnSubmitted = () =>
	{
		this.dialogOpen = false;
		//TODO: thx-popup
	}

	render()
	{
		Tracking.OnRender("SuggestEditPage");

		if (this.dialogOpen === false)
			return null;

		const { classes } = this.props;

		//const Transition = props => <Slide direction="up" {...props} />

		return (
			<Dialog
				fullScreen
				open={this.dialogOpen}
				onClose={this.HandleRequestClose}
				className={"suggestEditPageDialog" + AppState.instance.GetPlatformClasses()}
			>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton color="contrast" onClick={this.HandleRequestClose} aria-label="Close"
							classes={{root: classes.closeButtonRoot}}
						>
							<CloseIcon />
						</IconButton>
						<Typography type="title" color="inherit" className={classes.dialogTitle}>
							{Translate.T("en.Edit", "de.Editieren", "suggesteditpage.title")}
						</Typography>
					</Toolbar>
				</AppBar>

				<ActivityEditor
					data={this.data}
					onSubmitted={this.OnSubmitted}
					showHeader={true}
					showAge={false} showDuration={false}
				/>

			</Dialog>
		);
	}
}

SuggestEditPage.propTypes = {
	classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(SuggestEditPage);