import React from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import {withStyles} from 'material-ui/styles';
import PropTypes from 'prop-types';
import Utils from '../Utils';
import Rating from './Rating';
import Moment from 'moment';
import Translate from '../Translate';
import Tracking from '../Tracking';
import AppState from '../AppState';
import Email from 'material-ui-icons/Email';
import Phone from 'material-ui-icons/Phone';
import Web from 'material-ui-icons/Web';
import Directions from 'material-ui-icons/Directions';
//import CloseIcon from 'material-ui-icons/Close';
import AddAPhoto from 'material-ui-icons/AddAPhoto';
import AddLocation from 'material-ui-icons/AddLocation';
import EditLocation from 'material-ui-icons/EditLocation';
import ModeEdit from 'material-ui-icons/ModeEdit';
import ContentCopy from 'material-ui-icons/ContentCopy';
import Share from 'material-ui-icons/Share';
import CheckIcon from 'material-ui-icons/Check';
import MoreVert from 'material-ui-icons/MoreVert';
import Button from 'material-ui/Button';
import AllReviewsPage from '../pages/AllReviewsPage';
import AddReviewPage from '../pages/AddReviewPage';
import UserReview from '../components/UserReview';
import Trip from '../models/Trip';
import {CloudinaryContext/*, Video*/} from 'cloudinary-react';
import LiveData from '../models/LiveData';
import MediaPlayer from '../components/MediaPlayer';
import WeatherDisplay from '../components/WeatherDisplay';
import Map from '../components/Map';
import AccountRequestPage from '../pages/AccountRequestPage';
import ApiInterface from '../ApiInterface';
//import Tutorial from './Tutorial';
import EmailLink from './EmailLink';
//import RecorderPage from '../pages/RecorderPage';
import SuggestEditPage from '../pages/SuggestEditPage';
import DropdownMenu from 'react-dd-menu';
import {ShareButtons, generateShareIcon} from 'react-share';
import ProgressDisplay from './ProgressDisplay';
import UserListSelection, {ListSelectionMenu} from './UserListSelection';
import EuroSymbol from 'material-ui-icons/EuroSymbol';
import ChevronRight from 'material-ui-icons/ChevronRight';
import OpenInNewIcon from 'material-ui-icons/OpenInNew';
import ContentList from './ContentList';
import ContentSource from './ContentSource';
import Timeline from './Timeline';
import SocialInteraction from './SocialInteraction';
import PlaceIcon from 'material-ui-icons/Place';
import ContentGrid from './ContentGrid';
import RestApi from '../RestApi';
import AdvCardFetcher from './adventure/AdvCardFetcher';

const {FacebookShareButton, EmailShareButton} = ShareButtons;
const FacebookIcon = generateShareIcon('facebook');
const EmailIcon = generateShareIcon('email');

const styles = theme => ({
	addReviewButton: {
		color: 'white',
		backgroundColor: '#e31c46',
	},
});

@observer
class ActivityRenderer extends React.Component
{
	weblinks = [];
	@observable showOpenDetails = false;
	@observable showPriceDetails = false;
	@observable showMatchPopup = false;
	@observable showLowConfPopup = false;
	showIconTooltip = [];
	@observable isMoreMenuOpen = false;
	@observable isShareMenuOpen = false;

	defaultContentHeight = Utils.GetMaxContentHeight();

	@observable progressItems = [];
	mediaPlayer;

	@observable showNewDialog = false;
	decodedWeather;
	map;
	timeline;
	@observable isCheckedIn = false;

	componentDidMount()
	{
		setTimeout(() => {
			if (this.map)
				this.map.SetToCenterPosition();
		}, 1000);

		if (AppState.instance.deviceInfo.desktop)
		{
			this.showOpenDetails = true;
		}
	}

	OnMediaPlayerInstance = (mp) =>
	{
		this.mediaPlayer = mp;

		if (this.props.onMediaPlayerInstance)
			this.props.onMediaPlayerInstance(mp);
	}

	UpdateWeblinks()
	{
		var urls = [];
		if (this.props.data.activity.infoUrl !== undefined)
		{
			urls = this.props.data.activity.infoUrl.split("\n");
		}
		for (var i = 0; i < this.props.data.activity.pictureUrls.length; ++i)
		{
			const url = this.props.data.activity.pictureUrls[i];
			if (url !== undefined && url.length > 0 && (!url.startsWith("{")))
			{
				if (url.indexOf("://d1zjsncpn8gvig.cloudfront.net/") < 0 &&
					url.indexOf("lena.family") < 0 &&
					url.indexOf("lena.app") < 0 &&
					url.indexOf("localhost") < 0 &&
					url.indexOf("buffer-media-uploads.s3") < 0 &&
					!url.startsWith("data:image") &&
					url.indexOf("res.cloudinary.com/lenafamily") < 0)
					urls.push(url);
			}
		}
		
		var result = [];
		for (i = 0; i < urls.length; ++i)
		{
			var link = document.createElement('a');

			var u = urls[i];

			if (u.startsWith("//"))
				u = "http:" + u;
			else if (!u.startsWith("http"))
				u = "http://" + u;
			link.setAttribute('href', u);

			const hn = link.hostname;

			if (hn === "www.lena.app")
				continue;

			if (hn !== "activity")
				result.push({label: hn, url: urls[i]});
		}

		// Remove duplicates
		for (i = 0; i < result.length - 1; ++i)
		{
			// Remove subdomains
			var tld_i = Utils.GetDomainAndTld(result[i].label);

			for (var j = result.length - 1; j >= i + 1; --j)
			{
				var tld_j = Utils.GetDomainAndTld(result[j].label);
				if (tld_j === tld_i)
				{
					result.splice(j, 1);
				}
			}
		}

		this.weblinks = result;
	}

	IsLinkClickedAllowed(linkName)
	{
		if (this.props.onBeforeLinkClicked)
		{
			return this.props.onBeforeLinkClicked(linkName);
		}
		else
		{
			return true;
		}
	}

	OnWeblinkClicked = (e) =>
	{
		var url = e.target.dataset.url;
		if (url && this.IsLinkClickedAllowed("weblink"))
		{
			Tracking.SendEvent("weblinkClicked", {u: url, activityId: this.props.data.activity.id});
			Utils.OpenExternalLink(url);
		}
	}

	OnWeblinkClickedUrl = (url) => () =>
	{
		if (url && this.IsLinkClickedAllowed("weblink"))
		{
			Tracking.SendEvent("weblinkClicked", {u: url, activityId: this.props.data.activity.id});
			Utils.OpenExternalLink(url);
		}
	}

	OnAllReviews = () =>
	{
		const label = Translate.T('en.All Reviews', 'de.Alle Bewertungen', 'dialog.allreviews.title');
		var allReviewsPage = React.createElement(AllReviewsPage, {reviews: this.props.data.reviews, activity: this.props.data.activity}, null);
		AppState.instance.screenMessage.ShowDialog(label, undefined, allReviewsPage);
	}

	OnReviewSubmitted = () =>
	{
		if (this.props.onChanged)
			this.props.onChanged();
	}

	OnStartNavigation = () =>
	{
		if (!this.IsLinkClickedAllowed("navigation"))
			return;

		setTimeout(() => {
			Utils.StartNavigation(this.props.data.activity.address);	
		}, 200);
	}

	OnStartCall = () =>
	{
		if (!this.IsLinkClickedAllowed("startcall"))
			return;

		setTimeout(() => {
			Utils.StartCall(this.props.data.activity.phone);	
		}, 200);
	}

	OnAddToTrip = () =>
	{
		if (!this.IsLinkClickedAllowed("addtotrip"))
			return;

		var trip = AppState.instance.userProfile.EnsureActiveTrip();

		//TODO: duration of activity
		Trip.AddOrRemoveActivity(trip, this.props.data.activity.name, 120);
		AppState.instance.userProfile.OnTripChanged(trip);
		AppState.instance.appInstance.OnTabbarChanged(undefined, AppState.TabIndexJournal);

		if (this.props.onAddToTrip)
			this.props.onAddToTrip(trip);
	}

	OpenDetailsClicked = () =>
	{
		if (!this.IsLinkClickedAllowed("opening"))
			return;

		setTimeout(() => {
			this.showOpenDetails = true;
			if (this.props.onChanged)
				this.props.onChanged(true);
			this.forceUpdate();
		}, 50);
		
		Tracking.SendEvent("openingHoursDetails", {activityId: this.props.data.activity.id});
	}

	OpenPriceDetailsClicked = () =>
	{
		if (!this.IsLinkClickedAllowed("price"))
			return;

		setTimeout(() => {
			this.showPriceDetails = true;
			if (this.props.onChanged)
				this.props.onChanged(true);	
		}, 50);
		
		Tracking.SendEvent("priceDetails", {activityId: this.props.data.activity.id});
	}

	OnWeatherExpanded = () =>
	{
		if (this.props.onChanged)
			this.props.onChanged(true);
		Tracking.SendEvent("weatherDetails", {activityId: this.props.data.activity.id});
		this.forceUpdate();
	}

	OnShortInfoItemClicked = (name) => () =>
	{
		if (!this.IsLinkClickedAllowed("shortinfoicon"))
			return;

		const currentVal = this.showIconTooltip[name];
		if (currentVal === undefined || currentVal === false)
			this.showIconTooltip[name] = true;
		else
			this.showIconTooltip[name] = false;
		this.forceUpdate();
	}

	RenderShortInfoIcons(activity, distance, timelapse, weather, showNoeCard, showKinderaktivcard)
	{
		const iconSize = 24;

		return (
			<div className="detailShortInfo">
				
				<div className="detailShortInfoLine detailShortInfoLineTop">
					{activity.address && <div className="detailShortInfoItem">
						<img alt="place" src="/assets/place.svg" style={{width: iconSize, height: iconSize}} className="detailShortInfoIcon" />
						<div className="detailShortInfoText">
							{AppState.instance.ipLocationLoaded ? distance.distanceText : "..."}
						</div>
					</div>}

					{activity.address && <div className="detailShortInfoItem">
						{distance.travelMode === "driving" &&
							<img alt="traveldurationcar" src="/assets/car.svg" style={{width: iconSize, height: iconSize}} className="detailShortInfoIcon" />
						}
						{distance.travelMode !== "driving" &&
							<img alt="traveldurationtrain" src="/assets/train.svg" style={{width: iconSize, height: iconSize}} className="detailShortInfoIcon" />
						}
						<div className="detailShortInfoText">
							{AppState.instance.ipLocationLoaded ? distance.durationText : "..."}
						</div>
					</div>}

					{(timelapse.length > 0) && <div className="detailShortInfoItem">
						<img alt="visitduration" src="/assets/timelapse.svg" style={{width: iconSize, height: iconSize}} className="detailShortInfoIcon" />
						<div className="detailShortInfoText">
							{timelapse}
						</div>
					</div>}

					{/*activity.publicTransport && <div className="detailShortInfoItem" onClick={this.OnShortInfoItemClicked("train")}>
						<img alt="train" src="/assets/train.svg" style={{width: '32px', height: '32px'}} className="detailShortInfoIcon" />
						{this.showIconTooltip["train"] === true && <div className="detailShortInfoText">
							{Translate.T("en.Public Transport", "de.Öffentliche Anbindung", "icon.title.publictransport")}
						</div>}
					</div>*/}

					{weather && WeatherDisplay.RenderWeatherIcon(weather, "detailShortInfoItem", this.OnTopWeatherClicked)}

					{activity.ageFitMin && (activity.ageFitMax === undefined) && <div className="detailShortInfoItemSmall">
						<img alt="age" src="/assets/age.svg" style={{width: iconSize, height: iconSize}} className="detailShortInfoIcon" />
						<div className="detailShortInfoText">
							{Translate.T("en.{0} y/o & Up", "de.Ab {0} Jahre", "icon.agefit.minonly", [activity.ageFitMin])}
						</div>
					</div>}
					{activity.ageFitMax && (activity.ageFitMin === undefined) && <div className="detailShortInfoItemSmall">
						<img alt="age" src="/assets/age.svg" style={{width: iconSize, height: iconSize}} className="detailShortInfoIcon" />
						<div className="detailShortInfoText">
							{Translate.T("en.Up to {0} y/o", "de.Bis {0} Jahre", "icon.agefit.maxonly", [activity.ageFitMax])}
						</div>
					</div>}
					{activity.ageFitMax && activity.ageFitMin && <div className="detailShortInfoItemSmall">
						<img alt="age" src="/assets/age.svg" style={{width: iconSize, height: iconSize}} className="detailShortInfoIcon" />
						<div className="detailShortInfoText">
							{Translate.T("en.{0} - {1} y/o", "de.{0} - {1} Jahre", "icon.agefit.minmax", [activity.ageFitMin, activity.ageFitMax])}
						</div>
					</div>}

					{activity.pathDifficulty !== undefined && activity.pathDifficulty > 0 && <div className="detailShortInfoItem">
						<div style={{width: iconSize, height: iconSize}} className="detailShortInfoIcon path">
							{activity.pathDifficulty === 1 && Translate.T("de.leicht", "en.easy", "path.difficulty.easy")}
							{activity.pathDifficulty === 2 && Translate.T("de.mittel", "en.easy/med", "path.difficulty.easymedium")}
							{activity.pathDifficulty === 3 && Translate.T("de.mittel", "en.medium", "path.difficulty.medium")}
							{activity.pathDifficulty === 4 && Translate.T("de.mittel", "en.med/hard", "path.difficulty.mediumhard")}
							{activity.pathDifficulty === 5 && Translate.T("de.schwer", "en.hard", "path.difficulty.hard")}
						</div>
						<div className="detailShortInfoText">
							{Translate.T("de.Schwierigkeit", "en.Difficulty", "path.difficulty")}
						</div>
					</div>}

					{activity.pathLength !== undefined && <div className="detailShortInfoItem">
						<img alt="place" src="/assets/pathlength.svg" style={{width: iconSize, height: iconSize}} className="detailShortInfoIcon" />
						<div className="detailShortInfoText">
							{Utils.CalcPathLengthStr(activity.pathLength) + " km"}
						</div>
					</div>}

					{activity.pathRestStop === true && <div className="detailShortInfoItem">
						<img alt="place" src="/assets/home.svg" style={{width: iconSize, height: iconSize}} className="detailShortInfoIcon" />
						<div className="detailShortInfoText">
							{Translate.T("de.Einkehrmöglichkeit", "en.Rest Stop", "path.reststop")}
						</div>
					</div>}

					{activity.pathLoop === true && <div className="detailShortInfoItem">
						<img alt="place" src="/assets/pathloop.svg" style={{width: iconSize, height: iconSize}} className="detailShortInfoIcon" />
						<div className="detailShortInfoText">
							{Translate.T("de.Rundtour", "en.Loop Tour", "path.looptour")}
						</div>
					</div>}
				{/* </div>

				<div className="detailShortInfoLine">					 */}

					{/*activity.reducedFamilyPrice && <div className="detailShortInfoItemSmall" onClick={this.OnShortInfoItemClicked("ticket")}>
						<img alt="familydiscount" src="/assets/tickets.svg" style={{width: iconSize, height: iconSize}} className="detailShortInfoIcon" />
						{this.showIconTooltip["ticket"] === true && <div className="detailShortInfoText">
							{Translate.T("en.Family discount", "de.Familien-Ermäßigung", "icon.title.familydiscount")}
						</div>}
					</div>*/}

					{activity.carParking && <div className="detailShortInfoItemSmall" onClick={this.OnShortInfoItemClicked("parking")}>
						<img alt="carparking" src="/assets/parking.svg" style={{width: iconSize, height: iconSize}} className="detailShortInfoIcon" />
						{this.showIconTooltip["parking"] === true && <div className="detailShortInfoText">
							{Translate.T("en.Parking spot", "de.Parkplatz", "icon.title.parkingspot")}
						</div>}
					</div>}

					{activity.accessible && <div className="detailShortInfoItemSmall" onClick={this.OnShortInfoItemClicked("accessible")}>
						<img alt="accessible" src="/assets/accessible.svg" style={{width: iconSize, height: iconSize}} className="detailShortInfoIcon" />
						{this.showIconTooltip["accessible"] === true && <div className="detailShortInfoText">
							{Translate.T("en.Accessible", "de.Barrierefrei", "icon.title.accessible")}
						</div>}
					</div>}

					{activity.strollerSuitable && <div className="detailShortInfoItemSmall" onClick={this.OnShortInfoItemClicked("stroller")}>
						<img alt="stroller" src="/assets/stroller.svg" style={{width: iconSize, height: iconSize}} className="detailShortInfoIcon" />
						{this.showIconTooltip["stroller"] === true && <div className="detailShortInfoText">
							{Translate.T("en.Baby carriages suitable", "de.Kinderwagen geeignet", "icon.title.strollersuitable")}
						</div>}
					</div>}

					{activity.bookingRequired && <div className="detailShortInfoItemSmall" onClick={this.OnShortInfoItemClicked("booking")}>
						<img alt="call" src="/assets/booking.svg" style={{width: iconSize, height: iconSize}} className="detailShortInfoIcon" />
						{this.showIconTooltip["booking"] === true && <div className="detailShortInfoText">
							{Translate.T("en.Booking required", "de.Buchung notwendig", "icon.title.bookingrequired")}
						</div>}
					</div>}

					{activity.dogsWelcome && <div className="detailShortInfoItemSmall" onClick={this.OnShortInfoItemClicked("dog")}>
						<img alt="dog" src="/assets/dog.svg" style={{width: iconSize, height: iconSize}} className="detailShortInfoIcon" />
						{this.showIconTooltip["dog"] === true && <div className="detailShortInfoText">
							{Translate.T("en.Dogs Welcome", "de.Hunde willkommen", "icon.title.dogswelcome")}
						</div>}
					</div>}

					{showNoeCard === true && <div className="detailShortInfoItemSmall" onClick={this.OnShortInfoItemClicked("nöcard")}>
						<img alt="Nö Card" src="/assets/noecard.png" style={{width: 32}} />
						{this.showIconTooltip["nöcard"] === true && <div className="detailShortInfoText">
							{Translate.T("en.NÖ Card", "de.NÖ Card", "icon.title.noecard")}
						</div>}
					</div>}

					{showKinderaktivcard === true && <div className="detailShortInfoItemSmall" onClick={this.OnShortInfoItemClicked("kinderaktivcard")}>
						<img alt="kinderaktivcard" src="/assets/kinderaktivcard.png" style={{width: 32}} />
						{this.showIconTooltip["kinderaktivcard"] === true && <div className="detailShortInfoText">
							{Translate.T("en.kinderaktivcard", "de.kinderaktivcard", "icon.title.kinderaktivcard")}
						</div>}
					</div>}
				</div>
			</div>
		);
	}

	OnCheckInClicked = (e) =>
	{
		e.stopPropagation();
		e.preventDefault();

		const listStatus = AppState.instance.listManager.GetContentStatus(this.props.data.activity.id);
		if (!listStatus.checkedin)
			return;

		const checkInListId = listStatus.checkInListId;

		AppState.instance.listManager.RemoveFromList(checkInListId, this.props.data.activity.id)
		.then(() =>
		{
			this.forceUpdate();
		});
	}

	OnHeartClicked = (e) =>
	{
		if (!this.IsLinkClickedAllowed("heart"))
			return;

		const bookmarkedLists = AppState.instance.listManager.GetListsOfContent(this.props.data.activity.id, true);
		if (bookmarkedLists && bookmarkedLists.length === 1)
		{
			// Currently bookmarked on just 1 list -> remove it
			this.OnSetUserList(bookmarkedLists[0].id);
			return;
		}

		if (!AppState.instance.IsLoggedIn())
		{
			var lists = AppState.instance.listManager.GetLists();
			if (lists.length === 1)
			{
				this.OnSetUserList(lists[0].id);
				return;
			}
		}

		setTimeout(() =>
		{
			//HACK: we have to wait with opening the dialog, otherwise this click-event would cause a backdrop-click event too and close the dialog again
			
			AppState.instance.screenMessage.ShowDialog(undefined, ListSelectionMenu, undefined, undefined, undefined,
				{
					onNewList: this.OnNewList,
					onListSelected: this.OnSetUserList,
					contentId: this.props.data.activity.id,
					title: Translate.T("en.Save in list", "de.In Liste speichern", "userlistdialog.saveentry.title")
				}, false, "dialogListSelectionButton", true);		
		}, 50);
		
		e.stopPropagation();
		e.preventDefault();
	}

	OnSetUserList = (listId) =>
	{
		if (listId !== undefined)
		{
			if (AppState.instance.listManager.IsContentOnList(this.props.data.activity.id, listId, true))
			{
				AppState.instance.listManager.RemoveFromList(listId, this.props.data.activity.id)
					.then(() =>
					{
						this.forceUpdate();
					});
			}
			else
			{
				AppState.instance.listManager.AddToList(listId, this.props.data.activity.id)
					.then(() =>
					{
						this.forceUpdate();
					});
			}
		}
	}

	OnNewList = () =>
	{
		const isLoggedIn = AppState.instance.IsLoggedIn();

		if (isLoggedIn)
		{
			this.showNewDialog = true;
		}
		else
		{
			AccountRequestPage.Show();
		}
	}

	OnNewDialogClose = () =>
	{
		this.showNewDialog = false;
	}

	renderComments(comments, key)
	{
		if (comments === undefined || comments.length === 0)
			return undefined;

		return (
			<div className="comments" key={key}>
				{comments.map((comment, index) =>
				{
					const html = Utils.TextToHtml(comment, "comment");
					return (<div key={index} className="comment">{html}</div>);
				})}
			</div>
		);
	}

	OnMatchIndicatorClicked = (e) =>
	{
		//const matchValue = this.props.data.matchIndicator;
		//const matchConf = this.props.data.matchConfidence;
		//const isLowConf = this.IsLowMatchConf(matchConf);

		if (this.showLowConfPopup)
		{
			this.showLowConfPopup = false;
		}
		else
		{
			this.showLowConfPopup = true;
		}

		e.stopPropagation();
		e.preventDefault();
	}

	OnCloseLowConfPopupClicked = () =>
	{
		this.showLowConfPopup = false;
	}

	OnInfoContainerClicked = (e) =>
	{
		if (this.showLowConfPopup)
			this.showLowConfPopup = false;

		MediaPlayer.CheckAuthorLinkClick(e);
	}

	IsLowMatchConf(v)
	{
		return v < 0.5;
	}

	OnLoginButton = (e) =>
	{
		e.stopPropagation();
		var msg = Translate.FindTranslation("accountrequest.matchindicator");
		AccountRequestPage.Show(msg);
	}

	OnAskQuestionsButton = (e) =>
	{
		e.stopPropagation();

		ApiInterface.SendCommand("action", "activity.suggest.context.questions");
		AppState.instance.swipePageInstance.ClearAllCards();
	}

	OnAddReview = () =>
	{
		if (!this.IsLinkClickedAllowed("addreview"))
			return;

		setTimeout(() => {
			AddReviewPage.Show(this.props.data, this.OnReviewSubmitted);	
		}, 150);
	}

	OnEditReview = () =>
	{
		setTimeout(() => {
			AddReviewPage.Show(this.props.data, this.OnReviewSubmitted);	
		}, 150);
	}

	OnCategoryClicked = (catName) => (e) =>
	{
		if (!this.IsLinkClickedAllowed("category"))
			return;

		e.stopPropagation();
		e.preventDefault();

		if (this.props.onCategoryClicked)
			this.props.onCategoryClicked(catName);
	}

	GetProgressItem(id)
	{
		for (var i = 0; i < this.progressItems.length; ++i)
		{
			if (this.progressItems[i].id === id)
			{
				return this.progressItems[i];
			}
		}
		return undefined;
	}

	RemoveProgressItem(id)
	{
		for (var i = 0; i < this.progressItems.length; ++i)
		{
			if (this.progressItems[i].id === id)
			{
				this.progressItems.splice(i, 1);
				break;
			}
		}
	}

	UploadActivityImage = (file) =>
	{
		var id;

		Utils.UploadActivityImage(file, this.props.data.activity.id, (uc) =>
		{
			console.log("upload finished:");
			console.log(uc);
			AppState.instance.contentStore.ClearActivityDetails(this.props.data.activity.name);
			this.props.data.userContent.push(uc);
			this.props.data.activity.pictureUrls.push(uc.url);
			this.props.data.contentIds.push(uc.id);

			if (this.mediaPlayer)
			{
				// Scroll MediaPlayer to right to show new image
				this.mediaPlayer.GoToEnd();
			}

			var item = this.GetProgressItem(id);
			if (item)
			{
				item.label = Translate.T("en.Done", "de.Fertig", "photo.upload.done");

				setTimeout(() => {
					item.show = false; // This will fade out the progress item	
				}, 500);				
			}
		},

		//progress:
		(event) =>
		{
			var item = this.GetProgressItem(id);
			if (item)
			{
				const progress = event.loaded / event.total * 100;
				item.progress = progress;
			}
		},

		//on error
		(error) =>
		{
			console.log("Upload error:");
			console.log(error);

			var item = this.GetProgressItem(id);
			if (item)
			{
				//item.label = Translate.T("en.Error while uploading", "de.Fehler beim Upload", "photo.upload.error");
				item.label = JSON.stringify(error);
				item.error = true;
				this.forceUpdate();
			}
		},
	
		// onLocalImageData
		(dataUrl) =>
		{
			id = Utils.GenerateRandomStr(32);

			this.progressItems.push(
			{
				id: id,
				progress: 0,
				show: true,
				dataUrl: dataUrl,
				label: Translate.T("en.Uploading image", "de.Photo-Upload", "photo.upload.progress"),
				onDone: () =>
				{
					this.RemoveProgressItem(id);
					this.forceUpdate();
				},
				onRetry: () =>
				{
					setTimeout(() => {
						var item = this.GetProgressItem(id);
						if (item)
							item.show = false;
						this.UploadActivityImage(file);	
					}, 200);
				},
				onCancel: () =>
				{
					setTimeout(() => {
						var item = this.GetProgressItem(id);
						if (item)
						{
							setTimeout(() => {
								item.show = false;
							}, 100);
						}	
					}, 200);
				}
			});
		});
	}

	OnAddPhoto = () =>
	{
		if (!this.IsLinkClickedAllowed("addphoto"))
			return;

		setTimeout(() => {
			if (AppState.instance.IsLoggedIn())
			{
				AppState.instance.appContainer.PromptImage(this.UploadActivityImage);
			}
			else
			{
				var msg = Translate.FindTranslation("accountrequest.addphoto");
				AccountRequestPage.Show(msg);
			}	
		}, 200);
	}

	/*OnAddVideo = () =>
	{
		var closeIcon = <div className="headerlessDialogCloseButton"><CloseIcon /></div>;

		AppState.instance.screenMessage.ShowDialog(Translate.T('en.Record video', 'de.Video aufnehmen', 'recordvideo'),
			RecorderPage, undefined, closeIcon, undefined, {activity: this.props.data.activity}, false);
	}*/

	OnDropDownClose = () =>
	{
		this.isMoreMenuOpen = false;
		this.isShareMenuOpen = false;
	}

	OnMoreClicked = () =>
	{
		if (!this.IsLinkClickedAllowed("more"))
			return;

		this.isMoreMenuOpen = !this.isMoreMenuOpen;

		if (this.isMoreMenuOpen)
		{
			if (this.props.onDropDownMenuOpen)
				this.props.onDropDownMenuOpen('more');
		}
	}

	OnShare = () =>
	{
		if (!this.IsLinkClickedAllowed("share"))
			return;

		this.isShareMenuOpen = !this.isShareMenuOpen;

		if (this.isShareMenuOpen)
		{
			if (this.props.onDropDownMenuOpen)
				this.props.onDropDownMenuOpen('share');
		}
	}

	OnWebsiteClicked = () =>
	{
		if (!this.IsLinkClickedAllowed("website"))
			return;

		setTimeout(() => {
			var infoUrl = this.props.data.activity.infoUrl;
			if (infoUrl && !infoUrl.startsWith("http"))
				infoUrl = "http://" + infoUrl;
			Utils.OpenExternalLink(infoUrl);
		}, 200);
	}

	OnSuggestEditClicked = () =>
	{
		if (!this.IsLinkClickedAllowed("navigation"))
			return;

		setTimeout(() => {
			if (AppState.instance.IsLoggedIn())
			{
				SuggestEditPage.Show(this.props.data);	
			}
			else
			{
				var msg = Translate.FindTranslation("accountrequest.suggestedit");
				AccountRequestPage.Show(msg);
			}
		}, 200);
	}

	OnCopyLink = () =>
	{
		const shareUrl = encodeURI("https://www.lena.app/activity/" + Utils.GetUrlName(this.props.data.activity.name));
		Utils.CopyTextToClipboard(shareUrl, (success) =>
		{
			if (success)
				AppState.instance.screenMessage.Show(Translate.T("de.Link in Zwischenlage kopiert.", "en.Copied link to clipboard.", "buttonaction.copylink"));
			else
				AppState.instance.screenMessage.Show(Translate.T("de.Fehler beim Kopieren.", "en.Error while copying.", "buttonaction.copylink.error"));
		});
	}

	OnTopRatingClicked = () =>
	{
		if (this.props.onTopRatingClicked)
			this.props.onTopRatingClicked();
	}

	OnTopWeatherClicked = () =>
	{
		if (this.props.onTopWeatherClicked)
			this.props.onTopWeatherClicked();
	}

	OnMessageSent = (message, withHeart) =>
	{
		//TODO: send heart to creator of activity
		//TODO: display creator with her hearts
		
		if (this.timeline)
			this.timeline.Refresh();
	}

	OnCheckIn = () =>
	{
		var q = {
			location: AppState.instance.GetBestLocation(),
			activityTitle: this.props.data.activity.name
		};

		RestApi.SendRequest("/checkin", q).then((userListItem) =>
		{
			if (userListItem)
			{
				this.isCheckedIn = true;
				AppState.instance.screenMessage.Show(
					Translate.T("de.Erfolgreich zu Deinen besuchten Ausflugszielen hinzugefügt.",
						"en.Successfully added to your list of visited places.", "checkin.success"));
				AppState.instance.listManager.OnNewListContent();
			}
			else
			{
				this.OnCheckInError();
			}
		})
		.catch((error) =>
		{
			this.OnCheckInError();
		});
	}

	OnCheckInError()
	{
		AppState.instance.screenMessage.Show(
			Translate.T("de.Fehler. Bitte versuche es später nochmal.", "en.Error. Please try again later.", "checkin.error"));
	}

	renderDetails()
	{
		var thisPtr = this;

		const city = Utils.ExtractCity(this.props.data.activity.address);

		var showNoeCard = false;
		var showKinderaktivcard = false;
		var showWienXtra = false;

		var categoryItems;
		if (this.props.data.categories !== undefined && this.props.data.categories.length > 0)
		{
			categoryItems = [];
			const prefix = "\u00A0• ";

			var currentPrefix = prefix;
			if (city === undefined || city.length === 0)
			{
				currentPrefix = null;
			}

			for (var ci = 0; ci < this.props.data.categories.length; ++ci)
			{
				var category = this.props.data.categories[ci];
				if (category === "NÖ Card")
				{
					showNoeCard = true;
				}
				else if (category === "kinderaktivcard")
				{
					showKinderaktivcard = true;
				}
				else if (category === "wienXtra")
				{
					showWienXtra = true;
				}
			}

			for (ci = 0; ci < this.props.data.categories.length; ++ci)
			{
				category = this.props.data.categories[ci];

				if (Utils.IsInvisibleTextCategory(category))
					continue;

				categoryItems.push(
					<span className="detailCategory" key={category} onClick={this.OnCategoryClicked(category)}>
		  				{currentPrefix}{category}
					</span>
				);

				currentPrefix = prefix;

				if (this.props.onlyOneCategory)
					break; // only show the first category
			}

			if (/*!this.props.onlyOneCategory &&*/ showWienXtra)
			{
				categoryItems.push(
					<span className="detailCategory wienXtra" key={"wienXtra"} onClick={this.OnCategoryClicked("wienXtra")}>
						{currentPrefix}
						<img alt="wienXtra" src="/assets/wienxtra.png" />
					</span>
				);
			}
		}

		var timelapse = "";
		if (this.props.data.activity.durationMin && this.props.data.activity.durationMax)
		{
			const roundMinH = Math.round(this.props.data.activity.durationMin);
			const roundMaxH = Math.round(this.props.data.activity.durationMax);
			if (roundMinH === roundMaxH)
				timelapse = roundMinH + " h";
			else
				timelapse = roundMinH + " - " + roundMaxH + " h";
		}
		else if (this.props.data.activity.durationMin)
		{
			timelapse = "> " + Math.round(this.props.data.activity.durationMin) + " h";
		}
		else if (this.props.data.activity.durationMax)
		{
			timelapse = "< " + Math.round(this.props.data.activity.durationMax) + " h";
		}

		var reviews = [];
		var highlightReview;
		var ownReviewFound = false;
		var googleReviewFound = false;
		if (this.props.data.reviews !== undefined)
		{
			for (var i = 0; i < this.props.data.reviews.length; ++i)
			{
				if (this.props.data.reviews[i].isOwnReview)
				{
					ownReviewFound = true;
					break;
				}
			}
			for (i = 0; i < this.props.data.reviews.length; ++i)
			{
				if (this.props.data.reviews[i].source === "GOOGLE")
				{
					googleReviewFound = true;
					break;
				}
			}

			var maxCount = 3;
			if (ownReviewFound)
				maxCount = 4;

			for (i = 0; i < this.props.data.reviews.length && i < maxCount; ++i)
			{
				reviews.push(
					<UserReview
						key={i}
						activity={this.props.data.activity}
						review={this.props.data.reviews[i]}
						collapsedText={true}
						onExpanded={() => {
							if (this.props.onChanged)
								this.props.onChanged(true);
						}}
						extraPaddingMeasure={this.props.containerClassname === "swipePage" ? 16 : 0}
						onEdit={this.OnEditReview}
					/>
				);

				if (this.props.data.reviews[i].data.highlight && !highlightReview)
				{
					highlightReview =
						<UserReview
							key={i}
							activity={this.props.data.activity}
							review={this.props.data.reviews[i]}
							collapsedText={true}
							highlight={true}
							onExpanded={() => {
								if (this.props.onChanged)
									this.props.onChanged(true);
							}}
							extraPaddingMeasure={0}
							onEdit={this.OnEditReview}
						/>;
				}
			}
		}

		//const images = this.props.data.activity.pictureUrls;
		//const photoIndex = this.photoIndex;

		var openingHours;
		if (this.props.data.openingHours !== undefined && this.props.data.openingHours.length > 0)
		{
			//TODO: nicht immer alles neu berechnen, sondern nur einmal
			openingHours = [];

			var now = Moment();
			var nowHours = now.hours();
			var nowMinutes = now.minutes();
			var nowSpot = nowHours * 100 + nowMinutes;
			//console.log("NOW: " + nowSpot);
			var isOpenToday = false;
			var isOpenNow = false;
			var isOpenThisWeek = false;

			for (i = 0; i < this.props.data.openingHours.length; ++i)
			{
				const dayStr = Moment().day(this.props.data.openingHours[i].dayOfWeek).format("dddd");
				var intervalElements = [];
				if (this.props.data.openingHours[i].openIntervals !== undefined)
				{
					for (var j = 0; j < this.props.data.openingHours[i].openIntervals.length; ++j)
					{
						var startHours = Math.trunc(this.props.data.openingHours[i].openIntervals[j].start / 100);
						var startMinutes = this.props.data.openingHours[i].openIntervals[j].start - startHours * 100;
						var startStr = Moment().hours(startHours).minutes(startMinutes).format("LT");

						var endHours = Math.trunc(this.props.data.openingHours[i].openIntervals[j].end / 100);
						var endMinutes = this.props.data.openingHours[i].openIntervals[j].end - endHours * 100;
						var endStr = Moment().hours(endHours).minutes(endMinutes).format("LT");

						var ic = this.renderComments(this.props.data.openingHours[i].openIntervals[j].comments, "comments_" + j);

						isOpenThisWeek = true;

						if (this.props.data.openingHours[i].openIntervals[j].start === 0 &&
							this.props.data.openingHours[i].openIntervals[j].end === 2400)
						{
							intervalElements.push(
								<div key={j} className="detailOpenDayInterval">
									Open
									{ic}
								</div>
							);
						}
						else if (this.props.data.openingHours[i].openIntervals[j].start === this.props.data.openingHours[i].openIntervals[j].end)
						{
							intervalElements.push(
								<div key={j} className="detailOpenDayInterval">
									{startStr}
									{ic}
								</div>
							);
						}
						else
						{
							intervalElements.push(
								<div key={j} className="detailOpenDayInterval">
									{startStr} - {endStr}
									{ic}
								</div>
							);
						}

						if (i === 0)
						{
							// Just if it's open today
							if (this.props.data.openingHours[i].openIntervals[j].start <= nowSpot &&
								this.props.data.openingHours[i].openIntervals[j].end >= nowSpot)
							{
								isOpenNow = true;
								isOpenToday = true;
							}

							if (this.props.data.openingHours[i].openIntervals[j].start >= nowSpot)
							{
								isOpenToday = true;
							}

							if (isOpenNow)
							{
								if (this.props.data.openingHours[i].openIntervals[j].start === 0 &&
									this.props.data.openingHours[i].openIntervals[j].end === 2400)
								{
									isOpenNow = false;
								}
							}
						}
					}
				}

				if (intervalElements.length === 0)
					intervalElements = Translate.T("en.Closed", "de.Geschlossen", "openinghours.closed");

				if (this.showOpenDetails)
				{
					var firstDayClass = null;
					if (i === 0)
						firstDayClass = " detailOpenDayFirst";

					var dc = this.renderComments(this.props.data.openingHours[i].comments, "daycomment_" + i);

					openingHours.push(
						<div key={"container" + i} className="detailOpenDayContainer">
							<div key={"day" + i} className={"detailOpenDay" + firstDayClass}>
								{dayStr}
							</div>
							<div key={"intervals" + i} className="detailOpenDayIntervals">
								{intervalElements}
							</div>
							{dc}		
						</div>
					);
				}
			}

			if (!this.showOpenDetails)
			{
				var openDetails =
					<div onClick={this.OpenDetailsClicked} className="detailOpenDetailsButton">
						<span className="detailOpenDetailsButtonText">
							{Translate.T("en.Details", "de.Details", "openinghours.menu.details")}
						</span>
						<span className="detailOpenDetailsChevron"><ChevronRight /></span>
					</div>;

				if (isOpenNow)
				{
					openingHours.push(
						<div key={"containerOpenOverview"} className="containerOpenOverview">
							<div className="detailOpenNow">
								{Translate.T("en.Open Now", "de.Jetzt geöffnet", "openinghours.opennow")}
							</div>
							{openDetails}
						</div>
					);
				}
				else if (isOpenToday)
				{
					openingHours.push(
						<div key={"containerOpenOverview"} className="containerOpenOverview">
							<div className="detailOpenToday">
								{Translate.T("en.Open Today", "de.Heute geöffnet", "openinghours.opentoday")}
							</div>
							{openDetails}
						</div>
					);
				}
				else
				{
					openingHours.push(
						<div key={"containerOpenOverview"} className="containerOpenOverview">
							<div className="detailClosedToday">
								{Translate.T("en.Closed Today", "de.Heute geschlossen", "openinghours.closedtoday")}
							</div>
							{openDetails}
						</div>
					);
				}
			}

			if (this.props.data.nextOpeningInDays === 1000)
			{
				openingHours.push(
					<div className="nextOpenInDays" key="nextOpenInDays">
						{Translate.T("en.No future opening hours known", "de.Keine weiteren Öffnungszeiten bekannt", "openinghours.nonextopening")}
					</div>
				);
			}
			else if (!isOpenThisWeek && this.props.data.nextOpeningInDays >= 7)
			{
				const nextInMinutes = this.props.data.nextOpeningInDays * 24 * 60;
				const nextOpen = Moment().add(nextInMinutes, 'm');
				const nextOpenStr = nextOpen.format("LL");
				openingHours.push(
					<div className="nextOpenInDays" key="nextOpenInDays">
						{Translate.T("en.Opens again on {0}", "de.Öffnet wieder am {0}", "openinghours.nextopeningindays", [nextOpenStr])}
					</div>
				);
			}

			var c = this.renderComments(this.props.data.openingHoursComments, "generalcomments");
			if (c)
				openingHours.push(c);
		}
		else if (this.props.data.activity.openingHours !== undefined && this.props.data.activity.openingHours.length > 0)
		{
			openingHours = this.props.data.activity.openingHours;
		}

		var priceStr = this.props.data.activity.price;
		if (priceStr)
		{
			var doShowPriceDetails = this.showPriceDetails;
			if (showKinderaktivcard)
				doShowPriceDetails = true;

			priceStr = priceStr.split('\n');
			if (priceStr.length >= 1)
			{
				var priceIcons;
				var lines = [];
				for (i = 0; i < priceStr.length; ++i)
				{
					const line = priceStr[i];
					if (line === '€' || line === '€€' || line === '€€€')
					{
						var priceIconsDesc = [];
						priceIconsDesc.push(Translate.T("en.low-priced", "de.kostengünstig", "pricing.desc.1"));
						priceIconsDesc.push(Translate.T("en.moderate", "de.moderat", "pricing.desc.2"));
						priceIconsDesc.push(Translate.T("en.expensive", "de.teuer", "pricing.desc.3"));

						priceIcons = (<span key={i} className={"priceIcons" + ((priceStr.length > 1 && doShowPriceDetails) ? "PlusText" : "")}>
							<Rating value={line.length} starCount={3} renderStarIcon={(index, value, name) =>
								{
									if (index <= value)
										return <EuroSymbol className="priceIconFull" />;
									else
										return <EuroSymbol className="priceIconEmpty" />;
								}} />
							<span className="priceIconsDesc">{priceIconsDesc[line.length - 1]}</span>

							{!doShowPriceDetails && priceStr.length > 1 && <div onClick={this.OpenPriceDetailsClicked} className="detailOpenDetailsButton">
								<span className="detailOpenDetailsButtonText">
									{Translate.T("en.Details", "de.Details", "openinghours.menu.details")}
								</span>
								<span className="detailOpenDetailsChevron"><ChevronRight /></span>
							</div>}

						</span>);

						lines.push(priceIcons);
					}
					else
					{
						lines.push(
							<div key={i}>
								{Utils.TextToHtml(priceStr[i].trim(), "desc")}
							</div>
						);
					}
				}

				if (!doShowPriceDetails && priceIcons)
				{
					lines = [];
					lines.push(priceIcons);
				}

				priceStr = lines;
			}
		}

		var isInActiveTrip = this.IsInActiveTrip();
		//var trip = AppState.instance.userProfile.trip;

		var hotData = {};
		if (this.props.data.liveData)
			hotData = LiveData.GetHotData(this.props.data.liveData);

		var matchText;
		var matchConfText;
		var suggestionCardMatchClass = "";
		/*if (this.props.data.matchIndicator)
		{
			matchText = Math.round(this.props.data.matchIndicator * 100) + " %";
			if (this.props.data.matchIndicator < 0.7)
				suggestionCardMatchClass = " suggestionCardMatchLow";
			else if (this.props.data.matchIndicator < 0.85)
				suggestionCardMatchClass = " suggestionCardMatchMed";

			if (this.IsLowMatchConf(this.props.data.matchConfidence))
			{
				//matchConfText = Translate.T("en.Low\nConfidence", "de.Unsicherer\nWert", "confidence.low");
				suggestionCardMatchClass = " suggestionCardMatchUnconfident";
			}

			if (Tutorial.IsActive())
				suggestionCardMatchClass = " suggestionCardMatchTutorial";

			//console.log("CONF: " + this.props.data.matchConfidence);
		}*/

		var contactAddress = this.props.data.activity.address;
		contactAddress = Utils.RemoveCountry(contactAddress);

		var desc = Utils.TextToHtml(this.props.data.activity.desc, "desc");

		const listStatus = AppState.instance.listManager.GetContentStatus(this.props.data.activity.id);
		const isBookmarked = listStatus.bookmarked;
		const isCheckedIn = listStatus.checkedin;

		var highlightMarkers = [];
		if (this.props.data.activity.latitude !== 0)
		{
			highlightMarkers.push(Map.CreateHighlightMarker(
				this.props.data.activity.latitude,
				this.props.data.activity.longitude,
				this.props.data.activity,
				this.props.data.categories
			));
		}

		const matchConf = this.props.data.matchConfidence;
		const isLowConf = this.IsLowMatchConf(matchConf);
		const isLoggedIn = AppState.instance.IsLoggedIn();

		var infoUrlText = this.props.data.activity.infoUrl;
		if (infoUrlText)
		{
			infoUrlText = infoUrlText.replace("http://", "");
			infoUrlText = infoUrlText.replace("https://", "");
			if (infoUrlText.endsWith("/"))
				infoUrlText = infoUrlText.substring(0, infoUrlText.length - 1);
			const maxLength = AppState.instance.deviceInfo.desktop ? 60 : 30;
			if (infoUrlText.length > maxLength)
				infoUrlText = infoUrlText.substring(0, maxLength - 2) + "...";
		}

		//<a href={Utils.GetGoogleMapsAddress(this.props.data.activity.address)} target="_blank" className="detailIconLink"><Directions/></a>
		var actionButtons = [
			{
				icon: <Directions/>,
				label: Translate.T("en.Directions", "de.Navigation", "actionbutton.directions"),
				onClick: this.OnStartNavigation
			},
			{
				icon: Utils.RenderStarIcon(24, 24, isBookmarked ? "#e31c46" : "#777", isBookmarked ? "#e31c46" : "white", 2.5),
				label: Translate.T("en.Save", "de.Speichern", "actionbutton.bookmark"),
				onClick: this.OnHeartClicked
			},
			{
				icon: <AddAPhoto />,
				label: Translate.T("en.Photo", "de.Photo", "actionbutton.photo"),
				onClick: this.OnAddPhoto
			},
			{
				icon: <Share/>,
				label: Translate.T("en.Share", "de.Teilen", "actionbutton.share"),
				onClick: this.OnShare,
				share: true
			},
			{
				icon: <Web/>,
				label: Translate.T("en.Website", "de.Webseite", "actionbutton.website"),
				onClick: this.OnWebsiteClicked
			},
			{
				icon: <ModeEdit/>,
				label: Translate.T("en.Suggest\nAn Edit", "de.Änderung\nVorschlagen", "actionbutton.suggestedit"),
				onClick: this.OnSuggestEditClicked
			}
		];

		if (this.props.data.activity.phone)
		{
			actionButtons.push({
				icon: <Phone/>,
				label: Translate.T("en.Call", "de.Anruf", "actionbutton.call"),
				onClick: this.OnStartCall
			});
		}

		if (this.props.allowAddTripButton)
		{
			if (AppState.instance.userProfile.trip.active) //<div className={"detailAddTripButton" + (isInActiveTrip ? " detailRemoveTripButton":"")}>
			{
				var t;
				if (isInActiveTrip)
					t = Translate.T("en.Remove from trip", "de.Von Ausflug entfernen", "detailpage.button.removefromtrip");
				else
					t = Translate.T("en.Add to\nTrip", "de.Zu Ausflug\nhinzufügen", "actionbutton.addtrip");

				actionButtons.splice(0, 0, {
					icon: isInActiveTrip ? <EditLocation/> : <AddLocation/>,
					label: t,
					onClick: this.OnAddToTrip,
					invertColor: true
				});
			}
			else
			{
				actionButtons.push({
					icon: <AddLocation/>,
					label: Translate.T("en.Plan Trip", "de.Plane Ausflug", "actionbutton.trip"),
					onClick: this.OnAddToTrip
				});
			}
			// 	<Button onClick={this.OnAddToTrip} raised>
			// 		{!isInActiveTrip && <AddLocation/>}
			// 		{!isInActiveTrip && Translate.T("en.Add to trip", "de.Zu Ausflug hinzufügen", "detailpage.button.addtotrip")}
			// 		{isInActiveTrip && Translate.T("en.Remove from trip", "de.Von Ausflug entfernen", "detailpage.button.removefromtrip")}
			// 	</Button>
			// </div>}
		}

		actionButtons.push({
			icon: <ContentCopy/>,
			label: Translate.T("en.Copy Link", "de.Kopiere Link", "actionbutton.copylink"),
			onClick: this.OnCopyLink
		});

		// if (this.isCheckedIn)
		// {
		// 	actionButtons.splice(0, 0, {
		// 		icon: <CheckIcon/>,
		// 		label: Translate.T("en.Check-In", "de.Ich bin hier", "actionbutton.checkin"),
		// 		className: "checkin done"
		// 	});
		// }
		// else if (this.props.data.distance && this.props.data.distance.distanceValue < 500)
		// {
		// 	actionButtons.splice(0, 0, {
		// 		icon: <PlaceIcon/>,
		// 		label: Translate.T("en.Check-In", "de.Ich bin hier", "actionbutton.checkin"),
		// 		onClick: this.OnCheckIn,
		// 		className: "checkin"
		// 	});
		// }

		const shareUrl = encodeURI("https://www.lena.app/activity/" + Utils.GetUrlName(this.props.data.activity.name));
		const title = this.props.data.activity.name;
		const emailBody = Translate.FindTranslation("share.email.body", [title, city, shareUrl]);

		//TODO: report

		var maxNumButtons = 4;
		const vw = window.innerWidth;
		if (vw < 330)
			maxNumButtons = 3;
		if (vw > 460)
			maxNumButtons = 5;
		if (vw >= 1000)
			maxNumButtons = 5;
		if (AppState.instance.isLandingPage || AppState.instance.isProviderPage)
			maxNumButtons = 4;

		const menuOptions = {
			isOpen: this.isMoreMenuOpen,
			close: this.OnDropDownClose,
			toggle: (<Button className="actionButtonsMoreIcon" onClick={this.OnMoreClicked}>
				<MoreVert/>
			</Button>),
			align: 'right'
		};

		const shareMenuOptions = {
			isOpen: this.isShareMenuOpen,
			close: this.OnDropDownClose,
			align: 'right'
		};

		var infoUrl = this.props.data.activity.infoUrl;
		if (infoUrl && !infoUrl.startsWith("http"))
			infoUrl = "http://" + infoUrl;

		const isNear = this.props.data.distance && this.props.data.distance.distanceValue < 2000;
		const canCheckIn = this.props.data.distance && this.props.data.distance.distanceValue < 500;
		const showCheckInBar = isNear || canCheckIn || this.isCheckedIn;

		const isAustriaTourismData = (this.props.data.attributions && this.props.data.attributions.indexOf("oew") >= 0);
		let atSourceName;
		let atSourceUrl;
		let atSourceImg;
		if (isAustriaTourismData)
		{
			for (let i = 0; i < this.props.data.attributions.length; ++i)
			{
				const attr = this.props.data.attributions[i];
				if (attr.startsWith("source="))
					atSourceName = attr.substring(7);
				else if (attr.startsWith("sourceurl="))
					atSourceUrl = attr.substring(10);
				else if (attr.startsWith("sourceimg="))
					atSourceImg = attr.substring(10);
			}
		}

		let mapCenterLat;
		let mapCenterLng;
		if (this.props.data.activity)
		{
			mapCenterLat = this.props.data.activity.latitude;
			mapCenterLng = this.props.data.activity.longitude;

			const bounds = Utils.CalcBoundsOfPath(this.props.data.activity.pathCoordinates);
			if (bounds)
			{
				if (mapCenterLat > bounds.latMax)
					bounds.latMax = mapCenterLat;
				if (mapCenterLat < bounds.latMin)
					bounds.latMin = mapCenterLat;

				if (mapCenterLng > bounds.lngMax)
					bounds.lngMax = mapCenterLng;
				if (mapCenterLng < bounds.lngMin)
					bounds.lngMin = mapCenterLng;

				mapCenterLat = (bounds.latMin + bounds.latMax) * 0.5;
				mapCenterLng = (bounds.lngMin + bounds.lngMax) * 0.5;
			}
		}

		return (
			<article className={"detailContainer" + (showCheckInBar ? " withCheckInBar":"")} data-tkg={"activity." + this.props.data.activity.id}>

				{showCheckInBar &&
					<div className="checkInTopBar">
						{canCheckIn && !this.isCheckedIn &&
							<Button className="checkin" onClick={this.OnCheckIn}>
								<PlaceIcon/>
								{Translate.T("en.Check-In", "de.Ich bin hier", "checkInTopBar.checkin")}
							</Button>}
						{this.isCheckedIn &&
							<div className="checkin done">
								<CheckIcon/>
								{Translate.T("en.Check-In", "de.Ich bin hier", "checkInTopBar.checkin.done")}
							</div>}
						{isNear && !canCheckIn && !this.isCheckedIn &&
							<div className="nearby">
								{Translate.T("en.Nearby", "de.In der Nähe", "checkInTopBar.nearby")}
							</div>}
					</div>}

				<MediaPlayer
					data={this.props.data}
					defaultContentHeight={this.defaultContentHeight}
					onSwitching={this.props.onMediaSwitching}
					onTouchEnd={this.props.onMediaTouchEnd}
					onTouchStart={this.props.onMediaTouchStart}
					onBeforeZoom={this.props.onBeforeMediaZoom}
					onAfterZoom={this.props.onAfterMediaZoom}
					containerClassname={this.props.containerClassname}
					//ref={(mp) => {this.mediaPlayer = mp;}}
					onInstance={this.OnMediaPlayerInstance}
					allowGallery={true}
				/>

				{this.props.showBookmarkStatus && <div className="detailBookmarkStatus" onClick={this.OnHeartClicked}>
					{isBookmarked && Utils.RenderStarIcon(24, 24, "white", "#e31c46", 2.5)}
					{/*!isBookmarked && isSessionBookmarked && Utils.RenderStarIcon(24, 24, "#e31c46", "transparent", 2.5)*/}
					{!isBookmarked /*&& !isSessionBookmarked*/ && Utils.RenderStarIcon(24, 24, "white", "rgba(0,0,0,0)", 2.5)}
				</div>}

				{isCheckedIn && <div onClick={this.OnCheckInClicked} className={"detailCheckInStatus" + (isBookmarked && this.props.showBookmarkStatus ? " left":"")}>
					<PlaceIcon />
				</div>}

				<div className="detailInfoContainer" onClick={this.OnInfoContainerClicked}>

					<div className="detailIntroContainer">

						<h1 className="detailTitle">
							{Utils.DecodeHtml(this.props.data.activity.name)}
						</h1>

						{matchText !== undefined && <div className={"suggestionCardMatch" + suggestionCardMatchClass} onClick={this.OnMatchIndicatorClicked}>
							{matchText}
						</div>}
						{matchConfText !== undefined && <div className="suggestionCardMatchConf" onClick={this.OnMatchIndicatorClicked}>
							{matchConfText}
						</div>}
						{this.showLowConfPopup && <div className="lowConfPopup" onClick={this.OnCloseLowConfPopupClicked}>
							<div className="lowConfPopupText">
								{Translate.T("en.This percentage value gives an indication how well the presented activity is matching your interests and needs.",
								"de.Dieser Prozentwert drückt aus wie gut der dargestellte Vorschlag zu Deinen Interessen und Bedürfnissen passt.",
								"matchindicator.text1")}
							</div>
							{/*isLowConf && <div className="lowConfPopupText">
								{Translate.T("en.In case your personal profile is low on information and LENA can't calculate the matching percentage the hint \"Low Confidence\" is shown.",
								"de.Falls Dein Profil noch zu wenig Information enthält und LENA dadurch keinen guten Prozentwert berechnen kann, erscheint unter dem Prozentwert \"Unsicherer Wert\".",
								"matchindicator.text2")}
							</div>*/}
							{!isLoggedIn && <div className="lowConfPopupText">
								{Translate.T("en.Create an account and answer some questions to increase the quality of your suggestions.",
									"de.Lege einen Account an und beantworte ein paar Fragen um die Qualität der Vorschläge zu verbessern.",
									"matchindicator.cta_text")}
							</div>}
							{!isLoggedIn && <div className="lowConfPopupButtonWrapper">
								<Button color="default" raised onClick={this.OnLoginButton}>
									{Translate.T("en.Login", "de.Einloggen", "login")}
								</Button>
							</div>}

							{isLowConf && isLoggedIn && <div className="lowConfPopupText">
								{Translate.T("en.Answer some questions to increase the quality of your suggestions.",
									"de.Beantworte ein paar Fragen um die Qualität Deiner Vorschläge zu verbessern.",
									"matchindicator.cta2_text")}
							</div>}
							{isLowConf && isLoggedIn && <div className="lowConfPopupButtonWrapper">
								<Button color="default" raised onClick={this.OnAskQuestionsButton}>
									{Translate.T("en.Answer Questions", "de.Fragen beantworten", "matchindicator.cta2_button")}
								</Button>
							</div>}

							{!AppState.instance.isProduction && <div>{this.props.data.matchConfidence}</div>}
						</div>}

						<div className="detailCity">
							{city}
						</div>

						{categoryItems}

						{/*AppState.instance.userProfile.trip.active && this.props.allowAddTripButton && <div className={"detailAddTripButton" + (isInActiveTrip ? " detailRemoveTripButton":"")}>
							<Button onClick={this.OnAddToTrip} raised>
								{!isInActiveTrip && <AddLocation/>}
								{!isInActiveTrip && Translate.T("en.Add to trip", "de.Zu Ausflug hinzufügen", "detailpage.button.addtotrip")}
								{isInActiveTrip && Translate.T("en.Remove from trip", "de.Von Ausflug entfernen", "detailpage.button.removefromtrip")}
							</Button>
						</div>*/}

					</div>

					{hotData && <div className="detailLiveData">

						<div className="ratingSubContainer" onClick={this.OnTopRatingClicked}>
							<Rating value={this.props.data.activity.rating}/>
						</div>

						{/* {hotData.numCurrentVisitors > 0 && <div className="detailLiveDataItem detailLiveDataVisitors"><Icon>person</Icon><span className="label">{hotData.numCurrentVisitors}</span></div>}
						<div className="detailLiveDataItem detailLiveDataViewers"><Icon>remove_red_eye</Icon><span className="label">{hotData.numCurrentViewers ? hotData.numCurrentViewers : 1}</span></div> */}
					</div>}

					{AppState.instance.featureAdv && <AdvCardFetcher activityId={this.props.data.activity.id} distance={this.props.data.distance}
						activityLat={this.props.data.activity.latitude}
						activityLng={this.props.data.activity.longitude}
						onHintClicked={this.props.onCardFetcherHintClicked}
					/>}

					{this.props.data.distance && <div className="detailSection">
						{this.RenderShortInfoIcons(this.props.data.activity, this.props.data.distance, timelapse, this.decodedWeather, showNoeCard, showKinderaktivcard)}
					</div>}

					<div className="detailSection actionButtons" data-tk="actionButtons">
						{actionButtons.map((actionButton, index) =>
						{
							if (index >= maxNumButtons)
								return undefined;

							var result = (
								<Button
									key={index} className={"actionButton" + (actionButton.invertColor ? " inverted ":" ") + actionButton.className} onClick={actionButton.onClick}
								>
									<div className="actionButtonContent">
										<div className="actionButtonIcon">{actionButton.icon}</div>
										<div className="actionButtonLabel">{actionButton.label}</div>
									</div>
								</Button>
							);

							if (actionButton.share)
							{
								shareMenuOptions.toggle = result;

								return (
									<DropdownMenu key={index} className="buttonActionMore" {...shareMenuOptions}>
										<li>
											<FacebookShareButton
												url={shareUrl}
												quote={title}
												className="shareButton shareFacebookButton">
												<FacebookIcon size={48} round />
											</FacebookShareButton>
										</li>
										<li>
											<EmailShareButton
												url={shareUrl}
												subject={Translate.FindTranslation("share.email.title", [title])}
												body={emailBody}
												className="shareButton shareEmailButton">
												<EmailIcon size={48} round />
											</EmailShareButton>
										</li>
									</DropdownMenu>
								);
							}
							return result;
						})}

						{actionButtons.length > maxNumButtons && <DropdownMenu className="buttonActionMore" {...menuOptions}>
							{actionButtons.map((actionButton, index) =>
							{
								if (index < maxNumButtons)
									return undefined;
								return (
									<li key={index}>
										<Button className={"actionButton" + (actionButton.invertColor ? " inverted":"")} onClick={actionButton.onClick}>
											<div className="actionButtonContent">
												<div className="actionButtonIcon">{actionButton.icon}</div>
												<div className="actionButtonLabel">{actionButton.label}</div>
											</div>
										</Button>
									</li>
								);
							})}
						</DropdownMenu>}

						<UserListSelection
							dialogMode={true}
							visible={this.showNewDialog}
							onClose={this.OnNewDialogClose}
							setUserList={this.OnSetUserList}
						/>
					</div>

					{this.progressItems.length > 0 && <div className="progressItems">
						{this.progressItems.map((progressItem, index) =>
						{
							return (<ProgressDisplay data={progressItem} key={index} onDone={progressItem.onDone} isError={progressItem.error} />);
						})}
					</div>}

					{(desc || highlightReview) && <div className="detailSection" data-tk="desc">

						{this.props.data.username && this.props.data.activity && <ContentSource
							username={this.props.data.username}
							//time={this.props.data.activity.created}
							contentType={"activity"}
							//onDeleteContent={this.OnDeleteContent}
							//share={content.share}
							//onChangeShare={this.OnChangeShare}
						/>}

						<h2 className="detailSectionTitle">
							{Translate.T("en.Description", "de.Beschreibung", "detail.description.title")}
						</h2>
						<div className="detailSectionText detailDesc">
							{desc}
							{highlightReview && <div className="highlightReviews">
								{highlightReview}
							</div>}
						</div>
					</div>}

					{(this.props.data.activity.pathDifficulty !== undefined || this.props.data.activity.pathLength !== undefined) &&
						<div className="detailSection" data-tk="pathinfo">
							<h2 className="detailSectionTitle">
								{Translate.T("en.Tour Details", "de.Tour Informationen", "detail.tourdetails.title")}
							</h2>
							<div className="detailSectionText detailTour">

								{this.props.data.activity.pathDifficulty > 0 &&
									<div className="pathdifficulty">
										{Translate.T("de.Schwierigkeit", "en.Difficulty Level", "detail.path.difficulty")}
										<span className="text">
											{this.props.data.activity.pathDifficulty === 1 && Translate.T("de.leicht", "en.easy", "path.difficulty.easy")}
											{this.props.data.activity.pathDifficulty === 2 && Translate.T("de.leicht/mittel", "en.easy/medium", "path.difficulty.easymedium")}
											{this.props.data.activity.pathDifficulty === 3 && Translate.T("de.mittel", "en.medium", "path.difficulty.medium")}
											{this.props.data.activity.pathDifficulty === 4 && Translate.T("de.mittel/schwer", "en.medium/hard", "path.difficulty.mediumhard")}
											{this.props.data.activity.pathDifficulty === 5 && Translate.T("de.schwer", "en.hard", "path.difficulty.hard")}
										</span>
										<Rating value={this.props.data.activity.pathDifficulty} starCount={5} renderStarIcon={(index, value, name) =>
											{
												if (index <= value)
													return <span className="pathdifficon full" />;
												else
													return <span className="pathdifficon empty" />;
											}}/>
									</div>}

								{this.props.data.activity.pathLength !== undefined && <div className="pathlength">
									{Translate.T("de.Tour Länge", "en.Tour Length", "detail.path.length")}
									<span>{Utils.CalcPathLengthStr(this.props.data.activity.pathLength) + " km"}</span>
								</div>}

								{this.props.data.activity.pathRestStop === true && <div className="pathreststop">
									{Translate.T("de.Einkehrmöglichkeit vorhanden", "en.Rest Stop available", "detail.path.reststop")}
								</div>}

								{this.props.data.activity.pathRestStop === true && <div className="pathloop">
									{Translate.T("de.Rundtour", "en.Loop Tour", "detail.path.looptour")}
								</div>}
								
							</div>
						</div>}

					{this.props.data.activity.id && <div className="detailSection detailSectionReviews" data-tk="reviews">
						<h2 className="detailSectionTitle detailSectionTitleReviews">
							{Translate.T("de.Diskussion", "en.Discussion", "detail.discussion.title")}
						</h2>

						<SocialInteraction
							receiverType={"activity"}
							receiverId={this.props.data.activity.id}
							enableSendHeart={true}
							onMessageSent={this.OnMessageSent}
							//onCommentChanged={this.props.onCommentChanged}
						/>

						<Timeline
							activityId={this.props.data.activity.id}
							pullToRefreshDisabled={true}
							isOwnUser={false}
							disableEodMsg={true}
							context={"activity"}
							loadMoreButton={true}
							onInit={(instance) => this.timeline = instance}
							onLayoutChanged={() => {
								if (this.props.onChanged)
									this.props.onChanged(true);
							}}
							onMediaSwitching={this.props.onMediaSwitching}
							onMediaTouchEnd={this.props.onMediaTouchEnd}
							onMediaTouchStart={this.props.onMediaTouchStart}
						/>
					</div>}

					{openingHours && <div className="detailSection" data-tk="opening">
						<h2 className="detailSectionTitle">
							{Translate.T("en.Opening Hours", "de.Öffnungszeiten", "detail.openinghours.title")}
						</h2>
						<div className="detailSectionText detailOpening">
							{openingHours}
						</div>
					</div>}

					{this.props.data.activity.price && <div className="detailSection" data-tk="price">
						<h2 className="detailSectionTitle">
							{Translate.T("en.Prices", "de.Preise", "detail.prices.title")}
						</h2>
						<div className="detailSectionText detailPrice">
							{priceStr}

							{showKinderaktivcard &&
								<div
									className="kinderaktivcard"
									onClick={this.OnWeblinkClickedUrl("https://www.wienxtra.at/kinderaktiv/kinderaktivcard/")}
								>
									<img alt="kinderaktivcard" src="/assets/kinderaktivcard.png" />
									{Translate.T("de.KINDERAKTIVCARD gratis bestellen", "en.Get KINDERAKTIVCARD for free", "detail.prices.kinderaktivcard.order")}
									<OpenInNewIcon/>
								</div>}
						</div>
					</div>}					

					{/*!AppState.instance.featureSocial && this.props.data.reviews && <div className="detailSection detailSectionReviews">
						<h2 className="detailSectionTitle detailSectionTitleReviews">
							{Translate.T("de.Bewertungen", "en.Reviews", "detail.reviews.title")}
						</h2>
						{this.props.data.activity.rating !== undefined && this.props.data.activity.rating > 0 && <div className="ratingContainer">
							<Rating
								value={this.props.data.activity.rating}
								starColor="#e31c46"
							/>
						</div>}
						<div className="detailReviews">
							{reviews}
							{reviews.length > 0 && <div className="detailReadAllReviews">
								<Button onClick={this.OnAllReviews}>
									{Translate.T("en.Read all reviews", "de.Alle Bewertungen lesen", "button.readallreviews")}
								</Button>
							</div>}
						</div>
						{!ownReviewFound && <div className="detailAddReviewContainer">
							<Button className={this.props.classes.addReviewButton} onClick={this.OnAddReview} raised>
								{Translate.T("en.Add review", "de.Bewertung abgeben", "button.addreview")}
							</Button>
						</div>}
					</div>*/}

					{this.props.data.weatherForecast && this.props.data.weatherForecast.length > 0 &&
						<WeatherDisplay data={this.props.data.weatherForecast} onExpanded={this.OnWeatherExpanded} />}
							
					{(this.props.data.activity.address || this.props.data.activity.infoUrl || this.props.data.activity.email || this.props.data.activity.phone) &&
					<div className="detailSection" data-tk="contact">
						<h2 className="detailSectionTitle">
							{Translate.T("en.Contact", "de.Kontakt", "detail.contact.title")}
						</h2>
						<div className="detailSectionText">
							{this.props.data.activity.infoUrl && <div className="detailEmail">
								<a href={infoUrl} target="_blank" className="detailIconLink"><Web/></a>
								<div className="detailAddressText">
									<a href={infoUrl} target="_blank" className="detailEmailLink">{infoUrlText}</a>
								</div>
							</div>
							}
							{this.props.data.activity.email && <div className="detailEmail">
								<EmailLink email={this.props.data.activity.email} className="detailIconLink" icon={<Email/>} />
								<div className="detailAddressText">
									<EmailLink email={this.props.data.activity.email} className="detailEmailLink" />
								</div>
							</div>}
							{this.props.data.activity.phone && <div className="detailPhone">
								<a href={"tel:"+this.props.data.activity.phone} className="detailIconLink"><Phone/></a>
								<div className="detailAddressText">
									<a href={"tel:"+this.props.data.activity.phone} className="detailPhoneLink">{this.props.data.activity.phone}</a>
								</div>
							</div>}
							{this.props.data.activity.address && <div className="detailAddress">
								<a href={Utils.GetGoogleMapsAddress(this.props.data.activity.address)} target="_blank" className="detailIconLink"><Directions/></a>
								<div className="detailAddressText">
									<a href={Utils.GetGoogleMapsAddress(this.props.data.activity.address)} target="_blank" className="detailAddressLink">{contactAddress}</a>
								</div>
							</div>}
						</div>
					</div>}
				</div>

				{mapCenterLat && mapCenterLng &&
					/*!AppState.instance.isLandingPage &&*/
					<Map id="detailMap" gestureHandling="cooperative"
						forceInclude={[this.props.data.activity.id]}
						hideContentCard={[this.props.data.activity.id]}
						zoomFactor={(this.props.data.activity.pathCoordinates && this.props.data.activity.pathCoordinates.length > 0) ? 12 : 12}
						centerLat={mapCenterLat}
						centerLng={mapCenterLng}
						showLocation={false}
						maxNumCategories={1}
						mapSimpleMode={this.props.mapSimpleMode}
						disableFilterButton={true}
						highlightMarkers={highlightMarkers}
						loadOnVisibility={false}
						showTitle={true}
						onTouchEnd={this.props.onMediaTouchEnd}
						onTouchStart={this.props.onMediaTouchStart}
						ignoreActiveFilter={this.props.ignoreActiveFilter}
						onInit={(instance) => this.map = instance}
						pathCoordinates={this.props.data.activity.pathCoordinates}
					/>
				}

				{this.props.data && this.props.data.similarItems && this.props.data.similarItems.length > 0 &&
					<div className="detailSimilarItems" data-tk="nearby">
					{<h2 className="detailSimilarItemsTitle">
						{Translate.T("de.Ähnliche Ausflugsziele in Deiner Nähe", "en.Similar activities near you", "detail.similar.title")}
					</h2>}
					{!AppState.instance.deviceInfo.desktop &&
					<ContentList key={"detailSimilarItemsList"} itemType={"content"}
						categoryIds={null}
						renderMode={"tiny"}
						contentData={this.props.data.similarItems}
						maxCount={this.props.data.similarItems.length}
						maxTotal={0}
						horizontal={true}
					/>}
					{AppState.instance.deviceInfo.desktop && 
					<ContentGrid
						content={this.props.data.similarItems}
						minContentWidth={200}
						maxContentWidth={400}
						idealNumContentPerRow={4}
						contentSpacing={16}
					/>}
				</div>}

				<div className="detailInfoContainer detailInfoContainerBottom" data-tk="footer">
							
					{this.props.data.activity.infoUrl && <div className="detailSection detailSectionWeblink">
						<h2 className="detailSectionTitle detailTitleWeblink">
							{Translate.T("de.Informations- und Bildquellen", "en.References", "detail.references.title")}
						</h2>

						{!isAustriaTourismData && this.weblinks.map(function(weblink)
						{
							if (!weblink.url || weblink.url.length <= 3)
								return null;
								
							return (
								<div className="detailSectionText detailWeblink"
									key={"weblink " + weblink.url}
									data-url={weblink.url} onClick={thisPtr.OnWeblinkClicked}
								>
									{weblink.label} <OpenInNewIcon />
								</div>
							);
						})}

						{isAustriaTourismData &&
							<div className="detailSectionText detailWeblink"
								data-url={"https://www.austria.info"} onClick={thisPtr.OnWeblinkClicked}
							>
								© Österreich Werbung <OpenInNewIcon />
							</div>}

						{isAustriaTourismData && atSourceName &&
							<div className="detailSectionText detailWeblink"
								data-url={atSourceUrl} onClick={thisPtr.OnWeblinkClicked}
							>
								{atSourceName} <OpenInNewIcon />
							</div>}
					</div>}

					<div className="attributions">
						{this.props.data.attributions && this.props.data.attributions.map((attr, index) =>
						{
							if (attr === "oew")
								return null;
							if (attr.startsWith("source=") || attr.startsWith("sourceurl=") || attr.startsWith("sourceimg="))
								return null;

							return (
								<div className="attribution" key={index}>
									{attr}
								</div>
							);
						})}

						{googleReviewFound && <div className="googleReviewAttr">
							{Translate.T("en.Reviews", "de.Bewertungen", "details.googlereviews.title")}	
							<img alt="google" src="/assets/powered_by_google_on_white.png" />
						</div>}
					</div>

					{this.props.data.activity &&
						<div className="detailDisclaimer">
							{Translate.T("en.Information on this page without warranty. Usage of this content outside of LENA prohibited.", "de.Informationen auf dieser Seite ohne Gewährleistung. Verwendung der Daten außerhalb von LENA untersagt.", "details.disclaimer")}
						</div>}

				</div>
			</article>
		);
	}

	IsInActiveTrip()
	{
		var trip = AppState.instance.userProfile.trip;
		var isInActiveTrip = false;
		if (this.props.data.activity.name && trip.activities && trip.active)
			for (var i = 0; i < trip.activities.length; ++i)
			{
				if (trip.activities[i].title === this.props.data.activity.name)
				{
					isInActiveTrip = true;
					break;
				}
			}

		return isInActiveTrip;
	}

	render()
	{
		if (this.weblinks.length === 0)
			this.UpdateWeblinks();

		if (!this.decodedWeather && this.props.data && this.props.data.weather)
		{
			this.decodedWeather = WeatherDisplay.DecodeWeather(this.props.data.weather);
		}

		//console.log("ActivityRenderer::render");
		return (
			<CloudinaryContext cloudName="lenafamily">
				{this.renderDetails()}
			</CloudinaryContext>
		);
	}
}

ActivityRenderer.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ActivityRenderer);