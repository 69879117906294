import React from 'react';
import ReactDOM from 'react-dom';
//import WebFont from 'webfontloader';
import './index.css';
import 'react-infinite-calendar/styles.css';
//import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-dd-menu/dist/react-dd-menu.min.css";
import 'react-select/dist/react-select.css';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
//import 'video.js/dist/video-js.min.css';
//import 'videojs-record/dist/css/videojs.record.min.css';
import 'leaflet/dist/leaflet.css';
import './css/leaflet-gesture-handling.min.css';
import './css/framework.css';
import './css/mainpage.css';
import './css/detailpage.css';
import './css/mappage.css';
import './css/suggesteditpage.css';
import './css/addreviewpage.css';
import './css/journalpage.css';
import './css/entrypage.css';
import './css/bookmarkpage.css';
import './css/profilepage.css';
import './css/accountrequestpage.css';
import './css/settingspage.css';
import './css/contentcard.css';
import './css/searchresult.css';
import './css/filtercontainer.css';
import './css/chat.css';
import './css/suggestioncard.css';
import './css/landingpage.css';
import './css/providerpage.css';
import './css/timeline.css';
import './css/adventure.css';
import 'react-photoswipe/lib/photoswipe.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import './css/desktop.css';
import './css/responsive.css';
/*import App from './App';
import LandingPage from './LandingPage';
import ProviderPage from './ProviderPage';*/
import AppState from './AppState';
import AppContainer from './AppContainer';
import registerServiceWorker from './registerServiceWorker';
import Utils from './Utils';
//import {unregister} from './registerServiceWorker';
import {BrowserRouter as Router} from 'react-router-dom';

var ReactGA = require('react-ga');
require('moment/locale/de.js');
require('moment/locale/en-ie.js');

var console=(function(oldCons){
    return {
        log: function(text){
			oldCons.log(text);
			Utils.LogMessage("log", text);
            // Your code
        },
        info: function (text) {
			oldCons.info(text);
			Utils.LogMessage("info", text);
            // Your code
        },
        warn: function (text) {
			oldCons.warn(text);
			Utils.LogMessage("warn", text);
            // Your code
        },
        error: function (text) {
			oldCons.error(text);
			Utils.LogMessage("error", text);
            // Your code
        }
    };
}(window.console));
window.console = console;

const appState = new AppState();

var gaProperty = 'UA-105568793-1';

// Disable tracking if the opt-out cookie exists.
var disableStr = 'ga-disable-' + gaProperty;
if (document.cookie.indexOf(disableStr + '=true') > -1)
{
	window[disableStr] = true;
}

ReactGA.initialize(gaProperty);
ReactGA.ga('set', 'anonymizeIp', true);
//ReactGA.ga('require', 'GTM-PNCX9WR');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
	<Router>
		<AppContainer appState={appState}/>
	</Router>,
	document.getElementById('root')
);

//Swap to unregister
registerServiceWorker();
//unregister();
