import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import AppState from '../../AppState';
import { Button } from 'material-ui';
import Translate from '../../Translate';

const styles = theme => ({
});

@observer
class AdvStoryFragment extends React.Component
{
	advManager;

	constructor(props)
	{
		super(props);

		this.advManager = AppState.instance.advManager;
	}

	componentDidMount()
	{
		this.advManager.onConfigChanged.Add(this.OnConfigChanged);
		this.advManager.onInstancesChanged.Add(this.OnInstancesChanged);
	}

	componentWillUnmount()
	{
		this.advManager.onConfigChanged.Remove(this.OnConfigChanged);
		this.advManager.onInstancesChanged.Remove(this.OnInstancesChanged);
	}

	OnConfigChanged = () =>
	{
		this.forceUpdate();
	}

	OnInstancesChanged = () =>
	{
		this.forceUpdate();
	}

	render()
	{
		const story = this.props.story;
		if (story === undefined)
			return null;

		return (
			<div
				className={"advstory"}
				onClick={this.props.onClick}
			>
				{story.pictureUrl &&
					<div
						className={"keyImage"}
						style={{backgroundImage: 'url("' + story.pictureUrl + '")'}}
					/>}

				{story.text && <div className="text">{story.text}</div>}

				{this.props.onContinue &&
					<Button onClick={this.props.onContinue} className="continue">
						{Translate.T("de.Weiter", "en.Continue", "adventure.storyfragment.continue")}
					</Button>}
			</div>
		);
	}
}

AdvStoryFragment.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AdvStoryFragment);