import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import $ from 'jquery';
import List, { ListItem, ListItemText } from 'material-ui/List';
import AppState from '../AppState';

const styles = {
	listItemText: {
		fontSize: '18px',
		fontWeight: '300'
	},
};

@observer
class ListMenu extends React.Component
{
	OnMenuItemClicked = (e) =>
	{
		var itemId = undefined;
		if (e.target.dataset.id === undefined)
		{
			var parents = $(e.target).parents(".listMenuItem");
			if (parents.length > 0)
			{
				itemId = parseInt(parents[0].dataset.id, 10);	
			}
		}
		else
		{
			itemId = parseInt(e.target.dataset.id, 10);
		}

		const entry = this.GetMenuEntryById(itemId);
		if (!entry)
			return;

		var comp = entry.component;
		if (comp === null || comp === undefined)
			return;

		AppState.instance.screenMessage.ShowDialog(entry.label, comp, undefined, undefined, undefined, undefined, undefined, entry.className);
	}

	GetMenuEntryById(id)
	{
		for (var i = 0; i < this.props.menuEntries.length; ++i)
		{
			const e = this.props.menuEntries[i];
			if (e.id === id)
				return e;
		}
		return undefined;
	}

	OnMenuItemClickedWrapper = (onClickFunc) => (e) =>
	{
		if (this.props.onItemClicked)
			this.props.onItemClicked(e);

		if (onClickFunc)
			onClickFunc(e);
	}

	RenderMenuItem = (menuEntry, index) =>
	{
		var onClick = this.OnMenuItemClicked;
		if (menuEntry.onClick !== undefined)
			onClick = menuEntry.onClick;

		var props = {button: true};
		if (menuEntry.onClick === undefined &&
			(menuEntry.component === undefined || menuEntry.component === null))
		{
			props = {};
		}
		else if (menuEntry.rightLabel !== undefined)
		{
			props = {button: true};
		}

		var textProps = {};
		if (menuEntry.rightLabel)
		{
			textProps = {secondary: menuEntry.rightLabel};
		}

		return (
			<ListItem
				divider
				disableGutters
				onClick={this.OnMenuItemClickedWrapper(onClick)}
				data-id={menuEntry.id}
		  		className={"listMenuItem" + ((index === 0) ? " listMenuItemFirst" : "") + ((index === this.props.menuEntries.length - 1) ? " listMenuItemLast" : "")}
				key={index}
				{...props}
			>
				{menuEntry.content === undefined && <ListItemText
					classes={{
						primary: this.props.classes.listItemText,
					}}
					primary={menuEntry.label} {...textProps}/>}
				{menuEntry.content}
			</ListItem>
		);
	}

	render()
	{
		return (
			<List className="listMenu">
				{this.props.menuEntries.map((menuEntry, index) =>
				{
					return this.RenderMenuItem(menuEntry, index);
				})}
			</List>
		);
	}
}

ListMenu.propTypes = {
	classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(ListMenu);