import React, {Component} from 'react';
import {withStyles} from 'material-ui/styles';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import AppState from '../AppState';
import ChatBot from '../components/ChatBot';
import CloseIcon from 'material-ui-icons/Close';
import Button from 'material-ui/Button';


const styles = theme => ({

});

export class ChatBotHeaderBar extends Component
{
	OnCancel = () =>
	{
		if (AppState.instance.activeChatBot)
			AppState.instance.activeChatBot.OnCancel();
	}

	render()
	{
		return (
			<div className={"chatBotHeaderBar" + (AppState.instance.chatBotActive ? " active": " inactive")}>
				<div className="cancelButton">
					<Button onClick={this.OnCancel}>
						<CloseIcon />
					</Button>
				</div>
				<div className="header">
					LENA
				</div>
			</div>
		);
	}
}

@observer
class ChatBotPage extends Component
{
	componentDidMount()
	{
	}

	OnActivate()
	{
	}

	OnDeactivate()
	{
	}

	OnChatBotActivated = () =>
	{
		//AppState.instance.chatBotActive = true;
		//AppState.instance.appContainer.forceUpdate();
	}

	OnChatBotDeactivated = () =>
	{		
		AppState.instance.chatBotActive = false;
		AppState.instance.activeChatBot = undefined;
		AppState.instance.appContainer.forceUpdate();

		if (this.props.onDeactivated)
			this.props.onDeactivated();

		setTimeout(() => {
			if (AppState.instance.timelinePageInstance)
				AppState.instance.timelinePageInstance.OnActivate();
		}, 500);
	}

	OnChatBotInit = (instance) =>
	{
		AppState.instance.activeChatBot = instance;
	}

	OnBackgroundClicked = () =>
	{
		if (AppState.instance.activeChatBot)
		{
			AppState.instance.activeChatBot.OnCancel();
		}
	}

	render()
	{
		var s = {};
		const alwaysVisible = this.props.alwaysVisible === true;
		if (!alwaysVisible && AppState.instance.currentMainTab !== AppState.TabIndexTimeline)
			s = {'display': 'none'};

		return (
			<div
				id="chatBotPage"
				className={"chatBotPage" + AppState.instance.GetPlatformClasses()}
				key="chatBotPage"
				style={s}
				onClick={AppState.instance.deviceInfo.desktop ? this.OnBackgroundClicked : undefined}
			>

				<ChatBot
					id="lena"
					goal={AppState.instance.chatBotInitialGoal}
					onActivated={this.OnChatBotActivated}
					onDeactivated={this.OnChatBotDeactivated}
					onInit={this.OnChatBotInit}
				/>
			</div>
		);
	}
}

ChatBotPage.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ChatBotPage);