import React from 'react';
import AppState from '../AppState';
import PersonIcon from 'material-ui-icons/Person';
import Utils from '../Utils';
import FullscreenMedia from './FullscreenMedia';

class ProfilePicture extends React.Component
{
	id;
	_isMounted = false;

	constructor(props)
	{
		super(props);

		this.id = Utils.GenerateRandomId(32);
	}

	componentDidMount()
	{
		this._isMounted = true;
		AppState.instance.socialManager.onProfilesChanged.Add(this.OnProfilesChanged);
	}

	componentWillUnmount()
	{
		AppState.instance.socialManager.onProfilesChanged.Remove(this.OnProfilesChanged);
		AppState.instance.socialManager.Unsubscribe(this.props.username, this.OnProfileLoaded);
		this._isMounted = false;
	}

	OnProfilesChanged = () =>
	{
		if (this._isMounted)
			this.forceUpdate();
	}
	
	OnProfileLoaded = () =>
	{
		if (this._isMounted)
			this.forceUpdate();
	}

	OnPhotoClicked = (e) =>
	{
		if (this.props.onClick)
			this.props.onClick(e);

		if (this.props.showFullscreenOnClick)
		{
			const profile = AppState.instance.socialManager.GetProfile(this.props.username, undefined, this.OnProfileLoaded);
			if (!profile)
				return;
			const url = profile.profilePicture;
			if (!url)
				return;

			var items = [];
			var imageData = AppState.instance.imageManager.Get(url);

			if (!imageData && url.indexOf(".fbsbx.com") >= 0)
			{
				imageData = {
					width: 200,
					height: 200,
					url: url
				};
			}

			if (imageData)
			{
				if (Utils.IsVideoUrl(url))
				{
					const posterUrl = Utils.GetVideoPoster(url);
					const id = Utils.GenerateRandomId(32);

					items.push({
						videosrc: url,
						videoId: id,
						posterUrl: posterUrl,
						w: imageData ? imageData.width : window.innerWidth,
						h: imageData ? imageData.height : Math.round(window.innerWidth * 9 / 16),
						videoW: imageData ? imageData.width : window.innerWidth,
						videoH: imageData ? imageData.height : Math.round(window.innerWidth * 9 / 16),
					});
				}
				else
				{
					var fixedUrl = Utils.FixHttpImages(url);
					fixedUrl = Utils.ScaleDownCloudinaryImages(fixedUrl);

					items.push({
						msrc: fixedUrl,
						src: Utils.FixHttpImages(imageData.url),
						w: imageData.width,
						h: imageData.height,
					});
				}
			}

			if (items.length === 0)
				return; // No valid image data found in ImageManager

			FullscreenMedia.Show(items, 0, "#" + this.id);

			e.stopPropagation();
		}
	}

	render()
	{
		const profile = AppState.instance.socialManager.GetProfile(this.props.username, undefined, this.OnProfileLoaded);

		if (profile && profile.profilePicture)
		{
			return (
				<div
					id={this.id}
					onClick={this.OnPhotoClicked}
					className={"profilePix " + this.props.className + (this.props.username === "lena" ? " lena":"")}
					style={{backgroundImage: 'url("' + profile.profilePicture + '")'}}
				/>
			);
		}
		else
		{
			return (
				<div
					id={this.id}
					onClick={this.OnPhotoClicked}
					className={"profilePix " + this.props.className + (this.props.username === "lena" ? " lena":"")}
				>
					<PersonIcon />
				</div>
			);
		}
	}
}

export default ProfilePicture;