import React from 'react';
import Translate from '../../Translate';
import SearchIcon from 'material-ui-icons/Search';
import SearchField from '../SearchField';
import AppState from '../../AppState';
import StepBase from './StepBase';
import SearchPage from '../../pages/SearchPage';
import { CircularProgress } from 'material-ui';
import ClearIcon from 'material-ui-icons/Clear';
import $ from 'jquery';

export default class StepSelectActivity extends StepBase
{
	GetId()
	{
		return "activity";
	}

	GeneratedDataIds()
	{
		return ["activity"];
	}

	Undo(contextData)
	{
		delete contextData["activity"];
	}

	// OnSearchResultClicked(contextData, contentData)
	// {
	// 	this.OnStepDone(contextData, {
	// 		activity: contentData
	// 	});
	// }

	renderStepLena(contextData)
	{
		if (contextData.goal === "question")
			return this.renderLenaMsg(Translate.T("de.Zu welchem Ausflugsziel hast Du eine Frage?", "en.Which activity is your question about?", "lena.question.whichactivity"));

		if (contextData.goal === "media")
			return this.renderLenaMsg(Translate.T("de.Zu welchem Ausflugsziel?", "en.Which activity?", "lena.media.whichactivity"));

		return this.renderLenaMsg(Translate.T("de.Wo warst Du denn, {0}?", "en.Where have you been, {0}?", "createxp.where",
			[AppState.instance.userProfile.loginData.firstName]));
	}

	renderUserSelection(contextData)
	{
		return this.renderUserMsg(contextData["activity"].title, contextData);
	}

	OnSearchResultClicked = (contextData) => (contentData) =>
	{
		this.OnStepDone(contextData, {
			activity: contentData
		});

		// const targetStep = this;
		// if (targetStep && targetStep.OnSearchResultClicked)
		// 	targetStep.OnSearchResultClicked(this.contextData, contentData);

		// setTimeout(() => {
		// 	this.contextData.searchHasFocus = false;
		// 	this.forceUpdate();
		// }, 100);
	}

	OnClearInput = (contextData) => () =>
	{
		$(".userOptions #searchInput").val("");
		contextData.onSearch("");
		
		setTimeout(() => {
			$(".userOptions #searchInput").focus();
		}, 500);
	}

	renderUserOptions(contextData)
	{
		const isEdit = this.IsCurrentlyEdited(contextData);

		const isLoading = contextData.isSearching;
		const showClearIcon = AppState.instance.lastEnteredSearchString && AppState.instance.lastEnteredSearchString.length > 0 && isLoading === 0;

		return (<div className="activitySearchContainer">
			{isEdit && this.renderCancelEditButton(contextData)}

			{!showClearIcon && isLoading === 0 && <SearchIcon className="activitySearchIcon" />}
			{showClearIcon && <ClearIcon className="activitySearchIcon" onClick={this.OnClearInput(contextData)} />}
			{isLoading !== 0 && <CircularProgress size={24} className="activitySearchIcon" />}

			<div className="activitySearch">
				<SearchField
					breadcrumbs={Translate.FindTranslation("search.activities")}
					onSearch={contextData.onSearch}
					onFocus={contextData.onSearchFocus}
					onBlur={contextData.onSearchBlur}
				/>
			</div>

			<div
				id={contextData.searchPageContainerId}
				className={"activitySearchPageContainer" + (contextData.searchHasFocus ? " visible": " invisible")}
			>
				<SearchPage
					visible={contextData.searchHasFocus}
					searchTerm={AppState.instance.lastEnteredSearchString}
					hideDefaults={true}
					contentOnly={true}
					onContentClick={this.OnSearchResultClicked(contextData)}
				/>
			</div>
		</div>);
	}
}
