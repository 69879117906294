import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import ContentSource from './ContentSource';
import ContentSocial from './ContentSocial';
import TimelineItemContent from './TimelineItemContent';
import AppState from '../AppState';
import Translate from '../Translate';
import RestApi from '../RestApi';
import UserReview from './UserReview';
import TimelineProfilesItem from './TimelineProfilesItem';

const styles = {
};

@observer
class TimelineItem extends React.Component
{
	OnDeleteContent = () =>
	{
		AppState.instance.screenMessage.ShowAlert(Translate.T("en.Confirmation", "de.Bestätigung", "confirmation.title"),
				Translate.T("en.Do you really want delete this content?",
				"de.Willst Du diesen Beitrag wirklich löschen?", "confirmation.usercontent.delete"),
				[{label: Translate.FindTranslation("answer.no"), value: 0}, {label: Translate.FindTranslation("answer.yes"), value: 1}], true, (resultButton) =>
		{
			if (resultButton && resultButton.value)
			{
				RestApi.SendRequestText("/deletetimelineitem", {id: this.props.content.id}).then((voidResponse) =>
				{
					//TODO: snack message
					if (this.props.onDeleted)
						this.props.onDeleted();
				});
			}
		});
	}

	OnChangeShare = (newFlag) =>
	{
		var q = {
			id: this.props.content.id,
			share: newFlag
		};
		RestApi.SendRequestText("/timelineshare", q);
		this.props.content.share = newFlag;
	}

	render()
	{
		if (!this.props.content)
			return null;

		const content = this.props.content;

		if (content.type === "googlereview")
		{
			return (
				<div className="timelineItem" key={this.props.key}>
					<UserReview
						review={content.review}
						collapsedText={true}
						onExpanded={() => {
							if (this.props.onChanged)
								this.props.onChanged(true);
							if (this.props.onLayoutChanged)
								this.props.onLayoutChanged();
						}}
					/>
				</div>
			);
		}

		if (content.type.startsWith("profiles-"))
		{
			return (
				<TimelineProfilesItem
					key={this.props.key}
					content={content}
				/>
			);
		}

		return (
			<div
				className={"timelineItem" + (this.props.isSelected ? " selected ":" ") + content.type +
					(content.url === "/abenteuer" ? " abenteuer":"")}
				key={this.props.key}
				id={this.props.id}
			>

				<ContentSource
					username={content.username}
					time={content.time}
					contentType={content.type}
					onDeleteContent={this.OnDeleteContent}
					share={content.share}
					onChangeShare={this.OnChangeShare}
					context={this.props.context}
					onLayoutChanged={this.props.onLayoutChanged}
				/>

				<TimelineItemContent
					content={content}
					context={this.props.context}
					onLayoutChanged={this.props.onLayoutChanged}
					onMediaSwitching={this.props.onMediaSwitching}
					onMediaTouchEnd={this.props.onMediaTouchEnd}
					onMediaTouchStart={this.props.onMediaTouchStart}
					onBeforeMediaZoom={this.props.onBeforeMediaZoom}
					onAfterMediaZoom={this.props.onAfterMediaZoom}
				/>

				<ContentSocial
					content={content}
					context={this.props.context}
					defaultNumComments={this.props.context === "activity" ? 0 : undefined}
					onChangeShare={this.OnChangeShare}
					onLayoutChanged={this.props.onLayoutChanged}
				/>
			</div>
		);
	}
}

TimelineItem.propTypes = {
	classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(TimelineItem);