import React from 'react';
import Utils from '../../Utils';
import AppState from '../../AppState';
import ProfilePicture from '../ProfilePicture';
import Button from 'material-ui/Button';
import MediaPlayer from '../MediaPlayer';
import EditIcon from 'material-ui-icons/Edit';
import ClearIcon from 'material-ui-icons/Clear';
import Translate from '../../Translate';
import DeleteIcon from 'material-ui-icons/Delete';
import AddAPhoto from 'material-ui-icons/AddAPhoto';
import ProgressDisplay from '../ProgressDisplay';

// Steps are not allowed to have state! Keep the state in the context
export default class StepBase
{
	answerOptions = [];

	GetId() { return null; }

	GeneratedDataIds() { return []; }

	IsEditEnabled(contextData) { return true; }

	OnGoalChanged(goalId, contextData) {}

	OnLenaLogoClicked = () =>
	{
		AppState.instance.appContainer.OnProfileClicked("lena");
	}

	renderLenaMsg(label)
	{
		return (
			<div className="lenaMsg">
				<div className="logo" onClick={this.OnLenaLogoClicked}>
					{Utils.RenderLogo(32, 32, "white", "#e31c46")}
				</div>
				<div className="thinking">
					<span className="jumpingdot"></span>
					<span className="jumpingdot"></span>
					<span className="jumpingdot"></span>
				</div>
				<div className="label">{label}</div>
			</div>
		);
	}

	renderUserMsg(label, contextData)
	{
		var isEditEnabled = this.IsEditEnabled(contextData);
		if (contextData["edit"] === true)
			isEditEnabled = false;

		if (contextData.bot.IsClosingMsgActive())
			isEditEnabled = false;

		return (
			<div className="userMsg">
				<div className="label" onClick={(this.IsStepDone(contextData) && isEditEnabled) ? this.OnStartEdit(contextData) : undefined}>
					{label}
					{isEditEnabled && <span className="editBtn"><EditIcon /></span>}
				</div>

				<ProfilePicture className="csMyChatPhoto"
					username={AppState.instance.IsLoggedIn() ? AppState.instance.userProfile.username : undefined}
				/>
			</div>
		);
	}

	AddAnswerOption(option)
	{
		this.answerOptions.push(option);
	}

	GetAnswerOptionLabel(optionId)
	{
		for (var i = 0; i < this.answerOptions.length; ++i)
		{
			const option = this.answerOptions[i];
			if (option.id === optionId)
				return option.label;
		}
	}

	InternalOnOptionClicked = (contextData, option) => (e) =>
	{
		if (option.onClick)
			option.onClick(e, contextData);
	}

	IsCurrentlyEdited(contextData)
	{
		return contextData[this.GetId() + "_edit"] === true;
	}

	renderCancelEditButton(contextData)
	{
		return (
			<div className={"answerOption cancelEdit"} key={"cancelEdit"}>
				<Button onClick={this.CancelEdit(contextData)}>
					<ClearIcon />
				</Button>
			</div>
		);
	}

	renderAnswerOptions(contextData, options)
	{
		const isEdit = this.IsCurrentlyEdited(contextData);

		return (
			<div className={"answerOptions numAnswers" + options.length}>
				{isEdit && this.renderCancelEditButton(contextData)}

				{options.map((option, index) =>
				{
					return (
						<div className={"answerOption " + option.id} key={index}>
							<Button onClick={this.InternalOnOptionClicked(contextData, option)}>
								{option.icon}
								{option.label}
							</Button>
						</div>
					);
				})}
			</div>
		);
	}

	OnRemoveFile(contextData, url) {}
	OnAddFile(contextData) {}

	AddPhoto = (contextData) => () =>
	{
		this.OnAddFile(contextData);
	}

	RemovePicture = (contextData, files) => () =>
	{
		const mp = contextData.mediaPlayer;

		if (!mp)
			return;

		setTimeout(() =>
		{
			const url = files[mp.photoIndex];
			const isVideo = Utils.IsVideoUrl(url);

			var text;
			if (isVideo)
			{
				text = Translate.T("en.Do you really want to remove this video?",
					"de.Willst Du dieses Video wirklich entfernen?", "confirmation.editentry.removevideo");
			}
			else
			{
				text = Translate.T("en.Do you really want to remove this photo?",
					"de.Willst Du dieses Photo wirklich entfernen?", "confirmation.editentry.removephoto");
			}

			AppState.instance.screenMessage.ShowAlert(Translate.T("en.Confirmation", "de.Bestätigung", "confirmation.title"),
				text,
			[{label: Translate.FindTranslation("answer.no"), value: 0}, {label: Translate.FindTranslation("answer.yes"), value: 1}], true, (resultButton) =>
			{
				if (resultButton && resultButton.value)
				{
					if (mp.photoIndex === files.length - 1)
						--mp.photoIndex;

					this.OnRemoveFile(contextData, url);
				}
			});
		}, 150);
	}

	renderFilesProgress(contextData)
	{
		if (!contextData.progressItems || contextData.progressItems.length === 0)
			return null;

		return (
			<div className="progressItems">
				{contextData.progressItems.map((progressItem, index) =>
				{
					return (<ProgressDisplay data={progressItem} key={index}
						updateToken={contextData.progressItemsCounter}
						onDone={progressItem.onDone} isError={progressItem.error} />);
				})}
			</div>
		);
	}

	renderFiles(contextData, files)
	{
		var mediaData = {};
		mediaData.activity = {};
		mediaData.activity.pictureUrls = files;

		return (
			<div className="files">
				<MediaPlayer
					data={mediaData} className={"contentImage contentImageColumn"}
					interactive={true}
					disableZoom={true}
					disableAuthorLink={true}
					defaultContentHeight={200}
					onInstance={(instance) => { contextData.mediaPlayer = instance;} }
				/>

				{files && files.length > 0 &&
					<Button className="editRemovePictureBtn" onClick={this.RemovePicture(contextData, files)}>
						<DeleteIcon />
					</Button>}

				{files && files.length > 0 &&
					<Button className="editAddPictureBtn" onClick={this.AddPhoto(contextData)}>
						<AddAPhoto />
					</Button>}

				{this.renderFilesProgress(contextData)}
			</div>
		);
	}

	OnStepDone(contextData, newData)
	{
		const id = this.GetId();
		const isEdit = contextData[id + "_edit"] === true;

		if (isEdit)
		{
			delete contextData[id + "_edit"];
			delete contextData["edit"];
			if (contextData.onStepEdit)
				contextData.onStepEdit(newData);
		}
		else
		{
			this.SetStepDone(contextData, true);
			if (contextData.onStepDone)
				contextData.onStepDone(newData);
		}
	}

	CancelEdit = (contextData) => () =>
	{
		delete contextData[this.GetId() + "_edit"];
		delete contextData["edit"];
		contextData.onChanged();
	}

	renderStepLena(contextData) { return null; }
	renderUserSelection(contextData) { return null; }
	renderUserOptions(contextData) { return null; }
	Undo(contextData) {}

	IsStepDone(contextData)
	{
		return contextData[this.GetId() + "_done"] === true;
	}

	SetStepDone(contextData, isDone)
	{
		const id = this.GetId();
		contextData[id + "_done"] = isDone;
		contextData[id + "_done_now"] = true;

		setTimeout(() => {
			contextData[id + "_done_now"] = false;
			contextData.onChanged();
		}, 3000);
	}

	OnStartEditInternal(contextData) {}

	OnStartEdit = (contextData) => (e) =>
	{
		this.StartEdit(contextData);
	}

	StartEdit(contextData)
	{
		this.OnStartEditInternal(contextData);

		const id = this.GetId();
		contextData[id + "_edit"] = true;
		contextData["edit"] = true;
		contextData.onChanged();
	}

	render(contextData)
	{
		const isDone = this.IsStepDone(contextData);

		const id = this.GetId();

		var isDoneNow = false;
		if (isDone)
			isDoneNow = contextData[id + "_done_now"] === true;

		const isNew = contextData[id + "_new"] === true;
		const isEdit = contextData[id + "_edit"] === true;
		const isEditOther = !isEdit && (contextData["edit"] === true);

		return (
			<div
				className={"step" + (isDoneNow ? " now":"") + (isNew ? " new":"")}
				key={this.GetId()}
			>
				{this.renderStepLena(contextData)}

				{isDone && !isEdit && <div className="userSelection">
					{this.renderUserSelection(contextData)}
				</div>}

				{!isEditOther && (!isDone || isEdit) && <div className="userOptions">
					{this.renderUserOptions(contextData)}
				</div>}
			</div>
		);
	}
}
