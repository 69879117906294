import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import Translate from '../../Translate';
//import Button from 'material-ui/Button';
import { observable } from '../../../node_modules/mobx';
import Map from '../Map';
import ExpandMoreIcon from 'material-ui-icons/ExpandMore';
import SocialLists from './SocialLists';
import AppState from '../../AppState';
import PeopleSearch from '../PeopleSearch';
import Utils from '../../Utils';
import ExpansionPanel, {ExpansionPanelDetails, ExpansionPanelSummary}  from 'material-ui/ExpansionPanel';
import ProfilePicture from '../ProfilePicture';
import ProfileComponent from '../ProfileComponent';
import KeyboardArrowLeft from 'material-ui-icons/KeyboardArrowLeft';
import $ from 'jquery';
import FriendRequests from '../FriendRequests';

const styles = theme => ({
});

@observer
class MyCommunity extends React.Component
{
	@observable socialListIndex;
	map;
	@observable expanded = "map";

	componentDidMount()
	{
		if (AppState.instance.IsLoggedIn() && AppState.instance.loginDataChecked)
		{
			this.CheckAutoSwitchToCommunity();
		}
	}

	componentDidUpdate(prevProps)
	{
		if (this.props.selectedItem)
		{
			this.SelectMapItem(this.props.selectedItem.id);
		}

		if (this.props.selectedUsername && this.props.selectedUsername !== prevProps.selectedUsername)
		{
			const myUsername = (AppState.instance.IsLoggedIn() && AppState.instance.loginDataChecked) ? AppState.instance.userProfile.username : undefined;
			if (this.props.selectedUsername === myUsername)
			{
				this.expanded = "myprofile";
				if (this.props.onPanelOpened)
					this.props.onPanelOpened(this.expanded, this.props.selectedUsername, true);
			}
			else
			{
				this.expanded = "username_" + this.props.selectedUsername;
				if (this.props.onPanelOpened)
					this.props.onPanelOpened(this.expanded, this.props.selectedUsername, true);
			}
		}
		else if (!this.props.selectedUsername && this.props.selectedUsername !== prevProps.selectedUsername)
		{
			if (this.expanded === "username_" + prevProps.selectedUsername)
			{
				this.expanded = false;
			}
		}
	}

	CheckAutoSwitchToCommunity()
	{
		const myUsername = AppState.instance.userProfile.username;
		if (this.props.selectedUsername === myUsername || !this.props.selectedUsername)
		{
			// Show "My Community" panel if user does not have any friends
			const profile = AppState.instance.socialManager.GetProfile(myUsername, true, this.OnProfileLoaded);
			if (profile)
			{
				var numRelations = 0;
				if (profile.family)
					numRelations += profile.family.length;
				if (profile.friends)
					numRelations += profile.friends.length;

				var frs = AppState.instance.socialManager.GetPendingRequests();
				const pendingRequest = (frs && frs.length > 0);

				if (numRelations < 2 || pendingRequest)
				{
					this.expanded = "community";
				}
			}
		}
	}

	HandleExpansionChange = (panel, username) => (event, expanded) =>
	{
		this.expanded = expanded ? panel : false;

		if (this.props.onPanelOpened)
			this.props.onPanelOpened(panel, username, expanded);
	};

	SelectMapItem(itemId)
	{
		if (this.map)
		{
			this.map.SelectMarker(itemId);
		}
	}

	OnSocialListSelected = (index) =>
	{
		this.socialListIndex = index;
	}

	OnEmptySocialListCta = () =>
	{
		setTimeout(() => {
			$(".myCommunity .peopleSearch #searchInput").focus();
			$(".myCommunity .peopleSearch #searchInput").css('backgroundColor', '#e31c46');
			setTimeout(() => {
				$(".myCommunity .peopleSearch #searchInput").css('backgroundColor', 'white');	
			}, 300);
		}, 60);
	}

	CreateMapQuery = () =>
	{
		var loc = AppState.instance.ipLocation;
		if (AppState.instance.GetGpsPosition())
			loc = AppState.instance.GetGpsPosition();
		if (AppState.instance.manualLocation)
			loc = AppState.instance.manualLocation;

		var query = {};
		
		query.location = loc;
		query.referenceLocation = loc;

		query.language = AppState.instance.userProfile.userLang;

		query.q = undefined;
		
		return query;
	}

	GetMarkerIcon = (item, selected) =>
	{
		var result = {};

		const iconSize = 40;

		if (item.profilePicture)
		{
			result.url = item.profilePicture;
		}
		else
		{
			if (selected)
				result.url = Utils.RenderStarUrl(iconSize, iconSize, "white", "#e31c46");
			else
				result.url = Utils.RenderStarUrl(iconSize, iconSize, "#e31c46", "white");
		}

		result.size = [iconSize, iconSize];
		result.origin = [0, 0];
		result.anchor = [-iconSize / 2, -iconSize / 2];

		return result;
	}

	OnMarkerClicked = (marker) =>
	{
		const timelineItem = marker.activity;
		if (this.props.onItemClicked)
			this.props.onItemClicked(timelineItem, "timelineItem");
	}

	OnProfileLoaded = () =>
	{
		if (AppState.instance.IsLoggedIn() && AppState.instance.loginDataChecked)
		{
			this.CheckAutoSwitchToCommunity();
		}

		this.forceUpdate();
	}

	OnShowUserPosts = (username) => () =>
	{
		if (this.props.onShowUserPosts)
			this.props.onShowUserPosts(username);

		if (!username)
		{
			this.expanded = false;
		}
	}

	render()
	{
		const expanded = this.expanded;
		const myUsername = (AppState.instance.IsLoggedIn() && AppState.instance.loginDataChecked) ? AppState.instance.userProfile.username : undefined;

		const selectedProfile = this.props.selectedUsername ?
			AppState.instance.socialManager.GetProfile(this.props.selectedUsername, undefined, this.OnProfileLoaded) :
			undefined;

		const showOtherProfile = this.props.selectedUsername && this.props.selectedUsername !== myUsername && selectedProfile;

		return (
			<div className="myCommunity">

				{!showOtherProfile &&
				<ExpansionPanel expanded={expanded === 'community'} onChange={this.HandleExpansionChange('community')}>
					<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
						{Translate.T("de.Meine Community", "en.My Community", "mycommunity.title")}
						
						{AppState.instance.showNewIndicator[AppState.TabIndexProfile] &&
						<div className={"newIndicator" + (AppState.instance.showNewIndicator[AppState.TabIndexProfile] > 0 ? " newIndicatorNumber":"")}>
							{AppState.instance.showNewIndicator[AppState.TabIndexProfile] > 0 && AppState.instance.showNewIndicator[AppState.TabIndexProfile]}
						</div>}
					</ExpansionPanelSummary>
					<ExpansionPanelDetails className="myCommunityDetails">

						<FriendRequests />

						<div>
							<PeopleSearch asFamily={this.socialListIndex === 0} />
						</div>
						{myUsername && <SocialLists
							username={myUsername}
							onListSelected={this.OnSocialListSelected}
							onEmptyCta={this.OnEmptySocialListCta}
						/>}
					</ExpansionPanelDetails>
				</ExpansionPanel>}

				{!showOtherProfile &&
				<ExpansionPanel expanded={expanded === 'map'} onChange={this.HandleExpansionChange('map')}>
					<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
						{Translate.T("de.Community Karte", "en.Community Map", "mycommunity.map")}
					</ExpansionPanelSummary>
					<ExpansionPanelDetails className="myCommunityMapDetails">
						<Map
							id="communityPage"
							gestureHandling="greedy"
							ignoreActiveFilter={true}
							mapSimpleMode={false}
							disableFilterButton={true}
							disableContent={true}
							disableContentStoreCache={true}
							createQuery={this.CreateMapQuery}
							apiEndpoint={"/communitymap"}
							markerIcon={this.GetMarkerIcon}
							onMarkerClicked={this.OnMarkerClicked}
							onInit={(instance) => this.map = instance}
						/>
					</ExpansionPanelDetails>
				</ExpansionPanel>}

				{!showOtherProfile && myUsername &&
				<ExpansionPanel expanded={expanded === 'myprofile'} onChange={this.HandleExpansionChange('myprofile', myUsername)}>
					<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
						{Translate.T("de.Mein Profil", "en.My Profile", "mycommunity.myprofile")}
					</ExpansionPanelSummary>
					<ExpansionPanelDetails className="myCommunityMyProfileDetails">
						<ProfileComponent
							username={myUsername}
							disableTimeline={true}
							showSocialLists={false}
							disableNumbers={true}
						/>
					</ExpansionPanelDetails>
				</ExpansionPanel>}

				{showOtherProfile && AppState.instance.deviceInfo.desktop &&
				<div className="timelinePageHeaderUser" onClick={this.OnShowUserPosts(undefined)}>
					<KeyboardArrowLeft />
					{Translate.T("de.Zurück zur Community", "en.Back to Community", "timelinepage.backtocommunity")}
				</div>}

				{showOtherProfile &&
				<ExpansionPanel expanded={expanded === 'username_' + this.props.selectedUsername}
					onChange={this.HandleExpansionChange('username_' + this.props.selectedUsername, selectedProfile.username)}
					key={selectedProfile.username}
				>
					<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
						<ProfilePicture
							className="myCommunityUserHeader"
							username={selectedProfile.username}
							showFullscreenOnClick={true}
						/>
						<span className="myCommunityUserHeaderName">{selectedProfile.displayName}</span>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails className="myCommunityUserDetails">
						<ProfileComponent
							username={selectedProfile.username}
							disableTimeline={true}
							showSocialLists={true}
							showPhoto={false}
							showName={false}
						/>
					</ExpansionPanelDetails>
				</ExpansionPanel>}

			</div>
		);
	}
}

MyCommunity.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(MyCommunity);