import React, {Component} from 'react';
import {withStyles} from 'material-ui/styles';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import ApiInterface from '../ApiInterface';
import {observable} from 'mobx';
import AppState from '../AppState';
import Button from 'material-ui/Button';
import Utils from '../Utils';
import Translate from '../Translate';
import FilterBar from '../components/FilterBar';
import ContentCardStack from '../components/ContentCardStack';
import SwipeMessages from '../SwipeMessages';
import LocationSearch from '../components/LocationSearch';
import $ from 'jquery';
import ColorLens from 'material-ui-icons/ColorLens';
import AccessibleIcon from 'material-ui-icons/Accessible';
import ChildFriendly from 'material-ui-icons/ChildFriendly';
import FitnessCenter from 'material-ui-icons/FitnessCenter';
import NaturePeople from 'material-ui-icons/NaturePeople';
import EventIcon from 'material-ui-icons/Event';
import RegisterCard from '../components/RegisterCard';
import HomeLinkCard from '../components/HomeLinkCard';
import Tracking from '../Tracking';
import Tutorial from '../components/Tutorial';
import Direction from '../components/swing/Direction';
import TimelineItemContent from '../components/TimelineItemContent';

const styles = theme => ({

});

@observer
class SwipePage extends Component
{
	content = [];

	stack;
	messages = new SwipeMessages();

	@observable ageButtonStates = [];
	@observable isInLocationSearch = false;
	@observable locationSearchTerm;

	registerCardShown = false;
	homeLinkCardShown = false;
	skippedTutorialCardsProcessed = {};

	constructor(props)
	{
		super(props);

		AppState.instance.swipePageInstance = this;
		//AppState.instance.AddOnAuthDone(this.OnAuthDone);

		//this.CheckInit();
	}

	componentDidMount()
	{
		this.messages.OnMessagesChanged(this.OnMessagesChanged);
		this.OnMessagesChanged();

		AppState.instance.AddOnAuthDone(this.OnAuthDone);
		//this.CheckInit();
	}

	componentWillUnmount()
	{
		this.messages.OnMessagesChanged(undefined);
	}

	CheckInit()
	{
		if (!AppState.instance.isMobileLandingPage)
		{
			AppState.instance.UpdateLenaLocation();
			AppState.instance.UpdateLenaTripDate();
		}
	}

	ClearAllCards()
	{
		this.messages.ClearAllMessages();
	}

	AddActivityCard(activityId, atFront, sticky, onRemoved)
	{
		return this.messages.AddActivityMessage(activityId, atFront, sticky, onRemoved);
	}

	AddActivityCardDetails(details, atFront, sticky, onRemoved)
	{
		return this.messages.AddActivityMessageDetails(details, atFront, sticky, onRemoved);
	}

	AddCard(componentClass, props, onThrow, atFront, sticky, onRemoved)
	{
		this.messages.AddMessage(componentClass, props, onThrow, atFront, sticky, onRemoved);
	}

	Pose()
	{
		if (this.stack)
		{
			this.stack.Pose();
		}
	}

	OnAuthDone = () =>
	{
		this.CheckInit();
		this.messages.OnAuthDone();
	}

	OnActivate()
	{
		if (this.stack)
			this.stack.OnActivate();

		if (Tutorial.IsActive())
		{
			Tutorial.OnSwipePageActivate();
		}
	}

	OnDeactivate()
	{
		if (this.stack)
			this.stack.OnDeactivate();
		this.ResetLocationSearch();
	}

	OnStackInit = (stack) =>
	{
		this.stack = stack;
	}

	OnCardMediaTouchStart = (contentId) => () =>
	{
		if (this.stack)
			this.stack.DoCardMediaTouchStart(contentId);
	}

	OnCardMediaSwitching = (contentId) => () =>
	{
		if (this.stack)
			this.stack.DoCardMediaSwitching(contentId);
	}

	OnCardMediaTouchEnd = (contentId) => () =>
	{
		if (this.stack)
			this.stack.DoCardMediaTouchEnd(contentId);
	}

	OnAgeButtonClicked = ageGroupIdx => () =>
	{
		this.ageButtonStates[ageGroupIdx] = !this.ageButtonStates[ageGroupIdx];

		//We need to update the card manually as the input-props diff algo does not work in this case down to CardStack
		if (this.stack)
			this.stack.UpdateTopCardElement();
	}

	RenderAgeSelectorButton(ageGroupIdx, label)
	{
		const checked = this.ageButtonStates[ageGroupIdx];

		return (
			<Button
				onClick={this.OnAgeButtonClicked(ageGroupIdx)}
				className={"ageCheckbox " + (checked ? "checked" : "unchecked")}
			>
				{label}
			</Button>
		);
	}

	OnAgeSelectorResponseClicked = (question, msg) => () =>
	{
		if (msg.resolved !== undefined)
			return;

		var answerStr = "";
		for (var i = 0; i < 5; ++i)
			if (this.ageButtonStates[i])
				answerStr += "." + i;

		var responseCode = "activity.fb.answer." + question.id + answerStr;
		msg.resolved = true;

		ApiInterface.SendCommand("action", responseCode);

		//not necessary to remove it manually -> SwipeMessages will trigger change event
		//this.content.splice(0, 1);
		this.messages.RemoveMessage(msg.id);

		AppState.instance.userProfile.SetAgeGroups(this.ageButtonStates);
	}

	RenderAgeSelector(msg, question)
	{
		var anythingSet = false;

		if (this.ageButtonStates.length === 0)
		{
			for (var i = 0; i < 5; ++i)
				this.ageButtonStates.push(false);
		}
		else
		{
			for (i = 0; i < this.ageButtonStates.length; ++i)
			{
				if (this.ageButtonStates[i])
				{
					anythingSet = true;
					break;
				}
			}
		}

		return (
			<div className="uiControlAgeSelector" key={msg.id} id={msg.id}>
				<div className="uiControlAgeSelectorCBs">
					{this.RenderAgeSelectorButton(0, "0 - 1")}
					{this.RenderAgeSelectorButton(1, "2 - 3")}
					{this.RenderAgeSelectorButton(2, "4 - 5")}
					{this.RenderAgeSelectorButton(3, "6 - 9")}
					{this.RenderAgeSelectorButton(4, "10+")}
				</div>

				<div className="uiControlButton" key={"button.next"}>
					{anythingSet && <Button className={this.props.classes.responsebutton} onClick={this.OnAgeSelectorResponseClicked(question, msg)}>
						{Translate.T("en.Submit", "de.Speichern", "question.button.next")}
					</Button>}
				</div>
			</div>
		);
	}

	OnAnswerClicked = (msg, question, answerIndex) => () =>
	{
		setTimeout(() => {
			this.OnAnswerClickedInternal(msg, question, answerIndex);	
		}, 50);
	}

	OnAnswerClickedInternal(msg, question, answerIndex)
	{
		if (msg.resolved !== undefined)
			return;

		msg.resolved = answerIndex;

		//this.content.splice(0, 1);
		this.messages.RemoveMessage(msg.id);

		if (answerIndex !== -1)
			this.messages.ClearAllMessages();

		var responseCode = "activity.fb.answer." + question.id + "." + answerIndex;
		ApiInterface.SendCommand("action", responseCode);
	}

	OnAdThrowoutInternal(msg, ad, isPositive)
	{
		if (msg.resolved !== undefined)
			return;

		this.messages.RemoveMessage(msg.id);

		var responseCode = "activity.fb.ad." + ad.id + "." + (isPositive ? "1":"0");
		ApiInterface.SendCommand("action", responseCode);
	}

	RenderQuestion = (msg) => () =>
	{
		var question = msg.data;
		var ageselector = question.answers.length > 0 && question.answers[0].text === "ageselector";
		var icon = Translate.FindTranslation("question.icon." + question.text);

		return (
			<div className="uiQuestion" key={question.id}>

				<div className="uiTripHeader">
					<div className="uiTripHeaderHeadline">
						{Translate.T("en.Improve your suggestions", "de.Verbessere Deine Vorschläge", "card.tripplanning.headline")}
					</div>

					{icon === "agegroup" && Utils.RenderKidsIcon(64, 64, "transparent", "white", 1)}
					{icon === "dog" && Utils.RenderDogIcon(64, 64, "transparent", "white", 1)}
					{icon === "star" && Utils.RenderStarIcon(64, 64, "white", "transparent", 2, false)}
					{icon === "accessible" && <AccessibleIcon style={{width: 64, height: 64, color: 'white'}} />}
					{icon === "child_friendly" && <ChildFriendly style={{width: 64, height: 64, color: 'white'}} />}
					{icon === "fitness_center" && <FitnessCenter style={{width: 64, height: 64, color: 'white'}} />}
					{icon === "nature_people" && <NaturePeople style={{width: 64, height: 64, color: 'white'}} />}
					{icon === "event" && <EventIcon style={{width: 64, height: 64, color: 'white'}} />}
					{icon === "color_lens" && <ColorLens style={{width: 64, height: 64, color: 'white'}} />}

					<div className="uiTripHeaderTopic">
						{Translate.FindTranslation("question.topic." + question.text)}
					</div>
				</div>

				<div className="uiQuestionText">
					{Translate.FindTranslation(question.text)}
				</div>

				<div className="uiAnswers">
					{ageselector && this.RenderAgeSelector(msg, question)}

					{!ageselector && question.answers.map((answer, index) =>
					{
						return (
							<div className="uiAnswer" key={index}>
								<Button onClick={this.OnAnswerClicked(msg, question, index)}>
									{Translate.FindTranslation(answer.text)}
								</Button>
							</div>
						);
					})}
				</div>

				{/* <div className="uiTripOptionButtons">
					<Button onClick={this.OnAnswerClicked(msg, question, -1)}>
						{Translate.T("en.Skip", "de.Überspringen", "card.tripplanning.skip")}
					</Button>
				</div> */}
			</div>
		);
	}

	RenderAd = (msg) => () =>
	{
		var ad = msg.data;

		return (
			<div className="uiAd" key={ad.id}>

				<TimelineItemContent
					content={ad}
					context={"swipe"}
					onLayoutChanged={this.props.onLayoutChanged}
					onMediaSwitching={this.props.onMediaSwitching}
					onMediaTouchEnd={this.props.onMediaTouchEnd}
					onMediaTouchStart={this.props.onMediaTouchStart}
					onBeforeMediaZoom={this.props.onBeforeMediaZoom}
					onAfterMediaZoom={this.props.onAfterMediaZoom}
				/>
				
			</div>
		);
	}

	RenderCustomComponent = (msg) => () =>
	{
		if (!msg.data.component)
		{
			if (!msg.data.props)
				msg.data.props = {};

			const contentId = msg.id;
			
			msg.data.props.onMediaTouchStart = this.OnCardMediaTouchStart(contentId);
			msg.data.props.onMediaSwitching = this.OnCardMediaSwitching(contentId);
			msg.data.props.onMediaTouchEnd = this.OnCardMediaTouchEnd(contentId);
			
			msg.data.props.onLayoutChanged = this.OnCustomComponentLayoutChanged;
			msg.data.component = React.createElement(msg.data.componentClass, msg.data.props, null);
		}

		return msg.data.component;
	}

	OnCustomComponentLayoutChanged = () =>
	{
		if (this.stack)
			this.stack.OnLayoutChanged();
	}

	GetContentById(id)
	{
		for (var i = 0; i < this.content.length; ++i)
		{
			if (this.content[i].id === id)
				return this.content[i];
		}
		return undefined;
	}

	OnMessagesChanged = () =>
	{
		//console.log("messages changed");

		var newContent = [];

		var messages = this.messages.messages;
		for (var i = 0; i < messages.length; ++i)
		{
			//console.log(messages[i]);
			if (messages[i].type === "activity")
			{
				newContent.push(
					{
						id: messages[i].data.activity.id,
						type: messages[i].type,
						messageId: messages[i].id
					}
				);
			}
			else if (messages[i].type === "question")
			{
				newContent.push(
					{
						id: messages[i].data.id,
						type: messages[i].type,
						messageId: messages[i].id,
						onRender: this.RenderQuestion(messages[i])
					}
				);
			}
			else if (messages[i].type === "ad")
			{
				newContent.push(
					{
						id: messages[i].data.id,
						type: messages[i].type,
						messageId: messages[i].id,
						onRender: this.RenderAd(messages[i]),
						showScrollGradient: false
					}
				);
			}
			else if (messages[i].type === "customComponent")
			{
				newContent.push(
					{
						id: messages[i].id,
						type: messages[i].type,
						messageId: messages[i].id,
						onRender: this.RenderCustomComponent(messages[i])
					}
				);
			}
		}

		var changed = false;
		if (newContent.length !== this.content.length)
		{
			changed = true;
		}
		else
		{
			for (i = 0; i < this.content.length; ++i)
			{
				if (this.content[i].id !== newContent[i].id)
				{
					changed = true;
					break;
				}
			}
		}

		// Is there a new front item?
		if (newContent.length > 0)
		{
			var contentId = newContent[0].id;
			// Already has been at front?
			if (this.content.length > 0)
				if (this.content[0].id === contentId)
					contentId = undefined;

			if (contentId !== undefined)
				Tracking.SendEvent("contentSuggestionOpened", { activityId: contentId });
		}

		this.content = newContent;

		//console.log("messages changed => " + this.content.length);

		if (changed)
		{
			//console.log("SwipePage.forceUpdate() from OnMessagesChanged");
			this.forceUpdate();
		}
	}

	CheckRegisterCard()
	{
		if (this.registerCardShown)
			return;
		if (AppState.instance.IsLoggedIn())
			return;

		if (AppState.instance.numSwipeRight >= 3)
		{
			this.registerCardShown = true;
			this.messages.AddMessage(RegisterCard, undefined, RegisterCard.OnThrow, false);	
		}
	}

	CheckAppInstallCard()
	{
		if (this.homeLinkCardShown)
			return;
		if (AppState.instance.deviceInfo.desktop)
			return;

		if (AppState.instance.CanPromptA2HS())
		{
			// Let's do this...
		}
		else
		{
			const ios = AppState.instance.deviceInfo.ios;
			const isSafari = AppState.instance.deviceInfo.safari;
			if (!(ios && isSafari))
				return;

			if (Utils.IsStandaloneMode())
				return;
		}

		if (AppState.instance.numSwipeRight >= 5 ||
			(AppState.instance.IsLoggedIn() && AppState.instance.numSwipeRight >= 2))
		{
			this.homeLinkCardShown = true;
			this.messages.AddMessage(HomeLinkCard, undefined, HomeLinkCard.OnThrow, false);
		}
	}

	OnThrowOutDecision = (content, direction) =>
	{
	}

	OnThrowOutEnd = (content, direction) =>
	{
		//console.log("Throw end: " + content.id + ", dir: " + direction);

		if (content.throwOutHandled)
			return;

		if (content.type === "activity")
		{
			var tutorialMsg = this.messages.GetMessage(content.messageId);
			if (!tutorialMsg.isTutorial)
				tutorialMsg = undefined;

			var responseCode = "activity.fb.negative.";
			if (direction > 0)
				responseCode = "activity.fb.positive.";

			ApiInterface.SendCommand("action", responseCode + content.id);

			if (!tutorialMsg)
			{
				if (direction > 0)
				{
					var listId = AppState.instance.swipeListId;
					if ((listId === undefined) || (listId === -1 && AppState.instance.IsLoggedIn()))
					{
						var lists = AppState.instance.listManager.GetLists();
						if (lists.length > 0)
						{
							AppState.instance.swipeListId = lists[0].id;
							listId = AppState.instance.swipeListId;
						}
					}
					if (listId !== undefined)
					{
						AppState.instance.listManager.AddToList(listId, content.id);
					}

					AppState.instance.numSwipeRight++;

					this.CheckRegisterCard();
					this.CheckAppInstallCard();
				}
				else
				{
					AppState.instance.numSwipeLeft++;
				}

				this.SendTrackingEvent(direction > 0, content.id);
			}
		}
		else if (content.type === "question")
		{
			// Skip question
			var msg = this.messages.GetMessage(content.messageId);
			if (msg)
				this.OnAnswerClickedInternal(msg, msg.data, -1);
		}
		else if (content.type === "ad")
		{
			// Skip ad
			var adMsg = this.messages.GetMessage(content.messageId);
			if (adMsg)
				this.OnAdThrowoutInternal(adMsg, adMsg.data, direction > 0);
		}
		else if (content.type === "customComponent")
		{
			// Skip question
			var _msg = this.messages.GetMessage(content.messageId);
			if (_msg && _msg.data.onThrow)
				_msg.data.onThrow(direction);
		}

		content.throwOutHandled = true;

		//this.content.splice(0, 1);
		this.messages.RemoveMessage(content.messageId);

		//this.forceUpdate();
	}

	SendTrackingEvent(positive, activityId)
	{
		Tracking.FlushVisibleItemsTracking();
		Tracking.SendEvent(positive ? "contentRightSwipe" : "contentLeftSwipe",
		{
			activityId: activityId
		});
	}

	NudgeCard()
	{
		if (this.stack)
		{
			this.stack.NudgeCard();

			// bug workaround: after nudging the card can't be touched
			/*setTimeout(() => {
				this.stack.OnActivate();
			}, 1000);*/
		}
	}

	OnLocationButton = () =>
	{
		if (this.isInLocationSearch)
		{
			this.isInLocationSearch = false;
			//AppState.instance.appInstance.OnLeaveSearch();
		}
		else
		{
			this.isInLocationSearch = true;
			setTimeout(() => {
				$("#searchlocation").focus();
			}, 300);
		}
	}

	OnLocationSet = () =>
	{
		this.ResetLocationSearch();
	}

	ResetLocationSearch()
	{
		this.isInLocationSearch = false;
		this.locationSearchTerm = undefined;
		$("#searchlocation").val("");
	}

	OnSearchFieldChanged = (searchTerm) =>
	{
		this.locationSearchTerm = searchTerm;
	}

	OnSearchFieldEnter = () =>
	{
	}

	OnSearchFieldIcon = () =>
	{
		this.ResetLocationSearch();
		//if (this.locationSearchTerm && this.locationSearchTerm.length > 0)
		//{
		//	this.locationSearchTerm = undefined;
		//	$("#searchlocation").val("");
		//	$("#searchlocation").focus();
		// }
		// else
		// {
		// 	this.isInLocationSearch = false;
		// }
	}

	OnFilterBarButton = () =>
	{
		if (this.isInLocationSearch)
		{
			this.ResetLocationSearch();
		}
		return true;
	}

	IsBusy()
	{
		return (this.stack && this.stack.IsBusy());
	}

	OnAfterRenderTutorial = () =>
	{
		if (Tutorial.IsActive() && this.stack && (this.content.length > 0))
		{
			var card = this.stack.GetTopCard();
			if (card)
			{
				//console.log(this.content);
				Tutorial.SetTopCard(card, this.GetTopContentId());
			}
		}
	}

	GetTopContentId()
	{
		if (this.content.length > 0)
			return this.content[0].id;
		return undefined;
	}

	OnTutorialSkipped()
	{
		// Swipe out all tutorial cards
		const topContentId = this.GetTopContentId();
		if (topContentId === 99990 || topContentId === 99991)
		{
			if (this.skippedTutorialCardsProcessed[topContentId] !== true)
			{
				this.skippedTutorialCardsProcessed[topContentId] = true;

				var card = this.stack.GetTopCard();
				card.dragTo(-500, 0, 50, -100, 0);
				setTimeout(() => {
					card.throwOut(-500, 0, Direction.LEFT);
				}, 50);
			}

			setTimeout(() => {
				this.OnTutorialSkipped();
			}, 50);
		}

		//this.messages.ClearTutorialMessages();
		//this.stack.OnActivate();
		//this.messages.ClearAllMessages();
		//AppState.instance.appContainer.forceUpdate();
	}

	render()
	{
		const showEmpty = this.content.length === 0 && this.messages.HasNoMoreSuggestions();

		var s = {};
		if (AppState.instance.currentMainTab !== AppState.TabIndexSwipe)
			s = {'display': 'none'};

		//console.log("SwipePage content:");
		//console.log(this.content);

		if (Tutorial.IsActive())
		{
			setTimeout(this.OnAfterRenderTutorial, 100);
		}
		
		return (
			<div id="swipePage" className={"swipePage" + (this.isInLocationSearch ? " locationSearchActive":"")} key="swipePage"
				style={s}>

				<FilterBar
					topSpacing={8}
					showFiltersButton={false}
					showSortButton={false}
					showFilters={false}
					showUserList={true}
					showProfile={true}
					userListType={0}
					onLocationButton={this.OnLocationButton}
					onDateTimeButton={this.OnFilterBarButton}
					onUserListButtonClicked={this.OnFilterBarButton}
					userListTitle={Translate.T("en.Save in list", "de.In Liste speichern", "userlistdialog.swipetarget.title")}
				/>

				<ContentCardStack
					id={"swipePageCCS"}
					className={"swipePageCCS"}
					key={"swipePageCCS"}
					onInit={this.OnStackInit}
					content={this.content}
					classNameCardWrapper={"chatCard"}
					containerSelector={"#swipePage"}
					onThrowOutDecision={this.OnThrowOutDecision}
					onThrowOutEnd={this.OnThrowOutEnd}
				/>

				{showEmpty && <div className="emptySuggestion">
					{Translate.T("en.Looks like LENA is out of suggestions for today...",
						"de.Scheint als hätte LENA heute keine Vorschläge mehr...", "nomoresuggestions")}
				</div>}

				<LocationSearch
					showSearchField={true}
					visible={this.isInLocationSearch}
					q={this.locationSearchTerm}
					onLocationSet={this.OnLocationSet}
					onSearchFieldChanged={this.OnSearchFieldChanged}
					onSearchFieldEnter={this.OnSearchFieldEnter}
					onSearchFieldIconClicked={this.OnSearchFieldIcon}
				/>

			</div>
		);
	}
}

SwipePage.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(SwipePage);