import Utils from './Utils';
import Tracking from './Tracking';
import RestApi from './RestApi';
import AppState from './AppState';
import LiveData from './models/LiveData';

export default class ContentStore
{
	static CategoriesId = "categories";

	categories = [];
	categoryData = {};
	categoryDataWasRequested = {};
	categoryToken = {};
	categoryTotal = {};
	categoryChildIndex = {};

	onDataChangedCallbacks = {};
	searchTriggered = false;
	searchQuery;
	categoryLoading = {};
	categoryContTicket = {};

	onDataChangedGeneralCallback;

	isPaused = false;
	queue = [];

	contentData = {};
	contentItems = {};

	Init()
	{
		AppState.instance.AddOnAuthDone(this.OnAuthDone);
	}

	Pause()
	{
		this.isPaused = true;
	}

	Resume()
	{
		this.ProcessQueuedQueries();
	}

	IsPaused()
	{
		return this.isPaused;
	}

	OnAuthDone = () =>
	{
	}

	ProcessQueuedQueries()
	{
		if (this.queue.length > 0)
		{
			var firstQuery = this.queue[0];
			var thisPtr = this;
			this.ProcessSearchQuery(firstQuery).then(() =>
			{
				this.queue.splice(0, 1);
				thisPtr.ProcessQueuedQueries();
			});
		}
		else
		{
			this.isPaused = false;
		}
	}

	GetCategories(onDataChanged)
	{
		var catId = ContentStore.CategoriesId;

		this.AddOnDataChanged(catId, onDataChanged);
		this.OnDataChanged('request', catId);

		var loc = AppState.instance.GetBestLocation();

		var startEnd = this.GetStartEndDateFilter();

		var q = {
			//visibilityContext: ContentStore.VisibilityContext
			language: AppState.instance.userProfile.userLang,
			startDate: startEnd.start,
			endDate: startEnd.end,
			location: loc,
			includeHierarchy: true
		};

		RestApi.SendRequest("/userfilters", q)
		.then((r) =>
		{
			this.categories = r;

			for (var i = 0; i < this.categories.length; ++i)
			{
				const catId = this.categories[i].id;
				this.categoryData[catId] = []; // Nothing loaded yet
				this.categoryToken[catId] = undefined;
				this.categoryTotal[catId] = undefined;
				this.categoryChildIndex[catId] = undefined;

				//TEMP:
				//TODO:
				var c = this.categories[i];
				c.type = "filter";
				if (c.dataType === 0)
					c.itemType = "content";
				else if (c.dataType === 1)
					c.itemType = "user";
				else if (c.dataType === 2)
					c.itemType = "ugc";

				c.tab = i;
			}

			this.OnDataChanged('data', catId, this.categories ? this.categories.slice() : []);

			// Reload already requested data
			// for (var id in this.categoryDataWasRequested)
			// {
			// 	if (this.categoryDataWasRequested[id] === true)
			// 	{
			// 		this.GetCategoryContent(id);
			// 	}
			// }
		})
		.catch((error) =>
		{
			var qStr = JSON.stringify(q);
			Tracking.OnError("Error loading categories: " + error + ", q: " + qStr);

			this.OnDataChanged('error', catId);
		});
	}

	GetAllCategoriesDirectly()
	{
		return this.categories.slice();
	}

	GetCategoryName(catId)
	{
		for (var i = 0; i < this.categories.length; ++i)
		{
			if (this.categories[i].id === catId)
			{
				return this.categories[i].title;
			}
		}
		return undefined;
	}

	GetCategoryId(catName)
	{
		for (var i = 0; i < this.categories.length; ++i)
		{
			if (this.categories[i].title === catName)
			{
				return this.categories[i].id;
			}
		}
		return undefined;
	}

	ClearTokens()
	{
		//console.log("Clearing tokens");
		for (var i = 0; i < this.categories.length; ++i)
		{
			const catId = this.categories[i].id;
			this.categoryToken[catId] = undefined;
			this.categoryTotal[catId] = undefined;
			this.categoryChildIndex[catId] = undefined;
		}
	}

	GetTabIndexMax()
	{
		var max = 0;
		for (var i = 0; i < this.categories.length; ++i)
		{
			const t = this.categories[i].tab;
			if (t !== undefined && t > max)
				max = t;
		}
		return max;
	}

	OnSearchCleared()
	{
		this.searchQuery = undefined;

		// Clear all tokens
		for (var catId in this.categoryToken)
		{
			this.categoryToken[catId] = undefined;
			this.categoryTotal[catId] = undefined;
			this.categoryChildIndex[catId] = undefined;
		}

		// Start a fresh request for last open filter:
		var filterIdSum = "";
		for (var i = 0; i < AppState.instance.filterStack.length; ++i)
		{
			if (filterIdSum.length > 0)
				filterIdSum += "/";
			filterIdSum += AppState.instance.filterStack[i];
		}

		this.GetCategoryContent(filterIdSum, undefined);
	}
	
	Search(q, categoryOverride)
	{
		//console.log("search for: " + q);

		if (this.searchQuery !== q)
		{
			this.searchQuery = q;

			// Clear all tokens
			for (var catId in this.categoryToken)
			{
				this.categoryToken[catId] = undefined;
				this.categoryTotal[catId] = undefined;
				this.categoryChildIndex[catId] = undefined;
			}

			//if (q && q.length > 0)
			//{
				// Start a fresh request for last open filter:
				// var filterIdSum = "";
				// for (var i = 0; i < AppState.instance.filterStack.length; ++i)
				// {
				// 	if (filterIdSum.length > 0)
				// 		filterIdSum += "/";
				// 	filterIdSum += AppState.instance.filterStack[i];
				// }

				var filterIdSum = AppState.instance.filterManager.CreateFilterId(AppState.instance.filterStack);

				if (categoryOverride)
					filterIdSum = categoryOverride;

				this.GetCategoryContent(filterIdSum, undefined);
			//}
		}
	}

	OnDataChanged(action, catId, data, maxTotal)
	{
		 //console.log("                   data event: " + action + "@" + catId);
		// if (action === 'data')
		// {
		// 	if (data === undefined)
		// 		console.log("                     data.length: 0, token: " + this.categoryToken[catId]);
		// 	else
		// 		console.log("                     data.length: " + data.length + ", token: " + this.categoryToken[catId] + ", total: " + this.categoryTotal[catId]);
		// }

		if (this.onDataChangedCallbacks[catId] !== undefined)
		{
			//console.log("found " + this.onDataChangedCallbacks[catId].length + " CBs of: " + catId);

			for (var i = 0; i < this.onDataChangedCallbacks[catId].length; ++i)
			{
				this.onDataChangedCallbacks[catId][i](action, data, maxTotal);

				//console.log("fire CB of: " + catId);
				//console.log(this.onDataChangedCallbacks[catId][i]);
			}
		}

		if (this.onDataChangedGeneralCallback !== undefined)
			this.onDataChangedGeneralCallback(action, catId);
	}

	AddOnDataChanged(catId, onDataChanged)
	{
		if (onDataChanged !== undefined && onDataChanged !== null)
		{
			if (this.onDataChangedCallbacks[catId] === undefined)
				this.onDataChangedCallbacks[catId] = [];
			this.onDataChangedCallbacks[catId].push(onDataChanged);

			//console.log("added CB to: " + catId);
			//console.log(onDataChanged);
		}
	}

	RemoveOnDataChanged(catId, onDataChanged)
	{
		if (onDataChanged !== undefined && onDataChanged !== null &&
			this.onDataChangedCallbacks[catId])
		{
			for (var i = 0; i < this.onDataChangedCallbacks[catId].length; ++i)
			{
				if (this.onDataChangedCallbacks[catId][i] === onDataChanged)
				{
					this.onDataChangedCallbacks[catId].splice(i, 1);

					//console.log("removed CB from: " + catId);
					//console.log(onDataChanged);

					break;
				}
			}
		}
	}

	GetExistingCategoryContent(catId)
	{
		if (catId === undefined)
			catId = "";
		
		if (this.categoryData[catId])
			return this.categoryData[catId].slice();

		return undefined;
	}

	GetCategoryContent(catId, onDataChanged, childIndex)
	{
		if (catId === undefined)
			catId = "";

		const useCaching = childIndex === undefined;

		if (this.categoryChildIndex[catId] !== childIndex)
		{
			//console.log("changed childindex -> restart data stream: " + catId);
			this.DeleteContent(catId);
		}

		if (this.categoryToken[catId] === 0 && this.categoryLoading[catId] !== true && useCaching)
		{
			if (onDataChanged === undefined || onDataChanged !== null)
			{
				//The caller didn't call 'RequestMoreData', so tell them what we have already
				this.AddOnDataChanged(catId, onDataChanged);
				this.OnDataChanged('request', catId);
				var total = this.categoryTotal[catId];
				if (total > this.categoryData[catId].length && this.categoryToken[catId] === 0)
					total = this.categoryData[catId].length;

				this.OnDataChanged('data', catId, this.categoryData[catId].slice(), total);
			}
			return; // The server already told us there is no more data
		}

		this.AddOnDataChanged(catId, onDataChanged);
		this.OnDataChanged('request', catId);

		if (this.categoryLoading[catId] !== true)
		{
			this.categoryLoading[catId] = true;

			var query = this.CreateSearchQuery(catId);
			query.childIndex = childIndex;

			if (this.isPaused && catId !== 0 &&
				catId !== ContentStore.CategoriesId)
			{
				this.queue.push(query);
			}
			else
			{
				this.ProcessSearchQuery(query);
			}
		}
	}

	DeleteContent(catId)
	{
		this.categoryToken[catId] = undefined;
		this.categoryTotal[catId] = undefined;
		this.categoryChildIndex[catId] = undefined;
	}

	RequestMoreContent(catId, childIndex)
	{
		if (this.categoryLoading[catId] === true)
			return;

		if (this.categoryChildIndex[catId] === childIndex)
		{
			if (this.categoryToken[catId] === 0)
			{
				this.OnDataChanged('eod', catId);
				return; // No more content
			}
		}

		//console.log("loading more data for: " + catId);
		return this.GetCategoryContent(catId, null, childIndex);
	}

	GetStartEndDateFilter()
	{
		var start;
		var end;

		if (AppState.instance.userProfile.filterStartDate)
			start = AppState.instance.userProfile.filterStartDate;
		else
			start = new Date();
			
		if (AppState.instance.userProfile.filterEndDate)
			end = AppState.instance.userProfile.filterEndDate;
		else
			end = Utils.DatePlusDays(start, 2 * 31);

		if (end <= start)
			end = Utils.DatePlusDays(start, 1);

		return {start, end};
	}

	CreateSearchQuery(catId)
	{
		var loc = AppState.instance.GetBestLocation();

		var query = {};
		
		if (catId === undefined || catId === "" || catId === ContentStore.CategoriesId)
			query.filterStack = [];
		else if (typeof catId === 'string')
		{
			query.filterStack = catId.split('/');

			var areStrings = false;
			for (var i = query.filterStack.length - 1; i >= 0; --i)
			{
				if (Utils.IsNumber(query.filterStack[i]))
				{
					if (query.filterStack[i].length === 0)
					{
						query.filterStack.splice(i, 1);
					}
				}
				else
				{
					areStrings = true;
					break;
				}
			}

			// Move items to filterStackStr if they are strings
			if (areStrings)
			{
				query.filterStackStr = query.filterStack;
				query.filterStack = undefined;
			}
		}
		else
		{
			query.filterStack = [];
			query.filterStack.push(catId);
		}
		
		//if (catId !== undefined && catId !== "")
		//	query.filterStack.push(catId);
		query.location = loc;
		query.referenceLocation = loc;

		query.language = AppState.instance.userProfile.userLang;

		query.ageMin = AppState.instance.userProfile.filterMinAge;
		query.ageMax = AppState.instance.userProfile.filterMaxAge;

		var startEnd = this.GetStartEndDateFilter();
		query.tripStartDate = startEnd.start;
		query.tripEndDate = startEnd.end;

		query.token = this.categoryToken[catId];
		const isRequestForMore = query.token;
		if (query.token === undefined)
			query.token = 0;

		query.count = isRequestForMore ? 8 : 4;
		if (AppState.instance.deviceInfo.tablet)
			query.count = 6;
		else if (AppState.instance.deviceInfo.desktop)
			query.count = 8;

		if (!query.filterStack || query.filterStack.length === 0)
			query.minNumDataEntriesChildFilter = 2; // Don't display sub-filters less than N entries
		else
			query.minNumDataEntriesChildFilter = 8;
		//TODO: load more in SearchPage?

		query.q = this.searchQuery;

		if (this.searchQuery && this.searchQuery.length > 0)
			query.count = 10;

		query.filterMinRating = AppState.instance.userProfile.filterMinRating;
		query.filterMaxPrice = AppState.instance.userProfile.filterMaxPrice;

		query.sortBy = AppState.instance.userProfile.filterSortBy;
		query.sortAsc = AppState.instance.userProfile.filterSortAsc;

		query.searchRadius = AppState.instance.userProfile.filterMaxDistance * 1000; // km -> m
		query.searchDuration = AppState.instance.userProfile.filterMaxDuration; //seconds

		query.minimalVersion = true;
		
		return query;
	}

	ProcessSearchQuery(query)
	{
		var catId;
		if (query.filterStackStr && query.filterStackStr.length > 0)
		{
			catId = query.filterStackStr.join("/");
		}
		else
		{
			catId = AppState.instance.filterManager.CreateFilterId(query.filterStack);
		}

		//TODO: make requests cancelable, e.g. if filter change during loading

		this.categoryDataWasRequested[catId] = true;

		return RestApi.SendRequest("/search", query)
		.then((r) =>
		{
			// Has search been aborted?
			if (this.categoryToken[catId] === 0 || this.categoryToken[catId] === undefined)
			{
				// this was a fresh new search -> reset the data
				this.categoryData[catId] = [];
			}

			this.categoryData[catId] = this.categoryData[catId].concat(r.items);
			this.categoryToken[catId] = r.token; //undefined => restart the next time. 0 => EoD.
			this.categoryTotal[catId] = r.maxTotal;
			this.categoryChildIndex[catId] = query.childIndex;
			this.categoryLoading[catId] = false;

			var total = this.categoryTotal[catId];
			if (total > this.categoryData[catId].length && this.categoryToken[catId] === 0)
				total = this.categoryData[catId].length;

			for (var i = 0; i < this.categoryData[catId].length; ++i)
			{
				this.SetContentItem(this.categoryData[catId][i]);
			}

			this.OnDataChanged('data', catId, this.categoryData[catId].slice(), total);

			if (this.categoryToken[catId] === 0)
				this.OnDataChanged('eod', catId);
		})
		.catch((error) =>
		{
			this.categoryLoading[catId] = false;
			this.OnDataChanged('error', catId);
			Tracking.OnError("Error loading content: " + error);
		});
	}

	OnFilterChanged()
	{
		//console.log("FILTER CHANGED ========");

		for (var catId in this.categoryData)
			this.categoryData[catId] = []; // Nothing loaded yet

		for (catId in this.categoryToken)
		{
			this.categoryToken[catId] = undefined;
			this.OnDataChanged('flushed', catId);
		}

		// Reload currently displayed categoryId
		this.GetCategories();
	}

	DetailsToContentItem(activityData, distanceObj, categories, result)
	{
		result.type = "content";
		result.id = activityData.id;
		result.title = activityData.name;
		result.desc = activityData.desc;
		result.url = "/activity/" + Utils.GetUrlName(activityData.name);
		result.address = activityData.address;
		result.rating = activityData.r;
		result.distance = distanceObj;

		result.ageFitMin = activityData.ageFitMin;
		result.ageFitMax = activityData.ageFitMax;
		result.durationMin = activityData.durationMin;
		result.durationMax = activityData.durationMax;
		result.allWeather = activityData.allWeather;
		result.carParking = activityData.carParking;
		result.strollerSuitable = activityData.strollerSuitable;
		result.accessible = activityData.accessible;
		result.bookingRequired = activityData.bookingRequired;
		result.dogsWelcome = activityData.dogsWelcome;
		result.latitude = activityData.latitude;
		result.longitude = activityData.longitude;
		
		result.categories = categories;

		result.img = undefined;
		if (activityData.pictureUrls)
		{
			for (var i = 0; i < activityData.pictureUrls.length; ++i)
			{
				result.img = activityData.pictureUrls[i];
				break;
			}
		}

		return result;
	}

	FindContentData(id)
	{
		for (var title in this.contentData)
		{
			if (this.contentData[title].activity.id === id)
				return this.contentData[title];
		}
		return undefined;
	}

	FindContentItem(title)
	{
		return this.contentItems[Utils.GetUrlName(title)];
	}

	SetContentItem(ci)
	{
		if (!ci)
			return;
		if (ci.type !== "content")
			return;
		if (!ci.title)
			return;

		this.contentItems[Utils.GetUrlName(ci.title)] = ci;

		AppState.instance.imageManager.Store(ci.imageData);
	}

	GetActivityDetailsById(id, onLoaded)
	{
		var cached = this.FindContentData(id);
		if (cached)
		{
			if (onLoaded)
				onLoaded(cached);
			return new Promise((resolve, reject) => { return resolve(cached); });
		}

		var query = {};
		query.id = id;
		return this.LoadActivityDetails(query, onLoaded);
	}

	GetActivityDetails(title, onLoaded)
	{
		var cached = this.contentData[Utils.GetUrlName(title)];
		if (cached)
		{
			if (onLoaded)
				onLoaded(cached);
			return new Promise((resolve, reject) => { return resolve(cached); });
		}

		var query = {};
		query.title = title;
		return this.LoadActivityDetails(query, onLoaded);
	}

	LoadActivityDetails(query, onLoaded)
	{
		query.language = AppState.instance.userProfile.userLang;

		query.referenceLocation = AppState.instance.GetBestLocation();

		query.similarItems = 20;
		query.similarItemsThreshold = 0.3;
		query.similarItemsDuration = 30;

		return RestApi.Details(query).then( (details) =>
		{
			if (details && details.activity)
			{
				this.PrepareActivityDetails(details);
				this.SetActivityDetails(details.activity.name, details);

				//TODO: cache in localstorage ?

				if (onLoaded)
					onLoaded(details);

				return new Promise((resolve, reject) => { return resolve(details); });
				//return details;
			}
			else
			{
				return new Promise((resolve, reject) => { return reject(undefined); });	
			}
		})
		.catch(err =>
		{
			Tracking.OnError("error loading details: " + JSON.stringify(err));
			if (onLoaded)
				onLoaded(undefined);
			return new Promise((resolve, reject) => { return reject(err); });
		});
	}

	SetActivityDetails(title, details)
	{
		if (title === undefined || details === undefined)
			return;

		this.contentData[Utils.GetUrlName(title)] = details;
	}

	ClearActivityDetails(title)
	{
		var _title = Utils.GetUrlName(title);
		this.contentData[_title] = undefined;
		this.contentItems[_title] = undefined;
	}

	PrepareActivityDetails(details)
	{
		if (details.activity.pictureUrls === undefined)
			details.activity.pictureUrls = [];

		// Remove empty entries
		for (var i = details.activity.pictureUrls.length - 1; i >= 0; --i)
		{
			const entry = details.activity.pictureUrls[i];
			if (entry === undefined || entry === null || entry.length === 0)
				details.activity.pictureUrls.splice(i, 1);
		}
		
		details.contentIds = [];
		if (details.activity.pictureUrls)
		{
			for (i = 0; i < details.activity.pictureUrls.length; ++i)
				details.contentIds.push(undefined);
		}

		if (details.userContent)
		{
			for (i = 0; i < details.userContent.length; ++i)
			{
				//if (details.userContent[i].status == 1)
				//{
					const contentData = details.userContent[i];
					var contentLiveData = LiveData.GetContentLiveData(details.liveData, contentData.id);
					var hotData = LiveData.GetHotContentData(contentLiveData, contentData);

					if ((hotData.newContent || hotData.liveContent) && (details.activity.pictureUrls.length > 0))
					{
						//TODO: sort by "hotness" of content

						var index = 1;
						if (contentData.isOwnContent)
							index = 0;

						details.activity.pictureUrls.splice(index, 0, details.userContent[i].url);
						details.contentIds.splice(index, 0, details.userContent[i].id);
					}
					else
					{
						details.activity.pictureUrls.push(details.userContent[i].url);
						details.contentIds.push(details.userContent[i].id);
					}
				//}
			}
		}

		if (details.activity.pictureUrls.length === 0)
		{
			if (details.categories && details.categories.length > 0)
			{
				const catIconUrl = Utils.GetCategoryIconUrl(details.categories[0], "white", "#ccc", 16, 16, true);
				details.activity.pictureUrls.push(catIconUrl);
			}
			else
			{
				details.activity.pictureUrls.push("/assets/placeholder.svg");
			}
			details.contentIds.push(undefined);
		}

		// Fix bug in parsing polygone data
		if (details.activity.pathCoordinates && details.activity.pathCoordinates.length > 0 && details.activity.pathCoordinates[0] > 100000)
		{
			for (let i = 0; i < details.activity.pathCoordinates.length; ++i)
			{
				let coordStr = "" + details.activity.pathCoordinates[i];
				if (coordStr.indexOf(".") < 0)
				{
					let twoDigits = coordStr[0] + coordStr[1];
					let newCoord;
					if (Number(coordStr[0]) >= 6)
					{
						newCoord = Number(coordStr[0] + "." + coordStr.substr(1));
					}
					else
					{
						newCoord = Number(coordStr[0] + coordStr[1] + "." + coordStr.substr(2));
					}

					//console.log(details.activity.pathCoordinates[i] + " -> " + newCoord);
					details.activity.pathCoordinates[i] = newCoord;
				}
			}
		}

		AppState.instance.imageManager.Store(details.imageData);
	}
}