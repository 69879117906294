import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import IconButton from 'material-ui/IconButton';
//import ShareIcon from 'material-ui-icons/Share';
import MoreHorizIcon from 'material-ui-icons/MoreHoriz';
import AppState from '../AppState';
import Translate from '../Translate';
import Menu, { MenuItem } from 'material-ui/Menu';
import Divider from 'material-ui/Divider';
import Button from 'material-ui/Button';
import KeyboardArrowRight from 'material-ui-icons/KeyboardArrowRight';
import HorizontalScroller from './HorizontalScroller';
import ContentCard from './ContentCard';
import ContentGrid from './ContentGrid';

const styles = theme => ({
	flexGrow: {
		flex: '1 1 auto',
	},
});

@observer
class UserListCard extends React.Component
{
	@observable expanded;
	cardMenuAnchor;
	@observable isCardMenuVisible = false;

	content = [];
	_isMounted = false;

	componentDidMount()
	{
		this._isMounted = true;
		this.LoadData();
		AppState.instance.listManager.onListsChanged.Add(this.OnListsChanged);
	}

	componentWillUnmount()
	{
		AppState.instance.listManager.onListsChanged.Remove(this.OnListsChanged);
		this._isMounted = false;
	}

	LoadData()
	{
		AppState.instance.listManager.GetListContent(this.props.list.id)
		.then((r) =>
		{
			this.content = [];
			for (var i = 0; i < r.length; ++i)
			{
				var newItem = {};
				if (r[i].activity)
				{
					this.content.push(AppState.instance.contentStore.DetailsToContentItem(
						r[i].activity, r[i].distance, r[i].categories, newItem));
				}
				else
				{
					// User, Ugc
				}
			}

			if (this._isMounted)
				this.forceUpdate();
		})
		.catch((error) =>
		{
			this.content = [];
			console.log("error while loading userlist content");
			if (this._isMounted)
				this.forceUpdate();
		});
	}

	OnListsChanged = () =>
	{
		this.LoadData();
	}

	OnCardClicked = () =>
	{
		if (this.props.onClick)
			this.props.onClick(this.props.list);
	}

	OnOpenCardMenu = (e) =>
	{
		this.cardMenuAnchor = e.currentTarget;
		this.isCardMenuVisible = true;

		e.stopPropagation();
		e.preventDefault();
	}

	OnCloseCardMenu = () =>
	{
		this.isCardMenuVisible = false;
	}

	OnDeleteClicked = () =>
	{
		this.isCardMenuVisible = false;

		AppState.instance.screenMessage.ShowAlert(Translate.T("en.Confirmation", "de.Bestätigung", "confirmation.title"),
			Translate.T("en.Do you really want to delete this list?", "de.Willst Du diese Liste wirklich löschen?", "confirmation.deleteuserlist"),
			[{label: Translate.FindTranslation("answer.no"), value: 0}, {label: Translate.FindTranslation("answer.yes"), value: 1}], true, (resultButton) =>
		{
			if (resultButton && resultButton.value)
			{
				if (AppState.instance.swipeListId === this.props.list.id)
				{
					var lists = AppState.instance.listManager.GetLists();
					if (lists.length > 0)
						AppState.instance.swipeListId = lists[0].id;
				}
				
				AppState.instance.listManager.DeleteList(this.props.list.id);
				AppState.instance.appContainer.forceUpdate();
			}
		});
	}

	OnEmptyClicked = () =>
	{
		this.isCardMenuVisible = false;

		AppState.instance.screenMessage.ShowAlert(Translate.T("en.Confirmation", "de.Bestätigung", "confirmation.title"),
			Translate.T("en.Do you really want to empty this list?",
			"de.Willst Du diese Liste wirklich leeren?", "confirmation.emptyuserlist"),
			[{label: Translate.FindTranslation("answer.no"), value: 0}, {label: Translate.FindTranslation("answer.yes"), value: 1}], true, (resultButton) =>
		{
			if (resultButton && resultButton.value)
			{
				AppState.instance.listManager.EmptyList(this.props.list.id);
				AppState.instance.appContainer.forceUpdate();
			}
		});
	}

	renderContentMobile()
	{
		return (
			<HorizontalScroller allowIScroll={false}>
				{this.content.map((details, index, array) =>
				{
					return (
						<ContentCard
							contentData={details}
							key={index}
							columnMode={false}
							isFirst={index === 0}
							isLast={index === array.length - 1}
							itemType="content"
							renderMode="supertiny"
							defaultContentHeight={100}
							maxNumCategories={1}
						/>
					);
				})}
			</HorizontalScroller>
		);
	}

	OnRenderContent = (content, index, contentList,
		contentWidth, numContentPerRow, isFirstContentInRow, isLastContentInRow) =>
	{
		return (
			<div
				className={"userListCardContentCell"}
			>
				<ContentCard
					contentData={content}
					columnMode={false}
					isFirst={false}
					isLast={false}
					itemType={"content"}
					showLocation={true}
					defaultContentHeight={Math.floor(contentWidth / 1.5)}
				/>
			</div>
		);
	}

	renderContentDesktop()
	{
		const maxCount = 4;

		var list;
		if (this.content.length > maxCount)
		{
			list = [];
			for (var i = 0; i < maxCount; ++i)
			{
				list.push(this.content[i]);
			}
		}
		else
		{
			list = this.content;
		}

		if (!list || list.length === 0)
		{
			return (
				<div className="userlistCardEmpty">
					{Translate.T("de.Keine Einträge", "en.No entries", "userlistcard.emptylist")}
				</div>
			);
		}

		return (
			<ContentGrid
				content={list}
				minContentWidth={250}
				maxContentWidth={600}
				idealNumContentPerRow={4}
				contentSpacing={16}
				onRenderContent={this.OnRenderContent}
			/>
		);
	}

	render()
	{
		const list = this.props.list;

		var subHeader;
		if (list.items)
		{
			if (list.items.length > 1)
				subHeader = <span className="cardSubHeader">{list.items.length} {Translate.T("en.cards", "de.Karten", "userlistselection.entries")}</span>;
			else if (list.items.length === 1)
				subHeader = <span className="cardSubHeader">{list.items.length} {Translate.T("en.card", "de.Karte", "userlistselection.entries.one")}</span>;
		}
		if (!subHeader)
			subHeader = <span className="cardSubHeader">{Translate.T("en.No cards", "de.Keine Karten", "userlistselection.entries.none")}</span>;

		var translated = Translate.FindTranslation(list.title);
		if (!translated)
			translated = list.title;

		if (AppState.instance.deviceInfo.desktop)
			translated += " (" + this.content.length + ")";

		const isStandard = AppState.instance.listManager.IsDefaultList(list);

		const menuEnabled = (this.content.length > 0) || (!isStandard);

		return (
			<div className="userListCard" key={list.id}>
				<div className="resultCategoryName" onClick={this.content.length > 0 ? this.OnCardClicked : undefined}>{translated}</div>
				
				<div className="userListCardButtons">
					{menuEnabled && <IconButton className="userListCardMore" onClick={this.OnOpenCardMenu}>
						<MoreHorizIcon />
					</IconButton>}

					{!AppState.instance.deviceInfo.desktop && <div className="userListCardShow">
						{this.content.length > 0 && <Button onClick={this.OnCardClicked}>
							{Translate.T("en.Details", "de.Details", "userlistcard.details")}
							<KeyboardArrowRight
								style={{
									width: 20,
									height: 20,
									position: 'relative',
									top: '-1px'
								}}/>
						</Button>}
					</div>}

					<Menu
						anchorEl={this.cardMenuAnchor}
						open={this.isCardMenuVisible}
						onClose={this.OnCloseCardMenu}
					>
						{this.content.length > 0 && <MenuItem onClick={this.OnEmptyClicked}>{Translate.T("en.Empty {0}", "de.Leere {0}", "journals.menu.empty", [translated])}</MenuItem>}
						{!isStandard && this.content.length > 0 && <Divider light />}
						{!isStandard && <MenuItem onClick={this.OnDeleteClicked}>{Translate.T("en.Delete {0}", "de.Lösche {0}", "userlist.menu.delete", [translated])}</MenuItem>}
					</Menu>
				</div>

				{!AppState.instance.deviceInfo.desktop &&
					<div className="userListCardSubTitle" onClick={this.content.length > 0 ? this.OnCardClicked : undefined}>{subHeader}
					</div>
				}

				{AppState.instance.deviceInfo.desktop && this.renderContentDesktop()}
				{!AppState.instance.deviceInfo.desktop && this.renderContentMobile()}

				{AppState.instance.deviceInfo.desktop && this.content.length > 0 && <div className="resultCategoryMoreDesktop">
					<Button className="userListCardShowDesktop" onClick={this.OnCardClicked}>
						{Translate.T("en.Show all ({0})", "de.Alle anzeigen ({0})", "bookmarklist.showall.desktop", [this.content.length])}
						<KeyboardArrowRight
							style={{
								width: 20,
								height: 20,
								position: 'relative',
								top: '-1px'
							}}/>
					</Button>
				</div>}
			</div>
		);
	}
}


UserListCard.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserListCard);