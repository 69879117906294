import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import Translate from '../Translate';
import Utils from '../Utils';
import AppState from '../AppState';
import Button from 'material-ui/Button';
import { observable } from 'mobx';
import {CircularProgress} from 'material-ui/Progress';

const styles = theme => ({	
});

@observer
class HomeLinkCard extends React.Component
{
	@observable a2hsShown = false;
	a2hsSuccess = false;
	a2hsFailure = false;

	static OnThrow()
	{
		console.log("HomeLinkCard.OnThrow");
	}

	OnInstallA2HS = () =>
	{
		this.a2hsShown = true;
		AppState.instance.PromptA2HS(this.OnInstalled, this.OnNotInstalled);
	}

	OnInstalled = () =>
	{
		this.a2hsSuccess = true;
		this.a2hsShown = false;	
	}

	OnNotInstalled = () =>
	{
		this.a2hsFailure = true;
		this.a2hsShown = false;
	}

	render()
	{
		var ios = AppState.instance.deviceInfo.ios;
		var isSafari = AppState.instance.deviceInfo.safari;
		const a2hs = AppState.instance.CanPromptA2HS();

		if (a2hs)
		{
			ios = false;
			isSafari = false;
		}

		return (
			<div className="lenaCard">

				<div className="lenaCardHeader">
					<div className="intro_logocontainer">
						{Utils.RenderLogo(40, 40, "white", "#e31c46")}
					</div>

					<div className="lenaCardIntro">
						{Translate.T("de.Als App präsentiere ich mich noch besser!",
							"en.I can present myself even better as App!",
							"homelinkcard.intro")}
					</div>

					<div className="lenaCardLena">
						LENA
					</div>
				</div>

				{!a2hs && <div className="lenaCardBody homeLinkCardBody">

					{ios && isSafari && <div className="lenaCardBodyStatement">
						{Translate.T("de.Speichere mich als Verknüpfung auf Deinem Gerät um mich als App zu starten. Es ist kinderleicht!",
							"en.Save me as link on your home screen to get the full app experience. It's super simple!",
							"homelinkcard.explain")}
					</div>}

					{ios && isSafari && <div className="homeLinkStep step0">
						{Translate.T("de.Öffne das Browser-Menü",
							"en.Open the browser menu",
							"homelinkcard.step1")}
						<div className="imgContainer"><img src="/assets/action-ios.png" alt="action menu" /></div>
					</div>}
					{ios && isSafari && <div className="homeLinkStep step1">
						{Translate.T("de.und danach",
							"en.followed by",
							"homelinkcard.step2")}
						<div className="imgContainer">
							<img src="/assets/addhomescreen-ios.png" alt="add to home screen" />
							<div className="buttonText">
								{Translate.T("de.Zum Home -\nBildschirm",
									"en.Add to\nHome Screen",
									"homelinkcard.buttontext")}
							</div>
						</div>

					</div>}					
				</div>}

				{a2hs && !this.a2hsShown && !this.a2hsSuccess && !this.a2hsFailure && <div className="installA2HSBtn">
					<Button onClick={this.OnInstallA2HS}>
						{Translate.T("de.Als App-Verknüpfung installieren", "en.Install as App-Link", "homelinkcard.a2hs.buttontext")}
					</Button>
				</div>}

				{this.a2hsShown && <div className="progressCenter"><CircularProgress /></div>}

				{this.a2hsSuccess && <div className="lenaCardBody homeLinkCardBody">
					<div className="lenaCardBodyStatement">
						{Translate.T("de.Fantastisch! Viel Spaß mit meiner App!",
							"en.Great - enjoy my App!",
							"homelinkcard.install")}
					</div>
				</div>}

				{this.a2hsFailure && <div className="lenaCardBody homeLinkCardBody">
					<div className="lenaCardBodyStatement">
						{Translate.T("de.Okay, vielleicht ein anderes Mal.",
							"en.Okay, maybe next time.",
							"homelinkcard.noinstall")}
					</div>
				</div>}

			</div>
		);
	}
}

HomeLinkCard.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(HomeLinkCard);