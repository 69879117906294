/* eslint-disable no-useless-concat */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import AppState from './AppState';
import App from './App';
import Translate from './Translate';
import Button from 'material-ui/Button';
import KeyboardArrowDown from 'material-ui-icons/KeyboardArrowDown';
import $ from 'jquery';
import { observable } from 'mobx';
import DropdownMenu from 'react-dd-menu';
import AppBar from 'material-ui/AppBar';
import Toolbar from 'material-ui/Toolbar';
import Typography from 'material-ui/Typography';
import Dialog from 'material-ui/Dialog';
import IconButton from 'material-ui/IconButton';
import CloseIcon from 'material-ui-icons/Close';
import {WebContentPage, ToSPage, PPPage, ImprintPage} from './pages/WebContentPage';
import Slide from 'material-ui/transitions/Slide';
import Modal from 'material-ui/Modal';
import NewEntryPage from './pages/NewEntryPage';
import Switch from 'material-ui/Switch';
import BusinessMessage from './components/BusinessMessage';
import Tracking from './Tracking';


const styles = theme => ({
	closeButtonRoot: {
		width: '38px',
		height: '38px',
		marginLeft: '-4px',
	},
	dialogTitle: {
		marginLeft: '8px',
	},
});

@observer
class ProviderPage extends React.Component
{
	@observable isLanguageMenuOpen = false;

	dialogTitle;
	dialogContent;
	@observable dialogOpen = false;
	@observable isLoading = true;
	@observable showNewEntry = false;
	@observable priceAnnually = false;
	@observable showPremium = false;
	@observable showEnterprise = false;
	@observable showPlus = false;
	newEntryPage;

	constructor(props)
	{
		super(props);

		Tracking.OnPageView("provider");
	}

	componentDidMount()
	{
		AppState.instance.providerPage = this;

		$(window).scroll(this.OnScroll);

		if (window.location.pathname.indexOf("/profile") >= 0)
		{
		}
		else
		{
			AppState.instance.contentStore.Pause();
		}

		setInterval(() =>
		{
			var bodyOverflow = $("#body").css("overflow");
			if (bodyOverflow === "hidden")
			{
				if (!this.dialogOpen && !this.showNewEntry && !this.showPremium &&
					!this.showEnterprise && !this.showPlus)
					$("#body").css("overflow", "visible");
			}
		}, 500);

		$(window).scroll(this.OnScroll);

		this.forceUpdate();

		setTimeout(() => {
			$(".lpContainer").css('height', 'auto');
			this.isLoading = false;
		}, 250);
	}

	shouldComponentUpdate(nextProps, nextState)
	{
		// Prevents some refresh bugs
		return true;
	}

	OnScroll = () =>
	{
		//this.OnCoverScroll();

		const st = $(window).scrollTop();
		if (st > 0)
			this.hasScrolled = true;

		const threshold = window.innerHeight / 8;
		const percentTo2 = st / threshold; 

		if (percentTo2 >= 0)
		{
			$(".lpHeader").removeClass('lpHeader05');
			//$(".lpHeader").addClass('lpHeader2');
			$(".lpHeader").css('backgroundColor', 'white');
		}
		else
		{
			if (percentTo2 >= 0.5)
			{
				$(".lpHeader").addClass('lpHeader05');
			}
			else
			{
				$(".lpHeader").removeClass('lpHeader05');
			}
			//$(".lpHeader").removeClass('lpHeader2');
			$(".lpHeader").css('backgroundColor', 'rgba(255,255,255,' + percentTo2 + ')');
		}

		var lastCTA = $(".ppCTA").offset().top;
		lastCTA -= window.innerHeight;

		if (st > lastCTA || st < window.innerHeight * 0.5)
		{
			$(".lpHeaderCTA").addClass("lpHeaderCTA2");
		}
		else
		{
			$(".lpHeaderCTA").removeClass("lpHeaderCTA2");
		}
	}

	OnWebContentClick = (msg) =>
	{
		if (msg === "open.pp")
		{
			this.OnPP();
			return false;
		}
		else if (msg === "open.tos")
		{
			this.OnTos();
			return false;
		}

		return true;
	}

	OnShowWebContent(comp, title)
	{
		if (this.dialogOpen)
			this.dialogOpen = false;

		WebContentPage.clickHook = this.OnWebContentClick;

		this.dialogTitle = title;
		var _props = {};
		_props.id = 'generalDialogContent';
		this.dialogContent = React.createElement(comp, _props, null);
		this.dialogOpen = true;
	}

	OnTos = () =>
	{
		Tracking.OnPageView("provider/tos");
		this.OnShowWebContent(ToSPage, Translate.T('en.Terms of Service', 'de.Nutzungsbedingungen', 'tos'));
	}

	OnPP = () =>
	{
		Tracking.OnPageView("provider/pp");
		this.OnShowWebContent(PPPage, Translate.T('en.Privacy Policy', 'de.Datenschutzbestimmungen', 'privacypolicy'));
	}

	OnImprint = () =>
	{
		Tracking.OnPageView("provider/imprint");
		this.OnShowWebContent(ImprintPage, Translate.T('en.Imprint', 'de.Impressum', 'imprint'));
	}

	OnLanguageMenuClicked = () =>
	{
		Tracking.OnPageView("provider/languages");
		this.isLanguageMenuOpen = true;
	}

	OnLanguageMenuClose = () =>
	{
		this.isLanguageMenuOpen = false;
	}

	OnLanguageSelected = (lang) => () =>
	{
		Translate.SwitchLanguage(lang);
	}

	OnHeaderMenu = (selector, offset) => () =>
	{
		Tracking.OnPageView("provider/header/" + selector);

		var scrollTarget = $(selector).offset().top;
		if (offset)
			scrollTarget += offset;

		var body = $("html, body");
		body.stop().animate({scrollTop: scrollTarget}, 500, 'swing');
	}

	HandleDialogRequestClose = () =>
	{
		WebContentPage.clickHook = undefined;
		this.dialogOpen = false;
	}

	OnEmail = () =>
	{
		Tracking.OnPageView("provider/email");

		window.location.href = 'mailto:' + 'connect' + '@' + 'lena.app';
	}

	OnCloseNewEntry = () =>
	{
		if (this.newEntryPage)
			this.newEntryPage.Restart();
		this.showNewEntry = false;
	}

	OnModelCloseNewEntry = () =>
	{
		if (this.newEntryPage)
		{
			// Check progress
			const si = this.newEntryPage.stepIndex;
			if (si === 0 || si === 4)
			{
				Tracking.OnPageView("provider/basic/cancel/" + si);

				this.OnCloseNewEntry();
			}
			else
			{
				AppState.instance.screenMessage.ShowAlert(Translate.T("en.Confirmation", "de.Bestätigung", "confirmation.title"),
					Translate.T("en.Do you really want to cancel the creation of your entry? All changes would be lost.",
					"de.Willst Du die Erstellung Deines Eintrags wirklich abbrechen? Alle Änderungen gehen dabei verloren.", "confirmation.abortnewentry"),
					[{label: Translate.FindTranslation("answer.no"), value: 0}, {label: Translate.FindTranslation("answer.yes"), value: 1}], true, (resultButton) =>
				{
					if (resultButton && resultButton.value)
					{
						Tracking.OnPageView("provider/basic/cancel/" + si);

						this.OnCloseNewEntry();
					}
				});
			}
		}
		else
		{
			this.OnCloseNewEntry();
		}
	}

	OnGetListed = () =>
	{
		Tracking.OnPageView("provider/basic");
		this.showNewEntry = true;
	}

	renderNewEntry()
	{
		return (
			<Modal
				open={this.showNewEntry}
				onClose={this.OnModelCloseNewEntry}
				keepMounted={true}
			>
				<Slide in={this.showNewEntry} direction='up' timeout={300}>
					<div className="lpNewEntryContainer">
						<NewEntryPage
							onClose={this.OnCloseNewEntry}
							onInit={(instance) => {this.newEntryPage = instance;}}
						/>
					</div>
				</Slide>
			</Modal>
		);
	}

	renderOffer(p)
	{
		return (
			<div className={"ppOffer " + p.id + " " + AppState.instance.userProfile.userLang} id={"offer_" + p.id}>
				<div className="ppOfferHeader">
					<div className="ppOfferTitle">{p.title}</div>
					
					{p.price && <div className="ppOfferPrice">
						{p.pricePrefix && <span className="ppOfferPricingPrefix">{p.pricePrefix}</span>}
						{p.price}
					</div>}

					{p.priceAnnually && this.priceAnnually && <div className="ppOfferPrice annually">
						<div className="ppOfferPriceInnerContainer">
							{p.pricePrefix && <span className="ppOfferPricingPrefix">{p.pricePrefix}</span>}
							<span className="ppOfferEuro">&euro;</span>
							{p.priceAnnually}
							<span className="ppOfferPostfix">{Translate.T("de./ Monat", "en./ mo")}</span>
						</div>
					</div>}

					{p.priceMonthly && !this.priceAnnually && <div className="ppOfferPrice monthly">
						<div className="ppOfferPriceInnerContainer">
							{p.pricePrefix && <span className="ppOfferPricingPrefix">{p.pricePrefix}</span>}
							<span className="ppOfferEuro">&euro;</span>
							{p.priceMonthly}
							<span className="ppOfferPostfix">{Translate.T("de./ Monat", "en./ mo")}</span>
						</div>
					</div>}

					{p.priceSubtitle && <div className="ppOfferPriceSub">
						{p.priceSubtitle}
					</div>}
				</div>

				<div className="ppOfferBullets">
					<div className="ppOfferSubTitle ppOfferBullet">{p.subtitle}</div>

					{p.bullets.map((bullet, index) =>
					{
						return (
							<div className={"ppOfferBullet" + (index === p.bullets.length - 1 ? " last":"")} key={index}>
								{bullet}
							</div>
						);
					})}
				</div>

				<div className="ppOfferFooter">
					<Button className="ppOfferCta" onClick={p.onCta}>
						{p.cta}
					</Button>
				</div>
			</div>
		);
	}

	OnPriceAnnuallyChanged = () =>
	{
		Tracking.OnPageView("provider/changepricing");

		this.priceAnnually = !this.priceAnnually;
	}

	OpenLena = () =>
	{
		window.open("https://www.lena.app", '_blank');
	}

	OnPremiumOffer = () =>
	{
		Tracking.OnPageView("provider/premium");
		this.showPremium = true;
	}

	OnEnterpriseOffer = () =>
	{
		Tracking.OnPageView("provider/enterprise");
		this.showEnterprise = true;
	}

	OnPlusOffer = () =>
	{
		Tracking.OnPageView("provider/plus");
		this.showPlus = true;
	}

	render()
	{
		var _e0 = "connect";
		var _e1 = "@";
		var _e2 = "lena.app";

		const menuOptions = {
			isOpen: this.isLanguageMenuOpen,
			close: this.OnLanguageMenuClose,
			toggle: (<Button className="lpHeaderItem" onClick={this.OnLanguageMenuClicked}>
				{AppState.instance.userProfile.userLang === 'de' && Translate.T("de.Deutsch", "en.German", "lp.lang.german")}
				{AppState.instance.userProfile.userLang !== 'de' && Translate.T("de.Englisch", "en.English", "lp.lang.english")}
				<KeyboardArrowDown/>
			</Button>),
			align: 'right'
		};

		var eventPostfix = "_Mobile";
		if (AppState.instance.deviceInfo.desktop)
			eventPostfix = "_Desktop";

		return (
			<div className="lpContainer ppContainer" >
				{this.isLoading !== false && <div className="lpContainerLoader" 
					style={{
						width: '100vw',
						height: '100vh',
						backgroundColor: 'white',
						position: 'fixed',
						zIndex: '99999'
					}}
				/>}
				

				<div className="lpHeader lpHeader2">
					<div className="lpCol0" />

					<div className="lpCol1">
						<div className="lpBrand" onClick={this.OpenLena}>
							<div className="lpLogo">
								<img src="/assets/logons64.png" alt="logo" />
							</div>
							<div className="lpLogoText">
								LENA
							</div>
							<div className="lpLogoTextSub">
								Inspiring • Families
							</div>
						</div>
					</div>

					<div className="lpCol2 lpHeaderMain">
						<div className="lpHeaderItems">
							<Button className="lpHeaderItem" onClick={this.OnHeaderMenu('.ppSectionWho', -50)}>
								{Translate.T("en.Partner", "de.Partner", "pp.header.partner")}
							</Button>
							<Button className="lpHeaderItem" onClick={this.OnHeaderMenu('.ppSectionWhy', -70)}>
								{Translate.T("en.Features", "de.Features", "pp.header.features")}
							</Button>
							<Button className="lpHeaderItem lpHeaderCTA" onClick={this.OnHeaderMenu('.ppSectionHow', -70)}>
								{Translate.T("en.Get your activity listed", "de.Angebot eintragen", "lp.header.provider")}
							</Button>
							<Button className="lpHeaderItem" onClick={this.OnHeaderMenu('.ppSectionPricing')}>
								{Translate.T("en.Pricing", "de.Preise", "lp.header.pricing")}
							</Button>
							<Button className="lpHeaderItem" onClick={this.OnHeaderMenu('.lpFooter')}>
								{Translate.T("en.Contact", "de.Kontakt", "lp.header.contact")}
							</Button>
						</div>

						<div className="lpHeaderLanguages">
							<DropdownMenu className="lpLanguageMenu" {...menuOptions}>
								<li>
									<Button className={"lpLanguageMenuButton"} onClick={this.OnLanguageSelected('de')}>
										{Translate.T("de.Deutsch", "en.German", "lp.lang.german")}
									</Button>
								</li>
								<li>
									<Button className={"lpLanguageMenuButton"} onClick={this.OnLanguageSelected('en')}>
										{Translate.T("de.Englisch", "en.English", "lp.lang.english")}
									</Button>
								</li>
							</DropdownMenu>
						</div>
					</div>					
				</div>

				<div className="lpSection lpSection0">

					<div className="lpBackgroundImage" />

					<div className="lpCol0" />

					<div className="lpCol1" />

					<div className="lpCol2">
						<div className="lpMktClaim">
							{Translate.T("en.Simple. Quick.\nGet your activity listed on LENA.",
								"de.Einfach. Schnell.\nEuer Angebot auf LENA.", "providerpage.marketingclaim")}
						</div>

						<Button onClick={this.OnHeaderMenu('.ppSectionHow', -70)} className="lpButtonLearnMore">
							{Translate.T("en.Get listed", "de.Angebot eintragen", "providerpage.getlisted")}
						</Button>
					</div>

				</div>

				<div className="lpSection ppSectionWho">

					<div className="lpCol0" />

					<div className="lpCol1" >
						<div className="ppLine">
							{Translate.T("de.LENA hilft Betreibern von Ausflugszielen, Kursanbietern, Event-Veranstaltern und Gastronomiebetrieben ihr Angebot Familien vorzustellen.",
								"en.LENA helps providers of family events, outings and classes as well as family-friendly gastronomy to introduce their offers to families.",
								"pp.intro.0")}
						</div>
						<div className="ppLine">
							{Translate.T("de.LENA hilft auch gerne Webportalen und Bloggern, ihre Content-Reichweite zu erhöhen.",
								"en.LENA is also happy to help webportals and bloggers to increase their content's range of influence.",
								"pp.intro.1")}
						</div>
						<div className="ppLine">
							{Translate.T("de.LENA liegt am Herzen, nur familienfreundlichen Content anzubieten. Dabei ist LENA offen für neue Ideen und Kooperationen!",
								"en.It's important for LENA to only provide family-friendly content. Other than that, LENA is very open for new ideas and cooperations!",
								"pp.intro.2")}
						</div>
					</div>

					<div className="lpCol2" >
						<img src="/assets/collection.png" className="ppCollection" alt="cards collection" />
					</div>
				</div>

				<div className="lpSection ppSectionWhy">

					<img src="/assets/screens.png" className="ppScreens" alt="screenshots" />

					<div className="ppSectionWhyRight">
						<h2 className="ppSectionTitle">
							{Translate.T("de.Warum Euer Angebot bei LENA eintragen?",
								"en.Why get listed on LENA?", "pp.why.intro")}
						</h2>
						<ul className="ppBullets">
							<li>{Translate.T("de.Mit LENA erreicht Ihr kostenlos neue Familien", "en.Reach new families for free", "pp.why.0")}</li>
							<li>{Translate.T("de.Mit LENA könnt Ihr Euer Angebot in einem wunderschönen, modernen Stil präsentieren", "en.Present your offers in a wonderful and modern style", "pp.why.1")}</li>
							<li>{Translate.T("de.LENA verbindet Euch mit Tausenden Familien und Touristen in Österreich", "en.LENA connects you with thousands of families and tourists in Austria", "pp.why.2")}</li>
							<li>{Translate.T("de.LENA hält Euren Eintrag selbstständig aktuell", "en.LENA keeps your entries up to date - automatically", "pp.why.3")}</li>
							<li>{Translate.T("de.LENA wird zu Eurem mächtigen Marketing-Tool", "en.LENA becomes your powerful marketing tool", "pp.why.4")}</li>
						</ul>
					</div>

				</div>

				<div className="lpSection ppSectionHow">

					<div className="lpCol0" />

					<div className="lpCol12">
						<h2 className="ppSectionTitle">
							{Translate.T("Wie funktioniert's?", "en.How to get listed?", "pp.how.intro")}
						</h2>
						<div className="ppLine">
							{Translate.T("de.Ganz im Stil von LENA geht es sehr schnell und einfach Dein Angebot einzutragen.",
								"en.With LENA it's quick and simple to get listed.", "pp.how.0")}
							<br/>
							{Translate.T("de.LENA verwendet modernste Technologie um Dir Zeit und Geld zu sparen.",
								"en.LENA uses cutting-edge technology to save you time and money.", "pp.how.1")}
						</div>
						<div className="ppLine">
							{Translate.T("en.It takes only a minute to create your free basic listing in 3 simple steps!",
								"de.Du brauchst nur eine Minute um Deinen kostenlosen Basiseintrag in 3 einfachen Schritten zu erstellen!", "providerpage.createfree.simple")}
						</div>

						<div className="ppCTAContainer">
							<Button className="ppCTA" onClick={this.OnGetListed}>
								{Translate.T("en.Create Free Basic Entry", "de.Kostenlosen Basiseintrag erstellen", "providerpage.getlisted")}
							</Button>
						</div>

					</div>
				</div>

				<div className="lpSection ppSectionPricing">

					<h2 className="ppSectionTitle">
						{Translate.T("de.Einfache und transparente Preise", "en.Simple and transparent pricing", "providerpage.pricing.title")}
					</h2>
					<h3 className="ppSectionSubtitle">
						{Translate.T("de.Maßgeschneiderte Pakete für jeden Bedarf", "en.Plans for businesses of every shape and size", "providerpage.pricing.subtitle")}
					</h3>

					<div className="ppPriceSwitch">
						<span onClick={() => {this.priceAnnually = false;}}>
							{Translate.T("de.Monatlich", "en.Monthly", "providerpage.pay.monthly")}
						</span>
						<Switch
							value="checkedPrice"
							color="primary"
							checked={this.priceAnnually}
          					onChange={this.OnPriceAnnuallyChanged}
						/>
						<span onClick={() => {this.priceAnnually = true;}}>
							{Translate.T("de.Jährlich günstiger", "en.Annual Bonus", "providerpage.pay.annual")}
						</span>
					</div>

					<div className="ppOffers">
						{this.renderOffer(
							{
								id: "basic",
								title: Translate.T("de.Basic", "en.Basic", "offer.free.title"),
								subtitle: Translate.T("de.Präsentiere Dein Angebot der LENA Community",
									"en.Present your offer to the LENA community", "offer.free.subtitle"),
								price: Translate.T("de.Kostenloser Basiseintrag", "en.Free Basic Entry", "offer.free.price"),
								bullets: [
									Translate.T("de.Wunderschöne Präsentation Deines Angebots auf LENA", "en.Wonderful presentation of your offer on LENA", "offer.free.bullet0"),
									Translate.T("de.Vergrößerung Deiner Reichweite", "en.Increase your reach", "offer.free.bullet1"),
									//Translate.T("de.Automatische Suchmaschinenoptimierung", "en.Automatic search engine optimization", "offer.free.bullet2"),
									Translate.T("de.Bewertungen von Familien", "en.Receive reviews from families", "offer.free.bullet3"),
								],
								cta: Translate.T("de.Jetzt Eintrag erstellen", "en.Create entry now", "offer.free.cta"),
								onCta: this.OnGetListed
							}
						)}

						{this.renderOffer(
							{
								id: "plus",
								title: Translate.T("de.Marketing Plus", "en.Marketing Plus", "offer.mkt.plus.title"),
								subtitle: Translate.T("de.Umsätze ohne Aufwand steigern", "en.Increase revenue without effort", "offer.mkt.plus.subtitle"),
								priceMonthly: 99,
								priceAnnually: 79,
								bullets: [
									Translate.T("de.Alles vom Basic-Paket +", "en.All Basic features +", "offer.plus.bullet0"),

									Translate.T("de.Vermarkte Deinen gesamten Content auf LENA, inkl. Veranstaltungen, Workshops etc.",
										"en.Market all your family content on LENA, incl. events and workshops", "offer.plus.bullet1"),
									Translate.T("de.Automatischer Marketing-Boost für Deine Veranstaltungen (ab 2 Tagen vor Veranstaltung, bis 10.000 Impressions pro Monat)",
										"en.Automatic marketing boost for your events (starting 2 days before the event, up to 10.000 monthly impressions)", "offer.plus.bullet2"),
									Translate.T("de.Automatische Aktualisierung Deiner Daten durch LENA",
										"en.Automatic update of your content and offers by LENA", "offer.plus.bullet3"),
									Translate.T("de.Hochauflösende Videos und unlimitierte Fotos",
										"en.High-resolution videos and unlimited photos", "offer.plus.bullet4"),
									
									//Translate.T("de.Detailierte Statistiken, automatische Reports", "en.Detailed statistics, automatic reports", "offer.plus.bullet3"),
									//Translate.T("de.Anonyme Bewertungen", "en.Receive anonymous reviews", "offer.plus.bullet4"),
								],
								cta: Translate.T("de.Starte kostenlosen Test-Monat", "en.Start free trial", "offer.plus.cta"),
								onCta: this.OnPlusOffer
							}
						)}

						{this.renderOffer(
							{
								id: "premium",
								title: Translate.T("de.Marketing Premium", "en.Marketing Premium", "offer.premium.title"),
								subtitle: Translate.T("de.Unlimitierte, maßgeschneiderte Vermarktung", "en.Unlimited, customized Marketing", "offer.premium.subtitle"),
								priceMonthly: 219,
								priceAnnually: 169,
								//pricePrefix: Translate.T("de.ab", "en.from", "offer.premium.priceprefix"),
								priceSubtitle: (this.priceAnnually ? Translate.T("de.Setupkosten: entfallen", "en.Setup costs: omitted", "offer.premium.priceprefix") : Translate.T("de.Setup: € 299 einmalig", "en.Setup: € 299 one-off payment", "offer.premium.priceprefix")),
								bullets: [
									Translate.T("de.Alles vom Plus-Paket +", "en.All Plus features +", "offer.premium.bullet0"),

									Translate.T("de.Unlimitierter Marketing-Boost Deiner Veranstaltungen",
										"en.Unlimited marketing boost for your events", "offer.premium.bullet1"),
									Translate.T("de.Branding Deiner Angebote mit Deiner IP (Logos, Farben, Hintergrundbilder,...)",
										"en.Brand your offers with your own IP (logos, colors, graphics,...)", "offer.premium.bullet2"),
									Translate.T("de.Bewerbung Deiner Veranstaltungen über unsere Social Media Kanäle",
										"en.Market your offers using LENA's social media channels", "offer.premium.bullet3"),
									Translate.T("de.Automatische Suchmaschinenoptimierung", "en.Automatic search engine optimization", "offer.free.bullet4"),
									Translate.T("de.Bewerbung Deiner Mobile Native-App auf LENA",
										"en.Advertise your native mobile app with LENA", "offer.premium.bullet5"),
									
									//Translate.T("de.Automatischer monatlicher Report", "en.Automatic monthly reporting", "offer.premium.bullet6"),
								],
								cta: Translate.T("de.Starte kostenlosen Test-Monat", "en.Start free trial", "offer.premium.cta"),
								onCta: this.OnPremiumOffer
							}
						)}

						{this.renderOffer(
							{
								id: "enterprise",
								title: Translate.T("de.Zusatzpakete", "en.Additional Modules", "offer.enterprise.title"),
								subtitle: Translate.T("de.eCommerce Plattform", "en.eCommerce Platform", "offer.enterprise.subtitle"),
								price: Translate.T("de.Nach Absprache", "en.On Request", "offer.enterprise.price"),
								bullets: [

									Translate.T("de.Alles vom Premium-Paket + Kombination von:", "en.All Premium features + any of:", "offer.enterprise.bullet0"),
									Translate.T("de.Maßangefertigte Präsentation auf LENA", "en.Custom presentation on LENA", "offer.enterprise.bullet1"),
									Translate.T("de.Verwaltung von Kursen und Teilnehmern", "en.Managing classes and attendees", "offer.enterprise.bullet2"),
									Translate.T("de.Online-Verkauf von Tickets, Anzeige von Restplätzen", "en.Online ticket sales", "offer.enterprise.bullet3"),
									Translate.T("de.Schnittstellenanbindung (REST-Api)", "en.Online data interface (REST-Api)", "offer.enterprise.bullet4"),
									Translate.T("de.On-Site Informationen (Tourguide, Interaktive Karte,...)", "en.On-site information (tourguide, interactive maps etc.)", "offer.premium.bullet5"),
									Translate.T("de.Gewinnspiele", "en.Raffles", "offer.premium.bullet6"),
									Translate.T("de.Produkt-Promotionen", "en.Product promotions", "offer.premium.bullet7"),
									Translate.T("de.Detailierte Statistiken, monatliche Performance Reports", "en.Detailed statistics, monthly performance reports", "offer.enterprise.bullet8"),
								],
								cta: Translate.T("de.Angebot unverbindlich Anfragen", "en.Request offer", "offer.enterprise.cta"),
								onCta: this.OnEnterpriseOffer
							}
						)}
					</div>

				</div>

				<div className="lpFooter">
					<div className="lpCol0" />

					<div className="lpCol1">
						<div className="lpMissionBrand">
							<div className="lpMissionBrandTitle">
								LENA
							</div>
							<div className="lpMissionBrandSub">
								Inspiring • Families
							</div>
						</div>
					</div>

					<div className="lpCol2">

						<div className="lpFooterAbout">
							<div className="lpFooterSectionTitle">
								{Translate.T("en.Company", "de.Über LENA", "lp.footer.about")}
							</div>
							<div className="lpFooterSectionContent">
								<a href="https://blog.lena.app" target="_blank" className="lpFooterBlog">
									{Translate.T("en.Blog", "de.Blog", "lp.footer.blog")}
								</a>
								<a href="https://blog.lena.app/press" target="_blank" className="lpFooterPress">
									{Translate.T("en.Press", "de.Presse", "lp.footer.press")}
								</a>
								<a href="https://blog.lena.app/team" target="_blank" className="lpFooterTeam">
									{Translate.T("en.Team", "de.Team", "lp.footer.team")}
								</a>
							</div>
						</div>

						<div className="lpFooterLegal">
							<div className="lpFooterSectionTitle">
								{Translate.T("en.Legal", "de.Rechtliches", "lp.footer.legal")}
							</div>
							<div className="lpFooterSectionContent">
								<Button onClick={this.OnTos}>
									{Translate.T('en.Terms of Service', 'de.Nutzungsbedingungen', 'tos')}
								</Button><br/>
								<Button onClick={this.OnPP}>
									{Translate.T('en.Privacy Policy', 'de.Datenschutzbestimmungen', 'privacypolicy')}
								</Button><br/>
								<Button onClick={this.OnImprint}>
									{Translate.T('en.Imprint', 'de.Impressum', 'imprint')}
								</Button>
							</div>
						</div>

						<div className="lpFooterContact">
							<div className="lpFooterSectionTitle">
								{Translate.T("en.Contact us", "de.Kontakt", "lp.footer.contact")}
							</div>
							<div className="lpFooterSectionContent">
								<Button className="lpFooterContactEmail" onClick={this.OnEmail}><span>{_e0}</span><span>{_e1}</span><span>{_e2}</span></Button>
								{/* <Button className="lpFooterContactPhone">+43 (0)680 210 79 80</Button> */}
								<Button className="lpFooterContactAddr">{"Birkenweg 10\n2380 Perchtoldsdorf\nAustria"}</Button>
							</div>
						</div>
					</div>
				</div>

				{this.renderNewEntry()}

				<BusinessMessage
					open={this.showPremium}
					onClose={() => {this.showPremium = false;}}
					type="premium"
					title={Translate.T("de.Kostenlosen Test-Monat starten", "en.Start free trial month", "providerPage.premiuminquiry.title")}
					subtitle={Translate.T("en.Thanks for your interest in our Premium package.\nPlease fill out the form below to start your trial month.",
						"de.Vielen Dank für Dein Interesse an unserem Premium-Paket.\nBitte fülle dieses Formular aus um Deinen kostenlosen Test-Monat zu starten.",
						"providerPage.premiuminquiry.subtitle")}
					checkboxTitle={Translate.T("en.I'ld like to try...", "de.Ich möchte testen...", "providerPage.premiuminquiry.checkboxtitle")}
					checkboxes={[
						{
							id: "marketingtool",
							label: Translate.T("en.Marketing-Tool", "de.Marketing-Tool", "providerPage.premiuminquiry.checkbox.marketingtool")
						},
						{
							id: "raffles",
							label: Translate.T("en.Raffles and Games", "de.Gewinnspiele", "providerPage.premiuminquiry.checkbox.raffles")
						},
						{
							id: "promotions",
							label: Translate.T("en.Product Promotions", "de.Produktwerbung", "providerPage.premiuminquiry.checkbox.promotions")
						},
						{
							id: "cooperations",
							label: Translate.T("en.Cooperations with other hosts", "de.Kooperationen mit anderen Anbietern", "providerPage.premiuminquiry.checkbox.cooperations")
						},
					]}
					showOther={true}
					phoneOptional={true}
					isRequest={true}
					optionalMessage={true}
					trackingEvent={{
						category: 'Listing',
						action: 'Listing_Sub_Premium' + eventPostfix,
					}}
				/>

				<BusinessMessage
					open={this.showPlus}
					onClose={() => {this.showPlus = false;}}
					type="plus"
					title={Translate.T("de.Kostenlosen Test-Monat starten", "en.Start free trial month", "providerPage.plusinquiry.title")}
					subtitle={Translate.T("en.Thanks for your interest in our Plus package.\nPlease fill out the form below to start your free trial month.",
						"de.Vielen Dank für Dein Interesse an unserem Plus-Paket.\nBitte fülle dieses Formular aus um Deinen kostenlosen Test-Monat zu starten.",
						"providerPage.plusinquiry.subtitle")}
					showOther={false}
					phoneOptional={true}
					isRequest={true}
					disableMessage={true}
					trackingEvent={{
						category: 'Listing',
						action: 'Listing_Sub_Plus' + eventPostfix,
					}}
				/>

				<BusinessMessage
					open={this.showEnterprise}
					onClose={() => {this.showEnterprise = false;}}
					type="enterprise"
					title={Translate.T("de.Unverbindliche Angebotsanfrage", "en.Request Nonbinding Offer", "providerPage.enterpriseinquiry.title")}
					subtitle={Translate.T("en.Thanks for your interest in our Enterprise package.\nPlease fill out the form below to reveive your nonbinding offer.",
						"de.Vielen Dank für Dein Interesse an unserem Enterprise-Paket.\nBitte fülle dieses Formular aus um Dein unverbindliches Angebot zu erhalten.",
						"providerPage.enterpriseinquiry.subtitle")}
					checkboxTitle={Translate.T("en.I'm interested in...", "de.Mich interessiert...", "providerPage.enterpriseinquiry.checkboxtitle")}
					checkboxes={[
						{
							id: "marketingtool",
							label: Translate.T("en.Marketing-Tool", "de.Marketing-Tool", "providerPage.enterpriseinquiry.checkbox.marketingtool")
						},
						{
							id: "events_workshops",
							label: Translate.T("en.Managing Events and Workshops", "de.Verwalten von Veranstaltungen & Workshops", "providerPage.enterpriseinquiry.checkbox.events_workshops")
						},
						{
							id: "custompresentation",
							label: Translate.T("en.Custom Presentation on LENA", "de.Maßangefertigte Präsentation auf LENA", "providerPage.enterpriseinquiry.checkbox.custompresentation")
						},
						{
							id: "classes",
							label: Translate.T("en.Managing Classes", "de.Verwaltung von Kursen und Teilnehmern", "providerPage.enterpriseinquiry.checkbox.classes")
						},
						{
							id: "ticketsales",
							label: Translate.T("en.Online-Ticket Sales", "de.Online-Verkauf von Tickets", "providerPage.enterpriseinquiry.checkbox.ticketsales")
						},
						{
							id: "raffles",
							label: Translate.T("en.Raffles and Games", "de.Gewinnspiele", "providerPage.enterpriseinquiry.checkbox.raffles")
						},
						{
							id: "promotions",
							label: Translate.T("en.Product Promotions", "de.Produktwerbung", "providerPage.enterpriseinquiry.checkbox.promotions")
						},
						{
							id: "cooperations",
							label: Translate.T("en.Cooperations with other hosts", "de.Kooperationen mit anderen Anbietern", "providerPage.enterpriseinquiry.checkbox.cooperations")
						},
						{
							id: "data_interface",
							label: Translate.T("de.Schnittstellenanbindung (REST-Api)", "en.Online Data Interface (REST-Api)", "providerPage.enterpriseinquiry.checkbox.data_interface")
						},
					]}
					showOther={true}
					phoneOptional={true}
					isRequest={true}
					trackingEvent={{
						category: 'Listing',
						action: 'Listing_Sub_Enterprise' + eventPostfix,
					}}
				/>

				<Dialog
					fullScreen={false}
					open={this.dialogOpen}
					onClose={this.HandleDialogRequestClose}
					className={"webcontentDialogLP"}
				>
					{<AppBar>
						<Toolbar>
							<IconButton color="primary" onClick={this.HandleDialogRequestClose} aria-label="Close"
								classes={{root: this.props.classes.closeButtonRoot}}
							>
								<CloseIcon/>
							</IconButton>
							<Typography type="title" color="inherit" className={this.props.classes.dialogTitle}>
								{this.dialogTitle}
							</Typography>
						</Toolbar>
					</AppBar>}

					{this.dialogContent}
				</Dialog>
				
				<div className="ppAppContainer">
					<App appState={this.appState}/>
				</div>
			</div>
		);
	}
}

ProviderPage.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(ProviderPage);