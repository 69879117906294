import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import Utils from '../Utils';
import Translate from '../Translate';
import AppState from '../AppState';
import Direction from '../components/swing/Direction';
import DetectSwipe from '../DetectSwipe';
import Tracking from '../Tracking';
import { Button } from 'material-ui';

const styles = theme => ({	
});

@observer
class Tutorial extends React.Component
{
	static stepDone = 0;
	static idCounter = -10000;
	static topCardLeft;
	static topCardLeftId;
	static topCardRight;
	static topCardRightId;
	static halfDistance = 100;
	static swipeDuration = 300;
	static coverLifted = false;

	static Init()
	{
		// for (var i = 0; i < localStorage.length; i++)
		// {
		// 	console.log("LS: " + localStorage.key(i) + ": " + localStorage.getItem(localStorage.key(i)));
		// }
		// console.log("tutorialdone2: " + Utils.GetLocalStorage("tutorialdone2", "false"));


		if (Utils.GetLocalStorage("tutorialdone2", "false") === "true" ||
			AppState.instance.isLandingPage  ||
			AppState.instance.startedWithActivity ||
			Utils.IsStandaloneMode())
		{
			Tutorial.stepDone = -1;
		}
		else
		{
			Tutorial.stepDone = 0;
		}

		//console.log("Tutorial.stepDone: " + Tutorial.stepDone);

		//TEMP: tutorial disabled
		//Tutorial.stepDone = -1;
	}

	static HasFinishedSwipeTutorial()
	{
		return Utils.GetLocalStorage("tutorialdone2", "false") === "true";
	}

	static CreateMessages(content, step)
	{
		var result = [];

		// result.push({
		// 	id: Tutorial.idCounter++,
		// 	isCommand: true,
		// 	command: "response.start",
		// 	sender: "LENA",
		// 	isTutorial: true,
		// 	tutorialStep: step,
		// });

		result.push({
			id: Tutorial.idCounter++,
			isCommand: true,
			command: "activity.suggestions",
			sender: "LENA",
			message: JSON.stringify([content]),
			resultItems: [content],
			isTutorial: true,
			tutorialStep: step,
		});

		// result.push({
		// 	id: Tutorial.idCounter++,
		// 	isCommand: true,
		// 	command: "response.end",
		// 	sender: "LENA",
		// 	isTutorial: true,
		// 	tutorialStep: step,
		// });

		return result;
	}

	static GetTutorialMessages()
	{
		if (!Tutorial.IsActive())
		{
			return [];
		}

		var result = [];

		result = result.concat(Tutorial.CreateMessages({"activity":
			{"id":99990,"reporterId":4,"name":"Garten am Berg","desc":"Ein wundervoller Garten für die ganze Familie",
			"pictureUrls":["https://res.cloudinary.com/lenafamily/image/upload/v1527886734/4/msg3rh20sirayh3tffxi.jpg"],"infoUrl":"https://www.lena.app","price":"€€\nErwachsene € 6,-","latitude":48.2138678,"longitude":16.9374793,"address":"Am Berg, 1100 Wien","country":"AT","state":"Wien","rating":3.2,"reducedFamilyPrice":true,"birthdayParty":false,"carParking":true,"freeEntry":false,"lookout":false,"strollerSuitable":true,"greatForToddlers":false,"accessible":true,"childcare":false,"bookingRequired":false,"dogsWelcome":false,"lastChanged":"2018-01-01T21:46:19.0000000"},
			"url":"/activity/Museumamberg",
			"categories":["Museum"],
			"distance":{"distanceValue":60999,"distanceText":"ca. 61 km","durationValue":2389,"durationText":"ca. 40 min","travelMode":"driving"},
			"matchIndicator":0.5,"matchConfidence":1.0
		}, 0));

		result = result.concat(Tutorial.CreateMessages({"activity":
			{"id":99991,"reporterId":4,"name":"Erlebnispark Mödling","desc":"Erholung im Grünen für die ganze Familie",
			"pictureUrls":["https://res.cloudinary.com/lenafamily/image/upload/v1529959022/2/ocbic61oe5hwmuntcdq1.jpg"],"infoUrl":"https://www.lena.app","price":"€€\nErwachsene € 6,-","latitude":48.2138678,"longitude":16.9374793,"address":"Am Waldrand, 5020 Salzburg","country":"AT","state":"Salzburg","rating":4.6,"reducedFamilyPrice":true,"birthdayParty":false,"carParking":true,"freeEntry":false,"lookout":false,"strollerSuitable":true,"greatForToddlers":false,"accessible":true,"childcare":false,"bookingRequired":false,"dogsWelcome":true,"lastChanged":"2018-01-01T21:46:19.0000000"},
			"url":"/activity/Naturpark",
			"categories":["Naturpark"],
			"distance":{"distanceValue":60999,"distanceText":"ca. 61 km","durationValue":2389,"durationText":"ca. 40 min","travelMode":"driving"},
			"matchIndicator":0.94,"matchConfidence":1.0
		}, 1));

		return result;
	}

	static OnCardThrowout(msg)
	{
	}

	static IsActive()
	{
		if (AppState.instance.isLandingPage)
			return false;
			
		return Tutorial.stepDone >= 0;
	}

	static Start()
	{
		console.log("Tutorial.Start");
		Tracking.SendEvent("tutorial0");

		Tutorial.updateInterval = setInterval(() =>
		{
			Tutorial.Update();
		}, 100);

		setTimeout(() => {
			Tutorial.topCardLeft.dragTo(-Tutorial.halfDistance, 0, Tutorial.swipeDuration);	
		}, 600);
	}

	static OnSwipePageActivate()
	{
		setTimeout(() => {
			Tutorial.topCardLeft.dragTo(-Tutorial.halfDistance, 0, Tutorial.swipeDuration);	
		}, 600);
	}

	static EndUpdateInterval()
	{
		if (Tutorial.updateInterval)
		{
			clearInterval(Tutorial.updateInterval);
			Tutorial.updateInterval = undefined;
		}
	}

	static End(eventName)
	{
		console.log("Tutorial.End");

		Tutorial.EndUpdateInterval();

		//if (AppState.instance.isProduction)
		Utils.SetLocalStorage("tutorialdone2", "true");

		Tutorial.stepDone = -1; // done with it
		Tracking.SendEvent(eventName || "tutorial_end");

		AppState.instance.appInstance.OnTutorialEnded();
	}

	static Update()
	{
		var topCardId = AppState.instance.swipePageInstance.GetTopContentId();
		if (Tutorial.stepDone === 1 && Tutorial.topCardLeftId !== topCardId)
		{
			Tutorial.EndUpdateInterval();

			Tutorial.topCardRightId = topCardId;
			Tutorial.topCardRight = AppState.instance.swipePageInstance.stack.GetTopCard();

			Tutorial.stepDone = 2;
			Tracking.SendEvent("tutorial2");
			setTimeout(() => {
				Tutorial.topCardRight.dragTo(Tutorial.halfDistance, 0, Tutorial.swipeDuration);	
			}, 600);
			AppState.instance.appContainer.forceUpdate();
		}
	}

	static OnCoverLifted()
	{
		if (Tutorial.stepDone < 0)
			return;

		//console.log("============== OnCoverLifted");
		Tutorial.coverLifted = true;
		if (Tutorial.topCardLeft/* &&
			AppState.instance.isShowingDetailPage === 0 &&
			!AppState.instance.startedWithActivity*/)
			Tutorial.Start();
	}

	static SetTopCard(card, cardId)
	{
		//console.log("============== setting TopCard of Tutorial: Tutorial.stepDone");
		var wasSet = Tutorial.topCardLeft !== undefined;
		if (Tutorial.stepDone === 0)
		{
			//console.log("     new topleft card");
			Tutorial.topCardLeft = card;
			Tutorial.topCardLeftId = cardId;
		}

		if (!wasSet && Tutorial.coverLifted /*&&
			AppState.instance.isShowingDetailPage === 0 &&
			!AppState.instance.startedWithActivity*/)
		{
			setTimeout(() => {
				Tutorial.Start();
			}, 300);
		}
	}

	shouldComponentUpdate(nextProps, nextState)
	{
		return true;
	}

	OnNextStepClicked = () =>
	{
		// using setTimeout to prevent passing this input event to the BottomTutorial
		setTimeout(() => {
			console.log("OnNextStepClicked");

			if (Tutorial.stepDone === 0)
			{
				Tutorial.topCardLeft.dragTo(-500, 0, Tutorial.swipeDuration, -100, 0);
				setTimeout(() => {
					Tutorial.topCardLeft.throwOut(-500, 0, Direction.LEFT);
				}, Tutorial.swipeDuration);
	
				Tutorial.stepDone = 1;
				Tracking.SendEvent("tutorial1");
			}
			else if (Tutorial.stepDone === 2)
			{
				Tutorial.topCardRight.dragTo(500, 0, Tutorial.swipeDuration, 100, 0);
				setTimeout(() => {
					Tutorial.topCardRight.throwOut(500, 0, Direction.RIGHT);
				}, Tutorial.swipeDuration);
	
				Tutorial.stepDone = 3;
				Tracking.SendEvent("tutorial3");
				Tutorial.End();	
			}
	
			AppState.instance.appContainer.forceUpdate();
		}, 10);
	}

	static SkipTutorial = (e) =>
	{
		e.preventDefault();
		e.stopPropagation();

		setTimeout(() => {
			Utils.SetLocalStorage("bottomtutorialdone", "true"); // skip the second part as well
			Tutorial.End("tutorial_skip");

			AppState.instance.swipePageInstance.OnTutorialSkipped();
		}, 10);
	}

	static renderTooltip(step)
	{
		return (
			<div className={"tutorialTooltip step" + step}>
				{step === 0 && Translate.T("en.Swipe suggestions to the left if you don't like them.",
					"de.Swipe Vorschläge nach Links, wenn sie dir nicht gefallen.", "tutorial.tooltip.step0")}
				{step === 1 && Translate.T("en.Swipe suggestions to the right if you like them!",
					"de.Swipe Vorschläge nach Rechts, wenn sie dir gefallen!", "tutorial.tooltip.step1")}

				<div className="skip">
					<Button onClick={Tutorial.SkipTutorial}>
						{Translate.T("de.Tutorial überspringen", "en.Skip Tutorial", "tutorial.skip")}
					</Button>
				</div>
			</div>
		);
	}

	OnSwipe = (dir) =>
	{
		this.OnNextStepClicked();
	}

	render()
	{
		//0... {START} tooltip + start ani left
		//1... finish ani left
		//2... tooltip + start ani right
		//3... finish ani right => {END}
		//-1... {END}

		//console.log("Tutorial.render: " + Tutorial.stepDone);

		if (!Tutorial.IsActive() ||
			AppState.instance.currentMainTab !== AppState.TabIndexSwipe ||
			AppState.instance.isShowingDetailPage > 0)
		{
			return null;
		}

		return (
			<DetectSwipe onSwipe={this.OnSwipe} className="tutorialContainer" onClick={this.OnNextStepClicked}>
				
				{Tutorial.stepDone === 0 && Tutorial.renderTooltip(0)}
				{Tutorial.stepDone === 2 && Tutorial.renderTooltip(1)}

			</DetectSwipe>
		);
	}
}

Tutorial.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(Tutorial);