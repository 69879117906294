import React, {Component} from 'react';
import {withStyles} from 'material-ui/styles';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import $ from 'jquery';
import SuggestionCard from '../components/SuggestionCard';
import AppState from '../AppState';
import Utils from '../Utils';
import CardStack from '../components/CardStack';

const styles = theme => ({
});

@observer
class ContentCardStack extends Component
{
	content = [];
	stack;

	mediaDraggingTimer;
	windowInnerWidth;
	windowInnerHeight;

	componentDidMount()
	{
		this.UpdatePageDimensions();
		var thisPtr = this;
		$(window).on('resize orientationchange', function() {
			thisPtr.UpdatePageDimensions();
		});
		this.UpdateContent(this.props.content);
	}

	componentDidUpdate()
	{
		this.UpdateContent(this.props.content);
	}

	UpdatePageDimensions()
	{
		setTimeout(() =>
		{
			this.windowInnerHeight = window.innerHeight;
			this.windowInnerWidth = window.innerWidth;

			var h = this.windowInnerHeight;
			$('#swipePage').css({'height': h - 56});
		}, 50);
	}

	OnActivate()
	{
		if (this.stack)
			this.stack.OnActivate();
	}

	OnDeactivate()
	{
		if (this.stack)
			this.stack.OnDeactivate();
	}

	OnStackInit = (stack) =>
	{
		this.stack = stack;

		if (this.props.onInit)
			this.props.onInit(this);
	}

	SetContentData(contentId, contentData)
	{
		var content = this.GetContentById(contentId);
		if (content)
		{
			content.contentData = contentData;

			//console.log("ContentCardStack.forceUpdate() from SetContentData");
			this.forceUpdate();
		}
	}

	GetContentById(id)
	{
		for (var i = 0; i < this.content.length; ++i)
		{
			if (this.content[i].id === id)
			{
				return this.content[i];
			}
		}
		return undefined;
	}

	IsBusy()
	{
		return (this.stack && this.stack.IsBusy());
	}

	UpdateContent(input, callForceUpdate)
	{
		// If the card pack is currently dragging or scrolling don't do background work as it destroys the UX (jitter,...)
		if (this.IsBusy())
		{
			setTimeout(() => {
				this.UpdateContent(input, true);
			}, 100);
			return;
		}

		var newContent = [];

		for (var i = 0; i < input.length && i <= 1; ++i)
		{
			var neededContent = input[i];

			var c = this.GetContentById(neededContent.id);
			if (!c)
			{
				//console.log("ContentCardStack: new content passed as prop:");
				//console.log(neededContent);

				var cachedContentData = AppState.instance.contentStore.FindContentData(neededContent.id);

				c = {
					id: neededContent.id,
					type: neededContent.type,
					onRender: neededContent.type === "activity" ? this.RenderActivityCard : neededContent.onRender,
					contentData: neededContent.type === "activity" ? cachedContentData : {},
					showScrollGradient: neededContent.showScrollGradient
				};	
			}

			newContent.push(c);
		}

		var changed = false;

		if (callForceUpdate !== false)
		{
			if (this.content.length !== newContent.length)
			{
				changed = true;
			}
			else
			{
				for (i = 0; i < this.content.length; ++i)
				{
					if (this.content[i].id !== newContent[i].id)
					{
						changed = true;
						break;
					}
				}
			}
		}

		this.content = newContent;

		// Trigger loading of top most two content items (if not already done)
		for (i = 0; i < this.content.length && i <= 1; ++i)
		{
			c = this.content[i];
			if (i <= 1 && (c.contentData === undefined) && (c.type === "activity"))
			{
				cachedContentData = AppState.instance.contentStore.FindContentData(c.id);
				if (cachedContentData)
				{
					c.contentData = cachedContentData;
					changed = true;
				}
				else
				{
					// Async loading needed (rare case as LENA-messages already contain the content details)
					this.GetContentData(c.id, c.type)
						.then((details) =>
						{
							if (details && details.activity)
								this.SetContentData(details.activity.id, details);
						});
				}
			}
		}

		if (changed && callForceUpdate !== false)
		{
			//console.log("ContentCardStack.forceUpdate() from UpdateContent()");
			this.forceUpdate();
		}

		//console.log("updated content to: " + this.content.length);
	}

	GetContentData(contentId, contentType)
	{
		//TODO: handle other contentTypes too
		return AppState.instance.contentStore.GetActivityDetailsById(contentId);
	}

	OnDataInRendererChanged = (content) => (onlySizeChanged) =>
	{
		if (this.stack)
			this.stack.RefreshCard(content.id);

		if (onlySizeChanged !== true)
		{
			// Get new details from server
			AppState.instance.contentStore.GetActivityDetails(content.contentData.activity.name, (details) =>
			{
				if (details)
				{
					content.contentData = details;
					//console.log("ContentCardStack.forceUpdate() from OnDataInRendererChanged");
					this.forceUpdate();
					if (this.stack)
						this.stack.RefreshCard(content.id);
				}
			});
		}
	}

	OnCardMediaTouchStart = (contentId) => () =>
	{
		this.DoCardMediaTouchStart(contentId);
	}
	DoCardMediaTouchStart(contentId)
	{
		if (this.stack)
			this.stack.DisableDragging(contentId);
	}

	OnCardMediaSwitching = (contentId) => () =>
	{
		this.DoCardMediaSwitching(contentId);
	}
	DoCardMediaSwitching(contentId)
	{
		if (this.stack)
			this.stack.DisableDragging(contentId);

		this.mediaDraggingTimer = Utils.SetTimer(this.mediaDraggingTimer, 150, () =>
		{
			if (this.stack)
				this.stack.EnableDragging(contentId);
		}, 30);
	}

	OnCardMediaTouchEnd = (contentId) => () =>
	{
		this.DoCardMediaTouchEnd(contentId);
	}
	DoCardMediaTouchEnd(contentId)
	{
		if (this.stack)
			this.stack.EnableDragging(contentId);
	}

	OnDropDownMenuOpen = (content) => (menuName) =>
	{
		// Make sure there is enough space for the menu
		if (this.stack)
		{
			var minPosition = -210;
			if (menuName === "more")
				minPosition = -336;

			this.stack.ScrollTo(content.id, minPosition);
		}
	}

	ScrollToElement(selector, content)
	{
		var reviewPos = $("#swipePage .stack " + selector).offset().top - 
			$("#swipePage .stack .chatCardScroller").offset().top;

		this.stack.ScrollTo(content.id, -reviewPos);
	}

	OnTopRatingClicked = (content) => () =>
	{
		// Scroll to reviews
		this.ScrollToElement(".detailSectionReviews", content);
	}

	OnTopWeatherClicked = (content) => () =>
	{
		// Scroll to weather
		this.ScrollToElement(".detailSectionWeather", content);
	}

	RenderActivityCard = (content) =>
	{
		if (content.contentData === undefined)
			return null;
		if (content.contentData.activity === undefined)
			return null;

		return (
			<SuggestionCard
				contentData={content.contentData}
				columnMode={true}
				onChanged={this.OnDataInRendererChanged(content)}
				onMediaTouchStart={this.OnCardMediaTouchStart(content.id)}
				onMediaSwitching={this.OnCardMediaSwitching(content.id)}
				onMediaTouchEnd={this.OnCardMediaTouchEnd(content.id)}
				showBookmarkStatus={true}
				onBeforeLinkClicked={this.OnBeforeLinkClicked}
				onDropDownMenuOpen={this.OnDropDownMenuOpen(content)}
				onTopRatingClicked={this.OnTopRatingClicked(content)}
				onTopWeatherClicked={this.OnTopWeatherClicked(content)}
			/>
		);
	}

	OnThrowOutDecision = (content, direction) =>
	{
		if (this.props.content && this.props.onThrowOutDecision)
		{
			for (var i = 0; i < this.props.content.length; ++i)
			{
				if (this.props.content[i].id === content.id)
				{
					return this.props.onThrowOutDecision(this.props.content[i], direction);
				}
			}
		}

		return undefined;
	}

	OnThrowOutEnd = (content, direction) =>
	{
		if (this.props.content && this.props.onThrowOutEnd)
		{
			for (var i = 0; i < this.props.content.length; ++i)
			{
				if (this.props.content[i].id === content.id)
				{
					this.props.onThrowOutEnd(this.props.content[i], direction);
					break;
				}
			}
		}
	}

	NudgeCard()
	{
		if (this.stack)
			this.stack.NudgeCard();
	}

	Pose()
	{
		if (this.stack)
			this.stack.Pose();
	}

	OnLayoutChanged()
	{
		if (this.stack && this.content.length > 0)
			this.stack.RefreshCard(this.content[0].id);
	}

	UpdateTopCardElement()
	{
		if (this.stack)
			this.stack.UpdateTopCardElement();
	}

	GetTopCard()
	{
		if (this.stack)
			return this.stack.GetTopCard();
		return undefined;
	}

	render()
	{
		this.UpdateContent(this.props.content, false);

		//console.log("ContentCardStack content:");
		//console.log(this.content);

		return (
			<CardStack
				id={this.props.id}
				className={this.props.className}
				key={this.props.key}
				onInit={this.OnStackInit}
				cards={this.content}
				classNameCardWrapper={this.props.classNameCardWrapper}
				containerSelector={this.props.containerSelector}
				onThrowOutDecision={this.OnThrowOutDecision}
				onThrowOutEnd={this.OnThrowOutEnd}
				showBackgroundCards={this.props.showBackgroundCards}
				backgroundCardYOffset={this.props.backgroundCardYOffset}
			/>
		);
	}
}

ContentCardStack.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ContentCardStack);