import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import StarRatingComponent from 'react-star-rating-component';
import Utils from '../Utils';

const styles = theme => ({	
});

@observer
class Rating extends React.Component
{
	renderStarIcon = (index, value, name) =>
	{
		const starColor = this.props.starColor || '#e31c46';
		const fontSize = this.props.size || 14;

		if (!value) // no rating value?
			return Utils.RenderStarIcon(fontSize, fontSize, "#f0f0f0", "#f0f0f0", 1);
		else if (index <= value) // full stars?
			return Utils.RenderStarIcon(fontSize, fontSize, starColor, starColor, 1);
		else
			return Utils.RenderStarIcon(fontSize, fontSize, "#d8d8d8", "#d8d8d8", 1);
	}

	renderStarIconHalf = () =>
	{
		const fontSize = this.props.size || 14;
		const starColor = this.props.starColor || '#e31c46';

		return Utils.RenderStarIcon(fontSize, fontSize, starColor, starColor, 1, true, "#d8d8d8");
	}

	render()
	{
		var value = this.props.value || 0;
		value = Math.round(value * 2) / 2;

		var editing = this.props.editing || false;

		return (
			<StarRatingComponent
				key="rating"
				name="rate1" 
				starCount={this.props.starCount || 5}
				value={value}
				editing={editing}
				onStarClick={this.props.onStarClick}
				//starColor={'#888'}
				//emptyStarColor={'#ccc'}
				renderStarIcon={this.props.renderStarIcon || this.renderStarIcon}
				renderStarIconHalf={this.props.renderStarIconHalf || this.renderStarIconHalf}
			/>
		);
	}
}

Rating.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(Rating);