import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import AppState from '../AppState';
import SearchField from './SearchField';
import Button from 'material-ui/Button';
import ArrowBack from 'material-ui-icons/ArrowBack';
import SearchIcon from 'material-ui-icons/Search';
import ClearIcon from 'material-ui-icons/Clear';
import $ from 'jquery';
import Utils from '../Utils';
import Translate from '../Translate';
import { CircularProgress } from 'material-ui';
import {observable} from 'mobx';

const styles = theme => ({
});

@observer
class TopHeader extends React.Component
{
	@observable isLoading = 0;
	searchCategoryId;

	componentWillUnmount()
	{
		this.RemoveSearchCallback();
	}

	OnSearchDataChanged = (action, newData) =>
	{
		if (action === 'request')
		 	++this.isLoading;
		else if (action === 'data' || action === 'error' || action === 'abort' || action === 'eod' || action === 'flushed')
			this.isLoading = 0;
			 
		if (this.isLoading === 0)
		{
			this.RemoveSearchCallback();
		}
	}

	OnMapClicked = (e) =>
	{
		AppState.instance.appInstance.OnOpenMap(e);
	}

	OnArrowSearchClicked = () =>
	{
		this.RemoveSearchCallback();
		
		const searchHasFocus = AppState.instance.appInstance.searchHasFocus;
		const q = $("#searchInput").val();
		const hasQ = q && q.length > 0;

		const showCross = searchHasFocus && hasQ;
		var showSearch = searchHasFocus && !showCross;
		const showBackArrow = !searchHasFocus && (AppState.instance.filterStack.length > 0 || hasQ);

		if (!showBackArrow && !showCross && !showSearch)
			showSearch = true;
		const locationSearch = showSearch && AppState.instance.isInLocationSearch;
		if (locationSearch)
			showSearch = false;

		if (showCross && AppState.instance.isInLocationSearch)
		{
			$("#searchInput").val("");
			AppState.instance.contentStore.OnSearchCleared();
			AppState.instance.isInLocationSearch = false;
			AppState.instance.mainPageInstance.forceUpdate();
			AppState.instance.appInstance.OnLeaveSearch();
		}
		else if (showCross)
		{
			$("#searchInput").val("");
			AppState.instance.contentStore.OnSearchCleared();
			AppState.instance.appInstance.OnLeaveSearch();
			AppState.instance.appInstance.GoBack();
		}
		else if (showSearch || locationSearch)
		{
			if (q && q.length > 0)
			{
				$("#searchInput").focus();
			}
			else
			{
				if (AppState.instance.isInLocationSearch)
				{
					AppState.instance.isInLocationSearch = false;
					AppState.instance.appInstance.OnLeaveSearch();
				}
				else if (showSearch && !searchHasFocus)
				{
					$("#searchInput").focus();
				}
				else
				{
					AppState.instance.appInstance.OnLeaveSearch();
				}
			}
		}
		else
		{
			AppState.instance.appInstance.GoBack();
		}
		this.forceUpdate();
	}

	RemoveSearchCallback()
	{
		if (this.searchCategoryId)
		{
			AppState.instance.contentStore.RemoveOnDataChanged(this.searchCategoryId, this.OnSearchDataChanged);
			this.searchCategoryId = undefined;
		}
	}

	OnSearch = (val, onDone) =>
	{
		this.RemoveSearchCallback();
		this.searchCategoryId = AppState.instance.filterManager.CreateFilterId(AppState.instance.filterStack);
		AppState.instance.contentStore.AddOnDataChanged(this.searchCategoryId, this.OnSearchDataChanged);

		if (AppState.instance.appInstance.OnSearch)
			AppState.instance.appInstance.OnSearch(val, onDone);
		this.forceUpdate();
	}

	OnEnter = () =>
	{
		AppState.instance.appInstance.OnLeaveSearch();
	}

	GetBreadCrumbs()
	{
		if (AppState.instance.filterStack.length === 0)
			return undefined;

		var text = "";
		for (var i = 0; i < AppState.instance.filterStack.length; ++i)
		{
			const fId = AppState.instance.filterStack[i];
			const f = AppState.instance.filterManager.GetFilter(fId);
			if (f && f.showInSearchField)
			{
				if (text.length > 0)
					text += " • ";
				text += f.title;
			}
		}

		if (text.length === 0)
			return undefined;

		return text;
	}

	OnSearchBlur = () =>
	{
		this.RemoveSearchCallback();
		AppState.instance.appInstance.OnSearchBlur();
	}

	render()
	{
		var padding = 8;

		const searchHasFocus = AppState.instance.appInstance ? AppState.instance.appInstance.searchHasFocus : false;
		const q = $("#searchInput").val();
		const hasQ = q !== undefined && q.length > 0;

		var showCross = searchHasFocus && hasQ;
		var showSearch = searchHasFocus && !showCross;
		var showBackArrow = !searchHasFocus && (AppState.instance.filterStack.length > 0 || hasQ);

		if (!showBackArrow && !showCross && !showSearch)
			showSearch = true;

		var locationSearch = showSearch && AppState.instance.isInLocationSearch;
		if (locationSearch)
			showSearch = false;

		if (this.isLoading !== 0)
		{
			showBackArrow = false;
			locationSearch = false;
			showSearch = false;
			showCross = false;
		}

		var breadcrumbs = (showBackArrow || showSearch) ? this.GetBreadCrumbs() : undefined;
		if (locationSearch)
			breadcrumbs = Translate.FindTranslation("search.default.location");

		var iconKey = "";
		if (showBackArrow) iconKey += ",backarrow";
		if (locationSearch) iconKey += ",location";
		if (showSearch && !searchHasFocus) iconKey += ",showSearch && !searchHasFocus";
		if (showSearch && searchHasFocus) iconKey += ",showSearch && searchHasFocus";
		if (showCross) iconKey += ",cross";

		//console.log("____________ render topbar: " + iconKey);
		
		return (
			<div id="searchBarContainer" style={{ ...this.props.style, paddingBottom: '1px', zIndex: 2, overflow: 'hidden', backgroundColor: '#fbfbfb'}}>
				<div id="searchBar" style={{paddingLeft: padding, paddingRight: padding}} >

					<SearchField
						onSearch={this.OnSearch}
						onFocus={AppState.instance.appInstance.OnSearchFocus}
						onBlur={this.OnSearchBlur}
						onEnter={this.OnEnter}
						breadcrumbs={breadcrumbs}
					/>

					<div className="hideSearchClearBtn" />

					<Button className="mapButton" onClick={this.OnMapClicked}>
						<img alt="map" src={Utils.RenderMapUrl(24, 24, "white")} />
					</Button>

					<div className="searchFieldIcon" onClick={this.OnArrowSearchClicked} key={iconKey}>
						{showBackArrow && <ArrowBack className="arrowBack" />}

						{locationSearch && <ArrowBack className="searchIcon" />}

						{showSearch && !searchHasFocus && <SearchIcon className="searchIcon" />}
						{showSearch && searchHasFocus && <ArrowBack className="searchIcon" />}

						{showCross && <ClearIcon className="searchIcon searchClear" />}

						{this.isLoading !== 0 && <CircularProgress size={24} className="searchIcon loadingIcon" />}
					</div>

					
				</div>
			</div>
	  );
	}
}

TopHeader.propTypes =
{
	classes: PropTypes.object,
	theme: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(TopHeader);