/* eslint-disable no-useless-concat */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import $ from 'jquery';
import AppState from '../AppState';
import Translate from '../Translate';
import Button from 'material-ui/Button';
import BusinessMessage from './BusinessMessage';
import Dialog from 'material-ui/Dialog';
import IconButton from 'material-ui/IconButton';
import CloseIcon from 'material-ui-icons/Close';
import AppBar from 'material-ui/AppBar';
import Toolbar from 'material-ui/Toolbar';
import Typography from 'material-ui/Typography';
import {WebContentPage, ToSPage, PPPage, ImprintPage} from '../pages/WebContentPage';


const styles = {
	dialogTitle: {
		marginLeft: '8px',
	},
};

@observer
class LandingPageFooter extends React.Component
{
	dialogTitle;
	dialogContent;
	@observable dialogOpen = false;
	@observable showCoop = false;

	componentDidMount()
	{
		setInterval(() =>
		{
			var bodyOverflow = $("#body").css("overflow");
			if (bodyOverflow === "hidden")
			{
				if (!this.dialogOpen && !this.showCoop)
					$("#body").css("overflow", "visible");
			}
		}, 500);

		if (this.props.onInit)
			this.props.onInit(this);
	}

	OnWebContentClick = (msg) =>
	{
		if (msg === "open.pp")
		{
			this.OnPP();
			return false;
		}
		else if (msg === "open.tos")
		{
			this.OnTos();
			return false;
		}

		return true;
	}

	OnShowWebContent(comp, title)
	{
		if (this.dialogOpen)
			this.dialogOpen = false;

		WebContentPage.clickHook = this.OnWebContentClick;

		this.dialogTitle = title;
		var _props = {};
		_props.id = 'generalDialogContent';
		this.dialogContent = React.createElement(comp, _props, null);
		this.dialogOpen = true;
	}

	OnTos = () =>
	{
		this.OnShowWebContent(ToSPage, Translate.T('en.Terms of Service', 'de.Nutzungsbedingungen', 'tos'));
	}

	OnPP = () =>
	{
		this.OnShowWebContent(PPPage, Translate.T('en.Privacy Policy', 'de.Datenschutzbestimmungen', 'privacypolicy'));
	}

	OnImprint = () =>
	{
		this.OnShowWebContent(ImprintPage, Translate.T('en.Imprint', 'de.Impressum', 'imprint'));
	}

	HandleDialogRequestClose = () =>
	{
		WebContentPage.clickHook = undefined;
		this.dialogOpen = false;
	}

	OnEmail = () =>
	{
		window.location.href = 'mailto:' + 'connect' + '@' + 'lena.app';
	}

	OnCooperation = () =>
	{
		this.showCoop = true;
	}

	OnCloseCooperation = () =>
	{
		this.coopSuccess = false;
		this.showCoop = false;
	}

	OnOpenProvider = () =>
	{
		const url = AppState.instance.rootUrl + "/provider";
		window.open(url, '_blank');
	}

	render()
	{
		var _e0 = "connect";
		var _e1 = "@";
		var _e2 = "lena.app";

		var eventPostfix = "_Mobile";
		if (AppState.instance.deviceInfo.desktop)
			eventPostfix = "_Desktop";

		return (
			<div>
				<div className="lpFooter">
					<div className="lpCol0" />

					<div className="lpCol1">
						<div className="lpMissionBrand">
							<div className="lpMissionBrandTitle">
								LENA
							</div>
							<div className="lpMissionBrandSub">
								Inspiring • Families
							</div>
						</div>
					</div>

					<div className="lpCol2">

						<div className="lpFooterAbout">
							<div className="lpFooterSectionTitle">
								{Translate.T("en.Company", "de.Über LENA", "lp.footer.about")}
							</div>
							<div className="lpFooterSectionContent">
								<a href="https://blog.lena.app" target="_blank" className="lpFooterBlog">
									{Translate.T("en.Blog", "de.Blog", "lp.footer.blog")}
								</a>
								<a href="https://blog.lena.app/press" target="_blank" className="lpFooterPress">
									{Translate.T("en.Press", "de.Presse", "lp.footer.press")}
								</a>
								<a href="https://blog.lena.app/team" target="_blank" className="lpFooterTeam">
									{Translate.T("en.Team", "de.Team", "lp.footer.team")}
								</a>
								<div className="lpFooterSocial">
									<a href="https://www.linkedin.com/company/lenaapp/" target="_blank" className="linkedin">
										<img src="/assets/linkedin.png" alt="linkedin" />
									</a>
									<a href="https://www.facebook.com/lenaapp" target="_blank" className="facebook">
										<img src="/assets/facebook.png" alt="facebook" />
									</a>
									<a href="https://www.instagram.com/lena_family_app/" target="_blank" className="instagram">
										<img src="/assets/instagram.png" alt="instagram" />
									</a>
								</div>
							</div>
						</div>

						<div className="lpFooterLegal">
							<div className="lpFooterSectionTitle">
								{Translate.T("en.Legal", "de.Rechtliches", "lp.footer.legal")}
							</div>
							<div className="lpFooterSectionContent">
								<Button onClick={this.OnTos}>
									{Translate.T('en.Terms of Service', 'de.Nutzungsbedingungen', 'tos')}
								</Button><br/>
								<Button onClick={this.OnPP}>
									{Translate.T('en.Privacy Policy', 'de.Datenschutzbestimmungen', 'privacypolicy')}
								</Button><br/>
								<Button onClick={this.OnImprint}>
									{Translate.T('en.Imprint', 'de.Impressum', 'imprint')}
								</Button>
							</div>
						</div>

						<div className="lpFooterContact">
							<div className="lpFooterSectionTitle">
								{Translate.T("en.Contact us", "de.Kontakt", "lp.footer.contact")}
							</div>
							<div className="lpFooterSectionContent">
								<Button className="lpFooterContactEmail" onClick={this.OnOpenProvider}>
									{Translate.T("en.Get your activity listed", "de.Angebot eintragen", "lp.header.provider")}
								</Button>
								<Button className="lpFooterContactEmail" onClick={this.OnCooperation}>
									{Translate.T("en.Get in touch", "de.Kooperationsanfrage", "lp.footer.cooperation")}
								</Button>
								<Button className="lpFooterContactEmail" onClick={this.OnEmail}><span>{_e0}</span><span>{_e1}</span><span>{_e2}</span></Button>
								{/* <Button className="lpFooterContactPhone">+43 (0)680 210 79 80</Button> */}
								<Button className="lpFooterContactAddr">{"Birkenweg 10\n2380 Perchtoldsdorf\nAustria"}</Button>
							</div>
						</div>
					</div>

					{AppState.instance.isLandingPage && <div className="lpPhotoCredit">
						<a target="_blank" rel="noopener noreferrer" href="https://www.freepik.com/free-photos-vectors/people">People image created by Jcomp - Freepik.com</a>
					</div>}
				</div>

				<BusinessMessage
					key="cooperation"
					open={this.showCoop}
					onClose={this.OnCloseCooperation}
					type="cooperation"
					title={Translate.T("en.Cooperation Request", "de.Kooperationsanfrage", "lp.footer.cooperations.title")}
					subtitle={Translate.T("en.We'd love to hear about your idea for a business cooperation.\nPlease fill out the form below or send us an email.\nWe will get back to you asap.",
						"de.Wir würden uns freuen, von Ihrer Idee zu einer Kooperation zu hören.\nBitte senden Sie uns einfach dieses Formular oder schreiben uns eine Email.\nWir melden uns so schnell wie möglich.", "lp.footer.cooperations.text")}
					checkboxTitle={Translate.T("en.We are a...", "de.Wir sind ein...", "lp.footer.cooperations.businesstype.title")}
					checkboxes={[
						{
							id: "eventhost",
							label: Translate.T("en.Event Host", "de.Veranstalter", "businesstype.eventhost")
						},
						{
							id: "attractionhost",
							label: Translate.T("en.Attraction Host", "de.Ausflugszielbetreiber", "businesstype.eventhost")
						},
						{
							id: "mediaoutlet",
							label: Translate.T("en.Media Outlet", "de.Media Outlet", "businesstype.mediaoutlet")
						},
						{
							id: "blog",
							label: Translate.T("en.Blog", "de.Blog", "businesstype.blog")
						},
						{
							id: "ticketshop",
							label: Translate.T("en.Ticket Shop", "de.Ticket Shop", "businesstype.ticketshop")
						},
						{
							id: "gastro",
							label: Translate.T("en.Restaurant, Café,...", "de.Gastronomiebetrieb", "businesstype.gastro")
						},
					]}
					showOther={true}
					isRequest={false}
					trackingEvent={{
						category: 'Cooperation',
						action: 'Cooperation' + eventPostfix,
					}}
				/>

				<Dialog
					fullScreen={!AppState.instance.deviceInfo.desktop}
					open={this.dialogOpen}
					onClose={this.HandleDialogRequestClose}
					className={AppState.instance.deviceInfo.desktop ? "webcontentDialogLP" : "webcontentDialog"}
				>
					{<AppBar>
						<Toolbar>
							<IconButton color="primary" onClick={this.HandleDialogRequestClose} aria-label="Close"
								classes={{root: this.props.classes.closeButtonRoot}}
							>
								<CloseIcon/>
							</IconButton>
							<Typography type="title" color="inherit" className={this.props.classes.dialogTitle}>
								{this.dialogTitle}
							</Typography>
						</Toolbar>
					</AppBar>}

					{this.dialogContent}
				</Dialog>
			</div>
		);
	}
}

LandingPageFooter.propTypes = {
	classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(LandingPageFooter);