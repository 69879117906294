import React from 'react';
import {withStyles} from 'material-ui/styles';
import AppBase from './AppBase';
import PropTypes from 'prop-types';
import AccountRequestPage from './pages/AccountRequestPage';
import ApiInterface from './ApiInterface';
import RestApi from './RestApi';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import Translate from './Translate';
import Tracking from './Tracking';
import AppState from './AppState';
import {MuiThemeProvider, createMuiTheme} from 'material-ui/styles';
import $ from 'jquery';
import {primaryColorLena, secondaryColorLena} from './colors';
import Utils from './Utils';
import ScreenMessage from './components/ScreenMessage';
import LoginAction from './utils/LoginAction';
import {ToSPage, PPPage, ImprintPage} from './pages/WebContentPage';
import SiteStatus from './SiteStatus';
import FullscreenMedia from './components/FullscreenMedia';
import App from './App';
import {CircularProgress} from 'material-ui/Progress';
import LandingPage from './LandingPage';
import ProviderPage from './ProviderPage';
import raf from 'raf';
import DesktopApp from './DesktopApp';
import CardReceivePopup from './pages/CardReceivePopup';
import CardDetailsPopup from './pages/CardDetailsPopup';
import {utils} from 'iscroll/build/iscroll-probe.js';

const styles = theme => ({
});

const theme = createMuiTheme({
	palette: {
		primary: primaryColorLena,
		secondary: {
		...secondaryColorLena,
		/*A400: '#00e677',*/
		},
		//error: red,
	},
});

@observer
class AppContainer extends AppBase
{
	callAuthDone = false;
	oldOnError;
	inErrorHandler = false;
	urlFilterSetup;
	isLoadingUrlFilterSetup = true;
	unlisten;
	@observable searchHasFocus = false;
	onFileInput;

	static contextTypes =
	{
		router: PropTypes.object
	}

	constructor(props)
	{
		super(props);

		//console.log("============= APPCONTAINER()");

		AppState.instance.appContainer = this;

		Translate.Init();
		RestApi.Init();
		AppState.instance.OnRestApiInit();
		ApiInterface.Init();

		RestApi.AddOnNetworkStatus(this.OnNetworkStatus);

		this.CacheRefreshProcess();

		this.LoginProcess();

		$(window).scroll(this.OnScroll);

		if (document.referrer && document.referrer.length > 0)
		{
			const ref = document.referrer;
			if (ref.indexOf("//localhost:") < 0)
				AppState.instance.referrer = ref;
		}

		this.FixIScrollEventHandler();
	}

	FixIScrollEventHandler()
	{
		// There is a bug in iScroll that clientX and screenX are taken from 'target' instead from 'e'
		utils.click = function (e) {
			var target = e.target,
				ev;

			// on iOS the values for screenX, clientX etc are undefined
			// they only value defined seems to be layerX
			var cX = e.clientX;
			var cY = e.clientY;
			var sX = e.screenX;
			var sY = e.screenY;

			if (AppState.instance.deviceInfo.ios)
			{
				cX = e.layerX;
				cY = e.layerY;
				sX = e.layerX;
				sY = e.layerY;
			}
	
			if ( !(/(SELECT|INPUT|TEXTAREA)/i).test(target.tagName) ) {
				ev = document.createEvent('MouseEvents');
				ev.initMouseEvent('click', true, true, e.view, 1,
					sX, sY, cX, cY,
					e.ctrlKey, e.altKey, e.shiftKey, e.metaKey,
					0, null);
	
				ev._constructed = true;
				target.dispatchEvent(ev);
			}
		};
	}

	OnNetworkStatus(status)
	{
		if (status === 0)
		{
			console.log("Lost network connection");
		}
		else
		{
			console.log("Restored network connection");
		}
	}

	CacheRefreshProcess()
	{
		RestApi.SendRequestText("/pwaversion", undefined, undefined, undefined, "GET")
		.then((versionStr) =>
		{
			if (versionStr && versionStr.length > 0)
			{
				console.log("Version: " + versionStr);

				var localStr = Utils.GetHtmlVersionStr();
				if (localStr && (localStr.length > 0) && (localStr < versionStr))
				{
					Tracking.SendEvent("versionMismatch", {
						local: localStr,
						server: versionStr
					});
					setTimeout(() => {
						window.location.reload(true);
					}, 100);
				}
			}
		});
	}

	LoginProcess()
	{
		//console.log("============= APPCONTAINER.LoginProcess");

		if (!AppState.instance.isInAuthProcess)
		{
			AppState.instance.isInAuthProcess = true;
			setTimeout(() => {
				if (AppState.instance.isInAuthProcess)
				{
					console.log("Auth timeout");
					AppState.instance.userProfile.loginData.isLoggedIn = false;
					AppState.instance.userProfile.Save();
					AppState.instance.appContainer.OnAuthDone();
				}
			}, 20000);
		}

		if (AppState.instance.userProfile.loginData &&
			(AppState.instance.userProfile.loginData.isLoggedIn ||
				(AppState.instance.userProfile.loginData.accessTokens && AppState.instance.userProfile.loginData.accessTokens.length > 0)))
		{
			console.log("Auth 1");
			// Try to auto-login with saved access token
			RestApi.Auth().then((r) =>
			{
				if (r === null || r === undefined)
				{
					console.log("Auth 2");
					console.log(" AUTH response: null");
					AppState.instance.userProfile.loginData.isLoggedIn = false;
					AppState.instance.userProfile.Save();
					AppState.instance.appContainer.OnAuthDone();
				}
				else
				{
					console.log("Auth 3");
					console.log(" AUTH response: " + JSON.stringify(r));

					RestApi.GetLoginData().then((data) =>
					{
						if (data.isLoggedIn)
						{
							console.log("Auth 4");
							AppState.instance.userProfile.SetLoginData(data);
							AppState.instance.userProfile.username = data.username;
							LoginAction.OnAfterLogin();
						}
						else
						{
							console.log("Auth 5");
							//Don't delete tokens in the profile
							AppState.instance.userProfile.loginData.isLoggedIn = false;
						}
						AppState.instance.userProfile.Save();

						AppState.instance.appContainer.OnAuthDone();
					});
				}
			})
			.catch((error) =>
			{
				Tracking.OnError("Error authenticating with credentials");
				
				console.log("Auth 10: Error authenticating with credentials");
				AppState.instance.userProfile.loginData.isLoggedIn = false;
				AppState.instance.userProfile.Save();
				AppState.instance.appContainer.OnAuthDone();
			});
		}
		else
		{
			console.log("Auth 6");
			// Check if we are already logged in (e.g. after AccountRequestPage, auth + page-reload)
			RestApi.GetLoginData().then((data) =>
			{
				if (data && data.isLoggedIn)
				{
					console.log("Auth 7");
					AppState.instance.userProfile.SetLoginData(data);
					AppState.instance.userProfile.username = data.username;
					LoginAction.OnAfterLogin();
				}
				else
				{
					console.log("Auth 8");
					//Don't delete tokens in the profile
					AppState.instance.userProfile.loginData.isLoggedIn = false;
				}
				AppState.instance.userProfile.Save();

				AppState.instance.appContainer.OnAuthDone();
			});
		}
	}

	OnAuthDone()
	{
		//console.log("============= APPCONTAINER.OnAuthDone");

		//HACK:
		// if (AppState.instance.mainPageInstance)
		// 	AppState.instance.mainPageInstance.OnAuthDone();
		// else
		// 	this.callAuthDone = true; // Later when MainPage and BookmarkPage is ready
		
		AppState.instance.OnAuthDone();

		if (AppState.instance.startedWithActivity)
		{
			Tracking.SendEvent("startedWithActivity");
		}
		if (AppState.instance.referrer)
		{
			Tracking.SendEvent("referrer", {url: AppState.instance.referrer});
		}

		if (window.location.pathname === "/verifyemail")
		{
			var token = window.location.search.substring("?t=".length);

			var thanks = Translate.T("de.Vielen Dank für die Verifikation!", "en.Thanks for the verification!", "verifyemail.thanks");

			RestApi.SendRequestText("/verifyemail",
			{
				token: token,
			})
			.then((responseText) =>
			{
				this.GoTo("/");

				if (responseText && responseText === "ok")
				{
					if (AppState.instance.IsLoggedIn())
					{
						if (AppState.instance.deviceInfo.desktop)
						{
							AppState.instance.screenMessage.ShowAlert(undefined, thanks,
							[{label: Translate.FindTranslation("close"), value: 0}], true);
						}
						else
							AppState.instance.screenMessage.Show(thanks);

						// Refresh login information
						AppState.instance.userProfile.loginData.isVerified = true;
					}
					else
					{
						AccountRequestPage.ShowLogin(thanks);
					}
				}
				else
				{
					AccountRequestPage.ShowLogin(thanks);
				}
			})
			.catch((error) =>
			{
				AccountRequestPage.ShowLogin(thanks);
			});
		}

		//TODO:
		// if (window.location.pathname.indexOf("/profile") >= 0)
		// {
		// 	const username = locInfo.username;
		// 	this.OnTabbarChanged(undefined, AppState.TabIndexSwipe);

		// 	if (AppState.instance.swipePageInstance)
		// 		AppState.instance.swipePageInstance.AddCard(ProfileComponent, {username: username}, ProfileComponent.OnThrowCard, true);
		// 	else
		// 	{
		// 	}
		// }

		if (window.location.pathname === "/resetpassword")
		{
			//this.HideCover();
			token = window.location.search.substring("?t=".length);
			AccountRequestPage.ShowPasswordChange(token);
		}

		if (window.location.pathname === "/acceptinvite")
		{
			//this.HideCover();
			token = window.location.search.substring("?t=".length);
			AppState.instance.OnInviteToken(token);
			this.GoTo("/");
		}

		AppState.instance.DecideOnPageMode();

		var locInfo = Utils.ExtractLocationInfo(window.location.pathname);
		if (locInfo.filters && locInfo.filters.length > 0 &&
			!AppState.instance.isProviderPage)
		{
			// the user started the app using a deep-link =>
			// convert link to filter setup and show explore page
			var q = "?url=" + locInfo.filters.join("/") +
				"&language=" + AppState.instance.userProfile.userLang;
			RestApi.SendRequest("/convertfilter" + q, undefined, undefined, undefined, "GET")
			.then((filterSetup) =>
			{
				console.log(filterSetup);
				this.isLoadingUrlFilterSetup = false;
				if (filterSetup)
				{
					this.urlFilterSetup = filterSetup;
					const fs = filterSetup.filterStack;

					// Set global filter, except filter stack (list of categories)
					filterSetup.filterStack = [];
					AppState.instance.ApplyFilterSetup(filterSetup);
					this.urlFilterSetup.filterStack = fs;

					Tracking.SendEvent("startedWithFilterSetup", {
						filter: locInfo.filters.join("/")
					});

					// This will init <App>, <DesktopApp> etc.
					this.forceUpdate();
				}
			});
		}
		else
		{
			this.isLoadingUrlFilterSetup = false;
		}

		if (AppState.instance.isLandingPage || AppState.instance.isProviderPage)
		{
			$("#root").css('padding-bottom', '0px !important');
		}

		if (!AppState.instance.isLandingPage &&
			AppState.instance.showWebcontentAtStart)
		{
			if (AppState.instance.showWebcontentAtStart === "tos")
				this.OnToSClicked();
			else if (AppState.instance.showWebcontentAtStart === "pp")
				this.OnPPClicked();
			else if (AppState.instance.showWebcontentAtStart === "imprint")
				this.OnImprintClicked();
			AppState.instance.showWebcontentAtStart = undefined;
		}

		AppState.instance.loginDataChecked = true;
		this.forceUpdate();
	}	

	componentDidMount()
	{
		//console.log("============= APPCONTAINER.didMount()");

		if (!AppState.instance.deviceInfo.ios)
		{
			$("#root").css('overflow-y', AppState.instance.deviceInfo.desktop ? 'hidden' : 'auto');
		}

		if (!AppState.instance.deviceInfo.desktop)
		{
			window.scrollTo(0,1);
			const supportsPassive = Utils.SupportPassiveOption();
			document.addEventListener('touchmove',
				function (e)
				{
					if (e.cancelable && AppState.instance.appInstance && AppState.instance.appInstance.disableScrolling)
					{
						e.preventDefault();
						return;
					}

					// If e.target is child of '.swipeView' call preventDefault
					if ($(e.target).parents(".timePicker").length > 0 ||
						$(e.target).parents(".cardCollectionPage").length > 0 ||
						$(e.target).parents(".filterPageDialog").length > 0 ||
						$(e.target).parents(".filterPageDialogLP").length > 0 ||
						$(e.target).parents(".chatCard").length > 0)
					{
						e.preventDefault();
					}
				},
				supportsPassive ? {
					capture: false,
					passive: false
					} : false
				);
		}
		else
		{
			/*if(navigator.userAgent.match(/Trident\/7\./)) { // if IE
				$('body').on("mousewheel", function (event) {
					// remove default behavior
					event.preventDefault(); 
			
					//scroll without smoothing
					var wheelDelta = event.wheelDelta;
					var currentScrollPosition = window.pageYOffset;
					window.scrollTo(0, currentScrollPosition - wheelDelta);
				});
			}*/ 
		}

		this.TrackInitialPageView();

		this.oldOnError = window.error;
		//TODO: only on production
		//window.error = this.OnError;

		var history = this.context.router.history;
		this.unlisten = history.listen(this.OnHistoryChanged);

		var $inputField = $('#imagefile');
		$inputField.on('change', this.OnImageInput);

		$inputField = $('#videofile');
		$inputField.on('change', this.OnVideoInput);

		AppState.instance.EnsureValidTripDates();
	}

	PromptImage(fileCallback)
	{
		this.onFileInput = fileCallback;
		$("#imagefile").click();
	}

	PromptVideo(fileCallback)
	{
		this.onFileInput = fileCallback;
		$("#videofile").click();
	}

	OnImageInput = (e) =>
	{
		this.OnFileInput(e);
	}

	OnVideoInput = (e) =>
	{
		this.OnFileInput(e);
	}

	OnFileInput(e)
	{
		var files = e.target.files || e.dataTransfer.files;
		if (files)
		{
			var file = files[0];
			if (this.onFileInput)
			{
				try
				{
					this.onFileInput(file);
				}
				catch (e)
				{}
				this.onFileInput = undefined;
			}
		}

		//Reset event handlers as workaround
		var $inputField = $('#imagefile');
		$inputField.off('change', this.OnImageInput);
		$inputField.val("");
		$inputField.on('change', this.OnImageInput);

		$inputField = $('#videofile');
		$inputField.off('change', this.OnVideoInput);
		$inputField.val("");
		$inputField.on('change', this.OnVideoInput);
	}	

	componentWillUnmount()
	{
		//console.log("============= APPCONTAINER.willUnmount()");

		var $inputField = $('#imagefile');
		$inputField.off('change', this.OnImageInput);
		this.unlisten();
	}

	OnHistoryChanged = (location, action) =>
	{
		raf(() => {
			this.forceUpdate();
		});

		if (AppState.instance.appInstance)
			AppState.instance.appInstance.OnHistoryChanged(location);
		if (AppState.instance.desktopAppInstance)
			AppState.instance.desktopAppInstance.OnHistoryChanged(location);

		//HACK: provide event-delegate
		//if (AppState.instance.landingPage)
		//	AppState.instance.landingPage.forceUpdate();
	}

	OnProfileClicked(username)
	{
		if (AppState.instance.appInstance)
			AppState.instance.appInstance.OnProfileClicked(username);
		if (AppState.instance.desktopAppInstance)
			AppState.instance.desktopAppInstance.OnProfileClicked(username);
	}

	GetCurrentLocation()
	{
		return this.context.router.history.location.pathname +
			this.context.router.history.location.search;
	}

	GoTo(url, push)
	{
		if (url === "/editprofile")
		{
			if (AppState.instance.appInstance)
				AppState.instance.appInstance.AdjustUiToUrl();
		}
		else
		{
			if (push === true)
				this.context.router.history.push(url);
			else
				this.context.router.history.replace(url);
			this.forceUpdate();
		}
	}

	GoBack()
	{
		if (this.urlFilterSetup)
		{
			// Clear all filters and show normal explore page
			this.urlFilterSetup = undefined;
			AppState.instance.ClearFilter();
			this.GoTo("/", false);
		}
		else
		{
			this.context.router.history.goBack();
			this.forceUpdate();
		}
	}

	TrackInitialPageView()
	{
		var pageName = "home";

		const currentLocation = window.location.href;
		const idx = currentLocation.indexOf("/activity/");
		if (idx >= 0)
		{
			var _title = currentLocation.substring(idx + 10);
			pageName = "activity/" + _title;
		}

		Tracking.OnPageView(pageName);
	}

	OnError = (messageOrEvent, source, lineno, colno, error) =>
	{
		console.log("ERROR: " + messageOrEvent);
		
		if (this.inErrorHandler)
		{
			console.log("error while handling an error: " + messageOrEvent + ", " + error);
			return;
		}

		this.inErrorHandler = true;
		try
		{
			Tracking.OnError({msg: messageOrEvent,
				source: source,
				lineno: lineno,
				colno: colno,
				error: error});
		}
		catch (e)
		{}

		this.inErrorHandler = false;

		if (this.oldOnError !== null)
			return this.oldOnError(messageOrEvent, source, lineno, colno, error);
		else
			return false;
	}

	OnToSClicked = () =>
	{
		const label = Translate.T('en.Terms of Service', 'de.Nutzungsbedingungen', 'tos');
		AppState.instance.screenMessage.ShowDialog(label, ToSPage);
	}

	OnPPClicked = () =>
	{
		const label = Translate.T('en.Privacy Policy', 'de.Datenschutzbestimmungen', 'privacypolicy');
		AppState.instance.screenMessage.ShowDialog(label, PPPage);
	}

	OnImprintClicked = () =>
	{
		const label = Translate.T('en.Imprint', 'de.Impressum', 'imprint');
		AppState.instance.screenMessage.ShowDialog(label, ImprintPage);
	}

	OnScroll = () =>
	{
		AppState.instance.OnScreenScrolled(this);
	}

	renderLoadingScreen()
	{
		return (
			<div className="appLoadingScreen">
				<CircularProgress size={64}/>
			</div>
		);
	}

	render()
	{
		//console.log("AppContainer.render()");

		var showLandingPage = false;
		var showProviderPage = false;
		var showApp = false;
		var showDesktopApp = false;

		if (AppState.instance.loginDataChecked && !this.isLoadingUrlFilterSetup)
		{
			if (AppState.instance.isLandingPage && AppState.instance.deviceInfo.desktop)
				showLandingPage = true;
			else if (AppState.instance.isProviderPage)
				showProviderPage = true;
			else
				showApp = true;

			if (showLandingPage && (window.location.pathname.indexOf("/timeline") >= 0 ||
				window.location.pathname.indexOf("/abenteuer") >= 0 ||
				window.location.pathname.indexOf("/adventures") >= 0))
			{
				showDesktopApp = true;
			}
			else if (showLandingPage && this.urlFilterSetup)
			{
				showDesktopApp = true;
			}
		}

		return (
			<MuiThemeProvider theme={theme}>
				<div id="appContainer" className={"appContainer" + AppState.instance.GetPlatformClasses()} >

					{(!AppState.instance.loginDataChecked || this.isLoadingUrlFilterSetup) &&
						this.renderLoadingScreen()}

					{showApp &&
						<App />}
					
					{!showDesktopApp && showLandingPage && !AppState.instance.IsLoggedIn() && !AppState.instance.startedWithActivity &&
						<LandingPage />}

					{showLandingPage && (showDesktopApp || AppState.instance.IsLoggedIn() || AppState.instance.startedWithActivity) &&
						<DesktopApp />}
					
					{showProviderPage &&
						<ProviderPage />}

					{Translate.RenderLanguageSelection()}

					<AccountRequestPage/>
					<CardReceivePopup/>
					<CardDetailsPopup/>
					<FullscreenMedia />
					<ScreenMessage/>
					
					{!AppState.instance.isMobileLandingPage && <input id="imagefile" type="file" accept="image/*" style={{position: "absolute", left: "-500px"}} />}
					{!AppState.instance.isMobileLandingPage && <input id="videofile" type="file" accept="video/*" style={{position: "absolute", left: "-500px"}} />}

					<SiteStatus/>
				</div>
			</MuiThemeProvider>
		);
	}
}

export default withStyles(styles)(AppContainer);