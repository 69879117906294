import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import AppState from '../AppState';
import Translate from '../Translate';
import ContentCardStack from '../components/ContentCardStack';
import FilterBar from '../components/FilterBar';
import $ from 'jquery';
import ArrowBack from 'material-ui-icons/ArrowBack';
import Button from 'material-ui/Button';
import FormatListBulleted from 'material-ui-icons/FormatListBulleted';
import Utils from '../Utils';
import Map from '../components/Map';
import ContentList from '../components/ContentList';

const styles = theme => ({
});


@observer
class UserListSubPage extends React.Component
{
	@observable mode = 0; // swipe, list, map
	content = [];
	listContent = [];
	stack;

	numPlaceholderEntries = 0;

	//@observable items = [];

	constructor(props)
	{
		super(props);

		if (AppState.instance.deviceInfo.desktop)
			this.mode = 2;
	}

	componentDidMount()
	{
		//Tracking.OnPageView("webcontent/" + this.contentTitle);

		// AppState.instance.listManager.GetListContent(this.props.list.id)
		// .then((r) =>
		// {
		// 	this.items = r;
		// })

		this.UpdateContent();
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps.list.id !== this.props.list.id)
		{
			this.UpdateContent();
		}
	}

	UpdateContent()
	{
		this.content = [];

		const list = this.props.list;
		if (list.items)
		{
			for (var i = 0; i < list.items.length; ++i)
				this.content.push(
					{
						id: list.items[i].contentId,
						type: "activity"
					});
		}

		if (this.stack)
			this.stack.forceUpdate();
		this.forceUpdate();

		this.LoadListContent();
	}

	LoadListContent()
	{
		AppState.instance.listManager.GetListContent(this.props.list.id)
		.then((r) =>
		{
			this.listContent = [];
			for (var i = 0; i < r.length; ++i)
			{
				var newItem = {};
				if (r[i].activity)
				{
					this.listContent.push(AppState.instance.contentStore.DetailsToContentItem(
						r[i].activity, r[i].distance, r[i].categories, newItem));
				}
				else
				{
					//TODO: user, ugc
				}
			}
			this.forceUpdate();
		})
		.catch((error) =>
		{
			this.listContent = [];
			this.forceUpdate();
		});
	}

	OnStackInit = (stack) =>
	{
		this.stack = stack;
	}

	OnThrowOutDecision = (cardData, direction) =>
	{
	}

	OnThrowOutEnd = (cardData, direction) =>
	{
		var front = this.content[0];
		this.content.splice(0, 1);

		// Check if still on this list (user removed the bookmark by clicking the heart-icon?)
		const stillBookmarked = AppState.instance.listManager.IsContentOnList(front.id, this.props.list.id);
		if (stillBookmarked)
			this.content.push(front);

		// We need to change the instance which is going to be passed to ContentCardStack so it's re-rendered
		this.content = this.content.slice();
		this.forceUpdate();
	}

	OnFilterBarScroll = () =>
	{
		const id = this.props.list.id;

		var container = $("#list" + id + " .filterBarContainer");
		var sl = container.scrollLeft();

		var backGround = $("#list" + id + " .arrowBackButtonBackground");
		if (sl < 16)
		{
			const delta = (16 - 8);
			var opacity;

			if (sl < 8)
				opacity = 0;
			else
			{
				opacity = (sl - 8) / delta;
			}

			backGround.css('opacity', opacity);
		}
		else
		{
			backGround.css('opacity', 1);
		}
	}

	OnArrowBackClicked = () =>
	{
		if (this.props.onClose)
			this.props.onClose();
	}

	OnSwipeButtonClicked = () =>
	{
		this.mode = 0;
	}

	OnListButtonClicked = () =>
	{
		this.mode = 1;
	}

	OnMapButtonClicked = () =>
	{
		this.mode = 2;
	}

	OnLoadContentListData = (contentData) =>
	{
		console.log("OnLoadContentListData: " + contentData.id);
		return AppState.instance.contentStore.GetActivityDetailsById(contentData.id)
			.then((details) =>
			{
				if (details)
				{
					AppState.instance.contentStore.DetailsToContentItem(details.activity, details.distance, details.categories, contentData);
					contentData.type = "searchresult";
					return new Promise((resolve, reject) => { return resolve(contentData); });
				}
				else
				{
					return new Promise((resolve, reject) => { return reject(undefined); });
				}
			})
			.catch((error) =>
			{
				return new Promise((resolve, reject) => { return reject(error); });
			});
	}

	OnDeleteButtonClicked = () =>
	{
		AppState.instance.screenMessage.ShowAlert(Translate.T("en.Confirmation", "de.Bestätigung", "confirmation.title"),
			Translate.T("en.Do you really want to delete this list?", "de.Willst Du diese Liste wirklich löschen?", "confirmation.deleteuserlist"),
			[{label: Translate.FindTranslation("answer.no"), value: 0}, {label: Translate.FindTranslation("answer.yes"), value: 1}], true, (resultButton) =>
		{
			if (resultButton && resultButton.value)
			{
				if (this.props.onDelete)
					this.props.onDelete();
			}
		});
	}

	GetCurrentUserList = () =>
	{
		return this.props.list.id;
	}
		
	SetCurrentUserList = (listId) =>
	{
		if (this.props.onSwitchList)
			this.props.onSwitchList(listId);
	}

	OnShowAllLists = () =>
	{
		if (this.props.onClose)
			this.props.onClose();
	}

	render()
	{
		var additionalItems = [];

		additionalItems.push(
			<div className={"filterBarItem filterBarSwipeButton" + (this.mode === 0 ? " active":"")} key="filterBarSwipeButton">
				<Button onClick={this.OnSwipeButtonClicked}>
					{Utils.RenderCardIcon(true, 24, 24, this.mode === 0 ? "#e31c46" : "#aaa")}
				</Button>
			</div>
		);

		additionalItems.push(
			<div className={"filterBarItem filterBarListButton" + (this.mode === 1 ? " active":"")} key="filterBarListButton">
				<Button onClick={this.OnListButtonClicked}>
					<FormatListBulleted />
				</Button>
			</div>
		);

		additionalItems.push(
			<div className={"filterBarItem filterBarMapButton" + (this.mode === 2 ? " active":"")} key="filterBarMapButton">
				<Button onClick={this.OnMapButtonClicked}>
					<img alt="map" src={Utils.RenderMapUrl(24, 24, this.mode === 2 ? "#e31c46" : "#aaa")} />
				</Button>
			</div>
		);

		// additionalItems.push(
		// 	<div className={"filterBarItem filterBarShareButton"} key="filterBarShareButton">
		// 		<Button onClick={this.OnShareButtonClicked}>
		// 			<ShareIcon />
		// 		</Button>
		// 	</div>
		// );

		// additionalItems.push(
		// 	<div className={"filterBarItem filterBarDeleteButton"} key="filterBarDeleteButton">
		// 		<Button onClick={this.OnDeleteButtonClicked}>
		// 			<DeleteIcon />
		// 		</Button>
		// 	</div>
		// );

		var mapIncludes = [];
		var centerLat;
		var centerLng;
		if (this.mode === 2)
		{
			const list = this.props.list;
			if (list.items)
			{
				for (var i = 0; i < list.items.length; ++i)
					mapIncludes.push(list.items[i].contentId);
			}

			if (this.listContent.length > 0)
			{
				centerLat = 0;
				centerLng = 0;

				for (i = 0; i < this.listContent.length; ++i)
				{
					var c = this.listContent[i];
					centerLat += c.latitude;
					centerLng += c.longitude;
				}

				centerLat /= this.listContent.length;
				centerLng /= this.listContent.length;
			}
		}

		var contentListData = [];
		if (this.mode === 1)
		{
			const list = this.props.list;
			if (list.items)
			{
				for (i = 0; i < list.items.length; ++i)
				{
					contentListData.push(
					{
						id: list.items[i].contentId,
						type: "searchresult",
						onLoad: this.OnLoadContentListData
					});
				}
			}
		}

		const isDesktop = AppState.instance.deviceInfo.desktop;

		var translated = Translate.FindTranslation(this.props.list.title);
		if (!translated)
			translated = this.props.list.title;

		if (isDesktop)
		{
			const numItems = this.props.list.items ? this.props.list.items.length : 0;

			translated = (
				<div className="bookmarkMapTitle">
					<Button onClick={this.OnShowAllLists} className="bookmarkMapTitleShowAll">
						{Translate.T("de.Zeige alle Lesezeichen Listen", "en.Show all bookmark lists", "bookmarks.showall")}
					</Button>
					<div className="title">
						{translated}
					</div>
					<div className="subtitle">
						{numItems === 1 && Translate.T("en.1 entry", "de.1 Eintrag", "bookmarks.map.title.one")}
						{numItems !== 1 && Translate.T("en.{0} entries", "de.{0} Einträge", "bookmarks.map.title.multiple", [numItems])}
					</div>
				</div>
			);
		}

		return (
			<div
				id={"list" + this.props.list.id}
				className={this.props.className + " userListSubPage mode" + this.mode}
				key={this.props.list.id}
			>
				{!isDesktop && <FilterBar
					topSpacing={8}
					showUserList={true}
					userListType={-1}
					showDateTime={false}
					showFiltersButton={false}
					showSortButton={false}
					showFilters={false}
					showLocation={false}
					onScroll={this.OnFilterBarScroll}
					additionalItems={additionalItems}
					getUserList={this.GetCurrentUserList}
					setUserList={this.SetCurrentUserList}
					userListEnableCheckIn={true}
				/>}

				{!isDesktop && <div className="arrowBackButtonBackground"/>}

				{!isDesktop && <Button className="userListSubPageClose" onClick={this.OnArrowBackClicked}>
					<ArrowBack className="arrowBack" />
				</Button>}

				{this.mode === 0 &&
				<ContentCardStack
					id={"userListSubPageCCS"}
					className={"userListSubPageCCS"}
					key={"userListSubPageCCS"}
					onInit={this.OnStackInit}
					content={this.content}
					classNameCardWrapper={"chatCard"}
					containerSelector={"#swipePage"}
					onThrowOutDecision={this.OnThrowOutDecision}
					onThrowOutEnd={this.OnThrowOutEnd}
				/>}

				{this.mode === 1 && 
				<ContentList key={"userListContentList"} itemType={"content"}
					categoryIds={null}
					renderMode={"medium"}
					contentData={contentListData}
					maxTotal={this.numPlaceholderEntries}
					horizontal={false}
				/>}

				{this.mode === 2 && 
				<Map
					id="userListMap"
					gestureHandling="greedy"
					forceInclude={mapIncludes}
					forceIncludeOnly={true}
					zoomFactor={10}
					showLocation={true}
					maxNumCategories={1}
					mapSimpleMode={false}
					disableFilterButton={true}
					loadOnVisibility={false}
					showTitle={AppState.instance.deviceInfo.desktop}
					title={translated}
					centerLat={centerLat}
					centerLng={centerLng}
					verticalMode={AppState.instance.deviceInfo.desktop}
				/>}
			</div>
		);
	}
}

UserListSubPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserListSubPage);