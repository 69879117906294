import React from 'react';
import {Route} from 'react-router-dom';
import {withStyles} from 'material-ui/styles';
import DetailPage from './pages/DetailPage';
import SuggestEditPage from './pages/SuggestEditPage';
import AddReviewPage from './pages/AddReviewPage';
import MainPage from './pages/MainPage';
import BookmarkPage from './pages/BookmarkPage';
import ProfilePage from './pages/ProfilePage';
import {observer} from 'mobx-react';
import AppState from './AppState';
import $ from 'jquery';
import Utils from './Utils';
import FilterPage from './pages/FilterPage';
import Tutorial from './components/Tutorial';
import FilterBar from './components/FilterBar';
import Button from 'material-ui/Button';
import TimelinePage from './pages/TimelinePage';
import ChatBotPage from './pages/ChatBotPage';
import Tracking from './Tracking';
import DesktopMenu from './components/desktop/DesktopMenu';
import { observable } from '../node_modules/mobx';
import Translate from './Translate';
import Map from './components/Map';
import raf from 'raf';
import AdvAnnouncement from './components/adventure/AdvAnnouncement';
import AdvNotReady from './components/adventure/AdvNotReady';
import AccountRequestPage from './pages/AccountRequestPage';
import EditProfilePage from './pages/EditProfilePage';


const styles = theme => ({
});


@observer
class DesktopApp extends React.Component
{
	@observable selectedUsername;
	@observable showExploreMap = false;
	@observable chatBotVisible = false;

	constructor(props)
	{
		super(props);

		AppState.instance.desktopAppInstance = this;

		//if (AppState.instance.isProduction && AppState.instance.deviceInfo.desktop)
		//	window.location.replace("https://www.lena.app");

		$(window).scroll(this.OnScroll);

		if (AppState.instance.startedWithActivity)
			AppState.instance.currentMainTab = AppState.TabIndexExplore;
		else
			AppState.instance.currentMainTab = AppState.TabIndexExplore;

		if (AppState.instance.appContainer.urlFilterSetup)
			AppState.instance.currentMainTab = AppState.TabIndexExplore;

		if (window.location.pathname.indexOf("/timeline") >= 0)
		{
			AppState.instance.currentMainTab = AppState.TabIndexTimeline;
		}
		else if (window.location.pathname.indexOf("/adventures") >= 0 ||
			window.location.pathname.indexOf("/abenteuer") >= 0)
		{
			AppState.instance.currentMainTab = AppState.TabIndexAdventure;
		}
	}

	componentDidMount()
	{
		//$("#root").css('overflow-y', 'hidden');
		$("#root").css('padding-bottom', '0px !important');

		if (AppState.instance.deviceInfo.ie)
			$("#root").css('position', 'static');

		AppState.instance.EnsureValidTripDates();

		if (window.location.pathname.indexOf("/profile") >= 0)
		{
			const locInfo = Utils.ExtractLocationInfo(window.location.pathname);
			const username = locInfo.username;
			this.OnProfileClicked(username);
		}

		if (window.location.pathname.indexOf("/editprofile") >= 0)
		{
			if (AppState.instance.IsLoggedIn())
			{
				AppState.instance.screenMessage.ShowDialog(Translate.T('en.Edit profile', 'de.Profil bearbeiten', 'editprofile'), EditProfilePage);
			}
			else
			{
				AccountRequestPage.Show(undefined, undefined, "/editprofile");
			}
		}
	}

	componentWillUnmount()
	{
	}

	componentWillReceiveProps(nextProps)
	{
	}

	shouldComponentUpdate(nextProps, nextState)
	{
		// Prevents some refresh bugs
		return true;
	}

	OnScroll = () =>
	{
		AppState.instance.OnScreenScrolled(this);
	}

	OnProfileClicked(username)
	{
		this.ChangePage(AppState.TabIndexTimeline);
		this.selectedUsername = username;
	}

	OnHistoryChanged(location)
	{
		//console.log("new browser location: " + location);

		if (AppState.instance.mainPageInstance)
			AppState.instance.mainPageInstance.MatchTabWithLocation();

		AppState.instance.filterManager.onFiltersChanged.Call();

		if (AppState.instance.bookmarkPageInstance)
			AppState.instance.bookmarkPageInstance.MatchUiWithUrl();

		if (window.location.pathname.indexOf("/profile") >= 0)
		{
			const locInfo = Utils.ExtractLocationInfo(window.location.pathname);
			const username = locInfo.username;
			this.OnProfileClicked(username);
		}

		AppState.instance.RefreshContentGrids();
	}

	OnBackFromDetailPageStart()
	{
		this.ChangePage(AppState.TabIndexExplore);
	}

	ChangePage(index)
	{
		var oldIndex = AppState.instance.currentMainTab;

		if (index !== oldIndex)
		{
			if (AppState.instance.contentStore.IsPaused())
				AppState.instance.contentStore.Resume();
		}

		var oldPage = AppState.instance.GetPageByIndex(oldIndex);
		if (oldPage && oldPage.OnDeactivate)
			oldPage.OnDeactivate();

		console.log("ChangePage: " + index);
		AppState.instance.currentMainTab = index;

		var newPage = AppState.instance.GetPageByIndex(index);
		if (newPage && newPage.OnActivate)
			newPage.OnActivate();

		// Save/Restore scroll position
		Utils.ScrollToTop();

		if (index === AppState.TabIndexTimeline)
		{
			Tracking.OnPageView("timeline");
		}
		else if (index === AppState.TabIndexExplore)
		{
			Tracking.OnPageView("explore");
		}
		else if (index === AppState.TabIndexAdventure)
		{
			Tracking.OnPageView("adventures");
		}
		else if (index === AppState.TabIndexBookmark)
		{
			AppState.instance.showNewIndicator[AppState.TabIndexBookmark] = undefined;
			//Done by the BookmarkPage itself
			//Tracking.OnPageView("bookmark");
		}
		/*else if (index === AppState.TabIndexJournal)
		{
			Tracking.OnPageView("journal");
		}*/
		else if (index === AppState.TabIndexProfile)
		{
			Tracking.OnPageView("account");
		}

		AppState.instance.OnScreenScrolled(this);
		raf(() =>
		{
			AppState.instance.OnScreenScrolled(this);
		});
		setTimeout(() => {
			AppState.instance.OnScreenScrolled(this);
		}, 1000);

		this.chatBotVisible = false;
	}

	OnUserClosed = () =>
	{
		this.selectedUsername = undefined;

		if (window.location.pathname.indexOf("/profile") >= 0)
		{
			AppState.instance.appContainer.GoTo("/");
		}
	}

	OnOpenMap = () =>
	{
		this.showExploreMap = !this.showExploreMap;
	}

	OnMapContentLoaded = (content, markers) =>
	{
		if (AppState.instance.mainPageInstance)
			AppState.instance.mainPageInstance.OnMapContentLoaded(content, markers);
	}

	OnMarkerClicked = (marker) =>
	{
		if (AppState.instance.mainPageInstance)
			AppState.instance.mainPageInstance.OnMapMarkerClicked(marker);
	}

	OnChatBotDeactivated = () =>
	{
		this.chatBotVisible = false;
	}

	renderPageContent()
	{
		const showFilterBar = AppState.instance.currentMainTab === AppState.TabIndexExplore &&
			AppState.instance.isShowingDetailPage === 0 &&
			!AppState.instance.isMapPageVisible;

		return (
			<div>
				{/*AppState.instance.currentMainTab === AppState.TabIndexTimeline &&
				AppState.instance.isShowingDetailPage === 0 &&
					<ChatBotHeaderBar />
				*/}

				{showFilterBar &&
				<FilterBar
					topSpacing={16}
				/>}

				{showFilterBar &&
				<div className={"filterBarMapButtonContainer" + (this.showExploreMap ? " selected":" unselected")}>
					<Button onClick={this.OnOpenMap} className={this.showExploreMap ? "selected":"unselected"}>
						{this.showExploreMap && Translate.T("de.Karte verbergen", "en.Hide map", "desktop.filterbar.hidemap")}
						{!this.showExploreMap && Translate.T("de.Karte zeigen", "en.Show map", "desktop.filterbar.showmap")}
					</Button>
				</div>}

				{showFilterBar && this.showExploreMap &&
				<Map
					id="exploreMap"
					gestureHandling="greedy"
					mapSimpleMode={false}
					disableContent={true}
					disableFilterButton={true}
					//markerIcon={this.GetMarkerIcon}
					onMarkerClicked={this.OnMarkerClicked}
					onInit={(instance) =>
					{
							this.map = instance;
							this.forceUpdate();
					}}
					onContentLoaded={this.OnMapContentLoaded}
				/>}

				{this.chatBotVisible &&
				<ChatBotPage
					alwaysVisible={true}
					onDeactivated={this.OnChatBotDeactivated}
				/>}

				{<Route path="/activity/:title" component={DetailPage} />}

				{/*<MapPage />*/}

				<div id="siteContent" className="siteContentInitial">
					

					{AppState.instance.currentMainTab === AppState.TabIndexTimeline &&
					<TimelinePage
						onShowCommunity={this.OnCloseMyTimeline}
						selectedUsername={this.selectedUsername}
						onUserClosed={this.OnUserClosed}
					/>}

					{AppState.instance.currentMainTab === AppState.TabIndexExplore &&
					<MainPage
						showMap={this.showExploreMap && this.map ? this.map : false}
					/>}

					{AppState.instance.currentMainTab === AppState.TabIndexAdventure &&
						<AdvAnnouncement
							onCta={this.OnAdvAnnouncementCta}
						/>}

					{AppState.instance.currentMainTab === AppState.TabIndexBookmark &&
					<BookmarkPage/>}

					{AppState.instance.currentMainTab === AppState.TabIndexProfile &&
					<ProfilePage/>}
				</div>
			</div>
		);
	}

	OnAdvAnnouncementCta = () =>
	{
		AppState.instance.screenMessage.ShowDialog(undefined, AdvNotReady, undefined, undefined, undefined, undefined, false, "AdvNotReadyContainer");
	}

	OnPageSelected = (tabIndex) =>
	{
		// Still in DetailPage?
		if (window.location.pathname.indexOf("/activity") >= 0)
		{
			AppState.instance.appContainer.GoTo("/", true);
			this.forceUpdate();
		}

		this.ChangePage(tabIndex);
	}

	OnChatBotClicked = () =>
	{
		this.chatBotVisible = !this.chatBotVisible;
	}

	render()
	{
		//console.log("App.render: " + this.renderedLocation);

		return (
			<div id="desktopApp" className={"desktopApp" + AppState.instance.GetPlatformClasses()} >

				<DesktopMenu
					onPageSelected={this.OnPageSelected}
					onChatBotClicked={this.OnChatBotClicked}
					chatBotVisible={this.chatBotVisible}
				/>

				{this.renderPageContent()}
			
				{<SuggestEditPage/>}
				{<AddReviewPage/>}
				{<Tutorial/>}
				{<FilterPage/>}

			</div>
		);
	}
}

export default withStyles(styles)(DesktopApp);