import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import {withStyles} from 'material-ui/styles';
import Translate from '../Translate';
import {observer} from 'mobx-react';
import $ from 'jquery';
import Utils from '../Utils';

const styles = theme => ({
	textField:
	{
		width: '100%',
		border: '1px solid #e0e0e0',
		paddingBottom: '4px',
		paddingTop: '4px',
		paddingLeft: '8px',
		paddingRight: '8px',
		backgroundColor: 'white'
	},
});

@observer
class SearchField extends React.Component
{
	searchQueue = [];
	searchTimer;

	OnTextFieldChanged = (event) =>
	{
		//TODO: rate limit 1 per second
		//TODO: wait minimum of 500ms after last keypress

		const minLength = this.props.minLength || 3;

		const newValue = event.target.value;
		if (!newValue || newValue.length >= minLength)
			this.Enqueue(newValue);
	}

	Enqueue(value)
	{
		this.searchQueue.push(value);
		this.searchTimer = Utils.SetTimer(this.searchTimer, 500, this.FireSearch, 50);
	}

	FireSearch = () =>
	{
		if (this.searchQueue.length > 0)
		{
			const lastEntry = this.searchQueue[this.searchQueue.length - 1];
			this.searchQueue = [];

			if (this.props.onSearch)
				this.props.onSearch(lastEntry, this.OnSearchDone);
		}
	}

	OnSearchDone = () =>
	{
		//TODO: don't fire new search event until last search is done
	}

	OnTextFieldKeyPress = (event) =>
	{
		var key = event.key || event.keyCode || event.which;
		//console.log(`Pressed keyCode ${key}`);
		if (key === 'Enter' || key === '13')
		{
			event.preventDefault();
			//console.log("-_________ENTER");

			var selector;
			if (this.props.id)
			{
				selector = "#" + this.props.id;
			}
			else
			{
				selector = "#searchInput";
			}

			var blur = true;
			if (this.props.onEnter)
				blur = this.props.onEnter(event.target.value) !== true;

			if (blur)
				$(selector).blur();
		}
	}

	render()
	{
		const { classes } = this.props;

		return (
			<TextField
				type="search"
				className={classes.textField}
				onChange={this.OnTextFieldChanged}
				onKeyPress={this.OnTextFieldKeyPress}
				placeholder={this.props.breadcrumbs || Translate.FindTranslation("search.default")}
				autoComplete={"off"}
				InputProps={{
					disableUnderline: true,
					id: this.props.id || "searchInput",
					onFocus: this.props.onFocus,
					onBlur: this.props.onBlur,
				}}
			/>
		);
	}
}

SearchField.propTypes = {
	classes: PropTypes.object.isRequired,
	onSearch: PropTypes.func.isRequired
};

export default withStyles(styles)(SearchField);