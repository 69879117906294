import {observable} from 'mobx';

export default class IpLocation
{
	@observable id;
	@observable ip;
	@observable address;
	@observable zip;
	@observable district;
	@observable state;
	@observable country;
	@observable latitude;
	@observable longitude;
}