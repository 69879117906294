import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import AppState from '../AppState';
import Button from 'material-ui/Button';
import BackIcon from 'material-ui-icons/KeyboardArrowLeft';
import { observable } from 'mobx';
import Tracking from '../Tracking';
import Translate from '../Translate';

const styles = theme => ({
});

@observer
class CompetitionPage extends React.Component
{
	@observable showDisclaimer = false;

	componentDidMount()
	{
		Tracking.OnPageView("CompetitionPage");
	}

	OnCta = () =>
	{
		if (this.props.onBackClick)
			this.props.onBackClick();
	}

	OnDisclaimer = () =>
	{
		this.showDisclaimer = !this.showDisclaimer;
	}

	render()
	{
		if (AppState.instance.isShowingDetailPage > 0)
			return null;

		return (
			<div className="competitionPage">

				<div className="competitionPageTopBar">
					{<Button className="back" onClick={this.props.onBackClick}>
						<BackIcon />
					</Button>}
				</div>

				<div className="content">

					<div className="title">{Translate.T("de.Gewinne\nAbenteuerkarten, Glitzer-Sticker und Poster", "en.Win\nAdventure Cards, Glitter Stickers and Poster", "competition.title")}</div>

					<div className="how">{Translate.T("de.Unter den ersten 100 gesammelten Abenteuerkarten verlosen wir bis 15. Februar für Deine kleinen Abenteurer:",
						"en.Within the first 100 collected Adventure Cards we draw the following prizes until February 15th, 2019:", "competition.how")}</div>

					<div className="prizes">
						<div className="prize">{Translate.T("de.3x LENAs Abenteuer Kartenset bestehend aus 5 lustigen Spielkarten der tierischen Helden, 1 Poster Deines LENA Lieblingstiers und dem LENA Abenteuer Sticker-Set",
							"en.3x LENA's Adventure Cardsets compiled of 5 fun playcards of animal heroes, 1 poster of your favorite animal hero and 1 LENA Adventure glitter sticker set", "competition.prize0")}</div>
						<div className="prize">{Translate.T("de.10x LENAs Abenteuer Kartenset bestehend aus 5 lustigen Spielkarten der tierischen Helden",
							"en.10x LENA's Adventure Cardsets compiled of 5 fun playcards of animal heroes", "competition.prize1")}</div>
						<div className="prize">{Translate.T("de.87x Je eine LENA Abenteuerkarte nach Wahl", "en.87x 1 LENA Adventure Card of your choice", "competition.prize2")}</div>
					</div>
				

					<div className="cta">
						<Button onClick={this.OnCta}>
							{Translate.T("de.Mitmachen und Karten sammeln", "en.Take part and collect cards", "competition.cta")}
						</Button>
					</div>

					<div className="disclaimerBtn" onClick={this.OnDisclaimer}>
						{Translate.T("de.Teilnahmebedingungen", "en.Terms and conditions", "competition.disclaimer.button")}
					</div>

					{this.showDisclaimer && <div className="disclaimer" onClick={this.OnDisclaimer}>
						{Translate.T("de.Der Gewinn kann nicht übertragen oder in bar abgelöst werden. Es gelten die Nutzungsbedingungen und Datenschutzbestimmungen von Lena24 e.U.",
							"en.The prizes can't be transferred or redeemed for cash. Terms of Service and Privacy Policy of Lena24 e.U. apply.", "competition.disclaimer")}
					</div>}

					<div className="image" />

				</div>
			</div>
		);
	}
}

CompetitionPage.propTypes =
{
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(CompetitionPage);