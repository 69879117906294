import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from 'material-ui/styles';
import {observer} from 'mobx-react';
import SearchResult from './SearchResult';
import AppState from '../AppState';

const styles = theme => ({
	textField:
	{
		width: '100%',
		border: '1px solid #e0e0e0',
		paddingBottom: '4px',
		paddingTop: '4px',
		paddingLeft: '8px',
		paddingRight: '8px',
		backgroundColor: 'white'
	},
});

@observer
class SocialList extends React.Component
{
	componentDidMount()
	{
		AppState.instance.socialManager.onProfilesChanged.Add(this.OnProfilesChanged);
	}

	componentWillUnmount()
	{
		AppState.instance.socialManager.onProfilesChanged.Remove(this.OnProfilesChanged);
	}

	OnProfilesChanged = () =>
	{
		this.forceUpdate();
	}

	render()
	{
		return (
			<div className={"socialList"}>
				{this.props.usernames && this.props.usernames.map((username, index) =>
				{
					var sr = {
						id: index,
						type: "profile",
						username: username,
						url: "/profile/" + username,
					};
					return (
						<SearchResult key={index} contentData={sr} isFirst={index === 0} isLast={index === this.props.usernames.length - 1} columnMode={true} />
					);
				})}
			</div>
		);
	}
}

SocialList.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SocialList);